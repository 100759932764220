(function ($) {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Home controller
     * @class homeController
     * @memberOf angular_module.WB.cinehub.controllers
     * @desc This is home controller
     * @param {!angular.$rootScope} $rootScope angular root scope
     * @param {!angular.$scope} $scope angular scope
     * @param {$uibModal} $uibModal $uibModal
     * @param {$window} $window $window instance
     * @param {service} homeService home service
     * @param {factory} filmUrlGeneratorFactory film url generator factory
     * @param {service} deviceDetectorService device detector
     * @param {constant} API_EFD API_EFD
     * @param {service} videoSourceGeneratorService video source generatior service
     * @param {service} localStorageService local storage service
     * @param {service} highlightedFilmsService highlighted films service
     * @param {constant} AUDIO_VIDEO_ASSET_CATEGORY AUDIO_VIDEO_ASSET_CATEGORY
     * @param {service} shoppingCartService shopping cart service
     * @param {service} textService text service
     * @param {service} permissionQueueService permission queue service
     * @param {service} filmService film service
     * @param {service} filmRatingService film rating service
     * @param {service} heroUrlConvertorService API_ROOT hero url convertor service
     * @param {service} convertLocalizationCodeToValueService convert localization code to value service
     * @param {service} assetCategoryConvertor asset category convertor
     */
    function homeController($rootScope,
        $scope,
        $uibModal,
        $window,
        homeService,
        filmUrlGeneratorFactory,
        deviceDetectorService,
        API_EFD,
        videoSourceGeneratorService,
        localStorageService,
        highlightedFilmsService,
        AUDIO_VIDEO_ASSET_CATEGORY,
        shoppingCartService,
        textService,
        permissionQueueService,
        filmService,
        filmRatingService,
        heroUrlConvertorService,
        convertLocalizationCodeToValueService,
        assetCategoryConvertor,
        PERMISSIONS) {

        /**
         * Instance to homeController
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @private
         * @type {homeController}
         */
        var self = this,

            /**
             * Hero slick config
             * @type {object}
             * @memberOf angular_module.WB.cinehub.controllers.homeController
             * @private
             */
            heroSlickConfig = {
                prevArrow: "<a role='button' href class='carousel-control left hidden-sm hidden-xs'><span aria-hidden='true' class='glyphicon glyphicon-menu-left'></span></a>",
                nextArrow: "<a role='button' href class='carousel-control right hidden-sm hidden-xs'><span aria-hidden='true' class='glyphicon glyphicon-menu-right'></span></a>",
                autoplay: false,
                arrows: true,
                draggable: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 0
            },

            /**
             * Configuration for slick carusel
             * @memberOf angular_module.WB.cinehub.controllers.homeController
             * @private
             * @type {object}
             */
            slickConfig = {
                prevArrow: "<i class='fa fa-angle-left pointer'></i>",
                nextArrow: "<i class='fa fa-angle-right pointer'></i>",
                slidesToShow: 6,
                slidesToScroll: 6,
                autoplay: false,
                draggable: false,
                arrows: true,
                infinite: true,
                responsive: [{
                    breakpoint: 1800,
                    settings: {
                        slidesToShow: 5,
                        slidesToScroll: 5,
                        infinite: true
                    }
                }, {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true
                    }
                }, {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        infinite: true
                    }
                }, {
                    breakpoint: 460,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true
                    }
                }]
            };


        /**
         * translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         */
        self.translations = localStorageService.get("translations");

        /**
         * user info
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         */
        self.user = localStorageService.get("userInfo");
        
        /**
         * impersonate user flag
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");
        
        /**
         * API_EFD
         * @constant
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @public
         * @type {string}
         */
        self.API_EFD = API_EFD;
        
        /**
         * Permissions
         * @constant
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @public
         * @type {object}
         */

        self.PERMISSIONS = PERMISSIONS;

        /**
         * AUDIO_VIDEO_ASSET_CATEGORY
         * @constant
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @public
         * @type {string}
         */
        self.AUDIO_VIDEO_ASSET_CATEGORY = AUDIO_VIDEO_ASSET_CATEGORY;

        /**
         * film url generator
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @public
         * @type {filmUrlGeneratorFactory}
         */
        self.filmUrlGenerator = filmUrlGeneratorFactory;

        /**
         * divice detector
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @public
         * @type {service}
         */
        self.deviceDetector = deviceDetectorService;

        /**
         * Convert localization code to value service 
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @type {service}
         */
        self.convertLocalizationCodeToValueService = convertLocalizationCodeToValueService;
        
        /**
         * asset category convertor
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @type {service}
         */
        self.assetCategoryConvertor = assetCategoryConvertor;

        /**
         * Trailers sources
         * @public
         * @type {array}
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         */
        self.trailers = [];

        /**
         * Videogular config
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @type {object}
         */
        self.videogularConfig = {
            nativeFullscreen: true,
            preload: "none",
            controls: false,
            autoplay: false,
            plugins: {
                poster: "",
                controls: {}
            }
        };

        /**
         * Highlighted Films
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @private
         */
        self.highlightedFilms = {
            _embedded: {
                highlightedFilms: []        
            }
        },

        /**
         * Video gular api
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         */
        self.videogularAPI = null;

        /**
         * Show player
         * @type {boolean}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         */
        self.showPlayer = true;

        /**
         * Flag which indicates that main carousel is present on the page
         * @type {boolean}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         */
        self.isMainCarouselPresent = true;
        
        
        /**
         * Flag which indicates whether Home Page Results are loaded
         * @type {boolean}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         */
        self.isDataLoaded = false;
        

        /**
         * Get single featured film
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @private
         * @function getHighlightedFilms
         */
        var getHighlightedFilms = function () {
            var page = 0,
                size = 50,
                index = 0;
            return highlightedFilmsService.getHighlightedFilms(page, size).success(function (result) {
                if (result.data && result.data.highlightedFilms) {
                    self.highlightedFilms._embedded.highlightedFilms = result.data.highlightedFilms.content;
                    self.highlightedFilms._embedded.highlightedFilms = heroUrlConvertorService.convert(self.highlightedFilms._embedded.highlightedFilms);
                    if (result.data.highlightedFilms.content && result.data.highlightedFilms.content.length > 0) {
                        for (index; index < result.data.highlightedFilms.totalElements - 1; index++) {
                            self.trailers.push(videoSourceGeneratorService.getSource(result.data.highlightedFilms.content[index].film.trailerId, result.data.highlightedFilms.content[index].film.fileName));
                        }
                    } else {
                        self.isMainCarouselPresent = false;
                    }
                } else {
                    self.highlightedFilms._embedded.highlightedFilms = [];
                    self.isMainCarouselPresent = false;
                }

                //bad practice to have DOM selectors/jQuery inside controller
                //but if you have more then one slick carousel on the same page for some reason (slick reason)
                //hero carousel in FF/IE when you slide to a next slide it slides not in a full width and only after a couple sec it stretches to full width like it should
                //working with angular slick directive doesn't solve the problem (tried all kinds of setting/options/approaches) 
                //the only way seems to be working that is init slick by jquery I wrapped it into setTimeout in order to angular watch/ng-repeat
                //could build the html carousel slides for us
                setTimeout(function () {
                    $("#heroCarousel").slick(heroSlickConfig);
                }, 1);

            });
        };

        /**
         * Set pure content length 
         * @function setPureContentLength
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         */
        var setPureContentLength = function () {
            self.newsItems.forEach(function (news) {
                var pureContent = news.contents;
                pureContent = textService.removeHtmlTags(pureContent);
                news.pureContentLength = pureContent.length;
            });
            self.spotlightItems.forEach(function (spotlights) {
                var pureContent = spotlights.contents;
                pureContent = textService.removeHtmlTags(pureContent);
                spotlights.pureContentLength = pureContent.length;
            });
        };

        /**
         * Load ratings
         * @function loadRatingData
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.loginController
         * @returns {Promise}
         */
        var loadRatingData = function (upcomingFilms, inTheaterFilms) {
            var filmObjs = {};
            filmObjs.upcomingFilms = upcomingFilms;
            filmObjs.inTheaterFilms = inTheaterFilms;
            return filmRatingService.setFilmRatings(filmObjs).then(function (res) {
                self.ratingData = res.ratings;
                self.upcomingFilms = res.filmObjs["upcomingFilms"];
                self.inTheaterFilms = res.filmObjs["inTheaterFilms"];
            });
        };

        /**
         * Get home page results 
         * @function getHomePageResults
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         */
        var getHomePageResults = function () {
            homeService.getHomePageResults().success(function (result) {
                self.newsItems = result.data.newsItems.content;
                self.inTheaterFilms = result.data.theaterFilms.content;
                self.upcomingFilms = result.data.comingsoonFilms.content;
                self.updatedMaterials = result.data.filmAssets.content;
                self.spotlightItems = result.data.spotlightItems.content;
                self.isDataLoaded = true;

                loadRatingData(self.upcomingFilms, self.inTheaterFilms);

                shoppingCartService.checkAssetsInDownloadCart(self.updatedMaterials);
                permissionQueueService.checkAssetsInPermissionCart(self.updatedMaterials);

                //bad practice to have DOM selectors/jQuery inside controller
                //but if you have more then one slick carousel on the same page for some reason (slick reason)
                //hero carousel in FF/IE when you slide to a next slide it slides not in a full width and only after a couple sec it stretches to full width like it should
                //working with angular slick directive doesn't solve the problem (tried all kinds of setting/options/approaches) 
                //the only way seems to be working that is init slick by jquery I wrapped it into setTimeout in order to angular watch/ng-repeat
                //could build the html carousel slides for us
                setTimeout(function () {
                    $("#newUpdatedMaterialsCarousel").slick(slickConfig);
                    $("#comingSoonCarousel").slick(slickConfig);
                    $("#inTheatersCarousel").slick(slickConfig);
                }, 1);

                setPureContentLength();
            });
        };

        /**
         * Play trailer
         * @function playTrailer
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @public
         * @param {object} film film
         */
        self.playTrailer = function (film) {
            self.openLightbox = true;
            self.lightboxAsset = {
                id: film.trailerId ? film.trailerId : film.id,
                assetCategory: "Audio / Video",
                fileTypeCategory: "Video"
            };
            self.lightboxAction = "playTrailerOnly";
        };

        /**
         * Open edit main film carousel modal
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @function openEditMainFilmCarouselModal
         */
        self.openEditMainFilmCarouselModal = function () {
            var uibModalConfig = {
                    templateUrl: "/modules/editMainFilmCarouselModal/modalEditMainFilmCarousel.html",
                    controller: "modalEditMainFilmCarousel",
                    controllerAs: "modalEditMainFilmCarouselCtrl",
                    keyboard: false,
                    backdrop: "static",
                    size: "lg",
                    resolve: {}
                },
                openResolvedModal = function () {
                    uibModalConfig.resolve = {
                        highlightedFilms: function () {
                            return _.cloneDeep(self.highlightedFilms);
                        }
                    };
                    $uibModal.open(uibModalConfig).result.then(function (highlightedFilms) {
                        self.highlightedFilms._embedded.highlightedFilms = _.cloneDeep(heroUrlConvertorService.convert(highlightedFilms._embedded.highlightedFilms));
                        self.isMainCarouselPresent = self.highlightedFilms._embedded.highlightedFilms.length > 0;

                        //bad practice to have DOM selectors/jQuery inside controller
                        //but if you have more then one slick carousel on the same page for some reason (slick reason)
                        //hero carousel in FF/IE when you slide to a next slide it slides not in a full width and only after a couple sec it stretches to full width like it should
                        //working with angular slick directive doesn't solve the problem (tried all kinds of setting/options/approaches) 
                        //the only way seems to be working that is init slick by jquery I wrapped it into setTimeout in order to angular watch/ng-repeat
                        //could build the html carousel slides for us
                        $("#heroCarousel").slick("unslick");
                        setTimeout(function () {
                            $("#heroCarousel").slick(heroSlickConfig).slick("slickGoTo", 0, true);
                        }, 1);
                    });
                };

            openResolvedModal();
        };

        /**
         * on playr ready
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @function onPlayerReady
         * @param {object} API videogular api
         */
        self.onPlayerReady = function onPlayerReady(API) {
            self.videogularAPI = API;
        };

        /**
         * Play trailer
         * @funciton playTrailer
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @public
         */
        self.playCarouselTrailer = function () {
            self.videogularAPI.toggleFullScreen();
            self.videogularAPI.play();
        };

        /**
         * Open lightbox
         * @function openLightBox
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @param {object} $event browser event
         * @param {object} asset asset
         * @param {string} action action
         */
        self.openLightBox = function ($event, asset, action) {
            if ($event && $($event.target).hasClass("movie-title")) {
                return;
            }
            self.openLightbox = true;
            self.lightboxAsset = asset;
            self.lightboxAction = action || "edit";
        };

        /**
         * takes the user to other page
         * @function go
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @param {string} linkTo link to the other page
         */
        self.go = function (linkTo) {
            $window.location.href = linkTo;
        };

        /**
         * Add to cart
         * @function addToCart
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @public
         * @param {object} asset film asset
         */
        self.addToCart = function (asset) {
            shoppingCartService.addToCart(asset).success(function () {
                asset.isInDownloadCart = true;
                $rootScope.$emit("updateShoppingCartCount", true);
            });
        };

        /**
         * Init
         * @function init
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         * @public
         */
        self.init = function () {
            getHighlightedFilms();
            getHomePageResults();

            if (window.WB.cinehub.preload.assetId && window.WB.cinehub.preload.mmxId && window.WB.cinehub.preload.action) {
                self.openLightBox(null, {
                    id: window.WB.cinehub.preload.assetId,
                    mmxId: window.WB.cinehub.preload.mmxId
                }, window.WB.cinehub.preload.action);
            }
        };

        $rootScope.$on("headerInitialized", function () {
            self.init();
        });

        $rootScope.$on("materialNameUpdated", function ($event, assetObj) {
            self.updatedMaterials.forEach(function(updatedAsset)  {
                if (assetObj.oldFileName === updatedAsset.fileName && assetObj.newFileName !== updatedAsset.fileName) {
                    updatedAsset.fileName = assetObj.newFileName;
                }
            });
        });

        $rootScope.$on("assetTypeUpdated", function () {
            self.init();
        });


        $(function(){
            $scope.totalDisplayed = 30; 
            $("#newUpdatedMaterialsCarousel").on("beforeChange", function(event, slick, currentSlide, nextSlide){
                if(nextSlide == 0 && $scope.totalDisplayed < self.updatedMaterials.length){
                    $scope.totalDisplayed += 30;
                    $scope.$apply();
                    $("#newUpdatedMaterialsCarousel").slick("unslick");
                    $("#newUpdatedMaterialsCarousel").slick(slickConfig);
                }
            });
        });
    }


    controllers.controller("homeController", [
        "$rootScope",
        "$scope",
        "$uibModal",
        "$window",
        "homeService",
        "filmUrlGeneratorFactory",
        "deviceDetectorService",
        "API_EFD",
        "videoSourceGeneratorService",
        "localStorageService",
        "highlightedFilmsService",
        "AUDIO_VIDEO_ASSET_CATEGORY",
        "shoppingCartService",
        "textService",
        "permissionQueueService",
        "filmService",
        "filmRatingService",
        "heroUrlConvertorService",
        "convertLocalizationCodeToValueService",
        "assetCategoryConvertor",
        "PERMISSIONS",
        homeController
    ]);
}(window.jQuery));
