(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Spotlight list controller
     * @dec spotlight list controller
     * @class spotlightListController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.$rootScope} $rootScope angular root scope
     * @param {!angular.Scope} $scope angular scope
     * @param {$uibModal} $uibModal modal instance
     * @param {service} spotlightListService spotlight list service
     * @param {service} localStorageService local storage service
     * @param {constant} API_EFD API_EFD
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     * @param {service} deviceDetectorService device detector service
     */
    function spotlightListController($rootScope, $scope, $uibModal, spotlightListService, localStorageService, API_EFD, DEFAULT_PAGE_SIZE, deviceDetectorService) {

        /**
         * Instance of spotlightListController
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightListController
         * @type {spotlightListController}
         */
        var self = this;

        /**
         * translations
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.spotlightListController
         */
        self.translations = localStorageService.get("translations");

        /**
         * user
         * @type {object}
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.spotlightListController
         */
        self.user = localStorageService.get("userInfo");

        /**
         * impersonate user flag
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightListController
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");

        /**
         * Device detector service
         * @public
         * @type {service}
         * @memberOf angular_module.WB.cinehub.controllers.spotlightListController
         */
        self.deviceDetectorService = deviceDetectorService;

        /**
         * API_EFD
         * @type {constant}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightListController
         */
        self.API_EFD = API_EFD;

        /**
         * sorting options
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightListController
         */
        self.sortOptions = [{
            value: "lastModified,desc",
            text: self.translations.sort.labels.newestToOldest
        }, {
            value: "lastModified,asc",
            text: self.translations.sort.labels.oldestToNewest
        }];

        /**
         * modified interval
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightListController
         */
        self.modifiedIntervals = [{
            value: 1000,
            text: self.translations.filter.labels.allDays
        }, {
            value: 3,
            text: self.translations.filter.labels.modifiedWithin3Days
        }, {
            value: 7,
            text: self.translations.filter.labels.modifiedWithin7Days
        }, {
            value: 14,
            text: self.translations.filter.labels.modifiedWithin2Weeks
        }];
        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.spotlightListController
         */
        self.pageHeaderItem = {
            pageTitle: self.translations.spotlight.labels.spotlight,
            breadcrumbItems: [{
                linkTitle: self.translations.spotlight.labels.spotlight,
                link: "/spotlight-list"
            }]
        };

        /**
         * default current page
         * @type {int}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightListController
         */
        self.currentPage = 1;

        /**
         * page size
         * @type {int}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightListController
         */
        self.pageSize = DEFAULT_PAGE_SIZE;

        /**
         * filter sort options
         * @public
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.spotlightListController
         */
        self.filterSortOptions = [];

        /**
         * Get spotlight items
         * @function getSpotlightItems
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightListController
         */
        self.getSortOption = function () {
            self.filterSortOptions.push({
                type: "select",
                options: self.modifiedIntervals,
                propertyName: "modifiedInterval"
            }, {
                type: "select",
                options: self.sortOptions,
                propertyName: "sort"
            });
        };
        /**
         * Get Spotlight items
         * @function getSpotlightItems
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightListController
         * @param {object} selectedFilters selected filters
         */
        self.getSpotlightItems = function (selectedFilters) {
            var errorMessage = self.translations.newsList.messages.getNewsItemsError,
                filters = selectedFilters || {
                    modifiedInterval: 1000,
                    filmId: "",
                    sort: "lastModified,desc"
                };

            if (filters.modifiedInterval === "") {
                filters.modifiedInterval = 1000;
            }
            var hasNewSpotlights = localStorageService.get("hasNewSpotlights") ? localStorageService.get("hasNewSpotlights") : false;
            spotlightListService.getSpotlightItems(self.user.userId, hasNewSpotlights, self.pageSize, self.currentPage, filters.sort, filters.modifiedInterval, errorMessage).success(function (result) {
                self.spotlightItems = result;
            });
        };

        /**
         * Init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightListController
         */
        self.init = function () {
            self.getSortOption();
            localStorageService.set("ackSpotlights", true);
            self.getSpotlightItems();
        };

        //INIT
        self.init();
    }

    controllers.controller("spotlightListController", ["$rootScope", "$scope", "$uibModal", "spotlightListService", "localStorageService", "API_EFD", "DEFAULT_PAGE_SIZE", "deviceDetectorService", spotlightListController]);
}());
