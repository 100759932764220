(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");
    
    /**
     * @class modalDeleteSpotlightService
     * @memberOf angular_module.WB.cinehub.services
     * @desc delete spotlight service
     * @param {!angular.$http} $http angular $http service
     * @paarm {strict} API_ROOT API_ROOT constant  
     */
    function modalDeleteSpotlightService($http, API_ROOT) {

        /**
         * delete the spotlight item
         * @memberOf angular_module.WB.cinehub.services.modalDeleteSpotlightService
         * @public
         * @param {obj} spotlightItem spotlight item
         * @param {str} errorMessage error message
         * @param {str} alertKey alert key
         * @returns {promise}
         */
        this.removeSpotlight = function (spotlightItem, errorMessage, alertKey) {
            return $http.post(API_ROOT + "spotlightItems/deleteSpotlight", {
                "recordId" : spotlightItem.id,
                alertKey: alertKey
            }, {
                errorMessage : errorMessage
            });
        };
    }

    services.service("modalDeleteSpotlightService", ["$http", "API_ROOT", modalDeleteSpotlightService]);
}());