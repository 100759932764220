(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class permissionService
     * @desc permission service
     * @memberOf angular_module.WB.cinehub.services
     * @param {!angular.$http} $http angular $http service
     * @param {string} API_ROOT API_ROOT constant
     * @param {service} localStorageService local storage service
     * @param {service} locationService locationService
     */
    function permissionService($http, API_ROOT, localStorageService, locationService) {

        /**
         * Translations
         * @private
         * @memberOf angular_module.WB.cinehub.services.permissionService
         * @type {object}
         */
        var translations = localStorageService.get("translations"),

           /**
            * User information
            * @private
            * @memberOf angular_module.WB.cinehub.services.permissionService
            * @type {object}
            */
            user = localStorageService.get("userInfo");

        /**
         * Get permissions by asset ids
         * @function getPermissionsByAssetIds
         * @memberOf angular_module.WB.cinehub.services.permissionService
         * @public
         * @param {string} assetIds asset ids
         * @returns {promise}
         */
        this.getPermissionItemsByAssetIds = function (assetIds) {
            return $http.get(API_ROOT + "filmAssetPermissions/search/findAllTerritoryPermissionsByAssetId?assetId=" + assetIds + "&ignoreLocalizationString", {
                errorMessage: translations.permissionCart.messages.retrievePermissionsError
            });
        };

        /**
         * Get permissions by asset ids and territory id
         * @function getPermissionItemsByAssetTerritoryAndExhibitorId
         * @memberOf angular_module.WB.cinehub.services.permissionService
         * @public
         * @param {string} assetIds asset ids
         * @param {int} territoryId territory id
         * @param {int} exhibitorId exhibitor id
         * @returns {promise}
         */
        this.getPermissionItemsByAssetTerritoryAndExhibitorId = function (assetIds, territoryId, exhibitorId) {
            return $http.get(API_ROOT + "filmAssetPermissions/search/findByAssetTerritoryAndExhibitorId?assetId=" + assetIds + "&territoryId=" + territoryId + "&exhibitorId=" + exhibitorId + "&ignoreLocalizationString", {
                errorMessage: translations.permissionCart.messages.retrievePermissionsError,
                showSpinner: false
            });
        };

        /**
         * Get permissions by asset ids and territory id
         * @function getPermissionsByAssetIdAndByTerritoryIds
         * @memberOf angular_module.WB.cinehub.services.permissionService
         * @public
         * @param {string} assetIds asset ids
         * @param {int} territoryId territory id
         * @returns {promise}
         */
        this.getPermissionItemsByAssetIdsAndByTerritory = function (assetIds, territoryId) {
            return $http.get(API_ROOT + "filmAssetPermissions/search/findByAssetIdAndByTerritoryId?assetId=" + assetIds + "&territoryId=" + territoryId + "&ignoreLocalizationString", {
                errorMessage: translations.permissionCart.messages.retrievePermissionsError
            });
        };

        /**
         * Get all permissions by asset ids and territory id
         * @function getPermissionsByAssetIdAndByTerritoryIds
         * @memberOf angular_module.WB.cinehub.services.permissionService
         * @public
         * @param {string} assetIds asset ids
         * @param {int} territoryId territory id
         * @returns {promise}
         */
        this.getAllPermissionsByAssetAndTerritory = function (assetIds, territoryId) {
            return $http.get(API_ROOT + "filmAssetPermissions/search/findAllPermissionsByAssetAndTerritory?assetId=" + assetIds + "&territoryId=" + territoryId + "&ignoreLocalizationString", {
                errorMessage: translations.permissionCart.messages.retrievePermissionsError
            });
        };

        /**
         * Apply permissions
         * @function applyPermissions
         * @memberOf angular_module.WB.cinehub.services.permissionService
         * @public
         * @param {object} permissions permissions to update
         * @returns {promise}
         */
        this.applyPermissions = function (permissions) {
            return $http.post(API_ROOT + "filmAssetPermissions/" + (user.role === "homeOfficeAdmin" ? "hoa" : "ta") + "/applyPermissions", permissions, {
                errorMessage: translations.permissionCart.messages.retrievePermissionsError
            });
        };
        /**
         * Apply Group permissions
         * @function applyGroupPermissions
         * @memberOf angular_module.WB.cinehub.services.permissionService
         * @public
         * @param {object} permissions permissions to update
         * @returns {promise}
         */
        this.applyGroupPermissions = function (permissions) {
            return $http.post(API_ROOT + "filmAssetPermissions/" + (user.role === "homeOfficeAdmin" ? "hoa" : "ta") + "/applyGroupPermissions", permissions, {
                errorMessage: translations.permissionCart.messages.retrievePermissionsError
            });
        };

        /**
         * build region permissions json
         * @function buildRegionPermissionJson
         * @memberOf angular_module.WB.cinehub.services.permissionService
         * @public
         * @param {object} permissions permissions to update
         * @returns {promise}
         */
        this.buildRegionPermissionsJson = function () {
            self.activeRegions = locationService.getActiveRegions(translations.values.regions);
            var regions = _.clone(self.activeRegions),
                territories = _.clone(translations.values.territories);

            for (var i = 0; i < regions.length; i++) {
                var regionTerritories = _.filter(territories, {
                    extraCode: regions[i].id
                });
                regions[i].permissions = regionTerritories;
                regions[i].childCount = regionTerritories.length;
            }

            //for some reason angular service doesn't behave as singleton and sharing the same reference between 
            //permissionCartController and materialDetailsLightboxDirective for this reason we are making sure 
            //that service is going to return a new instance of data
            return _.cloneDeep(regions);
        };

        /**
         * build exhibitor permissions json
         * @function buildExhibitorPermissionsJson
         * @memberOf angular_module.WB.cinehub.services.permissionService
         * @public
         * @param {object[]} exhibitors list of exhibitors
         * @returns {promise}
         */
        this.buildExhibitorPermissionsJson = function (exhibitors) {
            var filteredExhibitors = _.filter(exhibitors, function (item) {
                return item.theaters.length > 0;
            });

            window.console.time("permissionService.buildExhibitorPermissionsJson for loop with clone");
            for (var i = 0; i < filteredExhibitors.length; i++) {
                filteredExhibitors[i].permissions = _.clone(filteredExhibitors[i].theaters);
                filteredExhibitors[i].childCount = filteredExhibitors[i].permissions.length;
                delete filteredExhibitors[i].theaters;
            }
            window.console.timeEnd("permissionService.buildExhibitorPermissionsJson for loop with clone");
            
            //for some reason angular service doesn't behave as singleton and sharing the same reference between 
            //permissionCartController and materialDetailsLightboxDirective for this reason we are making sure 
            //that service is going to return a new instance of data
            return _.cloneDeep(filteredExhibitors);
        };

    }

    services.service("permissionService", ["$http", "API_ROOT", "localStorageService", "locationService", permissionService]);
}());
