(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class whatsNewService
     * @memberOf angular_module.WB.cinehub.services
     * @desc faq service
     * @param {!angular.$http} $http angular $http service
     * @paarm {strict} API_ROOT API_ROOT constant  
     */
    function whatsNewService($http, API_ROOT, localStorageService) {

        /**
         * Location
         * @type {object}
         * @private
         * @memberOf angular_module.WB.cinehub.services.whatsNewService
         */
        var location = null,

            /**
             * exhibitor id
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.services.whatsNewService
             */
            exhibitorId = null,

            /**
             * theater id
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.services.whatsNewService
             */
            theaterId = null;

        /**
         * get tab count
         * @memberOf angular_module.WB.cinehub.services.whatsNewService
         * @public
         * @function getTabCount
         * @param {int} updatedFilmsDays past days for updated films
         * @param {int} newMaterialsDays past days for new materials
         * @param {int} updatedMaterialsDays past days for updated materials
         * @param {string} errorMessage error message 
         * @returns {promise}
         */
        this.getTabCount = function (updatedFilmsDays, newMaterialsDays, newMaterialsFilmId, newMaterialsWbAssetType, newMaterialsWbpiAssetType, newMaterialsFileType,
                updatedMaterialsDays,  updatedMaterialsFilmId, updatedMaterialsWbAssetType, updatedMaterialsWbpiAssetType, updatedMaterialsFileType, errorMessage) {
            location = localStorageService.get("location");
            exhibitorId = localStorageService.get("currentExhibitorAffiliation") || -1;
            theaterId = localStorageService.get("currentTheaterAffiliation") || -1;
            return $http.get(API_ROOT + "whatsNew/getHeaderCount?filmDaysRange=" + updatedFilmsDays + "&newAssetDaysRange=" + newMaterialsDays + "&updatedAssetDaysRange=" + updatedMaterialsDays + 
                "&territoryId=" + location.territoryId + "&localeId=" + location.localeId + "&exhibitorId=" + exhibitorId + "&theaterId=" + theaterId +
                "&newMaterialsFilmId=" + newMaterialsFilmId + "&newMaterialsAssetType=" + newMaterialsWbAssetType + "&newMaterialsAssetSubType=" + newMaterialsWbpiAssetType + "&newMaterialsFileType=" + newMaterialsFileType +
                "&updatedMaterialsFilmId=" + updatedMaterialsFilmId + "&updatedMaterialsAssetType=" + updatedMaterialsWbAssetType + "&updatedMaterialsAssetSubType=" +
                updatedMaterialsWbpiAssetType + "&updatedMaterialsFileType=" +
                updatedMaterialsFileType, { errorMessage: errorMessage, showSpinner: false });
        };

        /**
         * get new and updated titles
         * @memberOf angular_module.WB.cinehub.services.whatsNewService
         * @public
         * @function getNewAndUpdatedTitles
         * @param {int} days days modified within
         * @param {int} recType rec type orig/pub/both
         * @param {int} page page
         * @param {int} size size
         * @param {string} sort sort order
         * @param {string} secondarySort secondary sort
         * @param {string} errorMessage error message 
         * @returns {promise}
         */
        this.getNewAndUpdatedTitles= function (days, recType, page, size, sort, secondarySort, errorMessage) {
            var sortString = secondarySort ? "&sort=" + sort + "&sort=" + secondarySort : "&sort=" + sort;
            location = localStorageService.get("location");
            exhibitorId = localStorageService.get("currentExhibitorAffiliation") || -1;
            theaterId = localStorageService.get("currentTheaterAffiliation") || -1;
            return $http.get(API_ROOT + "films/search/findAllLocNewUpdatedFilmsByExhibitorByTheaterNDays?days=" + days + "&territoryId=" + location.territoryId + "&localeId=" + location.localeId +
                "&exhibitorId=" + exhibitorId + "&theaterId=" + theaterId + "&recType=pub&page=" + page + "&size=" + size + sortString, { errorMessage: errorMessage });
        };
        
        /**
         * get newly available assets for exhibitor and theater users
         * @memberOf angular_module.WB.cinehub.services.whatsNewService
         * @public
         * @function getNewAssetsForExhibitorsAndTheaters
         * @param {int} past days
         * @param {int} page page
         * @param {int} size size
         * @param {string} sort sort order
         * @param {string} secondarySort secondary sort
         * @param {string} errorMessage error message 
         * @returns {promise}
         * @todo Get This endpoint from Murthy ***********************************
         */
        this.getNewAssetsForExhibitorsAndTheaters = function (days, filmId, wbAssetType, wbpiAssetType, fileType, page, size, sort, secondarySort, errorMessage) {
            var sortString = secondarySort ? "&sort=" + sort + "&sort=" + secondarySort + "&sort=permissionId" : "&sort=" + sort;
            location = localStorageService.get("location");
            exhibitorId = localStorageService.get("currentExhibitorAffiliation") || -1;
            theaterId = localStorageService.get("currentTheaterAffiliation") || -1;
            return $http.post(API_ROOT + "filmAssets/search/findLocNewFilmAssetsByExhibitorAndByTheaterAndByNDays?size=" + size + "&page=" + page + sortString, {
                "filmId": filmId,
                "wbAssetType": wbAssetType,
                "wbpiAssetType": wbpiAssetType,
                "fileType": fileType,
                "days": days,
                "territoryId": location.territoryId,
                "localeId": location.localeId,
                "exhibitorId": exhibitorId,
                "theaterId": theaterId
            }, { errorMessage: errorMessage });
        };

        /**
         * Get filter values
         * @memberOf angular_module.WB.cinehub.services.whatsNewService
         * @public
         * @function getFiltersValuesForUnpublishedMaterials
         * @param {int} filmId film id
         * @param {string} wbAssetType wb asset type
         * @param {string} wbpiAssetType wbpi asset type
         * @returns {promise}
         */
        this.getFiltersValuesForNewMaterials = function (days, filmId, wbAssetType, wbpiAssetType, fileType, errorMessage) {
            location = localStorageService.get("location");
            exhibitorId = localStorageService.get("currentExhibitorAffiliation") || -1;
            theaterId = localStorageService.get("currentTheaterAffiliation") || -1;
            return $http.post(API_ROOT + "filmAssets/findLocFilmsAndAssetTypeAndSubtypeForNewAssets", {
                "filmId": filmId,
                "wbAssetType": wbAssetType,
                "wbpiAssetType": wbpiAssetType,
                "fileType": fileType,
                "days": days,
                "territoryId": location.territoryId,
                "localeId": location.localeId,
                "exhibitorId": exhibitorId,
                "theaterId": theaterId
            }, {
                errorMessage: errorMessage,
                showSpinner: true
            });
        };

        /**
         * get updated assets for exhibitor and theater users
         * @memberOf angular_module.WB.cinehub.services.whatsNewService
         * @public
         * @function getUpdatedAssetsForExhibitorsAndTheaters
         * @param {int} days past days
         * @param {int} page page
         * @param {int} size size
         * @param {string} sort sort order
         * @param {string} secondarySort secondary sort
         * @param {string} errorMessage error message 
         * @returns {promise}
         */
        this.getUpdatedAssetsForExhibitorsAndTheaters = function (days, filmId, wbAssetType, wbpiAssetType, fileType, page, size, sort, secondarySort, errorMessage) {
            var sortString = secondarySort ? "&sort=" + sort + "&sort=" + secondarySort : "&sort=" + sort;
            location = localStorageService.get("location");
            exhibitorId = localStorageService.get("currentExhibitorAffiliation") || -1;
            theaterId = localStorageService.get("currentTheaterAffiliation") || -1;
            return $http.post(API_ROOT + "filmAssets/search/findLocUpdatedFilmAssetsByExhibitorAndByTheaterAndByNDays?size=" + size + "&page=" + page + sortString, {
                "filmId": filmId,
                "wbAssetType": wbAssetType,
                "wbpiAssetType": wbpiAssetType,
                "fileType": fileType,
                "days": days,
                "territoryId": location.territoryId,
                "localeId": location.localeId,
                "exhibitorId": exhibitorId,
                "theaterId": theaterId
            }, { errorMessage: errorMessage });
        };

        /**
         * Get filter values
         * @memberOf angular_module.WB.cinehub.services.whatsNewService
         * @public
         * @function getFiltersValuesForUpdatedMaterials
         * @param {int} filmId film id
         * @param {string} wbAssetType wb asset type
         * @param {string} wbpiAssetType wbpi asset type
         * @returns {promise}
         */
        this.getFiltersValuesForUpdatedMaterials = function (days, filmId, wbAssetType, wbpiAssetType, fileType, errorMessage) {
            location = localStorageService.get("location");
            exhibitorId = localStorageService.get("currentExhibitorAffiliation") || -1;
            theaterId = localStorageService.get("currentTheaterAffiliation") || -1;
            return $http.post(API_ROOT + "filmAssets/findLocFilmsAndAssetTypeAndSubtypeForUpdatedAssets", {
                "filmId": filmId,
                "wbAssetType": wbAssetType,
                "wbpiAssetType": wbpiAssetType,
                "fileType": fileType,
                "days": days,
                "territoryId": location.territoryId,
                "localeId": location.localeId,
                "exhibitorId": exhibitorId,
                "theaterId": theaterId
            }, {
                errorMessage: errorMessage,
                showSpinner: true
            });
        };
    }

    services.service("whatsNewService", ["$http", "API_ROOT", "localStorageService", whatsNewService]);
} ());
