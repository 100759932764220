(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");
    
    /**
     * @class spotlightListService
     * @memberOf angular_module.WB.cinehub.services
     * @desc spotlight list service
     * @param {!angular.$http} $http angular $http service
     * @paarm {strict} API_ROOT API_ROOT constant  
     */
    function spotlightListService($http, API_ROOT) {

        /**
         * Get spotlight items
         * @memberOf angular_module.WB.cinehub.services.spotlightListService
         * @public
         * @function getSpotlightItems
         * @param {string} userId userId
         * @param {boolean} hasNewSpotlights hasNewSpotlightsnflag
         * @param {int} size size
         * @param {int} page page
         * @param {string} sort sort order
         * @param {string} errorMessage error message 
         * @returns {promise}
         */        
        this.getSpotlightItems = function (userId, hasNewSpotlights, size, page, sort, pastDays, errorMessage) {
            return $http.get(API_ROOT + "spotlightItems/search/findByTerritoryId?userId=" + userId + "&hasNewSpotlights=" + hasNewSpotlights + "&pastDays=" + pastDays + "&page=" + page + "&size=" + size + "&sort=" + sort, { errorMessage: errorMessage });
        };
    }

    services.service("spotlightListService", ["$http", "API_ROOT", spotlightListService]);
}());
