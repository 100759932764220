(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Search users directive
     * @class searchUsers
     * @memberOf angular_module.WB.cinehub.directives
     * @param {!angular.rootScope} $rootScope angular root scope
     * @param {service} translateService local storage service
     * @param {service} exhibitorsService exhibitors service
     * @param {service} territoriesService territory service
     * @param {service} theatersService theaters service
     * @param {service} userService user service
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     * @returns {directiveDefinitionObj}
     */
    function searchUsers($rootScope, localStorageService, exhibitorsService, territoriesService, theatersService, userService, DEFAULT_PAGE_SIZE) {

        /**
         * Location info
         * @type {object}
         * @private
         * @memberOf angular_module.WB.cinehub.directives.searchUsers
         */
        var location = localStorageService.get("location");

        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @private
         * @memberOf angular_module.WB.cinehub.directives.searchUsers
         */
        var link = function (scope) {

            /**
             * Translations
             * @type {object}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.searchUsers
             */
            scope.translations = localStorageService.get("translations");

            /**
             * User info object
             * @type {object}
             * @memberOf angular_module.WB.cinehub.directives.searchUsers
             * @public
             */
            scope.user = localStorageService.get("userInfo");

            /**
             * Is expanded filter
             * @type {boolean}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.searchUsers
             */
            scope.isExpandedFilter = false;

            /**
             * Exhibitors service
             * @type {service}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.searchUsers
             */
            scope.exhibitorsService = exhibitorsService;

            /**
             * Territories service
             * @memberOf angular_module.WB.cinehub.directives.searchUsers
             * @public
             * @type {service}
             */
            scope.territoriesService = territoriesService;

            /**
             * Theaters service
             * @memberOf angular_module.WB.cinehub.directives.searchUsers
             * @public
             * @type {service}
             */
            scope.theatersService = theatersService;

            /**
             * Get territory by directive
             * @function getTerritoryById
             * @private
             * @memberOf angular_module.WB.cinehub.directives.searchUsers
             * @param {int} territoryId territory id
             */
            var setTerritoryById = function (territoryId) {
                scope.territory = scope.translations.values.territories
                    .filter(function (t) {
                        return t.id === territoryId;
                    })
                    .map(function (t) {
                        return angular.merge({}, { "name": t.value }, t);
                    })
                    .reduce(function(acc, curr){
                        return curr;
                    });
            };

            /**
             * Set territory
             * @function setTerritory
             * @memberOf angular_module.WB.cinehub.directives.searchUsers
             * @private
             */
            var setTerritory = function () {
                if (scope.user.role === "homeOfficeAdmin") {
                    scope.searchUsers();
                } else {
                    setTerritoryById(location.territoryId);
                    scope.searchUsers();
                }
            };

            /**
             * Create query string
             * @private
             * @function createQueryString
             * @memberOf angular_module.WB.cinehub.directives.searchUsers
             */
            var createQueryString = function () {
                var userTypes = (scope.userTypeWb ? "wb," : "") + (scope.userTypeWBLicenseeJointVentureOther ? "wb-other," : "") + (scope.userTypeExhibitorCorporate ? "ex-corporate," : "") + (scope.userTypeExhibitorTheater ? "ex-theater," : "") + (scope.userTypeExhibitorVendorBookerAgencyPartner ? "ex-other," : ""),
                    statuses = (scope.statusPendingEmailVerification ? "pendingEmailVerification," : "") + (scope.statusPendingApproval ? "pendingApproval," : "") + (scope.statusRejected ? "rejected," : "") + (scope.statusActive ? "active," : "") + (scope.statusPendingAccountVerification ? "pendingAccountVerification," : "") + (scope.statusPendingAdminRegisteredVerification ? "pendingAdminRegisteredVerification," : "") + (scope.statusInactive ? "inactive," : ""),
                    territoryId = scope.territory && scope.territory.id ? scope.territory.id : "",
                    exhibitorId = "",
                    theaterId = "";

                if (scope.user.role === "homeOfficeAdmin") {
                    exhibitorId = scope.allExhibitors && scope.allExhibitors.id ? scope.allExhibitors.id : "";
                    theaterId = scope.allTheaters && scope.allTheaters.id ? scope.allTheaters.id : "";
                } else {
                    exhibitorId = scope.exhibitor && scope.exhibitor.id ? scope.exhibitor.id : "";
                    theaterId = scope.theater && scope.theater.id ? scope.theater.id : "";
                }

                scope.filterQuery = "userSearch=" + encodeURIComponent(scope.userSearch) + "&userTypes=" + userTypes.slice(0, -1) + "&territoryId=" + territoryId + "&exhibitorId=" + exhibitorId + "&theaterId=" + theaterId + "&statuses=" + statuses.slice(0, -1);
            };

            /**
             * Init
             * @function init
             * @memberOf angular_module.WB.cinehub.directives.searchUsers
             * @private
             */
            var init = function () {
                scope.resetUserFilters();
                setTerritory();
            };

            /**
             * Reset user filters
             * @public
             * @memberOf angular_module.WB.cinehub.directives.searchUsers
             * @function resetUserFilters
             */
            scope.resetUserFilters = function () {
                scope.userSearch = "";
                scope.userTypeWb = false;
                scope.userTypeWBLicenseeJointVentureOther = false;
                scope.userTypeExhibitorCorporate = false;
                scope.userTypeExhibitorTheater = false;
                scope.userTypeExhibitorVendorBookerAgencyPartner = false;
                scope.exhibitor = "";
                scope.theater = "";
                scope.allExhibitors = "";
                scope.allTheaters = "";
                scope.statusPendingEmailVerification = false;
                scope.statusPendingApproval = false;
                scope.statusRejected = false;
                scope.statusActive = false;
                scope.statusLocked = false;
                scope.statusInactive = false;

                if (scope.user.role === "homeOfficeAdmin") {
                    scope.territory = "";
                }
                scope.filteredUsers = {
                    content: [],
                    first: true,
                    last: false,
                    number: 0,
                    numberOfElements: 0,
                    size: scope.pageSize || DEFAULT_PAGE_SIZE,
                    sort: [],
                    totalElements: 0,
                    totalPages: 0
                };
            };

            /**
             * Search users
             * @function searchUsers
             * @memberOf angular_module.WB.cinehub.directives.searchUsers
             * @public
             */
            scope.searchUsers = function () {
                var page = 0,
                    size = scope.pageSize || DEFAULT_PAGE_SIZE;
                createQueryString();
                userService.searchUsersAndParse(scope.filterQuery, page, size, scope.sortQuery).success(function (response) {
                    scope.filteredUsers = response;
                });
            };

            /**
             * Search territories by search term
             * @function searchTerritoriesBySearchTerm
             * @memberOf angular_module.WB.cinehub.directives.searchUsers
             * @param {string} searchTerm search term
             * @public
             */
            scope.searchTerritoriesBySearchTerm = function (searchTerm) {
                return scope.translations.values.territories
                    .filter(function (t) {
                        return t.value.toUpperCase().indexOf((searchTerm || "").toUpperCase()) === 0;
                    })
                    .map(function (t) {
                        return angular.merge({"name": t.value}, t);
                    });
            };

            init();
        };

        return {
            replace: true,
            link: link,
            scope: {
                filteredUsers: "=",
                filterQuery: "=",
                sortQuery: "=",
                pageSize: "="
            },
            templateUrl: "searchUsersTemplate.html"
        };
    }

    directives.directive("searchUsers", ["$rootScope", "localStorageService", "exhibitorsService", "territoriesService", "theatersService", "userService", "DEFAULT_PAGE_SIZE", searchUsers]);
} ());
