(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Lightbox directive
     * @class lightboxDirective
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     */
    function lightboxDirective() {
        return {
            restrict: "E", // applied on 'element'
            transclude: true, // re-use the inner HTML of the directive
            template: "<section ng-transclude></section>" // need this so that inner HTML will be used
        };
    }

    directives.directive("lightboxDirective", [lightboxDirective]);

}());
