(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * privacy controller
     * @dec privacy controller 
     * @class privacyPolicyController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {service} privacyPolicyService privacy policy service
     * @param {service} localStorageService local storage service
     * @param {constant} US_TERRITORY_ID US_TERRITORY_ID
     * @param {constant} US_LOCALE_ID US_LOCALE_ID
     */
    function privacyPolicyController(privacyPolicyService, localStorageService, US_TERRITORY_ID, US_LOCALE_ID) {

        /**
         * Instance of privacyPolicyController
         * @type {privacyPolicyController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.privacyPolicyController
         */
        var self = this,
        
           /**
            * User info object
            * @type {object}
            * @memberOf angular_module.WB.cinehub.controllers.privacyPolicyController
            * @private
            */
            user = localStorageService.get("userInfo"),

           /**
            * territory info object for registration flow
            * @type {object}
            * @memberOf angular_module.WB.cinehub.controllers.privacyPolicyController
            * @private
            */
            territory = localStorageService.get("territoryInfo");

        /**
         * translations
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.privacyPolicyController
         * @public 
         */
        self.translations = localStorageService.get("translations");
        
        /**
         * location
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.privacyPolicyController
         * @public 
         */
        self.location = localStorageService.get("location");

        /**
         * browserLanguage
         * @type {object}   
         * @memberOf angular_module.WB.cinehub.controllers.privacyPolicyController
         * @public
         */
        self.browserLanguage = localStorageService.get("browserLanguage");
        
        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.privacyPolicyController
         */
        self.pageHeaderItem = {
            pageTitle: self.translations.privacyPolicy.labels.pageTitle,
            breadcrumbItems: [{
                linkTitle: self.translations.privacyPolicy.labels.breadcrumbLinkTitle,
                link: "/privacy-policy"
            }]
        };

        /**
         * privacy policy
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.privacyPolicyController
         */
        self.privacyPolicy = {};

        /**
         * get privacy
         * @function getPrivacyPolicy 
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.privacyPolicyController
         */
        self.getPrivacyPolicy = function () {
            var userTerritoryId = self.location && self.location.territoryId || user && user.territoryId || territory && territory.territoryId,
                userLocaleId = self.location && self.location.localeId || user && user.localeId || territory && territory.localeId,
                territoryId = userTerritoryId || US_TERRITORY_ID,
                localeId = userLocaleId || US_LOCALE_ID;
            privacyPolicyService.getPrivacyPolicy(territoryId, localeId).success(function (result) {
                self.privacyPolicy = result._embedded.privacies[0];
                if (self.privacyPolicy == undefined) {
                    privacyPolicyService.getPrivacyPolicy(US_TERRITORY_ID, US_LOCALE_ID).success(function (result) {
                        self.privacyPolicy = result._embedded.privacies[0];
                    });
                }
            });
        };

        self.init = function () {
            self.getPrivacyPolicy();
        };

        //INIT
        self.init();
    }

    controllers.controller("privacyPolicyController", ["privacyPolicyService", "localStorageService", "US_TERRITORY_ID", "US_LOCALE_ID", privacyPolicyController]);
}());
