(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");
    
    /**
     * @class activityHistoryService
     * @memberOf angular_module.WB.cinehub.services
     * @desc activity history service 
     * @param {!angular.$http} $http angular $http service
     * @param {strict} API_ROOT API_ROOT constant 
     * @param {service} localStorageService local storage service 
     */
    function activityHistoryService($http, API_ROOT, localStorageService) {

        /**
         * translations
         * @type {object}
         * @memberOf angular_module.WB.cinehub.services.activityHistoryService
         * @private
         */
        var translations = localStorageService.get("translations");

        /**
         * get activity histories
         * @memberOf angular_module.WB.cinehub.services.activityHistoryService
         * @public
         * @param {str} tableName table name
         * @param {int} tableId table id
         * @param {str} page page index
         * @param {str} size page size
         * @param {str} sort sorting
         * @param {str} errorMessage error message
         * @returns {promise}
         */
        this.getActivityHistories = function (tableName, tableId, page, size, sort, errorMessage) {
            return $http.get(API_ROOT + "activityHistories/search/findLocByTableNameAndTableId?tableName=" + tableName + "&tableId=" + tableId + "&page=" + page + "&size=" + size + "&sort=" + sort, { errorMessage: errorMessage });
        };

        /**
         * get EIS updated fields
         * @memberOf angular_module.WB.cinehub.services.activityHistoryService
         * @public
         * @param {string} tableName table name ether film or film_asset
         * @param {int} tableId table id
         * @param {str} publishDate publish date
         * @returns {promise}
         */
        this.getEisUpdatedFields = function (tableName, tableId, publishDate) {
            return $http.get(API_ROOT + "activityHistory/findEisChangesByTableNameAndTableId?tableName=" + tableName + "&tableId=" + tableId + "&publishDate=" + publishDate + "&page=0&size=10000&sort=lastModified,desc", { errorMessage: translations.filmDetails.messages.getActivityHistoryError });
        };
    }

    services.service("activityHistoryService", ["$http", "API_ROOT", "localStorageService", activityHistoryService]);
}());
