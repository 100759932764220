(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Modal review permissions controller
     * @dec The modal review permissions controller
     * @class modalReviewPermissionsController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {$uibModal} $uibModal uib modal
     * @param {$uibModalInstance} $uibModalInstance modal instance
     * @param {service} localStorageService local storage service
     * @param {object[]} permissionQueueItems permission queue items
     * @param {object[]} permissions permissions
     * @param {object[]} rawPermissionData raw permission data
     * @param {service} permissionService permission service
     */
    function modalReviewPermissionsController($rootScope,$uibModal, $uibModalInstance, localStorageService, permissionQueueItems, permissions, rawPermissionData, groupSelect, isGroupUpdate, templateAsset, permissionService) {

        /**
         * Instance of modalReviewPermissionsController
         * @type {modalReviewPermissionsController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalReviewPermissionsController
         */
        var self = this,

            /**
             * permission constant
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.modalReviewPermissionsController
             */
            permissionConstant = {
                none: "NONE",
                view_and_download: "VIEW_AND_DOWNLOAD",
                view: "VIEW"
            },

            /**
             * Location
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.modalReviewPermissionsController
             */
            location = localStorageService.get("location");

        /**
         * Permissions local copy 
         * @type {object[]}
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalReviewPermissionsController
         * //public only because unit tests but it is local in controller scope
         */
        self.permissionsLocalCopy = _.cloneDeep(permissions);

        /**
         * User info
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.modalReviewPermissionsController
         */
        self.user = localStorageService.get("userInfo");

        /**
         * Translations
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.modalReviewPermissionsController
         */
        self.translations = localStorageService.get("translations");

        /**
         * Permission queue items
         * @public
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.modalReviewPermissionsController
         */
        self.permissionQueueItems = permissionQueueItems;

        /**
         * Permission has changed
         * @public
         * @type {boolean}
         * @memberOf angular_module.WB.cinehub.controllers.modalReviewPermissionsController
         */
        self.isPermissionChanged = permissions.length;

        /**
         * Permissions
         * @public
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.modalReviewPermissionsController
         */
        self.permissions = _.cloneDeep(permissions);

        /**
         * Is expanded all flag
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalReviewPermissionsController
         * @type {boolean}
         */
        self.isExpandedAll = false;

        /**
         * Show no permissions
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalReviewPermissionsController
         * @type {boolean}
         */
        self.showNoPermission = false;

        /**
         * Permission data 
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalReviewPermissionsController
         * @type {object[]}
         * // this is expose as public var only for unit tests case
         */
        self.permissionData = [];

        /**
         * Raw permission data
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalReviewPermissionsController
         * @type {object[]}
         * // this is expose as public var only for unit tests case
         */
        self.rawPermissionData = _.cloneDeep(rawPermissionData);

        self.groupSelected = groupSelect;


        /**
         * bulk update flag set for select group permission action
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         * @type {boolean}
         */
        self.isGroupUpdate = isGroupUpdate;

        /**
         * asset selected to use as template
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         * @type {string}
         */
        self.templateAsset = templateAsset;
        /**
         * Create permission save data
         * @function createPermissionSaveData
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalReviewPermissionsController
         * @returns {object[]}
         */
        var createPermissionSaveData = function () {
            var permissionSaveItem = {},
                exhibitorLevelPermission = {};
            self.savePermissionData = [];
            self.permissionData = [];
            var isGroupPermission = self.isGroupUpdate ? "Y" : "N";

            self.permissionQueueItems.forEach(function (item) {
                self.rawPermissionData.forEach(function (permission) {
                    permissionSaveItem = {
                        assetId: item.assetId,
                        regionId: self.user.role === "homeOfficeAdmin" ? permission.extraCode : permission.regionId,
                        territoryId: self.user.role === "homeOfficeAdmin" ? permission.code : location.territoryId,
                        exhibitorId: self.user.role === "homeOfficeAdmin" ? "-1" : permission.exhibitorId,
                        theaterId: self.user.role === "homeOfficeAdmin" ? "-1" : permission.id,
                        permission: permission.permission,
                        lastModified: moment().utc().format(),
                        modifier: self.user.userId
                    };
                    self.savePermissionData.push(permissionSaveItem);
                });

                //insert exhibitor level permissions taking the highest permission out of its theater permissions
                if (self.user.role === "territoryAdmin") {

                    self.permissionsLocalCopy.forEach(function (exhibitor) {
                        exhibitorLevelPermission = {
                            assetId: item.assetId,
                            territoryId: exhibitor.territoryId,
                            exhibitorId: exhibitor.id,
                            theaterId: "-1",
                            lastModified: moment().utc().format(),
                            modifier: self.user.userId
                        };

                        if (exhibitor.permissions && exhibitor.permissions.hasOwnProperty(permissionConstant.view_and_download)) {
                            exhibitorLevelPermission.permission = permissionConstant.view_and_download;
                        } else if (exhibitor.permissions && exhibitor.permissions.hasOwnProperty(permissionConstant.view)) {
                            exhibitorLevelPermission.permission = permissionConstant.view;
                        } else {
                            exhibitorLevelPermission.permission = permissionConstant.none;
                        }
                        self.savePermissionData.push(exhibitorLevelPermission);
                    });
                }
            });

            self.permissionData = {savedPermissions:self.savePermissionData, isGroupPermission:isGroupPermission, templateAssetId:self.templateAsset.assetId};
            return self.permissionData;
        };
        /**
         * Create permission save data
         * @function createPermissionSaveData
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalReviewPermissionsController
         * @returns {object[]}
         */
        var createGroupPermissionSaveData = function () {
            var permissionSaveItem = {},
                exhibitorLevelPermission = {};
            self.saveGroupPermissionData = [];
            self.saveAssetData = [];
            var firstIteration = true;
            var isGroupPermission = self.isGroupUpdate ? "Y" : "N";

            self.permissionQueueItems.forEach(function (item) {
                if (firstIteration) {
                    self.rawPermissionData.forEach(function (permission) {
                        permissionSaveItem = {
                            assetId: item.assetId,
                            regionId: self.user.role === "homeOfficeAdmin" ? permission.extraCode : permission.regionId,
                            territoryId: self.user.role === "homeOfficeAdmin" ? permission.code : location.territoryId,
                            exhibitorId: self.user.role === "homeOfficeAdmin" ? "-1" : permission.exhibitorId,
                            theaterId: self.user.role === "homeOfficeAdmin" ? "-1" : permission.id,
                            permission: permission.permission,
                            lastModified: moment().utc().format(),
                            modifier: self.user.userId
                        };
                        self.saveGroupPermissionData.push(permissionSaveItem);
                        
                    });
                    //insert exhibitor level permissions taking the highest permission out of its theater permissions
                    if (self.user.role === "territoryAdmin") {

                        self.permissionsLocalCopy.forEach(function (exhibitor) {
                            exhibitorLevelPermission = {
                                assetId: item.assetId,
                                territoryId: exhibitor.territoryId,
                                exhibitorId: exhibitor.id,
                                theaterId: "-1",
                                lastModified: moment().utc().format(),
                                modifier: self.user.userId
                            };

                            if (exhibitor.permissions && exhibitor.permissions.hasOwnProperty(permissionConstant.view_and_download)) {
                                exhibitorLevelPermission.permission = permissionConstant.view_and_download;
                            } else if (exhibitor.permissions && exhibitor.permissions.hasOwnProperty(permissionConstant.view)) {
                                exhibitorLevelPermission.permission = permissionConstant.view;
                            } else {
                                exhibitorLevelPermission.permission = permissionConstant.none;
                            }
                            self.saveGroupPermissionData.push(exhibitorLevelPermission);
                        });
                    }
                    firstIteration = false;

                }
                self.saveAssetData.push(item.assetId);
            });
            self.savedGroupPermissionData = {savedPermissions:self.saveGroupPermissionData,assetIds:self.saveAssetData, isGroupPermission:isGroupPermission, templateAssetId:self.templateAsset.assetId};
            return self.savedGroupPermissionData;
        };
        /**
         * Cancel
         * @function cancel
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalReviewPermissionsController
         */
        self.cancel = function () {
            $uibModal.open({
                templateUrl: "confirmationTemplate.html",
                controller: "modalConfirmationController",
                controllerAs: "modalConfirmationCtrl",
                keyboard: false,
                backdrop: "static",
                size: "md",
                resolve: {
                    message: function () {
                        return self.translations.reviewPermissions.messages.confirmationMessage;
                    },
                    headerTitle: function () {
                        return self.translations.reviewPermissions.messages.warning;
                    },
                    showAffirmativeBtn: function () {
                        return true;
                    },
                    affirmativeBtnText: function () {
                        return self.translations.global.labels.yesButton;
                    },
                    cancelBtnText: function () {
                        return self.translations.global.labels.noButton;
                    }
                }
            }).result.then(function () {
                $uibModalInstance.dismiss();
            });
        };

        /**
         * Apply permissions
         * @function applyPermissions
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalReviewPermissionsController
         */
        self.applyPermissions = function () {
            var permissions;
            if((self.groupSelected || self.permissionQueueItems.length > 1 ) && self.user.role === "territoryAdmin"){
                permissions = createGroupPermissionSaveData();
                permissionService.applyGroupPermissions(permissions).success(function () {
                    $uibModalInstance.close();
                });
            }
            else{
                permissions = createPermissionSaveData();
                permissionService.applyPermissions(permissions).success(function () {
                    $uibModalInstance.close();
                });
            }
            
        };
        
    }

    controllers.controller("modalReviewPermissionsController", ["$rootScope", "$uibModal", "$uibModalInstance", "localStorageService", "permissionQueueItems", "permissions", "rawPermissionData", "groupSelect", "isGroupUpdate", "templateAsset", "permissionService", modalReviewPermissionsController]);
}());
