(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class privacyPolicyService
     * @memberOf angular_module.WB.cinehub.services
     * @desc privacy service
     * @param {!angular.$http} $http angular $http service
     * @param {object} $window window object
     * @param {strict} API_ROOT API_ROOT constant  
     * @param {service} localStorageService local storage service
     * @param {constant} PRIVACY_POLICY_URL privacy policy Url
     * @param {constant} WM_LOCALE_SUFFIX default suffix for privacy policy
     */
    function privacyPolicyService($http, $window, API_ROOT, localStorageService, PRIVACY_POLICY_URL, WM_LOCALE_SUFFIX) {

        /**
         * translations
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.privacyPolicyService
         * @public
         */
        var translations = localStorageService.get("translations");

        /**
         * browserLanguage
         * @type {object}   
         * @memberOf angular_module.WB.cinehub.controllers.privacyPolicyService
         * @public
         */
        var browserLanguage = localStorageService.get("browserLanguage");

        /**
         * Locales object
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.privacyPolicyService
         */
        var liveLocales = localStorageService.get("liveLocales");

        /**
         * get privacy
         * @memberOf angular_module.WB.cinehub.services.privacyPolicyService
         * @public
         * @function getPrivacyPolicy
         * @returns {promise}
         */
        this.getPrivacyPolicy = function (territoryId, localeId) {
            return $http.get(API_ROOT + "privacies/search/findByTerritoryIdAndLocaleId?territoryId="+territoryId+"&localeId="+localeId, {
                errorMessage: translations.privacyPolicy.messages.getPrivacyPolicyError
            });
        };

        /**
         * get Privacy Policy URL
         * @memberOf angular_module.WB.cinehub.services.privacyPolicyService
         * @function getWMPrivacyPolicyUrl
         * @public
         */
        this.getWMPrivacyPolicyUrl = function (location) {
            var locale = (location && location.locale) || browserLanguage;
            var wmLocaleSuffix = WM_LOCALE_SUFFIX;
            if(locale) {
                var userLocale = _.find(liveLocales, {
                    abbreviation: locale
                });
                wmLocaleSuffix = userLocale && userLocale.wmLocaleSuffix ? userLocale.wmLocaleSuffix : WM_LOCALE_SUFFIX;
            }
            $window.open(PRIVACY_POLICY_URL + wmLocaleSuffix, "_blank");

        };
    }

    services.service("privacyPolicyService", ["$http", "$window", "API_ROOT", "localStorageService", "PRIVACY_POLICY_URL", "WM_LOCALE_SUFFIX", privacyPolicyService]);
}());
