(function () {
    "use strict";

    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Login controller
     * @class passwordExpiredController
     * @memberOf angular_module.WB.cinehub.controllers.passwordExpiredController
     * @desc This is Password Expired controller
     * @param {service} $cookies $cookies service
     * @param {$window} $window $window instance
     * @param {service} localStorageService local storage service
     * @param {service} loginService login service
     * @param {service} translateService translate service
     * @param {constant} OAUTH_SESSION_LIFETIME oauth session lifetime
     * @param {service} helpContactService help contact service
     * @param {service} languageService language service
     */
    function passwordExpiredController($cookies, $window, localStorageService, loginService, translateService, OAUTH_SESSION_LIFETIME, helpContactService, languageService) {

        /**
         * Instance to passwordExpiredController
         * @memberOf angular_module.WB.cinehub.controllers.passwordExpiredController
         * @private
         * @type {passwordExpiredController}
         */
        var self = this,

            /**
             * Browser language
             * @type {string}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.passwordExpiredController
             */
            browserLanguage = "en-US";

        /**
         * translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.passwordExpiredController
         */
        self.translations = localStorageService.get("translations");

        /**
         * cookies enabled flag
         * @memberOf angular_module.WB.cinehub.controllers.passwordExpiredController
         * @public
         * @type {bool}
         */
        self.cookiesEnabled = true;

        /**
         * disable login button
         * @memberOf angular_module.WB.cinehub.controllers.passwordExpiredController
         * @public
         * @type {boolean}
         */
        self.disableLoginBtn = false;

        /**
         * loginName
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.passwordExpiredController
         */
        self.user = localStorageService.get("userInfo");

        /**
         * Load translations
         * @function loadTranslations
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.passwordExpiredController
         * @returns {Promise}
         */
        var loadTranslations = function () {
            return translateService.loadTranslations(browserLanguage, "global.messages.loadTranslationsError").success(function (result) {
                self.translations = result;
                localStorageService.set("translations", result);
                localStorageService.set("loadedTranslationsForLocale", browserLanguage);
            });
        };

        /**
         * Get user info
         * @function getUserInfo
         * @memberOf angular_module.WB.cinehub.controllers.passwordExpiredController
         * @private
         */
        var getUserInfo = function () {
            if (self.user) {
                switch (self.user.userType) {
                    case "ex-corporate":
                        localStorageService.set("currentExhibitorAffiliation", self.user.exhibitorId);
                        break;
                    case "ex-other":
                        var exhibitorRelationship = JSON.parse(self.user.exhibitorRelationship);
                        localStorageService.set("currentExhibitorAffiliation", exhibitorRelationship[0].id);
                        break;
                    case "ex-theater":
                        var theaterAffiliations = JSON.parse(self.user.theaterAffiliations);
                        localStorageService.set("currentExhibitorAffiliation", theaterAffiliations[0].exhibitorId);
                        localStorageService.set("currentTheaterAffiliation", theaterAffiliations[0].id);
                        break;
                }
                if ($window.location.hash) {
                    $window.location.href = $window.location.hash.slice(1, $window.location.hash.length);
                } else {
                    $window.location.href = "/home";
                }
            }
        };
        
        /**
         * Init
         * @memberOf angular_module.WB.cinehub.controllers.passwordExpiredController
         * @function init
         * @public
         */
        self.init = function () {
            var login =  localStorageService.get("expiryPageAlert");
            if (login) {
                if (login === self.user.loginName) {
                    languageService.getBrowserLanguage().then(function (browserLang) {
                        browserLanguage = browserLang;
                        loadTranslations();
                    });
                }
            } else {
                $window.location.href = "/error/403";
            }
            
        };
        
        self.reset = function () {
            var userId = self.user.userId;
            var resetPasswordToken = self.user.resetPasswordToken;
            localStorageService.clearAll();
            $window.location.href = "/reset-password?userId=" + userId + "&resetPasswordToken=" + resetPasswordToken;
            
        };
        
        self.continueToSite = function () {
            localStorageService.remove("expiryPageAlert");
            getUserInfo();
        };

        //INIT
        self.init();
    }

    controllers.controller("passwordExpiredController", ["$cookies", "$window", "localStorageService", "loginService", "translateService", "OAUTH_SESSION_LIFETIME", "helpContactService", "languageService", passwordExpiredController]);
}());
