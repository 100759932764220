(function () {
    "use strict";
    var services = angular.module("WB.cinehub.services");

    /**
     * Login service 
     * @class loginService
     * @memberOf angular_module.WB.cinehub.services
     * @desc This is login service 
     * @param {!angular.$http} $http angular $http service
     * @param {string} API_ROOT API_ROOT constant 
     * @param {service} localStorageService local storage service
     */
    function loginService($http, API_ROOT, localStorageService) {

        /**
         * translations
         * @memberOf angular_module.WB.cinehub.services.loginService
         * @private
         * @type {object}
         */
        var translations = localStorageService.get("translations");

        /**
         * Get user information 
         * @memberOf angular_module.WB.cinehub.services.loginService
         * @public
         * @function getUserInfo
         * @param {string} username user name
         * @returns {promise}
         */
        this.getUserInfo = function (username) {
            return $http.post(API_ROOT + "users/search/getProfile", {
                loginName: username
            }, {
                errorMessage: translations.login.messages.getUserInfoError,
                alertKey: "anonymousUserAlert",
                showSpinner: false
            });
        };

        /**
         * Validate User Migration 
         * @memberOf angular_module.WB.cinehub.services.loginService
         * @public
         * @function validateUserMigration
         * @param {string} username user name
         * @returns {promise}
         */
        this.validateUserMigration = function (username) {
            return $http.post(API_ROOT + "users/search/validateUserMigration", {
                loginName: username
            }, {
                errorMessage: translations.login.messages.getUserInfoError,
                alertKey: "anonymousUserAlert",
                showSpinner: false
            });
        };
        
        /**
         * Verify password changed date
         * @function verifyPasswordChangedDate
         * @public
         * @returns {promise}
         * @memberOf angular_module.WB.cinehub.services.loginService
         * @param {string} username user name
         */
        this.verifyPasswordChangedDate = function (username) {
            return $http.post(API_ROOT + "users/getPasswordChangedDateForUser", {
                loginName: username
            }, {
                errorMessage: "error retrieving password changed date for the user"
            });
        };
        
        /**
         * get admin token back from impersonate token
         * @function getAdminToken
         * @memberOf angular_module.WB.cinehub.services.loginService
         * @public
         */
        this.getAdminToken = function () {
            return $http.get(API_ROOT + "users/switchToSourceToken?", {
                errorMessage: translations.viewAs.messages.retrieveAdminTokenErrorMessage
            });
        };

        /**
         * Log out to reset access token
         * @function logOut
         * @memberOf angular_module.WB.cinehub.services.loginService
         * @public
         * @param {string} token token
         */
        this.logOut = function (token) {
            return $http.get(API_ROOT + "users/logout?token=" + token + "&ignoreLocalizationString", {
                alertKey: "anonymousUserAlert",
                showSpinner: false
            });
        };

        /**
         * Get territory contacts
         * @function getTerritoryContacts
         * @memberOf angular_module.WB.cinehub.services.loginService
         * @public
         * @returns {promise}
         */
        this.getTerritoryContacts = function () {
            return $http.get(API_ROOT + "territories/search/findAllWithContactInfo?projection=contactInfo", {
                errorMessage: translations.loginHelp.getTerritoryContactsError
            });
        };
    }

    services.service("loginService", ["$http", "API_ROOT", "localStorageService", loginService]);
}());
