(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Modal add edit news controller
     * @dec The modal add edit news controller
     * @class modalAssetPickerController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {$uibModalInstance} $uibModalInstance modal instance
     * @param {$uibModal} $uibModal modal window
     * @param {service} assetPickerService asset picker service
     * @param {service} localStorageService local storage service
     */
    function modalAssetPickerController($uibModalInstance, $uibModal, spotlightId, $rootScope, assetPickerService, selectedAssets, filmId, API_EFD, DEFAULT_PAGE_SIZE, localStorageService, assetCategoryConvertor) {

        /**
         * Instance of modalAssetPickerController
         * @type {modalAssetPickerController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         */
        var self = this;

        /**
         * Translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         */
        self.translations = localStorageService.get("translations");

        /**
         * filter sort options
         * @public
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         */
        self.filterSortOptions = [];

        /**
         * is exhibitor corporate
         * @type {bool}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         */
        self.selectedAssets = selectedAssets ? angular.copy(selectedAssets) : [];

        /**
         * filmId
         * @type {bool}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         */
        self.filmId = filmId ? angular.copy(filmId) : [];
        /**
         * Filter sort options for asset picket controller
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         * @public
         */
        self.spotlightAssetsFilterSort = {};
        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.pageHeaderItem = {
            pageTitle: self.translations.spotlight.labels.assetPickerPageTitle,
            breadcrumbItems: [{
                linkTitle: self.translations.spotlight.labels.spotlight,
                link: "/spotlight-list"
            }]
        };
       

        /**
         * Button default text for films
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         * @private
         * @type [Array]
         */
        self.filmTranslations = {buttonDefaultText: self.translations.filter.labels.films};

        /**
         * Button default text for Asset types
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         * @private
         * @type [Array]
         */
        self.assetTypeTranslations = {buttonDefaultText: self.translations.filter.labels.assetTypes};

        /**
         * Button default text for sub types
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         * @private
         * @type [Array]
         */
        self.subTypeTranslations = {buttonDefaultText: self.translations.filter.labels.assetSubTypes};

        /**
         * Button default text for file types
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         * @private
         * @type [Array]
         */
        self.fileTypeTranslations = {buttonDefaultText: self.translations.filter.labels.fileTypes};

        /**
         * Button default text Sort by
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         * @private
         * @type [Array]
         */
        self.sortTypeTranslations = {buttonDefaultText: "Sort By"};
        /**
         * Get FilterOptions Object by setting page Data objects.
         * @function unpermissionedMaterialsSelectAll
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         */

          /**
         * Asset category convertor
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         * @private
         * @type {service}
         */
        self.assetCategoryConvertor = assetCategoryConvertor;

         /**
         * API_EFD
         * @constant
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         * @public
         * @type {string}
         */
        self.API_EFD = API_EFD;

        self.allFilterValues = [];

        /**
         * Button default text for films
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @private
         * @type [Array]
         */
        self.filmTranslations = {buttonDefaultText: self.translations.filter.labels.films};

        /**
         * Button default text for Asset types
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @private
         * @type [Array]
         */
        self.assetTypeTranslations = {buttonDefaultText: self.translations.filter.labels.assetTypes};

        /**
         * Button default text for sub types
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @private
         * @type [Array]
         */
        self.subTypeTranslations = {buttonDefaultText: self.translations.filter.labels.assetSubTypes};

        /**
         * Button default text for file types
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @private
         * @type [Array]
         */
        self.fileTypeTranslations = {buttonDefaultText: self.translations.filter.labels.fileTypes};

         /**
         * Multi Select Filter Options & Models.
         * Default button text for Sort
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type [Array]
         */
        self.sortTypeTranslations = {buttonDefaultText: "Sort By"};


         /**
          * assets
          * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
          * @public
          * @type {object}
          */
        self.assets = {
            "size": DEFAULT_PAGE_SIZE,
            "number": 0,
            "totalElements" : 0
        };

        self.getFilterOptions = function () {
            var multiSelectOptions = [
                {
                    type : "films",
                    id:"filmId",
                    value:"title",
                    translations:self.filmTranslations
                },
                {
                    type : "assetTypes",
                    id:"wbAssetType",
                    translations:self.assetTypeTranslations
                },
                {
                    type : "subTypes",
                    id:"wbpiAssetType",
                    translations:self.subTypeTranslations
                },
                {
                    type : "fileTypes",
                    id:"fileType",
                    translations:self.fileTypeTranslations
                }/* ,
               {
                    type : "sort",
                    options: self.assetPickerSortOptions,
                    model:self.assetsSortModel,
                    translations:self.sortTypeTranslations
                }*/
            ];

            for (var filterIndex = 0; filterIndex < multiSelectOptions.length; filterIndex++) {
                if (multiSelectOptions[filterIndex].type !== "sort" && multiSelectOptions[filterIndex].type !== "modifiedInterval") {
                    multiSelectOptions[filterIndex].data = self.assetPickerFilterSortOptions[filterIndex];
                }
            }

            return multiSelectOptions;
        };

        /**
         * Reset All Static Filter Models
         * @function getUnpermissionedMaterials
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.initStaticFilters = function () {
            self.assetsSortModel = {id : self.assetPickerSortOptions[0].value, label: self.assetPickerSortOptions[0].text};
        };
        /**
         * material sorting options
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         */
        self.assetPickerSortOptions = [{
            value: "spotlightFilm,id,lastModified,desc",
            text: self.translations.sort.labels.newestToOldest
        }, {
            value: "lastModified,id,asc",
            text: self.translations.sort.labels.oldestToNewest
        }];

        /**
         * Cancel
         * @function cancel
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         */
        self.cancel = function () {
            if (self.addEditSpotlightForm.$dirty) {
                $uibModal.open({
                    templateUrl: "confirmationTemplate.html",
                    controller: "modalConfirmationController",
                    controllerAs: "modalConfirmationCtrl",
                    keyboard: false,
                    backdrop: "static",
                    size: "md",
                    resolve: {
                        message: function () {
                            return self.translations.global.messages.confirmationMessage;
                        },
                        headerTitle: function () {
                            return self.translations.global.messages.warning;
                        },
                        showAffirmativeBtn: function () {
                            return true;
                        },
                        affirmativeBtnText: function () {
                            return self.translations.global.labels.yesButton;
                        },
                        cancelBtnText: function () {
                            return self.translations.global.labels.noButton;
                        }
                    }
                }).result.then(function () {
                    $uibModalInstance.dismiss();
                });
            } else {
                $uibModalInstance.dismiss();
            }
        };

        /**
         * Cancel
         * @function cancel
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         */
        self.cancel = function () {
            if (self.addEditSpotlightForm.$dirty) {
                $uibModal.open({
                    templateUrl: "confirmationTemplate.html",
                    controller: "modalConfirmationController",
                    controllerAs: "modalConfirmationCtrl",
                    keyboard: false,
                    backdrop: "static",
                    size: "md",
                    resolve: {
                        message: function () {
                            return self.translations.global.messages.confirmationMessage;
                        },
                        headerTitle: function () {
                            return self.translations.global.messages.warning;
                        },
                        showAffirmativeBtn: function () {
                            return true;
                        },
                        affirmativeBtnText: function () {
                            return self.translations.global.labels.yesButton;
                        },
                        cancelBtnText: function () {
                            return self.translations.global.labels.noButton;
                        }
                    }
                }).result.then(function () {
                    $uibModalInstance.dismiss();
                });
            } else {
                $uibModalInstance.dismiss();
            }
        };
        /**
         * Clear selected state
         * @function clearSelectedState
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         */
        /*var clearSelectedState = function () {
            self.assetPickerMaterials.content.forEach(function (asset) {
                asset.selected = false;
            });
        };*/

        /**
         * Clear selected state
         * @function clearSelectedState
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         */
        self.submit = function () {
            self.assetPickerMaterials.content.forEach(function (asset) {
                if(asset.selected) {
                    self.selectedAssets.push(asset);
                }
            });
            $rootScope.$emit("updateSpotlightAssets", self.selectedAssets);
            $uibModalInstance.dismiss(self.selectedAssets);
        };

        /*
         * getAssetPickerMaterials
         * @function getAssetPickerMaterials
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         */
        self.getAssetPickerMaterials = function () {
            assetPickerService.getAssetPickerMaterials(spotlightId, self.spotlightAssetsFilterSort.filmId, self.spotlightAssetsFilterSort.wbAssetType, self.spotlightAssetsFilterSort.wbpiAssetType, self.spotlightAssetsFilterSort.fileType, self.assets.size, self.assets.number, self.spotlightAssetsFilterSort.sort, self.filmId).success(function (result) {
                self.assetPickerMaterials = result;
                self.assets = result;
            });
            self.getFiltersValuesForAssetPicker();
        };

        /**
         * Get filters values for asset picker
         * @function getFiltersValuesForAssetPicker
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         */
        self.getFiltersValuesForAssetPicker = function () {
            assetPickerService.getFiltersValuesForAssetPicker(spotlightId, self.spotlightAssetsFilterSort.filmId, self.spotlightAssetsFilterSort.wbAssetType, self.spotlightAssetsFilterSort.wbpiAssetType, self.spotlightAssetsFilterSort.fileType, self.filmId).success(function (result) {
                self.assetPickerFilterSortOptions = [{
                    type: "select",
                    options: result.data.films,
                    propertyName: "filmId"
                }, {
                    type: "select",
                    options: result.data.assetTypes,
                    propertyName: "wbAssetType"
                }, {
                    type: "select",
                    options: result.data.assetSubtypes,
                    propertyName: "wbpiAssetType"
                }, {
                    type: "select",
                    options: result.data.fileTypes,
                    propertyName: "fileType"
                }, {
                    type: "select",
                    options: self.assetPickerSortOptions,
                    propertyName: "sort"
                }];

                //Add default option
                self.assetPickerFilterSortOptions [0].options.unshift({
                    text: self.translations.filter.labels.allFilms,
                    value: "ALL",
                    disabled: true
                });
                self.assetPickerFilterSortOptions [1].options.unshift({
                    text: self.translations.filter.labels.allAssetTypes,
                    value: "ALL",
                    disabled: true
                });
                self.assetPickerFilterSortOptions [2].options.unshift({
                    text: self.translations.filter.labels.allAssetSubTypes,
                    value: "ALL",
                    disabled: true
                });
                self.assetPickerFilterSortOptions [3].options.unshift({
                    text: self.translations.filter.labels.allFileTypes,
                    value: "ALL",
                    disabled: true
                });
                self.allFilterValues = self.assetPickerFilterSortOptions [4].options;
                self.filterOptions = self.getFilterOptions();
            });

        };


        /**
         * Select All Filters
         * @function filterSelectAll
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.filterSelectAll = function () {
            self.spotlightAssetsFilterSort.filmId = [];
            self.spotlightAssetsFilterSort.wbAssetType = [];
            self.spotlightAssetsFilterSort.wbpiAssetType = [];
            self.spotlightAssetsFilterSort.fileType = [];
            self.getAssetPickerMaterials();
        };

        /**
         * Init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalAssetPickerController
         */
        self.init = function () {
            self.spotlightAssetsFilterSort = {
                filmId: [],
                sort: "spotlightFilm,id,lastModified,desc",
                wbAssetType: [],
                wbpiAssetType: [],
                fileType: []
            };            
            self.initStaticFilters();
            self.getAssetPickerMaterials();
        };

        //INIT
        self.init();
    }

    controllers.controller("modalAssetPickerController", ["$uibModalInstance", "$uibModal", "spotlightId", "$rootScope", "assetPickerService", "selectedAssets", "filmId", "API_EFD", "DEFAULT_PAGE_SIZE", "localStorageService", "assetCategoryConvertor", modalAssetPickerController]);
}());
