(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * News controller
     * @dec The news tab controller
     * @class filmNewsController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.Scope} $scope angular scope
     * @param {!angular.$rootScope} rootScope angular root scope
     * @param {$uibModal} $uibModal modal instance
     * @param {service} filmNewsService film news service
     * @param {service} localStorageService local storage service
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     * @param {service} deviceDetectorService device detector service
     */
    function filmNewsController($scope, $rootScope, $uibModal, filmNewsService, localStorageService, DEFAULT_PAGE_SIZE, deviceDetectorService) {

        /**
         * Instance of filmNewsController
         * @type {filmNewsController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmNewsController
         */
        var self = this,

            /**
             * Film id
             * @type {int}
             * @memberOf angular_module.WB.cinehub.controllers.filmNewsController
             * @private
             */
            filmId = window.WB.cinehub.preload.filmId;

        /**
         * translations
         * @memberOf angular_module.WB.cinehub.controllers.filmNewsController
         * @public
         * @type {object}
         */
        self.translations = localStorageService.get("translations");

        /**
         * impersonate user flag
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmNewsController
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");
        
        /**
         * Device detector factory
         * @public
         * @type {factory}
         * @memberOf angular_module.WB.cinehub.controllers.filmNewsController
         */
        self.deviceDetectorService = deviceDetectorService;

        /**
         * sorting options
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmNewsController
         */
        self.sortOptions = [
            {
                value: "lastModified,desc",
                text: self.translations.sort.labels.newestToOldest
            },
            {
                value: "lastModified,asc",
                text: self.translations.sort.labels.oldestToNewest
            }
        ];

        /**
         * default sorting
         * @type {str}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmNewsController
         */
        self.sort = "lastModified,desc";

        /**
         * default current page
         * @type {int}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmNewsController
         */
        self.currentPage = 1;

        /**
         * page size
         * @type {int}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmNewsController
         */
        self.pageSize = DEFAULT_PAGE_SIZE;

        /*
         * Get news items for the film
         * @function getNewsItems
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmNewsController
         */
        self.getNewsItems = function () {
            var errorMessage = self.translations.filmDetails.messages.getNewsItemsError,
                size = DEFAULT_PAGE_SIZE,
                sort = "lastModified,desc";

            filmNewsService.getNewsItems(filmId, size, self.currentPage, sort, errorMessage).success(function (result) {
                self.newsItems = result;
            });
        };

        /**
         * Open add edit news modal
         * @function openAddEditNewsModal
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmNewsController
         */
        self.openAddEditNewsModal = function () {
            var film = {
                id: filmId,
                title: $scope.$parent.filmCtrl.filmDetails.title
            };
            var modalInstance = $uibModal.open({
                templateUrl: "/modules/addEditNewsModal/modalAddEditNews.html",
                controller: "modalAddEditNewsController",
                controllerAs: "addEditNewsModalCtrl",
                keyboard: false,
                backdrop: "static",
                size: "lg",
                resolve: {
                    newsId: function () {
                        return null;
                    },
                    film: function () {
                        return film;
                    }
                }
            });

            modalInstance.result.then(function () {
                self.getNewsItems();
                $rootScope.$emit("editFilmModalClosed");
            });
        };

        /**
         * Init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmNewsController
         */
        self.init = function () {
            self.getNewsItems();
        };

        //INIT
        self.init();
    }

    controllers.controller("filmNewsController", ["$scope", "$rootScope", "$uibModal", "filmNewsService", "localStorageService", "DEFAULT_PAGE_SIZE", "deviceDetectorService", filmNewsController]);
} ());
