(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");
    
    /**
     * @class newsDetailsService
     * @memberOf angular_module.WB.cinehub.services
     * @desc film service
     * @param {!angular.$http} $http angular $http service
     * @paarm {strict} API_ROOT API_ROOT constant  
     */
    function newsDetailsService($http, API_ROOT) {

        /**
         * get news details 
         * @memberOf angular_module.WB.cinehub.services.newsDetailsService
         * @public
         * @param {int} newsId news id
         * @param {str} errorMessage error message
         * @returns {promise}
         */
        this.getNewsDetails = function (newsId, errorMessage) {
            return $http.get(API_ROOT + "newsItems/search/findLocById/?id=" + newsId, { errorMessage: errorMessage });
        };

        /**
         * Get recent news items
         * @memberOf angular_module.WB.cinehub.services.newsDetailsService
         * @public
         * @function getRecentNewsItems
         * @param {int} size size
         * @param {string} sort sort order
         * @param {string} secondarySort secondary sort
         * @param {string} errorMessage error message 
         * @returns {promise}
         */        
        this.getRecentNewsItems = function (size, sort, secondarySort, errorMessage) {
            var sortString = secondarySort ? "&sort=" + sort + "&sort=" + secondarySort : "&sort=" + sort;
            return $http.get(API_ROOT + "newsItems/search/findLocAll?page=0&size=" + size + sortString, { errorMessage: errorMessage });
        };
    }

    services.service("newsDetailsService", ["$http", "API_ROOT", newsDetailsService]);
}());