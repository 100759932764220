(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class sendPlusService
     * @memberOf angular_module.WB.cinehub.services
     * @desc sendPlus service
     * @param {!angular.$http} $http angular $http service
     * @param {strict} API_ROOT API_ROOT constant  
     * @param {service} localStorageService local storage service
     */
    function sendPlusService($http, API_ROOT) {

        /**
         * translations
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.sendPlusService
         * @public
         */
//        var translations = localStorageService.get("translations");

        /**
         * get JwtToken (test method not in use -
         * refer shared getRenditionsService module for sendplus impl)
         * @memberOf angular_module.WB.cinehub.services.sendPlusService
         * @public
         * @function getJwtToken
         * @returns {promise}
         */
        this.getJwtToken = function () {
            return $http.post(API_ROOT +"sendplus/getJwtToken",{
                "userFirstName": "Jags",
                "userLastName": "D",
                "userEmailId": "jags.d@wb.com",
                "title": "test",
                "adstreamAssetId": "59dc9de247c3fc728ea9991f",
                "adstreamEncodes" : ["59dc9d9de4b067b510e683bf"]
            }, {
                errorMessage: "error"
            });
        };
    }

    services.service("sendPlusService", ["$http", "API_ROOT", sendPlusService]);
}());
