/** Global JL **/
(function() {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * Password service
     * @class passwordService
     * @memberOf angular_module.WB.cinehub.services
     * @desc The password service
     * @param {!angular.$http} $http angular $http service
     * @param {string} API_ROOT API_ROOT constant
     * @param {service} localStorageService local storage service
     */
    function passwordService($http, $q, API_ROOT, localStorageService) {


        /**
         * translations
         * @type {object}
         * @memberOf angular_module.WB.cinehub.services.passwordService
         * @private
         */
        var translations = localStorageService.get("translations"),

            /**
             * Instance to passwordService
             * @memberOf angular_module.WB.cinehub.services.passwordService
             * @private
             * @type {passwordService}
             */
            self = this,

            /**
             * Minium password length
             * @private
             * @type {int}
             * @memberOf angular_module.WB.cinehub.services.passwordService
             */
            minimumPasswordLength = 12,

            /**
             * Min complexity count
             * @type {int}
             * @private
             * @memberOf angular_module.WB.cinehub.services.passwordService
             */
            minimumComplexityCount = 4;

        /**
         * The user
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @type {object[]}
         */
        self.user = [];
        /**
         * Has upper case
         * @function hasUpperCase
         * @private
         * @memberOf angular_module.WB.cinehub.services.passwordService
         * @param {string} password string value
         */
        var hasUpperCase = function(password) {
            return (/[A-Z]/.test(password));
        };

        /**
         * Has lower case
         * @function hasLowerCase
         * @private
         * @memberOf angular_module.WB.cinehub.services.passwordService
         * @param {string} password string value
         */
        var hasLowerCase = function(password) {
            return (/[a-z]/.test(password));
        };

        /**
         * Has Special Character
         * @function hasSpecialCharacter
         * @private
         * @memberOf angular_module.WB.cinehub.services.passwordService
         * @param {string} password string value
         */
        var hasSpecialCharacter = function(password) {
            return (/[\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:]/.test(password));
        };

        /**
         * Has number
         * @function hasNumber
         * @private
         * @memberOf angular_module.WB.cinehub.services.passwordService
         * @param {string} password string value
         */
        var hasNumber = function(password) {
            return (/[\d]/.test(password));
        };
        /**
         * Contains firstname or lastname
         * @function containsFirstnameOrLastname
         * @private
         * @memberOf angular_module.WB.cinehub.services.passwordService
         * @param {string} password password value
         * @param {string} email email
         * @returns {bool}
         */
        var containsFirstnameOrLastname = function(password, firstName,lastName) {
            var ret = false;
            if (password.indexOf(firstName) !== -1 || password.indexOf(lastName) !== -1) {
                ret = true;
            }
            return ret;
        };

        /**
         * Contains username
         * @function containsUsername
         * @private
         * @memberOf angular_module.WB.cinehub.services.passwordService
         * @param {string} password password value
         * @param {string} email email
         * @returns {bool}
         */
        var containsUsername = function(password, email) {
            var ret = false,
                twoLetterPartsOfEmail = email.match(/.{3}/g).concat(email.substr(1).match(/.{3}/g)),
                uniqueTwoLetterPartsOfEmail = _.uniq(twoLetterPartsOfEmail),
                twoLetterPartsOfPassword = password.match(/.{3}/g).concat(password.substr(1).match(/.{3}/g)),
                uniqueTwoLetterPartsOfPassword = _.uniq(twoLetterPartsOfPassword);

            for (var j = 0; j < uniqueTwoLetterPartsOfPassword.length; j++) {
                if (uniqueTwoLetterPartsOfEmail.indexOf(uniqueTwoLetterPartsOfPassword[j]) > -1) {
                    ret = true;
                }
            }
            return ret;
        };

        /**
         * Check password complexity
         * @memberOf angular_module.WB.cinehub.services.passwordService
         * @function checkPasswordComplexity
         * @param {string} password password
         * @private
         * @returns {bool}
         */
        var checkPasswordComplexity = function(password) {
            var reqMetCount = 0;

            if (hasUpperCase(password)) {
                reqMetCount += 1;
            }
            if (hasLowerCase(password)) {
                reqMetCount += 1;
            }
            if (hasNumber(password)) {
                reqMetCount += 1;
            }
            if (hasSpecialCharacter(password)) {
                reqMetCount += 1;
            }

            return reqMetCount >= minimumComplexityCount;
        };

        /**
         * Invalid password
         * @memberOf angular_module.WB.cinehub.services.passwordService
         * @function invalidPassword
         * @returns {bool}
         * @public
         */
        self.invalidPassword = function(password) {
            var invalid = false;
            if ((password && password.length < minimumPasswordLength) || !checkPasswordComplexity(password)) {
                invalid = true;
            }
            return invalid;
        };
        
        /**
         * password Username Error
         * @memberOf angular_module.WB.cinehub.services.passwordService
         * @function passwordUsernameError
         * @returns {bool}
         * @public
         */
        self.passwordUsernameError = function(password, email, firstName, lastName) {
            var invalid = false;
            if (password && password.length > 11 && containsUsername(password, email) || containsFirstnameOrLastname(password.toLowerCase(), firstName.toLowerCase(), lastName.toLowerCase())) {
                invalid = true;
            }
            return invalid;
        };

        /**
         * Update password
         * @param {string} credential
         * @function resetPassword
         * @memberOf angular_module.WB.cinehub.services.passwordService
         * @public
         * @returns {promise}
         */
        self.resetPassword = function(credential) {
            return $http.put(API_ROOT + "users/setPasswordForUser", credential, {
                errorMessage: translations.resetPassword.messages.resetPasswordError
            });
        };

        /**
         * forgot password
         * @function forgotPassword
         * @memberOf angular_module.WB.cinehub.services.passwordService
         * @public
         * @param {str} email email
         * @returns {promise}
         */
        self.forgotPassword = function(email) {
            return $http.get(API_ROOT + "users/resetPassword?loginName=" + email, {
                errorMessage: translations.forgotPassword.messages.resetPasswordError
            });
        };


        /**
         * Set new password for user
         * @function setNewPasswordForUser
         * @param {type} oldPassword old password
         * @param {type} newPassword new password
         * @returns {promise}
         * @public
         */
        self.setNewPasswordForUser = function(oldPassword, newPassword) {
            return $http.put(API_ROOT + "users/setNewPasswordForUser", {
                loginName: localStorageService.get("userInfo").loginName,
                password: oldPassword,
                newPassword: newPassword
            }, {
                errorMessage: translations.userInfo.messages.updatePasswordError
            });
        };
    }

    services.service("passwordService", ["$http", "$q", "API_ROOT", "localStorageService", passwordService]);
}());
