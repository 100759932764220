(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");
    
    /**
     * @class reportsService
     * @memberOf angular_module.WB.cinehub.services
     * @desc report service
     * @param {!angular.$http} $http angular $http service
     * @paarm {strict} API_ROOT API_ROOT constant
     * @param {service} localStorageService local storage service
     */
    function reportsService($http, API_ROOT, localStorageService) {

        /**
         * translations
         * @type {object}
         * @memberOf angular_module.WB.cinehub.services.reportsService
         * @public
         */
        var translations = localStorageService.get("translations");

        /**
         * Get anti piracy of use
         * @memberOf angular_module.WB.cinehub.services.reportsService
         * @public
         * @returns {promise}
         */
        this.getReport = function (fromDate, toDate, territoryId, role, reports) {
            return $http.post(API_ROOT + "reports/generateReports", {
                "fromDate": fromDate,
                "toDate": toDate,
                "territoryId" : territoryId,
                "role" : role,
                "reports": reports
            }, { 
                responseType: "arraybuffer"
            }, {
                errorMessage: translations.reports.messages.getReportDownloadError
            });
        };

    }

    services.service("reportsService", ["$http", "API_ROOT", "localStorageService", reportsService]);
}());
