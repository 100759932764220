(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class translateService
     * @memberOf angular_module.WB.cinehub.services
     * @desc asset service
     * @param {!angular.$http} $http angular $http service
     * @param {string} API_ROOT API_ROOT constant
     * @param {constant} US_TERRITORY_ID US_TERRITORY_ID
     * @param {string} API_ROOT API_ROOT constant
     */
    function translateService($http, promiseFactory, US_TERRITORY_ID, API_ROOT, territoriesService, localStorageService, $rootScope) {

        /**
         * Parse Crowdin Code
         * @memberOf angular_module.WB.cinehub.services.translateService
         * @function parseCrowdinCode
         * @desc This is translation code parser service. 
         * The service is responsible to process the localization json file and to parse value code from translation file to a array of objects in order for angular 
         * to consume it and build select elements as well as to preselect them correctly based on  saved value
         * @example input {"1|releaseDate": "Release Date"} output {id: 1, code: "releaseDate", value: "Release Date"}
         * @example input {"1|technologySolutions|wb": "Technology Solutions"} output {id: 1, code: "technologySolutions", extraCode: "wb", value: "Technology Solutions"}  
         * @private
         * @param {object} translations translations
         * @returns {object}
         */
        var parseCrowdinCode = function (translationValues) {
            window.console.time("parseCrowdinCode");
            var result = {},
                keys = Object.keys(translationValues),
                keyIndex = 0,
                keysLength = keys.length;
            for (keyIndex; keyIndex < keysLength; keyIndex++) {
                var key = keys[keyIndex];
                result[key] = [];
                translationValues[key].forEach(function (value) {
                    for (var encodedKey in value) {
                        var decodedKey = encodedKey.split("|"),
                            obj = {
                                id: decodedKey[0],
                                code: decodedKey[1],
                                value: value[encodedKey]
                            };
                        if (decodedKey.length === 3) {
                            obj.extraCode = decodedKey[2];
                        }
                        result[key].push(obj);
                    }
                });
            }
            window.console.timeEnd("parseCrowdinCode");
            return result;
        };

        /**
         * Get filtered territories 
         * @memberOf angular_module.WB.cinehub.services.translateService
         * @private
         * @param {object[]} liveTerritories live territories
         * @param {object} translations translations
         * @returns {object[]}
         */
        var getFilteredTerritories = function (liveTerritories, translations) {
            var filteredTerritories = [],
                index = 0,
                liveTerritoriesLength = liveTerritories.length;
            for (index; index < liveTerritoriesLength; index++) {
                var liveTerritory = _.find(translations.values.territories, {
                    id: liveTerritories[index]
                });
                if (liveTerritory) {
                    filteredTerritories.push(liveTerritory);
                }
            }

            return filteredTerritories;
        };

        /**
         * Get filtered locales 
         * @memberOf angular_module.WB.cinehub.services.translateService
         * @private
         * @param {object[]} liveLocales live locales
         * @returns {object[]}
         */
        var getFilteredLocales = function (liveLocales) {
            var filteredLocales = [],
                index = 0,
                liveLocalesLength = liveLocales.length;
            for (index; index < liveLocalesLength; index++) {
                filteredLocales.push({
                    id: liveLocales[index].id,
                    code: liveLocales[index].abbreviation,
                    value: liveLocales[index].name,
                    extraCode: liveLocales[index].territoryId
                });
            }
            return filteredLocales;
        };

        /**
         * Set live territories and locales and set the data to the translation json.
         * @memberOf angular_module.WB.cinehub.services.translateService
         * @private
         * @function setTerritoryAndLocaleData
         * @param {object} translations localized values
         * @param {promise} promise promise
         */
        var setTerritoryAndLocaleData = function (translations, promise) {
            var liveLocales = localStorageService.get("liveLocales") || null,
                liveTerritories = [],
                processLiveLocalesTerritories = function () {
                    liveTerritories = _.uniq(_.map(liveLocales, "territoryId"));
                    if (liveLocales.length > 0) {
                        translations.values.locales = getFilteredLocales(liveLocales);
                        translations.values.allTerritories = _.cloneDeep(translations.values.territories);
                        translations.values.territories = getFilteredTerritories(liveTerritories, translations);
                        localStorageService.set("translations", translations);
                        promise.resolve(translations);
                        $rootScope.$broadcast("localizationIsUpdated");
                    }
                };
            if (translations && translations.values) {
                if (liveLocales) {
                    processLiveLocalesTerritories();
                } else {
                    territoriesService.getLiveTerritoriesAndLocales().then(function (result) {
                        liveLocales = result.data._embedded.locales;
                        localStorageService.set("liveLocales", liveLocales);
                        processLiveLocalesTerritories();
                    });
                }
            }
        };

        /**
         * load translations
         * @memberOf angular_module.WB.cinehub.services.translateService
         * @public
         * @function loadTranslations
         * @returns translated labels/messages/values in object
         */
        this.loadTranslations = function (locale) {
            var results = promiseFactory.defer();

            //local function to stay DRY
            var resolveTranslations = function (result) {
                var translations = result.data;
                translations.values = parseCrowdinCode(translations.values);
                setTerritoryAndLocaleData(translations, results);
            };

            if (locale.length === 2) {
                locale += "-" + locale.toUpperCase();
            }

            //we are not translating the error message here because at this point we don't have any translations
            $http.get("/translations/i18n/" + locale + ".json", {
                errorMessage: "",
                hideErrorAlert: true,
                showSpinner: false
            })
            .then(resolveTranslations)
            .catch(function () {
                $http.get("/translations/i18n/" + locale + ".json", {
                    errorMessage: "",
                    hideErrorAlert: true,
                    showSpinner: false
                })
                .then(resolveTranslations)
                .catch(function () {
                    $rootScope.$broadcast("showAlert", "danger", "Error! Cannot load a language file for '" + locale + "' at this time. Defaulting to 'en-us'. Please try again later.", "anonymousUserAlert");
                    $http.get("/translations/i18n/en-US.json", {
                        errorMessage: "Error! Cannot load a language file for the fallback language of 'en-us'. Please try again later.",
                        showSpinner: false
                    })
                    .then(resolveTranslations)
                    .catch(function () {
                        results.reject();
                    });
                });
            });

            return results.promise;
        };

        /**
         * find items by property
         * @private
         * @function
         * @memberOf angular_module.WB.cinehub.services.translateService
         */
        this.findItemsByProperty = function (collection, property, values) {
            var valueArray = [],
                element;

            if(values && values.length === undefined) {
                values = [values.toString()];
            }

            if (collection && values && values.length) {
                for (var i = 0; i < values.length; i++) {
                    element = _.find(collection, function (item) {
                        return item[property] === (values[i] || "").trim();
                    });
                    valueArray.push(element);
                }
                return valueArray;
            } else {
                return [];
            }
        };
    }

    services.service("translateService", ["$http", "promiseFactory", "US_TERRITORY_ID", "API_ROOT", "territoriesService", "localStorageService", "$rootScope", translateService]);
}());
