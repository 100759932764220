(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");
    
    /**
     * @class modalLookupTheaterService
     * @memberOf angular_module.WB.cinehub.services
     * @desc modal look up theater service
     * @param {!angular.$http} $http angular $http service
     * @param {constant} API_ROOT API_ROOT constant  
     * @param {service} localStorageService local storage service 
     */
    function modalLookupTheaterService($http, API_ROOT, localStorageService) {

        /**
         * translations
         * @type {object}
         * @private
         * @memberOf angular_module.WB.cinehub.services.modalLookupTheaterService
         */
        var translations = localStorageService.get("translations");

        /**
         * search for theaters
         * @memberOf angular_module.WB.cinehub.services.modalLookupTheaterService
         * @public
         * @param {str} filterQuery filter query
         * @param {int} page page
         * @param {int} size size
         * @param {str} sort sort
         * @returns {promise}
         */
        this.searchTheaters = function (filterQuery, page, size, sort) {
            return $http.get(API_ROOT + "theaters/search/findByExhibitorIdAndNameLikeAndCityLikeAndStateLike?" + filterQuery
             + "&page=" + page + "&size=" + size + "&sort=" + sort + "&ignoreLocalizationString", { errorMessage: translations.registration.messages.getTheatersError, alertKey: "modalAlert" });
        };

        /**
         * search for theaters associated with independent/none/other exhibitors
         * @memberOf angular_module.WB.cinehub.services.modalLookupTheaterService
         * @public
         * @param {str} filterQuery filter query
         * @param {int} page page
         * @param {int} size size
         * @param {str} sort sort
         * @returns {promise}
         */
        this.searchTheatersForIndependentExhibitors = function (filterQuery, page, size, sort) {
            return $http.get(API_ROOT + "theaters/search/findByTerritoryIdAndNameLikeAndCityLikeAndStateLike?" + filterQuery
             + "&page=" + page + "&size=" + size + "&sort=" + sort + "&ignoreLocalizationString", { errorMessage: translations.registration.messages.getTheatersError, alertKey: "modalAlert" });
        };

    }

    services.service("modalLookupTheaterService", ["$http", "API_ROOT", "localStorageService", modalLookupTheaterService]);
}());
