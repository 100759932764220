(function() {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class theaterScreensService
     * @memberOf angular_module.WB.cinehub.services
     * @desc theater screen service
     * @param {!angular.$http} $http angular $http service
     * @param {strict} API_ROOT API_ROOT constant
     * @param {service} localStorageService local storage service
     */
    function theaterScreensService($http, API_ROOT, localStorageService) {

        /**
         * translations
         * @type {string}
         * @private
         * @memberOf angular_module.WB.cinehub.services.theaterScreensService
         */
        var translations = localStorageService.get("translations");

        /**
         * Get theater screens
         * @param {int} theaterId theater id  
         * @param {int} page page number 
         * @param {int} size page size
         * @param {string} sort sort string 
         * @function findByTheaterId
         * @returns {promise}
         * @memberOf angular_module.WB.cinehub.services.theaterScreensService
         * @public
         */
        this.findByTheaterId = function(theaterId, page, size, sort) {
            return $http.get(API_ROOT + "theaterScreens/search/findByTheaterId?theaterId=" + theaterId + "&page=" + page + "&size=" + size + "&sort=" + sort + "&ignoreLocalizationString", {
                errorMessage: translations.global.messages.searchTheaterScreensError
            });
        };
    }

    services.service("theaterScreensService", ["$http", "API_ROOT", "localStorageService", theaterScreensService]);
}());