(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Password complexity validation form directive
     * @class passwordComplexityValidator
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     * @param {service} passwordService password service
     */
    function passwordComplexityValidator(passwordService) {

        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @param {jqueryElement} element jquery element
         * @param {object} attrs attributes
         * @param {object} ngModel ng model
         * @private
         * @memberOf angular_module.WB.cinehub.directives.passwordComplexityValidator
         */
        var link = function (scope, element, attrs, ngModel) {
            ngModel.$validators.weakPassword = function (modelValue, viewValue) {
                if (ngModel.$isEmpty(modelValue)) {
                    // consider empty models to be valid
                    return true;
                }
                return !passwordService.invalidPassword(viewValue);
            };
            ngModel.$validators.passwordContainsUsername = function (modelValue, viewValue) {
                if (ngModel.$isEmpty(modelValue)) {
                    // consider empty models to be valid
                    return true;
                }
                var firstName = attrs.firstName ? attrs.firstName : attrs.firstname;
                var lastName = attrs.lastName ? attrs.lastName : attrs.lastname;
                return !passwordService.passwordUsernameError(viewValue, attrs.email, firstName, lastName);
            };
        };

        return {
            require: "ngModel",
            link: link
        };
    }

    directives.directive("passwordComplexityValidator", ["passwordService", passwordComplexityValidator]);
}());
