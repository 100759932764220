(function() {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class restrictService
     * @memberOf angular_module.WB.cinehub.services
     * @desc restrict service
     * @param {service} localStorageService local storage service
     */
    function restrictService(localStorageService) {

        /**
         * User info
         * @type {object}
         * @private
         * @memberOf angular_module.WB.cinehub.services.restrictService
         */
        var user = localStorageService.get("userInfo");

        /**
         * Access granted flag
         * @type {boolean}
         * @private
         * @memberOf angular_module.WB.cinehub.services.restrictService
         */
        var accessGranted = false;

        /**
         * Get access grant
         * @function getAccessGrant
         * @memberOf angular_module.WB.cinehub.services.restrictService
         * @public
         * @returns {boolean}
         * @param {array} restrictedRoles restricted roles
         */
        this.getAccessGrant = function(restrictedRoles) {
            accessGranted = false;
            if (user && user.role && restrictedRoles) {
                var index = 0,
                    restrictedRolesLength = restrictedRoles.length;
                for (index; index < restrictedRolesLength; index++) {
                    if (user.role !== restrictedRoles[index]) {
                        accessGranted = true;
                        break;
                    }
                }
            }
            return accessGranted;
        };
    }

    services.service("restrictService", ["localStorageService", restrictService]);
}());