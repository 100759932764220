(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Material activity history controller
     * @dec Material activity history controller
     * @class materialActivityHistoryController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.Scope} $scope angular scope
     * @param {service} activityHistoryService activity history service
     * @param {service} localStorageService local storage service
     * @param {service} redirectService redirect service
     * @param {service} translateService translate service
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     * @param {filter} $filter angular filter
     */
    function materialActivityHistoryController($scope, activityHistoryService, localStorageService, redirectService, translateService, DEFAULT_PAGE_SIZE, $filter) {

        /**
         * Instance of materialActivityHistoryController
         * @type {materialActivityHistoryController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.materialActivityHistoryController
         */
        var self = this;

        /**
         * Asset id
         * @type {int}
         * @memberOf angular_module.WB.cinehub.controllers.materialActivityHistoryController
         * @public
         */
        self.assetId = window.WB.cinehub.preload.assetId;

        /**
         * mmx id
         * @type {int}
         * @memberOf angular_module.WB.cinehub.controllers.materialActivityHistoryController
         * @public
         */
        self.mmxId = window.WB.cinehub.preload.mmxId;

        /**
         * Action
         * @type {string}
         * @memberOf angular_module.WB.cinehub.controllers.materialActivityHistoryController
         * @public
         */
        self.action = window.WB.cinehub.preload.action;

        /**
         * translations
         * @memberOf angular_module.WB.cinehub.controllers.materialActivityHistoryController
         * @public
         * @type {object}
         */
        self.translations = localStorageService.get("translations");

        /**
         * redirect service
         * @memberOf angular_module.WB.cinehub.controllers.materialActivityHistoryController
         * @public
         * @type {object}
         */
        self.redirectService = redirectService;

        /**
         * default current page
         * @type {int}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.materialActivityHistoryController
         */
        self.currentPage = 1;

        /**
         * page size
         * @type {int}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.materialActivityHistoryController
         */
        self.pageSize = DEFAULT_PAGE_SIZE;

        /*
         * Get activity histories
         * @function getActivityHistories
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.materialActivityHistoryController
         */
        self.getActivityHistories = function () {
            var errorMessage = self.translations.filmDetails.messages.getActivityHistoryError,
                tableName = "film_asset",
                size = DEFAULT_PAGE_SIZE,
                sort = "lastModified,desc";

            activityHistoryService.getActivityHistories(tableName, self.assetId, self.currentPage, size, sort, errorMessage).success(function (result) {
                self.activityHistories = result;
                self.activityHistories._embedded.activityHistories.forEach(function (activityHistoryItem) {
                    if(moment(new Date(activityHistoryItem.fieldNewValue)).isValid()) {
                        activityHistoryItem.fieldNewValue = $filter("date")(activityHistoryItem.fieldNewValue, "longDate");
                    }
                });
            });
        };

        /**
         * get field name
         * @function getFieldName
         * @public
         * @param {str} columnName column name
         * @memberOf angular_module.WB.cinehub.controllers.materialActivityHistoryController
         */
        self.getFieldName = function(columnName) {
            return _.find(self.translations.values.fieldNames, { code: columnName }).value;
        };

        /**
         * Init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.materialActivityHistoryController
         */
        self.init = function () {
            self.getActivityHistories();
        };

        $scope.$on("materialUpdated", function () {
            self.getActivityHistories();
        });

        //INIT
        self.init();
    }

    controllers.controller("materialActivityHistoryController", ["$scope", "activityHistoryService", "localStorageService", "redirectService", "translateService", "DEFAULT_PAGE_SIZE", "$filter", materialActivityHistoryController]);
} ());
