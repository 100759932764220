(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Email exist validation from directive
     * @class emailExistValidator
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     * @param {service} registrationService registration service
     * @param {service} $q $q service
     */
    function emailExistValidator(registrationService, $q) {
        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @param {jqueryElement} element jquery element
         * @param {object} attr attributes
         * @param {object} ngModel angular model
         * @private
         * @memberOf angular_module.WB.cinehub.directives.emailExistValidator 
         */
        var link = function (scope, element, attr, ngModel) {

            ngModel.$asyncValidators.userAlreadyRegister = function (modelValue) {

                if (ngModel.$isEmpty(modelValue)) {
                    // consider empty model valid
                    return $q.resolve();
                }

                var def = $q.defer();

                registrationService.validateUserEmail(modelValue.toLowerCase(), "anonymousUserAlert").success(function (result) {
                    if (result.data.isUserExists) {
                        def.reject();
                    } else {
                        def.resolve();
                    }
                    scope.$emit("accountTypeUpdate", result.data);
                    ngModel.$setValidity("missingWbEmailInOkta", true);
                    ngModel.$setValidity("pendingAccountVerification", true);
                }).error(function (error) {
                    if (error.message === "missingWbEmailInOkta") {
                        ngModel.$setValidity("missingWbEmailInOkta", false);
                    }
                    if(error.message === "pendingAccountVerification") {
                        ngModel.$setValidity("pendingAccountVerification", false);
                    }
                });

                return def.promise;
            };
        };

        return {
            require: "ngModel",
            link: link
        };
    }

    directives.directive("emailExistValidator", ["registrationService", "$q", emailExistValidator]);
}());
