(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Modal lookup theater controller
     * @dec The modal lookup theater controller
     * @class modalLookupTheaterController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.Scope} $scope angular scope
     * @param {$uibModalInstance} $uibModalInstance modal instance
     * @param {service} localStorageService local storage service
     * @param {service} modalLookupTheaterService modal lookup theater service
     * @param {obj} exhibitor exhibitor selected in previous screen
     * @param {int} territoryId territory id for independent exhibitors
     * @param {str} headerText header text
     * @param {bool} isExCorporate is exhibitor corporate
     * @param {bool} isExOther is exhibitor corporate
     * @param {Array} selectedTheaters previously selected Theaters
     * @param {service} $q $q service
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     */
    function modalLookupTheaterController($scope, $uibModalInstance, localStorageService, modalLookupTheaterService, exhibitor, territoryId, headerText, isExCorporate, isExOther, selectedTheaters, $q, DEFAULT_PAGE_SIZE) {

        /**
         * Instance of modalLookupTheaterController
         * @type {modalLookupTheaterController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        var self = this,
            /**
             * list of theaters
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
             */
            theaters;

        /**
         * list of allSelectedTheaters
         * @type {object}
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        self.allSelectedTheaters = selectedTheaters ? angular.copy(selectedTheaters) : [];

        /**
         * translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        self.translations = localStorageService.get("translations");

        /**
         * modal lookup theater service
         * @type {service}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        self.modalLookupTheaterService = modalLookupTheaterService;

        /**
         * exhibitor selected in previous screen
         * @type {obj}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        self.exhibitor = exhibitor;

        /**
         * header text
         * @type {str}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        self.headerText = headerText;

        /**
         * is exhibitor corporate
         * @type {bool}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        self.isExCorporate = isExCorporate;
        /**
         * is exhibitor other
         * @type {bool}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        self.isExOther = isExOther;
        /**
         * is exhibitor corporate
         * @type {bool}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        self.selectedTheaters = selectedTheaters;


        /**
         * is exhibitor corporate
         * @type {bool}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        self.userType = isExCorporate ? "ex-corporate" : isExOther ? "ex-other" : "";

        /**
         * Page data
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        self.page = {
            "totalElements": 0,
            "totalPages": 0,
            "number": 1,
            "size": DEFAULT_PAGE_SIZE
        };

        /**
         * sort
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        self.sort = "exhibitorName,name";

        /**
         * The list of the theaters
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         * @type {object}
         */
        self.theaters = null;

        /**
         * disable add button
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         * @type {boolean}
         */
        self.disableAddButton = true;

        /**
         * exhibitor flag
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @type {boolean}
         */
        self.multiExhibitorsFlag = true;

        /*
         * filter query
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @type {string}
         */
        self.filterQuery = "";

        /*
         * show grid
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @type {boolean}
         */
        self.showGrid = false;

        /*
         * search for theaters
         * @function search
         * @private
         * @param {obj} filterQuery filter query
         * @param {int} page page
         * @param {int} size size
         * @param {int} sort sort
         * @param {str} errorMessage error message
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        var searchTheaters = function (filterQuery, page, size, sort, errorMessage) {
            var def = $q.defer();
            modalLookupTheaterService.searchTheaters(self.filterQuery, self.page.number, self.page.size, self.sort, errorMessage).success(function (result) {
                def.resolve();
                self.theaters = result;
                if (result && result._embedded) {
                    for (var i = 0; i < result._embedded.theaterViews; i++) {
                        result._embedded.theaterViews[i].selected = false;
                    }
                }
                self.setSelectedTheaters(result);
            });
        };

        /*
         * search for theaters
         * @function search
         * @private
         * @param {obj} filterQuery filter query
         * @param {int} page page
         * @param {int} size size
         * @param {int} sort sort
         * @param {str} errorMessage error message
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        var searchTheatersForIndependentExhibitors = function (filterQuery, page, size, sort, errorMessage) {
            var def = $q.defer();
            modalLookupTheaterService.searchTheatersForIndependentExhibitors(self.filterQuery, self.page.number, self.page.size, self.sort, errorMessage).success(function (result) {
                def.resolve();
                self.theaters = result;
                self.setSelectedTheaters(result);
            });
        };

        /*
         * reset search fields and clear the result
         * @function resetFields
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        var resetFields = function () {
            var result = {
                _embedded: {
                    theaterViews: []
                }
            };
            self.theaters = result;
            self.name = "";
            self.city = "";
            self.state = "";
            self.page.size = DEFAULT_PAGE_SIZE;
        };

        /*
         * reset search fields and clear the result
         * @function resetSearchFields
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        var resetSearchFields = function () {
            var result = {
                _embedded: {
                    theaterViews: []
                }
            };
            self.theaters = result;
            self.name = "";
            self.city = "";
            self.state = "";
            self.page.size = DEFAULT_PAGE_SIZE;
            self.allSelectedTheaters = [];
        };

        /*
         * reset search fields and clear the result
         * @function resetSearchFields
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        self.resetSearchFields = function () {
            resetSearchFields();
            self.search();
        };

        /*
         * search for theaters
         * @function search
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        self.search = function () {
            var exhibitorId = self.exhibitor ? self.exhibitor.id : "";
            self.multiExhibitorsFlag = true;
            if (exhibitorId) {
                self.filterQuery = "exhibitorId=" + exhibitorId + "&name=" + self.name + "&city=" + self.city + "&state=" + self.state;
                searchTheaters(self.filterQuery, self.page.number, self.page.size, self.sort);
            } else {
                self.filterQuery = "territoryId=" + territoryId + "&name=" + self.name + "&city=" + self.city + "&state=" + self.state;
                searchTheatersForIndependentExhibitors(self.filterQuery, self.page.number, self.page.size, self.sort);
            }
            self.showGrid = true;
            self.disableAddButton = self.allSelectedTheaters.length > 0 ? false : true;
        };

        /**
         * Cancel
         * @function cancel
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        self.cancel = function () {
            self.allSelectedTheaters = [];
            $uibModalInstance.dismiss();
        };

        /**
         * Add theaters
         * @function add
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        self.add = function () {
            var checkedTheaters = _.filter(theaters, {
                selected: true
            });
            var exhibitorName = "";
            self.multiExhibitorsFlag = true;
            var theaterCheck;
            for (var m = 0; m < self.allSelectedTheaters.length; m++) {
                theaterCheck = false;
                for (var n = 0; n < checkedTheaters.length; n++) {
                    if (checkedTheaters[n].id === self.allSelectedTheaters[m].id) {
                        theaterCheck = true;
                        break;
                    }
                }
                if(!theaterCheck) {
                    checkedTheaters.push(self.allSelectedTheaters[m]);
                }
            }
            for (var i = 0; i < checkedTheaters.length; i++) {
                if (exhibitorName === "") {
                    exhibitorName = checkedTheaters[i].exhibitorName;
                } else {
                    if (exhibitorName !== checkedTheaters[i].exhibitorName && self.multiExhibitorsFlag) {
                        self.multiExhibitorsFlag = false;
                        //setTimeout(function(){ self.multiExhibitorsFlag = true; $scope.$apply(); }, 3000);
                        angular.element("#theaterName").focus();
                        break;
                    }
                }
            }
            if (self.multiExhibitorsFlag) {
                self.selectedTheaters = [];
                $uibModalInstance.close(checkedTheaters);
            }
        };

        /**
         * Init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        self.init = function () {
            resetFields();
            self.search();

        };

          /**
         * setSelectedTheaters
         * @function setSelectedTheaters
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
         */
        self.setSelectedTheaters = function (result) {
            if (self.selectedTheaters) {
                var index = 0,
                    selectedTheatersCount = self.selectedTheaters.length;
                for (index; index < selectedTheatersCount; index++) {
                    var existingTheaters = _.filter(result._embedded.theaterViews, { "id": self.selectedTheaters[index].id });
                    _(existingTheaters).each(function(existingTheater) {
                        existingTheater.selected = true;
                    });
                }
                self.selectedTheaters = [];
            }
            if (self.allSelectedTheaters) {
                for(var indx = 0; indx < self.allSelectedTheaters.length; indx++) {
                    var existingSelectedTheaters = _.filter(result._embedded.theaterViews, { "id": self.allSelectedTheaters[indx].id });
                    _(existingSelectedTheaters).each(function(existingSelectedTheater) {
                        existingSelectedTheater.selected = true;
                    });
                }
            }
        };

        $scope.$on("setTheatersSelected", function (event, data) {
            self.setSelectedTheaters(data);
        });

        $scope.$on("theaterSelected", function (event, data) {           
            var checkedTheaters = _.filter(data, {
                selected: true
            });
            var theaterCheck;
            for (var i = 0; i < checkedTheaters.length; i++) {
                theaterCheck = false;
                for (var j = 0; j < self.allSelectedTheaters.length; j++) {
                    if(self.allSelectedTheaters[j].id === checkedTheaters[i].id) {
                        theaterCheck = true;
                        break;
                    }
                }
                if (!theaterCheck) {
                    self.allSelectedTheaters.push(checkedTheaters[i]);
                }
            }
            for (var ind = 0; ind < data.length; ind++) {
                if(!data[ind].selected) {
                    for (var k = 0; k < self.allSelectedTheaters.length; k++) {
                        if(self.allSelectedTheaters[k].id === data[ind].id) {
                            self.allSelectedTheaters.splice(k, 1);
                            break;
                        }
                    }
                }
            }                
            
            theaters = data;
            self.disableAddButton = self.allSelectedTheaters.length > 0 ? false : true;
        });

        //INIT
        self.init();
    }

    controllers.controller("modalLookupTheaterController", ["$scope", "$uibModalInstance", "localStorageService", "modalLookupTheaterService", "exhibitor", "territoryId", "headerText", "isExCorporate", "isExOther", "selectedTheaters", "$q", "DEFAULT_PAGE_SIZE", modalLookupTheaterController]);
}());
