(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Password confirmation validation form directive
     * @class confirmPasswordValidator
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     */
    function confirmPasswordValidator() {

        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @param {jqueryElement} element jquery element
         * @param {object} attrs attributes
         * @param {object} ngModel ng model
         * @private
         * @memberOf angular_module.WB.cinehub.directives.confirmPasswordValidator
         */
        var link = function (scope, element, attrs, ngModel) {
            var value = "";
            ngModel.$validators.passwordsDoNotMatch = function (modelValue, viewValue) {
                if (ngModel.$isEmpty(modelValue)) {
                    // consider empty models to be valid
                    return true;
                }
                value = viewValue;
                return viewValue === scope.password;
            };
            scope.$watch("password", function (newVal) {
                ngModel.$setValidity("passwordsDoNotMatch", newVal === value);
            });
        };

        return {
            require: "ngModel",
            link: link,
            scope: {
                password: "="
            }
        };
    }

    directives.directive("confirmPasswordValidator", [confirmPasswordValidator]);
}());
