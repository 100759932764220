(function () {
    "use strict";

    /**
     * Promise factory
     * @class promiseFactory
     * @memberOf angular_module.WB.cinehub.services
     * @returns {promiseFactory}
     */
    var services = angular.module("WB.cinehub.services");

    /**
     * Promise factory
     * @class promiseFactory
     * @memberOf angular_module.WB.cinehub.services
     * @returns {promiseFactory}
     */
    function promiseFactory($q) {
        return {
            decorate: function (promise) {
                promise.success = function (callback) {
                    promise.then(callback);

                    return promise;
                };

                promise.error = function (callback) {
                    promise.then(null, callback);

                    return promise;
                };
            },
            defer: function () {
                var deferred = $q.defer();

                this.decorate(deferred.promise);

                return deferred;
            }
        };
    }

    services.factory("promiseFactory", ["$q", promiseFactory]);
}());
