(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * faq controller
     * @dec faq controller 
     * @class faqController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {service} faqService faq service
     * @param {service} localStorageService local storage service
     */
    function faqController(faqService, localStorageService) {

        /**
         * Instance of faqController
         * @type {faqController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.faqController
         */
        var self = this;

        /**
         * translations
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.faqController
         * @public
         */
        self.translations = localStorageService.get("translations");

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.faqController
         */
        self.pageHeaderItem = {
            pageTitle: self.translations.faq.labels.pageTitle,
            breadcrumbItems: [{
                linkTitle: self.translations.faq.labels.breadcrumbLinkTitle,
                link: "/faq"
            }]
        };

        /**
         * faq
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.faqController
         */
        self.faqs = [];

        /**
         * get faqs
         * @function getFAQs 
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.faqController
         */
        self.getFAQs = function () {
            faqService.getFAQs().success(function (result) {
                self.faqs = result._embedded.faqs;
            });
        };

        /**
         * Init
         * @function init 
         * @memberOf angular_module.WB.cinehub.controllers.faqController
         * @public
         */
        self.init = function () {
            self.getFAQs();
        };

        //INIT
        self.init();
    }

    controllers.controller("faqController", ["faqService", "localStorageService", faqController]);
}());
