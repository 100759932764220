(function () {
    "use strict";

    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Help controller
     * @class helpController
     * @memberOf angular_module.WB.cinehub.controllers.helpController
     * @desc This is Need Help controller 
     * @param {service} localStorageService localStorage service
     * @param {service} locationService
     * @param {helpContactService} helpContactService
     */
    function helpController( $window, localStorageService, locationService, helpContactService, territoriesService, emailService, DEFAULT_CONTACT_US_EMAIL) {

        /**
         * Instance to helpController
         * @memberOf angular_module.WB.cinehub.controllers.helpController
         * @private
         * @type {helpController}
         */
        var self = this;

        /**
         * translated labels/messages/values stored in local storage
         * @memberOf angular_module.WB.cinehub.controllers.helpController
         * @public
         * @type {object}
         */
        self.translations = localStorageService.get("translations");

        /**
         *  user
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.helpController
         */
        self.user = {};

        /**
         *  activeRegions
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.helpController 
         */
        self.activeRegions = null;
        
        /**
         * getLocaleByRegion
         * @memberOf angular_module.WB.cinehub.controllers.helpController
         * @function getLocaleByRegion
         * @param {obj} region region object
         * @public
         */
        self.getLocaleByRegion = function (region) {
            self.user = {};
            self.user.regionId = region.id;
            var localeData = locationService.filterLocaleByRegion(region);
            self.locales = localeData;
        };
        
        /**
         * set help contact
         * @memberOf angular_module.WB.cinehub.controllers.helpController
         * @private
         * @function setHelpContact
         */
        var setHelpContact = function () {
            helpContactService.getHelpContactEmail().then(function (contactEmail) {
                self.contactEmail = contactEmail;
            });
        };

        /**
         * set territory data
         * @memberOf angular_module.WB.cinehub.controllers.helpController
         * @function setTerritoryData
         * @param {obj} liveLocale locale object
         * @param {string} region region
         * @param {boolean} isRegionChanged isRegionChanged
         * @public
         */
        self.setTerritoryData = function (locale) {
            var selectedTerritory = _.filter(self.translations.values.territories, {
                id: locale.territoryId
            });
            self.user.territory = selectedTerritory[0].value;
            self.user.territoryId = locale.territoryId;
            self.user.regionId = locale.regionId;
            self.user.localeId = locale.id;
            self.user.locale = locale.name;
            getContactInfo();
        };
      
        /**
         * get contact info
         * @memberOf angular_module.WB.cinehub.controllers.helpController
         * @function getContactInfo
         * @private
         */
        var getContactInfo = function () {
            territoriesService.getTerritoryById(self.user.territoryId).success(function (result) {
                self.contactEmail = result._embedded.territories.length > 0 ? result._embedded.territories[0].contactInfo : "";
                $window.location.href = emailService.isValidEmail(self.contactEmail) ? "mailto:"+self.contactEmail : "mailto:"+DEFAULT_CONTACT_US_EMAIL;
            });
        };
        
        /**
         * Init
         * @memberOf angular_module.WB.cinehub.controllers.helpController
         * @function init
         * @public
         */
        self.init = function () {
            self.activeRegions =  locationService.getActiveRegions(self.translations.values.regions);
            setHelpContact();            
        };
        //INIT
        self.init();
    }

    controllers.controller("helpController", [ "$window", "localStorageService", "locationService", "helpContactService", "territoriesService", "emailService", "DEFAULT_CONTACT_US_EMAIL", helpController]);
}());

