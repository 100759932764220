(function () {
    "use strict";

    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Search exhibitors and theaters  controller
     * @dec The sarch exhibitors and theaters controller
     * @class searchExhibitorsTheatersController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {service} localStorageService localstorage service
     * @param {!angular.$window} $winow $window
     * @param {service} exhibitorsService exhibitros service
     * @param {service} theatersService theaters service
     * @param {service} deviceDetectorService device detector service
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     */
    function searchExhibitorsTheatersController(localStorageService, $window, exhibitorsService, theatersService, deviceDetectorService, DEFAULT_PAGE_SIZE) {

        /**
         * Instance of searchExhibitorsTheatersController
         * @type {searchExhibitorsTheatersController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         */
        var self = this;

        /**
         * Location info
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         * @type {object}
         */
        self.location = localStorageService.get("location");

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         */
        self.pageHeaderItem = {
            pageTitle: "",
            breadcrumbItems: [{
                linkTitle: "",
                link: ""
            }]
        };

        /**
         * User info
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         * @type {object}
         */
        self.user = localStorageService.get("userInfo");

        /**
         * The translations
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         */
        self.translations = localStorageService.get("translations");

        /**
         * tabs
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         * @public
         */
        self.tabs = [{
            heading: self.translations.manageExhibitorsTheaters.labels.exhibitors,
            templateUrl: "/modules/exhibitors/exhibitorsTab.html",
            active: true,
            tabName: "exhibitors"
        }, {
            heading: self.translations.manageExhibitorsTheaters.labels.theaters,
            templateUrl: "/modules/theaters/theatersTab.html",
            active: false,
            tabName: "theaters"
        }];

        /**
         * Filter query
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         * @type {object}
         */
        self.filterQuery = {
            name: "",
            localName: "",
            city: "",
            state: "",
            territoryId: "",
            territoryName: ""
        };

        /**
         * Theaters sort string
         * @type {string}
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         * @public
         */
        self.theatersSort = "name,asc";

        /**
         * Exhibitors sort string
         * @type {string}
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         * @public
         */
        self.exhibitorsSort = "name,asc";

        /**
         * Exhibitors data
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         */
        self.exhibitorsData = {
            page: {
                size: DEFAULT_PAGE_SIZE,
                number: 1,
                totalElements: 0
            }
        };

        /**
         * Theaters data
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         */
        self.theatersData = {
            page: {
                size: DEFAULT_PAGE_SIZE,
                number: 1,
                totalElements: 0
            }
        };

        /**
         * Get territory id 
         * @function getTerritoryId
         * @param {int} territory
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         * @private
         * @returns {int}
         */
        var getTerritoryId = function (territory) {
            var territoryId = "";
            territory = self.user.role === "territoryAdmin" ? self.location.territoryId : territory;
            if (territory >= 0) {
                territoryId = territory;
            } else if (territory && territory.territoryId) {
                territoryId = territory.territoryId;
            } else {
                territoryId = "";
            }
            return territoryId;
        };

        /**
         * Get all exhibitors
         * @function getAllExhibitors
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         * @public
         * @returns {promise}
         * @param {int} territory territory
         * @param {int} page current page
         * @param {int} size page size
         * @param {string} sort sort string
         */
        self.getExhibitors = function (territory, page, size, sort) {
            var territoryId = getTerritoryId(territory);
            self.exhibitorsSort = sort || self.exhibitorsSort;

            return exhibitorsService.findByNameLikeOrLocalNameLikeAndCityLikeOrStateLikeOrTerritoryNameLikeAndTerritoryId(territoryId, self.exhibitorsData.page.size, self.exhibitorsData.page.number, self.exhibitorsSort, self.filterQuery.name, self.filterQuery.localName, self.filterQuery.city, self.filterQuery.state, self.filterQuery.territoryName).success(function (result) {
                self.exhibitorsData = result;
                self.tabs[0].heading = self.translations.manageExhibitorsTheaters.labels.exhibitors;
                self.tabs[0].heading += " (" + self.exhibitorsData.page.totalElements + ")";
            });
        };

        /**
         * Get all theaters
         * @function getAllTheaters
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         * @public
         * @returns {promise}
         * @param {int} territory territory
         * @param {int} page current page
         * @param {int} size page size
         * @param {string} sort sort string
         */
        self.getTheaters = function (territory, page, size, sort) {
            var territoryId = getTerritoryId(territory);
            self.theatersSort = sort || self.theatersSort;

            return theatersService.findByNameLikeOrLocalNameLikeAndCityLikeOrStateLikeOrTerritoryNameLikeAndTerritoryId(territoryId, self.theatersData.page.size, self.theatersData.page.number, self.theatersSort, self.filterQuery.name, self.filterQuery.localName, self.filterQuery.city, self.filterQuery.state, self.filterQuery.territoryName).success(function (result) {
                self.theatersData = result;
                self.tabs[1].heading = self.translations.manageExhibitorsTheaters.labels.theaters;
                self.tabs[1].heading += " (" + self.theatersData.page.totalElements + ")";
            });
        };

        /**
         * Open exhibitor details
         * @function openExhibitorDetails
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         * @public
         * @param {int} exhibitorId exhibitor id
         */
        self.openExhibitorDetails = function (exhibitorId) {
            $window.open("/exhibitor/" + exhibitorId + "/", "_blank");
        };

        /**
         * Open theater details
         * @function openUserProfile
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         * @public
         * @param {int} theaterId theater id
         */
        self.openTheaterDetails = function (theaterId) {
            $window.open("/theater/" + theaterId + "/", "_blank");
        };

        /**
         * Get data
         * @function getData
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         * @param {promise}
         */
        self.getData = function () {
            if (self.user.role === "homeOfficeAdmin") {
                self.getExhibitors();
            } else {
                self.getExhibitors(self.location.territoryId);
            }

            if (self.user.role === "homeOfficeAdmin") {
                self.getTheaters();
            } else {
                self.getTheaters(self.location.territoryId);
            }
        };

        /**
         * Reset data
         * @function reset
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         */
        self.reset = function () {
            self.filterQuery = {
                name: "",
                localName: "",
                city: "",
                state: "",
                territoryId: "",
                territoryName: ""
            };
            self.exhibitorsData = {
                _embedded: {
                    exhibitors: []
                },
                page: {
                    size: DEFAULT_PAGE_SIZE,
                    number: 1,
                    totalElements: 0
                }
            };
            self.tabs[0].heading = self.translations.manageExhibitorsTheaters.labels.exhibitors;
            self.tabs[0].heading += " (0)";
            self.theatersData = {
                _embedded: {
                    theaters: []
                },
                page: {
                    size: DEFAULT_PAGE_SIZE,
                    number: 1,
                    totalElements: 0
                }
            };
            self.tabs[1].heading = self.translations.manageExhibitorsTheaters.labels.theaters;
            self.tabs[1].heading += " (0)";
            self.getData();
        };

        /**
         * Tab change
         * @function tabChange
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         * @param {object} activeTab active tab
         */
        self.tabChange = function (activeTab) {
            self.tabs.forEach(function (tab) {
                tab.active = false;
            });
            activeTab.active = true;
        };

        /**
         * Init
         * @function init
         * @memberOf angular_module.WB.cinehub.controllers.searchExhibitorsTheatersController
         * @public
         */
        self.init = function () {
            if (deviceDetectorService.isMobileOrTablet()) {
                $window.location.href = "/";
                return false;
            }
            self.pageHeaderItem.pageTitle = self.translations.manageExhibitorsTheaters.labels.pageTitle;
            self.pageHeaderItem.breadcrumbItems[0].linkTitle = self.translations.manageExhibitorsTheaters.labels.breadcrumbLinkTitle;
            self.getData();
        };

        //INIT
        self.init();
    }

    controllers.controller("searchExhibitorsTheatersController", ["localStorageService", "$window", "exhibitorsService", "theatersService", "deviceDetectorService", "DEFAULT_PAGE_SIZE", searchExhibitorsTheatersController]);
}());
