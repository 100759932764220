(function () {
    "use strict";
    var filters = angular.module("WB.cinehub.filters");

    /**
     * Slice by word angular filter 
     * @class sliceByWord
     * @memberOf angular_module.WB.cinehub.filters
     * @desc This slice by word filter 
     * @returns {string}
     */
    function sliceByWord() {

        return function (originalStr, position) {
            return originalStr.substring(0, position).replace(/\s+\S*$/, "");
        };
    
    }

    filters.filter("slicebyword", sliceByWord);

} ());