(function () {
    "use strict";
    var services = angular.module("WB.cinehub.services");

    /**
     * Hero url convertor service 
     * @class heroUrlConvertorService
     * @memberOf angular_module.WB.cinehub.services
     * @desc This is a hero url convertor service
     * @param {constant} API_ROOT API_ROOT
     */
    function heroUrlConvertorService(API_ROOT) {

        /**
         * Convert 
         * @function convert
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.heroUrlConvertorService
         * @returns {object[]}
         * @param {object[]} data data
         */
        this.convert = function (data) {
            data.forEach(function (data) {
                if (/^highlightedFilms/ig.test(data.heroImage)) {
                    data.heroImage = API_ROOT + data.heroImage;
                }
            });
            return data;
        };
    }

    services.service("heroUrlConvertorService", ["API_ROOT", heroUrlConvertorService]);
}());
