(function (){
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");
    /**
     * Multi factor Registration Controller
     * @class mfa
     * @memberOf angular_module.WB.cinehub.controllers
     * @desc This is Multi Factor Registration controller
     * @param {!angular.$scope} $scope angular scope
     * @param {!angular.$rootScope} $rootScope angular root scope
     * @param {window} window 
     * @param {object} localStorageService 
     * @param {MFA_REG_URL_INTERNAL} MFA_REG_URL_INTERNAL
     * @param {MFA_REG_URL_EXTERNAL} MFA_REG_URL_EXTERNAL
     */
    function mfaRegistrationController($scope, $rootScope, $window, localStorageService, MFA_REG_URL_INTERNAL, MFA_REG_URL_EXTERNAL) {
        /**
         * Instance to mfaController
         * @private
         * @type {mfaController}
         * @memberOf angular_module.WB.cinehub.controllers.mfaController
         */
        var self = this;
        /**
         * translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.mfaController
         */
        self.translations = localStorageService.get("translations");

        /**
         * Navigate to login page
         */
        self.login = function() {
            $window.location.href = "/login";
        };

        /**
         * Enroll MFA
         */
        self.enroll = function() {
            var actType = localStorageService.get("userActType");
            if (actType === "INTERNAL") {
                $window.open(MFA_REG_URL_INTERNAL, "_blank");
            } else {
                $window.open(MFA_REG_URL_EXTERNAL, "_blank");
            }
        };
    }

    controllers.controller("mfaRegistrationController", ["$scope", "$rootScope", "$window", "localStorageService", "MFA_REG_URL_INTERNAL", "MFA_REG_URL_EXTERNAL", mfaRegistrationController]);
}());
