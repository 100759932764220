(function() {
    "use strict";
    var services = angular.module("WB.cinehub.services");
    
     /**
     * MFA service 
     * @class mfaService
     * @memberOf angular_module.WB.cinehub.services
     * @desc This is MFA service 
     * @param {!angular.$http} $http angular $http service
     * @param {string} API_ROOT API_ROOT constant 
     * @param {service} localStorageService local storage service
     */
    function mfaService($http, API_ROOT, localStorageService) {
		
        /**
         * translations
         * @memberOf angular_module.WB.cinehub.services.loginService
         * @private
         * @type {object}
         */
        var translations = localStorageService.get("translations");

        /**
         * Get factors for the given userId 
         * @memberOf angular_module.WB.cinehub.services.mfaService
         * @public
         * @function getFactors
         * @param {string} userId OKTA userID
         * @returns {promise}
         */
        this.getFactors = function (userId, actType) {
            return $http.post(API_ROOT + "okta/mfa/getAllUserFactors", {
                "oktaUserId": userId,
                "accountType": actType
            }, {
                errorMessage: translations.error.messages.errorPageGenericMessage,
                alertKey: "anonymousUserAlert",
                showSpinner: false
            });
        };

        /**
         * Send the push notification
         * @memberOf 
         * @public
         * @function push
         * @param {string} userId OKTA userID
         * @param {string} factorId factorId
         * @param {string} accountType Account Type
         */
        this.push = function(userId, factorId, accountType) {
            return $http.post(API_ROOT + "okta/mfa/verifyFactor", {
                "oktaUserId": userId,
                "accountType": accountType,
                "factorId": factorId
            }, {
                errorMessage: translations.error.messages.errorPageGenericMessage,
                alertKey: "anonymousUserAlert",
                showSpinner: false
            });
        };
        
       /**
         * Send the heartbeat to check the status of push notification   
         * @memberOf angular_module.WB.cinehub.services.mfaService
         * @public
         * @function getFactheartbeators
         * @param {string} accountType Account Type
         * @param {string} poll the poll-href url
         * @returns {promise}
        */
        this.poll = function(userId, accountType, pollUrl) {
            return $http.post(API_ROOT + "okta/mfa/verifyPushFactor", {
                "oktaUserId": userId,
                "accountType": accountType,
                "pollUrl": pollUrl
            }, {
                errorMessage: translations.error.messages.errorPageGenericMessage,
                alertKey: "anonymousUserAlert",
                showSpinner: false
            });
        };

       /**
         * Validate the passcode entered by the user   
         * @memberOf angular_module.WB.cinehub.services.mfaService
         * @public
         * @function verifyPassCode
         * @param {string} accountType Account Type
         * @param {string} poll the poll-href url
         * @returns {promise}
        */
        this.verifyPassCode = function(userId, factorId, accountType, passCode) {
            return $http.post(API_ROOT + "okta/mfa/verifyFactor", {
                "oktaUserId": userId,
                "accountType": accountType,
                "factorId": factorId,            
                "passCode": passCode
            }, {
                errorMessage: translations.error.messages.errorPageGenericMessage,
                alertKey: "anonymousUserAlert",
                showSpinner: false
            });
        };
        
        /**
         * REST endpoint to fetch the token which is persisted in the Redis cache as soon as it was authenticated
         * @param {string} userId
         * @returns 
         */
        this.fetchTokenFromCache = function(userId, mfaTransactionId) {
            return $http.get(API_ROOT + "okta/mfa/" + userId + "/" + mfaTransactionId + "/token&ignoreLocalizationString", {
                errorMessage: translations.error.messages.errorPageGenericMessage,
                alertKey: "anonymousUserAlert",
                showSpinner: false
            });
        };

         /**
          * Service to persist autoMFA push flag
          * @param {string} userId
          * @param {string} oktaUserId
          * @param {string} mfaAutoPush
          * @returns
          */
        this.setUserAutoMFAPushFlag = function(userId, oktaUserId, mfaAutoPush) {
            return $http.put(API_ROOT + "okta/mfa/" + oktaUserId + "/autoPush",
                 { "userId": userId, "oktaUserId": oktaUserId, "mfaAutoPush": mfaAutoPush }, {
                     errorMessage: translations.error.messages.errorPageGenericMessage,
                     alertKey: "anonymousUserAlert",
                     showSpinner: false
                 });
        };
    }
    services.service("mfaService", ["$http", "API_ROOT", "localStorageService", mfaService]);	
}());
