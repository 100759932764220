(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * My profile controller
     * @dec The my profile controller
     * @class myProfileController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.RootScope} $rootScope angular root scope
     * @param {$uibModal} $uibModal modal window
     * @param {service} localStorageService local storage service
     * @param {service} userService user service
     * @param {service} exhibitorsService exhibitor service
     * @param {service} theatersService theater service
     * @param {service} registrationService registration service
     * @param {service} additionalAffiliationService additionalAffiliation service
     * @param {service} convertLocalizationCodeToValueService convert localization code to value
     * @param {service} passwordService password service
     * @param {service} emailService email service
     * @param {constant} STATE_LIST STATE_LIST
     */
    function myProfileController($rootScope, $uibModal, localStorageService, userService, additionalAffiliationService, exhibitorsService, theatersService, registrationService, convertLocalizationCodeToValueService, passwordService, emailService, locationService, STATE_LIST) {
        /**
         * Instance of myProfileController
         * @type {myProfileController}
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        var self = this,

            /**
             * Copy of user object
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.myProfileController
             */
            originalUserInfo = null;

        /**
         * Active tab
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        self.activeTab = "editProfile";

        /**
         * User profile format
         * @type {angular.Form}
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @public
         */
        self.userProfileForm = null;

        /**
         * List of the states for Canada and US 
         * @type {constant}
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @public
         */
        self.stateList = STATE_LIST;

        /**
         * USA country code constant
         * @type {constant}
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @public
         */
        self.USA = "176";

        /**
         * CANADA country code constant
         * @type {constant}
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @public
         */
        self.CANADA = "177";

        /**
         * HONG_KONG country code constant
         * @type {constant}
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @public
         */
        self.HONG_KONG = "131";

        /**
         * JAPAN country code constant
         * @type {constant}
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @public
         */
        self.JAPAN = "105";

        /**
         * KOREA country code constant
         * @type {constant}
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @public
         */
        self.KOREA = "130";

        /**
         * TAIWAN country code constant
         * @type {constant}
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @public
         */
        self.TAIWAN = "134";

        /**
         * table header for additional affiliations
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @type {regex}
         */
        self.headers= additionalAffiliationService.headers();

        /**
         * addtional affiliations list to store additional affiliation object 
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @type {array}
         * @public
         */
        self.additionalAffiliations = [];

        /**
         * additional affiliation object to store affiliation details
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @type {array}
         * @public
         */
        self.additionalAffiliation = {};

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        self.pageHeaderItem = {
            pageTitle: "",
            breadcrumbItems: [{
                linkTitle: "",
                link: "/my-profile"
            }]
        };

        /**
         * The user
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @type {object[]}
         */
        self.currentProfile = localStorageService.get("userInfo");

        /**
         * Flag if user is WB user
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @type {boolean}
         */
        self.isWBUser = false;

        /**
         * The translations
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        self.translations = localStorageService.get("translations");

        /**
         * Convert localization code to value
         * @type {service}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        self.convertLocalizationCodeToValueService = convertLocalizationCodeToValueService;

        /**
         * Is display change password tab
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @type {boolean}
         * @desc Commenting out Okta stuff for now. CIN-1273
         */
        self.isDisplayChangePasswordTab = true;

        /**
         * Exhibitor Relationship
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        self.exhibitorRelationship = [];

        /**
         * Theater Affiliations
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        self.theaterAffiliations = [];

        /**
         * Additional countries
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        self.additionalCountries = [];

        /**
         * User locales
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        self.locales = [];

        /**
         * getAdditionalLocaleByRegion
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @function getAdditionalLocaleByRegion
         * @public
         */
        self.getAdditionalLocaleByRegion = function () {
            var result =  additionalAffiliationService.getAdditionalLocaleByRegion(self.additionalAffiliation, self.user, self.additionalAffiliations, self.isAdditionalAffiliation);
            setResultValues(result);
            localStorageService.set("additionalLocales", self.additionalAffiliation.additionalLocales);
        };

        /**
         * getAdditionalLocaleByRegionExOther
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function getAdditionalLocaleByRegionExOther
         * @public
         */
        self.getAdditionalLocaleByRegionExOther = function (selectedItem) {
            self.additionalAffiliation = null;
            self.additionalAffiliation = {
                additionalRegion: selectedItem
            };
            var result =  additionalAffiliationService.getAdditionalLocaleByRegion(self.additionalAffiliation, self.user, self.additionalAffiliations, self.isAdditionalAffiliation, "myProfile");
            setResultValues(result);
            localStorageService.set("additionalLocales", self.additionalAffiliation.additionalLocales);
        };
        /**
         * Set Additional Territory Data
         * @function setAdditionalTerritoryData
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @return {(boolean|object[])}
         */
         
        self.setAdditionalTerritoryData = function () {
            self.additionalAffiliation =  additionalAffiliationService.setAdditionalTerritoryData(self.additionalAffiliation);
        };

        /**
         * set additional exhibitors
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @function setAdditionalExhibitors
         * @public
         */
        self.setAdditionalExhibitors = function(selectedItem){
            var result =  additionalAffiliationService.setAdditionalExhibitors(selectedItem,self.additionalAffiliation, self.user, self.additionalAffiliations, self.isAdditionalAffiliation);
            setResultValues(result);
        };
        /**
         * set additional exhibitors for Ex-Other
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function setAdditionalExhibitorsExOther
         * @public
         */
        self.setAdditionalExhibitorsExOther = function(selectedItem, isAddedOrRemoved){
            var origAddlAffln= _.cloneDeep(self.additionalAffiliation);
            //self.user.exhibitorRelationship = window.helpers.isJson(self.user.exhibitorRelationship) ? JSON.parse(self.user.exhibitorRelationship) : self.user.exhibitorRelationship;
            var result =  additionalAffiliationService.setAdditionalExhibitors(selectedItem,self.additionalAffiliation, self.user, self.additionalAffiliations, self.isExistingUser, isAddedOrRemoved);
            setResultValuesExOther(result, origAddlAffln);            
        };
        /**
         * set result values for Ex-Other
         * @function setResultValuesExOther
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @public
         */
        var setResultValuesExOther = function (result, origAddlAffln) {
            self.additionalAffiliation = !result.additionalAffiliation ? origAddlAffln : result.additionalAffiliation;
            self.user = result.user;
            self.additionalAffiliations = result.additionalAffiliations;
        };
        /**
         * Additional Exhibitor/Company Not Found Selected.
         * @function additionalCompanyNotSelected
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @public
         */
        self.additionalCompanyNotSelected = function () {
            self.additionalAffiliation =  additionalAffiliationService.additionalCompanyNotSelected(self.additionalAffiliation, self.user.userType);
        };

        /**
         * Open lookup theater modal
         * @function openLookupTheaterModal
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        self.openLookupTheaterModal = function () {
            var modalInstance = $uibModal.open({
                templateUrl: "/modules/lookupTheaterModal/modalLookupTheater.html",
                controller: "modalLookupTheaterController",
                controllerAs: "lookupTheaterCtrl",
                keyboard: false,
                backdrop: "static",
                size: "lg",
                resolve: {
                    exhibitor: function () {
                        return self.additionalAffiliation.exhibitorObj;
                    },
                    territoryId: function () {
                        return self.additionalAffiliation.territoryId;
                    },
                    headerText: function () {
                        return self.translations.registration.labels.exhibitorLookup;
                    },
                    isExCorporate: function () {
                        return self.user.userType === "ex-corporate";
                    },
                    isExOther: function () {
                        return self.user.userType === "ex-other";
                    },
                    selectedTheaters: function () {
                        return self.additionalAffiliation.selectedTheaters;
                    }
                }
            });

            modalInstance.result.then(function (selectedTheaters) {
                if (self.user.userType === "ex-corporate") {
                    self.setAdditionalExhibitors(selectedTheaters[0]);
                } else if(self.user.userType === "ex-other") {
                    self.setAdditionalExhibitorsExOther(selectedTheaters[0], "added");
                } 
            });
        };

        /**
         * get getAdditional Exhibitors
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @function getExhibitors
         * @param {string} keyword search term
         * @public
         */
        self.getAdditionalExhibitors = function (keyword) {
            return registrationService.getExhibitors(keyword, self.additionalAffiliation.territoryId);
        };

        /**
         * Check if the user can add addtional affiliations.
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @function showAdditionalAffiliations
         * @public
         */
        self.showAdditionalAffiliations = function () {
            return self.user
                && self.user.userType
                && (self.user.userType.toLowerCase() === "ex-corporate" || self.user.userType.toLowerCase() === "ex-other");
        };

        /**
         * removeAdditionalAffiliation
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @function removeAdditionalAffiliation
         * @param {int} theaterId theater id
         * @public
         */
        self.removeAdditionalAffiliation = function (theaterId) {
            var res =  additionalAffiliationService.removeAdditionalAffiliation(theaterId,
                self.additionalAffiliations, self.user);
            if (res && res.user) {
                self.additionalAffiliations = res.addlAfflns;
                self.user = res.user;
            }
        };
        /**
         * removeAdditionalAffiliationExOther
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function removeAdditionalAffiliationExOther
         * @param {int} theaterId theater id
         * @public
         */
        self.removeAdditionalAffiliationExOther = function (theaterId) {
            var res =  additionalAffiliationService.removeAdditionalAffiliationExOther(
                theaterId, self.additionalAffiliations, self.user, self.additionalAffiliation);
            if (res && res.user) {
                self.additionalAffiliation = res.additionalAffiliation;
                self.additionalAffiliations = res.addlAfflns;
                self.user = res.user;
            }
        };
        /**
         * getSelectedAffiliationId
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @function getSelectedAffiliationId
         * @param {obj} exhibitor exhibitor
         * @public
         */
        self.getSelectedAffiliationId = function (exhibitor) {
            return additionalAffiliationService.getSelectedAffiliationId(exhibitor);
        };

        /**
         * Conver additional country code tags input object
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @function converAdditionalCountryCodeIntoTagsInputObj
         */
        var converAdditionalCountryCodeIntoTagsInputObj = function () {
            var additionalCountries = window.helpers.isJson(self.user.additionalCountries) ? JSON.parse(self.user.additionalCountries) : "",
                index = 0,
                additionalCountriesLength = 0;
            if (additionalCountries) {
                additionalCountriesLength = additionalCountries.length;
                self.additionalCountries = [];
                for (index; index < additionalCountriesLength; index++) {
                    var code=_.find(self.translations.values.territories, {
                        code: additionalCountries[index].toString()
                    });
                    if (code) {
                        self.additionalCountries.push(code);
                    }
                }
            }
        };

        /**
         * Convert additional country tags input obj into array codes
         * @function convertAdditionalCountryTagsInputObjIntoArrayCodes
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @private
         */
        var convertAdditionalCountryTagsInputObjIntoArrayCodes = function () {
            var additionalCountriesLength = 0,
                index = 0,
                additionalCountries = [];
            if (self.additionalCountries) {
                additionalCountriesLength = self.additionalCountries.length;
                if (additionalCountriesLength > 0) {
                    for (index; index < additionalCountriesLength; index++) {
                        additionalCountries.push(self.additionalCountries[index].code);
                    }
                    self.user.additionalCountries = JSON.stringify(additionalCountries);
                }
            }
        };

        /**
         * Parse phone number to display country phone code and number
         * @function parsePhoneNumber
         * @returns {type} Description
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        var parsePhoneNumber = function () {
            var parsedPhone = "";
            if (self.user.primaryPhone) {
                parsedPhone = self.user.primaryPhone.match(/\d+/ig);
                if (parsedPhone.length > 1) {
                    self.user.phoneCountryCode = parsedPhone[0];
                    self.user.phone = parseInt(parsedPhone.slice(1).join(""), 10);
                }
            }
        };

        /**
         * Reset form
         * @function resetForm
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        var resetForm = function () {
            self.userProfileForm.$setPristine();
            self.userProfileForm.$setUntouched();
            self.additionalAffiliation = null;     
            self.additionalAffiliations = null;                 
        };

        /**
         * Get user local
         * @function getUserLocals
         * @private
         * @returns {type} Description
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        var getUserLocals = function () {
            self.locales = _.filter(self.translations.values.locales, {
                "extraCode": self.user.territoryId
            });
        };

        /**
         * Get my profile 
         * @function getMyProfile
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        var getMyProfile = function () {
            userService.getMyProfile(self.currentProfile.loginName).success(function (user) {
                self.user = user[0];
                self.isWBUser = self.user.accountType === "INTERNAL" ? true : false;
                self.isAdditionalAffiliation =  additionalAffiliationService.isAdditionalAffiliation(self.user);
                self.exhibitorRelationship = window.helpers.isJson(self.user.exhibitorRelationship) ? JSON.parse(self.user.exhibitorRelationship) : "";
                self.theaterAffiliations = window.helpers.isJson(self.user.theaterAffiliations) ? JSON.parse(self.user.theaterAffiliations) : "";
                self.user.exhibitorRelationship = self.exhibitorRelationship;
                converAdditionalCountryCodeIntoTagsInputObj();
                parsePhoneNumber();
                getUserLocals();
                originalUserInfo = _.cloneDeep(self.user);

                self.translations.values.titles = _.filter(self.translations.values.titles, {
                    extraCode: self.user.userType.toLowerCase()
                });
                self.translations.values.departments = _.filter(self.translations.values.departments, {
                    extraCode: self.user.userType.toLowerCase()
                });
                if ((self.user.userType === "ex-corporate" || self.user.userType === "ex-other") && self.isAdditionalAffiliation) {
                    var processedData =  additionalAffiliationService.processAdditionalExhibitors(self.user, self.currentProfile, self.isAdditionalAffiliation);
                    self.additionalExhibitors = processedData.additionalExhibitors;
                    self.primaryExhibitor = processedData.primaryExhibitor;
                    self.currentExhibitor = processedData.currentExhibitor;    
                }     
            });
        };

        /**
         * Update my profile
         * @function updateMyProfile
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @private
         */
        var updateMyProfile = function () {
            if (self.userProfileForm.$valid) {
                convertAdditionalCountryTagsInputObjIntoArrayCodes();
                self.user.exhibitorRelationship = self.exhibitorRelationship ? JSON.stringify(self.exhibitorRelationship) : "";
                self.user.theaterAffiliations = self.theaterAffiliations ? JSON.stringify(self.theaterAffiliations) : "";
                self.user.primaryPhone = "+" + self.user.phoneCountryCode + "-" + self.user.phone;

                return userService.updateMyProfile(self.user).success(function (user) {
                    _.merge(self.currentProfile, self.user);
                    localStorageService.set("userInfo", self.currentProfile);
                    converAdditionalCountryCodeIntoTagsInputObj();
                    originalUserInfo = _.cloneDeep(self.user);
                    self.user = user[0];
                    if ((self.user.userType === "ex-corporate" || self.user.userType === "ex-other") && !_.isEmpty(self.additionalAffiliations)) {
                        addAddtiionalAffiliaitonModal();
                    }
                    resetForm();
                    getMyProfile();
                }).error(function () {
                    self.user = _.cloneDeep(originalUserInfo);
                });
            }
        };

        /**
         * open download information modal
         * @function addAddtiionalAffiliaitonModal
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        var addAddtiionalAffiliaitonModal = function () {
            $uibModal.open({
                templateUrl: "confirmationTemplate.html",
                controller: "modalConfirmationController",
                controllerAs: "modalConfirmationCtrl",
                keyboard: false,
                backdrop: "static",
                size: "md",
                resolve: {
                    message: function () {
                        return self.translations.emailTemplates.registrationApproved.additionalTerritoryMessage;
                    },
                    headerTitle: function () {
                        return self.translations.myProfile.labels.addAffiliationConfirmation;
                    },
                    showAffirmativeBtn: function () {
                        return false;
                    },
                    affirmativeBtnText: function () {
                        return "";
                    },
                    cancelBtnText: function () {
                        return self.translations.cart.labels.closeButton;
                    }
                }
            });
        };

        /**
         * set result value
         * @function setResultValues
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @public
         */
        var setResultValues = function (result) {
            self.additionalAffiliation = result.additionalAffiliation;
            self.user = result.user;
            self.additionalAffiliations = result.additionalAffiliations;
        };        

        /**
         * Reset password fields
         * @function resetPasswordFields
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        var resetPasswordFields = function () {
            self.user.oldPassword = "";
            self.user.newPassword = "";
            self.user.confirmNewPassword = "";
            resetForm();
        };

        /**
         * Update user password
         * @function updateUserPassword
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        var updateUserPassword = function () {
            if (self.userProfileForm.$valid) {
                passwordService.setNewPasswordForUser(self.user.oldPassword, self.user.newPassword).success(function (response) {
                    if (response.status === "success") {
                        $rootScope.$emit("showAlert", "success", self.translations.myProfile.messages.passwordUpdatedSuccessfully, "alert");
                        resetPasswordFields();
                    } else if (response.status === "failure" ) {
                        self.userProfileForm.oldPassword.$setValidity("wrongPassword", !(response.code === "OKT_401"));
                        self.userProfileForm.newPassword.$setValidity("passwordNotCompliant", !(response.code === "OKT_110"));
                    }
                });
            }
        };

        /**
         * Validate password
         * @function validatePassword
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        var validatePassword = function () {
            self.userProfileForm.oldPassword.$setValidity("wrongPassword", true);
            self.userProfileForm.newPassword.$setValidity("passwordNotCompliant", true);
            self.userProfileForm.newPassword.$setValidity("passwordRequirements", !passwordService.invalidPassword(self.user.newPassword));
            self.userProfileForm.newPassword.$setValidity("passwordUsernameError", !passwordService.passwordUsernameError(self.user.newPassword, self.user.loginName, self.user.firstName, self.user.lastName));
            self.userProfileForm.newPassword.$setValidity("sameOldPassword", !(self.user.newPassword === self.user.oldPassword));
            self.userProfileForm.confirmNewPassword.$setValidity("confirmNewPassword", self.user.newPassword === self.user.confirmNewPassword);
        };

        /**
         * Change tab
         * @function changeTab
         * @param {string} activeTabName active tab name
         * @public
         */
        self.changeTab = function (activeTabName) {
            self.activeTab = activeTabName;
            self.user = _.cloneDeep(originalUserInfo);
            if (self.activeTab === "editProfile") {
                window.location.reload();   
            }
            resetForm();
        };

        /**
         * check if its isNonEnglishTerritory
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function isNonEnglishTerritory
         * @private
         */
        self.isNonEnglishTerritory = function () {
            return locationService.isNonEnglishTerritory(self.user);
        };

        /**
         * Update user
         * @function updateUser
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         */
        self.updateUser = function () {
            if (self.activeTab === "changePassword") {
                if (!self.userProfileForm.oldPassword.$error.required && !self.userProfileForm.newPassword.$error.required && !self.userProfileForm.confirmNewPassword.$error.required) {
                    validatePassword();
                }
                updateUserPassword();
            } else {
                updateMyProfile();
            }
        };

        /**
         * Init
         * @function init
         * @memberOf angular_module.WB.cinehub.controllers.myProfileController
         * @public
         */
        self.init = function () {
            getMyProfile();
            self.activeRegions =  locationService.getActiveRegions(self.translations.values.regions);
            self.pageHeaderItem.pageTitle = self.translations.myProfile.labels.pageTitle;
            self.pageHeaderItem.breadcrumbItems[0].linkTitle = self.translations.myProfile.labels.breadcrumbLinkTitle;

        };

        //INIT
        self.init();
    }

    controllers.controller("myProfileController", ["$rootScope", "$uibModal", "localStorageService", "userService", "additionalAffiliationService", "exhibitorsService", "theatersService", "registrationService", "convertLocalizationCodeToValueService", "passwordService", "emailService", "locationService", "STATE_LIST", myProfileController]);
}());
