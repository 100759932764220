(function () {
    "use strict";

    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Film controller
     * @dec The film controller
     * @class angular_module.WB.cinehub.controllers.filmController
     * @param {service} $cookies $cookies service
     * @param {!angular.Scope} $scope angular scope
     * @param {!angular.$state} $state angular state service
     * @param {!angular.$location} $location angular location service
     * @param {!angular.$timeout} $timeout angular timeout
     * @param {$uibModal} $uibModal uib modal
     * @param {service} filmService film service
     * @param {service} assetsService assets service
     * @param {service} deviceDetectorService device detector service
     * @param {service} localStorageService local storage service
     * @param {service} translateService translate service
     * @param {service} filmRatingService filmRating service
     * @param {constant} SECURE_COOKIES SECURE_COOKIES
     * @param {contant} API_EFD API_EFD
     * @param {constant} DOMAIN DOMAIN
     * @param {service} videoSourceGeneratorService video source generator service
     * @TODO: Remove $timeout hacks
     */
    function filmController($cookies,
        $rootScope,
        $scope,
        $state,
        $location,
        $timeout,
        $uibModal,
        $window,
        filmService,
        assetsService,
        deviceDetectorService,
        localStorageService,
        translateService,
        filmRatingService,
        SECURE_COOKIES,
        API_EFD,
        videoSourceGeneratorService,
        DOMAIN) {

        /**
         * Instance to filmController
         * @type {filmController}
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @private
         */
        var self = this,

            /**
             * Film id
             * @type {int}
             * @memberOf angular_module.WB.cinehub.controllers.filmController
             * @private
             */
            filmId = window.WB.cinehub.preload.filmId,

            /**
             * Eis id
             * @type {int}
             * @memberOf angular_module.WB.cinehub.controllers.filmController
             * @private
             */
            eisId = window.WB.cinehub.preload.eisId,

            /**
             * Original path
             * @type {string}
             * @memberOf angular_module.WB.cinehub.controllers.filmController
             * @private
             */
            originalPath = "",

            /**
             * location
             * @memberOf angular_module.WB.cinehub.controllers.filmController
             * @public
             * @type {object}
             */
            location = localStorageService.get("location");

        /**
         * Is source view 
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @public
         * @type {boolean}
         */
        self.isSourceView = window.WB.cinehub.preload.isSourceView;

        /**
         * API_EFD
         * @constant
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @private
         * @type {string}
         */
        self.API_EFD = API_EFD;

        /**
         * Device detector
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @public
         * @type {service}
         */
        self.deviceDetector = deviceDetectorService;

        /**
         * Read more
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @public
         * @type {bool}
         */
        self.readMore = false;
        
        /**
         * User info
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @private
         */
        self.user = localStorageService.get("userInfo");

        /**
         * translations
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @public
         * @type {object}
         */
        self.translations = localStorageService.get("translations");

        /**
         * impersonate user flag
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");
        
        /**
         * cookies enabled flag
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @public
         * @type {bool}
         */
        self.cookiesEnabled = true;

        /**
         * Tabs definition
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        self.tabs = [{
            heading: self.translations.filmDetails.labels.detailsTab,
            route: "film.details",
            active: false,
            hasContent: false,
            defaultHeading: "details",
            count: 0
        }, {
            heading: self.translations.filmDetails.labels.materialsTab,
            route: "film.materials.artwork",
            active: false,
            hasContent: false,
            defaultHeading: "materials",
            count: 0
        }, {
            heading: self.translations.filmDetails.labels.strategyTab,
            route: "film.strategy.trailerPlacement",
            active: false,
            hasContent: false,
            defaultHeading: "strategy",
            count: 0
        }, {
            heading: self.translations.filmDetails.labels.newsTab,
            route: "film.news",
            active: false,
            hasContent: false,
            defaultHeading: "news",
            count: 0
        }, {
            heading: self.translations.filmDetails.labels.antiPiracyTab,
            route: "film.antiPiracy",
            active: false,
            hasContent: false,
            defaultHeading: "antiPiracy",
            count: 0
        }, {
            heading: self.translations.filmDetails.labels.activityHistoryTab,
            route: "film.activityHistory",
            active: false,
            hasContent: false,
            defaultHeading: "activityHistory",
            count: 0
        }];
        
        /**
         * Strategy tabs definition 
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmStrategyController 
         */
        self.strategyTabs = [
            {
                heading: self.translations.filmDetails.labels.trailerPlacementStrategyTab,
                route: "film.strategy.trailerPlacement",
                defaultHeading: "trailerPlacement",
                hasContent:  false,
                count: 0
            },
            {
                heading: self.translations.filmDetails.labels.generalStrategyTab,
                route: "film.strategy.general",
                defaultHeading: "general",
                hasContent:  false,
                count: 0
            },
            {
                heading: self.translations.filmDetails.labels.inTheaterStrategyTab,
                route: "film.strategy.inTheater",
                defaultHeading: "inTheater",
                hasContent:  false,
                count: 0
            },
            {
                heading: self.translations.filmDetails.labels.onlinePromotionStrategyTab,
                route: "film.strategy.onlinePromotion",
                defaultHeading: "onlinePromotion",
                hasContent:  false,
                count: 0
            }
        ];

        /**
         * Videogular primary player api
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        self.videogularPrimaryPlayerAPI = null;

        /**
         * Videogular secondary player api
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        self.videogularSecondaryPlayerAPI = null;

        /**
         * Videogular primary player config
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @type {object}
         * @todo when we get asset server from WB replace static assets
         */
        self.videogularPrimaryPlayerConfig = {
            nativeFullscreen: true,
            preload: "auto",
            controls: false,
            sources: [{}],
            plugins: {
                poster: ""
            }
        };

        /**
         * Videogular secondary player config
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @type {object}
         * @todo when we get asset server from WB replace static assets
         */
        self.videogularSecondaryPlayerConfig = {
            nativeFullscreen: true,
            preload: "auto",
            controls: false,
            sources: [{}]
        };

        /**
         * active parent tab index 
         * @public
         * @type {int}
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        self.activeParentTabIndex = 0;

        /**
         * active child tab index 
         * @public
         * @type {int}
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        self.activeChildTabIndex = 0;

        /**
         * active child tab index
         * @public
         * @type {int}
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        self.locationSearch = $location.search();
        /**
         * is film Details Loaded flag
         * @public
         * @type {int}
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        self.isFilmDetailsLoaded = false;
        self.doNotShow = false;
        self.donotShowChecked = $cookies.get("donotShowChecked") || "";
        /**
         * Get film assets by id
         * @param {int} id id of the assets
         * @function getCensorCardById
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        var getCensorCardById = function (id) {
            var errorMessage = self.translations.filmDetails.messages.getCensorCardError;
            assetsService.getAssetById(id, errorMessage).success(function (result) {
                if (result._embedded.filmAssets && result._embedded.filmAssets.length) {
                    self.censorCard = result._embedded.filmAssets[0];
                }
            });
        };

        /**
         * Build data collection
         * @function buildDataCollection
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        var buildDataCollection = function () {
            self.genres = self.filmDetails.genres ? translateService.findItemsByProperty(self.translations.values.genres, "code", window.helpers.isJson(self.filmDetails.genres) ? JSON.parse(self.filmDetails.genres) : filmService.splitMultiValFields(self.filmDetails.genres)) : [];
            self.formats = self.filmDetails.format ? translateService.findItemsByProperty(self.translations.values.formats, "code", window.helpers.isJson(self.filmDetails.format) ? JSON.parse(self.filmDetails.format) : filmService.splitMultiValFields(self.filmDetails.format)) : [];
            self.releasePatterns = self.filmDetails.releasePattern ? translateService.findItemsByProperty(self.translations.values.releasePatterns, "code", window.helpers.isJson(self.filmDetails.releasePattern) ? JSON.parse(self.filmDetails.releasePattern) : filmService.splitMultiValFields(self.filmDetails.releasePattern)) : [];
            self.aspectRatios = self.filmDetails.aspectRatio ? translateService.findItemsByProperty(self.translations.values.aspectRatios, "code", window.helpers.isJson(self.filmDetails.aspectRatio) ? JSON.parse(self.filmDetails.aspectRatio) : filmService.splitMultiValFields(self.filmDetails.aspectRatio)) : [];
        };

        /**
         * Init functionality which require data
         * @function initFunctionalityWhichRequireData
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        var initFunctionalityWhichRequireData = function () {
            $scope.$parent.pageCtrl.title = self.filmDetails.title;

            buildDataCollection();

            self.videogularPrimaryPlayerConfig.sources = videoSourceGeneratorService.getSource(self.filmDetails.trailerId, self.filmDetails.fileName);
            self.videogularPrimaryPlayerConfig.plugins.poster = self.filmDetails.onesheet ? API_EFD + "efd/extendedViews/" + self.filmDetails.onesheet : "";

            if (self.filmDetails.censorCard) {
                getCensorCardById(self.filmDetails.censorCard);
            }
        };

        /**
         * get source film data from EIS
         * @function getSourceData
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        var getSourceData = function () {
            filmService.getFilmSourceAndCinehubData(eisId, -1, -1, location.territoryId, location.localeId).success(function (film) {
                if (!film) {
                    return false;
                }

                self.filmDetails = film;

                initFunctionalityWhichRequireData();
            });
        };

        /**
         * get Material count
         * @function getMaterialCount
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @private
         * @param {int} filmId film id
         * @param {string} category asset category
         * @param {str} wbAssetType asset type
         * @param {str} wbpiAssetType asset sub type
         * @param {str} fileType asset file type
         * @param {str} sort sorting
         * @param {str} recType rec type
         * @param {str} size page show all
         * @param {str} page page user Role 
         */
        var getFilmTabCount = function () {
            return filmService["getFilmTabCount"](filmId, null,
                    "pub", self.user.role).success(function (result) {
                        self.tabs.forEach(function (tab) {
                            tab.hasContent = false;
                            switch (tab.defaultHeading) {
                                case "details":
                                    if (self.filmDetails) {
                                        tab.hasContent = true;
                                    }
                                    break;
                                case "materials":
                                    tab.count = result.data.materialCount;
                                    if (tab.count > 0) {
                                        tab.hasContent = true;
                                    }
                                    break;
                                case "strategy":
                                    if (self.filmDetails.trailerPlcmntRecommendations || self.filmDetails.onlinepromotionsStrategy 
                                            || self.filmDetails.generalStrategy || self.filmDetails.intheaterStrategy) {
                                        tab.hasContent = true;
                                    }
                                    self.strategyTabs.forEach(function (tab) {
                                        tab.hasContent = false;
                                        switch (tab.defaultHeading) {
                                            case "trailerPlacement":
                                                if (self.filmDetails.trailerPlcmntRecommendations) {
                                                    tab.hasContent =  true;
                                                }
                                                break;
                                            case "general":
                                                if (self.filmDetails.generalStrategy) {
                                                    tab.hasContent =  true;
                                                }
                                                break;
                                            case "inTheater":
                                                if (self.filmDetails.intheaterStrategy) {
                                                    tab.hasContent =  true;
                                                }
                                                break;
                                            case "onlinePromotion":
                                                if (self.filmDetails.onlinepromotionsStrategy) {
                                                    tab.hasContent =  true;
                                                }
                                                break;
                                        }
                                    });
                                    break;
                                case "news":
                                    tab.count = result.data.newsCount;
                                    if (tab.count > 0) {
                                        tab.hasContent = true;
                                    }
                                    break;
                                case "antiPiracy":
                                    if (self.filmDetails.antiPiracyInfo) {
                                        tab.hasContent = true;
                                    }
                                    break;
                                case "activityHistory":
                                    tab.count = result.data.activityCount;
                                    if (tab.count > 0) {
                                        tab.hasContent = true;
                                    }
                                    break;
                            }
                        });
                    });
        };

        /**
         * get published film data
         * @function getFilmDetails
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        var getFilmDetails = function () {
            var territoryId = self.isSourceView ? -1 : location.territoryId,
                localeId = self.isSourceView ? -1 : location.localeId;
            filmService.getFilmSourceAndCinehubData(eisId, territoryId, localeId).success(function (film) {
                if (!film) {
                    getSourceData();
                }
                var filmObjs = {};
                filmObjs.film = film;
                return filmRatingService.setFilmRatings(filmObjs).then(function (res) {
                    self.filmDetails = res.filmObjs["film"];
                    self.ratingDetails = self.filmDetails.filmRating;
                    initFunctionalityWhichRequireData();
                    self.isFilmDetailsLoaded = true;
                    getFilmTabCount();
                });
            });
        };

        /**
         * Get film Trailers
         * @private
         * @function getFilmTrailers
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        var getFilmTrailers = function () {
            filmService.getFilmTrailers(filmId).success(function (result) {
                self.trailers = result.content;
            });
        };

        /**
         * Set active parent tab
         * @private
         * @function setActiveParentTab
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @param {string} route route
         */
        var setActiveParentTab = function (route) {
            var matchRoute = route.match(/film.[A-Za-z]+/ig);
            if (matchRoute) {
                switch (matchRoute[0]) {
                    case "film.details":
                        self.activeParentTabIndex = 0;
                        break;
                    case "film.materials":
                        self.activeParentTabIndex = 1;
                        break;
                    case "film.strategy":
                        self.activeParentTabIndex = 2;
                        break;
                    case "film.news":
                        self.activeParentTabIndex = 3;
                        break;
                    case "film.antiPiracy":
                        self.activeParentTabIndex = 4;
                        break;
                    case "film.activityHistory":
                        self.activeParentTabIndex = 5;
                        break;
                }
            }
        };

        /**
         * Set active child tab
         * @private
         * @function setActiveChildTab
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @param {string} route route 
         */
        var setActiveChildTab = function (route) {
            switch (route) {
                case "film.materials.artwork":
                case "film.strategy.trailerPlacement":
                    self.activeChildTabIndex = 0;
                    break;
                case "film.materials.audioVideo":
                case "film.strategy.general":
                    self.activeChildTabIndex = 1;
                    break;
                case "film.materials.written":
                case "film.strategy.inTheater":
                    self.activeChildTabIndex = 2;
                    break;
                case "film.materials.other":
                case "film.strategy.onlinePromotion":
                    self.activeChildTabIndex = 3;
                    break;
            }
        };

        /**
         * Navigate to correct route
         * @function navigateToCorrectRoute
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        var navigateToCorrectRoute = function () {
            var path = $location.$$path,
                pathWithoutQueryParam = path.substring(1).split("?"),
                route = pathWithoutQueryParam[0].replace(/\//g, ".");
            if (route) {
                setActiveParentTab(route);
                setActiveChildTab(route);
                $state.transitionTo(route);
            }
        };

        /**
         * Play trailer
         * @public
         * @function playTrailer
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @param {object} filmDetails the same filmDetails on the 'self' object...do we need this as a param?
         */
        self.playTrailer = function (filmDetails) {
            self.openLightbox = true;
            self.lightboxAsset = {
                id: filmDetails.trailerId ? filmDetails.trailerId : filmDetails.id,
                assetCategory: "Audio / Video",
                fileTypeCategory: "Video"
            };
            self.lightboxAction = "playTrailerOnly";
        };

        /**
         * On primary player ready
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @function onPrimaryPlayerReady
         * @param {object} API videogular api
         */
        self.onPrimaryPlayerReady = function onPlayerReady(API) {
            self.videogularPrimaryPlayerAPI = API;
        };

        /**
         * On secondary player ready
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @function onSecondaryPlayerReady
         * @param {object} API videogular api
         */
        self.onSecondaryPlayerReady = function (API) {
            self.videogularSecondaryPlayerAPI = API;
        };

        /**
         * Set video
         * @function setVideo
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @public
         * @param {int} trailerId trailer id
         */
        self.setVideo = function (trailer) {

            self.videogularSecondaryPlayerAPI.stop();
            self.videogularSecondaryPlayerAPI.sources = videoSourceGeneratorService.getSource(trailer.id, trailer.playFileName);
            $timeout(function () {
                self.videogularSecondaryPlayerAPI.play.bind(self.videogularSecondaryPlayerAPI);
                self.videogularSecondaryPlayerAPI.play();
            }, 100);

            self.videogularSecondaryPlayerAPI.toggleFullScreen();
        };

        /**
         * Go to tab
         * @function go
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @param {string} route route to go
         */
        self.go = function (route) {
            // Need to use navigateToCorrectRoute() to navigate users to a correct tab when deeplinking url is used.
            if (originalPath !== null && (originalPath.split("/").length > 3) || self.highlight) {
                navigateToCorrectRoute();
            } else {
                $state.transitionTo(route);
            }
        };

        /**
         * Open edit film modal
         * @function openEditFilmModal
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @param {string} initialTabSelected tab to be selected after opening the edit modal.
         * @param {string} initialSubTabSelected sub tab to be open
         */
        self.openEditFilmModal = function (initialTabSelected, initialSubTabSelected) {
            var modalInstance = $uibModal.open({
                templateUrl: "/modules/editFilmModal/modalEditFilm.html",
                controller: "modalEditFilmController",
                controllerAs: "editFilmCtrl",
                keyboard: false,
                backdrop: "static",
                size: "xlg",
                resolve: {
                    filmId: function () {
                        return filmId;
                    },
                    eisId: function () {
                        return eisId;
                    },
                    initialTabSelected: function () {
                        return initialTabSelected;
                    },
                    initialSubTabSelected: function () {
                        return initialSubTabSelected;
                    },
                    page: function () {
                        return "titleDetails";
                    }
                }
            });

            modalInstance.result.then(function () {
                getFilmDetails();
                $scope.$broadcast("filmUpdated");
            });
        };

        /**
         * Open lightbox
         * @function openLightBox
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @param {object} $event browser event
         * @param {object} asset asset
         * @param {string} action action
         */
        self.openLightBox = function ($event, asset, action) {
            self.openLightbox = true;
            self.lightboxAsset = asset;
            self.lightboxAction = action;
        };
        self.doNotShowCheck =function(){
            if (self.doNotShow) {
                var now = new Date(),
                    in30Days = new Date(now.setDate(now.getDate() + 30));

                $cookies.put("donotShowChecked", self.doNotShow, {
                    sameSite: "strict",
                    secure: SECURE_COOKIES,
                    expires: in30Days,
                    domain: DOMAIN
                });
            } else {
                $cookies.remove("donotShowChecked", {domain: DOMAIN, path: "/" });
            }
            self.donotShowChecked = $cookies.get("donotShowChecked") || "";
        };

         /**
         * @event stateChangeStart
         * @listens $stateChangeSuccess
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        $scope.$on("stateChangeStart", function (event, route) {
            if (self.locationSearch && route.name.indexOf("other") > -1) {
                self.locationSearch = $location.search();
            }
        });

        $scope.$on("updateTrailerFilename", function () {
            getFilmTrailers();
        });

        /**
         * @event $stateChangeSuccess
         * @listens $stateChangeSuccess
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         * @param {event} event event 
         * @param {object} route route info
         */
        $scope.$on("$stateChangeSuccess", function (event, route) {
            if (self.locationSearch &&  route.name.indexOf("other") > -1) {
                $location.search(self.locationSearch);
            } else {
                $location.search({});
            }
            self.highlight = $location.$$search.highlight;
            setActiveParentTab(route.name);
            setActiveChildTab(route.name);
        });

        /**
         * @event editFilmModalClosed
         * @listens editFilmModalClosed
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        $rootScope.$on("editFilmModalClosed", function () {
            getFilmDetails();
        });
        
        /**
         * Init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        self.init = function () {
            originalPath = $location.$$path;
            self.highlight = $location.$$search.highlight;
            navigateToCorrectRoute();
            getFilmDetails();

            if (self.isSourceView) {
                self.tabs.splice(5, 1);
            } else {
                getFilmTrailers();
            }
        };

        //INIT
        self.init();
    }

    controllers.controller("filmController", [
        "$cookies",
        "$rootScope",
        "$scope",
        "$state",
        "$location",
        "$timeout",
        "$uibModal",
        "$window",
        "filmService",
        "assetsService",
        "deviceDetectorService",
        "localStorageService",
        "translateService",
        "filmRatingService",
        "SECURE_COOKIES",
        "API_EFD",
        "videoSourceGeneratorService",
        "DOMAIN",
        filmController
    ]);
}());
