(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Be able to right click and open the deep linked tab in new browser tab
     * @class allowOpenInNewTab
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     */
    function allowOpenInNewTab($state) {
        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @param {jqueryElement} element jquery element
         * @private
         * @memberOf angular_module.WB.cinehub.directives.allowOpenInNewTab 
         */
        var link = function (scope, element, attrs) {

            var init = function () {
                //Doesn't matter which attribute we grab the base uri from.
                var currentUrl = window.location.href,
                    page = scope.tab.defaultHeading,
                    fromSubTab = currentUrl.indexOf("/film/strategy") > -1 || currentUrl.indexOf("/film/materials") > -1,
                    toPrimaryTab = attrs.primaryTab,
                    partToBeReplaced = _.last(currentUrl.split("#/film/")),
                    pageUrl = "",
                    stateList = $state.get(),
                    strategyStates = _.filter(stateList, function (item) { return item.name.indexOf(".strategy.") > -1; }),
                    materialStates = _.filter(stateList, function (item) { return item.name.indexOf(".materials.") > -1; });

                //If the tab has children, set the parent tab url to be the first child url.
                if (page === "strategy") {
                    page = "strategy" + strategyStates[0].url;
                } else if (page === "materials") {
                    page = "materials" + materialStates[0].url;
                }
                pageUrl = (!fromSubTab || (fromSubTab && toPrimaryTab)) ? currentUrl.replace(partToBeReplaced, page) : currentUrl.substring(0, currentUrl.lastIndexOf("/") + 1).concat(page);

                //Replace the href with the deeplinked url
                element.find("a").attr("href", pageUrl);
            };

            //INIT
            init();
        };

        return {
            link: link
        };
    }

    directives.directive("allowOpenInNewTab", ["$state", allowOpenInNewTab]);

} ());