(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * film url factory
     * @class filmUrlGeneratorFactory
     * @memberOf angular_module.WB.cinehub.services
     * @returns {parsedDate}
     */
    function filmUrlGeneratorFactory(FILM_URL_MAX_LENGTH) {
        return function (title) {
            var filmUrl = "";

            if (title) {
                filmUrl = title.toLowerCase().replace(/[^A-Za-z0-9\-\s\.]/g, "").replace(/\-|\s|\./g, "-");

                if (filmUrl.length > FILM_URL_MAX_LENGTH){
                    filmUrlGeneratorFactory(filmUrl.substring(0, FILM_URL_MAX_LENGTH));
                }
            }

            return filmUrl;
        };
    }

    services.factory("filmUrlGeneratorFactory", ["FILM_URL_MAX_LENGTH", filmUrlGeneratorFactory]);
}());
