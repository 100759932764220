(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");
    
    /**
     * @class cacheService
     * @memberOf angular_module.WB.cinehub.services
     * @desc asset service
     * @param {!angular.$http} $http angular $http service
     * @param {constant} API_ROOT API_ROOT constant  
     * @param {service} 
     */
    function cacheService($http, API_ROOT, localStorageService) {

        /**
         * translations
         * @private
         * @type {object}
         * @memberOf angular_module.WB.cinehub.services.cacheService
         */
        var translations = localStorageService.get("translations");

        /**
         * Flash exhibitor and theater redis cache
         * @memberOf angular_module.WB.cinehub.services.cacheService
         * @public
         * @function deleteExhibitorsTheatersRedisCache
         * @returns {promise}
         */
        this.deleteExhibitorsTheatersRedisCache = function () {
            return $http.delete(API_ROOT + "cache/flushExhibitorsTheatersListCache", { errorMessage: translations.global.messages.deleteRedisCacheError, showSpinner: false });
        };

    }

    services.service("cacheService", ["$http", "API_ROOT", "localStorageService", cacheService]);
}());
