(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * to do controller
     * @dec to do controller
     * @class toDoController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.$rootScope} $rootScope angular root scope
     * @param {!angular.Scope} $scope angular scope
     * @param {service} toDoService to do service
     * @param {service} localStorageService local storage service
     * @param {service} permissionQueueService permission queue serivice
     * @param {constant} API_EFD API_EFD
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     * @param {service} deviceDetectorService device detector service
     * @param {service} userService user service
     * @param {service} $window $window
     * @param {$uibModal} $uibModal $uibModal
     * @param {constant} US_TERRITORY_ID US_TERRITORY_ID
     * @param {service} assetCategoryConvertor asset category convertor
     * @param {service} shoppingCartService shopping cart service
     * @param {constant} AUDIO_VIDEO_ASSET_CATEGORY AUDIO_VIDEO_ASSET_CATEGORY
     */
    function toDoController($rootScope, $scope, toDoService, localStorageService, permissionQueueService, convertLocalizationCodeToValueService, API_EFD, DEFAULT_PAGE_SIZE, deviceDetectorService, userService, filmRatingService, $window, $uibModal, US_TERRITORY_ID, assetCategoryConvertor, shoppingCartService, AUDIO_VIDEO_ASSET_CATEGORY) {

        /**
         * Instance of toDoController
         * @type {toDoController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        var self = this,

            /**
             * Current user
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.toDoController
             */
            currentUser = localStorageService.get("userInfo"),


            /**
             * Location information
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.toDoController
             */
            location = localStorageService.get("location");

        /**
         * Flag which indicates whether Home Page Results are loaded
         * @type {boolean}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.homeController
         */
        self.isDataLoaded = false;

        /**
         * translations
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @public
         * @type {object}
         */
        self.translations = localStorageService.get("translations");

        /**
         * user info
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.user = localStorageService.get("userInfo");

        /**
         * impersonate user flag
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");

        /**
         * API_EFD
         * @constant
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @public
         * @type {string}
         */
        self.API_EFD = API_EFD;

        /**
         * AUDIO_VIDEO_ASSET_CATEGORY
         * @constant
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @public
         * @type {string}
         */
        self.AUDIO_VIDEO_ASSET_CATEGORY = AUDIO_VIDEO_ASSET_CATEGORY;

        /**
         * Device detector service
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type {service}
         */
        self.deviceDetectorService = deviceDetectorService;

        /**
         * Filter sort options for filterContainerDirective unpermissioned materials
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @public
         */
        self.materialsFilterSort = {};

        /**
         * Asset category convertor
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type {service}
         */
        self.assetCategoryConvertor = assetCategoryConvertor;

        /**
         * Multi Select Filter Options & Models.
         * Holds all the filter values
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type [Array]
         */
        self.allFilterValues = [];

        /**
         * Button default text for films
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type [Array]
         */
        self.filmTranslations = {buttonDefaultText: self.translations.filter.labels.films};

        /**
         * Button default text for Asset types
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type [Array]
         */
        self.assetTypeTranslations = {buttonDefaultText: self.translations.filter.labels.assetTypes};

        /**
         * Button default text for sub types
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type [Array]
         */
        self.subTypeTranslations = {buttonDefaultText: self.translations.filter.labels.assetSubTypes};

        /**
         * Button default text for file types
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type [Array]
         */
        self.fileTypeTranslations = {buttonDefaultText: self.translations.filter.labels.fileTypes};

        /**
         * Button default text Sort by
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type [Array]
         */
        self.sortTypeTranslations = {buttonDefaultText: "Sort By"};


        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.pageHeaderItem = {
            pageTitle: self.translations.toDo.labels.pageTitle,
            breadcrumbItems: [{
                linkTitle: self.translations.toDo.labels.breadcrumbLinkTitle,
                link: "/to-do"
            }]
        };

        /**
         * film sorting options
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.filmSortOptions = [{
            value: "sourceModifiedDate,desc",
            text: self.translations.sort.labels.updatedDateNewestToOldest
        }, {
            value: "sourceModifiedDate,asc",
            text: self.translations.sort.labels.updatedDateOldestToNewest
        }, {
            value: "title,asc",
            text: self.translations.sort.labels.titleNameAToZ
        }, {
            value: "title,desc",
            text: self.translations.sort.labels.titleNameZToA
        }];

        /**
         * material sorting options
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.materialSortOptions = [{
            value: "lastModified,id,desc",
            text: self.translations.sort.labels.newestToOldest
        }, {
            value: "lastModified,id,asc",
            text: self.translations.sort.labels.oldestToNewest
        }];

        /**
         * modified interval
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.modifiedIntervals = [{
            value: 0,
            text: self.translations.filter.labels.allDays
        }, {
            value: 3,
            text: self.translations.filter.labels.modifiedWithin3Days
        }, {
            value: 7,
            text: self.translations.filter.labels.modifiedWithin7Days
        }];

        /**
         * Sort by options model
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type [Array]
         */
        self.sortModel = {id : self.materialSortOptions[0].value, label: self.materialSortOptions[0].text};

        /**
         * Filter sort options for filterContainerDirective unpublished titles
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @public
         */
        self.unpublishedTitlesFilterSort = null;

        /**
         * Filter sort options for filterContainerDirective unpermissioned materials
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @public
         */
        self.materialsFilterSort = null;

        /**
         * tab menus
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @public
         */
        self.tabs = [{
            heading: self.translations.toDo.labels.unpublishedTitleInfoTab,
            templateUrl: "/modules/toDo/unpublishedTitleInfo/unpublishedTitleInfo.html",
            active: false,
            isVisibleToRole: currentUser.role === "territoryAdmin",
            tabName: "unpublishedTitleInfo",
            count: 0
        }, {
            heading: self.translations.toDo.labels.unpermissionedMaterialsTab,
            templateUrl: "/modules/toDo/unpermissionedMaterials/unpermissionedMaterials.html",
            active: false,
            isVisibleToRole: true,
            tabName: "unpermissionedMaterials",
            count: 0
        }, {
            heading: self.translations.toDo.labels.pendingUsers,
            templateUrl: "/modules/toDo/pendingUsers/pendingUsers.html",
            active: false,
            isVisibleToRole: true,
            tabName: "pendingUsers",
            count: 0
        }];

        /**
         * filter and sort options for unpublished materials tab
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.unpublishedTitleSortOptions = [{
            type: "select",
            options: self.modifiedIntervals,
            propertyName: "modifiedInterval"
        }, {
            type: "select",
            options: self.filmSortOptions,
            propertyName: "sort"
        }];

        /**
         * filter and sort options for unpublished title info tab
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.unpublishedTitlesFilterSort = {
            modifiedInterval: 0,
            sort: "sourceModifiedDate,desc"
        };

        /**
         * filter and sort options for unpermissioned materials tab
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.materialsFilterSortOptions = [];

        /**
         * default modified interval
         * @type {int}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.modifiedInterval = 0;

        /**
         * permission queue service
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @public
         * @type {permissionQueueService}
         */
        self.permissionQueueService = permissionQueueService;

        /**
         * show bulk approve reject pending user options
         * @type {boolean}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.showBulkApproveRejectPendingUserOptions = false;

        /**
         * Films
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.unpublishedTitles = {
            page: {
                "totalElements": 0,
                "totalPages": 0,
                "number": 1,
                "size": DEFAULT_PAGE_SIZE
            }
        };

         /**
         * Unpublished materials
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.unpublishedMaterials = {
            "totalElements": 0,
            "totalPages": 0,
            "size": DEFAULT_PAGE_SIZE,
            "number": 1
        };


        /**
         * Unpermissioned materials
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.unpermissionedMaterials = {
            "totalElements": 0,
            "totalPages": 0,
            "size": DEFAULT_PAGE_SIZE,
            "number": 1
        };

         /**
         * Multi Select Filter Options & Models.
         * Holds all the filter options
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type [Array]
         */
        self.filterOptions =  [];


        /**
         * Pending users
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.pendingUsers = {
            size: DEFAULT_PAGE_SIZE,
            number: 1
        };

        /**
         * Pending users sort
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.pendingUsersSort = "userId,desc";

        /**
         * Pending users filter query
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.pendingUsersFilterQuery = "";

        /**
         * Shopping cart service 
         * @type {service}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.shoppingCartService = shoppingCartService;

        /**
         * Toggle pending users bulk options
         * @function togglePendingUsersBulkOptions
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        var togglePendingUsersBulkOptions = function () {
            var index = 0,
                pendingUsersLength = self.pendingUsers.content.length;
            for (index; index < pendingUsersLength; index++) {
                var user = self.pendingUsers.content[index];
                if (user.selected) {
                    self.showBulkApproveRejectPendingUserOptions = true;
                    break;
                }
                self.showBulkApproveRejectPendingUserOptions = false;
            }
        };

        /**
         * Update users
         * @param user {object}
         * @function updateUser
         * @private
         * @returns {promise}
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        var updateUser = function (user) {
            user.lastModified = moment().utc().format();
            return userService.updateUser(user, user.userId).success(function () {
                self.getPendingUsers("", self.pendingUsers.number, self.pendingUsers.size, self.pendingUsersSort);
                setTodoTabCounts();
            }).error(function () {
                revertUpdateChanges(user);
            });
        };

        /**
         * Bulk update users
         * @param users {object[]}
         * @function bulkUpdateUsers
         * @private
         * @returns {promise}
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        var bulkUpdateUsers = function (users) {
            return userService.bulkUpdateUsers(users).success(function () {
                self.getPendingUsers("", self.pendingUsers.number, self.pendingUsers.size, self.pendingUsersSort);
                self.showBulkApproveRejectPendingUserOptions = false;
                setTodoTabCounts();
            }).error(function () {
                users.forEach(function (user) {
                    revertUpdateChanges(user);
                });
            });
        };

        /**
         * revert update changes
         * @function revertUpdateChanges
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        var revertUpdateChanges = function (user) {
            if (currentUser.role === "homeOfficeAdmin" || user.territoryId === currentUser.territoryId) {
                user.status = "pendingApproval";
                user.exhibitorRelationship = JSON.parse(user.exhibitorRelationship);
                user.theaterAffiliations = JSON.parse(user.theaterAffiliations);
            } else if (user.territoryId !== currentUser.territoryId && !_.isEmpty(user.userAffiliations) && !_.isEmpty(user.addlTerrStatusList)) {
                user.addlTerrStatusList.forEach (function (addlTerrStatus) {                   
                    if (addlTerrStatus.territoryId === currentUser.territoryId) {
                        addlTerrStatus.status = "pendingApproval";
                    }
                });
                user.exhibitorRelationship = JSON.parse(user.exhibitorRelationship);
                user.theaterAffiliations = JSON.parse(user.theaterAffiliations);
            }
        };

        /**
         * Clear selected state
         * @function clearSelectedState
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        var clearSelectedState = function () {
            self.unpermissionedMaterials.content.forEach(function (asset) {
                asset.selected = false;
            });
        };

        /**
         * Rerender todo counts 
         * @function rerenderTodoCounts
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        var rerenderTodoCounts = function () {
            self.tabs[0].count = toDoService.todoTabCounts.unpublishedTitleInfoCount > 0 ? toDoService.todoTabCounts.unpublishedTitleInfoCount : 0;
            //self.tabs[1].count = toDoService.todoTabCounts.unpublishedMaterialUpdatesCount > 0 ? toDoService.todoTabCounts.unpublishedMaterialUpdatesCount : 0;
            self.tabs[1].count = toDoService.todoTabCounts.unpermissionedMaterialsCount > 0 ? toDoService.todoTabCounts.unpermissionedMaterialsCount : 0;
            self.tabs[2].count = toDoService.todoTabCounts.pendingUsersCount > 0 ? toDoService.todoTabCounts.pendingUsersCount : 0;

            self.pageHeaderItem.pageTitle = self.translations.toDo.labels.pageTitle + " (" + (toDoService.todoTabCounts.allCount > 0 ? toDoService.todoTabCounts.allCount : 0) + ")";

            $scope.$parent.$parent.pageCtrl.todoTabCounts = toDoService.todoTabCounts;
        };

        /**
         * set todo tab counts
         * @function setTodoTabCounts
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        var setTodoTabCounts = function () {
            toDoService.setTodoTabCounts(currentUser.role, true).then(function () {
                rerenderTodoCounts();
            });
        };

        /**
         * Disabled bulk add to queue btn
         * @function disableBulkAddToQueueBtn
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @public
         * @returns {boolean}
         */
        self.disableBulkAddToQueueBtn = function () {
            var isSelected = false;
            if (self.unpermissionedMaterials && self.unpermissionedMaterials && self.unpermissionedMaterials.content) {
                isSelected = _.findIndex(self.unpermissionedMaterials.content, {
                    selected: true
                }) >= 0;
            }
            return !isSelected;
        };

        /**
         * Get pending users
         * @function getPendingUsers
         * @public
         * @returns {promise}
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @param {string} filterQuery filter query
         * @param {int} pageNumber page number
         * @param {int} pageSize page size
         * @param {string} sortString sort string
         */
        self.getPendingUsers = function (filterQuery, pageNumber, pageSize, sortString) {
            var userTypes = "",
                territoryId = "";

            if (pageNumber) {
                self.pendingUsers.number = pageNumber;
            }

            if (pageSize) {
                self.pendingUsers.size = pageSize;
            }

            if (sortString) {
                self.pendingUsersSort = sortString;
            }

            if (currentUser.role === "homeOfficeAdmin") {

                userTypes = "wb,wb-other";

            } else {
                territoryId = location.territoryId;
                userTypes = "ex-corporate,ex-theater,ex-other,wb,wb-other";

                /*if (currentUser.role === "territoryAdmin" && location.territoryId === US_TERRITORY_ID) {
                    userTypes += ",wb,wb-other";
                }*/
            }
            self.pendingUsersFilterQuery = "userSearch=&userTypes=" + userTypes + "&territoryId=" + territoryId + "&exhibitorId=&theaterId=&statuses=pendingApproval";

            return userService.searchUsers(self.pendingUsersFilterQuery, self.pendingUsers.number, self.pendingUsers.size, self.pendingUsersSort).success(function (result) {
                self.pendingUsers = result;
                if (result.totalElements == 0) {
                    self.isDataLoaded = false;
                } else {
                    self.isDataLoaded = true;
                }
                self.pendingUsers.content.forEach(function (user) {
                    user.exhibitorRelationship = JSON.parse(user.exhibitorRelationship);
                    user.theaterAffiliations = JSON.parse(user.theaterAffiliations);
                });
                self.tabs[2].count = result.totalElements;
                toDoService.recalculateTodoCounts("pendingUsersCount", result.totalElements);
                rerenderTodoCounts();
            });
        };

        /**
         * Select deselect all pending users
         * @function selectDeselectAllPendingUsers
         * @public
         * @param {boolean} selectState select state
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.selectDeselectAllPendingUsers = function (selectState) {
            self.pendingUsers.content.forEach(function (user) {
                user.selected = selectState;
                if (self.viewAs) {
                    user.selected = false;	
                }
            });
            self.showBulkApproveRejectPendingUserOptions = selectState;
        };

        /**
         * Select pending user
         * @function selectPendingUser
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.selectPendingUser = function () {
            togglePendingUsersBulkOptions();
        };

        /**
         * Open manage user page
         * @function openManageUserPage
         * @public
         * @param {int} userId user id
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.openManageUserPage = function (userId) {
            $window.open("/manage-users/user/" + userId + "/0/", "_self");
        };

        /**
         * Edit user
         * @function editUser
         * @public
         * @param {int} userId user id
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.editUser = function (userId) {
            $window.open("/manage-users/user/" + userId + "/1/", "_self");
        };

        /**
         * Approve user
         * @function approveUser
         * @public
         * @param {object} user user profile
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.approveUser = function (user) {
            $uibModal.open({
                templateUrl: "confirmationTemplate.html",
                controller: "modalConfirmationController",
                controllerAs: "modalConfirmationCtrl",
                keyboard: false,
                backdrop: "static",
                size: "md",
                resolve: {
                    message: function () {
                        return self.translations.toDo.messages.confirmationApprove + " " + user.firstName + " " + user.lastName + " ?";
                    },
                    headerTitle: function () {
                        return self.translations.global.messages.confirmation;
                    },
                    showAffirmativeBtn: function () {
                        return true;
                    },
                    affirmativeBtnText: function () {
                        return self.translations.global.labels.yesButton;
                    },
                    cancelBtnText: function () {
                        return self.translations.global.labels.noButton;
                    }
                }
            }).result.then(function () {
                if (currentUser.role === "homeOfficeAdmin" || user.territoryId === currentUser.territoryId) {
                    user.status = "active";
                    user.adminApproved = moment().utc().format();
                    user.approverId = currentUser.id;
                    user.exhibitorRelationship = JSON.stringify(user.exhibitorRelationship);
                    user.theaterAffiliations = JSON.stringify(user.theaterAffiliations);
                } else if (user.territoryId !== currentUser.territoryId && !_.isEmpty(user.userAffiliations) && !_.isEmpty(user.addlTerrStatusList)) {
                    user.addlTerrStatusList.forEach (function (addlTerrStatus) {                   
                        if (addlTerrStatus.territoryId === currentUser.territoryId) {
                            addlTerrStatus.status = "active";
                            addlTerrStatus.actionDate = moment().utc().format();
                            addlTerrStatus.actionBy = currentUser.userId;
                            addlTerrStatus.comments = "";
                        }
                    });
                    user.exhibitorRelationship = JSON.stringify(user.exhibitorRelationship);
                    user.theaterAffiliations = JSON.stringify(user.theaterAffiliations);
                }
                updateUser(user);
            });
        };

        /**
         * Reject user
         * @function rejectUser
         * @public
         * @param {object} user user profile
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.rejectUser = function (user) {
            $uibModal.open({
                templateUrl: "rejectionTemplate.html",
                controller: "modalConfirmationController",
                controllerAs: "modalConfirmationCtrl",
                keyboard: false,
                backdrop: "static",
                size: "md",
                resolve: {
                    message: function () {
                        return self.translations.toDo.messages.confirmationReject + " " + user.firstName + " " + user.lastName + " ?";
                    },
                    headerTitle: function () {
                        return self.translations.global.messages.confirmation;
                    },
                    showAffirmativeBtn: function () {
                        return true;
                    },
                    affirmativeBtnText: function () {
                        return self.translations.global.labels.yesButton;
                    },
                    cancelBtnText: function () {
                        return self.translations.global.labels.noButton;
                    }
                }
            }).result.then(function (rejectionComment) {
                if (currentUser.role === "homeOfficeAdmin" || user.territoryId === currentUser.territoryId) {
                    user.status = "rejected";
                    user.rejectedComment = rejectionComment;
                    user.adminRejected = moment().utc().format();
                    user.rejectedBy = currentUser.firstName + currentUser.lastName;
                    user.exhibitorRelationship = JSON.stringify(user.exhibitorRelationship);
                    user.theaterAffiliations = JSON.stringify(user.theaterAffiliations);
                } else if (user.territoryId !== currentUser.territoryId && !_.isEmpty(user.userAffiliations) && !_.isEmpty(user.addlTerrStatusList)) {
                    user.addlTerrStatusList.forEach (function (addlTerrStatus) {                   
                        if (addlTerrStatus.territoryId === currentUser.territoryId) {
                            addlTerrStatus.status = "rejected";
                            addlTerrStatus.comments =  rejectionComment;
                            addlTerrStatus.actionDate = moment().utc().format();
                            addlTerrStatus.actionBy = currentUser.userId;
                        }
                    });
                    user.exhibitorRelationship = JSON.stringify(user.exhibitorRelationship);
                    user.theaterAffiliations = JSON.stringify(user.theaterAffiliations);
                }
                updateUser(user);
            });
        };

        /**
         * Bulk approve users
         * @function bulkApproveUsers
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.bulkApproveUsers = function () {
            var selectedUsers = _.filter(self.pendingUsers.content, {
                selected: true
            });
            $uibModal.open({
                templateUrl: "confirmationTemplate.html",
                controller: "modalConfirmationController",
                controllerAs: "modalConfirmationCtrl",
                keyboard: false,
                backdrop: "static",
                size: "md",
                resolve: {
                    message: function () {
                        return self.translations.toDo.messages.confirmationBulkApprove + " " + selectedUsers.length + " " + self.translations.toDo.labels.users;
                    },
                    headerTitle: function () {
                        return self.translations.global.messages.confirmation;
                    },
                    showAffirmativeBtn: function () {
                        return true;
                    },
                    affirmativeBtnText: function () {
                        return self.translations.global.labels.yesButton;
                    },
                    cancelBtnText: function () {
                        return self.translations.global.labels.noButton;
                    }
                }
            }).result.then(function () {
                selectedUsers.forEach(function (user) {
                    if (currentUser.role === "homeOfficeAdmin" || user.territoryId === currentUser.territoryId) {
                        user.status = "active";
                        user.lastModified = moment().utc().format();
                        user.adminApproved = moment().utc().format();
                        user.approverId = currentUser.id;
                        if (user.exhibitorRelationship) {
                            user.exhibitorRelationship = JSON.stringify(user.exhibitorRelationship);
                        }
                        if (user.theaterAffiliations) {
                            user.theaterAffiliations = JSON.stringify(user.theaterAffiliations);
                        }  
                    } else if (user.territoryId !== currentUser.territoryId && !_.isEmpty(user.userAffiliations) && !_.isEmpty(user.addlTerrStatusList)) {
                        user.addlTerrStatusList.forEach (function (addlTerrStatus) {                   
                            if (addlTerrStatus.territoryId === currentUser.territoryId) {
                                addlTerrStatus.status = "active";
                                addlTerrStatus.actionDate = moment().utc().format();
                                addlTerrStatus.actionBy = currentUser.userId;
                                addlTerrStatus.comments = "";
                                if (user.exhibitorRelationship) {
                                    user.exhibitorRelationship = JSON.stringify(user.exhibitorRelationship);
                                }
                                if (user.theaterAffiliations) {
                                    user.theaterAffiliations = JSON.stringify(user.theaterAffiliations);
                                }  
                            }
                        });
                    }
                });

                bulkUpdateUsers(selectedUsers);
            });
        };

        /**
         * Bulk reject users
         * @function bulkRejectUsers
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.bulkRejectUsers = function () {
            var selectedUsers = _.filter(self.pendingUsers.content, {
                selected: true
            });
            $uibModal.open({
                templateUrl: "rejectionTemplate.html",
                controller: "modalConfirmationController",
                controllerAs: "modalConfirmationCtrl",
                keyboard: false,
                backdrop: "static",
                size: "md",
                resolve: {
                    message: function () {
                        return self.translations.toDo.messages.confirmationBulkReject + " " + selectedUsers.length + " " + self.translations.toDo.labels.users;
                    },
                    headerTitle: function () {
                        return self.translations.global.messages.confirmation;
                    },
                    showAffirmativeBtn: function () {
                        return true;
                    },
                    affirmativeBtnText: function () {
                        return self.translations.global.labels.yesButton;
                    },
                    cancelBtnText: function () {
                        return self.translations.global.labels.noButton;
                    }
                }
            }).result.then(function (rejectionComment) {
                selectedUsers.forEach(function (user) {
                    if (currentUser.role === "homeOfficeAdmin" || user.territoryId === currentUser.territoryId) {
                        user.status = "rejected";
                        user.rejectedComment = rejectionComment;
                        user.lastModified = moment().utc().format();
                        user.adminRejected = moment().utc().format();
                        user.rejectedBy = currentUser.firstName + currentUser.lastName;
                        if (user.exhibitorRelationship) {
                            user.exhibitorRelationship = JSON.stringify(user.exhibitorRelationship);
                        }
                        if (user.theaterAffiliations) {
                            user.theaterAffiliations = JSON.stringify(user.theaterAffiliations);
                        }
                    } else if (user.territoryId !== currentUser.territoryId && !_.isEmpty(user.userAffiliations) && !_.isEmpty(user.addlTerrStatusList)) {
                        user.addlTerrStatusList.forEach (function (addlTerrStatus) {                   
                            if (addlTerrStatus.territoryId === currentUser.territoryId) {
                                addlTerrStatus.status = "rejected";
                                addlTerrStatus.comments =  rejectionComment;
                                addlTerrStatus.actionDate = moment().utc().format();
                                addlTerrStatus.actionBy = currentUser.userId;
                            }
                        });
                        if (user.exhibitorRelationship) {
                            user.exhibitorRelationship = JSON.stringify(user.exhibitorRelationship);
                        }
                        if (user.theaterAffiliations) {
                            user.theaterAffiliations = JSON.stringify(user.theaterAffiliations);
                        }
                    }
                });

                bulkUpdateUsers(selectedUsers);
            });

        };

        /**
         * Get unpublished title info
         * @function getUnpublishedTitleInfo
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.getUnpublishedTitleInfo = function () {
            var secondarySort = "title";
            if (/^title/ig.test(self.unpublishedTitlesFilterSort.sort)) {
                secondarySort = "";
            }
            toDoService.getUnpublishedTitleInfo(self.unpublishedTitlesFilterSort.modifiedInterval, self.unpublishedTitles.page.size, self.unpublishedTitles.page.number, self.unpublishedTitlesFilterSort.sort, secondarySort).success(function (result) {
                var filmObjs = {};
                self.unpublishedTitles = result;
                self.tabs[0].count = result.page.totalElements;
                if (!self.unpublishedTitlesFilterSort.modifiedInterval) {
                    toDoService.recalculateTodoCounts("unpublishedTitleInfoCount", result.page.totalElements);
                    rerenderTodoCounts();
                }
                filmObjs.films = self.unpublishedTitles && self.unpublishedTitles._embedded && self.unpublishedTitles._embedded.films ? self.unpublishedTitles._embedded.films : [];
                return filmRatingService.setFilmRatings(filmObjs).then(function (res) {
                    self.unpublishedTitles._embedded.films = res.filmObjs["films"];
                });
            });
        };

        /**
         * Get filters values for unpublished materials
         * @function getFiltersValuesForUnpublishedMaterials
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.getFiltersValuesForUnpublishedMaterials = function () {
            toDoService.getFiltersValuesForUnpublishedMaterials(self.materialsFilterSort.filmId, self.materialsFilterSort.wbAssetType, self.materialsFilterSort.wbpiAssetType, self.materialsFilterSort.fileType).success(function (result) {
                self.materialsFilterSortOptions = [{
                    type: "select",
                    options: result.data.films,
                    propertyName: "filmId"
                }, {
                    type: "select",
                    options: result.data.assetTypes,
                    propertyName: "wbAssetType"
                }, {
                    type: "select",
                    options: result.data.assetSubtypes,
                    propertyName: "wbpiAssetType"
                }, {
                    type: "select",
                    options: result.data.fileTypes,
                    propertyName: "fileType"
                }, {
                    type: "select",
                    options: self.materialSortOptions,
                    propertyName: "sort"
                }];

                //Add default option
                self.materialsFilterSortOptions[0].options.unshift({
                    text: self.translations.filter.labels.allFilms,
                    value: "ALL",
                    disabled: true
                });
                self.materialsFilterSortOptions[1].options.unshift({
                    text: self.translations.filter.labels.allAssetTypes,
                    value: "ALL",
                    disabled: true
                });
                self.materialsFilterSortOptions[2].options.unshift({
                    text: self.translations.filter.labels.allAssetSubTypes,
                    value: "ALL",
                    disabled: true
                });
                self.materialsFilterSortOptions[3].options.unshift({
                    text: self.translations.filter.labels.allFileTypes,
                    value: "ALL",
                    disabled: true
                });
                self.allFilterValues = self.materialsFilterSortOptions[4].options;
                self.filterOptions = self.getFilterOptions();
            });

        };

        /**
         * Get filters values for unpermissioned materials
         * @function getFiltersValuesForUnpermissionedMaterials
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.getFiltersValuesForUnpermissionedMaterials = function () {
            toDoService.getFiltersValuesForUnpermissionedMaterials(self.materialsFilterSort.filmId, self.materialsFilterSort.wbAssetType, self.materialsFilterSort.wbpiAssetType, self.materialsFilterSort.fileType).success(function (result) {
                self.materialsFilterSortOptions = [{
                    type: "select",
                    options: result.data.films,
                    propertyName: "filmId"
                }, {
                    type: "select",
                    options: result.data.assetTypes,
                    propertyName: "wbAssetType"
                }, {
                    type: "select",
                    options: result.data.assetSubtypes,
                    propertyName: "wbpiAssetType"
                }, {
                    type: "select",
                    options: result.data.fileTypes,
                    propertyName: "fileType"
                }, {
                    type: "select",
                    options: result.data.filters,
                    propertyName: "filters"
                },  {
                    type: "select",
                    options: self.materialSortOptions,
                    propertyName: "sort"
                }];

                //Add default option
                self.materialsFilterSortOptions[0].options.unshift({
                    text: self.translations.filter.labels.allFilms,
                    value: "ALL",
                    disabled: true
                });
                self.materialsFilterSortOptions[1].options.unshift({
                    text: self.translations.filter.labels.allAssetTypes,
                    value: "ALL",
                    disabled: true
                });
                self.materialsFilterSortOptions[2].options.unshift({
                    text: self.translations.filter.labels.allAssetSubTypes,
                    value: "ALL",
                    disabled: true
                });
                self.materialsFilterSortOptions[3].options.unshift({
                    text: self.translations.filter.labels.allFileTypes,
                    value: "ALL",
                    disabled: true
                });
                self.allFilterValues = self.materialsFilterSortOptions[4].options;
                self.filterOptions = self.getFilterOptions();
            });
        };


        /**
         * Get unpublished materials
         * @function unpermissionedMaterialsSelectAll
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.unpermissionedMaterialsSelectAll = function () {
            if (!self.materialsFilterSort.retainStaticFilters) {
                self.sortModel = {id : self.materialSortOptions[0].value, label: self.materialSortOptions[0].text};
                self.materialsFilterSort.sort = "lastModified,id,desc";
            }
            self.materialsFilterSort.filmId = [];
            self.materialsFilterSort.wbAssetType = [];
            self.materialsFilterSort.wbpiAssetType = [];
            self.materialsFilterSort.fileType = [];
            self.getUnpermissionedMaterials();
        };


         /**
         * Get FilterOptions Object by setting page Data objects.
         * @function unpermissionedMaterialsSelectAll
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.getFilterOptions = function () {
            var multiSelectOptions = [
                {
                    type : "films",
                    id:"filmId",
                    value:"title",
                    translations:self.filmTranslations
                },
                {
                    type : "assetTypes",
                    id:"wbAssetType",
                    translations:self.assetTypeTranslations
                },
                {
                    type : "subTypes",
                    id:"wbpiAssetType",
                    translations:self.subTypeTranslations
                },
                {
                    type : "fileTypes",
                    id:"fileType",
                    translations:self.fileTypeTranslations
                },
                {
                    type : "sort",
                    options: self.materialSortOptions,
                    model:self.sortModel,
                    translations:self.sortTypeTranslations
                }];

            for (var filterIndex = 0; filterIndex < multiSelectOptions.length; filterIndex++) {
                if (multiSelectOptions[filterIndex].type !== "sort") {
                    multiSelectOptions[filterIndex].data = self.materialsFilterSortOptions[filterIndex];
                }
            }
            return multiSelectOptions;
        };

        /**
         * Get unpublished materials
         * @function getUnpublishedMaterialUpdate
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.unpublishedMaterialsSelectAll = function () {
            if (!self.materialsFilterSort.retainStaticFilters) {
                self.sortModel = {id : self.materialSortOptions[0].value, label: self.materialSortOptions[0].text};
                self.materialsFilterSort.sort = "lastModified,id,desc";
            }
            self.materialsFilterSort.filmId = [];
            self.materialsFilterSort.wbAssetType = [];
            self.materialsFilterSort.wbpiAssetType = [];
            self.materialsFilterSort.fileType = [];
            //self.getUnpublishedMaterialUpdate();
        };

         /**
         * Reset All Filter Modells
         * @function getUnpermissionedMaterials
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.resetFilterModels = function () {
            self.sortModel = {id : self.materialSortOptions[0].value, label: self.materialSortOptions[0].text};
        };

        /**
         * Get unpermissioned materials
         * @function getUnpermissionedMaterials
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.getUnpermissionedMaterials = function () {

            toDoService.getUnpermissionedMaterials(self.materialsFilterSort.filmId, self.materialsFilterSort.wbAssetType, self.materialsFilterSort.wbpiAssetType, self.materialsFilterSort.fileType, self.unpermissionedMaterials.size, self.unpermissionedMaterials.number, self.materialsFilterSort.sort).success(function (result) {
                self.unpermissionedMaterials = result;
                self.tabs[1].count = result.totalElements;
                toDoService.todoTabCounts.unpermissionedMaterialsCount = result.totalElements;
                if (self.materialsFilterSort.filmId.length > 0 && self.materialsFilterSort.wbAssetType.length > 0 && self.materialsFilterSort.wbpiAssetType.length > 0) {
                    toDoService.recalculateTodoCounts("unpermissionedMaterialsCount", result.totalElements);
                    rerenderTodoCounts();
                }
                self.getFiltersValuesForUnpermissionedMaterials();
            });
        };


        /**
         * Open edit film modal
         * @function openEditFilmModal
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @param {string} initialTabSelected tab to be selected after opening the edit modal.
         * @param {string} initialSubTabSelected sub tab to be open
         */
        self.openEditFilmModal = function (filmId, eisId, initialTabSelected, initialSubTabSelected) {
            var modalInstance = $uibModal.open({
                templateUrl: "/modules/editFilmModal/modalEditFilm.html",
                controller: "modalEditFilmController",
                controllerAs: "editFilmCtrl",
                keyboard: false,
                backdrop: "static",
                size: "xlg",
                resolve: {
                    filmId: function () {
                        return filmId;
                    },
                    eisId: function () {
                        return eisId;
                    },
                    initialTabSelected: function () {
                        return initialTabSelected;
                    },
                    initialSubTabSelected: function () {
                        return initialSubTabSelected;
                    },
                    page: function () {
                        return "todo";
                    }
                }
            });

            modalInstance.result.then(function () {
                self.getUnpublishedTitleInfo();
            });
        };

        /**
         * Bulk add to queue
         * @function bulkAddToQueue
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @public
         * @param {object[]} permissionQueueItems permission queue items
         */
        self.bulkAddToQueue = function (permissionQueueItems) {
            permissionQueueService.bulkAddToQueue(permissionQueueItems).success(function () {
                $rootScope.$emit("updatePermissionQueueCount", true);
                permissionQueueService.clearSelectedAssets();
                clearSelectedState();
            });
        };

        /**
         * Open lightbox
         * @function openLightBox
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @param {object} $event browser event
         * @param {object} asset asset
         * @param {string} action action
         */
        self.openLightBox = function ($event, asset, action) {
            self.openLightbox = true;
            self.lightboxAsset = asset;
            self.lightboxAction = action;
        };

        /**
         * On tab change
         * @function onTabChange
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @public
         */
        self.onTabChange = function (tab) {
            switch (tab.tabName) {
                case "unpublishedTitleInfo":
                    self.unpublishedTitlesFilterSort = {
                        modifiedInterval: 0,
                        sort: "sourceModifiedDate,desc"
                    };
                    if (currentUser.role === "territoryAdmin") {
                        self.getUnpublishedTitleInfo();
                    }
                    break;
                case "unpermissionedMaterials":
                    self.materialsFilterSort = {
                        filmId: [],
                        modifiedInterval: 0,
                        sort: "lastModified,id,desc",
                        wbAssetType: [],
                        wbpiAssetType: [],
                        fileType: [],
                        page:"unpermissionedMaterials"
                    };
                    self.resetFilterModels();
                    self.getUnpermissionedMaterials();
                    break;
                case "pendingUsers":
                    self.getPendingUsers();
                    break;
            }
        };

        /**
         * Init
         * @function init
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @public
         */
        self.init = function () {
            if(!self.deviceDetectorService.isMobileOrTablet()) {
                setTodoTabCounts();
                self.resetFilterModels();
            } else {
                $window.location.href = "/error/404";
            }
        };

        self.init();

        /*$rootScope.$on("materialUpdatePublished", function () {
            self.getUnpublishedMaterialUpdate();
        });*/
        $rootScope.$on("assetTypeUpdated", function () {
            self.init();
            self.getUnpermissionedMaterials();
        });

        $rootScope.$on("rerenderTodoCounts", function () {
            rerenderTodoCounts();
        });

    }

    controllers.controller("toDoController", ["$rootScope", "$scope", "toDoService", "localStorageService", "permissionQueueService", "convertLocalizationCodeToValueService", "API_EFD", "DEFAULT_PAGE_SIZE", "deviceDetectorService", "userService", "filmRatingService", "$window", "$uibModal", "US_TERRITORY_ID", "assetCategoryConvertor", "shoppingCartService", "AUDIO_VIDEO_ASSET_CATEGORY", toDoController]);
}());
