(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class getRenditionsService
     * @memberOf angular_module.WB.cinehub.services
     * @desc getRenditions service
     * @param {!angular.$http} $http angular $http service
     * @param {strict} API_ROOT API_ROOT constant  
     * @param {service} localStorageService local storage service
     */
    function getRenditionsService($http, API_ROOT) {

        /**
         * translations
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.getRenditionsService
         * @public
         */
        this.getEncodes = function (filmAsset) {
            return $http.post(API_ROOT +"filmAssets/getAssetTranscodes",filmAsset,{
                errorMessage: "error",
                showSpinner: false
            });
        };
        /**
         * get JwtToken
         * @memberOf angular_module.WB.cinehub.services.getRenditionsService
         * @public
         * @function getJwtToken
         * @returns {promise}
         */
        this.getJwtToken = function (data) {
            return $http.post(API_ROOT +"sendplus/getJwtToken", data, {
                errorMessage: "error"
            });
        };
    }

    services.service("getRenditionsService", ["$http", "API_ROOT", getRenditionsService]);
}());
