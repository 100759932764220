(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Cart controller
     * @dec cart controller
     * @class cartController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.$rootScope} $rootScope angular rootScope
     * @param {service} shoppingCartService cart service
     * @param {service} localStorageService local storage service
     * @param {constant} API_EFD API_EFD
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     * @param {service} deviceDetectorService device detector service
     * @param {$uibModal} $uibModal uibModal instance
     * @param {service} assetCategoryConvertor asset category convertor
     * @param {constant} AUDIO_VIDEO_ASSET_CATEGORY AUDIO_VIDEO_ASSET_CATEGORY
     */
    function cartController($rootScope, $scope, shoppingCartService, localStorageService, API_EFD, DEFAULT_PAGE_SIZE, deviceDetectorService, $uibModal, assetCategoryConvertor, AUDIO_VIDEO_ASSET_CATEGORY) {

        /**
         * Instance of cartController
         * @type {cartController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        var self = this,

            /**
             * user id
             * @type {object}
             * @public
             * @memberOf angular_module.WB.cinehub.controllers.cartController
             */
            user = localStorageService.get("userInfo");

        /**
         * API_EFD
         * @constant
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         * @private
         * @type {string}
         */
        self.API_EFD = API_EFD;

        /**
         * AUDIO_VIDEO_ASSET_CATEGORY
         * @constant
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         * @private
         * @type {string}
         */
        self.AUDIO_VIDEO_ASSET_CATEGORY = AUDIO_VIDEO_ASSET_CATEGORY;

        /**
         * default current page
         * @type {int}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        self.currentPage = 1;

        /**
         * page size
         * @type {int}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        self.pageSize = DEFAULT_PAGE_SIZE;

        /**
         * translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        self.translations = localStorageService.get("translations");

        /**
         * view As
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @public
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");

        /**
         * is Large Asset present
         * @type {bool}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        self.isLargeAssetPresent = false;

        /**
         * divice detector
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         * @public
         * @type {service}
         */
        self.deviceDetector = deviceDetectorService;

        /**
         * asset category convertor service
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         * @public
         * @type {service}
         */
        self.assetCategoryConvertor = assetCategoryConvertor;

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        self.pageHeaderItem = {
            pageTitle: self.translations.cart.labels.pageTitle,
            breadcrumbItems: [{
                linkTitle: self.translations.cart.labels.breadcrumbLinkTitle,
                link: "/cart"
            }]
        };

        /**
         * Clear selected state 
         * @function clearSelectedState
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        var clearSelectedState = function () {
            $scope.downloadItems._embedded.downloadItems.forEach(function (asset) {
                asset.selected = false;
            });
        };

        /**
         * open download information modal
         * @function openDownloadInformaitonModal
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        var openDownloadInformaitonModal = function () {
            var itemsSelectedForDownloadText = self.translations.cart.labels.itemsSelectedForDownload + " ",
                fileSize = self.getFileSize(),
                quantity = self.getQuantity();
            if (fileSize) {
                itemsSelectedForDownloadText += quantity + " / " + fileSize + "<br /><br />";
            } else {
                itemsSelectedForDownloadText += quantity + "<br /><br />";
            }
            var cartContainsLargeAssets = "<br /><br />";
            if (self.isLargeAssetPresent) {
                for (var i = 0; i < $scope.downloadItems._embedded.downloadItems.length; i++) {
                    if (!$scope.downloadItems._embedded.downloadItems[i].canBulkDownload) {
                        cartContainsLargeAssets += self.translations.cart.messages.confirmationMessageWithLargeAsset;
                        break;
                    }
                }
            }            
            $uibModal.open({
                templateUrl: "confirmationTemplate.html",
                controller: "modalConfirmationController",
                controllerAs: "modalConfirmationCtrl",
                keyboard: false,
                backdrop: "static",
                size: "md",
                resolve: {
                    message: function () {
                        return itemsSelectedForDownloadText + self.translations.cart.messages.confirmationMessage + cartContainsLargeAssets;
                    },
                    headerTitle: function () {
                        return self.translations.cart.messages.confirmationHeader;
                    },
                    showAffirmativeBtn: function () {
                        return false;
                    },
                    affirmativeBtnText: function () {
                        return "";
                    },
                    cancelBtnText: function () {
                        return self.translations.cart.labels.closeButton;
                    }
                }
            });
        };
        var getCart = function () {
            var errorMessage = "cart.messages.getCartError";

            shoppingCartService.getShoppingCartAssets(user.userId, self.pageSize, self.currentPage, errorMessage).success(function (result) {
                $scope.downloadItems = result;
            });
        };
        /**
         * Disabled bulk add to queue btn
         * @function disableBulkAddToQueueBtn
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         * @public
         */
        self.disableBulkAddToQueueBtn = function () {
            var isSelected = false;
            if ($scope.downloadItems && $scope.downloadItems._embedded && $scope.downloadItems._embedded.downloadItems) {
                isSelected = _.findIndex($scope.downloadItems._embedded.downloadItems, {
                    selected: true
                }) >= 0;
            }
            return !isSelected;
        };

        /**
         * get cart
         * @function getCart
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        self.getCart = function () {
            var errorMessage = "cart.messages.getCartError";

            shoppingCartService.getShoppingCartAssets(user.userId, self.pageSize, self.currentPage, errorMessage).success(function (result) {
                $scope.downloadItems = result;
            });
        };

        /**
         * get Remove cart
         * @function getRemoveCart
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        self.getRemoveCart = function () {
            var errorMessage = "cart.messages.getCartError";
            if(self.selectedItemsToRemove.length == $scope.downloadItems._embedded.downloadItems.length && self.currentPage > 1 ){
                self.currentPage = self.currentPage - 1;
                shoppingCartService.getShoppingCartAssets(user.userId, self.pageSize, self.currentPage, errorMessage).success(function (result) {
                    $scope.downloadItems = result;
                });
            } else {
                shoppingCartService.getShoppingCartAssets(user.userId, self.pageSize, self.currentPage, errorMessage).success(function (result) {
                    $scope.downloadItems = result;
                });
            }
        };
        /**
         * get quantity
         * @function getQuantity
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        self.getQuantity = function () {
            var selectedItems = $scope.downloadItems && $scope.downloadItems._embedded ? _.filter($scope.downloadItems._embedded.downloadItems, {
                    selected: true
                }) : [],
            // return selectedItems.length;
                fileQuantity = _.map(selectedItems, function (item) {
                    if(item.encodesCount == 0){
                        item.encodesCount = 1;
                    }
                    return parseInt(item.encodesCount);
                }),
                total = _.sum(fileQuantity);
            return total;
        };

        /**
         * get file size
         * @function getFileSize
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        self.getFileSize = function () {
            var selectedItems = $scope.downloadItems && $scope.downloadItems._embedded ? _.filter($scope.downloadItems._embedded.downloadItems, {
                    selected: true
                }) : [],
                fileSizes = _.map(selectedItems, function (item) {
                    if(item.encodeFileSize){
                        return parseInt(item.encodeFileSize); 
                    }
                    else{
                        return parseInt(item.fileSize);
                    }
                }),
                total = _.sum(fileSizes),
                oneMB = 1024 * 1024,
                oneGB = 1024 * 1024 * 1024,
                unit = total > oneGB ? " GB" : " MB",
                totalFileSize = total > oneGB ? total / oneGB : total / oneMB;

            if (totalFileSize) {
                return _.round(totalFileSize, 2) + unit;
            } else {
                return "";
            }
        };

        /**
         * remove items from cart
         * @function removeFromCart
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        self.removeFromCart = function () {
            self.selectedItemsToRemove = $scope.downloadItems && $scope.downloadItems._embedded ? _.filter($scope.downloadItems._embedded.downloadItems, {
                selected: true
            }) : [];

            shoppingCartService.bulkRemoveFromCart().success(function () {
                self.getRemoveCart();
                clearSelectedState();
                $rootScope.$emit("updateShoppingCartCount", true);
            });
        };        

        /**
         * request download
         * @function requestDownload
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        self.requestDownload = function () {
            openDownloadInformaitonModal();
            shoppingCartService.download().success(function () {
                self.removeFromCart();
            });
        };

        /**
         * Open lightbox
         * @function openLightBox
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         * @param {object} $event browser event
         * @param {object} asset asset
         * @param {string} action action
         */
        self.openLightBox = function ($event, asset, action) {
            self.openLightbox = true;
            self.lightboxAsset = asset;
            self.lightboxAction = action;
        };

        $scope.$on("isLargeAssetPresent", function (event, data) {
            self.isLargeAssetPresent = data;
        });

        /**
         * Init function
         * @function init
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         * @public
         */
        self.init = function () {
            self.getCart();
        };
        $rootScope.$on("updateDownloadcart", getCart);
        //INIT
        self.init();

    }

    controllers.controller("cartController", ["$rootScope", "$scope", "shoppingCartService", "localStorageService", "API_EFD", "DEFAULT_PAGE_SIZE", "deviceDetectorService", "$uibModal", "assetCategoryConvertor", "AUDIO_VIDEO_ASSET_CATEGORY", cartController]);
}());
