(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Reset password controller
     * @class emailVerificationController
     * @memberOf angular_module.WB.cinehub.controllers
     * @desc This is reset password controller
     * @param {service} localStorageService local storage service
     */
    function emailVerificationController($timeout, $window, localStorageService, userService) {
        /**
         * Instance to emailVerificationController
         * @private
         * @type {emailVerificationController}
         * @memberOf angular_module.WB.cinehub.controllers.emailVerificationController
         */
        var self = this;

        /**
         * translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.emailVerificationController
         */
        /* TODO(jesse): get translated verification message */
        self.translations = localStorageService.get("translations");

        /**
         * Get parameter by name
         * @function getParameterByName
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.emailVerificationController
         * @returns {string}
         */
        var getParameterByName = function (name, url) {
            if (!url) url = $window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return "";
            //return decodeURIComponent(results[2].replace(/\+/g, " "));
            return decodeURIComponent(results[2]);
        };

        /**
         * Init
         * @function init
         * @memberOf angular_module.WB.cinehub.controllers.emailVerificationController
         * @public
         */
        self.init = function () {
            self.user = {
                loginName: getParameterByName("loginName"),
                emailVerificationToken: getParameterByName("emailVerificationToken")
            };
            userService.verifyEmail(self.user)
                .success(function (result) {
                    self.status = (result.status || "");
                }).error(function (error) {
                    if (error.code) {
                        self.status = error.code;
                    } else {
                        self.status = "error.invalidRequest";
                    }
                });
        };

        //INIT
        self.init();
    }

    controllers.controller("emailVerificationController", [
        "$timeout",
        "$window",
        "localStorageService",
        "userService",
        emailVerificationController
    ]);
}());
