(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Filter Container directive
     * @class filterContainer
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     * @param {service} localStorageService local storage service 
     * @param {service} convertLocalizationCodeToValueService convert localization code to value service
     * @param {service} deviceDetectorService service
     */
    function filterContainer(localStorageService, convertLocalizationCodeToValueService,deviceDetectorService) {
        /**
         * placeholder option
         * @memberOf angular_module.WB.cinehub.controllers.filterContainer
         * @private
         * @type {object}
         */
        var placeholderOption = {};

        /**
         * Convert array to array of object
         * @function convertArrayToArrayOfObjects
         * @memberOf angular_module.WB.cinehub.directives.filterContainer
         * @private
         * @param {array} array  array of values for filters
         * @returns {object[]}
         */
        var convertArrayToArrayOfObjects = function (array) {
            var index = 0,
                arrayLength = array.length,
                newOptions = [];

            for (index; index < arrayLength; index++) {
                newOptions.push({
                    text: array[index],
                    value: array[index]
                });
            }
            return newOptions;
        };

        /**
         * Filter option validator
         * @private
         * @memberOf angular_module.WB.cinehub.directives.filterContainer
         * @param {object[]}  filterOptions filter options
         * @returns {object[]}
         */
        var filterOptionsValidator = function (filterOptions) {
            var index = 0,
                filterOptionsLength = filterOptions.length;

            for (index; index < filterOptionsLength; index++) {
                if (filterOptions[index].options && _.isObject(filterOptions[index].options[0]) && _.isString(filterOptions[index].options[1])) {
                    //splice the first element
                    placeholderOption = filterOptions[index].options[0];
                    filterOptions[index].options.splice(0, 1);

                    filterOptions[index].options = convertArrayToArrayOfObjects(filterOptions[index].options);
                    filterOptions[index].options.unshift(placeholderOption);
                }
            }

            return filterOptions;
        };

        /**
         * Localize filter options
         * @memberOf angular_module.WB.cinehub.directives.filterContainer
         * @private
         * @param {object} filter filter object
         */
        var localizeFilterOptions = function (filter) {
            if (filter.propertyName === "wbAssetType" || filter.propertyName === "wbpiAssetType") {
                filter.options.forEach(function (option) {
                    if (option.value) {
                        option.text = convertLocalizationCodeToValueService.convert(
                            filter.propertyName === "wbAssetType" ? "assetType" : "assetSubType",
                            option.value
                        );
                    }
                });
            }
        };

        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @param {jqueryElement} element jquery element
         * @private
         * @memberOf angular_module.WB.cinehub.directives.filterContainer
         */
        var link = function (scope) {

            /**
             * translations
             * @memberOf angular_module.WB.cinehub.controllers.filterContainer
             * @public
             * @type {object}
             */
            scope.translations = localStorageService.get("translations");

            /**
             * Determines whether the user's device is a mobile or a tablet
             * @public
             * @function isMobileOrTablet
             * @memberOf angular_module.WB.cinehub.controllers.filterContainer
             */
            scope.isMobileOrTablet = deviceDetectorService.isMobileOrTablet;

            /**
             * Init
             * @function init
             * @memberOf angular_module.WB.cinehub.directives.filterContainer
             * @private
             */
            var init = function () {
                if (scope.filterSort === undefined || scope.filterSort === "") {
                    scope.filterSort = {};
                }
                scope.filters = filterOptionsValidator(_.filter(scope.options, function (item) {
                    return item.propertyName !== "sort";
                }));
                scope.sort = _.head(_.filter(scope.options, function (item) {
                    return item.propertyName === "sort";
                }));

                if (scope.sort && scope.sort.options && scope.sort.options[0]) {
                    if (!scope.filterSort["sort"]) {
                        scope.filterSort["sort"] = scope.sort.options[0].value;
                    }
                }

                scope.filters.forEach(function (obj) {
                    if (!scope.filterSort[obj.propertyName] && obj.options && obj.options[0]) {
                        scope.filterSort[obj.propertyName] = obj.options[0].value;
                    }
                    localizeFilterOptions(obj);
                });

            };

            /**
             * change option
             * @function changeOption
             * @memberOf angular_module.WB.cinehub.directives.filterContainer
             * @public
             */
            scope.changeOption = function () {
                for (var val in scope.filterSort) {
                    if (scope.filterSort[val] === null) {
                        scope.filterSort[val] = "";
                    }
                }
                if(scope.data && scope.data.page) {
                    scope.data.page.number = 1;
                }
                scope.onChangeCallback()(scope.filterSort);
            };

            scope.$watch("options", function (newVal) {
                if (newVal) {
                    init();
                }
            });

        };

        return {
            replace: true,
            link: link,
            scope: {
                options: "=",
                onChangeCallback: "&",
                filterSort: "=",
                data: "="
            },
            templateUrl: "filterContainerTemplate.html"
        };
    }

    directives.directive("filterContainer", ["localStorageService", "convertLocalizationCodeToValueService","deviceDetectorService", filterContainer]);
}());
