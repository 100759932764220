(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class helpContactService
     * @memberOf angular_module.WB.cinehub.services
     * @desc asset service
     * @param {service} localStorageService local storage service 
     * @param {service} $q angular q service
     * @param {service} territoriesService territories service 
     * @param {service} $window $window service
     * @param {service} languageService language service
     */
    function helpContactService(localStorageService, $q, territoriesService, $window, languageService, emailService, DEFAULT_CONTACT_US_EMAIL) {

        /**
         * Promise 
         * @type {promise}
         * @private
         * @memberOf angular_module.WB.cinehub.services.helpContactService
         */
        var promise = $q.defer(),

            /**
             * Browser language
             * @type {string}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.helpContactService
             */
            browserLanguage = "en-US";

        /**
         * Flash exhibitor and theater redis cache
         * @memberOf angular_module.WB.cinehub.services.helpContactService
         * @public
         * @function getHelpContact
         * @returns {promise}
         */
        this.getHelpContactEmail = function () {
            languageService.getBrowserLanguage().then(function (browserLang) {
                browserLanguage = browserLang;
                var liveLocales = localStorageService.get("liveLocales"),
                    currentLocale = _.find(liveLocales, {
                        abbreviation: browserLanguage
                    });
                if (!currentLocale) {
                    currentLocale = liveLocales.filter(function (locale) {
                        if (browserLanguage.length > 2) {
                            browserLanguage = browserLanguage.split("-")[0];
                        }
                        return locale.abbreviation.split("-")[0] === browserLanguage.toLowerCase();
                    })[0];
                }
                territoriesService.getTerritoryById(currentLocale.territoryId).success(function (result) {
                    var territoryContactEmail = result._embedded.territories[0].contactInfo;
                    var contactEmail = emailService.isValidEmail(territoryContactEmail) ? territoryContactEmail: DEFAULT_CONTACT_US_EMAIL;
                    promise.resolve(contactEmail);
                }).error(function () {
                    promise.resolve(DEFAULT_CONTACT_US_EMAIL);
                });
            });

            return promise.promise;
        };
    }

    services.service("helpContactService", ["localStorageService", "$q", "territoriesService", "$window", "languageService", "emailService", "DEFAULT_CONTACT_US_EMAIL", helpContactService]);
}());
