(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Film activity history controller
     * @dec film activity history controller
     * @class filmActivityHistoryController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.Scope} $scope angular scope
     * @param {service} activityHistoryService activity history service
     * @param {service} localStorageService local storage service
     * @param {service} redirectService redirect service
     * @param {service} translateService translate service
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     */
    function filmActivityHistoryController($scope, activityHistoryService, localStorageService, redirectService, translateService, DEFAULT_PAGE_SIZE) {

        /**
         * Instance of filmActivityHistoryController
         * @type {filmActivityHistoryController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmActivityHistoryController
         */
        var self = this,

            /**
             * Film id 
             * @type {int}
             * @memberOf angular_module.WB.cinehub.controllers.filmActivityHistoryController
             * @private
             */
            filmId = window.WB.cinehub.preload.filmId;

        /**
         * user
         * @memberOf angular_module.WB.cinehub.controllers.filmActivityHistoryController
         * @public
         * @type {object}
         */
        self.user = localStorageService.get("userInfo");

        /**
         * translations
         * @memberOf angular_module.WB.cinehub.controllers.filmActivityHistoryController
         * @public
         * @type {object}
         */
        self.translations = localStorageService.get("translations");

        /**
         * redirect service
         * @memberOf angular_module.WB.cinehub.controllers.filmActivityHistoryController
         * @public
         * @type {object}
         */
        self.redirectService = redirectService;

        /**
         * default current page
         * @type {int}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmActivityHistoryController
         */
        self.currentPage = 1;

        /**
         * page size
         * @type {int}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmActivityHistoryController
         */
        self.pageSize = DEFAULT_PAGE_SIZE;

        /*
         * Unescape html entities
         * @function getActivityHistories
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.filmActivityHistoryController
         */
        var unescapeHtmlEntities = function (string) {
            var htmlUnescapes = {
                    "&amp;": "&",
                    "&lt;": "<",
                    "&gt;": ">",
                    "&quot;": "\"",
                    "&#39;": "'",
                    "&#96;": "`",
                    "&nbsp;": " ",
                    "&copy;": "©",
                    "&reg;": "®"
                },
                reEscapedHtml = /&(?:amp|lt|gt|quot|#39|#96|nbsp|copy|reg);/g,
                reHasEscapedHtml = RegExp(reEscapedHtml.source),
                ret = "";

            string = string.toString();
            ret = (string && reHasEscapedHtml.test(string)) ? string.replace(reEscapedHtml, function getNewValue(key) { return htmlUnescapes[key]; }) : string;
            return ret;
        };

        /*
         * Get activity histories
         * @function getActivityHistories
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmActivityHistoryController
         */
        self.getActivityHistories = function () {
            var errorMessage = self.translations.filmDetails.messages.getActivityHistoryError,
                tableName = "film",
                size = DEFAULT_PAGE_SIZE,
                sort = "lastModified,desc";

            activityHistoryService.getActivityHistories(tableName, filmId, self.currentPage, size, sort, errorMessage).success(function (result) {
                var oldCollection,
                    newCollection;
                self.activityHistories = result;

                self.activityHistories._embedded.activityHistories.forEach(function (activityHistory) {
                    if (activityHistory.columnName === "genres" || activityHistory.columnName === "release_patterns" || activityHistory.columnName === "aspect_ratios" || activityHistory.columnName === "formats") {
                        oldCollection = translateService.findItemsByProperty(self.translations.values[_.camelCase(activityHistory.columnName)], "code", JSON.parse(activityHistory.fieldOldValue));
                        newCollection = translateService.findItemsByProperty(self.translations.values[_.camelCase(activityHistory.columnName)], "code", JSON.parse(activityHistory.fieldNewValue));

                        activityHistory.fieldOldValue = _.map(oldCollection, "value").toString().replace(new RegExp(",", "g"), ", ");
                        activityHistory.fieldNewValue = _.map(newCollection, "value").toString().replace(new RegExp(",", "g"), ", ");
                    }
                });
            });
        };

        /**
         * html to plain text
         * @function htmlToPlainText
         * @public
         * @param {str} text
         * @memberOf angular_module.WB.cinehub.controllers.filmActivityHistoryController
         */
        self.htmlToPlainText = function(text) {
            var plainText = text ? String(text).replace(/<[^>]+>/gm, "") : "";
            return unescapeHtmlEntities(plainText);
        };

        /**
         * get field name
         * @function getFieldName
         * @public
         * @param {str} columnName column name
         * @memberOf angular_module.WB.cinehub.controllers.filmActivityHistoryController
         */
        self.getFieldName = function(columnName) {
            return _.find(self.translations.values.fieldNames, { code: columnName }).value;
        };

        /**
         * Init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmActivityHistoryController
         */
        self.init = function () {
            self.getActivityHistories();
        };

        $scope.$on("filmUpdated", function () {
            self.getActivityHistories();
        });

        //INIT
        self.init();
    }

    controllers.controller("filmActivityHistoryController", ["$scope", "activityHistoryService", "localStorageService", "redirectService", "translateService", "DEFAULT_PAGE_SIZE", filmActivityHistoryController]);
} ());
