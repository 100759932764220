(function () {
    "use strict";

    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Exhibitor details controller
     * @dec The exhibitor details controller
     * @class exhibitorDetailsController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {service} localStorageService localstorage service
     * @param {service} exhibitorsService exhibitros service
     * @param {service} theatersService theaters service
     * @param {service} userService user service
     * @param {$uibModal} $uibModal $uibModal
     * @param {$window} $window $window
     * @param {service} restrictService restrict service
     * @param {service} deviceDetectorService device detector service
     * @param {service} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     * @param {service} cacheService cache service
     */
    function exhibitorDetailsController(localStorageService, exhibitorsService, theatersService, userService, $uibModal, $window, restrictService, deviceDetectorService, DEFAULT_PAGE_SIZE, cacheService) {

        /**
         * Instance of exhibitorDetailsController
         * @type {exhibitorDetailsController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.exhibitorDetailsController
         */
        var self = this,

            /**
             * Exhibitor data copy
             * @memberOf angular_module.WB.cinehub.controllers.exhibitorDetailsController
             * @private
             * @type {object}
             */
            exhibitorDataCopy = null;

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.exhibitorDetailsController
         */
        self.pageHeaderItem = {
            pageTitle: "",
            breadcrumbItems: [{
                linkTitle: "",
                link: "/search/exhibitors-theaters"
            }, {
                linkTitle: "",
                link: ""
            }]
        };

        /**
         * The translations
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.exhibitorDetailsController
         */
        self.translations = localStorageService.get("translations");

        /**
         * impersonate user flag
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.exhibitorDetailsController
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");

        /**
         * The user info
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.exhibitorDetailsController
         */
        self.user = localStorageService.get("userInfo");

        /**
         * Sort string
         * @type {string}
         * @memberOf angular_module.WB.cinehub.controllers.exhibitorDetailsController
         * @public
         */
        self.sort = "name,asc";

        /**
         * Exhibitors data
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.exhibitorDetailsController
         */
        self.exhibitorData = {};

        /**
         * Theaters data
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.exhibitorDetailsController
         */
        self.theatersData = {
            page: {
                size: DEFAULT_PAGE_SIZE,
                number: 1
            }
        };
       
        /**
         * Get exhibitor by id
         * @function getExhibitorById
         * @memberOf angular_module.WB.cinehub.controllers.exhibitorDetailsController
         * @public
         * @returns {promise}
         */
        self.getExhibitorById = function () {
            return exhibitorsService.findById(window.WB.cinehub.preload.exhibitorId).success(function (result) {
                self.exhibitorData = result._embedded.exhibitors[0];
                if (self.pageHeaderItem.breadcrumbItems.length > 1) {
                    self.pageHeaderItem.breadcrumbItems[1].linkTitle = self.exhibitorData.name;
                } else {
                    self.pageHeaderItem.breadcrumbItems[0].linkTitle = self.exhibitorData.name;
                }
                exhibitorDataCopy = _.clone(self.exhibitorData);
            });
        };

        /**
         * Open theater details
         * @function openUserProfile
         * @memberOf angular_module.WB.cinehub.controllers.exhibitorDetailsController
         * @public
         * @param {int} theaterId theater id
         */
        self.openTheaterDetails = function (theaterId) {
            $window.open("/theater/" + theaterId + "/", "_blank");
        };

        /**
         * Get theaters by exhibitor id
         * @function getTheatersByExhibitorId
         * @memberOf angular_module.WB.cinehub.controllers.exhibitorDetailsController
         * @public
         * @returns {promise}
         */
        self.getTheatersByExhibitorId = function (filterQuery, page, size, sortOrder) {
            var sort = sortOrder || self.sort;
            return theatersService.findByExhibitorId(window.WB.cinehub.preload.exhibitorId, self.theatersData.page.number, self.theatersData.page.size, sort).success(function (result) {
                self.theatersData = result;
            });
        };

        /**
         * Save Exhibitor details info
         * @function saveExhibitorDetailsInfo
         * @memberOf angular_module.WB.cinehub.controllers.exhibitorDetailsController
         * @public
         */
        self.saveExhibitorInfo = function () {
            self.exhibitorData.localNameManuallyUpdated = 1;
            exhibitorsService.updateExhibitors(self.exhibitorData).success(function () {
                self.isEditMode = false;
                exhibitorDataCopy = _.clone(self.exhibitorData);
                userService.updateUserAffiliationByExhibitor([self.exhibitorData]);
                cacheService.deleteExhibitorsTheatersRedisCache();
            });
        };

        /**
         * Cancel
         * @function cancel
         * @memberOf angular_module.WB.cinehub.controllers.exhibitorDetailsController
         * @public
         */
        self.cancel = function () {
            if (self.exhibitorForm.$dirty) {
                $uibModal.open({
                    templateUrl: "confirmationTemplate.html",
                    controller: "modalConfirmationController",
                    controllerAs: "modalConfirmationCtrl",
                    keyboard: false,
                    backdrop: "static",
                    size: "md",
                    resolve: {
                        message: function () {
                            return self.translations.global.messages.confirmationMessage;
                        },
                        headerTitle: function () {
                            return self.translations.global.messages.warning;
                        },
                        showAffirmativeBtn: function () {
                            return true;
                        },
                        affirmativeBtnText: function () {
                            return self.translations.global.labels.yesButton;
                        },
                        cancelBtnText: function () {
                            return self.translations.global.labels.noButton;
                        }
                    }
                }).result.then(function () {
                    self.exhibitorData = exhibitorDataCopy;
                    self.isEditMode = false;
                });
            } else {
                self.isEditMode = false;
            }
        };

        /**
         * Init
         * @function init
         * @memberOf angular_module.WB.cinehub.controllers.exhibitorDetailsController
         * @public
         */
        self.init = function () {
            if (deviceDetectorService.isMobileOrTablet()) {
                $window.location.href = "/";
                return false;
            }
            self.pageHeaderItem.pageTitle = self.translations.exhibitorDetails.labels.pageTitle;
            self.pageHeaderItem.breadcrumbItems[0].linkTitle = self.translations.exhibitorDetails.labels.breadcrumbLinkTitle;
            if (restrictService.getAccessGrant("standard")) {
                self.pageHeaderItem.breadcrumbItems.splice(0, 1);
            }
            self.getExhibitorById();
            self.getTheatersByExhibitorId();
        };

        //INIT
        self.init();
    }

    controllers.controller("exhibitorDetailsController", ["localStorageService", "exhibitorsService", "theatersService", "userService", "$uibModal", "$window", "restrictService", "deviceDetectorService", "DEFAULT_PAGE_SIZE", "cacheService", exhibitorDetailsController]);
}());
