/** Global JL **/
(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * Email service
     * @class emailService
     * @param {!angular.$http} $http angular $http service
     * @param {strict} API_ROOT API_ROOT constant
     * @memberOf angular_module.WB.cinehub.services
     * @desc The email service
     */
    function emailService($http, API_ROOT, localStorageService) {

        /**
         * Instance to emailService
         * @memberOf angular_module.WB.cinehub.services.emailService
         * @private
         * @type {emailService}
         */
        var self = this;

        /**
         * Translations
         * @private
         * @type {object}
         * @memberOf angular_module.WB.cinehub.services.emailServoce
         */
        var translations = localStorageService.get("translations");



        /**
         * Is email wb
         * @function isEmailWB
         * @param {string} email email
         * @returns {boolean}
         * @public
         * @desc Commenting out Okta stuff for now. CIN-1273
         */
        self.isEmailWB = function (email) {
            //return /@warnerbros.com$/i.test(email) || /@wbconsultant.com$/i.test(email) || /@wblogon.com$/i.test(email);
            return $http.post(API_ROOT + "users/validateAccountType", { loginName: email }, {
                errorMessage: translations.forgotPassword.messages.resetPasswordError,
                showSpinner: false
            });
        };

        /**
         * Get email format regex
         * @function getEmailFormatRegex
         * @returns {regex}
         * @public
         */
        self.getEmailFormatRegex = function () {
            return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        };

        /**
         * isValidEmail - returns true if the email is not empty or null, false otherwise
         * @memberOf angular_module.WB.cinehub.controllers.emailServoce
         * @function isValidEmail
         * @param {string} email
         * @private
         */
        self.isValidEmail = function(emailId) {
            return emailId &&  emailId.trim().indexOf("@") > 0;
        };
    }

    services.service("emailService", ["$http", "API_ROOT", "localStorageService", emailService]);
}());
