(function () {
    "use strict";
    var services = angular.module("WB.cinehub.services");

    /**
     * Shopping cart service
     * @class shoppingCartService
     * @memberOf angular_module.WB.cinehub.services
     * @desc This service is to be used for both add/remove assets to download.
     * @param {!angular.$http} $http angular $http service
     * @param {$rootScope} $rootScope angular root scope
     * @param {string} API_ROOT API_ROOT constant
     * @param {service} localStorageService local storage service
     * @param {factory} promiseFactory promise factory
     * @param {int} DOWNLOAD_CART_GET_CALL_SIZE constant download cart get call size
     */
    function shoppingCartService($rootScope, $http, API_ROOT, localStorageService, promiseFactory, DOWNLOAD_CART_GET_CALL_SIZE) {

        /**
         * User info
         * @type {int}
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.shoppingCartService
         */
        var user = localStorageService.get("userInfo"),

            /**
             * Translations
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.shoppingCartService
             * @type {object}
             */
            translations = localStorageService.get("translations"),

            /**
             * Location information
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.shoppingCartService
             * @type {object}
             */
            location = localStorageService.get("location"),

            /**
             * instance to shoppingCartService
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.shoppingCartService
             * @type {shoppingCartService}
             */
            self = this,

            /**
             * Add to cart query
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.shoppingCartService
             * @type {object}
             */
            addToCartQuery = {
                "user": user && user.userId ? user.userId : -1,
                "assetId": "",
                "rendition": "1",
                "timestamp": moment().utc().format(),
                "regionId": -1,
                "localeId": user && user.role === "homeOfficeAdmin" ? -1 : location && location.localeId ? location.localeId : -1,
                "territoryId": user && user.role === "homeOfficeAdmin" ? -1 : location && location.territoryId ? location.territoryId : -1,
                "exhibitorId": localStorageService.get("currentExhibitorAffiliation") || -1,
                "theaterId": localStorageService.get("currentTheaterAffiliation") || -1
            },

            /**
             * selected assets
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.shoppingCartService
             * @type {object[]}
             */
            selectedAssets = [];

        /**
         * Shopping cart count 
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.shoppingCartService
         */
        self.shoppingCart = localStorageService.get("shoppingCart") || {
            _embedded: {
                downloadItems: []
            },
            page: {
                totalElements: 0
            }
        };

        /**
         * Remove from cart
         * @function removeFromCart
         * @memberOf angular_module.WB.cinehub.controllers.shoppingCartService
         * @private
         * @param {object[]} itemsToRemove items to remove
         * @returns {promise}
         */
        var removeFromCart = function (itemsToRemove) {
            return $http.delete(API_ROOT + "downloadItems/removeAll", {
                errorMessage: translations.global.messages.cannotRemoveAssetsToCart,
                data: itemsToRemove,
                headers: {
                    "Content-Type": "application/json;charset=utf-8"
                }
            });
        };

        /**
         * Check selected record are unique 
         * @function checkSelectedRecordsUnique
         * @memberOf angular_module.WB.cinehub.controllers.shoppingCartService
         * @private
         * @param {object[]} downloadItems download items
         * @returns {object[]}
         */
        var checkSelectedRecordsUnique = function (downloadItems) {
            var downloadItemIndex = 0,
                downloadItemsLength = downloadItems.length;
            if (downloadItemsLength > 0) {
                for (downloadItemIndex; downloadItemIndex < downloadItemsLength; downloadItemIndex++) {
                    var downloadItem = downloadItems[downloadItemIndex];
                    selectedAssets = selectedAssets.filter(function (selectedAsset) {
                        if(selectedAsset.adStreamAsset == false){
                            return selectedAsset.id != downloadItem.assetId;
                        }
                        else{
                            return true;
                        }
                    });
                }
            }
        };

        /**
         * Check assets in download cart 
         * @function checkAssetsInDownloadCart
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.shoppingCartService
         * @param {object[]} assets assets to check
         */
        self.checkAssetsInDownloadCart = function (assets) {
            if (assets) {
                var assetIndex = 0,
                    assetsLength = assets.length;
                for (assetIndex; assetIndex < assetsLength; assetIndex++) {
                    assets[assetIndex].isInDownloadCart = _.findIndex(self.shoppingCart._embedded.downloadItems, {
                        assetId: assets[assetIndex].id.toString()
                    }) !== -1;
                }
            }
        };

        /**
         * Add asset
         * @function addAsset
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.shoppingCartService
         * @param {object} asset asset
         */
        self.addAsset = function (asset) {
            var isAlreadySelected = _.find(selectedAssets, {
                id: asset.id
            });
            if (!isAlreadySelected) {
                selectedAssets.push(asset);
            }
        };

        /**
         * Remove asset
         * @function removeAsset
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.shoppingCartService
         * @param {object} asset asset
         */
        self.removeAsset = function (asset) {
            selectedAssets.splice(_.findIndex(selectedAssets, {
                assetId: asset.assetId
            }), 1);
        };

        /**
         * Get selected assets
         * @function getSelectedAssets
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.shoppingCartService
         * @returns {object[]}
         */
        self.getSelectedAssets = function () {
            return selectedAssets;
        };

        /**
         * Get shopping cart count 
         * @function getShoppingCartCount
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.shoppingCartService
         * @returns {promise}
         * @param {boolean} refresh flag indicates weather we want to get a fresh data from the server or used a cached one
         */
        self.getShoppingCartCount = function (refresh) {
            var shoppingCart = localStorageService.get("shoppingCart"),
                deferred = promiseFactory.defer();

            if (!refresh && shoppingCart !== undefined && shoppingCart !== "" && shoppingCart !== null) {
                self.shoppingCart = shoppingCart;
                deferred.resolve(shoppingCart);
                return deferred.promise;
            }

            if (refresh || shoppingCart === undefined || shoppingCart === "" || shoppingCart === null) {
                return self.getShoppingCartAssets(user.userId, DOWNLOAD_CART_GET_CALL_SIZE).then(function (result) {
                    self.shoppingCart = result.data;
                    localStorageService.set("shoppingCart", result.data);
                });
            }

        };

        /**
         * Get shopping cart assets
         * @memberOf angular_module.WB.cinehub.services.shoppingCartService
         * @public
         * @function getShoppingCartAssets
         * @param {int} userId user id
         * @param {int} pageSize page size
         * @param {string} errorMessage error message
         * @returns {promise}
         */
        self.getShoppingCartAssets = function (userId, pageSize, pageNmber) {
            if (user.role === "homeOfficeAdmin") {
                return $http.get(API_ROOT + "downloadItems/search/findByHOAUserOrderByTimestampDesc?user=" + userId + "&territoryId=-1&localeId=-1&recType=orig&exhibitorId=-1&theaterId=-1&page=" + pageNmber + "&size=" + pageSize, {
                    errorMessage: translations.global.messages.getCartInformationError
                });
            } else {
                return $http.get(API_ROOT + "downloadItems/search/findByUserOrderByTimestampDesc?user=" + userId + "&page=" + pageNmber + "&size=" + pageSize, {
                    errorMessage: translations.global.messages.getCartInformationError
                });
            }
        };

        /**
         * Bulk remove from cart
         * @function removeFromCart
         * @public
         * @memberOf angular_module.WB.cinehub.services.shoppingCartService
         * @returns {promise}
         * @param {object} singleItem single item to remove
         */
        self.removeFromCart = function (singleItem) {
            return removeFromCart([singleItem]);
        };

        /**
         * Bulk remove from cart
         * @function bulkRemoveFromCart
         * @public
         * @memberOf angular_module.WB.cinehub.services.shoppingCartService
         * @returns {promise}
         */
        self.bulkRemoveFromCart = function () {
            return removeFromCart(selectedAssets);
        };

        /**
         * Clear selected assets 
         * @function clearSelectedAssets
         * @memberOf angular_module.WB.cinehub.services.shoppingCartService
         * @public
         */
        self.clearSelectedAssets = function () {
            selectedAssets = [];
        };

        /**
         * add to cart
         * @function addToCart
         * @public
         * @memberOf angular_module.WB.cinehub.services.shoppingCartService
         * @returns {promise}
         * @param {int} singleItem single item to add
         */
        self.addToCart = function (asset,selectedEncodes) {
            //var assets = [];
            var masterAssetDetails =[],
                masteraddToCartQuery = {
                    "assetId": asset.adstreamAssetId,
                    "fileId": asset.masterFileId
                };
            masterAssetDetails.push(masteraddToCartQuery);
            addToCartQuery.assetId = asset.id;
            addToCartQuery.filmId = asset.filmId;
            if(selectedEncodes){
                addToCartQuery.adstreamEncodes = selectedEncodes;
            } else{
                addToCartQuery.adstreamEncodes = masterAssetDetails; 
            }
            if(asset.adstreamAssetId == undefined && asset.masterFileId == undefined){
                addToCartQuery.adstreamEncodes = null;
            }
            $rootScope.$emit("assetSuccessfullyAddedToCart");
            //assets.push(addToCartQuery);
            return $http.post(API_ROOT + "downloadItems/saveToCart", addToCartQuery, {
                errorMessage: translations.global.messages.cannotAddAssetToCart
            });
        };

        /**
         * add to cart
         * @function bulkAddToCart
         * @public
         * @memberOf angular_module.WB.cinehub.services.shoppingCartService
         * @returns {promise}
         * @param {object[]} downloadItems download items
         */
        self.bulkAddToCart = function (downloadItems) {
            var assets = [],
                cartItem = {};

            checkSelectedRecordsUnique(downloadItems);

            selectedAssets.forEach(function (asset) {
                cartItem = _.clone(addToCartQuery);
                cartItem.assetId = asset.id;
                cartItem.filmId = asset.filmId;
                cartItem.masterFileId = asset.masterFileId;
                cartItem.masterFileSize = asset.fileSize;
                cartItem.adstreamAssetId = asset.adstreamAssetId;
                cartItem.canBulkDownload = asset.canBulkDownload;
                cartItem.spotlight = asset.spotlightParentId ? asset.spotlightParentId : "";
                assets.push(cartItem);
            });

            return $http.post(API_ROOT + "downloadItems/saveAll", assets, {
                errorMessage: translations.global.messages.cannotAddAssetsToCart
            });
        };

        /**
         * download assets
         * @function download
         * @public
         * @memberOf angular_module.WB.cinehub.services.shoppingCartService
         * @returns {promise}
         */
        self.download = function () {
            return $http.put(API_ROOT + "filmAssetsDownload/downloadAssets?userId=" + user.userId + "&territoryId=" + addToCartQuery.territoryId + "&localeId=" +
                addToCartQuery.localeId + "&exhibitorId=" + addToCartQuery.exhibitorId + "&theaterId=" + addToCartQuery.theaterId, selectedAssets, {
                    errorMessage: translations.cart.messages.downloadAssetsError
                });
        };
        
        self.removeCartPopover = function(downloadItem){
            return $http.delete(API_ROOT + "downloadItems/removeEncode", {
                errorMessage: translations.global.messages.cannotRemoveAssetsToCart,
                data: downloadItem,
                headers: {
                    "Content-Type": "application/json;charset=utf-8"
                }
            });
        };

        self.getCartEncodes = function(downloadItem){
            return $http.post(API_ROOT +"downloadItems/getEncodeData",downloadItem,{
                errorMessage: "error",
                showSpinner: false
            });
        };
    }

    services.service("shoppingCartService", ["$rootScope", "$http", "API_ROOT", "localStorageService", "promiseFactory", "DOWNLOAD_CART_GET_CALL_SIZE", shoppingCartService]);
}());
