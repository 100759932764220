(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * Home service
     * @class homeService
     * @memberOf angular_module.WB.cinehub.services
     * @desc home service
     * @param {!angular.$http} $http anguar $http service
     * @param {string} API_ROOT API_ROOT constant
     * @param {service} localStorageService local storage service
     */
    function homeService($http, API_ROOT, localStorageService) {

        /**
         * Translations
         * @type {object}
         * @private
         * @memberOf angular_module.WB.cinehub.services.homeService
         */
        var translations = localStorageService.get("translations");

        /**
         * Get home page results
         * @memberOf angular_module.WB.cinehub.services.homeService
         * @public
         * @function getHomePageResults
         * @returns {promise}
         */
        this.getHomePageResults = function () {
            return $http.get(API_ROOT + "home/getHomePageResults?", {
                errorMessage: translations.home.messages.getHomePageResultsError,
                showSpinner: false
            });
        };


    }

    services.service("homeService", ["$http", "API_ROOT", "localStorageService", homeService]);
} ());
