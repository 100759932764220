(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Prevent right click
     * @class preventRightClick
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     */
    function preventRightClick() {
        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @param {jqueryElement} element jquery element
         * @private
         * @memberOf angular_module.WB.cinehub.directives.preventRightClick
         */
        var link = function (scope, element) {
            element.bind("contextmenu", function(e){
                e.preventDefault();
            });
        };

        return {
            restrict: "A",
            link: link
        };
    }

    directives.directive("preventRightClick", [preventRightClick]);
} ());
