(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class assetCategoryConvertor
     * @memberOf angular_module.WB.cinehub.services
     * @desc utility functions for converting asset category into the param for efd call. 
     */
    function assetCategoryConvertor() {

        /**
         * ARTWORK
         * @constant
         * @type {string}
         * @private
         * @memberOf angular_module.WB.cinehub.services.assetCategoryConvertor
         */
        var ARTWORK = "artwork",

            /**
             * MEDIA
             * @constant
             * @type {string}
             * @private
             * @memberOf angular_module.WB.cinehub.services.assetCategoryConvertor
             */
            MEDIA = "audio / video",

            /**
             * WRITTEN
             * @constant
             * @type {string}
             * @private
             * @memberOf angular_module.WB.cinehub.services.assetCategoryConvertor
             */
            WRITTEN = "written",
            
            /**
             * OTHER
             * @constant
             * @type {string}
             * @private
             * @memberOf angular_module.WB.cinehub.services.assetCategoryConvertor
             */
            OTHER = "other";

        /**
         * Convert asset category into efd asset type param
         * @memberOf angular_module.WB.cinehub.services.assetCategoryConvertor
         * @public
         * @function convertAssetCategoryIntoEfdAssetTypeParam
         * @returns {string}
         */
        this.convertAssetCategoryIntoEfdAssetTypeParam = function (assetCategoryCode, fileTypeCategoryCode) {
            if (!assetCategoryCode) {
                return "";
            }

            if (assetCategoryCode.toLowerCase() === ARTWORK && fileTypeCategoryCode === "NON_VIDEO") {
                return "artwork";
            } else if (assetCategoryCode.toLowerCase() === ARTWORK && fileTypeCategoryCode === "VIDEO") {
                return "audiovideo";
            } else if (assetCategoryCode.toLowerCase() === MEDIA && fileTypeCategoryCode === "VIDEO") {
                return "audiovideo";
            } else if (assetCategoryCode.toLowerCase() === WRITTEN && fileTypeCategoryCode === "NON_VIDEO") {
                return "written";
            } else if (assetCategoryCode.toLowerCase() === WRITTEN && fileTypeCategoryCode === "VIDEO") {
                return "audiovideo";
            } else if (fileTypeCategoryCode === "ARCHIVE") {
                return "archive";
            } else if (assetCategoryCode.toLowerCase() === MEDIA && fileTypeCategoryCode === "NON_VIDEO") {
                return "artwork";
            } else if (assetCategoryCode.toLowerCase() === OTHER && fileTypeCategoryCode === "NON_VIDEO") {
                return "artwork";
            } else if (assetCategoryCode.toLowerCase() === OTHER && fileTypeCategoryCode === "VIDEO") {
                return "audiovideo";
            } else {
                return "artwork";
            }

        };
    }
    services.service("assetCategoryConvertor", [assetCategoryConvertor]);
}());
