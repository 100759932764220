(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * sendPlus Controller
     * @dec sendPlus Controller 
     * @class sendPlusController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {service} sendPlusService sendPlus service
     * @param {service} localStorageService local storage service
     */
    function sendPlusController(sendPlusService) {

        /**
         * Instance of sendPlusController
         * @type {sendPlusController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.sendPlusController
         */
        var self = this;
        /**
         * User info object
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.sendPlusController
         * @private
         */
           // user = localStorageService.get("userInfo"),

        /**
         * territory info object for registration flow
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.sendPlusController
         * @private
         */
           // territory = localStorageService.get("territoryInfo");

           
        /**
         * translations
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.sendPlusController
         * @public 
         */
//        self.translations = localStorageService.get("translations");
        
        /**
         * location
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.sendPlusController
         * @public 
         */
//        self.location = localStorageService.get("location");

       

        /**
         * sendPlus
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.sendPlusController
         */
        self.jwtToken = {};
        self.tokenGenerate = false;
        /**
         * get JwtToken
         * @function getJwtToken 
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.sendPlusController
         */
        self.getJwtToken = function () {
            sendPlusService.getJwtToken().success(function (result) {
                self.tokenGenerate = true;
                self.jwtToken = result.data.jwtToken;
            });
        };
        self.setSendPlusUrl = function () {
            var url = "sendplus:downloadrequest?sourceId=562a1b3362356f7f2ea7cb17&jwt="+ self.jwtToken;
            location.href = url;
        };
//        self.init = function () {
//            self.getJwtToken();
//        };

        //INIT
       // self.init();
    }

    controllers.controller("sendPlusController", ["sendPlusService", "localStorageService", sendPlusController]);
}());
