(function() {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class addEditNewsService
     * @memberOf angular_module.WB.cinehub.services
     * @desc Add edit news service
     * @param {!angular.$http} $http angular $http service
     * @paarm {strict} API_ROOT API_ROOT constant
     * @param {service} localStorageService
     */
    function addEditNewsService($http, API_ROOT, localStorageService) {

        /**
         * Location
         * @type {object}
         * @private
         * @memberOf angular_module.WB.cinehub.services.addEditNewsService
         */
        var location = localStorageService.get("location"),

            /**
             * translations
             * @type {object}
             * @memberOf angular_module.WB.cinehub.services.addEditNewsService
             * @private
             */
            translations = localStorageService.get("translations");

        /**
         * get news details
         * @memberOf angular_module.WB.cinehub.services.addEditNewsService
         * @public
         * @param {int} newsId news id
         * @returns {promise}
         */
        this.getNewsDetails = function(newsId) {
            return $http.get(API_ROOT + "newsItems/search/findLocById/?id=" + newsId, {
                errorMessage: translations.addEditNewsModal.labels.getNewsDetailsError,
                alertKey: "modalAlert"
            });
        };

        /**
         * Get film titles
         * @memberOf angular_module.WB.cinehub.services.addEditNewsService
         * @public
         * @function getFilmTitles
         * @returns {promise}
         */
        this.getFilmTitles = function() {
            return $http.get(API_ROOT + "films/search/findLocAllNews?page=0&size=100&projection=forNewsItem", {
                errorMessage: translations.addEditNewsModal.labels.getFilmTitlesError
            });
        };

        /**
         * create news details changes
         * @memberOf angular_module.WB.cinehub.services.addEditNewsService
         * @public
         * @param {obj} newsItem news item
         * @returns {promise}
         */
        this.create = function(newsItem) {
            newsItem.territoryId = location.territoryId;
            newsItem.localeId = location.localeId;
            newsItem.recType = "pub";
            return $http.post(API_ROOT + "newsItems/", newsItem, {
                errorMessage: translations.addEditNewsModal.labels.publishNewsError,
                alertKey: "modalAlert"
            });
        };

        /**
         * update news details changes
         * @memberOf angular_module.WB.cinehub.services.addEditNewsService
         * @public
         * @param {obj} newsItem news item
         * @returns {promise}
         */
        this.update = function(newsItem) {
            newsItem.territoryId = location.territoryId;
            newsItem.localeId = location.localeId;
            newsItem.recType = "pub";
            return $http.put(API_ROOT + "newsItems/" + newsItem.id, newsItem, {
                errorMessage: translations.addEditNewsModal.labels.publishNewsError,
                alertKey: "modalAlert"
            });
        };
    }

    services.service("addEditNewsService", ["$http", "API_ROOT", "localStorageService", addEditNewsService]);
}());
