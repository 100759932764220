(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Multi Select Filter Container directive
     * @class multiSelectFilterContainer
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     * @param {service} localStorageService local storage service 
     * @param {service} convertLocalizationCodeToValueService convert localization code to value service
     * @param {service} deviceDetectorService service
     */
    function multiSelectFilterContainer(localStorageService, convertLocalizationCodeToValueService,deviceDetectorService, $q) {


         /**
         * Directive
         * active Tab Index variable to keep track of event handlers.
         * @private
         * @type Integer
         * @memberOf angular_module.WB.cinehub.directives.multiSelectFilterContainer
         */
        var activeTabIndex = -1;
        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @param {jqueryElement} element jquery element
         * @private
         * @memberOf angular_module.WB.cinehub.directives.multiSelectFilterContainer
         */
        var link = function (scope) {

            /**
             * translations
             * @memberOf angular_module.WB.cinehub.controllers.multiSelectFilterContainer
             * @public
             * @type {object}
             */
            scope.translations = localStorageService.get("translations");

            /**
             * Determines whether the user's device is a mobile or a tablet
             * @public
             * @function isMobileOrTablet
             * @memberOf angular_module.WB.cinehub.controllers.multiSelectFilterContainer
             */
            scope.isMobileOrTablet = deviceDetectorService.isMobileOrTablet;

             /**
             * Filter Stack - Maintains all of the filter invocations with order.
             * @public
             * @memberOf angular_module.WB.cinehub.controllers.multiSelectFilterContainer
             * @type [Array]
             */
            scope.filterCallStack = [];

             /**
             * primaryFilterData - Maintains primary filter selection items.
             * @public
             * @memberOf angular_module.WB.cinehub.controllers.multiSelectFilterContainer
             * @type [Array]
             */
            scope.primaryFilterData = [];



             /**
             * allFilterValues - intialize all Filter Values.
             * @public
             * @memberOf angular_module.WB.cinehub.controllers.multiSelectFilterContainer
             * @type [Array]
             */
            scope.allFilterValues = [];


            /**
             * allFilterValues - intialize all Filter Values.
             * @public
             * @memberOf angular_module.WB.cinehub.controllers.multiSelectFilterContainer
             * @type [Array]
             */
            scope.filters = [];


             /**
             * enableAllFilters boolean flag.
             * @public
             * @memberOf angular_module.WB.cinehub.controllers.multiSelectFilterContainer
             * @type Boolean
             */
            scope.enableAllFilters = false;

             /**
             * selectSettings - MultiFilter Select plugin settings.
             * @public
             * @memberOf angular_module.WB.cinehub.controllers.multiSelectFilterContainer
             * @type {Object}
             */
            scope.filterSettings = { idProp: "value",
                                    displayProp: "text",
                                    smartButtonMaxItems: 1,
                                    keyboardControls: true,
                                    showUncheckAll: false,
                                    showCheckAll: false,
                                    closeOnSelect: true,
                                    closeOnDeselect: true
                                  };

            /**
             * selectSettings - MultiFilter Select plugin static Filter settings.
             * @public
             * @memberOf angular_module.WB.cinehub.controllers.multiSelectFilterContainer
             * @type {Array}
             */
            scope.staticFilterSettings = { idProp: "value",
                                          displayProp: "text",
                                          keyboardControls: true,
                                          selectionLimit: 1,
                                          smartButtonMaxItems: 1,
                                          showUncheckAll: false,
                                          showCheckAll: false,
                                          closeOnSelect: true,
                                          closeOnDeselect: true
                                         };

             /**
             * selectSettings - MultiFilter Select plugin sort settings.
             * @public
             * @memberOf angular_module.WB.cinehub.controllers.multiSelectFilterContainer
             * @type {Array}
             */
            scope.sortSettings = { idProp: "value",
                                  displayProp: "text",
                                  keyboardControls: true,
                                  selectionLimit: 1,
                                  smartButtonMaxItems: 1,
                                  showUncheckAll: false,
                                  showCheckAll: false,
                                  closeOnSelect: true,
                                  closeOnDeselect: true
                                };


             /**
             * changeFilters - Event Handlers for Filter Option Changes.
             * @public
             * @memberOf angular_module.WB.cinehub.controllers.multiSelectFilterContainer
             * @type {Object}
             */
            scope.changeFilters = {
                onItemSelect: function(item) {
                    item.eventType = "select";
                    setSelectedFilterType(item).then(function(selectedItem) {
                        handleSelectEvent(selectedItem);
                    });
                },
                onItemDeselect: function(item) {
                    item.eventType = "deSelect";
                    setSelectedFilterType(item).then(function(deSelectedItem) {
                        handleDeSelectEvent(deSelectedItem);
                    });
                },
                onSelectAll: function () {
                    scope.clearAll();
                }
            };


            /**
             * changeStaticFilters - Event Handlers for Filter Sort Option Changes.
             * @public
             * @memberOf angular_module.WB.cinehub.controllers.multiSelectFilterContainer
             * @type {Object}
             */
            scope.changeStaticFilters = {
                onItemSelect: function(item) {
                    if (scope.filterSort[scope.filterSort.staticFilter] !== item.id) {
                        scope.filterSort[scope.filterSort.staticFilter] = item.id;
                        scope.enableAllFilters = true;
                        scope.fetchDataCallback()(true);
                    }
                }
            };
             /**
             * changeSortOptions - Event Handlers for Filter Sort Option Changes.
             * @public
             * @memberOf angular_module.WB.cinehub.controllers.multiSelectFilterContainer
             * @type {Object}
             */
            scope.changeSortOptions = {
                onItemSelect: function(item) {
                    if (scope.filterSort.sort !== item.id) {
                        scope.filterSort.sort = item.id;
                        scope.enableAllFilters = true;
                        scope.fetchDataCallback()(true);
                    }
                }
            };

             /**
             * Event Handler for CheckBox Option change
             * @function changeOption
             * @memberOf angular_module.WB.cinehub.directives.multiSelectFilterContainer
             * @public
             */
            scope.changeCheckBoxOption = function () {
                for (var val in scope.filterSort) {
                    if (scope.filterSort[val] === null) {
                        scope.filterSort[val] = "";
                    }
                }
                if(scope.data && scope.data.page) {
                    scope.data.page.number = 1;
                }
                if(scope.filterSort.isHOAView && !scope.filterSort.showAll) {
                    scope.filterSort.showAll = false;
                } else if(scope.filterSort.showAll && !scope.filterSort.isHOAView) {
                    scope.filterSort.isHOAView = false;
                }
                scope.fetchDataCallback()(scope.filterSort);
            };

             /**
             * Set Selected Filter Type invoked via event handlers.
             * @function getUnpermissionedMaterials
             * @public
             * @memberOf angular_module.WB.cinehub.directives.multiSelectFilterContainer
             */
            var setSelectedFilterType = function(item) {
                var selectedType = $q.defer();
                if(scope.data && scope.data.number) {
                    scope.data.number = 1;
                }
                scope.filters.some(function(filter) {
                    if (item.eventType === "select") {
                        if (!_.isEmpty(filter.model) && _.findIndex(filter.model, {id: item.id}) !== -1) {
                            if((item.id !== "ALL") || (item.id === "ALL" && filter.model.length > 1)) {
                                item.type = filter.type;
                            }
                        }
                    } else {
                        if (!_.isEmpty(filter.options) && _.findIndex(filter.options, {value: item.id}) !== -1) {
                            item.type = filter.type;
                        }
                    }

                });
                selectedType.resolve(item);
                return selectedType.promise;

            };

             /**
             * Filter Select Event Handler.
             * @public
             * @type function
             * @memberOf angular_module.WB.cinehub.directives.multiSelectFilterContainer
             */
            var handleSelectEvent = function (selectedFilterItem) {
                //self.unpermissionedMaterials.number = 1;
                if (selectedFilterItem.id !== "ALL") {
                    var selectedFilterType = _.find(scope.filters, {type: selectedFilterItem.type});
                    _.remove(selectedFilterType.model, {id: "ALL"});
                    scope.filterSort[selectedFilterType.id].push(selectedFilterItem.id);
                    if (!_.find(scope.filterCallStack, {type : selectedFilterItem.type})) {
                        scope.filterCallStack.push({type : selectedFilterItem.type});
                    }
                    var otherFilterTypes = _.filter(scope.filters, function(item) {
                        return item.type !== selectedFilterType.type;
                    });

                    otherFilterTypes.forEach(function (filterItem) {
                        if (!_.find(scope.filterCallStack, {type : filterItem.type})) {
                            filterItem.model = [];
                            scope.filterSort[filterItem.id] = [];
                        } else if (_.findIndex(scope.filterCallStack, {type: selectedFilterType.type}) <  _.findIndex(scope.filterCallStack, {type: filterItem.type})) {
                            var typeIndex = _.findIndex(scope.filterCallStack, {type : filterItem.type});
                            var selectedTypeIndex = _.findIndex(scope.filterCallStack, {type : selectedFilterType.type});
                            scope.filterCallStack.splice(typeIndex, 1, {type: filterItem.type, refresh : true, event : "select", primaryIndex:selectedTypeIndex === 0 ? 0 : selectedTypeIndex});
                        }
                    });
                    scope.enableAllFilters = true;
                    scope.fetchDataCallback()(true);
                } else {
                    scope.filterAllOptions(selectedFilterItem.type);
                }
            };

            /**
             * page header item for the page
             * @public
             * @type function
             * @memberOf angular_module.WB.cinehub.directives.multiSelectFilterContainer
             */
            var handleDeSelectEvent = function (selectedFilterItem) {
                //self.unpermissionedMaterials.number = 1;
                var selectedFilterType = _.find(scope.filters, {type: selectedFilterItem.type});
                var deSelectedIndex = scope.filterSort[selectedFilterType.id].indexOf(selectedFilterItem.id);
                if (deSelectedIndex > -1) {
                    scope.filterSort[selectedFilterType.id].splice(deSelectedIndex, 1);
                }
                if(_.isEmpty(selectedFilterType.model)) {
                    scope.filterSort[selectedFilterType.id] = [];
                    _.remove(scope.filterCallStack, {type : selectedFilterItem.type});
                }
                var selectedItemIndex = _.findIndex(scope.filterCallStack, {type : selectedFilterItem.type});
                var otherFilterTypes = _.filter(scope.filters, function(item) {
                    return item.type !== selectedFilterType.type;
                });
                otherFilterTypes.forEach(function (filterItem) {
                    if (!_.find(scope.filterCallStack, {type : filterItem.type})) {
                        filterItem.model = [];
                        scope.filterSort[filterItem.id] = [];
                    } else if (selectedItemIndex > -1 && selectedItemIndex <  _.findIndex(scope.filterCallStack, {type : filterItem.type})) {
                        var isSecondaryIndex = false;
                        var selectedFilters = _.filter(scope.allFilterValues, function(item) {
                            return _.find(filterItem.model, {id: item[filterItem.id]});
                        });

                        var filteredItems = _.filter(selectedFilters, function(item) {
                            return _.find(selectedFilterType.model, {id: item[selectedFilterType.id]});
                        });
                        var primaryItems = _.filter(scope.filterCallStack, function(item) {
                            return item.type !== selectedFilterItem.type && item.type !== filterItem.type;
                        });
                        primaryItems.forEach(function(primaryItem) {
                            if ( !isSecondaryIndex && _.findIndex(scope.filterCallStack, {type : primaryItem.type}) < selectedItemIndex) {
                                isSecondaryIndex = true;
                            }
                            var primaryItemFilter = _.find(scope.filters, {type: primaryItem.type});
                            selectedFilters = _.filter(selectedFilters, function(item) {
                                return _.find(primaryItemFilter.model, {id: item[primaryItemFilter.id]});
                            });
                        });
                        if (_.isEmpty(filteredItems)) {
                            filterItem.model = [];
                            scope.filterSort[filterItem.id] = [];
                            var remFilterTypes = _.filter(scope.filters, function(item) {
                                return item.type !== selectedFilterType.type && item.type !== filterItem.type;
                            });
                            remFilterTypes.forEach(function (remFilter) {
                                if (_.findIndex(scope.filterCallStack, {type : filterItem.type}) <  _.findIndex(scope.filterCallStack, {type : remFilter.type})) {
                                    remFilter.model = [];
                                    remFilter.data = [];
                                    _.remove(scope.filterCallStack, {type : remFilter.type});
                                }
                            });
                            _.remove(scope.filterCallStack, {type : filterItem.type});
                        } else {
                            var typeIndex = _.findIndex(scope.filterCallStack, {type : filterItem.type});
                            scope.filterCallStack.splice(typeIndex, 1, {type : filterItem.type, refresh : true, event : "deselect", primaryIndex:selectedItemIndex === 0 ? 0 : selectedItemIndex});
                        }
                    }
                });

                scope.enableAllFilters = !_.isEmpty(selectedFilterType.model);
                scope.fetchDataCallback()(!_.isEmpty(selectedFilterType.model));

            };

             /**
             * Reset All Filter Models
             * @function getUnpermissionedMaterials
             * @public
             * @memberOf angular_module.WB.cinehub.directives.multiSelectFilterContainer
             */
            scope.updateFilterModels = function () {
                scope.filters.forEach(function (filterType) {
                    if (_.isEmpty(scope.filterCallStack) || !_.find(scope.filterCallStack, {type : filterType.type})) {
                        filterType.options = filterType.data.options;
                        if (!_.find(scope.primaryFilterData, {type : filterType.type})) {
                            scope.primaryFilterData.push({type: filterType.type,options: filterType.options});
                        } else {
                            var primaryFilterIndex = _.findIndex(scope.primaryFilterData, {type : filterType.type});
                            scope.primaryFilterData.splice(primaryFilterIndex, 1, {type: filterType.type,options: filterType.options});
                        }
                    } else if ( _.find(scope.filterCallStack, {type : filterType.type, refresh : true})) {
                        var currentFilter = _.find(scope.filterCallStack, {type : filterType.type, refresh : true});
                        var primaryType = scope.filterCallStack[currentFilter.primaryIndex];
                        var primaryFilterItem = _.find(scope.filters, {type : primaryType.type});
                        var selectedFilters = _.filter(scope.allFilterValues, function(item) {
                            return _.find(primaryFilterItem.model, {id: item[primaryFilterItem.id]});
                        });
                        var otherPrimaryTypes = scope.filterCallStack.slice(0, _.findIndex(scope.filterCallStack, {type : primaryType.type}));
                        var otherPrimaryFilterItems = _.filter(scope.filters, function(item) {
                            return _.find(otherPrimaryTypes, {type: item.type});
                        });
                        if (currentFilter.event === "select" || currentFilter.event === "deselect") {
                            otherPrimaryFilterItems.forEach(function(primaryFilter) {
                                selectedFilters = _.filter(selectedFilters, function(item) {
                                    return _.find(primaryFilter.model, {id: item[primaryFilter.id]});
                                });

                            });
                           // var allModelsOption = _.find(filterType.options, {value: "ALL"});
                            var fileTypeOption = _.find(scope.primaryFilterData, {type : filterType.type});
                            var allModelsOption = _.find(fileTypeOption.options, {value: "ALL"});
                            filterType.options = _.uniqBy( _.map(selectedFilters, function(filterItem) {
                                var filterText = filterType.value ? filterType.value : filterType.id;
                                return {text : filterItem[filterText], value : filterItem[filterType.id]};
                            }), "value");
                            filterType.options.unshift(allModelsOption);
                        //Reset Model option which doesn't meet selection criteria
                            filterType.model = _.filter(filterType.model, function(item) {
                                return _.find(filterType.options, {value: item.id});
                            });
                            filterType.resetModelData = true;

                        }
                        var resetIndex = _.findIndex(scope.filterCallStack, {type : filterType.type});
                        scope.filterCallStack.splice(resetIndex, 1, {type : filterType.type});
                    } else if (_.isEmpty(filterType.options) &&  _.find(scope.filterCallStack, {type : filterType.type}) && _.find(scope.primaryFilterData, {type : filterType.type})) {
                        var primaryFilter = _.find(scope.primaryFilterData, {type : filterType.type});
                        filterType.options = primaryFilter.options;
                    }

                });
                if (_.isEmpty(scope.filterCallStack)) {
                    scope.filters.forEach(function(filter) {
                        var allFilterOption = _.find(filter.options, {value: "ALL"});
                        if(!_.find(filter.model, {id: "ALL"})) {
                            filter.model.unshift({id : allFilterOption.value,label : allFilterOption.text});
                        }
                    });
                }
                if (scope.enableAllFilters) {
                    updateFilterALLStatus();
                }

            };


             /**
             * Filter All Options based on other selected Items.
             * @function getUnpermissionedMaterials
             * @public
             * @memberOf angular_module.WB.cinehub.directives.multiSelectFilterContainer
             */
            scope.filterAllOptions = function (selectedEvent) {
                var selectedFilter = _.filter(scope.filters, {type: selectedEvent});
                if (scope.filterCallStack.length === 1) {
                    scope.clearAll(true);
                }
                if (scope.filterCallStack.length > 1  && selectedFilter) {
                    //selectedFilter[0].model = [];
                    //scope.filterSort[selectedFilter[0].id] = [];
                    selectedFilter.forEach(function (remFilter) {
                        remFilter.model = [];
                        scope.filterSort[remFilter.id] = [];
                    });
                    _.remove(scope.filterCallStack, {type : selectedEvent});
                    scope.enableAllFilters = true;
                    scope.fetchDataCallback()(true);
                }
            };


            /**
             * Init
             * @function init
             * @memberOf angular_module.WB.cinehub.directives.multiSelectFilterContainer
             * @private
             */
            var init = function () {
                var resetModels = false;
                if (!scope.filterSort) {
                    scope.filterSort = {};
                }
                var tabChangeItems = _.filter(scope.options, function (item) {
                    return item.tabChange && item.tabChange === true;
                });

                if(tabChangeItems && !_.isEmpty(tabChangeItems) &&  scope.filterSort[scope.filterSort.page]) {
                    scope.mapStoredOptions();
                    return;
                }

                if (isFilterReset()) {
                    scope.primaryFilterData = [];
                    scope.filters = _.filter(scope.options, function (item) {
                        return item.type !== "sort" && item.type !== "checkbox" && item.type !== "static";
                    });
                    scope.filters.forEach(function(filter) {
                        filter.model = [];
                        filter.options = [];
                        if (scope.filterSort && scope.filterSort.page) {
                            filter.page = scope.filterSort.page;
                        }
                    });
                    resetModels = true;
                } else if (!_.isEmpty(scope.filterCallStack)) {
                    var newFilterOptions = _.filter(scope.options, function (item) {
                        return item.type !== "sort" && item.type !== "checkbox" && item.type !== "static";
                    });
                    scope.filters.forEach(function(filter) {
                        var newFilter = _.find(newFilterOptions, {type : filter.type});
                        filter.data = newFilter.data;
                    });
                    resetModels = true;
                }
                scope.sort = _.head(_.filter(scope.options, function (item) {
                    return item.type === "sort";
                }));

                scope.staticFilters = _.head(_.filter(scope.options, function (item) {
                    return item.type === "static";
                }));

                scope.checkBoxOptions = _.filter(scope.options, function (item) {
                    return item.type === "checkbox";
                });

                if (_.isEmpty(scope.filterCallStack)) {
                    scope.allFilterValues = scope.allFilterData;
                }

                if (scope.sort && scope.sort.options && scope.sort.options[0]) {
                    if (!scope.filterSort["sort"]) {
                        scope.filterSort["sort"] = scope.sort.options[0].value;
                    }
                }
                if (scope.staticFilters && scope.staticFilters.options && scope.staticFilters.options[0]) {
                    if (!scope.filterSort.staticFilter && !scope.filterSort[scope.filterSort.staticFilter]) {
                        scope.filterSort[scope.filterSort.staticFilter] = scope.staticFilters.options[0].value;
                    }
                }
                if (resetModels) {
                    scope.updateFilterModels(scope.filters);
                    if (scope.filterSort.page) {
                        scope.filterSort[scope.filterSort.page] = {};
                        scope.filterSort[scope.filterSort.page].storedOptions = {filters: scope.filters,
                                                                                 filterCallStack: scope.filterCallStack,
                                                                                 primaryFilterData: scope.primaryFilterData,
                                                                                 allFilterValues: scope.allFilterValues,
                                                                                 sort:scope.sort,
                                                                                 staticFilters: scope.staticFilters,
                                                                                 checkBoxOptions: scope.checkBoxOptions};
                    }
                }
                localizeFilterOptions();
            };

             /**
             * Check if filters needs reset.
             * @function init
             * @memberOf angular_module.WB.cinehub.directives.multiSelectFilterContainer
             * @private
             */
            var isFilterReset = function () {
                if(scope.filterSort && scope.filterSort.page && !_.isEmpty(scope.filters)) {
                    if (!_.find(scope.filters, {page : scope.filterSort.page})) {
                        scope.resetFilterModels();
                    }
                }
                if ( (_.isEmpty(scope.filters) || _.isEmpty(scope.primaryFilterData)) || _.isEmpty(scope.filterCallStack)) {
                    return true;
                }
                return false;
            };


             /**
             * Localize filter options
             * @memberOf angular_module.WB.cinehub.directives.multiSelectFilterContainer
             * @private
             */
            var localizeFilterOptions = function () {
                var typeFilters = _.filter(scope.filters, function(item) {
                    return item.type === "assetTypes" || item.type === "subTypes";
                });
                typeFilters.forEach(function (filter) {
                    filter.options.forEach(function (option) {
                        if (option.value && option.value !== "ALL") {
                            option.text = convertLocalizationCodeToValueService.convert(
                                filter.type === "assetTypes" ? "assetType" : "assetSubType",
                                option.value
                            );
                        }
                    });
                });

            };

            /**
             * update filters ALL value
             * @public
             * @function updateFilterALLStatus
             * @memberOf angular_module.WB.cinehub.directives.multiSelectFilterContainer
             */
            var updateFilterALLStatus = function () {
                scope.filters.forEach(function (filter) {
                    filter.options.some(function (option) {
                        if (option.value && option.value === "ALL" &&  _.find(scope.filterCallStack, {type : filter.type})) {
                            option.disabled = false;
                        }
                    });
                });
            };

             /**
             * Reset Directive scope vars
             * @function changeOption
             * @memberOf angular_module.WB.cinehub.directives.multiSelectFilterContainer
             * @public
             *
             */
            scope.resetFilterModels = function () {
                scope.filterSort.retainStaticFilters = false;
                scope.filterCallStack = [];
                scope.primaryFilterData = [];
                scope.allFilterValues = [];
            };

            /**
             * Clear/Reset All Options
             * @function clearAll
             * @memberOf angular_module.WB.cinehub.directives.multiSelectFilterContainer
             * @public
             * @param retainStaticFilters Boolean
             */
            scope.clearAll= function (retainStaticFilters) {
                scope.resetFilterModels();
                if (retainStaticFilters) {
                    scope.filterSort.retainStaticFilters = retainStaticFilters;
                }
                scope.onClearAllCallback()(scope.filterSort);
            };

            scope.$watch("options", function (newVal) {
                if (newVal && !_.isEmpty(newVal) && scope.isValidReq(newVal)) {
                    init();
                }
            });

             /**
             * Map Filters based on Stored Options for Tab Switch.
             * @function mapStoredOptions
             * @memberOf angular_module.WB.cinehub.directives.multiSelectFilterContainer
             * @public
             */
            scope.mapStoredOptions= function () {
                scope.filters = scope.filterSort[scope.filterSort.page].storedOptions.filters;
                scope.filterCallStack = scope.filterSort[scope.filterSort.page].storedOptions.filterCallStack;
                scope.primaryFilterData = scope.filterSort[scope.filterSort.page].storedOptions.primaryFilterData;
                scope.allFilterValues = scope.filterSort[scope.filterSort.page].storedOptions.allFilterValues;
                scope.sort = scope.filterSort[scope.filterSort.page].storedOptions.sort;
                scope.checkBoxOptions = scope.filterSort[scope.filterSort.page].storedOptions.checkBoxOptions;
                scope.staticFilters = scope.filterSort[scope.filterSort.page].storedOptions.staticFilters;
            };

             /**
             * Validate if the request belongs to the same Page to prevent invalid directive event fire.
             * @function isValidReq
             * @memberOf angular_module.WB.cinehub.directives.multiSelectFilterContainer
             * @public
             */
            scope.isValidReq= function (newOptions) {
                var validReq = true;
                newOptions.some(function(option) {
                    if (option.page && scope.filterSort.page && option.page !== scope.filterSort.page) {
                        validReq = false;
                    }
                });
                if (!validReq) {
                    return validReq;
                }
                activeTabIndex++;
                if ( scope.filterSort.tabCount && activeTabIndex > scope.filterSort.tabCount-1) {
                    activeTabIndex = 0;
                }
                var newOption = _.findLast(newOptions, function(item){
                    return item.activeTabIndex && item.activeTabIndex >= 0;
                });

                if (newOption &&  newOption.activeTabIndex !== activeTabIndex) {
                    validReq = false;
                }

                return validReq;
            };



        };

        return {
            replace: true,
            link: link,
            scope: {
                options: "=",
                filterSort: "=",
                onClearAllCallback: "&",
                data: "=",
                allFilterData: "=",
                fetchDataCallback: "&"
            },
            templateUrl: "multiSelectFilterContainerTemplate.html"
        };
    }

    directives.directive("multiSelectFilterContainer", ["localStorageService", "convertLocalizationCodeToValueService","deviceDetectorService","$q", multiSelectFilterContainer]);
}());
