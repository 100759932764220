(function() {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class theatersService
     * @memberOf angular_module.WB.cinehub.services
     * @desc exhibitors service
     * @param {!angular.$http} $http angular $http service
     * @param {strict} API_ROOT API_ROOT constant
     * @param {service} localStorageService local storage service
     */
    function theatersService($http, API_ROOT, localStorageService) {

        /**
         * translations
         * @type {string}
         * @private
         * @memberOf angular_module.WB.cinehub.services.theatersService
         */
        var translations = localStorageService.get("translations"),

            /**
             * Error message for searchTheatersBySearchTerm
             * @type {string}
             * @private
             * @memberOf angular_module.WB.cinehub.services.theatersService
             */
            searchTheatersBySearchTermErrorMessage = translations.global.messages.cannotRetrieveListOfTheaters;

        /**
         * Get all theaters
         * @memberOf angular_module.WB.cinehub.services.exhibitorsService
         * @public
         * @function getAllTheaters
         * @returns {promise}
         * @param {int} size page size
         * @param {int} page page number
         * @param {string} sort sort
         */
        this.getAllTheaters = function(size, page, sort) {
            return $http.get(API_ROOT + "theaters?size=" + size + "&page=" + page + "&sort=" + sort + "&ignoreLocalizationString", {
                errorMessage: searchTheatersBySearchTermErrorMessage
            });
        };

        /**
         * Search theaters by search criteria
         * @memberOf angular_module.WB.cinehub.services.theatersService
         * @public
         * @function searchTheatersBySearchTerm
         * @returns {promise}
         * @param {string} term search term
         */
        this.searchTheatersBySearchTerm = function(term) {
            return $http.get(API_ROOT + "theaters/search/findByNameLike?name=" + term, {
                errorMessage: searchTheatersBySearchTermErrorMessage,
                showSpinner: false
            }).then(function(result) {
                return result.data._embedded.theaters;
            });
        };

        /**
         * Search theaters by search criteria and territory id
         * @memberOf angular_module.WB.cinehub.services.theatersService
         * @public
         * @function findByTerritoryIdAndByNameLike
         * @returns {promise}
         * @param {string} term search term
         * @param {int} territoryId territory id
         */
        this.findByTerritoryIdAndByNameLike = function(territoryId, term) {
            return $http.get(API_ROOT + "theaters/search/findByTerritoryIdAndByNameLike?territoryId=" + territoryId + "&name=" + term + "&ignoreLocalizationString", {
                errorMessage: searchTheatersBySearchTermErrorMessage,
                showSpinner: false
            }).then(function(result) {
                return result.data._embedded.theaters;
            });
        };

        /**
         * Search theaters by territory id
         * @memberOf angular_module.WB.cinehub.services.theatersService
         * @public
         * @function findByTerritoryIdAndByNameLike
         * @returns {promise}
         * @param {string} term search term
         * @param {int} territoryId territory id
         * @param {int} size page size
         * @param {int} page page number
         * @param {string} sort sort string
         */
        this.findByTerritoryId = function(territoryId, size, page, sort) {
            return $http.get(API_ROOT + "theaters/search/findByTerritoryIdAndByNameLike?territoryId=" + territoryId + "&name=" + "&page=" + page + "&size=" + size + "&sort=" + sort + "&ignoreLocalizationString", {
                errorMessage: searchTheatersBySearchTermErrorMessage
            });
        };

        /**
         * Find theaters by name like or local name like and by city like or by state like and territory id
         * @memberOf angular_module.WB.cinehub.services.theatersService
         * @public
         * @function findByNameLikeOrByLocalNameLikeAndByCityLikeOrByStateLikeAndByTerritoryId
         * @returns {promise}
         * @param {string} name name
         * @param {string} localName local name
         * @param {string} city city
         * @param {string} state state
         * @param {string} territoryName territory name
         * @param {int} territoryId territory id
         * @param {int} page page
         * @param {int} size page size
         * @param {string} sort sort string
         */
        this.findByNameLikeOrLocalNameLikeAndCityLikeOrStateLikeOrTerritoryNameLikeAndTerritoryId = function(territoryId, size, page, sort, name, localName, city, state, territoryName) {
            return $http.get(API_ROOT + "theaters/search/findByNameLikeOrLocalNameLikeAndCityLikeOrStateLikeOrTerritoryNameLikeAndTerritoryId?name=" + name + "&localName=" + localName + "&city=" + city + "&state=" + state + "&territoryName=" + territoryName + "&territoryId=" + territoryId + "&size=" + size + "&page=" + page + "&sort=" + sort + "&ignoreLocalizationString", {
                errorMessage: searchTheatersBySearchTermErrorMessage
            });
        };

        /**
         * Search theaters by exhibitor id
         * @memberOf angular_module.WB.cinehub.services.theatersService
         * @public
         * @function findByExhibitorId
         * @returns {promise}
         * @param {int} exhibitorId exhibitor id
         * @param {int} size page size
         * @param {int} page page number
         * @param {string} sort sort string
         */
        this.findByExhibitorId = function(exhibitorId, page, size, sort) {
            return $http.get(API_ROOT + "theaters/search/findByExhibitorId?exhibitorId=" + exhibitorId + "&page=" + page + "&size=" + size + "&sort=" + sort + "&ignoreLocalizationString", {
                errorMessage: searchTheatersBySearchTermErrorMessage
            });
        };

        /**
         * Find theater by id
         * @param {int} id theater id
         * @function findById
         * @returns {promise}
         * @memberOf angular_module.WB.cinehub.services.theatersService
         * @public
         */
        this.findById = function(id) {
            return $http.get(API_ROOT + "theaters/search/findById?id=" + id + "&ignoreLocalizationString", {
                errorMessage: searchTheatersBySearchTermErrorMessage
            });
        };

        /**
         * Update theaters
         * @param {object[]} theaters theaters 
         * @function updateTheaters
         * @returns {promise}
         * @memberOf angular_module.WB.cinehub.services.theatersService
         * @public
         */
        this.updateTheaters = function(theaters) {
            return $http.post(API_ROOT + "theaters/", theaters, {
                errorMessage: translations.global.messages.updateTheatersError
            });
        };

        /**
         * Get theater screens
         * @param {int} theaterId theater id  
         * @function getTheaterScreens
         * @returns {promise}
         * @memberOf angular_module.WB.cinehub.services.theatersService
         * @public
         */
        this.getTheaterScreens = function(theaterId) {
            return $http.get(API_ROOT + "theaters/" + theaterId + "/theaterScreens?" + "&ignoreLocalizationString", {
                errorMessage: translations.global.messages.searchTheaterScreensError
            });
        };

        /**
         * search for theaters by exhibitor id and name like
         * @memberOf angular_module.WB.cinehub.services.theatersService
         * @public
         * @param {int} exhibitorId exhibitor id
         * @param {string} name theater name
         * @returns {promise}
         */
        this.findByExhibitorIdAndName = function (exhibitorId, name) {
            return $http.get(API_ROOT + "theaters/search/findByExhibitorIdAndNameLikeAndCityLikeAndStateLike?exhibitorId=" + exhibitorId + "&name=" + name + "&city=&state=&page=0&size=20&sort=name&ignoreLocalizationString", {
                errorMessage: searchTheatersBySearchTermErrorMessage,
                showSpinner: false
            }).then(function(result) {
                return result.data._embedded.theaterViews;
            });
        };
    }

    services.service("theatersService", ["$http", "API_ROOT", "localStorageService", theatersService]);
}());
