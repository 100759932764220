(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class toDoService
     * @memberOf angular_module.WB.cinehub.services
     * @desc faq service
     * @param {!angular.$http} $http angular $http service
     * @param {constant} API_ROOT API_ROOT constant
     * @param {service} localStorageService local storage service
     * @param {factory} localStorageService promise factory
     */
    function toDoService($http, API_ROOT, localStorageService, promiseFactory) {

        /**
         * Instance of toDoService
         * @type {toDoService}
         * @public
         * @memberOf angular_module.WB.cinehub.services.toDoService
         */
        var self = this,

            /**
             * translations
             * @memberOf angular_module.WB.cinehub.services.toDoService
             * @private
             * @type {object}
             */
            translations = localStorageService.get("translations"),

            /**
             * Location
             * @type {object}
             * @memberOf angular_module.WB.cinehub.services.toDoService
             * @private
             */
            location = localStorageService.get("location");

        /**
         * User info
         * @type {object}
         * @memberOf angular_module.WB.cinehub.services.toDoService
         * @public
         * made this public for unit test
         */
        this.user = localStorageService.get("userInfo");

        /**
         * recalculate all count flag
         * @type {boolean}
         * @memberOf angular_module.WB.cinehub.services.toDoService
         * @public
         */
        this.recalculateAllCount = true;

        /**
         * Get unpublished title info
         * @memberOf angular_module.WB.cinehub.services.toDoService
         * @public
         * @function getUnpublishedTitleInfo
         * @param {int} past days
         * @param {int} size size
         * @param {int} page page
         * @param {string} sort sort order
         * @param {string} secondarySort secondary sort
         * @returns {promise}
         */
        this.getUnpublishedTitleInfo = function (pastDays, size, page, sort, secondarySort) {
            return $http.get(API_ROOT + "films/search/findLocAllUnpublishedByLastModified?daysLastModified=" + pastDays + "&page=" + page + "&size=" + size + "&sort=" + sort + "&sort=" + secondarySort, {
                errorMessage: translations.toDo.messages.cannotGetUnpublishedTitleInfo
            });
        };


        /**
         * Get filter values
         * @memberOf angular_module.WB.cinehub.services.toDoService
         * @public
         * @function getFiltersValuesForUnpublishedMaterials
         * @param {int} filmId film id
         * @param {string} wbAssetType wb asset type
         * @param {string} wbpiAssetType wbpi asset type
         * @param {string} fileType file type
         * @returns {promise}
         */
        this.getFiltersValuesForUnpublishedMaterials = function (filmId, wbAssetType, wbpiAssetType, fileType) {
            return $http.post(API_ROOT + "filmAssets/findLocFilmsAndAssetTypeAndSubtypeForPublishedAssetsWithMarsUpdates" , {
                "filmId": filmId,
                "wbAssetType": wbAssetType,
                "wbpiAssetType": wbpiAssetType,
                "fileType": fileType,
                "territoryId": location.territoryId,
                "localeId": location.localeId
            }, {
                errorMessage: translations.toDo.messages.cannotGetFilterValues,
                showSpinner: true
            });
        };

        /**
         * Get unpermissined materials
         * @memberOf angular_module.WB.cinehub.services.toDoService
         * @public
         * @function getUnpermissionedMaterials
         * @param {int} filmId film id
         * @param {string} wbAssetType wb asset type
         * @param {string} wbpiAssetType wbpi asset type
         * @param {string} fileType file type
         * @param {int} size page size
         * @param {int} page page number
         * @param {string} sort sort
         * @returns {promise}
         */
        this.getUnpermissionedMaterials = function (filmId, wbAssetType, wbpiAssetType, fileType, size, page, sort) {
            var restApiCall = self.user.role === "homeOfficeAdmin" ? "findUnpermissionedAssetsForHoa" : "findLocAllUnpermissionedByFilmIdAndWbAssetTypeAndWbpiAssetType";
            return $http.post(API_ROOT + "filmAssets/search/" + restApiCall + "?size=" + size + "&page=" + page + "&sort=" + sort, {
                "filmId": filmId,
                "wbAssetType": wbAssetType,
                "wbpiAssetType": wbpiAssetType,
                "fileType": fileType,
                "territoryId": location.territoryId,
                "localeId": location.localeId 
            }, {
                errorMessage: translations.toDo.messages.cannotGetUnpermissionedMaterials
            });
        };


        /**
         * Get filter values for unpermissined material
         * @memberOf angular_module.WB.cinehub.services.toDoService
         * @public
         * @function getFiltersValuesForUnpermissionedMaterials
         * @param {int} filmId film id
         * @param {string} wbAssetType wb asset type
         * @param {string} wbpiAssetType wbpi asset type
         * @param {string} fileType file type
         * @returns {promise}
         */
        this.getFiltersValuesForUnpermissionedMaterials = function (filmId, wbAssetType, wbpiAssetType, fileType) {
            var restApiCall = self.user.role === "homeOfficeAdmin" ? "findLocFilmsAndAssetTypeAndSubtypeForUnpermissionedAssetsHoa" : "findLocFilmsAndAssetTypeAndSubtypeForUnpermissionedAssets";
            return $http.post(API_ROOT + "filmAssets/" + restApiCall, {
                "filmId": filmId,
                "wbAssetType": wbAssetType,
                "wbpiAssetType": wbpiAssetType,
                "fileType": fileType,
                "territoryId": + location.territoryId,
                "localeId": location.localeId
            }, {
                errorMessage: translations.toDo.messages.cannotGetFilterValues,
                showSpinner: true
            });
        };

        /**
         * Get todo counts for HOA
         * @memberOf angular_module.WB.cinehub.services.toDoService
         * @public
         * @function getTodoCountsHOA
         * @returns {promise}
         */
        this.getTodoCountsHOA = function () {
            return $http.get(API_ROOT + "todo/getTodoCountsHOA?daysLastModified=0&territoryId=" + location.territoryId + "&localeId=" + location.localeId + "&ignoreLocalizationString", {
                errorMessage: translations.toDo.messages.cannotGetTabCounts,
                showSpinner: false
            });
        };

        /**
         * Get todo counts for territory admin user
         * @memberOf angular_module.WB.cinehub.services.toDoService
         * @public
         * @function getTodoCountsTA
         * @returns {promise}
         * @param {int} daysLastModified days last modified
         */
        this.getTodoCountsTA = function (daysLastModified) {
            return $http.get(API_ROOT + "todo/getTodoCountsTA?daysLastModified=" + daysLastModified + "&territoryId=" + location.territoryId + "&localeId=" + location.localeId + "&ignoreLocalizationString", {
                errorMessage: translations.toDo.messages.cannotGetTabCounts,
                showSpinner: false
            });
        };

        /**
         * Recalculate todo counts
         * @function recalculateTodoCounts
         * @public
         * @memberOf angular_module.WB.cinehub.services.toDoService
         * @param {string} todoProperty todo property pendingUsersCount|unpublishedTitleInfoCount|unpublishedMaterialUpdatesCount|unpermissionedMaterialsCount
         * @param {int} count count of the items
         */
        this.recalculateTodoCounts = function (todoProperty, count) {
            var allCount = 0,
                key = "";
            self.todoTabCounts[todoProperty] = count;
            if (self.recalculateAllCount) {
                for (key in self.todoTabCounts) {
                    if(key !== "allCount") {
                        allCount += self.todoTabCounts[key];
                    }
                }
                self.todoTabCounts.allCount = allCount;
            }
            localStorageService.set("todoTabCounts", self.todoTabCounts);
        };

        /**
         * Get todo tab counts
         * @memberOf angular_module.WB.cinehub.services.toDoService
         * @public
         * @function getTodoTabCounts
         * @returns {promise}
         * @param {string} role role
         * @param {boolean} refresh flag indicates weather we want to update stored todo counts value
         */
        this.getTodoTabCounts = function (role, refresh) {
            var deferred = promiseFactory.defer(),
                todoTabCounts = localStorageService.get("todoTabCounts"),
                results = {
                    "data": {
                        "data": {}
                    }
                };

            if (!refresh && todoTabCounts) {
                results.data.data = todoTabCounts;
                deferred.resolve(results);
                return deferred.promise;
            }

            if ((refresh || !todoTabCounts) && role === "territoryAdmin") {
                return self.getTodoCountsTA(0);
            }
            if ((refresh || !todoTabCounts) && role === "homeOfficeAdmin") {
                return self.getTodoCountsHOA();
            }

            //if standard user just resolve with empty results
            deferred.resolve(results);
            return deferred.promise;
        };

        /**
         * Set todo tab counts
         * @memberOf angular_module.WB.cinehub.services.toDoService
         * @public
         * @function getTodoTabCounts
         * @returns {promise}
         * @param {string} role user role
         * @param {boolean} refresh flag indicates weather we want to update stored todo counts value
         */
        this.setTodoTabCounts = function (role, refresh) {
            return this.getTodoTabCounts(role, refresh).then(function (result) {
                self.todoTabCounts = self.todoTabCounts || {};
                angular.merge(self.todoTabCounts, result.data.data);
                localStorageService.set("todoTabCounts", self.todoTabCounts);
            });
        };
    }

    services.service("toDoService", ["$http", "API_ROOT", "localStorageService", "promiseFactory", toDoService]);
}());
