(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Permission cart controller
     * @dec permission cart controller
     * @class permissionCartController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.$rootScope} $rootScope angular root scope
     * @param {!angular.Scope} $scope angular scope
     * @param {$window} $window angular window instance
     * @param {service} localStorageService local storage service
     * @param {service} permissionQueueService permission queue service
     * @param {service} exhibitorsService exhibitors service
     * @param {$uibModal} $uibModal modal
     * @param {service} permissionService permission service
     * @param {service} deviceDetectorService device detector service
     */
    function permissionCartController($rootScope, $scope, $window, localStorageService, permissionQueueService, exhibitorsService, $uibModal, permissionService, deviceDetectorService,PERMISSIONS_QUE_DISPLAY_SIZE,PERMISSIONS_QUE_DISPLAY_SIZE_HOA,$filter) {
        /**
         * Instance of permission cart controller
         * @type {permissionCartController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         */
        var self = this,

            /**
             * Location information 
             * @type {object}
             * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
             * @private
             */
            location = localStorageService.get("location"),
            orderBy = $filter("orderBy");

        /**
         * User info
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         */
        self.user = localStorageService.get("userInfo");

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         */
        self.pageHeaderItem = {
            pageTitle: "",
            breadcrumbItems: [{
                linkTitle: "",
                link: "/permission/cart"
            }]
        };

        /**
         * translations
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         */
        self.translations = localStorageService.get("translations");

        /**
         * impersonate user flag
         * @type {boolean}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");

        /**
         * Permission Queue Items
         * @public
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         */
        self.permissionQueueItems = [];

        /**
         * regions
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         * @type {object[]}
         */
        self.regions = permissionService.buildRegionPermissionsJson();

        /**
         * enable review button
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         * @type {boolean}
         */
        self.enableReviewButton = false;

        /**
         * Device detector service
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         * @private
         * @type {service}
         */
        self.deviceDetectorService = deviceDetectorService;

        /**
         * Parent items to pass into permission table directive as an outside-data property
         * @public
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         */
        self.parentData = [];
        self.groupSelected = false;
        self.limitReached = false;
        self.selectedAssets = [];

        /**
         * bulk update flag set for select group permission action
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         * @type {boolean}
         */
        self.isGroupUpdate = false;

         /**
         * asset selected to use as template
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         * @type {object}
         */
        self.templateAsset = {};


        /**
         * Process permission queue items
         * @function processPermissionQueueItems
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         * function has public self. for unit test but it is private in controller scope
         */
        self.processPermissionQueueItems = function (permissionQueueItems) {
            if (permissionQueueItems && permissionQueueItems.length) {
                self.permissionQueueItems = _.map(permissionQueueItems, function (obj) {
                    return _.extend({}, obj, {
                        isTemplate: false,
                        isSelected: (parseInt(window.WB.cinehub.preload.assetId, 10) > 0 && obj.assetId === window.WB.cinehub.preload.assetId) ? true : false
                    });
                });
                self.pageHeaderItem.pageTitle = self.translations.permissionCart.labels.pageTitle + " (" + self.permissionQueueItems.length + ")";
                if (window.WB.cinehub.preload.assetId) {
                    self.onSelect(_.find(permissionQueueItems, {
                        assetId: window.WB.cinehub.preload.assetId
                    }));
                }
            }
        };

        /**
         * Get permission queue assets for hoa
         * @function getPermissionQueueAssetsForHOA
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         */
        self.getPermissionQueueAssetsForHOA = function () {
            permissionQueueService.getPermissionQueueAssetsForHOA(self.user.userId).success(function (result) {
                self.permissionQueueItems = result._embedded.permissionItems;
                self.processPermissionQueueItems(result._embedded.permissionItems);
            });
        };

        /**
         * Get permission queue assets
         * @function getPermissionQueueAssets
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         */
        self.getPermissionQueueAssets = function () {
            permissionQueueService.getPermissionQueueAssets(self.user.userId).success(function (result) {
                self.permissionQueueItems = result._embedded.permissionItems;
                self.processPermissionQueueItems(result._embedded.permissionItems);
            });
        };

        /**
         * find exhibitors by territory id
         * @function findExhibitorsByTerritoryId
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         */
        self.findExhibitorsByTerritoryId = function () {
            return exhibitorsService.findByTerritoryId(location.territoryId).success(function (result) {
                self.parentData = permissionService.buildExhibitorPermissionsJson(result.data.exhibitors);
            });
        };

        /**
         * get permission queue items for review modal
         * @function getPermissionQueueItemsForReviewModal
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         * @returns {object[]}
         */
        self.getPermissionQueueItemsForReviewModal = function () {
            var permissionQueueItems = _.cloneDeep(self.selectedAssets);
            permissionQueueItems.forEach(function (item) {
                item.isSelected = false;
            });
            return permissionQueueItems;
        };

        /**
         * Get permissions for review modal
         * @function getPermissionsForReviewModal
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         * @returns {object[]}
         */
        self.getPermissionsForReviewModal = function () {
            var permissions = _.cloneDeep(self.parentData);
            permissions.forEach(function (item) {
                item.permissions = item.permissions.filter(function (permissionItem) {
                    return permissionItem.changed && permissionItem.permission;
                });
                item.permissions = _.groupBy(item.permissions, "permission");
                item.open = false;
            });
            permissions = permissions.filter(function (item) {
                return item.changed && !_.isEmpty(item.permissions);
            });
            return permissions;
        };

        /**
         * Get raw permission data
         * @function getRawPermissionData
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         * @returns {object[]}
         */
        self.getRawPermissionData = function () {
            var rawPermissionData = [],
                parentDataCopy = _.cloneDeep(self.parentData);
            parentDataCopy.forEach(function (data) {
                if (data.permissions && data.permissions.length > 0) {
                    data.permissions = data.permissions.filter(function (permissionItem) {
                        return permissionItem.permission;
                    });
                    rawPermissionData = _.concat(rawPermissionData, data.permissions);

                }
            });
            return rawPermissionData;
        };

        /**
         * update todo cache count 
         * @function updateToDoCacheCount
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         * @private
         * @param {int} permissionedAssets the number of assets got permissioned 
         */
        var updateToDoCacheCount = function (permissionedAssets) {
            var todoTabCounts = localStorageService.get("todoTabCounts");
            todoTabCounts.allCount -= permissionedAssets;
            localStorageService.set("todoTabCounts", todoTabCounts);
            $scope.$parent.$parent.pageCtrl.todoTabCounts = todoTabCounts;
        };

        /**
         * Use as tempalte
         * @function useAsTemplate
         * @param {object} asset asset
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         */
        self.useAsTemplate = function (asset) {
            self.templateAsset = asset;
            self.permissionQueueItems.forEach(function (item) {
                if (asset.isTemplate && item.assetId !== asset.assetId) {
                    item.isTemplate = false;
                    item.isTemplateDisabled = true;
                } else {
                    item.isTemplateDisabled = false;
                }
            });
            $scope.$broadcast("useAsTemplate");
        };

        /**
         * Toggle select all state
         * @function toggleSelectAll
         * @param {boolean} isSelected is selected flag
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         */
        self.toggleSelectAll = function (isSelected) {
            var index = 0,
                size = self.permissionQueSize;
            if(self.user.role === "homeOfficeAdmin"){
                self.permissionQueueItems = orderBy(self.permissionQueueItems, ["-hasTerritoryPermission","-id"]);
            } else {
                self.permissionQueueItems = orderBy(self.permissionQueueItems, ["-hasExhibitorPermission","-id"]);
            }
            if(self.viewAs) {
                isSelected = false;       	
            }
            if(isSelected && self.permissionQueueItems.length > size){
                self.resetPermissions();
                for (index; index < size; index++) {
                    self.permissionQueueItems[index].isSelected = isSelected;
                    if (!isSelected) {
                        self.permissionQueueItems[index].isTemplate = false;
                    }

                } 
            }
            else{
                self.permissionQueueItems.forEach(function (item) {
                    item.isSelected = isSelected;
                    if (!isSelected) {
                        item.isTemplate = false;
                    }
                });
            }
            if (isSelected) {
                $scope.$emit("groupSelect", true);
                self.onSelect();
            } else {
                self.selectedAssets = [];
                self.groupSelected = false;
                self.limitReached = false;
            }
        };

        /**
         * Remove from permission queue
         * @function removeFromPermissionQueue
         * @param {object} asset asset
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         */
        self.removeFromPermissionQueue = function (asset) {
            permissionQueueService.removeFromQueue(asset).success(function () {
                var index = _.findIndex(self.permissionQueueItems, {
                        id: asset.id
                    }),
                    selectedAssetIndex = _.findIndex(self.selectedAssets, {
                        id: asset.id
                    });

                if (selectedAssetIndex !== -1) {
                    self.selectedAssets.splice(index, 1);
                }
                self.permissionQueueItems.splice(index, 1);
                self.permissionQueueItems.forEach(function (item) {
                    item.isTemplateDisabled = false;
                });
                self.pageHeaderItem.pageTitle = self.translations.permissionCart.labels.pageTitle + " (" + self.permissionQueueItems.length + ")";

                if (self.permissionQueueItems.length === 0) {
                    self.selectedAssets = [];
                }
                $rootScope.$emit("updatePermissionQueueCount", true);
            });
        };

        /**
         * Bulk remove from permission queue
         * @function bulkRemoveFromPermissionQueue
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         * @public
         * @returns {promise}
         * @param {object[]} assets selected assets
         */
        self.bulkRemoveFromPermissionQueue = function (assets) {
            var selectedAssets = assets || _.filter(self.permissionQueueItems, {
                isSelected: true
            });
            if (selectedAssets && selectedAssets.length > 0) {
                return permissionQueueService.bulkRemoveFromQueue(selectedAssets).success(function () {
                    self.permissionQueueItems = _.filter(self.permissionQueueItems, {
                        isSelected: false
                    });

                    var index = 0,
                        selectedAssetsLength = selectedAssets.length;
                    for (index; index < selectedAssetsLength; index++) {
                        if (selectedAssets[index].isTemplate) {
                            self.permissionQueueItems.forEach(function (item) {
                                item.isTemplateDisabled = false;
                            });
                            break;
                        }
                    }

                    self.pageHeaderItem.pageTitle = self.translations.permissionCart.labels.pageTitle + " (" + self.permissionQueueItems.length + ")";

                    self.selectedAssets = [];
                    $rootScope.$emit("updatePermissionQueueCount", true);
                });
            }
        };

        /**
         * On select
         * @function onSelect
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         */
        self.onSelect = function () {
            self.limitReached = false;
            if(self.selectedAssets && self.selectedAssets.length < self.permissionQueSize){
                self.selectedAssets = _.filter(self.permissionQueueItems, {
                    isSelected: true
                }); 
            } else {
                self.selectedAssets = _.filter(self.permissionQueueItems, {
                    isSelected: true
                });
            }
            if(self.selectedAssets && self.selectedAssets.length == self.permissionQueSize){
                self.limitReached = true;
            }
        };

        /**
         * Reset permissions
         * @function resetPermissions
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         */
        self.resetPermissions = function () {
            self.selectedAssets = [];
            self.permissionQueueItems.forEach(function (permissionItem) {
                permissionItem.isSelected = false;
            });
            $scope.$broadcast("resetPermissions");
            self.groupSelected = false;
            self.isGroupUpdate = false;
            self.templateAssetId = {};
        };

        /**
         * Review permissions
         * @function reviewPermissions
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         */
        self.reviewPermissions = function () {
            $uibModal.open({
                templateUrl: "/modules/reviewPermissionsModal/modalReviewPermissions.html",
                controller: "modalReviewPermissionsController",
                controllerAs: "modalReviewPermissionsCtrl",
                keyboard: false,
                backdrop: "static",
                size: "lg",
                resolve: {
                    permissionQueueItems: self.getPermissionQueueItemsForReviewModal,
                    permissions: self.getPermissionsForReviewModal,
                    rawPermissionData: self.getRawPermissionData,
                    groupSelect: self.groupSelected,
                    isGroupUpdate: self.isGroupUpdate,
                    templateAsset: self.templateAsset
                }
            }).result.then(function () {
                var selectedAssets = _.filter(self.permissionQueueItems, {
                    isSelected: true
                });
                self.bulkRemoveFromPermissionQueue(selectedAssets).then(function () {
                    updateToDoCacheCount(selectedAssets.length);
                });
                self.selectedAssets = [];
                self.limitReached = false;
                self.isGroupUpdate = false;
                self.templateAssetId = {};

            });
        };

        /**
         * Open lightbox
         * @function openLightBox
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         * @param {object} $event browser event
         * @param {object} asset asset
         * @param {string} action action
         */
        self.openLightBox = function ($event, asset, action) {
            var lightboxAsset = _.clone(asset);
            lightboxAsset.id = asset.assetId;

            self.openLightbox = true;
            self.lightboxAsset = lightboxAsset;
            self.lightboxAction = action;
        };

        /**
         * init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         */
        self.init = function () {
            if(!self.deviceDetectorService.isMobileOrTablet()) {
                self.pageHeaderItem.breadcrumbItems[0].linkTitle = self.translations.permissionCart.labels.breadcrumbLinkTitle;
                self.childIdProperty = self.user.role === "homeOfficeAdmin" ? "territoryId" : "theaterId";
                self.itemNameProperty = self.user.role === "homeOfficeAdmin" ? "value" : "localName";

                if (self.user.role === "homeOfficeAdmin") {
                    self.permissionQueSize= PERMISSIONS_QUE_DISPLAY_SIZE_HOA;
                    self.parentData = self.regions;
                    self.getPermissionQueueAssetsForHOA();
                } else {
                    self.permissionQueSize= PERMISSIONS_QUE_DISPLAY_SIZE;
                    self.findExhibitorsByTerritoryId();
                    self.getPermissionQueueAssets();
                }
            } else {
                $window.location.href = "/error/404";
            }
        };

        /**
         * permissionChanged event listener
         * @eventListener permissionChanged
         * @public
         * @param {object} event javascript event
         * @param {bool} permissionChanged permission changed
         * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
         */
        $scope.$on("permissionChanged", function (event, permissionChanged) {
            self.enableReviewButton = permissionChanged;
        });


        $scope.$on("groupSelect", function () {
            self.groupSelected = true;
        });

        /**
        * Group/Bulk Permission Update eventListener
        * public
        * @param {object} event javascript event
        * @param {bool} isBulkAction
        * @memberOf angular_module.WB.cinehub.controllers.permissionCartController
        */
        $scope.$on("groupUpdate", function (event, isBulkAction) {
            self.isGroupUpdate = isBulkAction;
        });
        //INIT
        self.init();
    }

    controllers.controller("permissionCartController", ["$rootScope", "$scope", "$window", "localStorageService", "permissionQueueService", "exhibitorsService", "$uibModal", "permissionService", "deviceDetectorService", "PERMISSIONS_QUE_DISPLAY_SIZE","PERMISSIONS_QUE_DISPLAY_SIZE_HOA","$filter", permissionCartController]);
}());
