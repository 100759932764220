(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Health check controller
     * @dec The health check controller
     * @class healthCheckController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {service} healthService health check service
     */
    function healthCheckController(healthCheckService) {

        /**
         * Instance of healthCheckController
         * @type {healthCheckController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.healthCheckController
         */
        var self = this;

        /**
         * Health info 
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.healthCheckController
         */
        self.healthInfo = {};

        /**
         * Get health info 
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.healthCheckController
         * @function getHealthInfo 
         */
        var getHealthInfo = function () {
            healthCheckService.getHealthInfo().success(function (result) {
                self.healthInfo = result;
            }).error(function(result, status) {
                if (status == 503) {
                    self.healthInfo = result;
                }
            });
        };

        /**
         * Init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.healthCheckController
         */
        self.init = function () {
            getHealthInfo();
        };

        //INIT
        self.init();
    }

    controllers.controller("healthCheckController", ["healthCheckService", healthCheckController]);
}());
