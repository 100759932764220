(function () {
    "use strict";
    var directives = angular.module("WB.cinehub.directives");

    /**
     * pageHeader directive
     * @class pageHeader
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     */
    function pageHeader(localStorageService) {
        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @private
         * @memberOf angular_module.WB.cinehub.directives.pageHeader 
         */
        var link = function (scope) {

            /**
             * translations
             * @type {boolean}
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @public
             */
            scope.translations = localStorageService.get("translations");
        };

        return {
            replace: true,
            link: link,
            scope: {
                pageHeaderItem: "="
            },
            templateUrl: "pageHeaderTemplate.html"
        };
    }

    directives.directive("pageHeader", ["localStorageService", pageHeader]);
}());
