(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class registrationService
     * @desc registration service
     * @memberOf angular_module.WB.cinehub.services
     * @param {!angular.$http} $http angular $http service
     * @param {string} API_ROOT API_ROOT constant
     * @param {service} localStorageService local storage service
     */
    function registrationService($http, API_ROOT, localStorageService) {

        /**
         * Translations 
         * @type {object}
         * @private
         * @memberOf angular_module.WB.cinehub.services.registrationService
         */
        var translations = localStorageService.get("translations");

        /**
         * get territory info
         * @function getTerritoryInfo
         * @memberOf angular_module.WB.cinehub.services.registrationService
         * @public
         * @param {int} territoryId territory id
         * @param {str} errorMessage error message
         * @returns {promise}
         */
        this.getTerritoryInfo = function (territoryId, errorMessage) {
            return $http.get(API_ROOT + "territories/search/findById?id=" + territoryId, {
                errorMessage: errorMessage,
                alertKey: "anonymouseUserAlert"
            });
        };

        /**
         * get exhibitors
         * @function getExhibitors
         * @memberOf angular_module.WB.cinehub.services.registrationService
         * @public
         * @param {str} keyword keyword
         * @param {int} territoryId territory id
         * @returns {promise}
         */
        this.getExhibitors = function (keyword, territoryId) {
            return $http.get(API_ROOT + "exhibitors/search/findByTerritoryIdAndByNameLike?name=" + keyword + "&territoryId=" + territoryId + "&ignoreLocalizationString", {
                errorMessage: translations.registration.messages.getExhibitorsError,
                alertKey: "anonymouseUserAlert",
                showSpinner: true
            }).then(function (result) {
                return result.data._embedded.exhibitors;
            });
        };

        /**
         * create user
         * @function createUser
         * @memberOf angular_module.WB.cinehub.services.registrationService
         * @public
         * @param {obj} user user object
         * @returns {promise}
         */
        this.createUser = function (user) {
            return $http.post(API_ROOT + "users/createProfile", user, {
                errorMessage: translations.registration.messages.createUserError,
                alertKey: "anonymouseUserAlert"
            });
        };

        /**
         * create user
         * @function createUser
         * @memberOf angular_module.WB.cinehub.services.registrationService
         * @public
         * @param {obj} user user object
         * @returns {promise}
         */
        this.addUser = function (user) {
            return $http.post(API_ROOT + "users/addUser", user, {
                errorMessage: translations.registration.messages.createUserError,
                alertKey: "anonymouseUserAlert"
            });
        };

        /**
         * Get user by login name
         * @memberOf angular_module.WB.cinehub.services.registrationService
         * @public
         * @function validateUserEmail
         * @param {string} loginName user login email
         * @returns {promise}
         */
        this.validateUserEmail = function (loginName) {
            return $http.post(API_ROOT + "users/validateEmail", {
                loginName: loginName
            }, {
                errorMessage: translations.registration.messages.findUserByLoginNameError,
                showSpinner: false,
                alertKey: "anonymousUserAlert",
                hideErrorAlert: true
            });
        };
    }

    services.service("registrationService", ["$http", "API_ROOT", "localStorageService", registrationService]);
}());
