(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Select deselect all directive
     * @class selectDeselectAll
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     * @param {service} localStorageService local storage service 
     * @param {constant} PERMISSIONS PERMISSIONS
     */
    function selectDeselectAll(localStorageService, PERMISSIONS) {
        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @private
         * @memberOf angular_module.WB.cinehub.directives.selectDeselectAll
         */
        var link = function (scope) {

            /**
             * translations
             * @memberOf angular_module.WB.cinehub.directives.selectDeselectAll
             * @public
             * @type {object}
             */
            scope.translations = localStorageService.get("translations");
            
            /**
             * impersonate user flag
             * @public
             * @memberOf angular_module.WB.cinehub.directives.selectDeselectAll
             * @type {object}
             */
            scope.viewAs = localStorageService.get("impersonateUserFlag");

            /**
             * Select deselect all 
             * @param {boolean} state state 
             * @function selectDeselectAll
             * @public
             */
            scope.selectDeselectAll = function (state) {
                scope.assets.forEach(function (asset) {
                    if (asset.status && asset.status.toLowerCase() !== "killed" && asset.permission !== PERMISSIONS.VIEW) {
                        asset.selected = state;
                        if (scope.viewAs) {
                            asset.selected = false;
                        }
                    }
                });
            };
        };

        return {
            link: link,
            scope: {
                assets: "="
            },
            templateUrl: "selectDeselectAllTemplate.html"
        };
    }

    directives.directive("selectDeselectAll", ["localStorageService", "PERMISSIONS", selectDeselectAll]);

}());
