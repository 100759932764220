(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");
    
    /**
     * @class termsService
     * @memberOf angular_module.WB.cinehub.services
     * @desc Terms service
     * @param {!angular.$http} $http angular $http service
     * @param {strict} API_ROOT API_ROOT constant  
     */
    function termsService($http, API_ROOT) {
        
        /**
         * Get terms of use
         * @memberOf angular_module.WB.cinehub.services.termsService
         * @public
         * @param {int} territoryId territory id
         * @param {int} localeId locale id
         * @param {str} errorMessage error message
         * @returns {promise}
         */
        this.getTerms = function (territoryId, localeId, errorMessage) {
            return $http.get(API_ROOT + "terms/search/findByTerritoryIdAndLocaleId?territoryId=" + territoryId + "&localeId=" + localeId, { errorMessage: errorMessage});
        };

    }

    services.service("termsService", ["$http", "API_ROOT", termsService]);
}());
