(function() {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Search exhibitors theaters directive
     * @class searchExhibitorsTheaters
     * @memberOf angular_module.WB.cinehub.directives
     * @param {service} exhibitorsService exhibitors service
     * @returns {directiveDefinitionObj}
     */
    function searchExhibitorsTheaters(localStorageService, exhibitorsService) {

        /**
         * location
         * @private
         * @type {object}
         * @memberOf angular_module.WB.cinehub.directives.searchExhibitorsTheaters
         */
        var location = localStorageService.get("location");

        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @private
         * @memberOf angular_module.WB.cinehub.directives.searchExhibitorsTheaters
         */
        var link = function(scope) {

            /**
             * Translations
             * @type {object}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.searchExhibitorsTheaters
             */
            scope.translations = localStorageService.get("translations");

            /**
             * User info object
             * @type {object}
             * @memberOf angular_module.WB.cinehub.directives.searchExhibitorsTheaters
             * @public
             */
            scope.user = localStorageService.get("userInfo");

            /**
             * Exhibitors service
             * @type {service}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.searchExhibitorsTheaters
             */
            scope.exhibitorsService = exhibitorsService;

            /**
             * Territory id
             * @type {int}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.searchExhibitorsTheaters
             */
            scope.territoryId = "";

            /**
             * Create query string
             * @private
             * @function createQueryString
             * @memberOf angular_module.WB.cinehub.directives.searchExhibitorsTheaters
             */
            var createQueryString = function() {
                scope.filterQuery.name = scope.exhibitorTheater;
                scope.filterQuery.localName = scope.exhibitorTheater;
                scope.filterQuery.city = scope.location;
                scope.filterQuery.state = scope.location;
                scope.filterQuery.territoryName = scope.location;
            };

            /**
             * Init
             * @function init
             * @memberOf angular_module.WB.cinehub.directives.searchExhibitorsTheaters
             * @private
             */
            var init = function() {
                if(scope.user.role !== "homeOfficeAdmin") {
                    scope.territoryId = location.territoryId;
                }
                scope.resetFilters();
            };

            /**
             * Reset user filters
             * @public
             * @memberOf angular_module.WB.cinehub.directives.searchExhibitorsTheaters
             * @function resetFilters
             */
            scope.resetFilters = function() {
                scope.exhibitorTheater = "";
                scope.location = "";
                scope.reset()();
            };

            /**
             * Search users
             * @function searchExhibitorsTheaters
             * @memberOf angular_module.WB.cinehub.directives.searchExhibitorsTheaters
             * @public
             */
            scope.searchExhibitorsTheaters = function() {
                createQueryString();
                scope.getDataCallback()();
            };

            init();
        };

        return {
            replace: true,
            link: link,
            scope: {
                filterQuery: "=",
                getDataCallback: "&",
                reset: "&"
            },
            templateUrl: "searchExhibitorsTheatersTemplate.html"
        };
    }

    directives.directive("searchExhibitorsTheaters", ["localStorageService", "exhibitorsService", searchExhibitorsTheaters]);
}());
