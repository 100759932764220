(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Flash player fallback directive
     * @class flashPlayerFallbackDirective
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     * @param {service} localStorageService local storage service
     * @param {constant} API_EFD API_EFD
     */
    function flashPlayerFallbackDirective(localStorageService, API_EFD) {

        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @private
         * @memberOf angular_module.WB.cinehub.directives.flashPlayerFallbackDirective
         * @param {jQueryElement} element jquery element
         */
        var link = function (scope, element) {

            /**
             * translations
             * @memberOf angular_module.WB.cinehub.directives.flashPlayerFallbackDirective
             * @public
             * @type {object}
             */
            scope.translations = localStorageService.get("translations");

            element.html(
                "<object id=\"flowplayer\" width=\"900\" height=\"511\" data=\"/static/flash/flowplayer-3.2.18.swf\" type=\"application/x-shockwave-flash\">" +
                    "<param name=\"movie\" value=\"/static/flash/flowplayer-3.2.18.swf\">" +
                    "<param name=\"allowFullScreen\" value=\"true\" />" +
                    "<param name=\"wmode\" value=\"transparent\" />" +
                    "<param name=\"flashvars\" value='config={\"play\": null, \"clip\":\"" + API_EFD + "efd/sourceFiles/" + scope.assetId + "\"}'>" +
                     scope.translations.global.messages.flashFallbackMessage +
                    "<a href=\"http://get.adobe.com/flashplayer/\">" + scope.translations.global.messages.downloadFlashMessage + "</a>" +
                "</object>"
            );
        };

        return {
            link: link,
            restrict: "E", // applied on 'element'
            scope: {
                assetId: "="
            }
        };
    }

    directives.directive("flashPlayerFallback", ["localStorageService", "API_EFD", flashPlayerFallbackDirective]);

}());
