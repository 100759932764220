(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Audit Report controller
     * @dec The monthly report controller
     * @class reportsController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {service} reportsService  report service
     * @param {!angular.Service} $sce $sce
     * @param {service} localStorageService local storage service
     */
    function reportsController(localStorageService, $scope, reportsService) {

        /**
         * Instance of reportsController
         * @type {reportsController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         */
        var self = this;

        /**
         * translations
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         * @public
         */
        self.translations = localStorageService.get("translations");

        /**
         * impersonate user flag
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");

        /**
         * location
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         * @public 
         */
        self.location = localStorageService.get("location");
        
        /**
         * Current user
         * @type {object}
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         */
        self.currentUser = localStorageService.get("userInfo"),

        /**
         * disabled
         * @type {bool}
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         * @public 
         */
        self.disabled = false;

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         */
        self.pageHeaderItem = {
            pageTitle: self.translations.reports.labels.pageTitle,
            breadcrumbItems: [{
                linkTitle: self.translations.reports.labels.breadcrumbLinkTitle,
                link: "/reports"
            }]
        };

        /**
         * report model - Maintains report data.
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         * @type [Array]
         */
        self.reportModel = [];

        /**
         * All Reports.
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         * @type [Array]
         */
        self.allReports = [{
            value: "USER_REPORT",
            text: self.translations.reports.labels.userReports
        }, {
            value: "DOWNLOAD_REPORT",
            text: self.translations.reports.labels.downloadReports
        }, {
            value: "LOGIN_REPORT",
            text: self.translations.reports.labels.loginReports
        }, {
            value: "ALL_USERS",
            text: self.translations.reports.labels.allUsers
        }];
        
        /**
         * selectSettings - Reports Select plugin settings.
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         * @type {Object}
         */
        self.selectSettings = { idProp: "value",
                                displayProp: "text",
                                smartButtonMaxItems: 1,
                                keyboardControls: false,
                                showUncheckAll: true,
                                showCheckAll: true
                             };

        /**
         * Translation Text - Default value for the drop down
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         */
        self.reportTranslationText = { buttonDefaultText: self.translations.reports.labels.selectReports,
                                       checkAll: self.translations.reports.labels.checkAll,
                                       uncheckAll: self.translations.reports.labels.uncheckAll
                                     };

        /**
         * From Date - Calendar From date for the report
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         * @type {Object}
         */
        $scope.openFromDate = function() {
            $scope.startDateOpen.opened = true;
            if (!$scope.toDate) {
                $scope.startDateOptions.maxDate = new Date();
            }
        };

        $scope.startDateOpen = {
            opened: false
        };
        
        /**
         * To Date - Calendar To date for the report
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         * @type {Object}
         */
        $scope.openToDate = function() {
            $scope.endDateOpen.opened = true;
        };
        
        $scope.endDateOpen = {
            opened: false
        };

        /**
         * Start Date Options - Calendar settings for From Date
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         * @type {Object}
         */
        $scope.startDateOptions = {
            dateDisabled: false,
            showWeeks: false,
            minMode: "day",
            minDate: new Date(new Date().setMonth(new Date().getMonth() - 12)),
            maxDate: new Date()
        };

        /**
         * End Date Options - Calendar settings for To Date
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         * @type {Object}
         */
        $scope.endDateOptions = {
            dateDisabled: false,
            showWeeks: false,
            minMode: "day",
            minDate: null,
            maxDate: new Date()
        };

        $scope.$watch("fromDate", function (newVal) {
            $scope.endDateOptions.minDate = newVal;
            self.disabled = false;
            self.reportForm.$submitted = false;
        });

        $scope.$watch("toDate", function (newVal) {
            $scope.startDateOptions.maxDate = newVal;
            $scope.endDateOptions.minDate = new Date(new Date().setMonth(new Date().getMonth() - 12));
            self.disabled = false;
            self.reportForm.$submitted = false;
        });

        /**
         * Monthly Report
         * @public
         * @type {string}
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         */
        self.auditReports = [];

        /**
         * Report form
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         * @public
         * @type {object}
         */
        self.reportForm = null;

        $scope.checkReportType = function () {
            self.reportForm.$submitted = false;
            if (self.reportModel.length === 1) {
                if (self.reportModel[0].id === "ALL_USERS") {
                    self.disabled = true;
                }
            } else {
                self.disabled = false;
            }
        };

        /**
         * Get Monthly Report
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         * @function generate
         */
        $scope.generate = function () {
            if ((self.disabled && self.reportModel.length === 1) || (self.reportForm.$valid && self.reportModel.length > 0)) {
                self.reportModel.forEach(function (reportItem) {
                    self.auditReports.push(reportItem.id);
                });
                var fromDate = moment($scope.fromDate).format("DD-MMM-YY");
                var toDate = moment($scope.toDate).format("DD-MMM-YY");
                reportsService.getReport(fromDate, toDate, self.location.territoryId, self.currentUser.role, self.auditReports).success(function (result) {
                    self.reportForm.$submitted = false;
                    var file = new Blob([result], { type: "application/ms-excel;charset=utf-8" });
                    var fileName = "reports_" + moment().format("DDMMYYHHmmss") + ".xlsx";
                    if ((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {
                        if (window.navigator.msSaveOrOpenBlob) {
                            navigator.msSaveBlob(file, fileName);
                        }
                    } else {
                        var fileURL = URL.createObjectURL(file);
                        var link = document.createElement("a");
                        link.href = fileURL;
                        link.target = "_blank";
                        link.download = fileName;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                });
                self.auditReports = [];
            }
        };
        
        /**
         * Reset Report data
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         * @function reset
         */
        $scope.reset = function () {
            self.reportModel = [];
            $scope.fromDate = null;
            $scope.toDate = null;
            self.disabled = false;
            self.reportForm.$submitted = false;
        };
    }

    controllers.controller("reportsController", ["localStorageService", "$scope", "reportsService", reportsController]);
}());
