(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class languageService
     * @memberOf angular_module.WB.cinehub.services
     * @desc utility functions for determining user browser language
     * @param {service} $http $http service 
     * @param {constant} API_ROOT API_ROOT
     * @param {service} $q $q service
     * @param {service} $window window service
     * @param {service} localStorageService local storage service
     */
    function languageService($http, API_ROOT, $q, $window, localStorageService) {

        /**
         * Promise 
         * @type {promise}
         * @memberOf angular_module.WB.cinehub.services.languageService
         * @private
         */
        var promise = $q.defer(),

            /**
             * Browser language 
             * @type {string}
             * @memberOf angular_module.WB.cinehub.services.languageService
             * @private
             */
            browserLanguage = $window.navigator.language ? $window.navigator.language : $window.navigator.browserLanguage;

        /**
         * Get browser language
         * @memberOf angular_module.WB.cinehub.services.languageService
         * @public
         * @function getBrowserLanguage
         * @returns {promise}
         */
        this.getBrowserLanguage = function () {
            var storedBrowserLanguage = localStorageService.get("browserLanguage");
            if (storedBrowserLanguage) {
                promise.resolve(storedBrowserLanguage);
            } else {
                $http.get(API_ROOT + "locales/getLanguage&ignoreLocalizationString", {
                    errorMessage: "Cannot determine browser language rolling back to English",
                    showSpinner: false
                }).success(function (result) {
                    var acceptLanguageHeader = result.data.language,
                        language = "";
                    if (/^\S{2}-\S{2}/.test(acceptLanguageHeader)) {
                        language = /^\S{2}-\S{2}/.exec(acceptLanguageHeader)[0];
                        promise.resolve(language);
                    } else if (/^\S{2}/.test(acceptLanguageHeader)) {
                        language = /^\S{2}/.exec(acceptLanguageHeader)[0];
                        language = getLocaleAbbrByBrowserLang(result.data.locales, language);
                        promise.resolve(language);
                    } else {
                        promise.resolve(browserLanguage);
                    }
                    localStorageService.set("browserLanguage", language ? language : browserLanguage);
                }).error(function () {
                    promise.resolve("en-US");
                });
            }

            return promise.promise;
        };

        /**
         * Get Locale Abbreviation by browser Language.
         * @memberOf angular_module.WB.cinehub.services.languageService
         * @private
         * @param {object[]} liveLocales live locales
         * @param {string} Browser Language
         * @returns {string}
         */
        var getLocaleAbbrByBrowserLang = function (liveLocales, language) {
            var localeAbbr = language,
                index = 0,
                liveLocalesLength = liveLocales.length;
            for (index; index < liveLocalesLength; index++) {
                if (liveLocales[index].browserLanguage && liveLocales[index].browserLanguage === language) {
                    return liveLocales[index].abbreviation;
                }
            }
            return localeAbbr;
        };
    }
    services.service("languageService", ["$http", "API_ROOT", "$q", "$window", "localStorageService", languageService]);
}());
