(function ($) {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Grid directive
     * @class grid
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     * @param {service} convertLocalizationCodeToValueService convert localization code to value service
     * @param {service} localStorageService local storage service
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     * @param {constant} API_EFD API_EFD
     */
    function grid(convertLocalizationCodeToValueService, localStorageService, DEFAULT_PAGE_SIZE, API_EFD) {

        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @param {jQueryElement} element element
         * @private
         * @memberOf angular_module.WB.cinehub.directives.grid
         */
        var link = function (scope, element) {


            /**
             * Previous sort by property
             * @type {string}
             * @private
             * @memberOf angular_module.WB.cinehub.directives.grid
             */
            var sortQueryString = scope.defaultSort,

                /**
                 * Current header index, used to save the sate of column sort
                 * @type {int}
                 * @private
                 * @memberOf angular_module.WB.cinehub.directives.grid
                 */
                currentHeaderIndex = 0;

            /**
             * Translations
             * @type {object}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.grid
             */
            scope.translations = localStorageService.get("translations");

            /**
             * impersonate user flag
             * @public
             * @memberOf angular_module.WB.cinehub.directives.grid
             * @type {object}
             */
            scope.viewAs = localStorageService.get("impersonateUserFlag");

            /**
             * Current user object
             * @type {object}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.grid
             */
            scope.user = localStorageService.get("userInfo");

            /**
             * Table data
             * @type {object[]}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.grid
             */
            scope.data = [];

            /**
             * API EFD
             * @constant
             * @type {string}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.grid
             */
            scope.API_EFD = API_EFD;

            /**
             * Page data
             * @type {object}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.grid
             */
            scope.page = {
                "totalElements": 0,
                "totalPages": 0,
                "number": 1,
                "size": scope.pageSize || DEFAULT_PAGE_SIZE
            };

            /**
             * Convert localization code to value service
             * @type {service}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.grid
             */
            scope.convertLocalizationCodeToValueService = convertLocalizationCodeToValueService;

            /**
             * The list of the theaters
             * @public
             * @memberOf angular_module.WB.cinehub.controllers.modalLookupTheaterController
             * @type {object}
             */
            self.theaters = null;

            /**
             * Set model
             * @function setModel
             * @memberOf angular_module.WB.cinehub.directives.grid
             * @private
             * @param {object}
             */
            var setModel = function (result) {
                if (result._embedded) {
                    scope.data = result._embedded[scope.resultDataPropName];
                    scope.page = result.page;
                }
                if (result[scope.resultDataPropName]) {
                    scope.data = result[scope.resultDataPropName];
                    //scope.page.number = result.number + 1;
                    scope.page.number = result.number;
                    scope.page.size = result.size;
                    scope.page.totalPages = result.totalPages;
                    scope.page.totalElements = result.totalElements;
                }
            };

            var reset = function () {
                scope.headers.forEach(function (header) {
                    header.ascSortOrder = undefined;
                });
            };

            /**
             * Get data
             * @memberOf angular_module.WB.cinehub.directives.grid
             * @private
             * @function getData
             * @param {string} sort sort
             */
            var getData = function (sort) {
                scope.getDataFn()(scope.outsideFilterQuery, scope.page.number, scope.page.size, sort).success(function (result) {
                    scope.$emit("setTheatersSelected", result);
                    setModel(result);
                });
            };

            /**
             * Build sort query string
             * @function buildSortQueryString
             * @memberOf angular_module.WB.cinehub.directives.grid
             * @param {int} headerIndex header index
             * @private
             * @returns {string}
             */
            var buildSortQueryString = function (headerIndex) {
                sortQueryString = "";

                if (scope.headers[headerIndex].ascSortOrder !== undefined) {
                    sortQueryString = scope.headers[headerIndex].ascSortOrder === true ? scope.headers[headerIndex].sortKey + ",asc&sort=" : scope.headers[headerIndex].sortKey + ",desc&sort=";
                }

                if (sortQueryString.length > 0) {
                    scope.sortQuery = sortQueryString.slice(0, -6);
                } else {
                    scope.sortQuery = scope.defaultSort;
                }
                return scope.sortQuery;
            };

            /**
             * Sort data
             * @param {string} sortBy ether just string e.g "status" or "profile.firstName"
             * @param {boolean} ascSortOrder asc sort order
             * @param {int} headerIndex scope.headers index
             * @public
             * @memberOf angular_module.WB.cinehub.directives.grid
             * @function sort
             */
            scope.sort = function (sortBy, ascSortOrder, headerIndex) {
                sortQueryString = "";

                if (currentHeaderIndex !== headerIndex) {
                    reset();
                }

                if (ascSortOrder === undefined) {
                    scope.headers[headerIndex].ascSortOrder = true;
                } else if (ascSortOrder === true) {
                    scope.headers[headerIndex].ascSortOrder = false;
                } else {
                    scope.headers[headerIndex].ascSortOrder = undefined;
                }

                sortQueryString = buildSortQueryString(headerIndex);
                getData(sortQueryString);

                currentHeaderIndex = headerIndex;
            };

            /**
             * sort data on mobile
             * @param {string} sortBy ether just string e.g "status" or "profile.firstName"
             * @public
             * @memberOf angular_module.WB.cinehub.directives.grid
             * @function mobileSort
             */
            scope.mobileSort = function (sortBy) {
                if (sortBy) {
                    sortQueryString = "&sort=" + sortBy;
                    getData(sortQueryString);
                }
            };

            /**
             * On page state change
             * @function onPageStateChange
             * @public
             * @memberOf angular_module.WB.cinehub.directives.grid
             */
            scope.onPageStateChange = function () {
                scope.pageSize = scope.page.size;
                getData(sortQueryString);
            };

            /**
             * On row click
             * @function onRowClick
             * @public
             * @param {int} userId user id
             * @memberOf angular_module.WB.cinehub.directives.grid
             */
            scope.onRowClick = function (userId) {
                scope.onRowClickCallback()(userId);
            };

            /**
             * On callback one click
             * @function onCallbackOneClick
             * @public
             * @memberOf angular_module.WB.cinehub.directives.grid
             * @param {param} param param to pass to a callback function
             */
            scope.onCallbackOneClick = function (param) {
                scope.callbackOne()(param);
            };

            /**
             * On callback two click
             * @function onCallbackTwoClick
             * @public
             * @memberOf angular_module.WB.cinehub.directives.grid
             * @param {param} param param to pass to a callback function
             */
            scope.onCallbackTwoClick = function (param) {
                scope.callbackTwo()(param);
            };

            /**
             * On callback three click
             * @function onCallbackThreeClick
             * @public
             * @memberOf angular_module.WB.cinehub.directives.grid
             * @param {param} param param to pass to a callback function
             */
            scope.onCallbackThreeClick = function (param) {
                scope.callbackThree()(param);
            };

            /**
             * On callback four click
             * @function onCallbackFourClick
             * @public
             * @memberOf angular_module.WB.cinehub.directives.grid
             * @param {param} param param to pass to a callback function
             */
            scope.onCallbackFourClick = function (param) {
                scope.callbackFour()(param);
            };

            /**
             * On row select
             * @function onRowSelect
             * @public
             * @memberOf angular_module.WB.cinehub.directives.grid
             * @param {object} $event event
             */
            scope.onRowSelect = function ($event, rowData) {
                $("tr", $(element)).removeClass("selected");
                $($event.currentTarget).addClass("selected");
                scope.onRowClickCallback()(rowData);
            };

            /**
             * checkbox clicked
             * @function checkboxClicked
             * @public
             * @memberOf angular_module.WB.cinehub.directives.grid
             * @todo Make this more generic?
             */
            scope.checkboxClicked = function () {
                scope.$emit("theaterSelected", scope.data);
            };

            /**
             * radio selected
             * @function radioSelected
             * @public
             * @memberOf angular_module.WB.cinehub.directives.grid
             * @todo Make this more generic?
             */
            scope.radioSelected = function (item) {
                for (var i = 0; i < scope.data.length; i++) {
                    scope.data[i].selected = item.id === scope.data[i].id ? true : false;
                }

                scope.$emit("theaterSelected", scope.data);
            };

            scope.$watch("outsideData", function (newVal) {
                if (newVal) {
                    setModel(newVal);
                }
            }, true);
        };

        return {
            link: link,
            scope: {
                headers: "=",
                onRowClickCallback: "&",
                getDataFn: "&",
                callbackOne: "&",
                callbackTwo: "&",
                callbackThree: "&",
                callbackFour: "&",
                resultDataPropName: "@",
                outsideData: "=",
                outsideFilterQuery: "=",
                defaultSort: "@",
                sortQuery: "=",
                mobileSortOptions: "=",
                showGrid: "=",
                userType: "@",
                pageSize: "=",
                filterTerm: "="
            },
            templateUrl: function (element, attr) {
                return attr.templateUrl;
            }
        };
    }

    directives.directive("grid", ["convertLocalizationCodeToValueService", "localStorageService", "DEFAULT_PAGE_SIZE", "API_EFD", grid]);
}(window.jQuery));
