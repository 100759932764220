(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Multi factor authentication Controller
     * @class mfa
     * @memberOf angular_module.WB.cinehub.controllers
     * @desc This is Multi Factor Authentication controller
     * @param {!angular.$rootScope} $rootScope angular root scope
     * @param {window} window 
     * @param {cookies} cookies
     * @param {service} mfaService MFA Service
     * @param {service} userService user service
     * @param {service} localStorageService localStorageService
     * @param {constant} OAUTH_SESSION_LIFETIME oauth session lifetime
     * @param {constant} SECURE_COOKIES SECURE_COOKIES
     * @param {constant} DOMAIN DOMAIN
     */
    function mfaController($rootScope, $window, $cookies, localStorageService, mfaService, userService, OAUTH_SESSION_LIFETIME, SECURE_COOKIES, DOMAIN) {
        /**
         * Instance to mfaController
         * @private
         * @type {mfaController}
         * @memberOf angular_module.WB.cinehub.controllers.mfaController
         */
        var self = this;

        /**
         * translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.mfaController
         */
        self.translations = localStorageService.get("translations");

        /**
         *  isPushEnabled
         * @type {boolean}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.mfaController
         */        
        self.isPushEnabled = false;

        /**
         *  isTOTPEnabled
         * @type {boolean}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.mfaController
         */        
        self.isTOTPEnabled = false;

        /**
         *  pushFactorId
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.mfaController
         */        
        self.pushFactorId = null;

        /**
         *  totpFactorId
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.mfaController
         */        
        self.totpFactorId = null;
        /**
         *  MFA Verify Form
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.mfaController
         */        
        self.verifyForm = null;

        /**
         * Push Response Payload
         * @type {object}
         * @public
         * @memberof  angular_module.WB.cinehub.controllers.mfaController
         */
        self.pushResponse = null;
        /**
         * isPushButtonEnabled - This will be set to true once the push is sent successfully
         * @type boolean
         * @public
         * @memberof  angular_module.WB.cinehub.controllers.mfaController         
         */        
        self.isPushButtonEnabled = false;
        /**
         * currentPollingCount = counter that keeps the current polling count
         * @type number
         * @public
         * @memberof  angular_module.WB.cinehub.controllers.mfaController         
         */        
        self.currentPollingCount = 0;                 
        /**
         * pollingIntervalHndlr - Persist the handlers which needs to be stopped after polling ends
         * @type object
         * @public
         * @memberof  angular_module.WB.cinehub.controllers.mfaController         
         */        
        self.pollingIntervalHndlr = [];
        /**
         * timeoutErrMsg - Error Msg to display 
         * @type object
         * @public
         * @memberof  angular_module.WB.cinehub.controllers.mfaController         
         */        
        self.timeoutErrMsg = null;
        /**
         * errMsg - Error Msg to display 
         * @type object
         * @public
         * @memberof  angular_module.WB.cinehub.controllers.mfaController         
         */        
        self.errMsg = null;
        /**
         * passCode - PassCode entered by the user 
         * @type object
         * @public
         * @memberof  angular_module.WB.cinehub.controllers.mfaController         
         */        
        self.passCode = null;
        /**
         * MAX_POLLING_COUNT  - 60 - max number of retries (for 5mins with an interval of 5secs)
         * @type object
         * @public
         * @memberof  angular_module.WB.cinehub.controllers.mfaController         
         */        
        self.MAX_POLLING_COUNT = 60;
        /**
         * MAX_PASSCODE_RETRY_COUNT  - 3 - max number of retries (for 5mins with an interval of 5secs)
         * @type object
         * @public
         * @memberof  angular_module.WB.cinehub.controllers.mfaController         
         */        
        self.MAX_PASSCODE_RETRY_COUNT = 3;
        /**
         * currHeartbeatCount = counter that keeps the current count
         * @type number
         * @public
         * @memberof  angular_module.WB.cinehub.controllers.mfaController         
         */        
        self.currentVerifyPassCodeCount = 1;        
        /**
         * passCodeErrMsg - Error message after the submission of the passcode 
         * @type object
         * @public
         * @memberof  angular_module.WB.cinehub.controllers.mfaController         
         */        
        self.passCodeErrMsg = null;        
        /**
         * passCodeSbmtMsg - Number of times passcode entery unsuccessful 
         * @type object
         * @public
         * @memberof  angular_module.WB.cinehub.controllers.mfaController         
         */        
        self.passCodeSbmtMsg = null;

        /**
         * showPasscodeLink - Verify passcode entered by the user 
         * @type object
         * @public
         * @memberof  angular_module.WB.cinehub.controllers.mfaController         
         */        
        self.showPasscodeLink = true;

        /**
         * showMFAVerifyPage - flag to determine to render MFA verify vs registration
         * @type boolean
         * @public
         * @memberof  angular_module.WB.cinehub.controllers.mfaController
         */
        self.showMFAVerifyPage = false;
        /**
         * autoSendPush - flag to store the send push automatically stored in localstorage
         * @type boolean
         * @public
         * @memberof  angular_module.WB.cinehub.controllers.mfaController
         */
        self.autoSendPush = true;

        /**
         * Init
         * Get the factors and send the push notification if its present as factor
         * ticker1 - is to check whether the softErr should be enabled
         * @function init
         * @memberOf angular_module.WB.cinehub.controllers.mfaController
         * @public
         */
        self.init = function () { 
            self.userId = localStorageService.get("oktaUserId");
            self.actType = localStorageService.get("userActType");
            var oktaToken = localStorageService.get("oktaSessionToken");
            $rootScope.forceLoading = true;
            var autoPushFlag = localStorageService.get("autoMFAPush");
            if (autoPushFlag) {
                self.autoSendPush = (autoPushFlag === "Y");
            }
            mfaService.getFactors(self.userId, self.actType, oktaToken).success(function(resp){
                if(resp && resp.length > 0) {
                    var pushFactor = _.filter(resp, function(data) {
                        return (data.factorType === "push" && data.provider === "OKTA") ? true: false;
                    });
                    if (!_.isEmpty(pushFactor)) {
                        self.isPushEnabled = true;
                        self.pushFactorId = pushFactor[0]["id"];
                    }
                    var totpFactor = _.filter(resp, function(data) {
                        return (data.factorType.indexOf("totp") >= 0 && data.provider === "OKTA") ? true: false;
                    });
                    if (!_.isEmpty(totpFactor)) {
                        self.isTOTPEnabled = true;
                        self.totpFactorId = totpFactor[0]["id"];
                    }
                    // Cater for cases where user is registered with non OKTA MFA factors
                    if (_.isEmpty(pushFactor) && _.isEmpty(totpFactor)) {
                        self.showMFAVerifyPage = false;
                        $window.location.href = "/registerMFA";
                    } else {
                        self.showMFAVerifyPage = true;
                        $rootScope.forceLoading = false;
                        if (!self.autoSendPush) {
                            self.isPushButtonEnabled = true;
                        } else if (self.isPushEnabled) {
                            self.sendPush();
                        }
                    }
                } else {
                    $window.location.href = "/registerMFA";
                }
            }).error(function(err) {
                $window.location.href = "/login#?error=" + err.code;
            });
        };

        self.sendPush = function() {
            mfaService.push(self.userId, self.pushFactorId, self.actType).then(function(pushRspPayload) {
                self.isPushButtonEnabled = false;
                self.startPolling();
                self.pushResponse = {"expiresAt": pushRspPayload.data.expiresAt, "pollUrl": pushRspPayload.data._links.poll.href};
            });
        };
        
        self.verifyPush = function() {
            if (self.currentPollingCount < self.MAX_POLLING_COUNT) {
                mfaService.poll(self.userId, self.actType, self.pushResponse["pollUrl"]).success(function(resp) {
                    if (resp.factorResult === "TIMEOUT" ||
                        resp.factorResult === "REJECTED") {
                        self.errMsg = null;
                        self.timeoutErrMsg = resp.factorResult === "TIMEOUT" ? self.translations.login.messages.mfaPushFactorTimeout : 
                            self.translations.login.messages.mfaPushFactorRejected;
                        self.loginRedirect();
                    } else if (resp.factorResult === "SUCCESS") {
                        self.errMsg = null; self.timeoutErrMsg = null;
                        self.stopPolling();
                        self.dashboardRedirect(resp.mfaTransactionId);
                    } else if (resp.factorResult === "WAITING") {
                        self.currentPollingCount += 1;
                        self.pollingIntervalHndlr.push(setTimeout(self.verifyPush, 5000));
                    } 
                    if (!self.errMsg  && self.currentPollingCount >= 4) {
                        self.timeoutErrMsg = null;
                        self.errMsg = self.translations.login.messages.mfaPushFactorWaiting;
                    }                    
                }).error(function(err) {
                    $window.location.href = "/login#?error=" + err.code;
                });
            }
        };
        /**
         * Redirect to login
         */
        self.loginRedirect = function () {
            self.stopPolling();
            $window.location.href = "/login";
        };

        self.setAutoSendPush = function () {
            var autoPushFlag = self.autoSendPush ? "Y" : "N";
            mfaService.setUserAutoMFAPushFlag(localStorageService.get("userId"),
                self.userId, autoPushFlag).success(function(resp) {
                    if (resp && resp.status && resp.status === "SUCCESS") {
                        localStorageService.set("autoMFAPush", autoPushFlag);
                    }
                    if (autoPushFlag === "Y") {
                        self.sendPush();
                    }
                }).error(function(err) {
                    if (err) {
                        $window.location.href = "/login#?error=" + err.code;
                    }
                });
        };

        self.dashboardRedirect = function (mfaTransactionId) {
            mfaService.fetchTokenFromCache(self.userId, mfaTransactionId).success(function(resp) {
                $cookies.put("accessToken", resp.access_token, {
                    sameSite: "strict",
                    secure: SECURE_COOKIES,
                    expires: OAUTH_SESSION_LIFETIME,
                    domain: DOMAIN
                });
                self.getUserInfo();
            }).error(function(err) {
                $window.location.href = "/login#?error=" + err.code;
            });
        };

        /**
         * Get user info
         * @function getUserInfo
         * @memberOf angular_module.WB.cinehub.controllers.mfaController
         * @private
         */
        self.getUserInfo = function () {
            userService.getUserInfo(localStorageService.get("username"));
        };
                
        /**
         * Start the /verify call to check the status of 'push' notification
         */
        self.startPolling = function () {
            self.pollingIntervalHndlr.push(setTimeout(self.verifyPush, 5000));
        };

        /**
         * Clears all the timeout handlers
         */
        self.stopPolling = function () {
            _.forEach(self.pollingIntervalHndlr, function (intvlId) {
                clearTimeout(intvlId);
            });
        };      

        /**
         * Verify the passCode entered by the user
         */
        self.verifyPassCode = function () {
            if (self.currentVerifyPassCodeCount >= self.MAX_PASSCODE_RETRY_COUNT) {
                self.passCodeErrCode = self.passCodeErrCode ? self.passCodeErrCode : "sessionExpired";
                self.passCodeSbmtMsg = self.translations.login.messages.mfaCodeexhausted;
                $window.location.href = "/login#?error=" + self.passCodeErrCode;
            } else {
                self.verifyForm.$submitted = true;
                if (self.verifyForm.$valid) {
                    mfaService.verifyPassCode(self.userId, self.totpFactorId, self.actType, self.passCode).success(function(resp) {
                        if (resp.factorResult === "SUCCESS") {
                            self.passCodeSbmtMsg = "";
                            self.passCodeErrMsg = "";
                            self.dashboardRedirect(resp.mfaTransactionId);
                        } else {
                            self.passCode = "";
                            self.passCodeSbmtMsg = self.currentVerifyPassCodeCount + "/" + self.MAX_PASSCODE_RETRY_COUNT + " " + self.translations.login.messages.mfaAllowedAttempts;
                            self.passCodeErrMsg = self.translations.login.messages.mfaIncorrectCode;
                            self.currentVerifyPassCodeCount++;
                        }
                    }).error(function(err) {
                        if (err.code === "E0000069") {
                            $window.location.href = "/login#?error=" + err.code;
                        } else  {
                            self.passCode = "";
                            self.passCodeErrCode = err.code;
                            self.passCodeSbmtMsg = self.currentVerifyPassCodeCount + "/" + self.MAX_PASSCODE_RETRY_COUNT + " "+ self.translations.login.messages.mfaAllowedAttempts;
                            self.passCodeErrMsg = self.translations.login.messages.mfaIncorrectCode;
                            self.currentVerifyPassCodeCount++;
                        }
                    });
                }
            }
            
        };        
        //INIT
        self.init();
    }

    controllers.controller("mfaController", ["$rootScope", "$window", "$cookies", "localStorageService", "mfaService", "userService", "OAUTH_SESSION_LIFETIME", "SECURE_COOKIES", "DOMAIN", mfaController]);
} ());
