(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Error controller
     * @dec The error controller
     * @class errorController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {service} localStorageService local storage service
     * @param {service} translateService translate service
     * @param {service} locationService location service
     * @param {module} tmhDynamicLocale tmh dynamic locale module
     * @param {service} languageService language service
     */
    function errorController(localStorageService, translateService, locationService, tmhDynamicLocale, languageService) {

        /**
         * Instance to errorController
         * @memberOf angular_module.WB.cinehub.controllers.errorController
         * @private
         * @type {object}
         */
        var self = this,

            /**
             * User info
             * @type {int}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.materialsController
             */
            user = localStorageService.get("userInfo");

        /**
         * translations
         * @type {int}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.materialsController
         */
        self.translations = localStorageService.get("translations");

        /**
         * Whether the page controller has initialized the translations for the page
         * @type {boolean}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.errorController
         */
        self.translationsHaveloaded = false;

        /**
         * Page name
         * @type {boolean}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.errorController
         */
        self.page = "Error";

        /**
         * Error code
         * @public
         * @type {int}
         * @memberOf angular_module.WB.cinehub.controllers.errorController
         */
        self.errorCode = window.WB.cinehub.preload.errorCode;

        /**
         * Load translations
         * @function loadTranslations
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.errorController
         */
        var loadTranslations = function () {
            var isLoginPage = !user,
                isRegistrationPage = localStorageService.get("registrationData"),
                loadedTranslationsForLocale = localStorageService.get("loadedTranslationsForLocale"),
                location = localStorageService.get("location"),
                isInitialLogin = user && !location,
                locale = null,
                browserLanguage = "en-US";

            languageService.getBrowserLanguage().then(function (browserLang) {
                browserLanguage = browserLang;
                if (isInitialLogin) {
                    locationService.setDefaultLocale();
                    location = localStorageService.get("location");
                }

                if (isLoginPage && !isRegistrationPage) {
                    locale = browserLanguage;
                } else {
                    locale = location.locale;
                }

                if (loadedTranslationsForLocale === locale) {
                    self.translations = localStorageService.get("translations");
                    self.translationsHaveloaded = true;
                } else {
                    translateService.loadTranslations(locale).success(function (result) {
                        self.translations = result;
                        //localStorageService.set("translations", self.translations);
                        localStorageService.set("loadedTranslationsForLocale", locale);
                        self.translationsHaveloaded = true;
                    });
                }
                if (locale.toLowerCase() === "zh-hk") {
                    tmhDynamicLocale.set("zh-hant");
                } else {
                    tmhDynamicLocale.set(locale.toLowerCase());
                }
            });
        };

        /**
         * Init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.errorController
         * @function init
         */
        self.init = function () {
            if (!localStorageService.isSupported) {
                window.alert("Cinehub requires access to the browser data. Please exit from private browsing.");
            }

            loadTranslations();
        };

        //INIT
        self.init();

    }

    controllers.controller("errorController", ["localStorageService", "translateService", "locationService", "tmhDynamicLocale", "languageService", errorController]);
}());
