(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Selectable Media Asset directive
     * @class selectableMediaAsset
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     * @param {scope} $rootScope root scope
     * @param {object} window angular window
     * @param {service} localStorageService location storage service
     * @param {service} shoppingCartService shopping cart service
     * @param {service} restrictService restrict service
     * @param {service} deviceDetectorService service
     * @param {service} spotlightDetailsService service
     * @param {constant} API_EFD api constant for efd
     * @param {service} mediaTypeService service
     * @param {constant} AUDIO_VIDEO_ASSET_CATEGORY constant for the asset category
     * @param {service} videoSourceGeneratorService helpers for source video
     * @param {factory} filmUrlGeneratorFactory film url generator service
     * @param {service} convertLocalizationCodeToValueService convert localization code to value service 
     * @param {service} assetsService assets service 
     * @param {service} toDoService todo service 
     * @param {constant} PERMISSIONS PERMISSIONS
     */
    function selectableMediaAsset($rootScope,
        $window,
        localStorageService,
        shoppingCartService,
        permissionQueueService,
        restrictService,
        $uibModal,
        deviceDetectorService,
        spotlightDetailsService,
        API_EFD,
        mediaTypeService,
        AUDIO_VIDEO_ASSET_CATEGORY,
        videoSourceGeneratorService,
        filmUrlGeneratorFactory,
        convertLocalizationCodeToValueService,
        assetsService,
        toDoService,
        PERMISSIONS) {
        var selectableMediaAssetController =["getRenditionsService","localStorageService","$scope","$animate", function(getRenditionsService,localStorageService,$scope,$animate){
            $scope.displayPopover =false;
            $scope.url = " ";
            $animate.enabled(false);
            $scope.getEncodes = function(){
                $scope.selectedEncodes = [];
                $scope.displayPopover =false;
                var filmAsset = $scope.asset;
                getRenditionsService.getEncodes(filmAsset).success(function(result){
                    $scope.encodeOptions = result.data.AssetData;
                    $scope.displayPopover = true;
                });
            };
            $scope.getCartEncodes = function(){
                var downloadItem = $scope.asset;
                shoppingCartService.getCartEncodes(downloadItem).success(function(result){
                    if (downloadItem.canBulkDownload) {
                        $scope.cartEncodes = result.data.AssetData;
                    } else {
                        $scope.encodeOptions = result.data.AssetData;
                        $scope.displayPopover = true;
                    }
                });
            };
        }];
        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @param {jQuery} element element
         * @private
         * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
         */
        var link = function (scope, element) {

            /**
             * User information
             * @public
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @type {object}
             */
            scope.user = localStorageService.get("userInfo");

            /**
             * impersonate user flag
             * @public
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @type {object}
             */
            scope.viewAs = localStorageService.get("impersonateUserFlag");

            /**
             * exhibitor id
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @private
             * @type {int}
             */
            scope.exhibitorId = localStorageService.get("currentExhibitorAffiliation") || -1;

            /**
             * theater id
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @private
             * @type {int}
             */
            scope.theaterId = localStorageService.get("currentTheaterAffiliation") || -1;
            /**
             * translations
             * @type {boolean}
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @public
             */
            scope.translations = localStorageService.get("translations");

            /**
             * Access granted
             * @type {boolean}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             */
            scope.accessGranted = restrictService.getAccessGrant(["standard"]);

            /**
             * Is asset added to shopping cart flag
             * @type {boolean}
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @public
             */
            scope.isAssetAddedToShoppingCart = false;

            /**
             * Is asset in permission queue flag
             * @type {boolean}
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @public
             */
            scope.isAssetInPermissionQueue = false;

            /**
             * API_EFD constant
             * @constant
             * @type {string}
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @public
             */
            scope.API_EFD = API_EFD;

            /**
             * Permission constant 
             * @type {object}
             * @constant
             * @public
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             */
            scope.PERMISSIONS = PERMISSIONS;

            /** 
             * film url generator
             * @type {factory}
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @public
             */
            scope.filmUrlGenerator = filmUrlGeneratorFactory;

            /** 
             * convert localization string to value service
             * @type {service}
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @public
             */
            scope.convertLocalizationCodeToValueService = convertLocalizationCodeToValueService;
            scope.asset.selected = false;
            /**
             * Select unselect callback
             * @function selectUnselectCallback
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @private
             * @param {object} newVal new value
             */
            var selectUnselectCallback = function (newVal) {
                if (newVal !== undefined) {
                    if (scope.asset.selected) {
                        shoppingCartService.addAsset(scope.asset);
                        permissionQueueService.addAsset(scope.asset);
                    } else {
                        shoppingCartService.removeAsset(scope.asset);
                        permissionQueueService.removeAsset(scope.asset);
                    }
                }
            };

            /**
             * Get permission queue assets
             * @function getPermissionQueueAssets
             * @private
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             */
            var getPermissionQueueAssets = function () {
                if (scope.user.role === "homeOfficeAdmin") {
                    permissionQueueService.getPermissionQueueAssetsForHOA(scope.user.userId).success(function (result) {
                        scope.permissionQueueItems = result._embedded.permissionItems;
                        scope.asset.selected = false;
                    });
                } else if (scope.user.role === "territoryAdmin") {
                    permissionQueueService.getPermissionQueueAssets(scope.user.userId).success(function (result) {
                        scope.permissionQueueItems = result._embedded.permissionItems;
                        scope.asset.selected = false;
                    });
                }
            };

            /**
             * Select midia asset
             * @function selectMediaAsset
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @public
             */
            scope.selectMediaAsset = function () {
                scope.asset.selected = !scope.asset.selected;
                if (scope.viewAs) {
                    scope.asset.selected = false;    
                }
            };

            /**
             * Select midia asset
             * @function removeMediaAsset
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @public
             */
            scope.removeMediaAsset = function () {
                shoppingCartService.removeFromCart(scope.asset).success(function () {
                    $rootScope.$emit("updateShoppingCartCount", true);
                    $rootScope.$emit("updateDownloadcart", true);
                });
            };

            /**
             * getEFD URL
             * @function getEfdUrl
             * @public
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             */
            /**scope.getEfdUrl = function () {
                if (scope.asset.id) {
                    return scope.API_EFD + "efd/sourceFiles/" + scope.asset.id + "/" + scope.user.userId + "/" + scope.user.userId + "?territoryId="+scope.user.territoryId+
                "&localeId="+scope.user.localeId+"&exhibitorId="+scope.exhibitorId+"&theaterId="+scope.theaterId;
                } else {
                    return "";
                }
                
            };*/

            /**
             * Add to cart
             * @function addToCart
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @public
             */
            scope.addToCart = function () {
                if (scope.isAssetAddedToShoppingCart) {
                    return;
                }

                shoppingCartService.addToCart(scope.asset).success(function () {
                    $rootScope.$emit("updateShoppingCartCount", true);
                    //getShoppingCartAssets();
                });
            };

            /**
             * Remove from cart
             * @function removeFromCart
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @public
             */
            scope.removeFromCart = function () {
                shoppingCartService.removeFromCart(scope.asset).success(function () {
                    $rootScope.$emit("updateShoppingCartCount", true);
                    element.parent().parent().remove();
                });
            };

            /**
             * Remove from todo  
             * @function removeFromTodo
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @public
             */
            scope.removeFromTodo = function (asset) {
                var modalInstance = $uibModal.open({
                    templateUrl: "confirmationTemplate.html",
                    controller: "modalConfirmationController",
                    controllerAs: "modalConfirmationCtrl",
                    keyboard: false,
                    backdrop: "static",
                    size: "md",
                    resolve: {
                        message: function () {
                            return scope.translations.toDo.messages.confirmRemoveAsset;
                        },
                        headerTitle: function () {
                            return scope.translations.global.messages.warning;
                        },
                        showAffirmativeBtn: function () {
                            return true;
                        },
                        affirmativeBtnText: function () {
                            return scope.translations.global.labels.confirmButton;
                        },
                        cancelBtnText: function () {
                            return scope.translations.global.labels.cancelButton;
                        }
                    }
                });

                modalInstance.result.then(function () {
                    assetsService.removeFromTodo(asset.id).success(function() {
                        toDoService.todoTabCounts.unpermissionedMaterialsCount--;
                        toDoService.todoTabCounts.allCount--;
                        scope.unpublishedMaterials.totalElements--;
                        toDoService.recalculateAllCount = !scope.appliedFilters;
                        toDoService.recalculateTodoCounts("unpermissionedMaterialsCount", toDoService.todoTabCounts.unpermissionedMaterialsCount);
                        toDoService.setTodoTabCounts(scope.user.role, false).then(function () {
                            $rootScope.$emit("rerenderTodoCounts");
                        });
                        element.parent().remove();  
                        scope.asset.selected = false; 
                    });
                });
            };

            /**
             * Add to queue
             * @function addToQueue
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @public
             */
            scope.addToQueue = function () {
                if (scope.isAssetInPermissionQueue) {
                    return;
                }

                permissionQueueService.addToQueue(scope.asset).success(function () {
                    $rootScope.$emit("updatePermissionQueueCount", true);
                    getPermissionQueueAssets();
                });
            };

            /**
             * Remove from queue
             * @function removeFromQueue
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @public
             */
            scope.removeFromQueue = function () {
                permissionQueueService.removeFromQueue(scope.asset).success(function () {
                    getPermissionQueueAssets();
                });
            };

            /**
             * Open light box
             * @function openLightbox
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @public
             */
            scope.openLightbox = function () {
                var action = scope.isHoaView ? "sourceView" : scope.action ? scope.action : "edit";
                scope.openLightBox()(null, scope.asset, action);
            };

            /**
             * Open light box
             * @function openLightbox
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @public
             */
            scope.updateOnesheet = function () {
                if (scope.asset.id !== scope.asset.posterImage) {
                    spotlightDetailsService.updateOnesheet(scope.asset).success(function () {
                        $rootScope.$emit("UpdateParentSpotlight");
                    });
                }
            };

            /**
             * Determines whether the user's device is a mobile or a tablet
             * @public
             * @function isMobileOrTablet
             * @memberOf angular_module.WB.cinehub.controllers.selectableMediaAsset
             */
            scope.isMobileOrTablet = deviceDetectorService.isMobileOrTablet;

            /**
             * Specifies whether the asset is audio or video
             * @public
             * @function isAudioVideo
             * @memberOf angular_module.WB.cinehub.controllers.selectableMediaAsset
             */
            scope.isAudioVideo = (scope.assetCategory || "").toUpperCase() === AUDIO_VIDEO_ASSET_CATEGORY;

            scope.$watch("asset.selected", selectUnselectCallback);

            scope.$watch("shoppingCartInfo", function (newVal) {
                scope.isAssetAddedToShoppingCart = _.findIndex(newVal, {
                    assetId: scope.asset.id.toString()
                }) >= 0;
            });

            scope.$watch("permissionQueueItems", function (newVal) {
                scope.isAssetInPermissionQueue = _.findIndex(newVal, {
                    assetId: scope.asset.id.toString()
                }) >= 0;
            });

            $rootScope.$on("materialNameUpdated", function ($event, assetObj) {
                if (assetObj.oldFileName === scope.fileName && assetObj.newFileName !== scope.fileName) {
                    scope.fileName = assetObj.newFileName;
                }
            });

        };

        return {
            replace: true,
            link: link,
            scope: {
                title: "@",
                fileName: "@",
                assetCategory: "@",
                wbType: "@",
                wbpiType: "@",
                fileType: "@",
                imgSrc: "@",
                displayHoverMenu: "=",
                displaySelectBtn: "=",
                asset: "=",
                shoppingCartInfo: "=",
                permissionQueueItems: "=",
                selectedAssets: "=",
                displayAssetCategory: "=",
                displayActionBtnMobileVersion: "=",
                mobileAction: "@",
                isHoaView: "=",
                openLightBox: "&",
                action: "@",
                section: "@",
                appliedFilters: "=",
                unpublishedMaterials: "=",
                displayCartEncodes:"=",
                downloadOnly: "=",
                encodesCount:"@",
                isCartPage:"="
            },
            templateUrl: "selectableMediaAssetTemplate.html",
            controller: selectableMediaAssetController,
            controllerAs: "selectableMediaAssetCtrl"
        };
    }

    directives.directive("selectableMediaAsset", [
        "$rootScope",
        "$window",
        "localStorageService",
        "shoppingCartService",
        "permissionQueueService",
        "restrictService",
        "$uibModal",
        "deviceDetectorService",
        "spotlightDetailsService",
        "API_EFD",
        "mediaTypeService",
        "AUDIO_VIDEO_ASSET_CATEGORY",
        "videoSourceGeneratorService",
        "filmUrlGeneratorFactory",
        "convertLocalizationCodeToValueService",
        "assetsService",
        "toDoService",
        "PERMISSIONS",
        selectableMediaAsset
    ]);
}());
