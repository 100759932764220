(function () {
    "use strict";

    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * admin registered profile controller
     * @class adminRegisteredProfileController
     * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
     * @desc This is register profile controller
     * @param {!angular.$rootScope} $rootScope root scope
     * @param {modal} $uibModal uib modal
     * @param {object} $window window object
     * @param {service} userService user service
     * @param {service} localStorageService local storage service
     * @param {service} emailService email service
     * @param {service} helpContactService help contact service
     * @param {service} helpContactService registration Service
     * @param {service} languageService language service
     * @param {service} loginService login service
     * @param {service} oauthService oauth service 
     * @param {service} $cookies cookies service 
     * @param {service} translateService translate service
     * @param {constant} SECURE_COOKIES SECURE_COOKIES
     * @param {constant} STATE_LIST STATE_LIST
     * @param {constant} DOMAIN DOMAIN 
     * @param {constant} OAUTH_SESSION_LIFETIME OAUTH_SESSION_LIFETIME
     * @param {service} privacyPolicyService privacy policy service
     */
    function adminRegisteredProfileController($rootScope, $uibModal, $window, userService, localStorageService, emailService, helpContactService, registrationService, languageService, loginService, oauthService, $cookies, translateService, privacyPolicyService, territoriesService, SECURE_COOKIES, STATE_LIST, DOMAIN, OAUTH_SESSION_LIFETIME, DEFAULT_CONTACT_US_EMAIL) {

        /**
         * Instance to adminRegisteredProfileController
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         * @private
         * @type {adminRegisteredProfileController}
         */
        var self = this,

            /**
             * Browser language
             * @type {string}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
             */
            browserLanguage = "en-US",

            /**
             * initial user state object
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
             */
            initialUserState = {
                receiveNewsletter: false,
                receiveNewMaterialUpdates: false,
                receiveNewsUpdates: false,
                receiveReleaseDateUpdates: false
            };

        /**
         * Register profile form
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         */
        self.adminRegisteredProfileForm = null;

        /**
         * Register profile form
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         */
        self.verifyUserForm = null;

        /**
         * Verification error flag
         * @type {boolean}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         */
        self.verificationError = true;

        /**
         * Exhibitor Relationship
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        self.exhibitorRelationship = [];

        /**
         * Theater Affiliations
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        self.theaterAffiliations = [];
        /**
         * Verification error flag
         * @type {boolean}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         */
        self.verificationComplete = false;

        /**
         * state list
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @type {array}
         * @public
         */
        self.stateList = STATE_LIST;

        /**
         * Verification error message 
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         */
        self.verificationErrorMessage = "";

        /**
         * translated labels/messages/values stored in local storage
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         * @public
         * @type {object}
         */
        self.translations = localStorageService.get("translations");

        /**
         * email format regex
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         * @type {regex}
         */
        self.emailFormat = emailService.getEmailFormatRegex();

        /**
         * user object to store all the registration data
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         * @type {obj}
         * @public
         */
        self.user = _.clone(initialUserState);

        /**
         * USA country code constant
         * @type {constant}
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @public
         */
        self.USA = "176";


        /**
         * CANADA country code constant
         * @type {constant}
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @public
         */
        self.CANADA = "177";

        /**
         * Load translations
         * @function loadTranslations
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         * @returns {Promise}
         */
        var loadTranslations = function () {
            return translateService.loadTranslations(browserLanguage, "global.messages.loadTranslationsError").success(function (result) {
                self.translations = result;
                localStorageService.set("translations", result);
                localStorageService.set("loadedTranslationsForLocale", browserLanguage);
            });
        };

        /**
         * Get error message 
         * @function getErrorMessage
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         * @returns {string}
         * @param {string} errorCode error code
         */
        var getErrorMessage = function (errorCode) {
            var errorMessage = "";
            switch (errorCode) {
                case "CH_100":
                    errorMessage = self.translations.registerProfile.messages.userNotFound;
                    break;
                case "CH_207":
                    errorMessage = self.translations.registerProfile.messages.invalidToken;
                    break;
                case "CH_201":
                    errorMessage = self.translations.registerProfile.messages.invalidStatusPendingEmailVerification;
                    break;
                case "CH_208":
                    errorMessage = self.translations.registerProfile.messages.invalidStatusActive;
                    break;
                case "CH_202":
                    errorMessage = self.translations.registerProfile.messages.invalidStatusPendingApproval;
                    break;
                case "CH_206":
                    errorMessage = self.translations.registerProfile.messages.invalidStatus;
                    break;
                case "CH_210":
                    errorMessage = self.translations.registerProfile.messages.invalidEmail;
                    break;
                case "pendingEmailVerification":
                    errorMessage = self.translations.login.messages.pendingEmailVerificationError;
                    break;
                case "pendingApproval":
                    errorMessage = self.translations.login.messages.pendingApprovalError;
                    break;
                case "rejected":
                    errorMessage = self.translations.login.messages.rejectedError;
                    break;
                case "inactive":
                    errorMessage = self.translations.login.messages.inactiveError;
                    break;
                case "invalidCredentials":
                    errorMessage = self.translations.login.messages.invalidCredentialsError;
                    break;
                case "userNotFound":
                    errorMessage = self.translations.login.messages.userNotFoundError;
                    break;
                case "error":
                    errorMessage = self.translations.login.messages.error;
                    break;
                case "pendingAccountVerification":
                    errorMessage = self.translations.login.messages.pendingAccountVerificationError;
                    break;
                default:
                    errorMessage = self.translations.registerProfile.messages.callFail;
            }
            return errorMessage;
        };

        /**
         * Verify userId and token
         * @private 
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         * @function verifyUserIdAndToken
         */
        var verifyUserIdAndToken = function () {
            var payload = {
                userId: $window.WB.cinehub.preload.userId,
                emailVerificationToken: $window.WB.cinehub.preload.emailVerificationToken
            };
            self.verificationErrorMessage = getErrorMessage("CH_208");
            return userService.verifyStandardProfile(payload).success(function (response) {
                if (response.status && response.status.toLowerCase() === "success") {
                    self.adminRegisteredProfileForm.token.$setValidity("invalidToken", true);
                    self.verificationError = false;
                    self.user = response.data.user;
                    self.exhibitorRelationship = JSON.parse(self.user.exhibitorRelationship);
                    self.theaterAffiliations = JSON.parse(self.user.theaterAffiliations);
                    self.user.email = "";
                    // getCountryCode();
                    getContactInfo();
                    self.isVerified = true;
                }
                self.verificationComplete = true;
                $rootScope.forceLoading = false;
            }).error(function (response) {
                $rootScope.$broadcast("hideAlert");
                if (response.status && response.status.toLowerCase() === "fail") {
                    self.adminRegisteredProfileForm.token.$setValidity(response.code, false);
                    self.verificationError = true;
                    if (response.code !== "CH_208") {
                        self.verificationErrorMessage = getErrorMessage(response.code);
                    }
                }
                self.verificationComplete = true;
                $rootScope.forceLoading = false;
            });
        };

        // /**
        //  * get country code
        //  * @memberOf angular_module.WB.cinehub.controllers.registrationController
        //  * @function getCountryCode
        //  * @private
        //  */
        // var getCountryCode = function () {
        //     registrationService.getTerritoryInfo(self.user.territoryId).success(function (result) {
        //         self.countryCode = result._embedded.territories.length > 0 ? result._embedded.territories[0].countryCode : "";
        //     });
        // };

        /**
         * get contact info
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         * @function getContactInfo
         * @private
         */
        var getContactInfo = function () {
            territoriesService.getTerritoryById(self.user.territoryId).success(function (result) {
                self.contactEmail = result._embedded.territories.length > 0 ? result._embedded.territories[0].contactInfo : "";
                self.countryCode = result._embedded.territories.length > 0 ? result._embedded.territories[0].countryCode : "";

                self.contactEmail = emailService.isValidEmail(self.contactEmail) ? self.contactEmail : DEFAULT_CONTACT_US_EMAIL;
            });
        };

        /**
         * set help contact
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         * @private
         * @function setHelpContact
         */
        var setHelpContact = function () {
            helpContactService.getHelpContactEmail().then(function (contactEmail) {
                self.contactEmail = contactEmail;
            });
        };

        /**
         * Get user info
         * @function getUserInfo
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         * @private
         */
        var getUserInfo = function () {
            loginService.getUserInfo(self.user.email).success(function (result) {
                var userInfo = result[0];
                if (userInfo) {
                    localStorageService.set("userInfo", userInfo);
                    switch (userInfo.userType) {
                        case "ex-corporate":
                            localStorageService.set("currentExhibitorAffiliation", userInfo.exhibitorId);
                            break;
                        case "ex-other":
                            var exhibitorRelationship = JSON.parse(userInfo.exhibitorRelationship);
                            localStorageService.set("currentExhibitorAffiliation", exhibitorRelationship[0].id);
                            break;
                        case "ex-theater":
                            var theaterAffiliations = JSON.parse(userInfo.theaterAffiliations);
                            localStorageService.set("currentExhibitorAffiliation", theaterAffiliations[0].exhibitorId);
                            localStorageService.set("currentTheaterAffiliation", theaterAffiliations[0].id);
                            break;
                    }
                    $window.location.href = "/home";
                }
            }).error(function () {
                self.disableLoginBtn = false;
            });
        };

        /**
         * Get oauth token
         * @function getOauthToken
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         * @private
         */
        var getOauthToken = function () {
            oauthService.getUserOauthToken("password", self.user.email, self.user.password).success(function (token) {
                $cookies.put("accessToken", token.access_token, {
                    sameSite: "strict",
                    secure: SECURE_COOKIES,
                    expires: OAUTH_SESSION_LIFETIME,
                    domain: DOMAIN
                });
                getUserInfo();
            }).error(function (error) {
                $rootScope.$broadcast("showAlert", "danger", getErrorMessage(error.error_description), "anonymousUserAlert");
                self.disableLoginBtn = false;
            });
        };

        /**
         * Log in 
         * @function logIn
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         */
        self.logIn = function () {
            getOauthToken();
            self.disableLoginBtn = true;
        };

        /**
         * cancel register profile and redirect to login page
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         * @function cancel
         * @public
         */
        self.cancel = function () {
            var modalInstance = $uibModal.open({
                templateUrl: "confirmationTemplate.html",
                controller: "modalConfirmationController",
                controllerAs: "modalConfirmationCtrl",
                keyboard: false,
                backdrop: "static",
                size: "md",
                resolve: {
                    message: function () {
                        return self.translations.registerProfile.messages.cancel;
                    },
                    headerTitle: function () {
                        return self.translations.userInfo.messages.warning;
                    },
                    showAffirmativeBtn: function () {
                        return true;
                    },
                    affirmativeBtnText: function () {
                        return self.translations.userInfo.labels.yesBtn;
                    },
                    cancelBtnText: function () {
                        return self.translations.userInfo.labels.noBtn;
                    }
                }
            });

            modalInstance.result.then(function () {
                $window.onbeforeunload = undefined;
                $window.location.href = "/login";
            });
        };

        /**
         * Submit profile
         * @function submit 
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         */
        self.verifySubmit = function () {
            if (!self.user.email) {
                self.invalidEmail = true;
            } else {
                self.user.email = self.user.email.toLowerCase();
                if (self.user.email === self.user.loginName) {
                    self.isVerified = false;
                    self.invalidEmail = false;
                } else {
                    self.invalidEmail = true;
                }
            }
        };

        /**
         * isInternalAccount
         * @function isInternalAccount
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @public
         */
        self.isInternalAccount = function () {
            if (self.user.accountType === "INTERNAL") {
                return true;
            }
            return false;
        };
        /**
         * Submit profile
         * @function submit 
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         */
        self.submit = function () {
            if (self.adminRegisteredProfileForm.$valid) {
                self.user.termsAccepted = true;
                self.user.privPolicyAccepted = true;
                self.user.primaryPhone = "+" + self.countryCode + "-" + self.user.phone;
                //self.user.loginName = self.user.email;
                userService.adminRegisteredProfile(self.user).success(function () {
                    self.isCompleted = true;
                }).error(function (response) {
                    $rootScope.$broadcast("hideAlert");
                    if (response.status && response.status.toLowerCase() === "fail") {
                        $rootScope.$broadcast("showAlert", "danger", getErrorMessage(response.code), "anonymousUserAlert");
                    }
                });
            }
        };

        /**
         * Init
         * @function init
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         * @public
         */
        self.init = function () {
            if (!localStorageService.isSupported) {
                window.alert("Cinehub requires access to the browser data. Please exit from private browsing.");
                return;
            }
            $rootScope.forceLoading = true;
            languageService.getBrowserLanguage().then(function (browserLang) {
                browserLanguage = browserLang;
                loadTranslations().then(function () {
                    setHelpContact();
                    verifyUserIdAndToken();
                });
            });
        };

        /**
         * Privacy Policy 
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         * @function privacyPolicy
         * @public
         */
        self.privacyPolicy = function () {
            privacyPolicyService.getWMPrivacyPolicyUrl(localStorageService.get("location"));
        };

        //INIT
        self.init();
    }

    controllers.controller("adminRegisteredProfileController", ["$rootScope", "$uibModal", "$window", "userService", "localStorageService", "emailService", "helpContactService", "registrationService", "languageService", "loginService", "oauthService", "$cookies", "translateService", "privacyPolicyService", "territoriesService", "SECURE_COOKIES", "STATE_LIST", "DOMAIN", "OAUTH_SESSION_LIFETIME", "DEFAULT_CONTACT_US_EMAIL", adminRegisteredProfileController]);
}());
