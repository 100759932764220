(function() {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Permission restrict
     * @class restrictDirective
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     * @param {service} restrictService restrict service
     */
    function restrictDirective(restrictService) {

        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @param {jqueryElement} element jquery element
         * @param {object} attrs attributes
         * @private
         * @memberOf angular_module.WB.cinehub.directives.restrictDirective
         */
        var link = function(scope, element, attrs) {

            /**
             * Access granted
             * @private
             * @type {boolean}
             * @memberOf angular_module.WB.cinehub.directives.restrictDirective
             */
            var accessGranted = restrictService.getAccessGrant(attrs.restrictedRoles.split(","));

            if (!accessGranted) {
                switch (attrs.restrictionLevel) {
                    case "display":
                        element.remove();
                        break;
                    case "edit":
                        element.prop("disabled", true);
                        break;
                    default:
                        element.remove();
                }
            }
        };

        return {
            restrict: "A", //attribute directive
            link: link
        };
    }

    directives.directive("restrict", ["restrictService", restrictDirective]);
}());
