(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");
    
    /**
     * @class redirectService
     * @memberOf angular_module.WB.cinehub.services
     * @desc film service
     */
    function redirectService() {
        /**
         * get redirect film url
         * @memberOf angular_module.WB.cinehub.services.redirectService
         * @public
         * @param {str} columnName column name
         * @returns {promise}
         */
        this.getRedirectFilmUrl = function (columnName) {
            var filmUrl = location.href.split("#"),
                redirectUrl = filmUrl[0] + "#/film/",
                activeTitleSection = "";

            switch (columnName) {
                case "synopsis":
                case "website":
                case "runtime":
                case "rating":
                case "other":
                case "genres":
                case "release_patterns":
                case "release_date_comment":
                case "aspect_ratios":
                case "formats":
                case "title":
                    activeTitleSection = "details";
                    break;
                case "anti_piracy_info":
                    activeTitleSection = "antiPiracy";
                    break;
                case "general_strategy":
                    activeTitleSection = "strategy/general";
                    break;
                case "intheater_strategy":
                    activeTitleSection = "strategy/inTheater";
                    break;
                case "onlinepromotions_strategy":
                    activeTitleSection = "strategy/onlinePromotion";
                    break;
                case "trailer_plcmnt_recommendations":
                    activeTitleSection = "strategy/trailerPlacement";
                    break;
                default:
                    activeTitleSection = "details";
                    break;
            }

            return redirectUrl + activeTitleSection + "?highlight=" + columnName;
        };
    }

    services.service("redirectService", [redirectService]);
}());
