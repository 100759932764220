(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Modal add edit news controller
     * @dec The modal add edit news controller
     * @class modalAddEditNewsController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {$uibModalInstance} $uibModalInstance modal instance
     * @param {$uibModal} $uibModal modal window
     * @param {int} newsId news id
     * @param {object} film film
     * @param {service} addEditNewsService add edit news service
     * @param {service} localStorageService local storage service
     * @param {service} tinymceService tinymceService
     */
    function modalAddEditNewsController($uibModalInstance, $uibModal, addEditNewsService, newsId, film, localStorageService, tinymceService) {

        /**
         * Instance of modalAddEditNewsController
         * @type {modalAddEditNewsController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalAddEditNewsController
         */
        var self = this,

            /**
             * user id
             * @type {object}
             * @public
             * @memberOf angular_module.WB.cinehub.controllers.modalAddEditNewsController
             */
            user = localStorageService.get("userInfo");

        /**
         * Translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalAddEditNewsController
         */
        self.translations = localStorageService.get("translations");

        /**
         * new item flag
         * @public
         * @type {boolean}
         * @memberOf angular_module.WB.cinehub.controllers.modalAddEditNewsController
         */
        self.isNew = !newsId ? true : false;

        /**
         * film new flag
         * @public
         * @type {boolean}
         * @memberOf angular_module.WB.cinehub.controllers.modalAddEditNewsController
         */
        self.isFilmNews = film ? true : false;

        /**
         * news item
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.modalAddEditNewsController
         */
        self.newsItem = {};

        /**
         * tinymce text editor option
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @public
         */
        self.tinymceOptions = tinymceService.getTinymceOptions();

        /*
         * Get film titles
         * @function getFilmTitles
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        var getFilmTitles = function () {
            addEditNewsService.getFilmTitles().success(function (result) {
                self.filmTitles = result._embedded.films;
                self.filmTitles.forEach(function (filmTitle) {
                    if (filmTitle.akaTitle === "generalNews") {
                        filmTitle.title = self.translations.global.labels.generalNews;
                    }
                });
            });
        };

        /**
         * Get news details
         * @function getNewsDetails
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalAddEditNewsController
         */
        var getNewsDetails = function () {
            addEditNewsService.getNewsDetails(newsId).success(function (result) {
                self.newsItem = result._embedded.newsItems[0];
                self.newsItem.film = {
                    id: self.newsItem.filmId,
                    title: self.newsItem.filmTitle
                };
            });
        };

        /**
         * Validate news story field
         * @function validateNewsStoryField
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalAddEditNewsController
         */
        self.validateNewsStoryField = function () {
            self.addEditNewsForm.story.$setValidity("required", !_.isEmpty(self.newsItem.contents));
        };

        /**
         * Cancel
         * @function cancel
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalAddEditNewsController
         */
        self.cancel = function () {
            if (self.addEditNewsForm.$dirty) {
                $uibModal.open({
                    templateUrl: "confirmationTemplate.html",
                    controller: "modalConfirmationController",
                    controllerAs: "modalConfirmationCtrl",
                    keyboard: false,
                    backdrop: "static",
                    size: "md",
                    resolve: {
                        message: function () {
                            return self.translations.global.messages.confirmationMessage;
                        },
                        headerTitle: function () {
                            return self.translations.global.messages.warning;
                        },
                        showAffirmativeBtn: function () {
                            return true;
                        },
                        affirmativeBtnText: function () {
                            return self.translations.global.labels.yesButton;
                        },
                        cancelBtnText: function () {
                            return self.translations.global.labels.noButton;
                        }
                    }
                }).result.then(function () {
                    $uibModalInstance.dismiss();
                });
            } else {
                $uibModalInstance.dismiss();
            }
        };

        /**
         * Publish the changes
         * @function publish
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalAddEditNewsController
         */
        self.publish = function () {
            var saveEndpoint = null,
                newsItemSaveCopy = _.cloneDeep(self.newsItem),
                now = moment().utc().format();

            self.validateNewsStoryField();

            if (self.addEditNewsForm.$valid) {
                saveEndpoint = self.isNew ? addEditNewsService.create(newsItemSaveCopy) : addEditNewsService.update(newsItemSaveCopy);

                newsItemSaveCopy.displayOptions = 1;
                newsItemSaveCopy.creationDate = self.isNew ? now : newsItemSaveCopy.creationDate;
                newsItemSaveCopy.lastModified = now;
                newsItemSaveCopy.filmId = newsItemSaveCopy.film ? newsItemSaveCopy.film.id : newsItemSaveCopy.filmId;
                newsItemSaveCopy.filmTitle = newsItemSaveCopy.film ? newsItemSaveCopy.film.title : newsItemSaveCopy.filmTitle;
                delete newsItemSaveCopy.film;
                newsItemSaveCopy.modifier = user.userId;

                saveEndpoint.success(function () {
                    $uibModalInstance.close();
                });
            }
        };

        /**
         * Init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalAddEditNewsController
         */
        self.init = function () {
            getFilmTitles();

            if (!self.isNew) {
                getNewsDetails();
            } else if (film) {
                self.newsItem.filmId = film.id;
                self.newsItem.filmTitle = film.title;
            }
        };

        //INIT
        self.init();
    }

    controllers.controller("modalAddEditNewsController", ["$uibModalInstance", "$uibModal", "addEditNewsService", "newsId", "film", "localStorageService", "tinymceService", modalAddEditNewsController]);
}());
