(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");
    
    /**
     * @class cacheService
     * @memberOf angular_module.WB.cinehub.services
     * @desc asset service
     * @param {!angular.$http} $http angular $http service
     * @param {constant} API_ROOT API_ROOT constant  
     * @param {service} localStorageService local storage service
     * @param {service} locationService location service
     */
    function additionalAffiliationService($http, API_ROOT, localStorageService, locationService) {

        /**
         * translations
         * @private
         * @type {object}
         * @memberOf angular_module.WB.cinehub.services.additionalAffiliationService
         */
        self.translations = localStorageService.get("translations");

        /**
         * The user
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.additionalAffiliationService
         * @type {object[]}
         */
        self.currentProfile = localStorageService.get("userInfo");

        /**
         * table header for additional affiliations
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.additionalAffiliationService
         * @type {regex}
         */
        this.headers = function () {
            return [{name: "country"}, {name: "exhibitor"}, {name: "status"}];
        };
    
        /**
         * check user has additional affiliations
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.additionalAffiliationService
         * @type {regex}
         */
        this.isAdditionalAffiliation = function (user) {
            var  isAdditionalAffiliation =  user && !_.isEmpty(user.userAffiliations) && !_.isEmpty(user.addlTerrStatusList) ? true : false;
            return isAdditionalAffiliation;
        };

        /**
         * getAdditionalLocaleByRegion
         * @memberOf angular_module.WB.cinehub.controllers.additionalAffiliationService
         * @function getAdditionalLocaleByRegion
         * @public
         */
        this.getAdditionalLocaleByRegion = function (additionalAffiliation, user, additionalAffiliations, isExistingUser, pageRender) {
            var origAddlAffln = _.cloneDeep(additionalAffiliation);
            var origUser = _.cloneDeep(user);
            var origAddlAfflns = _.cloneDeep(additionalAffiliations);
            if (origAddlAffln.additionalRegion) {
                var region = JSON.parse(origAddlAffln.additionalRegion);
                origAddlAffln.regionId = region.id;
                var additionalLocales = locationService.filterLocaleByRegion(region);
                origAddlAffln.additionalLocales = additionalLocales;
                if(user.userType === "ex-other" && pageRender === "registration") {
                    return  {
                        additionalAffiliation: origAddlAffln,
                        user: origUser,
                        additionalAffiliations: origAddlAfflns
                    };
                } else if(user.userType === "ex-other" && pageRender === "myProfile") {
                    return filterMyProfileTerritory(origAddlAffln, origUser, origAddlAfflns);
                }
                else {
                    return filterRegisteredTerritory(origAddlAffln, origUser, origAddlAfflns, isExistingUser);
                }            
            }
            origAddlAffln.additionalLocales = [];
            return  {
                additionalAffiliation: origAddlAffln,
                user: origUser,
                additionalAffiliations: origAddlAfflns
            };
        };

        /**
         * remove selected registered territory from lang drop-down.
         * @memberOf angular_module.WB.cinehub.controllers.additionalAffiliationService
         * @function filterRegisteredTerritory
         * @public
         */
        var filterRegisteredTerritory = function (origAddlAffln, origUser, origAddlAfflns, isExistingUser) {
            _.remove(origAddlAffln.additionalLocales, function(locale) {
                return locale.territoryId === origUser.territoryId;
            });
            if (isExistingUser) {
                _.remove(origAddlAffln.additionalLocales, function (locale) {
                    return _.findIndex(origUser.userAffiliations, function (userAffiliation) {
                        return locale.territoryId === userAffiliation.territoryId;
                    }) >= 0;
                });
            }
            if (!_.isEmpty(origAddlAfflns)) {
                _.remove(origAddlAffln.additionalLocales, function (locale) {
                    return _.findIndex(origAddlAfflns, function (affiliation) {
                        return locale.territoryId === affiliation.territoryId;
                    }) >= 0;
                });
            }
            return  {
                additionalAffiliation: origAddlAffln,
                user: origUser,
                additionalAffiliations: origAddlAfflns
            };
        };
        /**
         * remove selected registered territory from lang drop-down.
         * @memberOf angular_module.WB.cinehub.controllers.additionalAffiliationService
         * @function filterMyProfileTerritory
         * @public
         */
        var filterMyProfileTerritory = function (origAddlAffln, origUser, origAddlAfflns) {           
            
            if (!_.isEmpty(origAddlAffln && origAddlAffln.additionalLocales)) {
                _.remove(origAddlAffln.additionalLocales, function(locale) {
                    return locale.territoryId === origUser.territoryId;
                });
                
                origUser.addlTerrStatusList.forEach(function(affiliation) {
                    _.remove(origAddlAffln.additionalLocales, function(locale) {
                        return locale.territoryId === affiliation.territoryId;
                    });
                });
            }
            return  {
                additionalAffiliation: origAddlAffln,
                user: origUser,
                additionalAffiliations: origAddlAfflns
            };
        };
        /**
         * Set Additional Territory Data
         * @function setAdditionalTerritoryData
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.additionalAffiliationService
         * @return {(boolean|object[])}
         */
        this.setAdditionalTerritoryData = function (additionalAffiliation) {
            var origAddlAffln = _.cloneDeep(additionalAffiliation);
            var selectedLocale = _.filter(self.translations.values.locales, {
                id: origAddlAffln.localeId
            });
            var selectedTerritory = _.filter(self.translations.values.territories, {
                id: selectedLocale[0].extraCode
            });
            origAddlAffln.regionId = selectedTerritory[0].extraCode;
            origAddlAffln.territory = selectedTerritory[0].value;
            origAddlAffln.territoryId = selectedLocale[0].extraCode;
            origAddlAffln.locale = selectedLocale[0].value;
            additionalAffiliation = origAddlAffln;
            return additionalAffiliation;
        };

        /**
         * set additional exhibitors
         * @memberOf angular_module.WB.cinehub.controllers.additionalAffiliationService
         * @function setAdditionalExhibitors
         * @public
         */
        this.setAdditionalExhibitors = function(selectedItem, additionalAffiliation, user, additionalAffiliations, isExistingUser, isAddedOrRemoved){
            var addExbObj = {};
            var additionalExhibitors = !_.isEmpty(additionalAffiliations) ?  _.cloneDeep(additionalAffiliations) : [];
            var origAddlAffln = _.cloneDeep(additionalAffiliation);
            var origUser = _.cloneDeep(user);
            var isExistExhibitor =false;
            if(user.userType === "ex-other") {
                if(isAddedOrRemoved === "added") {
                    var exhibitors = false;
                    origUser.userAffiliations = origUser.userAffiliations || [];
                    if((origUser.userAffiliations && origUser.userAffiliations.length >0) || (origUser.exhibitorRelationship && origUser.exhibitorRelationship.length>0)) {
                        var exhibitorId = selectedItem && selectedItem.exhibitorId ? selectedItem.exhibitorId : selectedItem.id;
                        exhibitors = origUser.userAffiliations.some(function(selected) { 
                            return selected.exhibitorId == exhibitorId;
                        }) || origUser.exhibitorRelationship.some(function(selected) { 
                            return selected.id == exhibitorId;
                        });
                    }
                    if(exhibitors) {
                        isExistExhibitor =true;
                        origAddlAffln = null;
                    } else {
                        origAddlAffln.exhibitorObj = selectedItem;
                        addExbObj.exhibitorId = selectedItem && selectedItem.exhibitorId ? selectedItem.exhibitorId : selectedItem.id;
                        selectedItem.localName = selectedItem && selectedItem.exhibitorId ? selectedItem.exhibitorLocalName : selectedItem.localName;
                        
                        addExbObj.localName = selectedItem.localName + " (" + origAddlAffln.territory + ")";
                        addExbObj.regionId = origAddlAffln.regionId;
                        addExbObj.territory = origAddlAffln.territory;
                        addExbObj.territoryId = origAddlAffln.territoryId;
                        addExbObj.localeId = origAddlAffln.localeId;
                        additionalExhibitors.push(addExbObj);

                        origUser.userAffiliations = additionalExhibitors;
                        origAddlAffln = null;
                    }
                } else if (!_.isEmpty(additionalExhibitors)) { 
                    exhibitorId = selectedItem && selectedItem.exhibitorId ? selectedItem.exhibitorId : selectedItem.id;
                    
                    // remove de-selected affiliation from the user obj.
                    if (origUser && origUser.userAffiliations) {
                        _.remove(origUser.userAffiliations, function (affil) {
                            return affil.exhibitorId == exhibitorId;
                        });
                    }

                    if (additionalExhibitors && additionalExhibitors.length >0) {
                        _.remove(additionalExhibitors, function (exhib) {
                            return exhib.exhibitorId == exhibitorId;
                        });
                    }
                    
                    origAddlAffln = null;
                }
                           
            } else {               
                origAddlAffln.exhibitorObj = selectedItem;
                if(selectedItem && selectedItem.exhibitorId) {
                    addExbObj.exhibitorId = selectedItem.exhibitorId;
                } else {
                    addExbObj.exhibitorId = selectedItem.id;
                }
                addExbObj.localName = selectedItem.localName + " (" + origAddlAffln.territory + ")";
                addExbObj.regionId = origAddlAffln.regionId;
                addExbObj.territory = origAddlAffln.territory;
                addExbObj.territoryId = origAddlAffln.territoryId;
                addExbObj.localeId = origAddlAffln.localeId;
                additionalExhibitors.push(addExbObj);

                if (!_.isEmpty(additionalExhibitors)) {
                    if (isExistingUser) {
                        var usrAdditionalAffiliations = additionalExhibitors;
                        var userAffiliations = origUser.userAffiliations;
                        usrAdditionalAffiliations.forEach(function (affiliation) {
                            if (_.findIndex(userAffiliations, function (userAffil) {
                                return affiliation.exhibitorId === userAffil.exhibitorId;}) === -1) {
                                userAffiliations.push(affiliation);
                            }
                        });
                        origUser.userAffiliations = userAffiliations;
                        origAddlAffln = null;
                    } else {
                        origUser.userAffiliations = additionalExhibitors;
                        origAddlAffln = null;
                    }
                }
            }            
            return {
                additionalAffiliation: origAddlAffln,
                user: origUser,
                additionalAffiliations: additionalExhibitors,
                isExistExhibitors: isExistExhibitor
            };
        };

        /**
         * Additional Exhibitor/Company Not Found Selected.
         * @function additionalCompanyNotSelected
         * @memberOf angular_module.WB.cinehub.controllers.additionalAffiliationService
         * @public
         */
        this.additionalCompanyNotSelected = function (additionalAffiliation, userType) {
            var origAddlAffln = _.cloneDeep(additionalAffiliation);
            if(userType === "ex-other") {
                origAddlAffln.theater = null;
                origAddlAffln.selectedTheaters = null;
                additionalAffiliation = origAddlAffln;
            } else {
                origAddlAffln.exhibitorObj = null;
                origAddlAffln.theater = null;
                origAddlAffln.selectedTheaters = null;
                origAddlAffln.exhibitorId = null;
                additionalAffiliation = origAddlAffln;
            }
            
            return additionalAffiliation;
        };

        /**
         * removeAdditionalAffiliation
         * @memberOf angular_module.WB.cinehub.controllers.additionalAffiliationService
         * @function removeAdditionalAffiliation
         * @param {int} theaterId theater id
         * @public
         */
        this.removeAdditionalAffiliation = function (affiliationId, additionalAffiliations, user) {
            var addlAfflnsClone = _.cloneDeep(additionalAffiliations);
            var userClone = _.cloneDeep(user);
            // remove de-selected affiliation from the user obj.
            if (userClone && userClone.userAffiliations) {
                _.remove(userClone.userAffiliations, function (affil) {
                    return affil.exhibitorId === affiliationId;
                });
            }
            var selectedIndex = _.findIndex(addlAfflnsClone, {
                exhibitorId: affiliationId
            });
            addlAfflnsClone.splice(selectedIndex, 1);
            if(_.isEmpty(addlAfflnsClone)) {
                return { addlAfflns : null, user : userClone};
            } else {
                return { addlAfflns : addlAfflnsClone, user : userClone};
            }
        };
        /**
         * removeAdditionalAffiliationExOther
         * @memberOf angular_module.WB.cinehub.controllers.additionalAffiliationService
         * @function removeAdditionalAffiliationExOther
         * @param {int} affiliationId 
         * @public
         */
        this.removeAdditionalAffiliationExOther = function (affiliationId, additionalAffiliations, user, additionalAffiliation) {
            var addlAfflnsClone = _.cloneDeep(additionalAffiliations);
            var userClone = _.cloneDeep(user);
            var origAddlAffln = _.cloneDeep(additionalAffiliation);
            // remove de-selected affiliation from the user obj.
            if (userClone && userClone.userAffiliations) {
                _.remove(userClone.userAffiliations, function (affil) {
                    return affil.exhibitorId == affiliationId;
                });
            }
            var selectedIndex = _.findIndex(addlAfflnsClone, {
                exhibitorId: affiliationId
            });
            addlAfflnsClone.splice(selectedIndex, 1);

            if(origAddlAffln && origAddlAffln.exhibitorObj) {
                _.remove(origAddlAffln.exhibitorObj, function (affil) {
                    return affil.id == affiliationId;
                });
            }

            if(_.isEmpty(addlAfflnsClone)) {
                return { addlAfflns : null, user : userClone, additionalAffiliation: origAddlAffln};
            } else {
                return { addlAfflns : addlAfflnsClone, user : userClone, additionalAffiliation: origAddlAffln};
            }
        };
        /**
         * getSelectedAffiliationId
         * @function getSelectedAffiliationId
         * @param {obj} exhibitor exhibitor
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.additionalAffiliationService
         */
        this.getSelectedAffiliationId = function (exhibitor) {
            return exhibitor && exhibitor.exhibitorId ? exhibitor.exhibitorId : exhibitor
            && exhibitor.id ? exhibitor.id : null;
        };

        /**
         * process additional exhibitors
         * @function processAdditionalExhibitors
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.additionalAffiliationService
         */
        this.processAdditionalExhibitors = function (user, currentUser, isPrimaryTerritory) {
            var additionalExhibitors = [];
            var additionalExhibitorsCurrentUser = [];
            var userAffiliations = user.userAffiliations;
            var currentExhibitor;
            var primaryExhibitor;
            if (isPrimaryTerritory) {
                userAffiliations.forEach (function (affiliation) {                   
                    user.addlTerrStatusList.forEach (function (addlTerrStatus) {
                        if (affiliation.territoryId === addlTerrStatus.territoryId) {
                            affiliation["status"] = addlTerrStatus.status;
                            additionalExhibitors.push(affiliation);
                        }
                    });
                });
                var primaryAffiliation = _.find(user.userAffiliations,  {
                    territoryId: user.territoryId
                });
                primaryAffiliation["status"] = user.status;
                if(user.userType !== "ex-other") {
                    additionalExhibitors.push(primaryAffiliation);
                }
            } else {
                currentExhibitor = _.find(user.userAffiliations,  {
                    territoryId: currentUser.territoryId
                });
                primaryExhibitor = _.find(user.userAffiliations,  {
                    territoryId: user.territoryId
                });
                primaryExhibitor["status"] = user.status;
                if(user.userType !== "ex-other") {
                    additionalExhibitors.push(primaryExhibitor);
                }
                
                userAffiliations.forEach (function (affiliation) {                   
                    user.addlTerrStatusList.forEach (function (addlTerrStatus) {
                        if (affiliation.territoryId === addlTerrStatus.territoryId) {
                            if (addlTerrStatus.territoryId === currentUser.territoryId) {
                                if(user.userType === "ex-other") {
                                    affiliation.localName = affiliation.exhibitorName;
                                    additionalExhibitorsCurrentUser.push(affiliation);
                                } 
                                currentExhibitor["status"] = addlTerrStatus.status;
                                currentExhibitor["comments"] = addlTerrStatus.comments;
                                currentExhibitor["actionDate"] = addlTerrStatus.actionDate;
                                affiliation["status"] = addlTerrStatus.status;
                                additionalExhibitors.push(affiliation);                                                              

                            } else {
                                affiliation["status"] = addlTerrStatus.status;
                                additionalExhibitors.push(affiliation);
                            }

                        }
                    });
                });
            }
            return {
                primaryExhibitor : primaryExhibitor,
                currentExhibitor : currentExhibitor,
                additionalExhibitors : additionalExhibitors,
                additionalExhibitorsCurrentUser: additionalExhibitorsCurrentUser
            };
        };

    }

    services.service("additionalAffiliationService", ["$http", "API_ROOT", "localStorageService", "locationService", additionalAffiliationService]);
}());
