(function() {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * News details controller
     * @dec The news details controller
     * @class newsDetailsController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.Scope} $scope angular scope
     * @param {$uibModal} $uibModal modal instance
     * @param {$window} $window $window instance
     * @param {service} newsDetailsService news details service
     * @param {service} localStorageService local storage service
     * @param {constant} API_EFD API_EFD
     * @param {service} deviceDetectorService device detector service
     * @param {factory} filmUrlGeneratorFactory film url generator factory
     */
    function newsDetailsController($scope, $uibModal, $window, newsDetailsService, localStorageService, API_EFD, deviceDetectorService, filmUrlGeneratorFactory) {

        /**
         * Instance of newsDetailsController
         * @type {newsDetailsController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsDetailsController
         */
        var self = this,

            /**
             * News id
             * @type {int}
             * @memberOf angular_module.WB.cinehub.controllers.newsDetailsController
             * @private
             */
            newsId = window.WB.cinehub.preload.newsId;

        /**
         * Device detector factory
         * @public
         * @type {factory}
         * @memberOf angular_module.WB.cinehub.controllers.newsDetailsController
         */
        self.deviceDetectorService = deviceDetectorService;

        /**
         * Film url generator service
         * @public
         * @type {service}
         * @memberOf angular_module.WB.cinehub.controllers.newsDetailsController
         */
        self.filmUrlGenerator = filmUrlGeneratorFactory;

        /**
         * translations
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.newsDetailsController
         */
        self.translations = localStorageService.get("translations");

        /**
         * impersonate user flag
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsDetailsController
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");

        /**
         * API_EFD
         * @type {constant}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsDetailsController
         */
        self.API_EFD = API_EFD;
        

        /**
         * is newsItem Loaded Flag
         * @type {constant}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsDetailsController
         */
        self.isNewsItemLoaded = false;

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.newsDetailsController
         */
        self.pageHeaderItem = {
            pageTitle: self.translations.newsDetails.labels.pageTitle,
            breadcrumbItems: [{
                linkTitle: self.translations.newsDetails.labels.breadcrumbLinkTitle,
                link: "/news-list"
            }]
        };

        /**
         * Get news details
         * @function getNewsDetails
         * @private
         * @param {boolean} newsUpdated news updated flag
         * @memberOf angular_module.WB.cinehub.controllers.newsDetailsController
         */
        var getNewsDetails = function(newsUpdated) {
            var errorMessage = self.translations.newsDetails.messages.getNewsDetailsError;

            newsDetailsService.getNewsDetails(newsId, errorMessage).success(function(result) {
                self.isNewsItemLoaded = true;
                self.newsItem = result._embedded.newsItems[0];

                if (newsUpdated) {
                    self.pageHeaderItem.breadcrumbItems.splice(-1, 1);
                }
                
                if (self.newsItem) {
                    self.pageHeaderItem.breadcrumbItems.push({
                        linkTitle: self.newsItem.title.toLowerCase() === "general news" ? self.translations.global.labels.generalNews : self.newsItem.title,
                        link: "/news/" + self.newsItem.id
                    });
                }
            });
        };

        /**
         * Get recent news items
         * @function getRecentNewsItems
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.newsDetailsController
         */
        var getRecentNewsItems = function() {
            var size = 8,
                primarySort = "lastModified,desc",
                secondarySort = "title",
                errorMessage = self.translations.newsDetails.messages.getRecentNewsItemsError;

            newsDetailsService.getRecentNewsItems(size, primarySort, secondarySort, errorMessage).success(function(result) {
                self.recentNewsItems = result._embedded.newsItems;
            });
        };

        /**
         * takes the user to other page
         * @function go
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsDetailsController
         * @param {string} linkTo link to the other page
         */
        self.go = function(linkTo) {
            $window.location.href = linkTo;
        };

        /**
         * Open add edit news modal
         * @function openAddEditNewsModal
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsDetailsController
         */
        self.openAddEditNewsModal = function() {
            var modalInstance = $uibModal.open({
                templateUrl: "/modules/addEditNewsModal/modalAddEditNews.html",
                controller: "modalAddEditNewsController",
                controllerAs: "addEditNewsModalCtrl",
                keyboard: false,
                backdrop: "static",
                size: "lg",
                resolve: {
                    newsId: function() {
                        return newsId;
                    },
                    film: function() {
                        return null;
                    }
                }
            });

            modalInstance.result.then(function() {
                getNewsDetails(true);
            });
        };

        /**
         * Open delete news modal
         * @function openAddEditNewsModal
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsDetailsController
         * @param {object} newsItem news item
         */
        self.openDeleteNewsModal = function(newsItem) {
            $uibModal.open({
                templateUrl: "/modules/deleteNewsModal/modalDeleteNews.html",
                controller: "modalDeleteNewsController",
                controllerAs: "deleteNewsModalCtrl",
                keyboard: false,
                backdrop: "static",
                resolve: {
                    newsItem: function() {
                        return newsItem;
                    }
                }
            });
        };


        /**
         * Init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsDetailsController
         */
        self.init = function() {
            getNewsDetails();
            getRecentNewsItems();
        };

        //INIT
        self.init();
    }

    controllers.controller("newsDetailsController", ["$scope", "$uibModal", "$window", "newsDetailsService", "localStorageService", "API_EFD", "deviceDetectorService", "filmUrlGeneratorFactory", newsDetailsController]);
}());
