(function ($) {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * what's new controller
     * @dec what's new controller
     * @class whatsNewController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.$rootScope} $rootScope angular rootScope
     * @param {!angular.$scope} $scope angular scope
     * @param {!angular.$location} angulr $location
     * @param {service} whatsNewService what's new service
     * @param {factory} filmUrlGeneratorFactory filim url generator factory
     * @param {constant} API_EFD API_EFD
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     * @param {service} permissionQueueService permission queue service
     * @param {service} shoppingCartService shopping cart service
     * @param {constant} SECURE_COOKIES SECURE_COOKIES
     * @param {constant} DOMAIN DOMAIN
     * @param {service} assetCategoryConvertor asset category convertor
     * @param {!angular.$state} $state angular state
     * @param {constant} AUDIO_VIDEO_ASSET_CATEGORY AUDIO_VIDEO_ASSET_CATEGORY
     */
    function whatsNewController($rootScope, $scope, $location, $cookies, whatsNewService, localStorageService, filmRatingService, filmUrlGeneratorFactory, API_EFD, DEFAULT_PAGE_SIZE, permissionQueueService, shoppingCartService, SECURE_COOKIES, DOMAIN, assetCategoryConvertor, $state, AUDIO_VIDEO_ASSET_CATEGORY) {

        /**
         * Instance of whatsNewController
         * @type {whatsNewController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        var self = this,

            /**
             * user
             * @type {object}
             * @public
             * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
             */
            user = localStorageService.get("userInfo"),

            /**
             * Original path
             * @type {string}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
             */
            originalPath = $location.$$path,

            /**
             * Default filter sort
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
             */
            defaultFilterSort = {
                sort: "lastModified,desc",
                modifiedInterval: 3
            },

            /**
             * Updated assets
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
             */
            updatedAssets = null,

             /**
             * Updated assets Filters
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
             */
            updatedAssetsFilters = null,

            /**
             * New assets
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
             */
            newAssets = null,

            /**
             * New assets Filters
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
             */
            newAssetsFilters = null,

            /**
             * days
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
             */
            daysModified = null;

        /**
         * translations
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @public
         * @type {object}
         */
        self.translations = localStorageService.get("translations");

        /**
         * Multi Select Filter Options & Models.
         * Holds all the filter options
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @private
         * @type [Array]
         */
        self.filterOptions =  [];

        /**
         * Multi Select Filter Options & Models.
         * Holds all the filter values
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @private
         * @type [Array]
         */
        self.allFilterValues = [];


        /**
         * Filter sort options for filterContainerDirective  materials
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @public
         */
        self.materialsFilterSort = {};

        /**
         * assets
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @public
         * @type {object}
         */
        self.assets = {
            "size": DEFAULT_PAGE_SIZE,
            "number": 0
        };

        /**
         * films
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @public
         * @type {object}
         */
        self.films = {
            page: {
                size: DEFAULT_PAGE_SIZE,
                number: 0
            }
        };

        /**
         * API_EFD
         * @constant
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @private
         * @type {string}
         */
        self.API_EFD = API_EFD;

        /**
         * AUDIO_VIDEO_ASSET_CATEGORY
         * @constant
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @private
         * @type {string}
         */
        self.AUDIO_VIDEO_ASSET_CATEGORY = AUDIO_VIDEO_ASSET_CATEGORY;

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.pageHeaderItem = {
            pageTitle: self.translations.whatsNew.labels.pageTitle,
            breadcrumbItems: [{
                linkTitle: self.translations.whatsNew.labels.breadcrumbLinkTitle,
                link: "/whats-new"
            }]
        };

        /**
         * New materials filter sort
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @public
         * @type {object}
         */
        self.newMaterialsFilterSort = {
            filmId: [],
            sort: "permissionDate,desc",
            wbAssetType: [],
            wbpiAssetType: [],
            fileType: [],
            modifiedInterval: 3
        };

        /**
         * Updated films filter sort
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @public
         * @type {object}
         */
        self.updatedFilmsFilterSort = {
            filmId: "",
            sort: "permissionDate,desc",
            wbAssetType: "",
            wbpiAssetType: "",
            modifiedInterval: 3
        };

        /**
         * Updated materials filter sort
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @public
         * @type {object}
         */
        self.updatedMaterialsFilterSort = {
            filmId: [],
            sort: "permissionDate,desc",
            wbAssetType: [],
            wbpiAssetType: [],
            fileType: [],
            modifiedInterval: 3
        };

        /**
         * film sorting options
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.filmSortOptions = [{
            value: "releaseDate,desc",
            text: self.translations.sort.labels.releaseDateNewestToOldest
        }, {
            value: "releaseDate,asc",
            text: self.translations.sort.labels.releaseDateOldestToNewest
        }, {
            value: "title,asc",
            text: self.translations.sort.labels.titleNameAToZ
        }, {
            value: "title,desc",
            text: self.translations.sort.labels.titleNameZToA
        }, {
            value: "lastModified,desc",
            text: self.translations.sort.labels.updatedDateNewestToOldest
        }, {
            value: "lastModified,asc",
            text: self.translations.sort.labels.updatedDateOldestToNewest
        }];

        /**
         * material sorting options
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.materialSortOptions = [{
            value: "lastModified,desc",
            text: self.translations.sort.labels.newestToOldest
        }, {
            value: "lastModified,asc",
            text: self.translations.sort.labels.oldestToNewest
        }, {
            value: "filmTitle,asc",
            text: self.translations.sort.labels.titleNameAToZ
        }, {
            value: "filmTitle,desc",
            text: self.translations.sort.labels.titleNameZToA
        }];

        /**
         * New material sorting options
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.newMaterialSortOptions = [{
            value: "permissionDate,desc",
            text: self.translations.sort.labels.newestToOldest
        }, {
            value: "permissionDate,asc",
            text: self.translations.sort.labels.oldestToNewest
        }, {
            value: "filmTitle,asc",
            text: self.translations.sort.labels.titleNameAToZ
        }, {
            value: "filmTitle,desc",
            text: self.translations.sort.labels.titleNameZToA
        }];


         /**
         * Sort by options model
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @private
         * @type [Array]
         */
        self.newMaterialSortModel = {};


         /**
         * Sort by options model
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @private
         * @type [Array]
         */
        self.updatedMaterialSortModel = {};

         /**
         * Multi Select Filter Options & Models.
         * Default button text for Sort
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type [Array]
         */
        self.sortTypeTranslations = {buttonDefaultText: "Sort By"};

        /**
         * modified interval for the film tab
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.filmModifiedIntervals = [{
            value: 3,
            text: self.translations.filter.labels.modifiedWithin3Days
        }, {
            value: 7,
            text: self.translations.filter.labels.modifiedWithin7Days
        }, {
            value: 14,
            text: self.translations.filter.labels.modifiedWithin2Weeks
        }, {
            value: 28,
            text: self.translations.filter.labels.modifiedWithin4Weeks
        }];

        /**
         * modified interval for the material tabs
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.materialModifiedIntervals = [{
            value: 3,
            text: self.translations.filter.labels.modifiedWithin3Days
        }, {
            value: 7,
            text: self.translations.filter.labels.modifiedWithin7Days
        }, {
            value: 14,
            text: self.translations.filter.labels.modifiedWithin2Weeks
        }];


        /**
         * Sort by options model
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @private
         * @type [Array]
         */
        self.newMaterialDaysModel = {id : self.materialModifiedIntervals[0].value, label: self.materialModifiedIntervals[0].text};


        /**
         * Sort by options model
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @private
         * @type [Array]
         */
        self.updatedMaterialDaysModel = {id : self.materialModifiedIntervals[0].value, label: self.materialModifiedIntervals[0].text};

        /**
         * Button default text for films
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @private
         * @type [Array]
         */
        self.filmTranslations = {buttonDefaultText: self.translations.filter.labels.films};

        /**
         * Button default text for Asset types
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @private
         * @type [Array]
         */
        self.assetTypeTranslations = {buttonDefaultText: self.translations.filter.labels.assetTypes};

        /**
         * Button default text for sub types
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @private
         * @type [Array]
         */
        self.subTypeTranslations = {buttonDefaultText: self.translations.filter.labels.assetSubTypes};

        /**
         * Button default text for file types
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @private
         * @type [Array]
         */
        self.fileTypeTranslations = {buttonDefaultText: self.translations.filter.labels.fileTypes};



        /**
         * tab menus
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @public
         */
        self.tabs = [{
            heading: self.translations.whatsNew.labels.updatedFilmInfoTab,
            active: false,
            route: "updatedFilmsInfo",
            name: "updatedFilmsInfo",
            totalElements: 0
        }, {
            heading: self.translations.whatsNew.labels.newMaterialsTab,
            active: false,
            route: "newMaterials",
            name: "newMaterials",
            totalElements: 0
        }, {
            heading: self.translations.whatsNew.labels.updatedMaterialsTab,
            active: false,
            route: "updatedMaterials",
            name: "updatedMaterials",
            totalElements: 0
        }];

        /**
         * filter and sort options for title tab
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.titleTabFilterSortOptions = [];

        /**
         * filter and sort options for new materials tab
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.newMaterialTabsFilterSortOptions = [];

        /**
         * filter and sort options for new materials tab
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.materialTabsFilterSortOptions = [];
        /**
         * filter and sort options for new materials tab
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.newMaterialsTabFilterSortOptions = [];

        /**
         * filter and sort options for updated materials tab
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.updatedMaterialsTabFilterSortOptions = [];

        /**
         * film url generator
         * @type {filmUrlGeneratorFactory}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.filmUrlGenerator = filmUrlGeneratorFactory;

        /**
         * asset category convertor
         * @type {service}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.assetCategoryConvertor = assetCategoryConvertor;

        /**
         * Shopping cart service 
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @public
         * @type {service}
         */
        self.shoppingCartService = shoppingCartService;

        /**
         * Permission queue service
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @type {service}
         */
        self.permissionQueueService = permissionQueueService;

        /**
         * Filter sort options for filterContainerDirective new materials
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @public
         */
        self.materialsSort = null;
        /**
         * title tab filter sort options
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.titleTabFilterSortOptions = [{
            type: "select",
            options: self.filmModifiedIntervals,
            propertyName: "modifiedInterval"
        }, {
            type: "select",
            options: self.filmSortOptions,
            propertyName: "sort"
        }];

        /**
         * Active tab index
         * @type {int}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.activeTabIndex = 0;
        self.doNotShow = false;
        self.donotShowChecked = $cookies.get("donotShowChecked") || "";
        /**
         * set film url
         * @function setFilmUrl
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @private
         */
        var setFilmUrl = function () {
            var title = "";

            for (var i = 0, length = self.films._embedded.films.length; i < length; i++) {
                title = self.filmUrlGenerator(self.films._embedded.films[i].title);
                self.films._embedded.films[i].filmUrl = "/films/" + self.films._embedded.films[i].id + "/" + self.films._embedded.films[i].eisId + "/false/" + title + "#/film/activityHistory";
            }
        };

        /**
         * Clear selected state 
         * @function clearSelectedState
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        var clearSelectedState = function () {
            self.assets.content.forEach(function (asset) {
                asset.selected = false;
            });
        };

        /**
         * get tab count
         * @function setTabCount
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @private
         * @param {int} updatedFilmsDays past days for updated films
         * @param {int} newMaterialsDays past days for new materials
         * @param {String} filmId for new materials
         * @param {String} wbAssetType for new materials
         * @param {String} wbpiAssetType for new materials
         * @param {String} newMaterialsFileType for new materials
         * @param {int} updatedMaterialsDays past days for updated materials
         * @param {String} filmId for updated materials
         * @param {String} wbAssetType for updated materials
         * @param {String} wbpiAssetType for updated materials
         * @param {String} updatedMaterialsFileType for updated materials
         */
        var getTabCount = function (updatedFilmsDays, newMaterialsDays, newMaterialsFilmId, newMaterialsWbAssetType, newMaterialsWbpiAssetType, newMaterialsFileType, updatedMaterialsDays,
                updatedMaterialsFilmId, updatedMaterialsWbAssetType, updatedMaterialsWbpiAssetType, updatedMaterialsFileType) { 
            whatsNewService.getTabCount(updatedFilmsDays, newMaterialsDays, newMaterialsFilmId, newMaterialsWbAssetType, newMaterialsWbpiAssetType, newMaterialsFileType,
                    updatedMaterialsDays, updatedMaterialsFilmId, updatedMaterialsWbAssetType, updatedMaterialsWbpiAssetType, updatedMaterialsFileType,
                    self.translations.whatsNew.messages.cannotGetTabCounts).success(function (result) {
                        var totalCount = result.data.totalUpdatedFilmInfoCount + result.data.totalNewMaterialsCount + result.data.totalUpdatedMaterialsCount;
                        self.pageHeaderItem.pageTitle = self.translations.whatsNew.labels.pageTitle + " (" + totalCount + ")";
                        self.tabs.forEach(function (tab) {
                            switch (tab.name) {
                                case "updatedFilmsInfo":
                                    tab.totalElements = result.data.totalUpdatedFilmInfoCount;
                                    break;
                                case "newMaterials":
                                    tab.totalElements = result.data.totalNewMaterialsCount;
                                    break;
                                case "updatedMaterials":
                                    tab.totalElements = result.data.totalUpdatedMaterialsCount;
                                    break;
                            }
                        });
                    });
        };

         /**
         * reCalculateTabCounts
         * @function onTitleFilterChange
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @public
         * @param {object} api result
         * @param String tabName
         */
        self.reCalculateTabCounts = function (result, tabName) {
            var totalCount = 0;
            self.tabs.forEach(function (tab) {
                if(tab.name === tabName) {
                    tab.totalElements = result.totalElements;
                }
                totalCount += tab.totalElements;
            });
            self.pageHeaderItem.pageTitle = self.translations.whatsNew.labels.pageTitle + " (" + totalCount + ")";

        };


        /**
         * On title filter change
         * @function onTitleFilterChange
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @public
         * @param {object} selectedFilters selected filters
         */
        self.onTitleFilterChange = function (selectedFilters) {
            self.getTitles(selectedFilters);
            getTabCount(self.updatedFilmsFilterSort.modifiedInterval, self.newMaterialsFilterSort.modifiedInterval,
                    self.newMaterialsFilterSort.filmId, self.newMaterialsFilterSort.wbAssetType, self.newMaterialsFilterSort.wbpiAssetType, self.newMaterialsFilterSort.fileType,
                    self.updatedMaterialsFilterSort.modifiedInterval, self.updatedMaterialsFilterSort.filmId, self.updatedMaterialsFilterSort.wbAssetType,
                    self.updatedMaterialsFilterSort.wbpiAssetType, self.updatedMaterialsFilterSort.fileType);
        };

        /**
         * On new materials filter change
         * @function onNewMaterialFilterChange
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @public
         * @param {object} selectedFilters selected filters
         */
        self.onNewMaterialFilterChange = function () {
            if (daysModified !== self.newMaterialsFilterSort.modifiedInterval) {
                self.newMaterialsFilterSort.filmId = [];
                self.newMaterialsFilterSort.wbAssetType = [];
                self.newMaterialsFilterSort.wbpiAssetType = [];
                self.newMaterialsFilterSort.fileType = [];
            }
            getTabCount(self.updatedFilmsFilterSort.modifiedInterval, self.newMaterialsFilterSort.modifiedInterval,
                    self.newMaterialsFilterSort.filmId, self.newMaterialsFilterSort.wbAssetType, self.newMaterialsFilterSort.wbpiAssetType, self.newMaterialsFilterSort.fileType,
                    self.updatedMaterialsFilterSort.modifiedInterval, self.updatedMaterialsFilterSort.filmId, self.updatedMaterialsFilterSort.wbAssetType,
                    self.updatedMaterialsFilterSort.wbpiAssetType, self.updatedMaterialsFilterSort.fileType);

        };

        /**
         * On updated materials filter change
         * @function onUpdatedMaterialFilterChange
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @public
         * @param {object} selectedFilters selected filters
         */
        self.onUpdatedMaterialFilterChange = function () {
            if (daysModified !== self.updatedMaterialsFilterSort.modifiedInterval) {
                self.updatedMaterialsFilterSort.filmId = [];
                self.updatedMaterialsFilterSort.wbAssetType = [];
                self.updatedMaterialsFilterSort.wbpiAssetType = [];
                self.updatedMaterialsFilterSort.fileType = [];
            }
            getTabCount(self.updatedFilmsFilterSort.modifiedInterval, self.newMaterialsFilterSort.modifiedInterval,
                    self.newMaterialsFilterSort.filmId, self.newMaterialsFilterSort.wbAssetType, self.newMaterialsFilterSort.wbpiAssetType, self.newMaterialsFilterSort.fileType,
                    self.updatedMaterialsFilterSort.modifiedInterval, self.updatedMaterialsFilterSort.filmId, self.updatedMaterialsFilterSort.wbAssetType,
                    self.updatedMaterialsFilterSort.wbpiAssetType, self.updatedMaterialsFilterSort.fileType);
        };

        /**
         * Disabled bulk add to queue btn
         * @function disableBulkAddToQueueBtn
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @public
         * @returns {boolean}
         */
        self.disableBulkAddToQueueBtn = function () {
            var isSelected = false;
            if (self.assets && self.assets.content) {
                isSelected = _.findIndex(self.assets.content, {
                    selected: true
                }) >= 0;
            }
            return !isSelected;
        };

        /**
         * Bulk add to queue
         * @function bulkAddToQueue
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @public
         * @param {object[]} permissionQueueItems permission queue items
         */
        self.bulkAddToQueue = function (permissionQueueItems) {
            permissionQueueService.bulkAddToQueue(permissionQueueItems).success(function () {
                $rootScope.$emit("updatePermissionQueueCount", true);
                permissionQueueService.clearSelectedAssets();
                clearSelectedState();
            });
        };

        /**
         * Bulk add to cart
         * @function bulkAddToCart
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @public
         * @param {object[]} downloadItems download items
         */
        self.bulkAddToCart = function (downloadItems) {
            shoppingCartService.bulkAddToCart(downloadItems).success(function () {
                $rootScope.$emit("updateShoppingCartCount", true);
                shoppingCartService.clearSelectedAssets();
                clearSelectedState();
            });
        };

        /**
         * get titles
         * @function getTitles
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @param {obj} selectedFilters
         */
        self.getTitles = function (selectedFilters) {
            var errorMessage = self.translations.whatsNew.messages.getItemsError,
                secondarySort = "title",
                recType = user.role === "homeOfficeAdmin" || user.role === "territoryAdmin" ? "both" : "pub";

            selectedFilters = selectedFilters || {};
            self.updatedFilmsFilterSort.modifiedInterval = selectedFilters.modifiedInterval || self.updatedFilmsFilterSort.modifiedInterval;
            self.updatedFilmsFilterSort.sort = selectedFilters.sort || self.updatedFilmsFilterSort.sort;

            whatsNewService.getNewAndUpdatedTitles(self.updatedFilmsFilterSort.modifiedInterval, recType, self.films.page.number, self.films.page.size, self.updatedFilmsFilterSort.sort, secondarySort, errorMessage).success(function (result) {
                self.films = result;
                setFilmUrl();
                var filmObjs = {};
                filmObjs.films = result && result._embedded && result._embedded.films ? result._embedded.films : [];
                return filmRatingService.setFilmRatings(filmObjs).then(function (res) {
                    self.films._embedded.films = res.filmObjs["films"];
                });
            });
        };

        /**
         * get new assets
         * @function getAssets
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.getNewAssets = function (selectedFilters) {
            var errorMessage = self.translations.whatsNew.messages.getAssetsError,
                secondarySort = "filmTitle";
            selectedFilters = selectedFilters || {};
            self.newMaterialsFilterSort.modifiedInterval = selectedFilters.modifiedInterval || self.newMaterialsFilterSort.modifiedInterval;
            self.newMaterialsFilterSort.filmId = selectedFilters.filmId || self.newMaterialsFilterSort.filmId;
            self.newMaterialsFilterSort.wbAssetType = selectedFilters.wbAssetType || self.newMaterialsFilterSort.wbAssetType;
            self.newMaterialsFilterSort.wbpiAssetType = selectedFilters.wbpiAssetType || self.newMaterialsFilterSort.wbpiAssetType;
            self.newMaterialsFilterSort.fileType = selectedFilters.fileType || self.newMaterialsFilterSort.fileType;
            self.newMaterialsFilterSort.sort = selectedFilters.sort || self.newMaterialsFilterSort.sort;
            daysModified = self.newMaterialsFilterSort.modifiedInterval;
            whatsNewService.getNewAssetsForExhibitorsAndTheaters(self.newMaterialsFilterSort.modifiedInterval,
                    self.newMaterialsFilterSort.filmId, self.newMaterialsFilterSort.wbAssetType, self.newMaterialsFilterSort.wbpiAssetType, self.newMaterialsFilterSort.fileType,
                    self.assets.number, self.assets.size, self.newMaterialsFilterSort.sort, secondarySort, errorMessage).success(function (result) {
                        self.assets = result;
                        newAssets = result;
                        self.reCalculateTabCounts(result, "newMaterials");

                    });
            self.getFiltersValuesForNewMaterials();
            //daysModified = self.newMaterialsFilterSort.modifiedInterval;
            //self.onNewMaterialFilterChange();
        };

        /**
         * Get filters values for new materials
         * @function getFiltersValuesForNewMaterials
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.getFiltersValuesForNewMaterials = function () {
            var errorMessage = self.translations.whatsNew.messages.getItemsError;
            whatsNewService.getFiltersValuesForNewMaterials(self.newMaterialsFilterSort.modifiedInterval, self.newMaterialsFilterSort.filmId, self.newMaterialsFilterSort.wbAssetType, self.newMaterialsFilterSort.wbpiAssetType, self.newMaterialsFilterSort.fileType, errorMessage).success(function (result) {
                newAssetsFilters = result;
                self.materialTabsFilterSortOptions = [{
                    type: "select",
                    options: self.materialModifiedIntervals,
                    propertyName: "modifiedInterval"
                }, {
                    type: "select",
                    options: result.data.films,
                    propertyName: "filmId"
                }, {
                    type: "select",
                    options: result.data.assetTypes,
                    propertyName: "wbAssetType"
                }, {
                    type: "select",
                    options: result.data.assetSubtypes,
                    propertyName: "wbpiAssetType"
                } , {
                    type: "select",
                    options: result.data.fileTypes,
                    propertyName: "fileType"
                },
                {
                    type: "select",
                    options: result.data.filters,
                    propertyName: "filters"
                },
                {
                    type: "select",
                    options: self.newMaterialSortOptions,
                    propertyName: "sort"
                }];

                //Add default option
                self.materialTabsFilterSortOptions[1].options.unshift({
                    text: self.translations.filter.labels.allFilms,
                    value: "ALL",
                    disabled: true
                });
                self.materialTabsFilterSortOptions[2].options.unshift({
                    text: self.translations.filter.labels.allAssetTypes,
                    value: "ALL",
                    disabled: true
                });
                self.materialTabsFilterSortOptions[3].options.unshift({
                    text: self.translations.filter.labels.allAssetSubTypes,
                    value: "ALL",
                    disabled: true
                });
                self.materialTabsFilterSortOptions[4].options.unshift({
                    text: self.translations.filter.labels.allFileTypes,
                    value: "ALL",
                    disabled: true
                });
                self.allFilterValues = self.materialTabsFilterSortOptions[5].options;
                self.selectedTab = "newMaterials";
                self.filterOptions = self.getFilterOptions();
            });
        };

        /**
         * get updated assets
         * @function getAssets
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.getUpdatedAssets = function (selectedFilters) {
            var errorMessage = self.translations.whatsNew.messages.getAssetsError,
                secondarySort = "filmTitle";

            selectedFilters = selectedFilters || {};
            self.updatedMaterialsFilterSort.modifiedInterval = selectedFilters.modifiedInterval || self.updatedMaterialsFilterSort.modifiedInterval;
            self.updatedMaterialsFilterSort.filmId = selectedFilters.filmId || self.updatedMaterialsFilterSort.filmId;
            self.updatedMaterialsFilterSort.wbAssetType = selectedFilters.wbAssetType || self.updatedMaterialsFilterSort.wbAssetType;
            self.updatedMaterialsFilterSort.wbpiAssetType = selectedFilters.wbpiAssetType || self.updatedMaterialsFilterSort.wbpiAssetType;
            self.updatedMaterialsFilterSort.fileType = selectedFilters.fileType || self.updatedMaterialsFilterSort.fileType;
            self.updatedMaterialsFilterSort.sort = selectedFilters.sort || self.updatedMaterialsFilterSort.sort;
            daysModified = self.updatedMaterialsFilterSort.modifiedInterval;
            whatsNewService.getUpdatedAssetsForExhibitorsAndTheaters(self.updatedMaterialsFilterSort.modifiedInterval,
                    self.updatedMaterialsFilterSort.filmId, self.updatedMaterialsFilterSort.wbAssetType, self.updatedMaterialsFilterSort.wbpiAssetType,  self.updatedMaterialsFilterSort.fileType,
                    self.assets.number, self.assets.size, self.updatedMaterialsFilterSort.sort, secondarySort, errorMessage).success(function (result) {
                        self.assets = result;
                        updatedAssets = result;
                        self.reCalculateTabCounts(result, "updatedMaterials");

                    });
            self.getFiltersValuesForUpdatedMaterials();
            //self.onUpdatedMaterialFilterChange();
        };

        /**
         * Get filters values for updated materials
         * @function getFiltersValuesForUpdatedMaterials
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.getFiltersValuesForUpdatedMaterials = function () {
            var errorMessage = self.translations.whatsNew.messages.getItemsError;
            whatsNewService.getFiltersValuesForUpdatedMaterials(self.updatedMaterialsFilterSort.modifiedInterval, self.updatedMaterialsFilterSort.filmId, self.updatedMaterialsFilterSort.wbAssetType, self.updatedMaterialsFilterSort.wbpiAssetType, self.updatedMaterialsFilterSort.fileType, errorMessage).success(function (result) {
                updatedAssetsFilters = result;
                self.materialTabsFilterSortOptions = [{
                    type: "select",
                    options: self.materialModifiedIntervals,
                    propertyName: "modifiedInterval"
                }, {
                    type: "select",
                    options: result.data.films,
                    propertyName: "filmId"
                }, {
                    type: "select",
                    options: result.data.assetTypes,
                    propertyName: "wbAssetType"
                }, {
                    type: "select",
                    options: result.data.assetSubtypes,
                    propertyName: "wbpiAssetType"
                }, {
                    type: "select",
                    options: result.data.fileTypes,
                    propertyName: "fileType"
                }, {
                    type: "select",
                    options: result.data.filters,
                    propertyName: "filters"
                }, {
                    type: "select",
                    options: self.newMaterialSortOptions,
                    propertyName: "sort"
                }];

                //Add default option
                self.materialTabsFilterSortOptions[1].options.unshift({
                    text: self.translations.filter.labels.allFilms,
                    value: "ALL",
                    disabled: true
                });
                self.materialTabsFilterSortOptions[2].options.unshift({
                    text: self.translations.filter.labels.allAssetTypes,
                    value: "ALL",
                    disabled: true
                });
                self.materialTabsFilterSortOptions[3].options.unshift({
                    text: self.translations.filter.labels.allAssetSubTypes,
                    value: "ALL",
                    disabled: true
                });
                self.materialTabsFilterSortOptions[4].options.unshift({
                    text: self.translations.filter.labels.allFileTypes,
                    value: "ALL",
                    disabled: true
                });
                self.allFilterValues = self.materialTabsFilterSortOptions[5].options;
                self.selectedTab = "updatedMaterials";
                self.filterOptions = self.getFilterOptions();
            });
        };


         /**
         * Get Stored Filtered Values
         * @function getFiltersValuesForUpdatedMaterials
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.getStoredFiltersValues = function (result) {
            var tabChange = true;
            self.materialTabsFilterSortOptions = [{
                type: "select",
                options: self.materialModifiedIntervals,
                propertyName: "modifiedInterval"
            }, {
                type: "select",
                options: result.data.films,
                propertyName: "filmId"
            }, {
                type: "select",
                options: result.data.assetTypes,
                propertyName: "wbAssetType"
            }, {
                type: "select",
                options: result.data.assetSubtypes,
                propertyName: "wbpiAssetType"
            }, {
                type: "select",
                options: result.data.fileTypes,
                propertyName: "fileType"
            }, {
                type: "select",
                options: result.data.filters,
                propertyName: "filters"
            }, {
                type: "select",
                options: self.newMaterialSortOptions,
                propertyName: "sort"
            }];
            self.allFilterValues = self.materialTabsFilterSortOptions[5].options;
            self.filterOptions = self.getFilterOptions(tabChange);

        };


        /**
         * Get FilterOptions Object by setting page Data objects.
         * @function unpermissionedMaterialsSelectAll
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.getFilterOptions = function (tabChange) {
            var multiSelectOptions = [
                {
                    type : "static",
                    options: self.materialModifiedIntervals,
                    model:self.selectedTab === "newMaterials" ? self.newMaterialDaysModel :self.updatedMaterialDaysModel,
                    translations:self.materialModifiedIntervals[0].text
                },
                {
                    type : "films",
                    id:"filmId",
                    value:"title",
                    translations:self.filmTranslations
                },
                {
                    type : "assetTypes",
                    id:"wbAssetType",
                    translations:self.assetTypeTranslations
                },
                {
                    type : "subTypes",
                    id:"wbpiAssetType",
                    translations:self.subTypeTranslations
                },
                {
                    type : "fileTypes",
                    id:"fileType",
                    translations:self.fileTypeTranslations
                },
                {
                    type : "sort",
                    options: self.newMaterialSortOptions,
                    model: self.selectedTab === "newMaterials" ? self.newMaterialSortModel:self.updatedMaterialSortModel,
                    translations:self.sortTypeTranslations
                }];

            for (var filterIndex = 0; filterIndex < multiSelectOptions.length; filterIndex++) {
                if (multiSelectOptions[filterIndex].type !== "sort" && multiSelectOptions[filterIndex].type !== "modifiedInterval") {
                    multiSelectOptions[filterIndex].data = self.materialTabsFilterSortOptions[filterIndex];
                    multiSelectOptions[filterIndex].activeTabIndex = self.activeTabIndex;
                    if (tabChange) {
                        multiSelectOptions[filterIndex].tabChange = tabChange;
                    }
                }
            }
            /*return _.filter(multiSelectOptions, function(item) {
                return item.type !== "modifiedInterval";
            });*/
            return multiSelectOptions;
        };

        /**
         * Get unpublished materials
         * @function newMaterialsSelectAll
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.newMaterialsSelectAll = function () {
            if (!self.newMaterialsFilterSort.retainStaticFilters) {
                self.initNewMaterialsStaticFilters();
                self.newMaterialsFilterSort.sort = "permissionDate,desc";
                self.newMaterialsFilterSort.modifiedInterval = 3;
            }
            self.newMaterialsFilterSort.filmId = [];
            self.newMaterialsFilterSort.wbAssetType = [];
            self.newMaterialsFilterSort.wbpiAssetType = [];
            self.newMaterialsFilterSort.fileType = [];
            self.getNewAssets();
        };

        /**
         * Get unpublished materials
         * @function updatedMaterialsSelectAll
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.updatedMaterialsSelectAll = function () {
            if (!self.updatedMaterialsFilterSort.retainStaticFilters) {
                self.initUpdatedMaterialsStaticFilters();
                self.updatedMaterialsFilterSort.sort = "permissionDate,desc";
                self.updatedMaterialsFilterSort.modifiedInterval = 3;
            }
            self.updatedMaterialsFilterSort.filmId = [];
            self.updatedMaterialsFilterSort.wbAssetType = [];
            self.updatedMaterialsFilterSort.wbpiAssetType = [];
            self.updatedMaterialsFilterSort.fileType = [];
            self.getUpdatedAssets();
        };

         /**
         * Reset All Static Filter Models
         * @function getUnpermissionedMaterials
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.initNewMaterialsStaticFilters = function () {
            self.newMaterialSortModel = {id : self.newMaterialSortOptions[0].value, label: self.newMaterialSortOptions[0].text};
            self.newMaterialDaysModel = {id : self.materialModifiedIntervals[0].value, label: self.materialModifiedIntervals[0].text};
        };

        /**
         * Reset All Static Filter Models
         * @function getUnpermissionedMaterials
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.initUpdatedMaterialsStaticFilters = function () {
            self.updatedMaterialSortModel= {id : self.newMaterialSortOptions[0].value, label: self.newMaterialSortOptions[0].text};
            self.updatedMaterialDaysModel = {id : self.materialModifiedIntervals[0].value, label: self.materialModifiedIntervals[0].text};
        };

        /**
         * Open lightbox
         * @function openLightBox
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @param {object} $event browser event
         * @param {object} asset asset
         * @param {string} action action
         */
        self.openLightBox = function ($event, asset, action) {
            self.openLightbox = true;
            self.lightboxAsset = asset;
            self.lightboxAction = action;
            if (self.activeTabIndex === 2) {
                self.lightboxActiveTab = "activityHistory";
            }
        };

        /**
         * Go to tab
         * @function go
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         * @param {string} route route to go
         */
        self.go = function (tab) {
            self.onTabChange(tab.name);
            $state.transitionTo(tab.route);
        };

        /**
         * On tab change
         * @function onTabChange
         * @public
         * @param {string} name tab name
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.onTabChange = function (name) {
            var isFilmsDataLoaded = self.films && self.films._embedded && self.films._embedded.films,
                isNewAssetsDataLoaded = newAssets && newAssets.content,
                isUpdatedAssetsDataLoaded = updatedAssets && updatedAssets.content;

            switch (name) {
                case "updatedFilmsInfo":
                    self.activeTabIndex = 0;
                    if (!isFilmsDataLoaded) {
                        self.updatedFilmsFilterSort = _.clone(defaultFilterSort);
                        self.getTitles();
                    }
                    break;
                case "newMaterials":
                    self.activeTabIndex = 1;
                    self.selectedTab = name;
                    if (isNewAssetsDataLoaded) {
                        self.assets = _.cloneDeep(newAssets);
                        self.getStoredFiltersValues(newAssetsFilters);
                    } else {
                        self.newMaterialsFilterSort = {
                            filmId: [],
                            sort: "permissionDate,desc",
                            wbAssetType: [],
                            wbpiAssetType: [],
                            fileType: [],
                            modifiedInterval: 3,
                            page:"newMaterials",
                            staticFilter:"modifiedInterval",
                            tabCount : 3
                        };
                        daysModified = self.newMaterialsFilterSort.modifiedInterval;
                        self.initNewMaterialsStaticFilters();
                        self.getNewAssets();
                    }
                    break;
                case "updatedMaterials":
                    self.activeTabIndex = 2;
                    self.selectedTab = name;
                    if (isUpdatedAssetsDataLoaded) {
                        self.assets = _.cloneDeep(updatedAssets);
                        self.getStoredFiltersValues(updatedAssetsFilters);
                    } else {
                        self.updatedMaterialsFilterSort = {
                            filmId: [],
                            sort: "permissionDate,desc",
                            wbAssetType: [],
                            wbpiAssetType: [],
                            fileType: [],
                            modifiedInterval: 3,
                            page:"updatedMaterials",
                            staticFilter:"modifiedInterval",
                            tabCount : 3
                        };
                        daysModified = self.updatedMaterialsFilterSort.modifiedInterval;
                        self.initUpdatedMaterialsStaticFilters();
                        self.getUpdatedAssets();
                    }
                    break;
            }
        };
        self.doNotShowCheck =function(){
            if (self.doNotShow) {
                var now = new Date(),
                    in30Days = new Date(now.setDate(now.getDate() + 30));

                $cookies.put("donotShowChecked", self.doNotShow, {
                    sameSite: "strict",
                    secure: SECURE_COOKIES,
                    expires: in30Days,
                    domain: DOMAIN
                });
            } else {
                $cookies.remove("donotShowChecked", {domain: DOMAIN, path: "/" });
            }
            self.donotShowChecked = $cookies.get("donotShowChecked") || "";
        };
        $rootScope.$on("assetTypeUpdated", function () {
            self.init();
            self.getNewAssets();
            self.getUpdatedAssets();
        });
        /**
         * Init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.whatsNewController
         */
        self.init = function () {
            var path = $location.$$path,
                pathWithoutQueryParam = path.substring(1).split("?"),
                route = pathWithoutQueryParam[0].replace(/\//g, ".");
            if (originalPath !== null && (originalPath.split("/").length > 1)) {
                self.onTabChange(route);
                originalPath = null;
            }
            getTabCount(defaultFilterSort.modifiedInterval, self.newMaterialsFilterSort.modifiedInterval,
                    self.newMaterialsFilterSort.filmId, self.newMaterialsFilterSort.wbAssetType, self.newMaterialsFilterSort.wbpiAssetType, self.newMaterialsFilterSort.fileType,
                    self.updatedMaterialsFilterSort.modifiedInterval, self.updatedMaterialsFilterSort.filmId, self.updatedMaterialsFilterSort.wbAssetType,
                    self.updatedMaterialsFilterSort.wbpiAssetType, self.updatedMaterialsFilterSort.fileType);
        };

        self.init();
        setTimeout(function () {
            $(".whatsNew").on("click",function(){
                self.activeTabIndex = 0;
            });
        },1000);

    }

    controllers.controller("whatsNewController", ["$rootScope", "$scope", "$location", "$cookies", "whatsNewService", "localStorageService", "filmRatingService", "filmUrlGeneratorFactory", "API_EFD", "DEFAULT_PAGE_SIZE", "permissionQueueService", "shoppingCartService", "SECURE_COOKIES", "DOMAIN", "assetCategoryConvertor", "$state", "AUDIO_VIDEO_ASSET_CATEGORY", whatsNewController]);
}(window.jQuery));
