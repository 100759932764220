(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Pagination Size directive
     * @class paginationSize
     * @memberOf ngular_module.WB.cinehub.directives
     * @example <pagination-size page-size="{?Array}"></pagination-size>
     * @returns {object} directive definition object
     * @param {service} localStorageService local storage service
     * @param {object[]} PAGE_SIZE_LIST page size list constant
     */
    var paginationSize = function (localStorageService, PAGE_SIZE_LIST) {

        /**
         * Default Page Sizes
         * @memberOf ngular_module.WB.cinehub.directives.paginationSize
         * @var pageSizes
         * @type {Array}
         * @private
         */
        var pageSizes = PAGE_SIZE_LIST;

        /**
         * Directive link function
         * @function link
         * @memberOf ngular_module.WB.cinehub.directives.paginationSize
         * @param  {!angular.Scope} scope angular scope
         * @private
         */
        var link = function (scope) {

            /**
             * translations
             * @type {boolean}
             * @memberOf angular_module.WB.cinehub.directives.paginationSize
             * @public
             */
            scope.translations = localStorageService.get("translations");

            /**
             * Page Size - accepts optional page size
             * @memberOf ngular_module.WB.cinehub.directives.paginationSize
             * @public
             * @var sizes
             * @type {Array}
             */
            scope.sizes = scope.pageSizes || pageSizes;

            /**
             * Change
             * @function change
             * @public
             * @memberOf ngular_module.WB.cinehub.directives.paginationSize
             */
            scope.change = function () {
                setTimeout(function () {
                    scope.ngChange();
                }, 0);
            };
        };

        return {
            restrict: "EA",
            require: "ngModel",
            scope: {
                model: "=ngModel",
                ngChange: "&",
                pageSizes: "="
            },
            template: "{{translations.pagination.labels.show}} <select ng-model=\"model\" ng-options=\"size for size in sizes\" ng-change=\"change()\"></select> {{translations.pagination.labels.perPage}}",
            link: link
        };
    };

    directives.directive("paginationSize", ["localStorageService", "PAGE_SIZE_LIST", paginationSize]);
} ());
