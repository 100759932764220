(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class footerService
     * @memberOf angular_module.WB.cinehub.services
     * @desc asset service
     * @param {service} localStorageService local storage service 
     * @param {service} $q angular q service
     * @param {service} territoriesService territories service 
     * @param {service} $window $window service
     */
    function footerService(localStorageService, $q, territoriesService, emailService, DEFAULT_CONTACT_US_EMAIL) {

        /**
         * Promise 
         * @type {promise}
         * @private
         * @memberOf angular_module.WB.cinehub.services.footerService
         */
        var promise = $q.defer();

        /**
         * Flash exhibitor and theater redis cache
         * @memberOf angular_module.WB.cinehub.services.footerService
         * @public
         * @function getHelpContact
         * @returns {promise}
         */
        this.getHelpContactEmail = function () {
            var userInfo = localStorageService.get("userInfo");
            territoriesService.getTerritoryById(userInfo.territoryId).success(function (result) {
                var territoryContactEmail = result._embedded.territories[0].contactInfo;
                var contactEmail = emailService.isValidEmail(territoryContactEmail) ? territoryContactEmail: DEFAULT_CONTACT_US_EMAIL;
                if (contactEmail) {
                    promise.resolve(contactEmail);
                } else {
                    promise.resolve(DEFAULT_CONTACT_US_EMAIL);
                }

            }).error(function () {
                promise.resolve(DEFAULT_CONTACT_US_EMAIL);
            });
            return promise.promise;
        };
    }

    services.service("footerService", ["localStorageService", "$q", "territoriesService", "emailService", "DEFAULT_CONTACT_US_EMAIL", footerService]);
}());
