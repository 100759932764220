(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class catalogService
     * @memberOf angular_module.WB.cinehub.services
     * @desc catalog service
     * @param {!angular.$http} $http angular $http service
     * @param {strict} API_ROOT API_ROOT constant  
     * @param {service} localStorageService local storage service
     */
    function catalogService($http, API_ROOT, localStorageService) {

        /**
         * translations
         * @type {object}
         * @memberOf angular_module.WB.cinehub.services.catalogService
         * @private
         */
        var translations = localStorageService.get("translations");

        /**
         * get all films
         * @memberOf angular_module.WB.cinehub.services.catalogService
         * @public
         * @function getAllFilms
         * @param {int} past days
         * @param {int} size size
         * @param {int} page page
         * @param {string} sort sort order
         * @param {string} secondarySort secondary sort
         * @returns {promise}
         */
        this.getAllFilms = function (pastDays, page, size, sort, secondarySort) {
            var sortString = secondarySort ? "&sort=" + sort + "&sort=" + secondarySort : "&sort=" + sort;
            return $http.get(API_ROOT + "films/search/findLocAllByLastModified?daysLastModified=" + pastDays + "&page=" + page + "&size=" + size + sortString, {
                errorMessage: translations.catalog.messages.getCatalogError
            });
        };

        /**
         * get all films for hoa
         * @memberOf angular_module.WB.cinehub.services.catalogService
         * @public
         * @function getAllFilmsHoa
         * @param {int} size size
         * @param {int} page page
         * @param {string} sort sort order
         * @returns {promise}
         */
        this.getAllFilmsHoa = function (page, size, sort) {
            return $http.get(API_ROOT + "films/search/findLocAllForHoa?" + "&page=" + page + "&size=" + size + "&ignoreLocalizationString" + "&sort=" + sort, {
                errorMessage: translations.catalog.messages.getCatalogError
            });
        };

        /**
         * get upcoming films
         * @memberOf angular_module.WB.cinehub.services.catalogService
         * @public
         * @function getUpcomingFilms
         * @param {int} past days
         * @param {int} size size
         * @param {int} page page
         * @param {string} sort sort order
         * @param {string} secondarySort secondary sort
         * @returns {promise}
         */
        this.getUpcomingFilms = function (pastDays, page, size, sort, secondarySort) {
            var sortString = secondarySort ? "&sort=" + sort + "&sort=" + secondarySort : "&sort=" + sort;
            return $http.get(API_ROOT + "films/search/findLocFilmsComingSoonByLastModified?daysLastModified=" + pastDays + "&page=" + page + "&size=" + size + sortString, {
                errorMessage: translations.catalog.messages.getCatalogError
            });
        };

        /**
         * get in theater films
         * @memberOf angular_module.WB.cinehub.services.catalogService
         * @public
         * @function getInTheaterFilms
         * @param {int} past days
         * @param {int} size size
         * @param {int} page page
         * @param {string} sort sort order
         * @param {string} secondarySort secondary sort
         * @returns {promise}
         */
        this.getInTheaterFilms = function (pastDays, page, size, sort, secondarySort) {
            var sortString = secondarySort ? "&sort=" + sort + "&sort=" + secondarySort : "&sort=" + sort;
            return $http.get(API_ROOT + "films/search/findLocFilmsInTheatersByLastModified?daysLastModified=" + pastDays + "&page=" + page + "&size=" + size + sortString, {
                errorMessage: translations.catalog.messages.getCatalogError
            });
        };
    }

    services.service("catalogService", ["$http", "API_ROOT", "localStorageService", catalogService]);
}());
