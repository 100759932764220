(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * New list controller
     * @memberOf angular_module.WB.cinehub.controllers
     * @class newsListController
     * @param {!angular.$scope} $scope angular $scope
     * @desc This news list controller
     * @param {$uibModal} $uibModal modal instance
     * @param {service} newsListService news list service
     * @param {service} localStorageService local storage service
     * @param {constant} API_EFD API_EFD
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     * @param {service} deviceDetectorService device detector service
     * @param {factory} filmUrlGeneratorFactory film url generator factory
     */
    function newsListController($rootScope, $scope, $uibModal, newsListService, localStorageService, API_EFD, DEFAULT_PAGE_SIZE, deviceDetectorService, filmUrlGeneratorFactory) {

        /**
         * Instance of newsListController
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsListController
         * @type {newsListController}
         */
        var self = this;

        /**
         * translations
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.newsListController
         */
        self.translations = localStorageService.get("translations");

        /**
         * impersonate user flag
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsListController
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");

        /**
         * Device detector service
         * @public
         * @type {service}
         * @memberOf angular_module.WB.cinehub.controllers.newsListController
         */
        self.deviceDetectorService = deviceDetectorService;

        /**
         * API_EFD
         * @type {constant}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsDetailsController
         */
        self.API_EFD = API_EFD;

        /**
         * sorting options
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsListController
         */
        self.sortOptions = [{
            value: "lastModified,desc",
            text: self.translations.sort.labels.postDateNewestToOldest
        }, {
            value: "lastModified,asc",
            text: self.translations.sort.labels.postDateOldestToNewest
        }, {
            value: "filmTitle,asc",
            text: self.translations.sort.labels.titleNameAToZ
        }, {
            value: "filmTitle,desc",
            text: self.translations.sort.labels.titleNameZToA
        }, {
            value: "filmReleaseDate,desc",
            text: self.translations.sort.labels.releaseDateNewestToOldest
        }, {
            value: "filmReleaseDate,asc",
            text: self.translations.sort.labels.releaseDateOldestToNewest
        }];

        /**
         * modified interval
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsListController
         */
        self.modifiedIntervals = [{
            value: 1000,
            text: self.translations.filter.labels.allDays
        }, {
            value: 3,
            text: self.translations.filter.labels.modifiedWithin3Days
        }, {
            value: 7,
            text: self.translations.filter.labels.modifiedWithin7Days
        }, {
            value: 14,
            text: self.translations.filter.labels.modifiedWithin2Weeks
        }];

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.newsDetailsController
         */
        self.pageHeaderItem = {
            pageTitle: self.translations.newsList.labels.pageTitle,
            breadcrumbItems: [{
                linkTitle: self.translations.newsList.labels.breadcrumbLinkTitle,
                link: "/news-list"
            }]
        };

        /**
         * default current page
         * @type {int}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsListController
         */
        self.currentPage = 1;

        /**
         * page size
         * @type {int}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsListController
         */
        self.pageSize = DEFAULT_PAGE_SIZE;

        /**
         * filter sort options
         * @public
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.newsListController
         */
        self.filterSortOptions = [];

        /**
         * Film url generator
         * @public
         * @type {factory}
         * @memberOf angular_module.WB.cinehub.controllers.newsListController
         */
        self.filmUrlGenerator = filmUrlGeneratorFactory;

        /*
         * Get film titles
         * @function getFilmTitles
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.newsListController
         */
        var getFilmTitles = function () {
            var errorMessage = self.translations.newsList.messages.getFilmTitlesError;

            newsListService.getFilmTitles(errorMessage).success(function (result) {
                var filterOptions = [];
                filterOptions.push({
                    value: "",
                    text: self.translations.filter.labels.allNews
                });
                result._embedded.films.forEach(function (obj) {
                    filterOptions.push({
                        value: obj.id,
                        text: obj.akaTitle === "generalNews" ? self.translations.global.labels.generalNews : obj.title
                    });
                });
                self.filterSortOptions.push({
                    type: "select",
                    options: filterOptions,
                    propertyName: "filmId"
                }, {
                    type: "select",
                    options: self.modifiedIntervals,
                    propertyName: "modifiedInterval"
                }, {
                    type: "select",
                    options: self.sortOptions,
                    propertyName: "sort"
                });
            });
        };

        /*
         * Get news items
         * @function getNewsItems
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsListController
         * @param {object} selectedFilters selected filters
         */
        self.getNewsItems = function (selectedFilters) {
            var errorMessage = self.translations.newsList.messages.getNewsItemsError,
                filters = selectedFilters || {
                    modifiedInterval: 1000,
                    filmId: "",
                    sort: "lastModified,desc"
                },
                secondarySort = "title,asc";

            if (filters.modifiedInterval === "") {
                filters.modifiedInterval = 1000;
            }

            newsListService.getNewsItems(filters.filmId, filters.modifiedInterval, self.pageSize, self.currentPage, filters.sort, secondarySort, errorMessage).success(function (result) {
                self.newsItems = result;
            });
        };

        /**
         * Open add edit news modal
         * @function openAddEditNewsModal
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsListController
         */
        self.openAddNewsModal = function () {
            var modalInstance = $uibModal.open({
                templateUrl: "/modules/addEditNewsModal/modalAddEditNews.html",
                controller: "modalAddEditNewsController",
                controllerAs: "addEditNewsModalCtrl",
                keyboard: false,
                backdrop: "static",
                size: "lg",
                resolve: {
                    newsId: function () {
                        return null;
                    },
                    film: function () {
                        return null;
                    }
                }
            });

            modalInstance.result.then(function () {
                self.init();
            });
        };

        /**
         * Init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsListController
         */
        self.init = function () {
            getFilmTitles();
            self.getNewsItems();
        };

        //INIT
        self.init();
    }

    controllers.controller("newsListController", ["$rootScope", "$scope", "$uibModal", "newsListService", "localStorageService", "API_EFD", "DEFAULT_PAGE_SIZE", "deviceDetectorService", "filmUrlGeneratorFactory", newsListController]);
}());
