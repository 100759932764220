(function() {
    "use strict";
    var filters = angular.module("WB.cinehub.filters");

    /**
     * Page size filter for pagination directive 
     * @class maxPageSize
     * @memberOf angular_module.WB.cinehub.filters
     * @desc This max page size filter to set pagination size based on screen dementions
     * @returns {string}
     */
    function maxPageSize() {

        return function() {

            var maxSize = 7,
                windowWidth = "innerWidth" in window ? window.innerWidth : document.documentElement.offsetWidth;

            // Change maxSize based on window width
            if (windowWidth > 1320) {
                maxSize = 7;
            } else if (windowWidth > 320) {
                maxSize = 2;
            } else if (windowWidth > 100) {
                maxSize = 1;
            } 

            return maxSize;
        };
    }

    filters.filter("maxpagesize", [maxPageSize]);

}());