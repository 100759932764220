(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Film controller
     * @dec The film controller
     * @class angular_module.WB.cinehub.controllers.filmMaterialsController
     * @param {!angular.$rootScope} rootScope angular root scope
     * @param {!angular.Scope} $scope angular scope
     * @param {!angular.$state} $state angular state service
     * @param {service} filmService film service
     * @param {service} shoppingCartService add remove asset cart serivice
     * @param {service} permissionQueueService permission queue serivice
     * @param {constant} API_EFD API_EFD
     * @param {service} localStorageService local storage service
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     * @param {service} deviceDetectorService device detector service
     * @param {service} assetCategoryConvertor asset category convertor
     * @param {constant} AUDIO_VIDEO_ASSET_CATEGORY AUDIO_VIDEO_ASSET_CATEGORY
     * @todo Create a materialsService
     */
    function filmMaterialsController($cookies,$rootScope, $scope, $state, $location,  $anchorScroll, filmService, shoppingCartService, permissionQueueService, API_EFD, localStorageService, DEFAULT_PAGE_SIZE, deviceDetectorService, assetCategoryConvertor, AUDIO_VIDEO_ASSET_CATEGORY) {

        /**
         * Instance to filmMaterialsController
         * @type {filmMaterialsController}
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @private
         */
        var self = this,

            /**
             * Film id
             * @type {int}
             * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
             * @private
             */
            filmId = window.WB.cinehub.preload.filmId,

             /**
             * Eis id
             * @type {int}
             * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
             * @private
             */
            eisId = window.WB.cinehub.preload.eisId,

            /**
             * User info
             * @type {object}
             * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
             * @private
             */
            user = localStorageService.get("userInfo");

        /**
         * Translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         */
        self.translations = localStorageService.get("translations");

        /**
         * view As
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @public
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");

        /**
         * API_EFD
         * @constant
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @public
         * @type {string}
         */
        self.API_EFD = API_EFD;

        /**
         * Device detector service
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @public
         * @type {service}
         */
        self.deviceDetectorService = deviceDetectorService;

        /**
         * Asset category convertor service
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @public
         * @type {service}
         */
        self.assetCategoryConvertor = assetCategoryConvertor;

        /**
         * Materials tabs definition
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         */
        self.materialsTabs = [{
            heading: self.translations.filmDetails.labels.artworkMaterialsTab,
            route: "film.materials.artwork",
            defaultHeading: "artwork",
            count: 0
        }, {
            heading: self.translations.filmDetails.labels.audioVideoMaterialsTab,
            route: "film.materials.audioVideo",
            defaultHeading: "audioVideo",
            count: 0
        }, {
            heading: self.translations.filmDetails.labels.writtenMaterialsTab,
            route: "film.materials.written",
            defaultHeading: "written",
            count: 0
        }, {
            heading: self.translations.filmDetails.labels.otherMaterialsTab,
            route: "film.materials.other",
            defaultHeading: "other",
            count: 0
        }];

        /**
         * filter options
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         */
        self.materialsFilterSortOptions = [];

        /**
         * AUDIO_VIDEO_ASSET_CATEGORY
         * @constant
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @private
         * @type {string}
         */
        self.AUDIO_VIDEO_ASSET_CATEGORY = AUDIO_VIDEO_ASSET_CATEGORY;

        /**
         * filters 
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         */
        self.filterSort = {
            wbAssetType: [],
            wbpiAssetType: [],
            fileType: [],
            isHOAView: $scope.$parent.filmCtrl.isSourceView ? true : undefined,
            showAll: "false",
            sort: "lastModified,desc"
        };

        /**
         * add asset to cart service
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @public
         * @type {shoppingCartService}
         */
        self.shoppingCartService = shoppingCartService;

        /**
         * Permission queue service
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @public
         * @type {service}
         */
        self.permissionQueueService = permissionQueueService;

        /**
         * Film assets
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         */
        self.filmAssets = {
            data: {
                filmAssetDownloadView: {
                    size: DEFAULT_PAGE_SIZE,
                    number: 1
                }
            }
        };

        /**
         * material sorting options
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.materialsSort = [{
            value: "lastModified,id,desc",
            text: self.translations.sort.labels.newestToOldest
        }, {
            value: "lastModified,id,asc",
            text: self.translations.sort.labels.oldestToNewest
        }];


        /**
         * Multi Select Filter Options & Models.
         * Holds all filter values
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type [Array]
         */
        self.allFilterValues = [];

        /**
         * Multi Select Filter Options & Models.
         * Holds all he filter options
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type [Array]
         */
        self.filterOptions = [];

        /**
         * Multi Select Filter Options & Models.
         * Sort Model - Holds Filter selections
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type [Array]
         */
        self.sortModel = {id : self.materialsSort[0].value, label: self.materialsSort[0].text};

        /**
         * Multi Select Filter Options & Models.
         * Default button text for films
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type [Array]
         */
        self.filmTranslations = {buttonDefaultText: self.translations.filter.labels.films};

        /**
         * Multi Select Filter Options & Models.
         * Default button text for asset types
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type [Array]
         */
        self.assetTypeTranslations = {buttonDefaultText: self.translations.filter.labels.assetTypes};

        /**
         * Multi Select Filter Options & Models.
         * Default button text for sub types
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type [Array]
         */
        self.subTypeTranslations = {buttonDefaultText: self.translations.filter.labels.assetSubTypes};

        /**
         * Multi Select Filter Options & Models.
         * Default button text for file types
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type [Array]
         */
        self.fileTypeTranslations = {buttonDefaultText: self.translations.filter.labels.fileTypes};

        /**
         * Multi Select Filter Options & Models.
         * Default button text for Sort
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         * @private
         * @type [Array]
         */
        self.sortTypeTranslations = {buttonDefaultText: "Sort By"};

        /**
         * is hoa view 
         * @type {boolean}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         */
        self.isHOAView = $scope.$parent.filmCtrl.isSourceView;
        self.donotShowChecked = $cookies.get("donotShowChecked") || "";

        /**
         * Clear selected state
         * @function clearSelectedState
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         */
        var clearSelectedState = function () {
            self.filmAssets.data.filmAssetDownloadView.content.forEach(function (asset) {
                asset.selected = false;
            });
        };

        /** Init filters
         * @function initFilters
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         */
        var initFilters = function () {
            var getAssetTypeAndSubtypeByTypeAndBySubtypeServiceCallName = self.filterSort.isHOAView ? "findLocAssetTypeAndSubtypeByTypeAndBySubtypeForHoa" : "getAssetTypeAndSubtypeByTypeAndBySubtype";
            var filmKeyId = self.filterSort.isHOAView ? eisId : filmId;
            return filmService[getAssetTypeAndSubtypeByTypeAndBySubtypeServiceCallName](filmKeyId, self.assetCategory, self.filterSort.wbAssetType, self.filterSort.wbpiAssetType, self.filterSort.fileType, "pub", self.filterSort.showAll).success(function (result) {
                self.materialsFilterSortOptions = [{
                    type: "select",
                    options: result.data.assetTypes,
                    propertyName: "wbAssetType"
                }, {
                    type: "select",
                    options: result.data.assetSubtypes,
                    propertyName: "wbpiAssetType"
                }, {
                    type: "select",
                    options: result.data.fileTypes,
                    propertyName: "fileType"
                }, {
                    type: "select",
                    options: result.data.filters,
                    propertyName: "filters"
                }];

                if (!self.filterSort.isHOAView) {
                    self.materialsFilterSortOptions.push({
                        type: "sort",
                        options: self.materialsSort,
                        model:self.sortModel,
                        translations:self.sortTypeTranslations
                    });
                }

                if (user.role === "homeOfficeAdmin") {
                    self.materialsFilterSortOptions.push({
                        type: "checkbox",
                        options: [{
                            value: undefined,
                            text: self.translations.global.labels.hoaView
                        }],
                        propertyName: "isHOAView",
                        disabled: $scope.$parent.filmCtrl.isSourceView
                    });
                    self.materialsFilterSortOptions.push({
                        type: "checkbox",
                        options: [{
                            value: undefined,
                            text: "Show All"
                        }],
                        propertyName: "showAll"
                    });
                }

                if (user.role === "territoryAdmin") {
                    self.materialsFilterSortOptions.push({
                        type: "checkbox",
                        options: [{
                            value: undefined,
                            text: "Show All"
                        }],
                        propertyName: "showAll"
                    });
                }

                //Add default option
                self.materialsFilterSortOptions[0].options.unshift({
                    text: self.translations.filter.labels.allAssetTypes,
                    value: "ALL",
                    disabled: true
                });
                self.materialsFilterSortOptions[1].options.unshift({
                    text: self.translations.filter.labels.allAssetSubTypes,
                    value: "ALL",
                    disabled: true
                });
                self.materialsFilterSortOptions[2].options.unshift({
                    text: self.translations.filter.labels.allFileTypes,
                    value: "ALL",
                    disabled: true
                });
                self.allFilterValues = self.materialsFilterSortOptions[3].options;

                self.filterOptions = [{
                    type : "assetTypes",
                    id:"wbAssetType",
                    data: self.materialsFilterSortOptions[0],
                    translations:self.assetTypeTranslations
                },
                    {
                        type : "subTypes",
                        id:"wbpiAssetType",
                        data: self.materialsFilterSortOptions[1],
                        translations:self.subTypeTranslations
                    },
                    {
                        type : "fileTypes",
                        id:"fileType",
                        data: self.materialsFilterSortOptions[2],
                        translations:self.fileTypeTranslations
                    }];

                if (self.filterSort.isHOAView) {
                    self.materialsFilterSortOptions.splice(5,1);
                }
                if (self.materialsFilterSortOptions.length > 6 && self.materialsFilterSortOptions[6]) {
                    self.filterOptions.push(self.materialsFilterSortOptions[4]);
                    self.filterOptions.push(self.materialsFilterSortOptions[5]);
                    self.filterOptions.push(self.materialsFilterSortOptions[6]);
                } else if (self.materialsFilterSortOptions.length === 6) {
                    self.filterOptions.push(self.materialsFilterSortOptions[4]);
                    self.filterOptions.push(self.materialsFilterSortOptions[5]);
                } else if (self.materialsFilterSortOptions.length === 5) {
                    self.filterOptions.push(self.materialsFilterSortOptions[4]);
                }
            });
        };

        /**
         * Get unpublished materials
         * @function getUnpublishedMaterialUpdate
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.toDoController
         */
        self.materialFilterSelectAll = function () {
            if (!self.filterSort.retainStaticFilters) {
                self.sortModel = {id : self.materialsSort[0].value, label: self.materialsSort[0].text};
                self.filterSort.sort = "lastModified,id,desc";
            }
            self.filterSort.wbAssetType = [];
            self.filterSort.wbpiAssetType = [];
            self.filterSort.fileType = [];
            self.getAssets();
        };

        /**
         * Disabled bulk add to queue btn
         * @function disableBulkAddToQueueBtn
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @public
         * @returns {boolean}
         */
        self.disableBulkAddToQueueBtn = function () {
            var isSelected = false;
            if (self.filmAssets && self.filmAssets.data && self.filmAssets.data.filmAssetDownloadView) {
                isSelected = _.findIndex(self.filmAssets.data.filmAssetDownloadView.content, {
                    selected: true
                }) >= 0;
            }
            return !isSelected;
        };

        /**
         * Set asset category
         * @function setAssetCategory
         * @public
         * @param {string} assetCategory asset category
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         */
        self.setAssetCategory = function (assetCategory) {
            self.assetCategory = assetCategory;
        };


        $rootScope.$on("updateMaterialsTab", function ($event, tabObj) {
            self.reCalculateTabCounts(tabObj.count, tabObj.tabName);
        });
        $rootScope.$on("assetTypeUpdated", function () {
            self.init();
            self.getFilmAssets();
        });
        /**other
         * Get film  assets
         * @function getFilmAssets
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @returns {promise}
         */
        self.getFilmAssets = function () {
            if (self.filterSort.isHOAView) {
                self.filterSort.showAll = false;
            }
            var serviceCallName = self.filterSort.isHOAView ? "getHoaFilmAssets" : "getFilmAssets";
            var filmKeyId = self.filterSort.isHOAView ? eisId : filmId;
            return filmService[serviceCallName](filmKeyId, self.assetCategory, self.filterSort.wbAssetType, self.filterSort.wbpiAssetType, self.filterSort.fileType, self.filmAssets.data.filmAssetDownloadView.size, self.filmAssets.data.filmAssetDownloadView.number, self.filterSort.sort, "pub", self.filterSort.showAll, user.role).success(function (result) {
                self.filmAssets = result;
                self.filmAssets.data.filmAssetDownloadView.number++;
                $rootScope.$emit("updateMaterialsTab", {count : result.data.filmAssetDownloadView.totalElements, tabName: self.assetCategory});
                initFilters();
                setAnchorScroll();
            });
        };
        
        /**
         * get Material count
         * @function getMaterialCount
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @private
         * @param {int} filmId film id
         * @param {string} category asset category
         * @param {str} wbAssetType asset type
         * @param {str} wbpiAssetType asset sub type
         * @param {str} fileType asset file type
         * @param {str} sort sorting
         * @param {str} recType rec type
         * @param {str} size page show all
         * @param {str} page page user Role 
         */
        self.getMaterialCount = function () {
            var filmKeyId = self.filterSort.isHOAView ? eisId : filmId;
            return filmService["getMaterialTabCount"](filmKeyId, "assetCategory", self.filterSort.wbAssetType, self.filterSort.wbpiAssetType, self.filterSort.fileType, self.filterSort.sort,
                    "pub", self.filterSort.showAll, user.role).success(function (result) {
                        self.materialsTabs.forEach(function (tab) {
                            switch (tab.defaultHeading) {
                                case "artwork":
                                    tab.count = result.data.artWorkCount;
                                    break;
                                case "audioVideo":
                                    tab.count = result.data.audiovideoCount;
                                    break;
                                case "written":
                                    tab.count = result.data.writtenCount;
                                    break;
                                case "other":
                                    tab.count = result.data.otherCount;
                                    break;
                            }
                        });
                    });
        };

        /** setAnchorScroll
         * @function setPageScroll
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         */
        var setAnchorScroll = function () {
            var path = $location.search();
            if(path && path.heroClick) {
                $location.hash("materialsTab");
                $anchorScroll();
            }

           //}
        };

        /**
         * re-retrieve assets based on refined sort/filter
         * @function getAssets
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @param {object} selectedFilters selected filters
         */
        self.getAssets = function (selectedFilters) {
            if (selectedFilters) {
                if (self.filterSort.isHOAView === false || self.filterSort.isHOAView === true) {
                    permissionQueueService.clearSelectedAssets();
                }
                if (self.filterSort.isHOAView === false) {
                    self.filterSort = {
                        wbAssetType: [],
                        wbpiAssetType: [],
                        isHOAView: undefined,
                        showAll: "false",
                        sort: "lastModified,desc"
                    };
                }

                self.isHOAView = $scope.$parent.filmCtrl.isSourceView ? true : self.filterSort.isHOAView;
                self.filmAssets.data.filmAssetDownloadView.number = 1;
            }

            self.callbackFunction();
        };

        /**
         * Set active tab
         * @function active
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @param {string} route tab route
         */
        self.active = function (route) {
            return $state.current.name.indexOf(route) > -1;
        };

        /**
         * Bulk add to cart
         * @function bulkAddToCart
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @public
         * @param {object[]} downloadItems download items
         */
        self.bulkAddToCart = function (downloadItems) {
            shoppingCartService.bulkAddToCart(downloadItems).success(function () {
                $rootScope.$emit("updateShoppingCartCount", true);
                shoppingCartService.clearSelectedAssets();
                clearSelectedState();
            });
        };

        /**
         * Bulk add to queue
         * @function bulkAddToQueue
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @public
         * @param {object[]} permissionQueueItems permission queue serivice
         */
        self.bulkAddToQueue = function (permissionQueueItems) {
            permissionQueueService.bulkAddToQueue(permissionQueueItems).success(function () {
                $rootScope.$emit("updatePermissionQueueCount", true);
                permissionQueueService.clearSelectedAssets();
                clearSelectedState();
            });
        };
        
        /**
         * reCalculateTabCounts
         * @function reCalculateTabCounts
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         * @public
         * @param {int} api result
         * @param String tabName
         */
        self.reCalculateTabCounts = function (result, tabName) {
            self.materialsTabs.forEach(function (tab) {
                if(tab.defaultHeading === tabName) {
                    tab.count = result;
                }
            });
        };
        
       
        /**
         * Init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmMaterialsController
         */
        self.init = function () {
            self.getMaterialCount();
        };
        self.init();
        

    }

    controllers.controller("filmMaterialsController", ["$cookies","$rootScope", "$scope", "$state", "$location", "$anchorScroll", "filmService", "shoppingCartService", "permissionQueueService", "API_EFD", "localStorageService", "DEFAULT_PAGE_SIZE", "deviceDetectorService", "assetCategoryConvertor", "AUDIO_VIDEO_ASSET_CATEGORY", filmMaterialsController]);
}());
