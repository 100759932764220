(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Page controller
     * @dec The page controller
     * @class pageController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.$scope} $scope angular $scope
     * @param {!angular.$window} $window window instance
     * @param {service} localStorageService local storage service
     * @param {service} translateService translate service
     * @param {service} locationService location service
     * @param {module} tmhDynamicLocale tmh dynamic locale module
     * @param {service} languageService language service
     * @param {service} oauthService oauth service
     * @param {constant} OAUTH_SESSION_LIFETIME OAUTH_SESSION_LIFETIME
     * @param {service} $cookies $cookies service 
     * @param {constant} SECURE_COOKIES SECURE_COOKIES
     * @param {constant} DOMAIN DOMAIN
     * @param {constant} GA_TOKEN GA tracking code
     */
    function pageController($window, $scope, localStorageService, translateService, locationService, tmhDynamicLocale, languageService, oauthService, OAUTH_SESSION_LIFETIME, $cookies, SECURE_COOKIES, DOMAIN, GA_TOKEN) {

        /**
         * Instance to pageController
         * @memberOf angular_module.WB.cinehub.controllers.pageController
         * @private
         * @type {object}
         */
        var self = this,

            /**
             * User info
             * @type {int}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.materialsController
             */
            user = localStorageService.get("userInfo"),

            /**
             * Is page public flag
             * @type {boolean}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.materialsController
             */
            isPagePublic = (/\/login[\/,\?,#]\d*|\/login$/ig.test(window.location.href) ||
                /\/forgot-password[\/,\?]\d*|\/forgot-password$/ig.test(window.location.href) ||
                /\/registration[\/,\?]\d*|\/registration$/ig.test(window.location.href) ||
                /\/register-profile[\/,\?]\d*|\/register-profile$/ig.test(window.location.href) ||
                /\/reset-password[\/,\?]\d*|\/reset-password$/ig.test(window.location.href) ||
                /\/email-verification[\/,\?]\d*|\/email-verification$/ig.test(window.location.href) ||
                /\/anti-piracy[\/,\?]\d*|\/anti-piracy$/ig.test(window.location.href) ||
                /\/terms[\/,\?]\d*|\/terms$/ig.test(window.location.href) ||
                /\/privacy-policy[\/,\?]\d*|\/privacy-policy$/ig.test(window.location.href) ||
                /\/faq[\/,\?]\d*|\/faq$/ig.test(window.location.href) ||
                /\/healthcheck[\/,\?]\d*|\/healthcheck$/ig.test(window.location.href) ||
                /\/send-plus[\/,\?]\d*|\/send-plus/ig.test(window.location.href) ||
                /\/password-expired[\/,\?]\d*|\/password-expired/ig.test(window.location.href) ||
                /\/verifyMFA[\/,\?]\d*|\/verifyMFA/ig.test(window.location.href) ||
                /\/registerMFA[\/,\?]\d*|\/registerMFA/ig.test(window.location.href) ||
                /\/error[\/,\?]\d*|\/error$/ig.test(window.location.href)) ||
                /\/needHelp[\/,\?]\d*|\/needHelp$/ig.test(window.location.href) ||
                /\/admin-registered-profile[\/,\?]\d*|\/admin-registered-profile/ig.test(window.location.href);

        /**
         * translations
         * @type {int}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.materialsController
         */
        self.translations = localStorageService.get("translations");

        /**
         * Whether the page controller has initialized the translations for the page
         * @type {boolean}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.pageController
         */
        self.translationsHaveloaded = false;

        /**
         * Page name
         * @type {boolean}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.pageController
         */
        self.page = "";

        /**
         * Shopping cart info
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.pageController
         */
        self.shoppingCartInfo = [];

        /**
         * No template page titles
         * @memberOf angular_module.WB.cinehub.controllers.pageController
         * @type {array}
         * @public
         */
        self.noTemplatePageTitles = ["Please log in", "Forgot Password", "Reset Password", "Help", "Register", "Register Profile", "Health Check", "Email Verification", "Error", "MFA", "Register MFA", "Need Help"];

        /**
         * Error code
         * @public
         * @type {int}
         * @memberOf angular_module.WB.cinehub.controllers.pageController
         */
        self.errorCode = window.WB.cinehub.preload.errorCode;
        
        /**
         * API_EFD
         * @type {constant}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.newsDetailsController
         */
        self.GA_TOKEN = GA_TOKEN;

        /**
         * Load translations
         * @function loadTranslations
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.pageController
         */
        var loadTranslations = function () {
            var isLoginPage = !user,
                isRegistrationPage = localStorageService.get("registrationData"),
                loadedTranslationsForLocale = localStorageService.get("loadedTranslationsForLocale"),
                location = localStorageService.get("location"),
                isInitialLogin = user && !location,
                locale = null,
                browserLanguage = "en-US";

            languageService.getBrowserLanguage().then(function (browserLang) {
                browserLanguage = browserLang;
                if (isInitialLogin) {
                    locationService.setDefaultLocale();
                    location = localStorageService.get("location");
                }

                if (isLoginPage && !isRegistrationPage) {
                    locale = browserLanguage;
                } else {
                    locale = location.locale;
                }

                if (loadedTranslationsForLocale === locale) {
                    self.translations = localStorageService.get("translations");
                    self.translationsHaveloaded = true;
                } else {
                    translateService.loadTranslations(locale).success(function (result) {
                        self.translations = result;
                        //localStorageService.set("translations", self.translations);
                        localStorageService.set("loadedTranslationsForLocale", locale);
                        self.translationsHaveloaded = true;
                    });
                }
                if (locale.toLowerCase() === "zh-hk") {
                    tmhDynamicLocale.set("zh-hant");
                } else {
                    tmhDynamicLocale.set(locale.toLowerCase());
                }
            });
        };

        /**
         * Get application oauth token for public apis
         * @function getAppOauthToken
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.pageController
         */
        var getAppOauthToken = function () {
            var accessToken = $cookies.get("accessToken");
            if (!accessToken && isPagePublic) {
                oauthService.getAppOauthToken().success(function (token) {
                    $cookies.put("accessToken", token.access_token, {
                        sameSite: "strict",
                        secure: SECURE_COOKIES,
                        expires: OAUTH_SESSION_LIFETIME,
                        domain: DOMAIN
                    });
                    loadTranslations();
                });
            } else {
                loadTranslations();
            }
        };

        /**
         * Init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.pageController
         * @function init
         */
        self.init = function () {
            if (!localStorageService.isSupported) {
                window.alert("WB360 requires access to the browser data. Please exit from private browsing.");
            }
            if (!window.navigator.cookieEnabled) {
                window.alert("WB360 requires access to the browser cookies. Please enable browser cookies.");
            }

            getAppOauthToken();

            if (!user && !isPagePublic) {
                $window.location.href = "/login#" + $window.location.pathname + $window.location.hash;
                return false;
            }


        };

        //INIT
        self.init();

    }

    controllers.controller("pageController", ["$window", "$scope", "localStorageService", "translateService", "locationService", "tmhDynamicLocale", "languageService", "oauthService", "OAUTH_SESSION_LIFETIME", "$cookies", "SECURE_COOKIES", "DOMAIN", "GA_TOKEN", pageController]);
}());
