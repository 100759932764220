(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");
    
    /**
     * @class newsListService
     * @memberOf angular_module.WB.cinehub.services
     * @desc film service
     * @param {!angular.$http} $http angular $http service
     * @paarm {strict} API_ROOT API_ROOT constant  
     */
    function newsListService($http, API_ROOT) {

        /**
         * Get news items
         * @memberOf angular_module.WB.cinehub.services.newsListService
         * @public
         * @function getNewsItems
         * @param {int} film id
         * @param {int} past days
         * @param {int} size size
         * @param {int} page page
         * @param {string} sort sort order
         * @param {string} secondarySort secondary sort
         * @param {string} errorMessage error message 
         * @returns {promise}
         */        
        this.getNewsItems = function (filmId, pastDays, size, page, sort, secondarySort, errorMessage) {
            return $http.get(API_ROOT + "newsItems/search/findLocByFilmIdAndByPastDays?filmId=" + filmId + "&pastDays=" + pastDays + "&page=" + page + "&size=" + size + "&sort=" + sort, { errorMessage: errorMessage });
        };

        /**
         * Get film titles
         * @memberOf angular_module.WB.cinehub.services.newsListService
         * @public
         * @function getFilmTitles
         * @param {string} errorMessage error message 
         * @returns {promise}
         */  
        this.getFilmTitles = function (errorMessage) {
            return $http.get(API_ROOT + "films/search/findLocFilmsWithNewsItem?page=0&size=100&projection=forNewsItem", { errorMessage: errorMessage, showSpinner: false });
        };
    }

    services.service("newsListService", ["$http", "API_ROOT", newsListService]);
}());
