(function () {
    "use strict";
    var directives = angular.module("WB.cinehub.directives");
    directives.directive("alerts", function () {
        function alertsController($scope, $rootScope, $timeout) {
            $scope.alerts = [];

            $rootScope.$on("showAlert", function (e, type, message, key) {
                $scope.hideAlert = false;
                $scope.alerts.push({
                    msg: message,
                    type: type
                });
                if (key) {
                    $scope.key = key;
                } else {
                    $scope.alertsKey = "default";
                    $scope.key = "default";
                }
                if (type === "success") {
                    $timeout(function () {
                        $scope.hideAlert = true;
                    }, 3000, true);
                }

                //make the list of alert messages distinct
                $scope.alerts = _.uniqBy($scope.alerts, "msg");
            });

            $rootScope.$on("hideAlert", function () {
                $scope.alerts = [];
            });

            //FIX: this doesn't get fired??
            $scope.closeAlert = function (index) {
                $scope.alerts.splice(index, 1);
            };
        }

        return {
            restrict: "A",
            replace: true,
            scope: {
                alertsKey: "@alertsKey"
            },
            controller: ["$scope", "$rootScope", "$timeout", alertsController],
            template: "<uib-alert dismiss-on-timeout=\"10000\" ng-repeat=\"alert in alerts\" type=\"{{alert.type}}\" close=\"$parent.closeAlert($index)\" data-ng-show=\"alerts.length !== 0 && alertsKey == key\">{{alert.msg}}</uib-alert>"
        };
    });
} ());