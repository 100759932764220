(function () {
    "use strict";
    var services = angular.module("WB.cinehub.services");

    /**
     * Permission queue service
     * @class permissionQueueService
     * @memberOf angular_module.WB.cinehub.services
     * @desc permission queue service
     * @param {!angular.$http} $http angular $http service
     * @param {string} API_ROOT API_ROOT constant
     * @param {service} localStorageService local storage service
     * @param {factory} promiseFactory promise factory
     * @param {int} PERMISSION_QUEUE_ASSETS_GET_CALL_SIZE constant size for a call
     */
    function permissionQueueService($http, API_ROOT, localStorageService, promiseFactory, PERMISSION_QUEUE_ASSETS_GET_CALL_SIZE) {

        /**
         * User info
         * @type {int}
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.permissionQueueService
         */
        var user = localStorageService.get("userInfo"),

            /**
             * translations
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.permissionQueueService
             * @type {object}
             */
            translations = localStorageService.get("translations"),

            /**
             * location
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.permissionQueueService
             * @type {object}
             */
            location = localStorageService.get("location"),

            /**
             * instance to permissionQueueService
             * @private
             * @memberOf ngular_module.WB.cinehub.controllers.permissionQueueService
             * @type {permissionQueueService}
             */
            self = this,

            /**
             * Add to queue query
             * @private
             * @memberOf ngular_module.WB.cinehub.controllers.permissionQueueService
             * @type {object}
             */
            addToQueueQuery = {
                "user": user && user.userId ? user.userId : -1,
                "assetId": "",
                "rendition": "1",
                "timestamp": moment().utc().format(),
                "regionId": location && location.localeId ? location.localeId : -1,
                "territoryId": user && user.role === "homeOfficeAdmin" ? -1 : location && location.territoryId ? location.territoryId : -1,
                "exhibitorId": -1,
                "theaterId": -1
            },

            /**
             * selected assets
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.permissionQueueService
             * @type {object[]}
             */
            selectedAssets = [];

        /**
         * Permission cart 
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.shoppingCartService
         */
        self.permissionCart = localStorageService.get("permissionCart") || {
            _embedded: {
                permissionItems: []
            },
            page: {
                totalElements: 0
            }
        };

        /**
         * Remove from queue
         * @function removeFromQueue
         * @memberOf angular_module.WB.cinehub.controllers.permissionQueueService
         * @private
         * @param {object[]} itemsToRemove items to remove
         * @returns {promise}
         */
        var removeFromQueue = function (itemsToRemove) {
            return $http.delete(API_ROOT + "permissionItems/removeAll", {
                errorMessage: translations.permissionCart.messages.removeFromQueueError,
                data: itemsToRemove,
                headers: {
                    "Content-Type": "application/json;charset=utf-8"
                }
            });
        };

        /**
         * Check selected record are unique 
         * @function checkSelectedRecordsUnique
         * @memberOf angular_module.WB.cinehub.controllers.permissionQueueService
         * @private
         * @param {object[]} permission queue items
         * @returns {object[]}
         */
        var checkSelectedRecordsUnique = function (permissionQueueItems) {
            var permissionQueueItemsLength = permissionQueueItems.length;
            if (permissionQueueItemsLength > 0) {
                var permissionQueueItemIndex = 0;
                for (permissionQueueItemIndex; permissionQueueItemIndex < permissionQueueItemsLength; permissionQueueItemIndex++) {
                    var permissionQueueItem = permissionQueueItems[permissionQueueItemIndex];
                    selectedAssets = selectedAssets.filter(function (selectedAsset) {
                        return selectedAsset.id != permissionQueueItem.assetId;
                    });
                }
            }
        };

        /**
         * Check assets in permission cart 
         * @function checkAssetsInPermissionCart
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.permissionQueueService
         * @param {object[]} assets assets to check
         */
        self.checkAssetsInPermissionCart = function (assets) {
            if (assets) {
                var assetIndex = 0,
                    assetsLength = assets.length;
                for (assetIndex; assetIndex < assetsLength; assetIndex++) {
                    assets[assetIndex].isInPermissionCart = _.findIndex(self.permissionCart._embedded.downloadItems, {
                        assetId: assets[assetIndex].id
                    }) !== -1;
                }
            }
        };

        /**
         * Add asset
         * @function addAsset
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.permissionQueueService
         * @param {object} asset asset
         */
        self.addAsset = function (asset) {
            var isAlreadySelected = _.find(selectedAssets, {
                id: asset.id
            });
            if (!isAlreadySelected) {
                selectedAssets.push(asset);
            }
        };

        /**
         * Remove asset
         * @function removeAsset
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.permissionQueueService
         * @param {object} asset asset
         */
        self.removeAsset = function (asset) {
            selectedAssets.splice(_.findIndex(selectedAssets, {
                assetId: asset.assetId
            }), 1);
        };

        /**
         * Get selected assets
         * @function getSelectedAssets
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.permissionQueueService
         * @returns {object[]}
         */
        self.getSelectedAssets = function () {
            return selectedAssets;
        };

        /**
         * Get permission cart count 
         * @function getPermissionCartCount
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.permissionQueueService
         * @returns {promise}
         * @param {boolean} refresh flag indicates weather we want to get a fresh data from the server or used a cached one
         */
        self.getPermissionCartCount = function (refresh) {
            var permissionCart = localStorageService.get("permissionCart"),
                deferred = promiseFactory.defer(),
                serviceCall = user.role === "homeOfficeAdmin" ? "getPermissionQueueAssetsForHOA" : "getPermissionQueueAssets";

            if (!refresh && permissionCart !== undefined && permissionCart !== "" && permissionCart !== null) {
                self.permissionCart = permissionCart;
                deferred.resolve(permissionCart);
                return deferred.promise;
            }

            if (refresh || permissionCart === undefined || permissionCart === "" || permissionCart === null) {
                return self[serviceCall](user.userId, PERMISSION_QUEUE_ASSETS_GET_CALL_SIZE, translations.global.messages.getPermissionQueueItemsError).then(function (result) {
                    self.permissionCart = result.data;
                    localStorageService.set("permissionCart", result.data);
                });
            }

        };

        /**
         * Get permission queue assets
         * @memberOf angular_module.WB.cinehub.services.permissionQueueService
         * @public
         * @function getPermissionQueueAssets
         * @param {int} userId user id
         * @returns {promise}
         */
        self.getPermissionQueueAssets = function (userId) {
            return $http.get(API_ROOT + "permissionItems/search/findByUserOrderByTimestampDesc?user=" + userId + "&page=0&size=" + PERMISSION_QUEUE_ASSETS_GET_CALL_SIZE + "&sort=" + "timestamp,desc" + "&sort=" + "filmTitle,asc" + "&exhibitorId=-1&theaterId=-1&ignoreExhibitorTheater", {
                errorMessage: translations.permissionCart.messages.getPermissionQueueAssetsError
            });
        };

        /**
         * Get permission queue assets for hoa
         * @memberOf angular_module.WB.cinehub.services.permissionQueueService
         * @public
         * @function getPermissionQueueAssets
         * @param {int} userId user id
         * @returns {promise}
         */
        self.getPermissionQueueAssetsForHOA = function (userId) {
            return $http.get(API_ROOT + "permissionItems/search/findByUserForHoa?user=" + userId + "&page=0&size=" + PERMISSION_QUEUE_ASSETS_GET_CALL_SIZE + "&sort=" + "timestamp,desc" + "&sort=" + "filmTitle,asc&ignoreExhibitorTheater", {
                errorMessage: translations.permissionCart.messages.getPermissionQueueAssetsError
            });
        };

        /**
         * Bulk remove from queue
         * @function removeFromQueue
         * @public
         * @memberOf angular_module.WB.cinehub.services.permissionQueueService
         * @returns {promise}
         * @param {object} singleItem single item to remove
         */
        self.removeFromQueue = function (singleItem) {
            return removeFromQueue([singleItem]);
        };

        /**
         * Bulk remove from queue
         * @function bulkRemoveFromQueue
         * @public
         * @memberOf angular_module.WB.cinehub.services.permissionQueueService
         * @returns {promise}
         */
        self.bulkRemoveFromQueue = function (outerAssets) {
            var assets = outerAssets || selectedAssets;
            return removeFromQueue(assets);
        };

        /**
         * Clear selected assets 
         * @function clearSelectedAssets
         * @memberOf angular_module.WB.cinehub.services.permissionQueueService
         * @public
         */
        self.clearSelectedAssets = function () {
            selectedAssets = [];
        };

        /**
         * add to queue
         * @function addToQueue
         * @public
         * @memberOf angular_module.WB.cinehub.services.permissionQueueService
         * @returns {promise}
         * @param {int} singleItem single item to add
         */
        self.addToQueue = function (asset) {
            addToQueueQuery.assetId = asset.id;
            addToQueueQuery.filmId = asset.filmId;
            return $http.post(API_ROOT + "permissionItems", addToQueueQuery, {
                errorMessage: translations.permissionCart.messages.addToQueueError
            });
        };

        /**
         * add to queue
         * @function bulkAddToQueue
         * @public
         * @memberOf angular_module.WB.cinehub.services.permissionQueueService
         * @returns {promise}
         * @param {object[]} permissionQueueItems permission queue items
         */
        self.bulkAddToQueue = function (permissionQueueItems) {
            var assets = [],
                queueItem = {};

            checkSelectedRecordsUnique(permissionQueueItems);

            selectedAssets.forEach(function (asset) {
                queueItem = _.clone(addToQueueQuery);
                queueItem.assetId = asset.id;
                queueItem.filmId = asset.filmId;
                assets.push(queueItem);
            });

            return $http.post(API_ROOT + "permissionItems/saveAll", assets, {
                errorMessage: translations.permissionCart.messages.bulkAddToQueueError
            });
        };
    }

    services.service("permissionQueueService", ["$http", "API_ROOT", "localStorageService", "promiseFactory", "PERMISSION_QUEUE_ASSETS_GET_CALL_SIZE", permissionQueueService]);
}());
