(function () {

    /**
     * @class helpers
     */
    window.helpers = {};
    
    /**
     * Is Json
     * @param {string} str string
     * @returns {boolean}
     * @private
     * @memberOf helpers
     * @function isJson
     */
    window.helpers.isJson = function (str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    /**
     * check null from the server and convert it into a js null
     * @param {string} str string
     * @returns {boolean|string}
     * @private
     * @memberOf helpers
     * @function checkNull
     */
    window.helpers.checkNull = function (value) {
        if (value === "[null]") { //coming like that from BE if the value in DB (null)
            return null;
        } else {
            return value;
        }
    };
})();
