(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");
    
    /**
     * @class materialDetailsService
     * @memberOf angular_module.WB.cinehub.services
     * @desc material detail service
     * @param {!angular.$http} $http angular $http service
     * @param {strict} API_ROOT API_ROOT constant  
     * @param {service} localStorageService local storage service 
     */
    function materialDetailsService($http, API_ROOT, localStorageService) {

        /**
         * Translations 
         * @private 
         * @memberOf angular_module.WB.cinehub.services.materialDetailsService
         * @type {object}
         */
        var translations = localStorageService.get("translations");

        /**
         * Get asset source and cinehub data - This endpoint at most returns 0-0-ORIG (EIS), 0-0-PUB (HomeOffice Version), and localized asset data. 
         * @memberOf angular_module.WB.cinehub.services.materialDetailsService
         * @public
         * @param {int} mmxId mmxid mmxid
         * @returns {promise}
         */
        this.getAssetOrig = function (mmxId) {
            return $http.get(API_ROOT + "filmAssets/search/findByMmxIdAndTerritoryIdAndLocaleId?mmxId=" + mmxId + "&territoryId=-1&localeId=-1&ignoreLocalizationString", { errorMessage: translations.materialDetails.messages.getAssetError });
        };

        /**
         * Get asset source and cinehub data - This endpoint at most returns 0-0-ORIG (EIS), 0-0-PUB (HomeOffice Version), and localized asset data. 
         * @memberOf angular_module.WB.cinehub.services.materialDetailsService
         * @public
         * @param {int} mmxId mmx id
         * @param {int} territoryId territory id
         * @param {int} localeId locale id
         * @returns {promise}
         */
        this.getAssetPub = function (mmxId) {
            return $http.get(API_ROOT + "filmAssets/search/getAssetDetails?mmxId=" + mmxId, { errorMessage: translations.materialDetails.messages.getAssetError });
        };

        /**
         * get Asset Types
         * @function getAssetTypes
         * @memberOf angular_module.WB.cinehub.services.materialDetailsService
         * @public
         * @param {str} keyword keyword
         * @returns {promise}
         */
        this.getAssetTypes = function (keyword) {
            return $http.get(API_ROOT + "assetType/search/findAllFilmTypes?name=" + keyword, {
                errorMessage: translations.registration.messages.getExhibitorsError,
                alertKey: "anonymouseUserAlert",
                showSpinner: true
            }).then(function (result) {
                return result.data._embedded.assetType;
            });
        };
        /**
         * get Asset Types
         * @function getAssetTypes
         * @memberOf angular_module.WB.cinehub.services.materialDetailsService
         * @public
         * @param {str} keyword keyword
         * @returns {promise}
         */
        this.getSubTypes = function (assetTypeId) {
            return $http.get(API_ROOT + "assetSubTypes/search/getSubTypeByAssetType?assetTypeId=" + assetTypeId, {
                errorMessage: translations.materialDetails.messages.getAssetError });
        };

        /**
         * update asset
         * @memberOf angular_module.WB.cinehub.services.materialDetailsService
         * @public
         * @param {obj} asset asset
         * @param {str} errorMessage error message
         * @returns {promise}
         */
        this.save = function (asset, errorMessage) {
            return $http.put(API_ROOT + "filmAssets/" + asset.assetId, asset, { errorMessage: errorMessage });
        };

    }

    services.service("materialDetailsService", ["$http", "API_ROOT", "localStorageService", materialDetailsService]);
}());
