(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Anti piracy controller
     * @dec The anti piracy controller
     * @class antiPiracyController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {service} antiPiracyService anti piracy service
     * @param {!angular.Service} $sce $sce
     * @param {service} localStorageService local storage service
     * @param {constant} US_TERRITORY_ID US_TERRITORY_ID
     * @param {constant} US_LOCALE_ID US_LOCALE_ID
     */
    function antiPiracyController(antiPiracyService, $sce, localStorageService, US_TERRITORY_ID, US_LOCALE_ID) {

        /**
         * Instance of antiPiracyController
         * @type {antiPiracyController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.antiPiracyController
         */
        var self = this,

           /**
            * User info object
            * @type {object}
            * @memberOf angular_module.WB.cinehub.controllers.privacyPolicyController
            * @private
            */
            user = localStorageService.get("userInfo"),

           /**
            * territory info object for registration flow
            * @type {object}
            * @memberOf angular_module.WB.cinehub.controllers.privacyPolicyController
            * @private
            */
            territory = localStorageService.get("territoryInfo");

        /**
         * translations
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.antiPiracyController
         * @public
         */
        self.translations = localStorageService.get("translations");

        /**
         * location
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.antiPiracyController
         * @public 
         */
        self.location = localStorageService.get("location");

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.antiPiracyController
         */
        self.pageHeaderItem = {
            pageTitle: self.translations.antiPiracy.labels.pageTitle,
            breadcrumbItems: [{
                linkTitle: self.translations.antiPiracy.labels.breadcrumbLinkTitle,
                link: "/anti-piracy"
            }]
        };

        /**
         * Anti piracy model
         * @public
         * @type {string}
         * @memberOf angular_module.WB.cinehub.controllers.antiPiracyController
         */
        self.antiPiracy = "";

        /**
         * Get anti piracy
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.antiPiracyController
         * @function getPiracy
         */
        var getAntiPiracy = function () {
            var userTerritoryId = self.location && self.location.territoryId || user && user.territoryId || territory && territory.territoryId,
                userLocaleId = self.location && self.location.localeId || user && user.localeId || territory && territory.localeId,
                territoryId = userTerritoryId || US_TERRITORY_ID,
                localeId = userLocaleId || US_LOCALE_ID;
            antiPiracyService.getAntiPiracy(territoryId, localeId).success(function (result) {
                if (result._embedded.antiPiracyInfo.length) {
                    self.antiPiracy = $sce.trustAsHtml(result._embedded.antiPiracyInfo[0].antiPiracy); //based on locale server might return more then one anti piracy but we need only the first one
                } else {
                    antiPiracyService.getAntiPiracy(US_TERRITORY_ID, US_LOCALE_ID).success(function (result) {
                        if (result._embedded.antiPiracyInfo.length) {
                            self.antiPiracy = $sce.trustAsHtml(result._embedded.antiPiracyInfo[0].antiPiracy); //based on locale server might return more then one anti piracy but we need only the first one
                        }
                    });
                }
            });
        };

        /**
         * Init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.antiPiracyController
         */
        self.init = function () {
            getAntiPiracy();
        };

        //INIT
        self.init();
    }

    controllers.controller("antiPiracyController", ["antiPiracyService", "$sce", "localStorageService", "US_TERRITORY_ID", "US_LOCALE_ID", antiPiracyController]);
}());
