(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Select deselect all directive
     * @class cartselectDeselectAll
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     * @param {service} localStorageService local storage service
     * @param {$uibModal} $uibModal $uibModal
     * @param {constant} PERMISSIONS PERMISSIONS
     */
    function cartselectDeselectAll(localStorageService, $uibModal, PERMISSIONS) {
        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @private
         * @memberOf angular_module.WB.cinehub.directives.cartselectDeselectAll
         */
        var link = function (scope) {

            /**
             * translations
             * @memberOf angular_module.WB.cinehub.directives.cartselectDeselectAll
             * @public
             * @type {object}
             */
            scope.translations = localStorageService.get("translations");
            
            /**
             * impersonate user flag
             * @public
             * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
             * @type {object}
             */
            scope.viewAs = localStorageService.get("impersonateUserFlag");

            /**
             * Select deselect all 
             * @param {boolean} state state 
             * @function cartselectDeselectAll
             * @public
             */
            scope.cartselectDeselectAll = function (state) {
                scope.assets.forEach(function (asset) {
                    if (asset.status && asset.status.toLowerCase() !== "killed" && asset.permission !== PERMISSIONS.VIEW && asset.canBulkDownload) {
                        asset.selected = state;
                        if (scope.viewAs) {
                            asset.selected = false;
                        }
                    }
                });
                scope.$emit("isLargeAssetPresent", state);
                var onlyLargeAssets = false;
                var cartContainsOnlyLargeAssets = scope.translations.global.messages.largeAssetConfirmationMessage;
                for (var i = 0; i < scope.assets.length; i++) {
                    if (scope.assets[i].canBulkDownload) {
                        onlyLargeAssets = true;
                        break;
                    }
                }
                if (!onlyLargeAssets && state) {
                    $uibModal.open({
                        templateUrl: "confirmationTemplate.html",
                        controller: "modalConfirmationController",
                        controllerAs: "modalConfirmationCtrl",
                        keyboard: false,
                        backdrop: "static",
                        size: "md",
                        resolve: {
                            message: function () {
                                return cartContainsOnlyLargeAssets;
                            },
                            headerTitle: function () {
                                return "";
                            },
                            showAffirmativeBtn: function () {
                                return false;
                            },
                            affirmativeBtnText: function () {
                                return "";
                            },
                            cancelBtnText: function () {
                                return scope.translations.global.labels.cancelButton;
                            }
                        }
                    });
                }
            };
        };

        return {
            link: link,
            scope: {
                assets: "="
            },
            templateUrl: "cartSelectDeselectAllTemplate.html"
        };
    }

    directives.directive("cartselectDeselectAll", ["localStorageService", "$uibModal", "PERMISSIONS", cartselectDeselectAll]);

}());
