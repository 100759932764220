(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");
    
    /**
     * @class assetsService
     * @memberOf angular_module.WB.cinehub.services
     * @desc asset service
     * @param {!angular.$http} $http angular $http service
     * @param {constant} API_ROOT API_ROOT constant 
     * @param {service} localStorageService local storage service 
     */
    function assetsService($http, API_ROOT, localStorageService) {

        /**
         * Translations 
         * @private
         * @type {object}
         * @memberOf angular_module.WB.cinehub.services.assetsService
         */
        var translations = localStorageService.get("translations");

        /**
         * get assets
         * @memberOf angular_module.WB.cinehub.services.assetsService
         * @public
         * @function getAssets
         * @param {int} id id of the asset to get
         * @param {str} errorMessage error message
         * @returns {promise}
         * @todo Switch this to a correct endpoint.
         */
        this.getAssetById = function (id, errorMessage) {
            return $http.get(API_ROOT + "filmAssets/search/findLocById/?id=" + id, { errorMessage: errorMessage });
        };

        /**
         * Remove from todo
         * @function removeFromTodo
         * @memberOf angular_module.WB.cinehub.services.assetsService
         * @public
         * @param {int} assetId asset id
         */
        this.removeFromTodo = function (assetId) {
            return $http.post(API_ROOT + "todo/clearAsset", { id: assetId }, {
                errorMessage: translations.toDo.messages.cannotRemoveAssetFromTodo
            });
        };

    }

    services.service("assetsService", ["$http", "API_ROOT", "localStorageService", assetsService]);
}());
