(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Film Container directive
     * @class filmContainer
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     * @param {$uibModal} $uibModal uibModal instance
     */
    function filmContainer($uibModal, localStorageService, deviceDetectorService, API_ROOT) {
        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @private
         * @memberOf angular_module.WB.cinehub.directives.filmContainer
         */
        var link = function (scope) {

            /**
             * translations
             * @memberOf angular_module.WB.cinehub.directives.filmContainer
             * @public
             * @type {object}
             */
            scope.translations = localStorageService.get("translations");
            /**
             * userObj
             * @memberOf angular_module.WB.cinehub.directives.filmContainer
             * @public
             * @type {object}
             */
            scope.user = localStorageService.get("userInfo");
            /**
             * deviceDetector
             * @memberOf angular_module.WB.cinehub.directives.filmContainer
             * @public
             * @type {object}
             */
            scope.deviceDetector = deviceDetectorService;

            /**
             * filmRatingService
             * @memberOf angular_module.WB.cinehub.directives.filmContainer
             * @public
             * @type {object}
             */
            scope.API_ROOT = API_ROOT;

            /**
             * Play trailer
             * @public
             * @function playTrailer
             * @memberOf angular_module.WB.cinehub.controllers.filmContainer
             * @param {object} filmDetails the same filmDetails on the 'self' object...do we need this as a param?
             */
            scope.playTrailer = function (filmDetails) {
                var lightboxAsset = {
                        id: filmDetails.trailerId ? filmDetails.trailerId : filmDetails.id,
                        assetCategory: "Audio / Video",
                        fileTypeCategory: "Video",
                        mmxId: filmDetails.trailerMmxId
                    },
                    lightboxAction = "playTrailerOnly";
                scope.openLightBox()(null, lightboxAsset, lightboxAction);
            };
        };

        return {
            replace: true,
            link: link,
            scope: {
                id: "@",
                title: "@",
                releaseDate: "@",
                rating: "@",
                ratingDescription: "@",
                synopsis: "@",
                trailer: "@",
                lastModified: "@",
                imgSrc: "@",
                linkTo: "@",
                displayHover: "=",
                displayUpdateTag: "=",
                displayLastModified: "=",
                film: "=",
                index: "=",
                openLightBox: "&"
            },
            templateUrl: "filmContainerTemplate.html"
        };
    }

    directives.directive("filmContainer", ["$uibModal", "localStorageService","deviceDetectorService", "API_ROOT", filmContainer]);

}());
