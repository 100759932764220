(function() {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Lazy load
     * @class smartSourceDirective
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     */
    function smartSourceDirective() {
        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @param {jqueryElement} element jquery element
         * @private
         * @memberOf angular_module.WB.cinehub.directives.smartSourceDirective 
         */
        var link = function(scope, element) {

            //cache for cleanup
            var unwatcher = scope.$watch(scope.smartSrcWatch, function(newVal) {

                //validate $watch
                if (newVal) {

                    //add src
                    element.attr("src", scope.smartSrc);

                    scope.loadedImgSrc = scope.smartSrc;

                    //cleanup
                    unwatcher();
                }
            });
        };

        return {
            restrict: "A", //attribute directive
            scope: { //isolate scope
                smartSrc: "@", //smart src
                smartSrcWatch: "&", //$watch hook
                loadedImgSrc: "="
            },
            link: link
        };
    }

    directives.directive("smartSrc", [smartSourceDirective]);
}());