(function() {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Modal confirmation controller
     * @class modalConfirmationController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {$uibModalInstance} $uibModalInstance modal instance
     * @param {service} localStorageService local storage service
     * @param {string} message message to display 
     * @param {string} headerTitle header title to display 
     * @param {boolean} showAffirmativeBtn show affirmative btn
     * @param {string} affirmativeBtnText affirmative btn text
     * @param {string} cancelBtnText cancel btn text
     */
    function modalConfirmationController($uibModalInstance, localStorageService, message, headerTitle, showAffirmativeBtn, affirmativeBtnText, cancelBtnText, SENDPLUS_URL) {

        /**
         * Instance of modalConfirmationController
         * @type {modalConfirmationController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalConfirmationController
         */
        var self = this;

        /**
         * Translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalConfirmationController
         */
        self.translations = localStorageService.get("translations");

        /**
         * Comment 
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalConfirmationController
         */
        self.comment = "";

        /**
         * Message
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalConfirmationController
         */
        self.message = message;

         /**
         * Header title 
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalConfirmationController
         */       
        self.headerTitle = headerTitle;

         /**
         * Cancel btn text 
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalConfirmationController
         */
        self.cancelBtnText = cancelBtnText;

         /**
         * Show affirmative btn  
         * @type {boolean}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalConfirmationController
         */  
        self.showAffirmativeBtn = showAffirmativeBtn;

         /**
         * Affirmative btn text
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalConfirmationController
         */ 
        self.affirmativeBtnText = affirmativeBtnText;

         /**
         * SENDPLUS_URL
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalConfirmationController
         */ 

        self.SENDPLUS_URL = SENDPLUS_URL;

        /**
         * Cancel 
         * @function cancel 
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalConfirmationController
         */
        self.cancel = function() {
            $uibModalInstance.dismiss();
        };

        /**
         * Affirmation action 
         * @function affirmative 
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalConfirmationController
         */
        self.affirmative = function() {
            $uibModalInstance.close(self.comment);
        };

        /**
         * Affirmation action 
         * @function affirmative 
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalConfirmationController
         */
        self.copyToClipboard = function() {
            var el = document.createElement("textarea");
            el.value = message;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
        };


         /**
         * shareLink
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalConfirmationController
         */
        self.shareLink ="mailto: ?body=" + self.translations.assetShare.emailBody + "%0D%0A%0D%0A" + self.message + "&subject=" + self.translations.assetShare.emailSubject;



    }

    controllers.controller("modalConfirmationController", ["$uibModalInstance", "localStorageService", "message", "headerTitle", "showAffirmativeBtn", "affirmativeBtnText", "cancelBtnText", "SENDPLUS_URL", modalConfirmationController]);
}());
