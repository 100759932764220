(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Search controller
     * @dec search controller
     * @class searchController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.$rootScope} $rootScope angular rootScope
     * @param {service} searchService search service
     * @param {factory} filmUrlGeneratorFactory filim url generator factory
     * @param {service} shoppingCartService asset service
     * @param {service} localStorageService local storage service
     * @param {constant} API_EFD API_EFD
     * @param {service} permissionQueueService permission queue service
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     * @param {constant} SECURE_COOKIES SECURE_COOKIES
     * @param {constant} DOMAIN DOMAIN
     * @param {service} assetCategoryConvertor asset category convertor
     * @param {constant} AUDIO_VIDEO_ASSET_CATEGORY AUDIO_VIDEO_ASSET_CATEGORY
     */
    function searchController($rootScope, searchService, filmUrlGeneratorFactory, shoppingCartService, localStorageService, filmRatingService, API_EFD, permissionQueueService, DEFAULT_PAGE_SIZE,$cookies, SECURE_COOKIES,DOMAIN, assetCategoryConvertor, AUDIO_VIDEO_ASSET_CATEGORY) {

        /**
         * Instance of searchController
         * @type {searchController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         */
        var self = this;

        /**
         * translations
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         * @public
         * @type {object}
         */
        self.translations = localStorageService.get("translations");

        /**
         * User profile information
         * @type {object}
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         */
        self.user = localStorageService.get("userInfo");

        /**
         * Permission queue service
         * @type {service}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         */
        self.permissionQueueService = permissionQueueService;

        /**
         * Asset category convertor
         * @type {service}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         */
        self.assetCategoryConvertor = assetCategoryConvertor;

        /**
         * API_EFD
         * @type {constant}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         */
        self.API_EFD = API_EFD;

        /**
         * AUDIO_VIDEO_ASSET_CATEGORY
         * @type {constant}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         */
        self.AUDIO_VIDEO_ASSET_CATEGORY = AUDIO_VIDEO_ASSET_CATEGORY;

        /**
         * toggle select all
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         */
        self.toggleSelectAll = false;

        /**
         * Search term
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         */
        self.searchTerm = window.location.search.split("search=")[1];

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         */
        self.pageHeaderItem = {
            pageTitle: self.translations.search.labels.pageTitle + " \"" + decodeURIComponent(self.searchTerm) + "\"",
            breadcrumbItems: [{
                linkTitle: self.translations.search.labels.breadcrumbLinkTitle,
                link: "#"
            }]
        };

        /**
         * film url generator
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         * @public
         * @type {filmUrlGeneratorFactory}
         */
        self.filmUrlGenerator = filmUrlGeneratorFactory;

        /**
         * Shopping cart service
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         * @public
         * @type {service}
         */
        self.shoppingCartService = shoppingCartService;

        /**
         * tab menus
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         * @public
         */
        self.tabs = [{
            title: self.translations.search.labels.titlesTab,
            templateUrl: "/modules/search/titlesTab/titlesTab.html",
            active: true,
            serviceMethodNameLoadData: "getSearchResultsFilteredByTitleName"
        }, {
            title: self.translations.search.labels.materialsTab,
            templateUrl: "/modules/search/materialsTab/materialsTab.html",
            active: false,
            serviceMethodNameLoadData: "getSearchResultsFilteredByMaterialTypes"
        }, {
            title: self.translations.search.labels.newsTab,
            templateUrl: "/modules/search/newsTab/newsTab.html",
            active: false,
            serviceMethodNameLoadData: "getSearchResultsFilteredByNews"
        }];

        /**
         * Material filter sort options
         * @public
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         */
        self.materialsFilterSortOptions = [];

        /**
         * search results filtered by title name
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         */
        self.searchResultsFilteredByTitleName = {
            page: {
                number: 1,
                size: DEFAULT_PAGE_SIZE,
                totalElements: 0
            }
        };

        /**
         * search results filtered by materials types
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         */
        self.searchResultsFilteredByMaterialTypes = {
            page: {
                number: 1,
                size: DEFAULT_PAGE_SIZE,
                totalElements: 0
            }
        };

        /**
         * search results filtered by news
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         */
        self.searchResultsFilteredByNews = {
            page: {
                number: 1,
                size: DEFAULT_PAGE_SIZE,
                totalElements: 0
            }
        };

        /**
         * Filter sort
         * @type {string}
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         * @public
         */
        self.filterSort = "";
        self.doNotShow = false;
        self.donotShowChecked = $cookies.get("donotShowChecked") || "";
        /**
         * Clear selected state 
         * @function clearSelectedState
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         */
        var clearSelectedState = function () {
            self.searchResultsFilteredByMaterialTypes._embedded.filmAssets.forEach(function (asset) {
                asset.selected = false;
            });
        };

        /**
         * Disabled bulk add to queue btn
         * @function disableBulkAddToQueueBtn
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         * @public
         * @returns {boolean}
         */
        self.disableBulkAddToQueueBtn = function () {
            var isSelected = false;
            if (self.searchResultsFilteredByMaterialTypes && self.searchResultsFilteredByMaterialTypes._embedded && self.searchResultsFilteredByMaterialTypes._embedded.filmAssets) {
                isSelected = _.findIndex(self.searchResultsFilteredByMaterialTypes._embedded.filmAssets, {
                    selected: true
                }) >= 0;
            }
            return !isSelected;
        };

        /**
         * set film url
         * @function setFilmUrl
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         * @private
         */
        var setFilmUrl = function (data) {
            var title = "",
                index = 0,
                dataLength = data.length;

            for (index; index < dataLength; index++) {
                title = self.filmUrlGenerator(data.title);
                data[index].filmUrl = "/films/" + data[index].id + "/" + data[index].eisId + "/false/" + title + "#/film/details";
            }
        };

        /**
         * get asset type subtype filtered options
         * @function getAssetTypeSubtypeFilterOptions
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         * @param {string} wbAssetType wb asset type
         * @param {string} assetSubtype wb asset sub type
         */
        var getAssetTypeSubtypeFilterOptions = function (wbAssetType, assetSubtype) {
            if (!self.searchTerm) {
                return true;
            }

            searchService.getAssetTypeSubtypeFilterOptions(self.searchTerm, wbAssetType, assetSubtype).success(function (result) {
                self.materialsFilterSortOptions = [{
                    type: "select",
                    options: result.data.assetTypes,
                    propertyName: "wbAssetType"
                }, {
                    type: "select",
                    options: result.data.assetSubtypes,
                    propertyName: "wbpiAssetType"
                }];
               
                //Add default option
                self.materialsFilterSortOptions[0].options.unshift({
                    text: self.translations.filter.labels.allAssetTypes,
                    value: ""
                });
                self.materialsFilterSortOptions[1].options.unshift({
                    text: self.translations.filter.labels.allAssetSubTypes,
                    value: ""
                });
            });
        };

        /**
         * Get serach result counts
         * @function getSearchResultCounts
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         */
        var getSearchResultCounts = function () {
            searchService.getSearchResultCounts(self.searchTerm).success(function (result) {
                self.tabs[0].heading = self.tabs[0].title + " (" + result.data.filmCount + ")";
                self.tabs[1].heading = self.tabs[1].title + " (" + result.data.filmAssetCount + ")";
                self.tabs[2].heading = self.tabs[2].title + " (" + result.data.newsItemCount + ")";
            });
        };

        /**
         * get search results filtered by title name
         * @function getSearchResultsFilteredByTitleName
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         */
        self.getSearchResultsFilteredByTitleName = function () {
            searchService.getSearchResultsFilteredByTitleName(self.searchTerm, self.searchResultsFilteredByTitleName.page.size, self.searchResultsFilteredByTitleName.page.number).success(function (result) {
                self.searchResultsFilteredByTitleName = {
                    _embedded: {
                        films: result.content
                    },
                    page: {
                        number: result.number + 1,
                        size: result.size,
                        totalElements: result.totalElements,
                        totalPages: result.totalPages
                    }
                };
                setFilmUrl(self.searchResultsFilteredByTitleName._embedded.films);
                var filmObjs = {};
                filmObjs.films = result && result.content ? result.content : [];
                return filmRatingService.setFilmRatings(filmObjs).then(function (res) {
                    self.searchResultsFilteredByTitleName._embedded.films = res.filmObjs["films"];
                });
            });
        };

        /**
         * get search results filtered by material types
         * @function getSearchResultsFilteredByMaterialTypes
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         * @param {string} filterType filter type
         * @param {string} value filter value
         */
        self.getSearchResultsFilteredByMaterialTypes = function (selectedFilters) {
            selectedFilters = selectedFilters || {
                wbAssetType: "",
                wbpiAssetType: ""
            };

            searchService.getSearchResultsFilteredByMaterialTypes(self.searchTerm, self.searchResultsFilteredByMaterialTypes.page.size, self.searchResultsFilteredByMaterialTypes.page.number, selectedFilters.wbAssetType, selectedFilters.wbpiAssetType).success(function (result) {
                self.tabs[1].heading = self.tabs[1].title + " (" + result.totalElements + ")";
                self.searchResultsFilteredByMaterialTypes = {
                    _embedded: {
                        filmAssets: result.content
                    },
                    page: {
                        number: result.number + 1,
                        size: result.size,
                        totalElements: result.totalElements,
                        totalPages: result.totalPages
                    }
                };
                getAssetTypeSubtypeFilterOptions(selectedFilters.wbAssetType, selectedFilters.wbpiAssetType);
            });
        };

        /**
         * get search results filtered by by news
         * @function getSearchResultsFilteredByNews
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         */
        self.getSearchResultsFilteredByNews = function () {
            searchService.getSearchResultsFilteredByNews(self.searchTerm, self.searchResultsFilteredByNews.page.size, self.searchResultsFilteredByNews.page.number).success(function (result) {
                self.searchResultsFilteredByNews = {
                    _embedded: {
                        newsItems: result.content
                    },
                    page: {
                        number: result.number + 1,
                        size: result.size,
                        totalElements: result.totalElements,
                        totalPages: result.totalPages
                    }
                };
            });
        };

        /**
         * Tab change
         * @function tabChange
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         * @param {string} serviceMethodNameLoadData the name of the service to call
         */
        self.tabChange = function (serviceMethodNameLoadData) {
            self[serviceMethodNameLoadData]();
            self.filterSort = "";
        };

        /**
         * Bulk add to cart
         * @function bulkAddToCart
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         * @public
         * @param {object[]} downloadItems download items
         */
        self.bulkAddToCart = function (downloadItems) {
            shoppingCartService.bulkAddToCart(downloadItems).success(function () {
                $rootScope.$emit("updateShoppingCartCount", true);
                shoppingCartService.clearSelectedAssets();
                clearSelectedState();
            });
        };

        /**
         * Bulk add to permission queue
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         * @public
         * @function bulkAddToQueue
         * @param {object[]} permissionQueueItems permission queue items
         */
        self.bulkAddToQueue = function (permissionQueueItems) {
            permissionQueueService.bulkAddToQueue(permissionQueueItems).success(function () {
                $rootScope.$emit("updatePermissionQueueCount", true);
                permissionQueueService.clearSelectedAssets();
                clearSelectedState();
            });
        };

        /**
         * Open lightbox
         * @function openLightBox
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         * @param {object} $event browser event
         * @param {object} asset asset
         * @param {string} action action
         */
        self.openLightBox = function ($event, asset, action) {
            self.openLightbox = true;
            self.lightboxAsset = asset;
            self.lightboxAction = action;
        };
        self.doNotShowCheck =function(){
            if (self.doNotShow) {
                var now = new Date(),
                    in30Days = new Date(now.setDate(now.getDate() + 30));

                $cookies.put("donotShowChecked", self.doNotShow, {
                    sameSite: "strict",
                    secure: SECURE_COOKIES,
                    expires: in30Days,
                    domain: DOMAIN
                });
            } else {
                $cookies.remove("donotShowChecked", {domain: DOMAIN, path: "/" });
            }
            self.donotShowChecked = $cookies.get("donotShowChecked") || "";
        };
        /**
         * init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchController
         */
        self.init = function () {
            self.getSearchResultsFilteredByTitleName();
            getSearchResultCounts();
        };

        //INIT
        self.init();

    }

    controllers.controller("searchController", ["$rootScope", "searchService", "filmUrlGeneratorFactory", "shoppingCartService", "localStorageService", "filmRatingService", "API_EFD", "permissionQueueService", "DEFAULT_PAGE_SIZE","$cookies", "SECURE_COOKIES","DOMAIN", "assetCategoryConvertor", "AUDIO_VIDEO_ASSET_CATEGORY", searchController]);
}());
