(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    directives.directive("paginationLabel", [function () {

        /**
         * Pagination label controller
         * @class paginationLabelController
         * @memberOf ngular_module.WB.cinehub.directives
         * @param  {!angular.Scope} $scope angular scope
         * @param  {service} localStorageService local storage service
         */
        var paginationLabelController = function ($scope, localStorageService) {

            /**
             * translations
             * @type {boolean}
             * @memberOf angular_module.WB.cinehub.directives.paginationLabel
             * @public
             */
            $scope.translations = localStorageService.get("translations");

            $scope.$watch(function () {
                var currentPage = parseInt($scope.currentPage, 10);
                if (parseInt($scope.totalRecords, 10) === 0) {
                    $scope.startCount = 0;
                } else {
                    $scope.startCount = (currentPage * parseInt($scope.pageSize, 10)) - (parseInt($scope.pageSize, 10) - 1);
                }
                $scope.endCount = (currentPage * $scope.pageSize);
                if ($scope.endCount > $scope.totalRecords) {
                    $scope.endCount = $scope.totalRecords;
                }
            });
        };

        return {
            restrict: "AE",
            scope: {
                pageSize: "@",
                currentPage: "@",
                totalRecords: "@"
            },
            template: "<span>{{startCount > 0 ? startCount : 0}} - {{endCount || 0}} {{translations.pagination.labels.of}} {{totalRecords || 0}} {{translations.pagination.labels.results}}</span>",
            controller: ["$scope", "localStorageService", paginationLabelController]
        };

    }]);

}());
