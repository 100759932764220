(function ($) {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Asset list item directive
     * @class assetListItemDirective
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     * @param {constant} API_EFD API_EFD
     * @param {service} localStorageService local storage service
     * @param {constant} AUDIO_VIDEO_ASSET_CATEGORY AUDIO_VIDEO_ASSET_CATEGORY
     * @param {service} convertLocalizationCodeToValueService convert localization code to value service
     * @param {service} assetCategoryConvertor asset category convertor
     */
    function assetListItemDirective(API_EFD, localStorageService, AUDIO_VIDEO_ASSET_CATEGORY, convertLocalizationCodeToValueService, assetCategoryConvertor) {

        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @private
         * @memberOf angular_module.WB.cinehub.directives.assetListItemDirective
         */
        var link = function (scope) {

            /**
             * API_EFD
             * @type {constant}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.assetListItemDirective
             */
            scope.API_EFD = API_EFD;

            /**
             * AUDIO_VIDEO_ASSET_CATEGORY
             * @type {constant}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.assetListItemDirective
             */
            scope.AUDIO_VIDEO_ASSET_CATEGORY = AUDIO_VIDEO_ASSET_CATEGORY;

            /**
             * Asset category convertor
             * @public
             * @type {service}
             * @memberOf angular_module.WB.cinehub.directives.assetListItemDirective
             */
            scope.assetCategoryConvertor = assetCategoryConvertor;

            /**
             * User info
             * @public
             * @type {object}
             * @memberOf angular_module.WB.cinehub.directives.assetListItemDirective
             */
            scope.user = localStorageService.get("userInfo");

            /**
             * translations
             * @public
             * @type {object}
             * @memberOf angular_module.WB.cinehub.directives.assetListItemDirective
             */
            scope.translations = localStorageService.get("translations");
            
            /**
             * View As
             * @public
             * @type {object}
             * @memberOf angular_module.WB.cinehub.directives.assetListItemDirective
             */
            scope.viewAs = localStorageService.get("impersonateUserFlag");

            /**
             * convert localization code to value service 
             * @public
             * @type {service}
             * @memberOf angular_module.WB.cinehub.directives.assetListItemDirective
             */
            scope.convertLocalizationCodeToValueService = convertLocalizationCodeToValueService;

            /**
             * Remove
             * @function remove
             * @public
             * @memberOf angular_module.WB.cinehub.directives.assetListItemDirective
             */
            scope.remove = function () {
                if(!scope.viewAs) {
                    scope.removeCallback()(scope.assetData);
                }
            };

            /**
             * Use as tempalte
             * @function useAsTemplate
             * @public
             * @memberOf angular_module.WB.cinehub.directives.assetListItemDirective
             * @param {object} $event javascript event
             */
            scope.useAsTemplate = function ($event) {
                $event.stopPropagation();
                if (!scope.assetData.isSelected) {
                    scope.assetData.isSelected = true;
                    scope.onSelectCallback()(scope.assetData);
                }
                scope.useAsTemplateCallback()(scope.assetData);
            };

            /**
             * Open light box
             * @function openLightbox
             * @memberOf angular_module.WB.cinehub.directives.assetListItemDirective
             * @public
             */
            scope.openLightbox = function () {
                var action = scope.action ? scope.action : "permissionReviewOnly";
                scope.openLightBox()(null, scope.assetData, action);
            };

            /**
             * Toggle select
             * @function toggleSelect
             * @public
             * @memberOf angular_module.WB.cinehub.directives.assetListItemDirective
             * @param {object} $event javascript event 
             * @param {object} assetData asset data
             */
            scope.toggleSelect = function (assetData, $event) {
                if(scope.limitReached){
                    if (scope.assetData.isSelected) {
                        scope.assetData.isSelected = !scope.assetData.isSelected;
                        scope.onSelectCallback()(assetData);
                    }
                } else {
                    if ($($event.target).is("label") || $($event.target).hasClass("permission-indicator") || $($event.target).hasClass("fa-key")) {
                        return;
                    }
                    if (!scope.isOnlyDisplayPurpose) {
                        scope.assetData.isSelected = !scope.assetData.isSelected;
                        if (!scope.assetData.isSelected) {
                            scope.assetData.isTemplate = false;
                        }
                        scope.onSelectCallback()(assetData);
                    }
                }
                if(scope.viewAs) {
                    scope.assetData.isSelected = false;
                }
            };
        };

        return {
            replace: true,
            link: link,
            scope: {
                assetData: "=",
                removeCallback: "&",
                useAsTemplateCallback: "&",
                onSelectCallback: "&",
                isOnlyDisplayPurpose: "=",
                openLightBox: "&",
                action: "@",
                limitReached: "="
            },
            templateUrl: "assetListItemTemplate.html"
        };
    }

    directives.directive("assetListItemDirective", ["API_EFD", "localStorageService", "AUDIO_VIDEO_ASSET_CATEGORY", "convertLocalizationCodeToValueService", "assetCategoryConvertor", assetListItemDirective]);

}(window.jQuery));
