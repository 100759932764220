(function ($) {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Croppie directive
     * @class croppie
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     */
    function croppie() {

        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @param {jQueryElement} element element
         * @private
         * @memberOf angular_module.WB.cinehub.directives.croppie
         */
        var link = function (scope, element) {

            $(element).croppie("destroy");

            var basic = $(element).croppie({
                    enableExif: true,
                    viewport: scope.viewport,
                    boundary: scope.boundary
                }),
                $previewBtn = $("." + scope.previewBtnClass),
                $doneBtn = $("." + scope.doneBtnClass),
                croppieResultConfig = {
                    type: "canvas",
                    size: {
                        width: "1900"
                    },
                    quality: 0.1
                },
                applyCroppedResult = function (base64Image) {
                    scope.cropResult = base64Image;
                    scope.$apply();
                };

            $previewBtn.off().on("click", function () {
                basic.croppie("result", croppieResultConfig).then(applyCroppedResult);
            });

            $doneBtn.off().on("click", function () {
                basic.croppie("result", croppieResultConfig).then(function (base64Image) {
                    applyCroppedResult(base64Image);
                    scope.doneCallback()();
                });
            });

            scope.$watch("url", function (newUrl) {
                basic.croppie("bind", {
                    url: newUrl
                }).then(function () {
                    setTimeout(function () { //to consider rendering time
                        scope.onReadyCallback()();
                    }, 500);
                });
            });
        };

        return {
            link: link,
            scope: {
                url: "@",
                previewBtnClass: "@",
                doneBtnClass: "@",
                viewport: "=",
                boundary: "=",
                cropResult: "=",
                doneCallback: "&",
                onReadyCallback: "&"
            }
        };
    }

    directives.directive("croppie", [croppie]);
}(window.jQuery));
