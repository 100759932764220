(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Terms controller
     * @dec The terms controller
     * @class termsController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {service} termsService terms service
     * @param {!angular.Service} $sce $sce
     * @param {service} localStorageService local storage service
     * @param {constant} US_TERRITORY_ID US_TERRITORY_ID
     * @param {constant} US_LOCALE_ID US_LOCALE_ID
     */
    function termsController(termsService, $sce, localStorageService, US_TERRITORY_ID, US_LOCALE_ID) {

        /**
         * Instance of termsController
         * @type {termsController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.termsController
         */
        var self = this,

            /**
             * User info object
             * @type {object}
             * @memberOf angular_module.WB.cinehub.controllers.termsController
             * @private
             */
            user = localStorageService.get("userInfo"),

            /**
             * territory info object for registration flow
             * @type {object}
             * @memberOf angular_module.WB.cinehub.controllers.termsController
             * @private
             */
            territory = localStorageService.get("territoryInfo");
        
        /**
         * translations
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.termsController
         * @public 
         */
        self.translations = localStorageService.get("translations");

        /**
         * location
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.termsController
         * @public 
         */
        self.location = localStorageService.get("location");

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.termsController
         */
        self.pageHeaderItem = {
            pageTitle: self.translations.termsOfUse.labels.pageTitle,
            breadcrumbItems: [
                { linkTitle: self.translations.termsOfUse.labels.breadcrumbLinkTitle, link: "/terms" }
            ]
        };

        /**
         * Terms model
         * @public
         * @type {string}
         * @memberOf angular_module.WB.cinehub.controllers.termsController
         */
        self.terms = "";

        /**
         * Get terms
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.termsController
         * @function getTerms
         */
        var getTerms = function () {
            var errorMessage = self.translations.termsOfUse.messages.getTermsOfUseError,
                userTerritoryId = self.location && self.location.territoryId || user && user.territoryId || territory && territory.territoryId,
                userLocaleId = self.location && self.location.localeId || user && user.localeId || territory && territory.localeId,
                territoryId = userTerritoryId || US_TERRITORY_ID,
                localeId = userLocaleId || US_LOCALE_ID;

            termsService.getTerms(territoryId, localeId, errorMessage).success(function (result) {
                if (result._embedded.terms.length) {
                    self.terms = $sce.trustAsHtml(result._embedded.terms[0].terms); //based on locale server might return more then one terms but we need only the first one
                } else {
                    termsService.getTerms(US_TERRITORY_ID, US_LOCALE_ID, errorMessage).success(function (result) {
                        self.terms = $sce.trustAsHtml(result._embedded.terms[0].terms); //based on locale server might return more then one terms but we need only the first one
                    });
                }             
                    
            });
        };

        /**
         * Init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.termsController
         */
        self.init = function () {
            getTerms();
        };

        //INIT
        self.init();
    }

    controllers.controller("termsController", ["termsService", "$sce", "localStorageService", "US_TERRITORY_ID", "US_LOCALE_ID", termsController]);
} ());
