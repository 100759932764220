(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class searchService
     * @memberOf angular_module.WB.cinehub.services
     * @desc film service
     * @param {!angular.$http} $http angular $http service
     * @param {strict} API_ROOT API_ROOT constant  
     * @param {service} localStorageService local storage service
     */
    function searchService($http, API_ROOT, localStorageService) {

        /**
         * translations
         * @memberOf angular_module.WB.cinehub.controllers.searchService
         * @private
         * @type {object}
         */
        var translations = localStorageService.get("translations"),
            
            /**
             * location
             * @memberOf angular_module.WB.cinehub.controllers.searchService
             * @private
             * @type {object}
             */
            location = localStorageService.get("location"),

            /**
             * exhibitor id
             * @memberOf angular_module.WB.cinehub.services.filmService
             * @private
             * @type {int}
             */
            exhibitorId = localStorageService.get("currentExhibitorAffiliation") || -1,

            /**
             * theater id
             * @memberOf angular_module.WB.cinehub.services.filmService
             * @private
             * @type {int}
             */
            theaterId = localStorageService.get("currentTheaterAffiliation") || -1;

        /**
         * Get titles typeahead
         * @memberOf angular_module.WB.cinehub.services.searchService
         * @public
         * @function getTitles
         * @param {string} term term
         * @param {string} errorMessage error message 
         * @returns {promise}
         */
        this.getTitles = function (term, errorMessage) {
            return $http.post(API_ROOT + "films/search/findTypeAheadTitle?territoryId=" + location.territoryId + "&localeId=" + location.localeId + "&recType=pub&exhibitorId=" + exhibitorId + "&theaterId=" + theaterId + "&projection=titleOnly", {
                "searchTerm": term
            }, {
                errorMessage: errorMessage,
                showSpinner: false
            }).then(function (result) {
                return result.data.content;
            });
        };

        /**
         * Get search result filtered by title name
         * @memberOf angular_module.WB.cinehub.services.searchService
         * @public
         * @function getSearchResultsFilteredByTitleName
         * @param {string} term term
         * @param {int} size items per page
         * @param {int} page page number
         * @returns {promise}
         */
        this.getSearchResultsFilteredByTitleName = function (term, size, page) {
            return $http.post(API_ROOT + "films/search/findLocByTitleLike?territoryId=" + location.territoryId + "&localeId=" + location.localeId + "&recType=pub&exhibitorId=" + exhibitorId + "&theaterId=" + theaterId + "&page=" + page + "&size=" + size, {
                "searchTerm": term
            }, {
                errorMessage: translations.search.messages.searchForTitlesError
            });
        };

        /**
         * Get search result filtered by news
         * @memberOf angular_module.WB.cinehub.services.searchService
         * @public
         * @function getSearchResultsFilteredByNews
         * @param {string} term term
         * @param {int} size items per page
         * @param {int} page page number
         * @returns {promise}
         */
        this.getSearchResultsFilteredByNews = function (term, size, page) {
            return $http.post(API_ROOT + "newsItems/search/findLocByTitleOrFilmTitleLike?territoryId=" + location.territoryId + "&localeId=" + location.localeId + "&recType=pub&exhibitorId=" + exhibitorId + "&theaterId=" + theaterId + "&page=" + page + "&size=" + size + "&sort=lastModified,desc", {
                "searchTerm": term
            }, {
                errorMessage: translations.search.messages.searchForNewsItemsError
            });
        };

        /**
         * Get search result filtered by materials type
         * @memberOf angular_module.WB.cinehub.services.searchService
         * @public
         * @function getSearchResultsFilteredByMaterialTypes
         * @param {string} term term
         * @param {int} size items per page
         * @param {int} page page number
         * @param {string} assetType wbAssetType
         * @param {string} assetSubType wbpiAssetType
         * @returns {promise}
         */
        this.getSearchResultsFilteredByMaterialTypes = function (term, size, page, assetType, assetSubType) {
            return $http.post(API_ROOT + "filmAssets/search/getAssetsBySearchFilters?territoryId=" + location.territoryId + "&localeId=" + location.localeId + "&recType=pub&exhibitorId=" + exhibitorId + "&theaterId=" + theaterId + "&wbAssetType=" + assetType + "&wbpiAssetType=" + assetSubType + "&page=" + page + "&size=" + size + "&sort=lastModified,desc", {
                "searchTerm": term
            }, {
                errorMessage: translations.search.messages.searchForMaterialsError
            });
        };

        /**
         * Get asset types and subtypes for filters
         * @memberOf angular_module.WB.cinehub.services.searchService
         * @public
         * @function getAssetTypeSubtypeFilterOptions
         * @param {string} term term
         * @returns {promise}
         */
        this.getAssetTypeSubtypeFilterOptions = function (term, wbAssetType, wbpiAssetType) {
            return $http.post(API_ROOT + "filmAssets/findLocAssetTypeAndSubtypeForSearchMaterials?territoryId=" + location.territoryId + "&localeId=" + location.localeId + "&recType=pub&exhibitorId=" + exhibitorId + "&theaterId=" + theaterId + "&wbAssetType=" + wbAssetType + "&wbpiAssetType=" + wbpiAssetType, {
                "searchTerm": term
            }, {
                errorMessage: translations.search.messages.getSearchFilterOptionsError,
                showSpinner: false
            });
        };

        /**
         * Get search results count 
         * @memberOf angular_module.WB.cinehub.services.searchService
         * @public
         * @function getSearchResultCounts
         * @param {string} term term
         * @returns {promise}
         */
        this.getSearchResultCounts = function (term) {
            return $http.post(API_ROOT + "search/getSearchCount?territoryId=" + location.territoryId + "&localeId=" + location.localeId + "&recType=pub&exhibitorId=" + exhibitorId + "&theaterId=" + theaterId, {
                "searchTerm": term
            }, {
                errorMessage: translations.search.messages.getSearchResultCountsError,
                showSpinner: false
            });
        };
    }

    services.service("searchService", ["$http", "API_ROOT", "localStorageService", searchService]);
}());
