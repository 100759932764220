(function ($) {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Clear whitespace directive
     * @class clearWhitespaceDirective
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     */
    function clearWhitespaceDirective() {

        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @param {jQueryElement} element jquery element
         * @private
         * @memberOf angular_module.WB.cinehub.directives.clearWhitespaceDirective
         */
        var link = function (scope, element) {
            var $element = $(element);
            $element.bind("paste", function () {
                window.setTimeout(function () {
                    var text = "",
                        value = $element.val().split("\n");
                    $(value).each(function (i, v) {
                        v = $.trim(v);
                        if (v.length > 0) {
                            text += v + "\n";
                        }
                    });
                    $element.val(text);
                }, 1);
            });
        };

        return {
            restrict: "A",
            link: link
        };
    }

    directives.directive("clearWhitespace", [clearWhitespaceDirective]);
}(window.jQuery));
