(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * selectEncode Controller
     * @dec selectEncode Controller 
     * @class selectEncodeController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {service} selectEncodeService selectEncode service
     * @param {service} localStorageService local storage service
     * @param {$location} $location window location
     */
    function selectEncodeController(selectEncodeService,localStorageService,$uibModalInstance) {

        /**
         * Instance of selectEncodeController
         * @type {selectEncodeController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.selectEncodeController
         */
        var self = this;
        
           
        /**
         * translations
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.selectEncodeController
         * @public 
         */
        self.translations = localStorageService.get("translations");
        
        /**
         * location
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.selectEncodeController
         * @public 
         */
        self.location = localStorageService.get("location");

        self.encodeOptions;

        /**
         * selectEncode
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.selectEncodeController
         */
        self.jwtToken = {};
        self.tokenGenerate = false;
        self.data = {};
        /**
         * tab menus
         * @type {array}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @public
         */
        self.tabs = [{
            heading: "Browser Download",
            templateUrl: "/modules/selectEncodeModal/browserDownload/browserDownload.html",
            propertyName: "oneSheets",
            active: false,
            key: "editFilmModal.oneSheet",
            hasUpdates: false
        }, {
            heading: "SendPlus Download",
            templateUrl: "/modules/selectEncodeModal/sendPlusDownload/sendPlusDownload.html",
            propertyName: "trailers",
            active: false,
            key: "editFilmModal.trailer",
            hasUpdates: false
        }, {
            heading: "Add to Cart",
            templateUrl: "/modules/selectEncodeModal/addToCart/addToCart.html",
            active: false,
            key: "editFilmModal.titleInfo",
            hasUpdates: false
        }];
        self.getEncodes = function(){
            setTimeout(function () { 
                selectEncodeService.getEncodes("1223").success(function(result){
                    self.encodeOptions = result.data.AdstreamAsset.renditions;
                });
            },100);
        };
        
        /**
         * get JwtToken
         * @function getJwtToken 
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.selectEncodeController
         */
        self.setSendPlusUrl = function () {
            self.data = {
                "userFirstName": "Arvind",
                "userLastName": "Nadimpally",
                "userEmailId": "ar.na@wb.com",
                "title": "test",
                "adstreamAssetId": "59dc9de247c3fc728ea9991f",
                "adstreamEncodes" : ["59dc9d9de4b067b510e683bf","59dc9d9de4b067b510e683bd", "59dc9d9ee4b08777595cede0"]
            };
            selectEncodeService.getJwtToken(self.data).success(function (result) {
                self.jwtToken = result.data.jwtToken;
                var url = "sendplus:downloadrequest?sourceId=562a1b3362356f7f2ea7cb17&jwt="+ self.jwtToken;
                location.href = url;
            });
        };
        self.cancle = function () {
            $uibModalInstance.close();
        };
    }

    controllers.controller("selectEncodeController", ["selectEncodeService", "localStorageService","$uibModalInstance", selectEncodeController]);
}());
