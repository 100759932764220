(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class mediaTypeService
     * @memberOf angular_module.WB.cinehub.services
     * @desc utility functions for determining media type service
     */
    function mediaTypeService() {
        var videoRegex = new RegExp("^.*\.(mp4|mov|wmv|flv)$", "i");
        var audioRegex = new RegExp("^.*\.(ais|mp3|aiff)$", "i");

        /**
         * The audio constant
         * @constant AUDIO
         * @type {String}
         * @memberOf angular_module.WB.cinehub.services.mediaTypeService
         * @public
         */
        this.AUDIO = "AUDIO";

        /**
         * The video constant
         * @constant AUDIO
         * @type {String}
         * @memberOf angular_module.WB.cinehub.services.mediaTypeService
         * @public
         */
        this.VIDEO = "VIDEO";

        /**
         * Get the media type (audio/video) from a filename
         * @memberOf angular_module.WB.cinehub.services.mediaTypeService
         * @public
         * @function getMediaTypeFromFileName
         * @returns the media type for a given filename
         */
        this.getMediaTypeFromFileName = function (fileName) {
            if (typeof fileName !== "string") {
                return undefined;
            }
            if (videoRegex.test(fileName)) {
                return this.VIDEO;
            }
            if (audioRegex.test(fileName)) {
                return this.AUDIO;
            }
        };
    }
    services.service("mediaTypeService", [mediaTypeService]);
} ());