(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Download controller
     * @class downloadController
     * @memberOf angular_module.WB.cinehub.controllers.downloadController
     * @desc This is download controller
     * @param {!angular.$rootScope} $rootScope angular root scope
     * @param {service} downloadService download service 
     * @param {service} localStorageService local storage service 
     * @param {service} deviceDetectorService device detector service
     * @param {service} $window angular $window service
     */
    function downloadController($rootScope, downloadService, localStorageService, deviceDetectorService, $window) {

        /**
         * Instance to downloadController
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.downloadController
         * @type {downloadController}
         */
        var self = this,

            /**
             * User info
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.downloadController
             * @type {object}
             */
            user = localStorageService.get("userInfo");

        /**
         * translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.downloadController
         */
        self.translations = localStorageService.get("translations");

        /**
         * Device detector service
         * @type {service}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.downloadController
         */
        self.deviceDetectorService = deviceDetectorService;

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.downloadController
         */
        self.pageHeaderItem = {
            pageTitle: self.translations.downloadPage.labels.pageTitle,
            breadcrumbItems: [
                { linkTitle: self.translations.downloadPage.labels.breadcrumbLinkTitle, link: "/download" }
            ]
        };

        /**
         * init
         * @function init
         * @memberOf angular_module.WB.cinehub.controllers.downloadController
         * @public
         */
        self.init = function () {
            if(!self.deviceDetectorService.isMobileOrTablet()) {
                downloadService.verifyDownloadLink(user.userId, window.WB.cinehub.preload.downloadId).success(function (result) {
                    $rootScope.loading = true;
                    if(result.status.toLowerCase() === "success") {
                        $rootScope.loading = false;
                        downloadService.download(user.userId, window.WB.cinehub.preload.downloadId);
                    } else {
                        $window.location.href = "/error/" + result.code;
                    }
                });
            }
        };

        self.init();

    }

    controllers.controller("downloadController", ["$rootScope", "downloadService", "localStorageService", "deviceDetectorService", "$window", downloadController]);
} ());
