(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Review permission accordion directive
     * @class reviewPermissionAccordionDirective
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     * @param {service} localStorageService local storage service
     * @param {service} convertLocalizationCodeToValueService convert localization code to value service
     */
    function reviewPermissionAccordionDirective(localStorageService, convertLocalizationCodeToValueService) {

        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @private
         * @memberOf angular_module.WB.cinehub.directives.reviewPermissionAccordionDirective
         */
        var link = function (scope) {

            /**
             * Translations
             * @public
             * @type {object}
             * @memberOf angular_module.WB.cinehub.directives.reviewPermissionAccordionDirective
             */
            scope.translations = localStorageService.get("translations");

            /**
             * User info
             * @public
             * @memberOf angular_module.WB.cinehub.directives.reviewPermissionAccordionDirective
             * @type {object}
             */
            scope.user = localStorageService.get("userInfo");

            /**
             * Permission items reduced 
             * @public
             * @memberOf angular_module.WB.cinehub.directives.reviewPermissionAccordionDirective
             * @type {object[]}
             */
            scope.permissionItemsReduced = scope.permissionItems.splice(0, scope.sliceSize);

            /**
             * Conver localization code to value service
             * @public
             * @type {service}
             * @memberOf angular_module.WB.cinehub.directives.reviewPermissionAccordionDirective
             */
            scope.convertLocalizationCodeToValueService = convertLocalizationCodeToValueService;

            /**
             * Display more records 
             * @public
             * @function displayMore
             * @memberOf angular_module.WB.cinehub.directives.reviewPermissionAccordionDirective
             */
            scope.displayMore = function () {
                var slice = scope.permissionItems.splice(0, scope.sliceSize);
                scope.permissionItemsReduced = scope.permissionItemsReduced.concat(slice);
            };

            scope.$watch("isExpandedAll", function (newVal) {
                scope.permissionItemsReduced.forEach(function (item) {
                    item.open = newVal;
                });
                scope.permissionItems.forEach(function (item) {
                    item.open = newVal;
                });
            });

            scope.$watch("permissionItems", function (newVal) {
                if (newVal && newVal.length > 0) {
                    scope.permissionItems = newVal;
                    var slice = scope.permissionItems.splice(0, scope.sliceSize);
                    scope.permissionItemsReduced = scope.permissionItemsReduced.concat(slice);
                }
            });
        };

        return {
            link: link,
            scope: {
                permissionItems: "=",
                isExpandedAll: "=",
                sliceSize: "=",
                scrollAreaSelector: "@"
            },
            templateUrl: "reviewPermissionAccordionTemplate.html"
        };
    }

    directives.directive("reviewPermissionAccordionDirective", ["localStorageService", "convertLocalizationCodeToValueService", reviewPermissionAccordionDirective]);

}());
