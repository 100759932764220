(function () {
    "use strict";
    var directives = angular.module("WB.cinehub.directives");
    directives.directive("ngBindHtmlTrusted", ["$sce", function ($sce) {
        return {
            scope: {
                ngBindHtmlTrusted: "="
            },
            template: "<div ng-bind-html='trustedHtml'></div>",
            link: function ($scope) {
                $scope.updateView = function (newVal) {
                    $scope.trustedHtml = $sce.trustAsHtml(newVal);
                };

                $scope.$watch("ngBindHtmlTrusted", function (newVal) {
                    $scope.updateView(newVal);
                });
            }
        };
    }]);
} ());