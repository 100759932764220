(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Wb email validation from directive
     * @class wbEmailValidator
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     * @param {service} emailService email service
     * @param {service} $q $q service
     */
    function wbEmailValidator(emailService,  $q) {
        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @param {jqueryElement} element jquery element
         * @param {object} attr attributes
         * @param {object} ngModel angular model
         * @private
         * @memberOf angular_module.WB.cinehub.directives.wbEmailValidator 
         */
        var link = function (scope, element, attr, ngModel) {
            ngModel.$validators.cantChangePasswordForWbUser = function (modelValue, viewValue) {
                if (ngModel.$isEmpty(modelValue)) {
                    // consider empty models to be valid
                    return true;
                }
                var def = $q.defer();
                emailService.isEmailWB(viewValue).success(function(result) {
                    def.resolve();
                    if (result.data.isInternalAccount) {
                        ngModel.$setValidity("internalAccount", false);
                    } else {
                        ngModel.$setValidity("internalAccount", true);
                    }
                }).error(function (error) {
                    def.reject();
                    if (error) {
                        ngModel.$setValidity("resetPasswordError", true);
                    }
                });
                return def.promise;
            };
        };

        return {
            require: "ngModel",
            link: link
        };
    }

    directives.directive("wbEmailValidator", ["emailService", "$q", wbEmailValidator]);
}());
