(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Film controller
     * @dec The film controller 
     * @class angular_module.WB.cinehub.controllers.filmStrategyController
     * @param {!angular.$state} $state angular state service 
     * @param {service} localStorageService local storage service
     */
    function filmStrategyController($state, localStorageService) {

        /**
         * Instance to filmStrategyController
         * @type {filmStrategyController}
         * @memberOf angular_module.WB.cinehub.controllers.filmStrategyController
         * @private
         */
        var self = this;

        /**
         * translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmStrategyController
         */
        self.translations = localStorageService.get("translations");
                    
        /**
         * Strategy tabs definition 
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmStrategyController 
         */
        self.strategyTabs = [
            {
                heading: self.translations.filmDetails.labels.trailerPlacementStrategyTab,
                route: "film.strategy.trailerPlacement",
                defaultHeading: "trailerPlacement",
                count: 0
            },
            {
                heading: self.translations.filmDetails.labels.generalStrategyTab,
                route: "film.strategy.general",
                defaultHeading: "general",
                count: 0
            },
            {
                heading: self.translations.filmDetails.labels.inTheaterStrategyTab,
                route: "film.strategy.inTheater",
                defaultHeading: "inTheater",
                count: 0
            },
            {
                heading: self.translations.filmDetails.labels.onlinePromotionStrategyTab,
                route: "film.strategy.onlinePromotion",
                defaultHeading: "onlinePromotion",
                count: 0
            }
        ];

        /**
         * Set active tab 
         * @function active
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.filmStrategyController
         * @param {string} route tab route 
         */
        self.active = function (route) {
            return $state.current.name.indexOf(route) > -1;
        };
    }

    controllers.controller("filmStrategyController", ["$state", "localStorageService", filmStrategyController]);
} ());
