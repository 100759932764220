(function() {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class assetPickerService
     * @memberOf angular_module.WB.cinehub.services
     * @desc Add edit news service
     * @param {!angular.$http} $http angular $http service
     * @paarm {strict} API_ROOT API_ROOT constant
     * @param {service} localStorageService
     */
    function assetPickerService($http, API_ROOT, localStorageService) {

        /**
         * Location
         * @type {object}
         * @private
         * @memberOf angular_module.WB.cinehub.services.assetPickerService
         */
        var location = localStorageService.get("location"),

            /**
             * translations
             * @type {object}
             * @memberOf angular_module.WB.cinehub.services.assetPickerService
             * @private
             */
            translations = localStorageService.get("translations");

        /**
         * get spotlight details along with assets
         * @memberOf angular_module.WB.cinehub.services.assetPickerService
         * @public
         * @param {int} newsId spotlight id
         * @param {int} filmId film id
         * @param {string} wbAssetType wb asset type
         * @param {string} wbpiAssetType wbpi asset type
         * @param {string} fileType file type
         * @param {str} size page size
         * @param {str} page page index
         * @param {str} sort sorting
         * @param {str} errorMessage error message
         * @returns {promise}
         */
        this.getSpotlightDetails = function (spotlightId, filmId, wbAssetType, wbpiAssetType, fileType, size, page, sort, errorMessage) {
            return $http.post(API_ROOT + "spotlightItems/search/findLocById?size=" + size + "&page=" + page + "&sort=" + sort , {
                "recordId" : spotlightId,
                "filmId": filmId,
                "wbAssetType": wbAssetType,
                "wbpiAssetType": wbpiAssetType,
                "fileType": fileType,
                "territoryId": location.territoryId,
                "localeId": location.localeId
            }, {
                errorMessage : errorMessage
            });
        };
        /**
         * Get Asset Picker
         * @memberOf angular_module.WB.cinehub.services.assetPickerService
         * @public
         * @function getAssetPicker
         * @param {int} filmId film id
         * @param {string} wbAssetType wb asset type
         * @param {string} wbpiAssetType wbpi asset type
         * @param {string} fileType file type
         * @param {int} size page size
         * @param {int} page page number
         * @param {string} sort sort
         * @returns {promise}
         */
        this.getAssetPickerMaterials = function (spotlightId, filmId, wbAssetType, wbpiAssetType, fileType, size, page, sort, spotlightFilmIds) {
            if(spotlightFilmIds && filmId && filmId.length === 0){
                filmId = spotlightFilmIds;
            }
            return $http.post(API_ROOT + "spotlightItems/search/getAssetsForAssetPicker?size=" + size + "&page=" + page + "&sort=" + sort, {
                "spotlightId": spotlightId,
                "filmId": filmId,
                "wbAssetType": wbAssetType,
                "wbpiAssetType": wbpiAssetType,
                "fileType": fileType,
                "territoryId": location.territoryId,
                "localeId": location.localeId 
            }, {
                errorMessage: translations.spotlight.messages.fetchingAssetsInAssetPickerErrorMessage
            });
        };

        /**
         * getFiltersValuesForAssetPicker
         * @memberOf angular_module.WB.cinehub.services.assetPickerService
         * @public
         * @function getFilmTitles
         * @returns {promise}
         */
        this.getFiltersValuesForAssetPicker = function (spotlightId, filmId, wbAssetType, wbpiAssetType, fileType, spotlightFilmIds) {
            if(spotlightFilmIds && filmId && filmId.length === 0){
                filmId = spotlightFilmIds;
            }
            return $http.post(API_ROOT + "spotlightItems/findLocFilmsAndAssetTypeAndSubtypeAndFileType" , {
                "spotlightId": spotlightId,
                "filmId": filmId,
                "wbAssetType": wbAssetType,
                "wbpiAssetType": wbpiAssetType,
                "fileType": fileType,
                "territoryId": location.territoryId,
                "localeId": location.localeId
            }, {
                errorMessage: translations.toDo.messages.cannotGetFilterValues,
                showSpinner: true
            });
        };

    }

    services.service("assetPickerService", ["$http", "API_ROOT", "localStorageService", assetPickerService]);
}());
