(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Timeout controller
     * @class timeoutController
     * @memberOf angular_module.WB.cinehub.controllers
     * @desc This is timeout controller
     * @param {!angular.$scope} $scope angular scope
     * @param {Idle} Idle Idle
     * @param {$uibModal} $uibModal uib modal
     * @param {!angular.$timeout} $timeout timeout
     * @param {!angular.$window} $window window object
     * @param {int} TIMEOUT_COUNTDOWN TIMEOUT_COUNTDOWN constant
     * @param {service} localStorageService local storage service
     */
    function timeoutController($scope, Idle, $uibModal, $timeout, $window, TIMEOUT_COUNTDOWN, localStorageService) {

        /**
         * Timeout sec
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.timeoutController
         * @type {int}
         */
        self.timeoutSec = TIMEOUT_COUNTDOWN;

        /**
         * Countdown copy
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.timeoutController
         * @type {int}
         */
        self.countdown = _.clone(self.timeoutSec);

        /**
         * Close modals
         * @function closeModals
         * @memberOf angular_module.WB.cinehub.controllers.timeoutController
         * @private
         */
        function closeModals() {
            if (self.warningModal) {
                self.warningModal.close();
                self.warningModal = null;
            }

            if (self.timeoutModal) {
                self.timeoutModal.close();
                self.timeoutModal = null;
            }
        }

        /**
         * @event IdleStart
         * @listens IdleStart
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.timeoutController
         */
        $scope.$on("IdleStart", function () {
            closeModals();
            self.warningModal = $uibModal.open({
                templateUrl: "/shared/timeoutModal/warningDialog.html",
                controller: ["$scope", "TIMEOUT_COUNTDOWN", function ($scope, TIMEOUT_COUNTDOWN) {
                    $scope.timeoutSec = TIMEOUT_COUNTDOWN;
                }]
            });
        });

        /**
         * @event IdleEnd
         * @listens IdleEnd
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.timeoutController
         */
        $scope.$on("IdleEnd", function () {
            closeModals();
        });

        /**
         * @event IdleTimeout
         * @listens IdleTimeout
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.timeoutController
         */
        $scope.$on("IdleTimeout", function () {
            closeModals();
            self.timeoutModal = $uibModal.open({
                templateUrl: "/shared/timeoutModal/timeoutDialog.html"
            });

            self.timeoutModal.result.then(function () {
            }, function () {
                localStorageService.remove("userInfo");
                $window.location.href = "/login?logout";
            });

            $timeout(function () {
                localStorageService.remove("userInfo");
                $window.location.href = "/login?logout";
            }, 5000);
        });

        /**
         * Start
         * @function start
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.timeoutController
         */
        self.start = function () {
            closeModals();
            Idle.watch();
        };

        self.start();
    }

    controllers.controller("timeoutController", ["$scope", "Idle", "$uibModal", "$timeout", "$window", "TIMEOUT_COUNTDOWN", "localStorageService", timeoutController]);
} ());
