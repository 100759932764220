(function ($) {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * downloadOptions controller
     * @dec downloadOptions controller
     * @class downloadOptionsController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {service} getRenditionsService getRenditions service
     * @param {service} localStorageService local storage service
     * @param {constant} API_EFD API_EFD
     */
    function downloadOptionsController(getRenditionsService,$scope,$rootScope,$location,localStorageService,shoppingCartService,API_EFD,$uibModal) {

        /**
         * Instance to downloadOptionsController
         * @memberOf angular_module.WB.cinehub.controllers.downloadOptionsController
         * @private
         * @type {downloadOptionsController}
         */
        var self = this,
            currentUser = localStorageService.get("userInfo"),

            /**
             * location
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.permissionQueueService
             * @type {object}
             */
            location = localStorageService.get("location");
        /**
         * exhibitor id
         * @memberOf angular_module.WB.cinehub.controllers.downloadOptionsController
         * @private
         * @type {int}
         */
        self.exhibitorId = localStorageService.get("currentExhibitorAffiliation") || -1;

        /**
         * theater id
         * @memberOf angular_module.WB.cinehub.controllers.downloadOptionsController
         * @private
         * @type {int}
         */
        self.theaterId = localStorageService.get("currentTheaterAffiliation") || -1;
        /**
         * API_EFD
         * @constant
         * @memberOf angular_module.WB.cinehub.controllers.downloadOptionsController
         * @private
         * @type {string}
         */
        self.API_EFD = API_EFD;
        /**
         * translations
         * @memberOf angular_module.WB.cinehub.controllers.downloadOptionsController
         * @public
         * @type {object}
         */
        self.translations = localStorageService.get("translations");

        /**
         * view As
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.downloadOptionsController
         * @public
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");
        self.asset = $scope.asset;
        self.encodeItem = {};
        /**
         * Is asset added to shopping cart flag
         * @type {boolean}
         * @memberOf angular_module.WB.cinehub.directives.downloadOptionsController
         * @public
         */
        $scope.isAssetAddedToShoppingCart = false;

        $scope.selectEncodes = function(data) {
            $scope.selectedEncodes = [];
            for(var i in data){
                if(data[i].SELECTED){
                    if(data[i].fileId == null){
                        data[i].assetId = data[i].assetId.toString();
                        $scope.selectedEncodes.push(data[i].assetId);
                    }
                    else{
                        var encodeItem = {assetId : "",fileId : ""};
                        encodeItem.assetId = data[i].adstreamAssetId.toString();
                        encodeItem.fileId = data[i].fileId.toString();
                        var spotlight = self.asset.spotlightParentId ? self.asset.spotlightParentId : "NO";
                        $scope.selectedEncodes.push(encodeItem);
                        // data[i].fileId = data[i].fileId.toString();
                        // $scope.selectedEncodes.push(data[i].fileId);
                        if ($scope.displayCartEncodes) {
                            $scope.urlDownload =  self.API_EFD + "efd/sourceFiles/" + self.asset.assetId + "/" + currentUser.userId + "?territoryId=" + 
                            currentUser.territoryId+ "&localeId="+ currentUser.localeId + "&exhibitorId="+self.exhibitorId + "&theaterId="+ self.theaterId + "&fileId=" 
                            + data[i].fileId + encodeItem.fileId + "&elementId=" + encodeItem.assetId +"&filename=" + data[i].filename + "&fileSize=" + data[i].fileSize
                            + "&spotlight=" + spotlight;
                        }
                        else{
                            $scope.urlDownload =  self.API_EFD + "efd/sourceFiles/" + self.asset.id + "/" + currentUser.userId + "?territoryId=" + 
                            currentUser.territoryId + "&localeId=" + currentUser.localeId + "&exhibitorId=" + self.exhibitorId + "&theaterId=" + self.theaterId  
                            + "&fileId=" + encodeItem.fileId + "&elementId=" + encodeItem.assetId +"&filename=" + data[i].filename + "&fileSize=" + data[i].fileSize
                            + "&spotlight=" + spotlight;
                        }
                    }                    
                }
            }
        };
        $scope.toggleAll = function(data) {
            var toggleStatus = $scope.isAllSelected;
            angular.forEach(data, function(options){ options.SELECTED = toggleStatus; $scope.selectedEncodes.push(options.assetId);});
        };
  
        $scope.optionToggled = function(){
            $scope.isAllSelected = $scope.encodeOptions.every(function(options){ return options.SELECTED; });
        };
        $scope.getEfdUrl = function () {
            var spotlight = self.asset.spotlightParentId ? self.asset.spotlightParentId : "NO";
            if ($scope.displayCartEncodes) {
                return self.API_EFD + "efd/sourceFiles/" + self.asset.assetId + "/" + currentUser.userId + "?territoryId=" + currentUser.territoryId+
                       "&localeId=" + currentUser.localeId+"&exhibitorId=" + self.exhibitorId+"&theaterId="+self.theaterId +"&fileId="
                       + $scope.encodeOptions[0].fileId + "&elementId=" + $scope.encodeOptions[0].adstreamAssetId + "&filename=" + encodeURIComponent($scope.encodeOptions[0].filename) + "&fileSize=" + $scope.encodeOptions[0].fileSize
                       + "&spotlight=" + spotlight;
                
            }
            if ($scope.asset.id) {
                return self.API_EFD + "efd/sourceFiles/" + self.asset.id + "/" + currentUser.userId + "?territoryId="+currentUser.territoryId+
                       "&localeId="+currentUser.localeId + "&exhibitorId=" + self.exhibitorId + "&theaterId=" + self.theaterId + "&fileId=" 
                        + $scope.encodeOptions[0].fileId + "&elementId=" + $scope.encodeOptions[0].adstreamAssetId + "&filename=" + encodeURIComponent($scope.encodeOptions[0].filename) + "&fileSize=" + $scope.encodeOptions[0].fileSize
                        + "&spotlight=" + spotlight;
                
            } else {
                return "";
            }
        };
        self.installSendPlus = function(){
            $uibModal.open({
                templateUrl: "sendPlusInstallTemplate.html",
                controller: "modalConfirmationController",
                controllerAs: "modalConfirmationCtrl",
                keyboard: false,
                backdrop: "static",
                size: "md",
                resolve: {
                    message: function () {
                        return self.translations.downloadManagerModal.messages.requestInstall;
                    },
                    headerTitle: function () {
                        return self.translations.downloadManagerModal.labels.modalTitle;
                    },
                    showAffirmativeBtn: function () {
                        return true;
                    },
                    affirmativeBtnText: function () {
                        return self.translations.downloadManagerModal.labels.installButton;
                    },
                    cancelBtnText: function () {
                        return self.translations.downloadManagerModal.labels.cancelButton;
                    }
                }
            });
        };
        /**
         * get JwtToken
         * @function getJwtToken 
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.downloadOptionsController
         */
        $scope.setSendPlusUrl = function () {  
            self.url = " ";          
            if($scope.encodeOptions.length == 1){
                self.encodeItem.assetId = $scope.encodeOptions[0].adstreamAssetId;
                self.encodeItem.fileId = $scope.encodeOptions[0].fileId;
                $scope.selectedEncodes.push(self.encodeItem);
                // $scope.selectedEncodes.push($scope.encodeOptions[0].fileId);
            }
            var spotlight = $scope.asset.spotlightParentId ? $scope.asset.spotlightParentId : "";
            self.data = {
                "userFirstName": currentUser.firstName,
                "userLastName": currentUser.lastName,
                "userEmailId": currentUser.email,
                "adstreamAssetId": $scope.asset.adstreamAssetId,
                "adstreamEncodes" :  $scope.selectedEncodes,
                "assetId": $scope.asset.id,
                "fileName" :  $scope.asset.fileName,
                "userId" : currentUser.userId,
                "territoryId" : currentUser && currentUser.role === "homeOfficeAdmin" ? -1 : location && location.territoryId ? location.territoryId : -1,
                "localeId" : currentUser && currentUser.role === "homeOfficeAdmin" ? -1 : location && location.localeId ? location.localeId : -1,
                "exhibitorId" : self.exhibitorId,
                "theaterId" : self.theaterId,
                "title":$scope.asset.filmTitle,
                "spotlight":spotlight
            };
            getRenditionsService.getJwtToken(self.data).success(function (result) {
                self.jwtToken = result.data.jwtToken;
                self.url = "sendPlus:downloadrequest?sourceId=562a1b3362356f7f2ea7cb17&jwt="+ self.jwtToken;
                // location.href = self.url;
                window.protocolCheck(self.url,function () {
                    self.installSendPlus();
                });
            }).finally(function(){
                $scope.selectedEncodes.length = 0;
            });
            if ($scope.isInDownloadCart) {
                shoppingCartService.removeFromCart(self.asset).success(function () {
                    $rootScope.$emit("updateShoppingCartCount", true);
                    $rootScope.$emit("updateDownloadcart", true);
                });
            }
        };

        /**
         * Remove large asset from cart
         * @function removeLargeAssetFromCart
         * @memberOf angular_module.WB.cinehub.controllers.downloadOptionsController
         * @public
         */
        $scope.removeLargeAssetFromCart = function () {
            if ($scope.isInDownloadCart) {
                shoppingCartService.removeFromCart(self.asset).success(function () {
                    $rootScope.$emit("updateShoppingCartCount", true);
                    $rootScope.$emit("updateDownloadcart", true);
                });
            }
        };

        /**
         * Add to cart
         * @function addToCart
         * @memberOf angular_module.WB.cinehub.controllers.downloadOptionsController
         * @public
         */
        $scope.addToCart = function () {
            if ($scope.isAssetAddedToShoppingCart) {
                return;
            }
            if ($scope.encodeOptions[0].fileId == null) {
                $scope.selectedEncodes = null;
            }
            else if($scope.encodeOptions.length == 1){
                self.encodeItem.assetId = $scope.encodeOptions[0].adstreamAssetId;
                self.encodeItem.fileId = $scope.encodeOptions[0].fileId;
                $scope.selectedEncodes.push(self.encodeItem);
                // $scope.selectedEncodes.push($scope.encodeOptions[0].fileId);
            }
            shoppingCartService.addToCart($scope.asset,$scope.selectedEncodes).success(function () {
                $rootScope.$emit("updateShoppingCartCount", true);
                //getShoppingCartAssets();
            }).finally(function(){
                $scope.selectedEncodes.length = 0;
            });
        };
        self.init = function () {
            if($location.absUrl().indexOf("cart") > -1) {
                $scope.isInDownloadCart = true;
            } 
            else {
                $scope.isInDownloadCart = false;
            }

        };
        self.init();
        $(function(){
            $("html").click(function(e) {   
                if( !$(e.target).closest(".ns-popover-tooltip-theme").length>0){
                    $scope.selectedEncodes = [];
                    $scope.selectedEncodes.length = 0;
                }
            });
        });
    }

    controllers.controller("downloadOptionsController", ["getRenditionsService","$scope","$rootScope","$location", "localStorageService","shoppingCartService","API_EFD","$uibModal", downloadOptionsController]);
} (window.jQuery));
