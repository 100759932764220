(function (g) {
    "use strict";
    /**
     * Mocks $http promise
     */
    g.httpify = function (promise) {
        promise.success = function (fn) {
            promise.then(function (response) {
                fn(response);
            });
            return promise;
        };

        promise.error = function (fn) {
            promise.then(null, function (response) {
                fn(response);
            });
            return promise;
        };

        return promise;
    };

    /**
     * Mocks $modal promise
     */
    g.modalPromise = {
        result: {
            then: function (confirmCallback, cancelCallback) {
                //Store the callbacks for later when the user clicks on the OK or Cancel button of the dialog
                this.confirmCallBack = confirmCallback;
                this.cancelCallback = cancelCallback;
            },
            "finally": function (callback) {
                this.finalCallback = callback;
            }
        },
        close: function (item) {
            //The user clicked OK on the modal dialog, call the stored confirm callback with the selected item
            this.result.confirmCallBack(item);
        },
        dismiss: function (type) {
            //The user clicked cancel on the modal dialog, call the stored cancel callback
            this.result.cancelCallback(type);
        }
    };

    /**
     * Mocks $modalInstance
     */
    g.modalInstanceMock = {
        dismiss: function () { },
        close: function () { }
    };

}(window));
