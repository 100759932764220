(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Header controller
     * @class headerController
     * @memberOf angular_module.WB.cinehub.controllers
     * @desc This is header controller
     * @param {!angular.$rootScope} $rootScope angular root scope
     * @param {service} locationService location service
     * @param {!angular.$window} $window window instance
     * @param {service} searchService search service
     * @param {service} localStorageService local storage service
     * @param {service} deviceDetectorService device detector service
     * @param {service} translateService translate service
     * @param {service} exhibitorsService exhibitors service
     * @param {service} additionalAffiliationService additionalAffiliation service
     * @param {service} convertLocalizationCodeToValueService conver localization code to value service
     * @param {service} toDoService todo service
     * @param {service} shoppingCartService shopping cart service
     * @param {service} permissionQueueService permission cart service
     * @param {service} loginService login service
     * @param {service} $cookies $cookies service
     * @param {constant} DOMAIN DOMAIN
     */
    function headerController($rootScope,
        $scope,
        locationService,
        $window,
        searchService,
        localStorageService,
        deviceDetectorService,
        translateService,
        exhibitorsService,
        convertLocalizationCodeToValueService,
        toDoService,
        shoppingCartService,
        permissionQueueService,
        loginService,
        additionalAffiliationService,
        spotlightDetailsService,
        $cookies,
        DOMAIN,
        SECURE_COOKIES,
        OAUTH_SESSION_LIFETIME) {

        /**
         * Instance to headerController
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @private
         * @type {headerController}
         */
        var self = this,

            /**
             * Current user
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.headerController
             */
            currentUser = localStorageService.get("userInfo"),

            /**
             * Location
             * @type {object}
             * @memberOf angular_module.WB.cinehub.controllers.headerController
             * @private
             */
            location = localStorageService.get("location");

        /**
         * Conver localization code to value servcie
         * @type {service}
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         */
        self.convertLocalizationCodeToValueService = convertLocalizationCodeToValueService;

        /**
         * User
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         */
        self.user = localStorageService.get("userInfo");

        /**
         * Translations
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         */
        self.translations = localStorageService.get("translations");
        
        /**
         * view As
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");

        /**
         * Header term model property
         * @type {string}
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         */
        self.searchTerm = "";

        /**
         * locale to be used on the page
         * @type {string}
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         */
        self.locale = "";

        /**
         * Exhibitor relantionship
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         */
        self.exhibitorRelationship = null;

        /**
         * Theater affiliations
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         */
        self.theaterAffiliations = null;

        /**
         * Exhibitor affiliations
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         */
        self.exhibitorAffiliation = {};

        /**
         * Theater affiliation
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         */
        self.theaterAffiliation = {};

        /**
         * Device detector service
         * @type {service}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         */
        self.deviceDetectorService = deviceDetectorService;

        /**
         * Territory languages
         * @type {array}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         */
        self.territoryLanguages = [];

        /**
         * Territory
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         */
        self.territory = null;

        /**
         * Locale text
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         */
        self.localeText = "";

        /**
         * Shopping cart badge count
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @type {int}
         * @public
         */
        self.shoppingCartCount = 0;

        /**
         * Permission cart badge count
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @type {int}
         * @public
         */
        self.permissionCartCount = 0;

        /**
         * UserAffiliation has additional affiliations
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @type {int}
         * @public
         */
        self.isAdditionalAffiliation =  additionalAffiliationService.isAdditionalAffiliation(self.user);

        /**
         * find exhibitor by id
         * @private
         * @function findExhibitorById
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         */
        var findExhibitorById = function () {
            exhibitorsService.findById(self.user.exhibitorId).success(function (result) {
                self.user.primaryExhibitor = result._embedded.exhibitors[0];
                localStorageService.set("userInfo", self.user);

                buildExhibitorsList();
            });
        };

        /**
         * build exibitors list
         * @function buildExhibitorsList
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         */
        var buildExhibitorsList = function () {
            var exhibitorAffiliation;
            self.user = localStorageService.get("userInfo"); // when this method fired from angular even lister we want make sure we have the latest user data from storage
            self.exhibitorRelationship = self.user.exhibitorRelationship && window.helpers.isJson(self.user.exhibitorRelationship) ? JSON.parse(self.user.exhibitorRelationship) : [];

            if ((self.user.userType === "ex-corporate" || self.user.userType === "ex-other") && !self.isAdditionalAffiliation) {
                self.exhibitorRelationship.unshift(self.user.primaryExhibitor);
            }

            if ((self.user.userType === "ex-corporate" || self.user.userType === "ex-other") && self.isAdditionalAffiliation) {
                processAdditionalExhibitors();
                setCurrentExhibitorDetails();
            } else {
                exhibitorAffiliation = localStorageService.get("currentExhibitorAffiliation") || _.head(self.exhibitorRelationship).id;
                self.exhibitorAffiliation = exhibitorAffiliation;
                self.exhibitorAffiliationText = self.exhibitorAffiliation ? _.find(self.exhibitorRelationship, {
                    id: parseInt(self.exhibitorAffiliation, 10)
                }).localName : _.head(self.exhibitorRelationship).localName;
            }
        };

        /**
         * process additional exhibitors
         * @function processAdditionalExhibitors
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         */
        var processAdditionalExhibitors = function () {
            self.exhibitorRelationship = [];
            self.additionalTerritoryList = [];
            var primaryExhibitor = null;
            if(self.user.userType === "ex-other") {
                var exhibitorExOther = window.helpers.isJson(self.user.exhibitorRelationship) ? JSON.parse(self.user.exhibitorRelationship) : self.user.exhibitorRelationship;
                //primaryExhibitor = (exhibitorExOther && exhibitorExOther.length > 0) ? exhibitorExOther[0] : "";
                if (Array.isArray(exhibitorExOther) && exhibitorExOther.length > 0) {
                    primaryExhibitor = exhibitorExOther[0];
                } else {
                    primaryExhibitor = "";
                }
            } else {
                primaryExhibitor = _.find(self.user.userAffiliations,  {
                    exhibitorId: self.user.exhibitorId
                });
            }
            
            var territory= _.find(self.translations.values.territories,  {
                id: self.user.territoryId
            });
            if(primaryExhibitor){
                self.exhibitorRelationship.push(primaryExhibitor);
            }
            self.additionalTerritoryList.push(territory);  

            self.user.userAffiliations.forEach (function (affiliation) {                   
                self.user.addlTerrStatusList.forEach (function (addlTerrStatus) {
                    if (affiliation.territoryId === addlTerrStatus.territoryId && addlTerrStatus.status === "active") {
                        var territory= _.find(self.translations.values.territories,  {
                            id: affiliation.territoryId
                        });
                        self.additionalTerritoryList.push(territory);  
                        self.exhibitorRelationship.push(affiliation);
                    }
                });
            });
            if(self.user.userType === "ex-other") {
                var uniqueTerritoryList = [];
                var seen = {}; // Object to track seen items
                var additionalTerritoryList = _.cloneDeep(self.additionalTerritoryList);
                for (var i = 0; i < additionalTerritoryList.length; i++) {
                    var item = additionalTerritoryList[i];
                    if (!seen[item.id]) { // Check if id is already added
                        seen[item.id] = true;
                        uniqueTerritoryList.push(item);
                    }
                }
                self.additionalTerritoryList = uniqueTerritoryList;
            }            
        };

        /**
         * set current  exhibitor details
         * @function setCurrentExhibitorDetails
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         */
        var setCurrentExhibitorDetails = function () {
            var exhibitorAffiliation = localStorageService.get("currentExhibitorAffiliation");
            if (!exhibitorAffiliation && self.exhibitorRelationship && self.exhibitorRelationship.length > 0) {
                var firstExhibitor = _.head(self.exhibitorRelationship);
                exhibitorAffiliation = firstExhibitor.exhibitorId || firstExhibitor.id;
            }
            if (exhibitorAffiliation) {
                self.exhibitorAffiliation = exhibitorAffiliation.toString();
            }

            //var exhibitorAffiliation = localStorageService.get("currentExhibitorAffiliation") || (_.head(self.exhibitorRelationship) && _.head(self.exhibitorRelationship).exhibitorId ? _.head(self.exhibitorRelationship).exhibitorId : _.head(self.exhibitorRelationship).id);
            //self.exhibitorAffiliation = exhibitorAffiliation;
            if(self.user.userType === "ex-other") {
                self.currentExhibitor = self.exhibitorAffiliation 
                ? _.find(self.exhibitorRelationship, function(item) {
                    return item.exhibitorId === self.exhibitorAffiliation || item.id == self.exhibitorAffiliation;
                }) : _.head(self.exhibitorRelationship);
                if(self.currentExhibitor) {
                    if(self.currentExhibitor.exhibitorName) {
                        self.exhibitorAffiliationText = self.currentExhibitor.exhibitorName;
                    } else {
                        self.exhibitorAffiliationText = self.currentExhibitor.localName;
                    }                       
                } else {
                    self.exhibitorAffiliationText = _.head(self.exhibitorRelationship).exhibitorName;
                }
                //self.exhibitorAffiliationText = self.currentExhibitor &&  self.currentExhibitor.exhibitorName? self.currentExhibitor.exhibitorName : _.head(self.exhibitorRelationship).exhibitorName;
                var additionalLocaleIdEx = self.currentExhibitor && self.currentExhibitor.localeId ? self.currentExhibitor.localeId : self.user.localeId;
                var additionalTerritoryIdEx = self.currentExhibitor ? self.currentExhibitor.territoryId : _.head(self.exhibitorRelationship).territoryId;
                self.additionalLocale = _.find(self.translations.values.locales, {
                    id: additionalLocaleIdEx
                });
                self.additionalTerritory = _.find(self.translations.values.territories, {
                    id: additionalTerritoryIdEx
                });
            } else {
                self.currentExhibitor = self.exhibitorAffiliation ? _.find(self.exhibitorRelationship, {
                    exhibitorId: self.exhibitorAffiliation
                }) : _.head(self.exhibitorRelationship);
                self.exhibitorAffiliationText = self.currentExhibitor &&  self.currentExhibitor.exhibitorName? self.currentExhibitor.exhibitorName : _.head(self.exhibitorRelationship).exhibitorName;
                var additionalLocaleId = self.user.localeId;//self.currentExhibitor ? self.currentExhibitor.localeId : _.head(self.exhibitorRelationship).localeId;
                var additionalTerritoryId = self.currentExhibitor ? self.currentExhibitor.territoryId : _.head(self.exhibitorRelationship).territoryId;
                self.additionalLocale = _.find(self.translations.values.locales, {
                    id: additionalLocaleId
                });
                self.additionalTerritory = _.find(self.translations.values.territories, {
                    id: additionalTerritoryId
                });
            }
        };

        /**
         * build theaters list
         * @function buildTheatersList
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         */
        var buildTheatersList = function () {
            var theaterAffiliation;
            self.user = localStorageService.get("userInfo"); // when this method fired from angular even lister we want make sure we have the latest user data from storage
            self.theaterAffiliations = self.user.theaterAffiliations && window.helpers.isJson(self.user.theaterAffiliations) ? JSON.parse(self.user.theaterAffiliations) : [];
            if (!_.isEmpty(self.theaterAffiliations)) {
                theaterAffiliation = localStorageService.get("currentTheaterAffiliation") || _.head(self.theaterAffiliations).id;
                self.theaterAffiliation = theaterAffiliation;
                self.theaterAffiliationText = self.theaterAffiliation ? _.find(self.theaterAffiliations, {
                    id: self.theaterAffiliation
                }).localName : _.head(self.theaterAffiliations).localName;
            }
        };

        /**
         * set todo tab counts
         * @function setTodoTabCounts
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         */
        var setTodoTabCounts = function () {
            toDoService.setTodoTabCounts(currentUser.role).then(function () {
                $scope.$parent.$parent.pageCtrl.todoTabCounts = toDoService.todoTabCounts;
            });
        };

        /**
         * Set shopping cart count
         * @function setShoppingCartCount
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @param {object} $event angular event
         * @param {boolean} refresh flag indicates weather we want to get a new count from the server
         */
        var setShoppingCartCount = function ($event, refresh) {
            shoppingCartService.getShoppingCartCount(refresh).then(function () {
                self.shoppingCartCount = shoppingCartService.shoppingCart.page.totalElements;
            });
        };

        /**
         * Set permission cart count
         * @function setPermissionCartCount
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @param {object} $event angular event
         * @param {boolean} refresh flag indicates weather we want to get a new count from the server
         */
        var setPermissionCartCount = function ($event, refresh) {
            permissionQueueService.getPermissionCartCount(refresh).then(function () {
                self.permissionCartCount = permissionQueueService.permissionCart.page.totalElements;
            });
        };

        /**
         * Get titles
         * @function getTitles
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         * @param {string} term search term
         * @returns {object[]}
         */
        self.getTitles = function (term) {
            var errorMessage = "getSearchResultsError";
            return searchService.getTitles(term, errorMessage, false);
        };

        /**
         * Set model
         * @param {object} $item item
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         * @function setModel
         */
        self.setModel = function ($item) {
            self.searchTerm = $item.title;
        };

        /**
         * Search
         * @function search
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         */
        self.search = function () {
            if (self.searchTerm.trim().length > 0) {
                $window.location = "/search?search=" + encodeURIComponent(self.searchTerm);
            }
        };

        /**
         * Set the language
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @function setLang
         * @public
         */
        self.setLang = function () {
            locationService.changeLocale(self.locale, self.territory, true);
        };

        /**
         * Set the territory
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @function setTerritory
         * @public
         */
        self.setTerritory = function () {
            if ((self.user.userType === "ex-corporate" || self.user.userType === "ex-other") && self.isAdditionalAffiliation) {
                self.additionalLocaleCode = self.additionalLocale.code;
                locationService.changeLocale(self.additionalLocaleCode, self.additionalTerritory, true);
            } else {
                self.locale = self.territory.languages[0].code;
                locationService.changeLocale(self.locale, self.territory, true);
            }
            localStorageService.remove("todoTabCounts");
            localStorageService.remove("permissionCart");
        };

        /**
         * changeTerritoryAndAffiliation
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @function changeTerritoryAndAffiliation
         * @public
         */
        self.changeTerritoryAndAffiliation = function () {
            var exhibitorAffiliation = self.exhibitorAffiliation ? _.find(self.exhibitorRelationship, {
                territoryId: self.additionalTerritory.id
            }).exhibitorId : (_.head(self.exhibitorRelationship) && _.head(self.exhibitorRelationship).exhibitorId ? _.head(self.exhibitorRelationship).exhibitorId : _.head(self.exhibitorRelationship).id);
            self.exhibitorAffiliation = exhibitorAffiliation;
            localStorageService.set("currentExhibitorAffiliation", self.exhibitorAffiliation);
            setCurrentExhibitorDetails();
            self.setTerritory();
        };

        /**
         * Exhibitor affiliation change
         * @function exhibitorAffiliationChange
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         */
        self.exhibitorAffiliationChange = function () {
            localStorageService.set("currentExhibitorAffiliation", self.exhibitorAffiliation);
            $window.location.href = "/";
        };

        /**
         * Theater affiliation change
         * @function theaterAffiliationChange
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         */
        self.theaterAffiliationChange = function () {
            var exhibitor = _.find(self.theaterAffiliations, {
                    id: self.theaterAffiliation
                }),
                exhibitorId = exhibitor ? exhibitor.exhibitorId : -1;
            localStorageService.set("currentExhibitorAffiliation", exhibitorId);
            localStorageService.set("currentTheaterAffiliation", self.theaterAffiliation);
            $window.location.href = "/";
        };

        /**
         * Logout
         * @function logout
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         */
        self.logout = function () {
            var accessToken = $cookies.get("accessToken");
            if (accessToken) {
                loginService.logOut(accessToken).success(function () {
                    $cookies.remove("accessToken", {domain: DOMAIN, path: "/" });
                    window.location.href = "/login";
                });
            }
        };


         /**
         * check if user has any new spotlight notifications
         * @function setspotlightNotifications
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         */
        var setspotlightNotifications = function () {
            var userInfo = localStorageService.get("userInfo");
            var ackSpotlights = localStorageService.get("ackSpotlights");
            if (!ackSpotlights && userInfo && userInfo.userId) {
                spotlightDetailsService.getNewSpotlightCount(userInfo.userId).success(function (res) {
                    if (res > 0) {
                        self.hasNewSpotlights = true;
                        localStorageService.set("hasNewSpotlights", true);
                    }

                });
            } else {
                self.hasNewSpotlights = false;
                localStorageService.set("hasNewSpotlights", false);
            }
        };
        
        /**
         * Exit view as
         * @function exitViewAs
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         */        
        self.exitViewAs = function () {
            var impersonateFlag = localStorageService.get("impersonateUserFlag");
            if (impersonateFlag) {
                localStorageService.set("impersonateUserFlag", false);
                loginService.getAdminToken().success(function (result) {
                    if (result.status === "success") {
                        localStorageService.set("userInfo", result.data.userInfo);
                        if (result.data.userInfo.localeId !== localStorageService.get("location").localeId) {
                            var switchedLocale = _.find(localStorageService.get("liveLocales"), {id : result.data.userInfo.localeId});
                            var locale = switchedLocale && switchedLocale.abbreviation ? switchedLocale.abbreviation : "en-US";
                            translateService.loadTranslations(locale).success(function (translations) {
                                localStorageService.set("translations", translations);
                                localStorageService.set("location", null);
                                locationService.setDefaultLocale();
                                self.setAdminFields(result);
                            });
                        } else {
                            self.setAdminFields(result);
                        }
                    }
                });
            }
        };
        
        /**
         * set Admin Fields
         * @function setAdminFields
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         */
        self.setAdminFields = function (result) {
            switch (result.data.userInfo.userType) {
                case "ex-corporate":
                    localStorageService.set("currentExhibitorAffiliation", result.data.userInfo.exhibitorId);
                    break;
                case "ex-other":
                    var exhibitorRelationship = JSON.parse(result.data.userInfo.exhibitorRelationship);
                    localStorageService.set("currentExhibitorAffiliation", exhibitorRelationship[0].id);
                    break;
                case "ex-theater":
                    var theaterAffiliations = JSON.parse(result.data.userInfo.theaterAffiliations);
                    localStorageService.set("currentExhibitorAffiliation", theaterAffiliations[0].exhibitorId);
                    localStorageService.set("currentTheaterAffiliation", theaterAffiliations[0].id);
                    break;
            }
            var admToken = result.data.sourceToken.token;
            if (admToken) {
                $cookies.put("accessToken", admToken, {
                    sameSite: "strict",
                    secure: SECURE_COOKIES,
                    expires: OAUTH_SESSION_LIFETIME,
                    domain: DOMAIN,
                    path: "/"
                });
                $window.location.href = "/home";
            }
        };


        /**
         * init function
         * @function init
         * @memberOf angular_module.WB.cinehub.controllers.headerController
         * @public
         */
        self.init = function () {
            if (!currentUser) {
                return false;
            }

            self.territoryLanguages = locationService.getTerritoryLanguages();
            self.territory = locationService.getTerritory();
            self.locale = location ? location.locale : locationService.getDefaultLocale();
            self.localeText = locationService.getLocaleText();

            if (currentUser.role === "homeOfficeAdmin" || currentUser.role === "territoryAdmin") {
                setTodoTabCounts();
                setPermissionCartCount({},true);
            }

            setShoppingCartCount({},true);

            setspotlightNotifications();

            switch (self.user.userType) {
                case "ex-corporate":
                    findExhibitorById();
                    break;
                case "ex-other":
                    buildExhibitorsList();
                    break;
                case "ex-theater":
                    buildTheatersList();
                    break;
            }
            $rootScope.$emit("headerInitialized");
        };

        $rootScope.$on("exhibitorLocalNameUpdated", buildExhibitorsList);

        $rootScope.$on("theaterLocalNameUpdated", buildTheatersList);

        $rootScope.$on("updateShoppingCartCount", setShoppingCartCount);

        $rootScope.$on("updatePermissionQueueCount", setPermissionCartCount);

        //INIT
        self.init();
    }

    controllers.controller("headerController", [
        "$rootScope",
        "$scope",
        "locationService",
        "$window",
        "searchService",
        "localStorageService",
        "deviceDetectorService",
        "translateService",
        "exhibitorsService",
        "convertLocalizationCodeToValueService",
        "toDoService",
        "shoppingCartService",
        "permissionQueueService",
        "loginService",
        "additionalAffiliationService",
        "spotlightDetailsService",
        "$cookies",
        "DOMAIN",
        "SECURE_COOKIES",
        "OAUTH_SESSION_LIFETIME",
        headerController
    ]);
}());
