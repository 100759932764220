(function () {
    "use strict";

    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Login controller
     * @class loginController
     * @memberOf angular_module.WB.cinehub.controllers.loginController
     * @desc This is login controller
     * @param {service} $cookies $cookies service
     * @param {$window} $window $window instance
     * @param {$location} $location instance
     * @param {service} localStorageService local storage service
     * @param {service} loginService login service
     * @param {service} userService user service
     * @param {service} translateService translate service
     * @param {constant} OAUTH_SESSION_LIFETIME oauth session lifetime
     * @param {service} helpContactService help contact service
     * @param {service} languageService language service
     * @param {service} locationService location service
     * @param {constant} SECURE_COOKIES SECURE_COOKIES
     * @param {service} oauthService oauth service
     * @param {constant} DOMAIN DOMAIN
     * @param {service} $q angular $q service
     * @param {service} mfaService
     */
    function loginController($cookies, $window, $location, localStorageService, loginService, userService, translateService, locationService, OAUTH_SESSION_LIFETIME, helpContactService, languageService, SECURE_COOKIES, oauthService, DOMAIN) {

        /**
         * Instance to loginController
         * @memberOf angular_module.WB.cinehub.controllers.loginController
         * @private
         * @type {loginController}
         */
        var self = this,

            /**
             * Browser language
             * @type {string}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.loginController
             */
            browserLanguage = "en-US";

        /**
         * translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.loginController
         */
        self.translations = localStorageService.get("translations");

        /**
         * Login form
         * @memberOf angular_module.WB.cinehub.controllers.loginController
         * @public
         * @type {object}
         */
        self.loginForm = null;

        /**
         * cookies enabled flag
         * @memberOf angular_module.WB.cinehub.controllers.loginController
         * @public
         * @type {bool}
         */
        self.cookiesEnabled = true;

        /**
         * contact email
         * @memberOf angular_module.WB.cinehub.controllers.loginController
         * @public
         * @type {string}
         */
        self.contactEmail = "CineHubUS@warnerbros.com";

        /**
         * disable login button
         * @memberOf angular_module.WB.cinehub.controllers.loginController
         * @public
         * @type {boolean}
         */
        self.disableLoginBtn = false;

        /**
         * Current year
         * @memberOf angular_module.WB.cinehub.controllers.loginController
         * @public
         * @type {string}
         */
        self.currentYear = moment().year();


        /**
         * Remember login username
         * @memberOf angular_module.WB.cinehub.controllers.loginController
         * @private
         * @function rememberLoginUsername
         */
        var rememberLoginUsername = function () {
            if (self.rememberMe) {
                var now = new Date(),
                    in30Days = new Date(now.setDate(now.getDate() + 30));

                $cookies.put("loginUsername", self.username, {
                    sameSite: "strict",
                    secure: SECURE_COOKIES,
                    expires: in30Days,
                    domain: DOMAIN
                });
            } else {
                $cookies.remove("loginUsername", {domain: DOMAIN, path: "/" });
            }
        };

        /**
         * Try to restore the username
         * @memberOf angular_module.WB.cinehub.controllers.loginController
         * @private
         * @function tryRestoreUserName
         */
        var tryRestoreUserName = function () {
            var username = $cookies.get("loginUsername") || "";

            if (username) {
                self.username = username;
                self.rememberMe = true;
            }
        };

        /**
         * Load translations
         * @function loadTranslations
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.loginController
         * @returns {Promise}
         */
        var loadTranslations = function () {
            return translateService.loadTranslations(browserLanguage, "global.messages.loadTranslationsError").success(function (result) {
                self.translations = result;
                localStorageService.set("translations", result);
                localStorageService.set("loadedTranslationsForLocale", browserLanguage);
            });
        };

        /**
         * Get user info
         * @function getUserInfo
         * @memberOf angular_module.WB.cinehub.controllers.loginController
         * @private
         */
        var getUserInfo = function () {
            userService.getUserInfo(self.username);
        };
        
        /**
         * Get oauth token
         * @function getOauthToken
         * @memberOf angular_module.WB.cinehub.controllers.loginController
         * @private
         */
        var getOauthToken = function () {
            oauthService.getUserOauthToken("password", self.username, self.password).success(function (token) {
                $cookies.put("accessToken", token.access_token, {
                    sameSite: "strict",
                    secure: SECURE_COOKIES,
                    expires: OAUTH_SESSION_LIFETIME,
                    domain: DOMAIN
                });
                getUserInfo();
            }).error(function (error) {
                var errDesc = error.error_description;
                var resetToken = null;
                var userId = null;
                var userTerritory;
                var userLocale;
                var browserLocale;
                if (errDesc.indexOf("passwordExpired") !== -1) {
                    var errCodes = _.split(errDesc, "||");
                    if (errCodes.length === 3) {
                        resetToken = errCodes[errCodes.length - 2];
                        userId = errCodes[errCodes.length - 1];
                        error.error_description = errCodes[0];
                    } else {
                        error.error_description = "error";
                    }
                }
                if (errDesc.indexOf("oktaMfaRequired") !== -1) {
                    var mfaErr = JSON.parse(errDesc);
                    localStorageService.set("userId", mfaErr.userId);
                    localStorageService.set("oktaUserId", mfaErr.oktaUserId);
                    localStorageService.set("userActType", mfaErr.accountType);
                    localStorageService.set("oktaSessionToken", mfaErr.sessionId);
                    localStorageService.set("autoMFAPush", mfaErr.autoMFAPush);
                    localStorageService.set("username", self.username);
                    var territoryLanguages = locationService.getTerritoryLanguages({
                        userId: mfaErr.userId,
                        username: mfaErr.oktaUserId,
                        territoryId: mfaErr.territoryId
                    });
                    userTerritory =  territoryLanguages && territoryLanguages.length > 1 ? _.find(territoryLanguages, {
                        id: mfaErr.territoryId
                    }) : territoryLanguages[0];
                    userLocale = userTerritory && userTerritory.languages[0].code;
                    browserLocale = localStorageService.get("loadedTranslationsForLocale");
                    error.error_description = mfaErr.errorMessage;
                }
                switch (error.error_description) {
                    case "pendingEmailVerification":
                        self.loginError = self.translations.login.messages.pendingEmailVerificationError;
                        break;
                    case "pendingApproval":
                        self.loginError = self.translations.login.messages.pendingApprovalError;
                        break;
                    case "rejected":
                        self.loginError = self.translations.login.messages.rejectedError;
                        break;
                    case "inactive":
                        self.loginError = self.translations.login.messages.inactiveError;
                        break;
                    case "invalidCredentials":
                        self.loginError = self.translations.login.messages.loginFailedMsg;
                        break;
                    case "userNotFound":
                        self.loginError = self.translations.login.messages.userNotFoundError;
                        break;
                    case "error":
                        self.loginError = self.translations.login.messages.error;
                        break;
                    case "pendingAccountVerification":
                        self.loginError = self.translations.login.messages.pendingAccountVerificationError;
                        break;
                    case "userLockedOut":
                        self.loginError = self.translations.login.messages.accountLocked;
                        break;
                    case "passwordExpired":
                        $window.location.href = "/reset-password?userId=" + userId + "&resetPasswordToken=" + resetToken + "&status=expired";
                        break;
                    case "notMigrated":
                        self.loginError = self.translations.login.messages.tenatMigrationPwdResetMsg;
                        break;
                    case "oktaMfaRequired":
                        if (userTerritory && userLocale
                            && browserLocale && browserLocale !== userLocale) {
                            return locationService.changeLocale(userLocale, userTerritory, false, function () {
                                $window.location.href = "/verifyMFA";
                            }, true);
                        } else {
                            $window.location.href = "/verifyMFA";
                        }
                        break;
                    default:
                        self.loginError = self.translations.login.messages.error;
                }
                self.disableLoginBtn = false;
            });

        };

        /**
         * set help contact
         * @memberOf angular_module.WB.cinehub.controllers.loginController
         * @private
         * @function setHelpContact
         */
        var setHelpContact = function () {
            helpContactService.getHelpContactEmail().then(function (contactEmail) {
                self.contactEmail = contactEmail;
            });
        };

        /**
         * Validate login
         * @memberOf angular_module.WB.cinehub.controllers.loginController
         * @public
         * @function validateLogin
         */
        self.validateLogin = function () {
            var processLogin = function () {
                if(self.username) {
                    self.username = self.username.toLowerCase();
                }

                rememberLoginUsername();

                if (self.loginForm.$valid) {
                    //commented Okta Migration 
                    /*loginService.validateUserMigration(self.username).success(function () {
                        getOauthToken();
                    }).error(function (error) {
                        if (error.message === "USER_NOT_MIGRATED") {
                            self.loginError = "As per WB policy, please change your password. To reset password check your email.";
                            self.disableLoginBtn = false;
                        } else {
                            self.loginError = error.message;
                        }
                    });*/
                    getOauthToken();
                } else {
                    self.disableLoginBtn = false;
                }
            };
            self.loginForm.$submitted = true;

            if (!localStorageService.isSupported) {
                window.alert("Cinehub requires access to the browser data. Please exit from private browsing.");
                return;
            }
            self.translations = localStorageService.get("translations");
            self.disableLoginBtn = true;
            if (self.translations) {
                processLogin();
            } else {
                languageService.getBrowserLanguage().then(function (browserLang) {
                    browserLanguage = browserLang;
                    loadTranslations().then(function () {
                        processLogin();
                        setHelpContact();
                    });
                });
            }
        };

        /**
         * Init
         * @memberOf angular_module.WB.cinehub.controllers.loginController
         * @function init
         * @public
         */
        self.init = function () {
            self.cookiesEnabled = navigator.cookieEnabled;
            self.sessionExpiredError = $cookies.get("sessionExpiredError");
            var redirErrCode = $location.search() && $location.search().error;
            if(redirErrCode ) {
                if (redirErrCode === "E0000069") {
                    self.loginError = self.translations.login.messages.accountLocked;
                } else {
                    self.loginError = self.translations.login.messages.sessionExpiredError;
                }
            }
            if (self.sessionExpiredError) {
                $cookies.remove("sessionExpiredError", {domain: DOMAIN, path: "/" });
            }
            //destroy local storage
            localStorageService.clearAll();
            tryRestoreUserName();
            languageService.getBrowserLanguage().then(function (browserLang) {
                browserLanguage = browserLang;
                loadTranslations().then(setHelpContact);
            });
        };

        //INIT
        self.init();
    }

    controllers.controller("loginController", ["$cookies", "$window", "$location", "localStorageService", "loginService", "userService", "translateService", "locationService", "OAUTH_SESSION_LIFETIME", "helpContactService", "languageService", "SECURE_COOKIES", "oauthService", "DOMAIN", loginController]);
}());
