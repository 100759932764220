(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * highlighted Films Service
     * @class highlightedFilms
     * @memberOf angular_module.WB.cinehub.services
     * @desc home service
     * @param {!angular.$http} $http anguar $http service
     * @param {string} API_ROOT API_ROOT constant
     * @param {service} localStorageService local storage service
     */
    function highlightedFilmsService($http, API_ROOT, localStorageService) {

        /**
         * Translations
         * @type {object}
         * @private
         * @memberOf angular_module.WB.cinehub.services.highlightedFilms
         */
        var translations = localStorageService.get("translations");

        /**
         * Get highlighted films
         * @memberOf angular_module.WB.cinehub.services.highlightedFilms
         * @public
         * @function getHighlightedFilms
         * @returns {promise}
         * @param {int} page page
         * @param {int} size size
         */
        this.getHighlightedFilms = function (page, size) {
            return $http.get(API_ROOT + "highlightedFilms/search/findLocAll?&page=" + page + "&size=" + size + "&sort=order", {
                errorMessage: translations.home.messages.getFeaturedFilmsError,
                showSpinner: false
            });
        };

        /**
         * Save highlighted films
         * @memberOf angular_module.WB.cinehub.services.highlightedFilms
         * @public
         * @function getHighlightedFilms
         * @returns {promise}
         * @param {object} highlightedFilm highlighted film
         */
        this.saveHighlightedFilms = function (highlightedFilm) {
            return $http.post(API_ROOT + "highlightedFilms", highlightedFilm, {
                errorMessage: translations.global.messages.cannotSaveHighlightedFilms
            });
        };

        /**
         * Save getHighlighted films in bulk
         * @memberOf angular_module.WB.cinehub.services.highlightedFilms
         * @public
         * @function bulkSaveHighlightedFilms
         * @returns {promise}
         * @param {object[]} highlightedFilms highlighted films
         */
        this.bulkSaveHighlightedFilms = function (highlightedFilms) {
            return $http.post(API_ROOT + "highlightedFilms/saveAll", highlightedFilms, {
                errorMessage: translations.global.messages.cannotSaveHighlightedFilms
            });
        };

        /**
         * Delete highlighted film
         * @memberOf angular_module.WB.cinehub.services.highlightedFilms
         * @public
         * @function deleteHighlightedFilm
         * @returns {promise}
         * @param {object[]} highlightedFilms highlighted films
         */
        this.deleteHighlightedFilms = function (highlightedFilms) {
            return $http.post(API_ROOT + "highlightedFilms/delete", highlightedFilms, {
                errorMessage: translations.global.messages.cannotDeleteHighlightedFilms
            });
        };

        /**
         * Find loc by filmId and artwork for highlighted films
         * @memberOf angular_module.WB.cinehub.services.highlightedFilms
         * @public
         * @function findLocByFilmIdAndArtworksForHighlightedFilms
         * @returns {promise}
         * @param {int} id highlighted film id
         * @TODO: change error message
         */
        this.findLocByFilmIdAndArtworksForHighlightedFilms = function (id) {
            return $http.get(API_ROOT + "filmAssets/search/findLocByFilmIdAndArtworksForHighlightedFilms?filmId=" + id, {
                errorMessage: translations.global.messages.cannotGetFeaturedFilmTitles
            });
        };
    }

    services.service("highlightedFilmsService", ["$http", "API_ROOT", "localStorageService", highlightedFilmsService]);
} ());
