(function ($) {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * permissionTable directive
     * @class permissionTable
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     * @param {service} localStorageService local storage service
     * @param {service} permissionService permission service
     */
    function permissionTable(localStorageService, permissionService) {

        /**
         * Positioner helper class
         * @class Positioner
         * @memberof angular_module.WB.cinehub.directives.permissionTable
         */
        function Positioner() {

            /**
             * Active parent jquery element 
             * @type {jQueryElement}
             * @private
             * @memberof Positioner
             */
            var $activeParent = null,

                /**
                 * Position 
                 * @type {object}
                 * @private
                 * @memberof Positioner
                 */
                position = null,

                /**
                 * Child data table 
                 * @type {jQueryElement}
                 * @private
                 * @memberof Positioner
                 */
                $child = $("#child"),

                /**
                 * Interval id
                 * @type {intervalInstance}
                 * @private
                 * @memberof Positioner
                 */
                intervalId = null,

                /**
                 * Is child present on the page flag 
                 * @type {boolean}
                 * @private
                 * @memberof Positioner
                 */
                isChildPresent = false;

            /**
             * Position the element
             * @param {int} id id
             * @param {boolean} positionOnTop position on top flag
             * @public
             * @memberof Positioner
             */
            this.position = function (id, positionOnTop) {
                isChildPresent = $child && $activeParent && $child.length > 0 && $activeParent.length > 0;

                if (isChildPresent) {
                    $child.css({ "left": "-316px", "zIndex": "-1" });
                }

                intervalId = setInterval(function () {
                    $activeParent = $("#parent-" + id);
                    isChildPresent = $child && $activeParent && $child.length > 0 && $activeParent.length > 0;

                    if (isChildPresent) {
                        position = $activeParent.position();
                        if(!positionOnTop) {
                            $child.css("top", position.top);
                        }
                        $child.animate({
                            "left": "0",
                            "zIndex": "1"
                        }, "fast");

                        clearInterval(intervalId);
                    }
                }, 100);

            };

        }

        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @private
         * @memberOf angular_module.WB.cinehub.directives.permissionTable
         */
        var link = function (scope) {

            /**
             * template asset object
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.directives.permissionTable
             */
            var templateAsset = {},

                /**
                 * Positioner class instance 
                 * @type {Positioner}
                 * @private
                 * @memberof angular_module.WB.cinehub.directives.permissionTable
                 */
                positioner = new Positioner(),
                
                /**
                 * Location info
                 * @type {object}
                 * @private
                 * @memberOf angular_module.WB.cinehub.directives.permissionTable
                 */
                location = localStorageService.get("location"),

                /**
                 * Saved templates
                 * @type {object}
                 * @private
                 * @memberOf angular_module.WB.cinehub.directives.permissionTable
                 */
                savedTemplates = {};

            /**
             * user info
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.directives.permissionTable
             * make it public by using scope for unit tests but it is private in scope of directive
             */
            scope.user = localStorageService.get("userInfo"),
            scope.filtered,
            scope.filterUpdate = false;
            /**
             * Translations
             * @type {object}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.permissionTable
             */
            scope.translations = localStorageService.get("translations");

            /**
             * permission constant
             * @type {object}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.permissionTable
             */
            scope.permissionConstant = {
                none: "NONE",
                view_and_download: "VIEW_AND_DOWNLOAD",
                view: "VIEW"
            };

            /**
             * global permission
             * @type {string}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.permissionTable
             */
            scope.globalPermission = null;

            /**
             * child global permission
             * @type {string}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.permissionTable
             */
            scope.childGlobalPermission = null;

            /**
             * is territory admin flag
             * @type {boolean}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.permissionTable
             */
            scope.isTerritoryAdmin = scope.user.role === "territoryAdmin";

            /**
             * allowed theater permissions Territory Admin can set
             * @type {object}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.permissionTable
             */
            scope.allowedTheaterPermissions = {
                none: true,
                view_and_download: false
            };

            /**
             * Calculate child permission tally
             * @private
             * @function calculateChildPermissionTally
             * @param {object[]} data data collection
             * @memberOf angular_module.WB.cinehub.directives.permissionTable
             */
            var calculateChildPermissionTally = function (data) {
                var downloadCount = 0,
                    viewCount = 0,
                    index = 0,
                    dataLength = data.length;
                if(scope.filterUpdate){
                    data = scope.filtered;
                    dataLength = scope.filtered.length;
                }
                for (index; index < dataLength; index++) {
                    if (data[index].permission === scope.permissionConstant.view_and_download) {
                        downloadCount++;
                    } else if (data[index].permission === scope.permissionConstant.view) {
                        viewCount++;
                    }
                }
                if (downloadCount === dataLength && downloadCount > 0) {
                    scope.childGlobalPermission = scope.permissionConstant.view_and_download;
                } else if (viewCount === dataLength && viewCount > 0) { 
                    scope.childGlobalPermission = scope.permissionConstant.view;
                } else {
                    scope.childGlobalPermission = null;
                }

                scope.childPermissionTally = {
                    view_and_download: downloadCount,
                    view: viewCount
                };
            };

            /**
             * bulk update permissions
             * @private
             * @function bulkUpdatePermissions
             * @param {object[]} data data
             * @param {string} permission permission
             * @memberOf angular_module.WB.cinehub.directives.permissionTable
             */
            var bulkUpdatePermissions = function (data, permission) {
                var index = 0,
                    dataLength = data.length;
                if(scope.filterUpdate){
                    data = scope.filtered;
                    dataLength = scope.filtered.length;
                }
                for (index; index < dataLength; index++) {
                    data[index].permission = permission;
                    data[index].changed = true;
                }
            };

            /**
             * set Permissions
             * @private
             * @function setPermissions
             * @param {object[]} data data
             * @param {object} parentRow parent row
             * @memberOf angular_module.WB.cinehub.directives.permissionTable
             */
            var setPermissions = function (data) {
                var setAsTemplate = templateAsset && templateAsset.permissions && templateAsset.permissions.length > 0;
                if (setAsTemplate) {
                    setExistingPermission(data.permissions, templateAsset.permissions, true);
                    scope.$emit("permissionChanged", true);
                } else {
                    if (scope.selectedAssets[0] && scope.selectedAssets[0].permissions && !scope.globalPermission) { // might be case which should be resolve on top
                        setExistingPermission(data.permissions, scope.selectedAssets[0].permissions, false);
                    }
                }
            };

            /**
             * set existing permission
             * @private
             * @function setExistingPermission
             * @param {object[]} data data
             * @param {object[]} savedPermissions saved permissions
             * @param {boolean} isChange is change flag
             * @memberOf angular_module.WB.cinehub.directives.permissionTable
             */
            var setExistingPermission = function (data, savedPermissions, isChange) {
                if (!savedPermissions) {
                    return;
                }
                var index = 0,
                    savedPermissionsIndex = 0,
                    dataLength = data.length,
                    savedPermissionsLength = savedPermissions.length;

                for (index; index < dataLength; index++) {
                    savedPermissionsIndex = 0;
                    for (savedPermissionsIndex; savedPermissionsIndex < savedPermissionsLength; savedPermissionsIndex++) {
                        if (data[index].id == savedPermissions[savedPermissionsIndex][scope.childIdProperty]) {
                            if ((!data[index].changed) || (data[index].changed && !data[index].permission)) {
                                data[index].permission = savedPermissions[savedPermissionsIndex].permission;
                                data[index].changed = isChange;
                            }
                            
                        }
                    }
                }
            };

            /**
             * clear set as template flag
             * @private
             * @function clearSetAsTemplateFlag
             * @memberOf angular_module.WB.cinehub.directives.permissionTable
             */
            var clearSetAsTemplateFlag = function () {
                templateAsset = {};
                scope.selectedAssets.forEach(function (asset) {
                    asset.isTemplate = false;
                });
            };

            /**
             * Get permission items by asset ids
             * @function getPermissionItemsByAssetIds
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.permissionTable
             * @param {string} asset asset
             * @returns {promise}
             */
            var getPermissionItemsByAssetIds = function (assetIds) {
                return permissionService.getPermissionItemsByAssetIds(assetIds).success(function (result) {
                    scope.selectedAssets.forEach(function (asset) {
                        var existingAssetPermissions = _.filter(result._embedded.filmAssetPermissions, {
                            assetId: asset.assetId + ""
                        });

                        if (existingAssetPermissions.length > 0) {
                            asset.permissions = existingAssetPermissions;
                        }

                    });
                });
            };

            /**
             * Get permission items by asset ids and by territory id
             * @function getPermissionItemsByAssetIdsAndByTerritory
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.permissionTable
             * @param {string} asset asset
             * @param {object} exhibitor exhibitor info
             * @returns {promise}
             */
            var getPermissionItemsByAssetIdsAndByTerritory = function (assetIds, exhibitor) {
                var serviceCall = exhibitor && exhibitor.id ? "getPermissionItemsByAssetTerritoryAndExhibitorId" : "getPermissionItemsByAssetIdsAndByTerritory",
                    exhibitorId = exhibitor && exhibitor.id ? exhibitor.id : "";
                return permissionService[serviceCall](assetIds, location.territoryId, exhibitorId).success(function (result) {

                    scope.selectedAssets.forEach(function (asset) {
                        var responseData = exhibitor && exhibitor.id ? result : result["data"].filmAssetPermissions;
                        var existingAssetPermissions = _.filter(responseData, {
                            assetId: asset.assetId + ""
                        });

                        if (existingAssetPermissions.length > 0) {
                            if (asset.permissions && asset.permissions.length > 0) {
                                asset.permissions = asset.permissions.concat(existingAssetPermissions);

                            } else {
                                asset.permissions = existingAssetPermissions;
                            }
                        }

                    });
                });
            };

            /**
             * Set template
             * @function setTemplate
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.permissionTable
             */
            var setTemplate = function () {
                var index = 0,
                    outsideDataLength = scope.outsideData.length,
                    process = function () {
                        var permissionTemplate = {};
                        templateAsset.allPermissionsLoaded = true;

                        for (index; index < outsideDataLength; index++) {
                            setExistingPermission(scope.outsideData[index].permissions, templateAsset.permissions, true);
                        }
                        permissionTemplate[templateAsset.assetId] = _.cloneDeep(scope.outsideData);

                        _.extend(savedTemplates, permissionTemplate);

                        if (scope.selectedParent) {
                            scope.selectedParent = _.find(scope.outsideData, {
                                id: scope.selectedParent.id
                            });
                            calculateChildPermissionTally(scope.selectedParent.permissions);
                        }
                    };

                templateAsset = _.head(_.filter(scope.selectedAssets, {
                    isTemplate: true
                }));

                if (templateAsset && templateAsset.isTemplate) {
                    scope.globalPermission = null;
                    scope.bulkUpdatePermissions(null, "global");
                    if (scope.isTerritoryAdmin) {
                        if (templateAsset.allPermissionsLoaded) {
                            window.console.time("process");
                            if (savedTemplates[templateAsset.assetId]) {
                                scope.outsideData = _.cloneDeep(savedTemplates[templateAsset.assetId]);
                            } else {
                                process();
                            }
                            if (scope.selectedParent) {
                                scope.selectedParent = _.find(scope.outsideData, {
                                    id: scope.selectedParent.id
                                });
                                setExistingPermission(scope.selectedParent.permissions, templateAsset.permissions, true);
                                calculateChildPermissionTally(scope.selectedParent.permissions);
                            }
                            window.console.timeEnd("process");
                        } else {
                            getPermissionItemsByAssetIdsAndByTerritory(templateAsset.assetId).then(process);
                        }
                    } else {
                        getPermissionItemsByAssetIds(templateAsset.assetId).then(process);
                    }
                } else if (scope.selectedAssets && scope.selectedAssets.length > 1) {
                    scope.bulkUpdatePermissions(scope.permissionConstant.none, "global");
                }
            };

            /**
             * select permission
             * @function selectPermission
             * @param {string} permission NONE/VIEW/VIEW_AND_DOWNLOAD
             * @param {object} childRow child row
             * @public
             * @memberOf angular_module.WB.cinehub.directives.permissionTable
             */
            scope.selectPermission = function (permission, childRow) {
                var oldPermission = childRow.permission ? childRow.permission.toLowerCase() : "";

                childRow.permission = permission;
                childRow.changed = true;
                
                scope.globalPermission = null;

                if (scope.selectedParent) {
                    scope.selectedParent.changed = true;
                    scope.childPermissionTally[oldPermission]--;
                    scope.childPermissionTally[permission.toLowerCase()]++;
                }

                calculateChildPermissionTally(scope.selectedParent.permissions);

                scope.$emit("permissionChanged", true);
            };

            /**
             * select row
             * @function selectRow
             * @param {object} row row
             * @public
             * @memberOf angular_module.WB.cinehub.directives.permissionTable
             */
            scope.selectRow = function (row) {
                scope.filterUpdate = false;
                var isSelectedOneAsset = scope.selectedAssets && scope.selectedAssets.length === 1,
                    isPermissionLoadedForSelectedAsset = isSelectedOneAsset && scope.selectedAssets[0].permissions && _.find(scope.selectedAssets[0].permissions, {
                        exhibitorId: row.id + ""
                    }),
                    processPermissions = function () {
                        scope.selectedParent = row;
                        scope.selectedParentCount = row.childCount;

                        setPermissions(row);

                        calculateChildPermissionTally(row.permissions);

                    };

                scope.searchTerm = "";

                if (isSelectedOneAsset && !isPermissionLoadedForSelectedAsset && scope.selectedAssets[0].hasTheaterPermission && scope.isTerritoryAdmin && !scope.globalPermission) {
                    getPermissionItemsByAssetIdsAndByTerritory(scope.selectedAssets[0].assetId, row).then(processPermissions);
                } else {
                    processPermissions();
                }

                positioner.position(row.id, !scope.isTerritoryAdmin);
            };

            /**
             * bulk update permission
             * @function bulkUpdatePermission
             * @param {string} permission permission
             * @param {string} bulkUpdateType bulk update type
             * @param {boolean} exhibitorNotAllowed exhibitors are not allowed to set this permission
             * @public
             * @memberOf angular_module.WB.cinehub.directives.permissionTable
             */
            scope.bulkUpdatePermissions = function (permission, bulkUpdateType) {
                var index = 0,
                    outsideDataLength = scope.outsideData.length;
                scope.childGlobalPermission = permission;
                if (bulkUpdateType === "global") {
                    if (permission) {
                        scope.$emit("groupUpdate", true);
                    }
                    scope.globalPermission = permission;

                    for (index; index < outsideDataLength; index++) {
                        scope.outsideData[index].changed = true;
                        bulkUpdatePermissions(scope.outsideData[index].permissions, permission);
                    }

                } else {
                    scope.selectedParent.changed = true;
                    bulkUpdatePermissions(scope.selectedParent.permissions, permission);
                }

                if (scope.selectedParent) {
                    calculateChildPermissionTally(scope.selectedParent.permissions);
                }

                scope.$emit("permissionChanged", true);
            };

            scope.$on("resetPermissions", function () {
                clearSetAsTemplateFlag();
                if (scope.selectedParent) {
                    scope.selectedParent.permissions = _.find(scope.outsideData, {
                        id: scope.selectedParent.id
                    }).permissions;
                }
                scope.parentRow = null;
                scope.selectedAssets = [];
                scope.searchTerm = "";
                scope.$emit("groupUpdate", false);
            });

            scope.$on("useAsTemplate", setTemplate);

            scope.$watch("selectedAssets", function (newVal) {
                if (newVal && newVal.length) {

                    var isSelectedOneAsset = scope.selectedAssets && scope.selectedAssets.length === 1,
                        isSelectedMultipleAssets = scope.selectedAssets && scope.selectedAssets.length > 1,
                        index = 0,
                        outsideDataLength = scope.outsideData.length,
                        hasPermissions = scope.isTerritoryAdmin ? scope.selectedAssets[0].hasTheaterPermission : scope.selectedAssets[0].hasTerritoryPermission;

                    scope.selectedAssets = newVal;

                    scope.globalPermission = null;
                    scope.childGlobalPermission = null;

                    templateAsset = _.head(_.filter(scope.selectedAssets, {
                        isTemplate: true
                    }));

                    if (_.isEmpty(templateAsset)) {
                        scope.bulkUpdatePermissions(null, "global");
                    } else {
                        setTemplate();
                    }

                    if (scope.isTerritoryAdmin) {

                        if ((isSelectedOneAsset || isSelectedMultipleAssets) && hasPermissions ) {

                            if (isSelectedOneAsset && scope.selectedParent) {
                                scope.selectRow(scope.selectedParent);
                            }
                        }
                    }

                    if (scope.user.role === "homeOfficeAdmin") {
                        if (isSelectedOneAsset) {
                            getPermissionItemsByAssetIds(scope.selectedAssets[0].assetId).then(function () {
                                if (scope.selectedAssets[0].permissions) {
                                    for (index; index < outsideDataLength; index++) {
                                        scope.outsideData[index].changed = true;
                                        setExistingPermission(scope.outsideData[index].permissions, scope.selectedAssets[0].permissions, false);
                                    }
                                }
                            });
                        }
                        if (isSelectedMultipleAssets && _.isEmpty(templateAsset)) {
                            scope.bulkUpdatePermissions(scope.permissionConstant.none, "global");
                            scope.globalPermission = null;
                        }
                    }

                    

                    scope.$emit("permissionChanged", scope.selectedAssets.length);
                } else {
                    scope.selectedParent = null;
                    scope.searchTerm = "";
                }
            });

            scope.$watch("outsideData", function (newVal) {
                if (newVal) {
                    scope.outsideData = newVal;
                }
            });
            scope.$watch("searchTerm", function(newVal){
                if (newVal !== "") {
                    scope.filterUpdate = true;
                    if(scope.selectedParent){
                        calculateChildPermissionTally(scope.selectedParent.permissions);
                    }
                }
                else{
                    scope.filterUpdate = false;
                    if(scope.selectedParent){
                        calculateChildPermissionTally(scope.selectedParent.permissions);
                    }
                }
            });
        };

        return {
            link: link,
            scope: {
                childIdProperty: "@",
                itemNameProperty: "@",
                outsideData: "=",
                selectedAssets: "="
            },
            templateUrl: "permissionTableTemplate.html"
        };
    }

    directives.directive("permissionTable", ["localStorageService", "permissionService", permissionTable]);
}(window.jQuery));
