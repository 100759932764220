(function() {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class exhibitorsService
     * @memberOf angular_module.WB.cinehub.services
     * @desc exhibitors service
     * @param {!angular.$http} $http angular $http service
     * @param {strict} API_ROOT API_ROOT constant
     * @param {service} localStorageService local storage service
     */
    function exhibitorsService($http, API_ROOT, localStorageService) {

        /**
         * translations
         * @private
         * @memberOf angular_module.WB.cinehub.services.exhibitorsService
         * @type {object}
         */
        var translations = localStorageService.get("translations");

        /**
         * Get all exhibitors
         * @memberOf angular_module.WB.cinehub.services.exhibitorsService
         * @public
         * @function getAllExhibitors
         * @returns {promise}
         * @param {int} size page size
         * @param {int} page page number
         * @param {string} sort sort
         */
        this.getAllExhibitors = function(size, page, sort) {
            return $http.get(API_ROOT + "exhibitors?size=" + size + "&page=" + page + "&sort=" + sort + "&ignoreLocalizationString", {
                errorMessage: translations.global.messages.cannotRetrieveListOfExhibitors
            });
        };

        /**
         * Search exhibitors by search term
         * @memberOf angular_module.WB.cinehub.services.exhibitorsService
         * @public
         * @function searchExhibitorsBySearchTerm
         * @returns {promise}
         * @param {string} term search term
         */
        this.searchExhibitorsBySearchTerm = function(term) {
            return $http.get(API_ROOT + "exhibitors/search/findByNameLike?name=" + term, {
                errorMessage: translations.global.messages.cannotRetrieveListOfExhibitors,
                showSpinner: false
            }).then(function(result) {
                return result.data._embedded.exhibitors;
            });
        };

        /**
         * Find by territory id
         * @memberOf angular_module.WB.cinehub.services.exhibitorsService
         * @public
         * @function findByTerritoryId
         * @returns {promise}
         * @param {int} territoryId territory id
         */
        this.findByTerritoryId = function(territoryId) {
            return $http.get(API_ROOT + "exhibitors/search/findByTerritoryId?territoryId=" + territoryId + "&size=20000&ignoreLocalizationString", {
                errorMessage: translations.global.messages.cannotRetrieveListOfExhibitors,
                showSpinner: false
            });
        };

        /**
         * Find by territory id not limit by page size
         * @memberOf angular_module.WB.cinehub.services.exhibitorsService
         * @public
         * @function findByTerritoryIdNoLimitByPageSize
         * @returns {promise}
         * @param {int} territoryId territory id
         * @param {int} size page size
         * @param {int} page page number
         * @param {string} sort sort string
         */
        this.findByTerritoryIdNoLimitByPageSize = function(territoryId, size, page, sort) {
            return $http.get(API_ROOT + "exhibitors/search/findByTerritoryId?territoryId=" + territoryId + "&size=" + size + "&page=" + page + "&sort=" + sort + "&ignoreLocalizationString", {
                errorMessage: translations.global.messages.cannotRetrieveListOfExhibitors
            });
        };

        /**
         * Find by territory id and by name like
         * @memberOf angular_module.WB.cinehub.services.exhibitorsService
         * @public
         * @function findByTerritoryIdAndByNameLike
         * @returns {promise}
         * @param {string} term search term
         * @param {int} territoryId territory id
         */
        this.findByTerritoryIdAndByNameLike = function(territoryId, term) {
            return $http.get(API_ROOT + "exhibitors/search/findByTerritoryIdAndByNameLike?territoryId=" + territoryId + "&name=" + term + "&ignoreLocalizationString", {
                errorMessage: translations.global.messages.cannotRetrieveListOfExhibitors,
                showSpinner: false
            }).then(function(result) {
                return result.data._embedded.exhibitors;
            });
        };

        /**
         * Find exhibitros by name like or local name like and by city like or by state like and territory id
         * @memberOf angular_module.WB.cinehub.services.exhibitorsService
         * @public
         * @function findByNameLikeOrByLocalNameLikeAndByCityLikeOrByStateLikeAndByTerritoryId
         * @returns {promise}
         * @param {string} name name
         * @param {string} localName local name
         * @param {string} city city
         * @param {string} state state
         * @param {int} territoryId territory id
         * @param {string} territoryName territory name
         * @param {int} page page
         * @param {int} size page size
         * @param {string} sort sort string
         */
        this.findByNameLikeOrLocalNameLikeAndCityLikeOrStateLikeOrTerritoryNameLikeAndTerritoryId = function(territoryId, size, page, sort, name, localName, city, state, territoryName) {
            return $http.get(API_ROOT + "exhibitors/search/findByNameLikeOrLocalNameLikeAndCityLikeOrStateLikeOrTerritoryNameLikeAndTerritoryId?name=" + name + "&localName=" + localName + "&city=" + city + "&state=" + state + "&territoryName=" + territoryName + "&territoryId=" + territoryId + "&size=" + size + "&page=" + page + "&sort=" + sort + "&ignoreLocalizationString", {
                errorMessage: translations.global.messages.cannotRetrieveListOfExhibitors
            });
        };

        /**
         * Find by id
         * @memberOf angular_module.WB.cinehub.services.exhibitorsService
         * @public
         * @function findById
         * @returns {promise}
         * @param {int} id  id
         */
        this.findById = function(id) {
            return $http.get(API_ROOT + "exhibitors/search/findById?id=" + id + "&ignoreLocalizationString", {
                errorMessage: translations.global.messages.cannotRetrieveListOfExhibitors
            });
        };

        /**
         * Find exhibitor and theater by city state like
         * @memberOf angular_module.WB.cinehub.services.exhibitorsService
         * @public
         * @function findExhibitorAndTheaterByCityStateLike
         * @returns {promise}
         * @param {string} term search term
         * @param {int} territoryId territory id
         */
        this.findExhibitorAndTheaterByCityStateLike = function(term, territoryId) {
            return $http.get(API_ROOT + "exhibitors/findExhibitorAndTheaterByCityStateLike?cityState=" + term + "&territoryId=" + territoryId + "&ignoreLocalizationString", {
                errorMessage: translations.manageExhibitorsTheaters.messages.searchCitiesStatesError,
                showSpinner: false
            }).then(function(result) {
                return result.data.data.citiesStates;
            });
        };

        /**
         * Find exhibitor and theater by city state like
         * @memberOf angular_module.WB.cinehub.services.exhibitorsService
         * @public
         * @function findExhibitorAndTheaterByNameLocalNameLike
         * @returns {promise}
         * @param {string} term search term
         * @param {int} territoryId territory id
         */
        this.findExhibitorAndTheaterByNameLocalNameLike = function(term, territoryId) {
            return $http.get(API_ROOT + "exhibitors/findExhibitorAndTheaterByNameLocalNameLike?nameLocalName=" + term + "&territoryId=" + territoryId + "&ignoreLocalizationString", {
                errorMessage: translations.manageExhibitorsTheaters.messages.searchExhibitorsTheatersError,
                showSpinner: false
            }).then(function(result) {
                return result.data.data.names;
            });
        };

        /**
         * Update exhibitors
         * @function updateExhibitors
         * @memberOf angular_module.WB.cinehub.services.exhibitorsService
         * @public
         * @param {object}
         * @returns {promise}
         */
        this.updateExhibitors = function(exhibitors) {
            return $http.post(API_ROOT + "exhibitors/", exhibitors, {
                errorMessage: translations.global.messages.cannotUpdateExhibitors
            });
        };
    }

    services.service("exhibitorsService", ["$http", "API_ROOT", "localStorageService", exhibitorsService]);
}());
