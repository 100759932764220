(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Autofocus directive
     * @class autofocusDirective
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     */
    function autofocusDirective() {

        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @param {jqueryElement} $element $element
         * @private
         * @memberOf angular_module.WB.cinehub.directives.autofocusDirective
         */
        var link = function ($scope, $element) {
            setTimeout(function () {
                $element[0].focus();
            }, 100);

        };

        return {
            restrict: "A",
            link: link
        };
    }

    directives.directive("autofocus", [autofocusDirective]);
}());
