(function (global) {
    "use strict";

    /**
     * Loading interceptor for http service
     * @class loadingInterceptor
     * @memberOf angular_module.WB
     * @returns {object}
     * @param {!angular.$q} $q angular $q
     * @param {!angular.$rootScope} $rootScope root scope
     * @param {!angular.$log} $log angular log
     * @param {!angular.$anchorScroll} $anchorScroll angular anchorscroll service
     * @param {service} localStorageService local storage service
     * @param {constant} US_TERRITORY_ID US_TERRITORY_ID
     * @param {constant} US_LOCALE_ID US_LOCALE_ID
     * @param {service} $cookies $cookies
     * @param {service} $window $window
     * @param {constant} SECURE_COOKIES SECURE_COOKIES
     * @param {constant} DOMAIN DOMAIN
     */
    var loadingInterceptor = function ($q, $rootScope, $log, $anchorScroll, localStorageService, US_TERRITORY_ID, US_LOCALE_ID, WB360_ANON_TOKEN, $cookies, $window, $injector, SECURE_COOKIES, DOMAIN) {
        /**
         * xhrCreations
         * @type {int}
         * @private
         * @memberOf angular_module.WB.loadingInterceptor
         */
        var xhrCreations = 0;

        /**
         * xhrResolutions
         * @type {int}
         * @private
         * @memberOf angular_module.WB.loadingInterceptor
         */
        var xhrResolutions = 0;

        /**
         * Is loading
         * @function isLoading
         * @memberOf angular_module.WB.loadingInterceptor
         * @private
         */
        function isLoading() {
            return xhrResolutions < xhrCreations;
        }

        /**
         * Update status
         * @function updateStatus
         * @memberOf angular_module.WB.loadingInterceptor
         * @private
         * @param {object} config config
         */
        function updateStatus(config) {
            if (config && config.showSpinner === false) {
                $rootScope.loading = false;
            } else {
                $rootScope.loading = isLoading();
            }
        }

        /**
         * Adjust page size
         * @function adjustPageSize
         * @param {string} url url
         * @memberOf angular_module.WB.loadingInterceptor
         * @private
         */
        function adjustPageSize(url) {
            var pageRegex = /&page=(\d+)/ig,
                regexResult = pageRegex.exec(url),
                pageNumber = 0;
            if (regexResult && regexResult.length === 2) {
                pageNumber = parseInt(regexResult[1]);
                if (pageNumber > 0) {
                    pageNumber = pageNumber - 1;
                }
            }
            return url.replace(pageRegex, "&page=" + pageNumber);
        }

        /**
         * generate query string
         * @function generateQueryString
         * @memberOf angular_module.WB.loadingInterceptor
         * @private
         * @param {boolean} ignoreExhibitorTheater ignore exhibitor theater 
         */
        function generateQueryString(ignoreExhibitorTheater) {
            //location for data containing territoryId and localeId
            var location = localStorageService.get("location") || {},
                exhibitorId = localStorageService.get("currentExhibitorAffiliation") || -1,
                theaterId = localStorageService.get("currentTheaterAffiliation") || -1,
                territoryId = location.territoryId || US_TERRITORY_ID,
                localeId = location.localeId || US_LOCALE_ID,
                recType = "pub";

            if (ignoreExhibitorTheater) {
                return "&territoryId=" + territoryId + "&localeId=" + localeId + "&recType=" + recType;
            } else {
                return "&territoryId=" + territoryId + "&localeId=" + localeId + "&recType=" + recType + "&exhibitorId=" + exhibitorId + "&theaterId=" + theaterId;
            }
        }

        /**
         * determines if the request is a get endpoint (some are caching templates)
         * @function isGetEndpoint
         * @memberOf angular_module.WB.loadingInterceptor
         * @private
         */
        function isGetEndpoint(config) {
            return config.url.indexOf("http") > -1 && config.method.toUpperCase() === "GET";
        }

        /**
         * check if the url contains the querystring
         * @function hasQueryString
         * @memberOf angular_module.WB.loadingInterceptor
         * @private
         */
        function hasQueryString(url) {
            return url.indexOf("territoryId") > -1 && url.indexOf("localeId") > -1;
        }

        /**
         * create new endpoint url
         * @function createNewUrl
         * @memberOf angular_module.WB.loadingInterceptor
         * @private
         */
        function createNewUrl(url) {
            var queryString = "",
                newUrl = "",
                hasPageParameter = url.indexOf("page") > -1,
                hasProjectionParameter = url.indexOf("projection") > -1,
                ignoreLocalizationString = url.indexOf("ignoreLocalizationString") > -1,
                ignoreExhibitorTheater = url.indexOf("ignoreExhibitorTheater") > -1;

            queryString = generateQueryString(ignoreExhibitorTheater);
            newUrl = url + queryString;

            // Localization parameters need to be in front of page and projection since the order of the query strings matter in the back end.
            if (hasPageParameter && !ignoreLocalizationString) {
                newUrl = url.replace("&page=", queryString + "&page=").replace("?page=", "?" + queryString + "&page=");
            } else if (hasProjectionParameter && !ignoreLocalizationString) {
                newUrl = url.replace("&projection=", queryString + "&projection=").replace("?projection=", "?" + queryString + "&projection=");
            }

            if (ignoreLocalizationString) {
                newUrl = url.replace("&ignoreLocalizationString", "");
            }

            if (ignoreExhibitorTheater) {
                newUrl = newUrl.replace("&ignoreExhibitorTheater", "");
            }

            return newUrl;
        }

        /**
         * Xhr creation increment and status updateStatus
         * @function xhrCreationIncStatusUpdate
         * @private
         * @memberOf angular_module.WB.loadingInterceptor
         */
        function xhrCreationIncStatusUpdate() {
            xhrResolutions++;
            updateStatus();
        }

        return {
            request: function (config) {

                if (isGetEndpoint(config) && !hasQueryString(config.url)) {
                    config.url = createNewUrl(config.url);
                }

                if (config.url.indexOf("page") > -1) {
                    config.url = adjustPageSize(config.url);
                }

                if (/(?:[^\w]|$)(static|shared)(?:[^\w]|$)/i.test(config.url)) {
                    config.headers["Cache-Control"] = "max-age=86400";
                    config.cache = true;
                }
                if (/oauth\/token/ig.test(config.url)) {
                    config.headers.Authorization = WB360_ANON_TOKEN;
                } else if (!/static/ig.test(config.url)) {
                    config.headers.Authorization = "Bearer " + $cookies.get("accessToken");
                }
                if (/okta\/mfa/ig.test(config.url)) {
                    if (localStorageService.get("oktaSessionToken")) {
                        config.headers["okta-session-token"] = localStorageService.get("oktaSessionToken");
                    }
                }
                xhrCreations++;

                updateStatus(config);
                return config;
            },
            requestError: function (rejection) {
                xhrCreationIncStatusUpdate();
                $log.error("Request error:", rejection);
                return $q.reject(rejection);
            },
            response: function (response) {
                xhrCreationIncStatusUpdate();

                if (response.data && response.data.page) {
                    response.data.page.number += 1;
                } else if (response.data && response.data.totalPages) {
                    response.data.number += 1;
                }
                return response;
            },
            responseError: function (rejection) {
                var errorMessage = rejection.config && rejection.config.errorMessage ? rejection.config.errorMessage : "There was an error.",
                    alertKey = rejection.config && rejection.config.alertKey ? rejection.config.alertKey : "alert",
                    hideErrorAlert = rejection.config && rejection.config.hideErrorAlert;

                xhrCreationIncStatusUpdate();
                $log.error("Response error:", rejection);

                if (rejection.status === 401) {
                    $cookies.put("sessionExpiredError", true, {
                        sameSite: "strict",
                        secure: SECURE_COOKIES,
                        expires: new Date(moment().add(30, "minutes").format()),
                        domain: DOMAIN
                    });
                    $window.location.href = /\/login$/ig.test($window.location.pathname) ? "/login" : "/login#" + $window.location.pathname + $window.location.hash;
                    $cookies.remove("accessToken", {domain: DOMAIN, path: "/" });
                } else if (rejection.status === 403) {
                    $window.location.href = "/error/403";
                    $cookies.remove("accessToken", {domain: DOMAIN, path: "/" });
                } else if (rejection.status <= 0) {
                    var $http = $injector.get("$http");
                    return $http(rejection.config);
                } else {
                    if(!hideErrorAlert) {
                        $rootScope.$broadcast("showAlert", "danger", errorMessage, alertKey);
                    }
                }

                return $q.reject(rejection);
            }
        };
    };

    global.WB.factory("loadingInterceptor", ["$q", "$rootScope", "$log", "$anchorScroll", "localStorageService", "US_TERRITORY_ID", "US_LOCALE_ID", "WB360_ANON_TOKEN", "$cookies", "$window", "$injector", "SECURE_COOKIES", "DOMAIN", loadingInterceptor]);

}(window));
