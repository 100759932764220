(function() {
    "use strict";

    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * theater details controller
     * @dec The theater details controller
     * @class theaterDetailsController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {service} localStorageService localstorage service
     * @param {service} theatersService exhibitros service
     * @param {service} theaterScreensService theater screens service
     * @param {service} userService user service
     * @param {$uibModal} $uibModal $uibModal
     * @param {$window} $window $window
     * @param {service} restrictService restrict service
     * @param {service} deviceDetectorService device detector service
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     * @param {service} cacheService cache service
     */
    function theaterDetailsController(localStorageService, theatersService, theaterScreensService, userService, $uibModal, $window, restrictService, deviceDetectorService, DEFAULT_PAGE_SIZE, cacheService) {

        /**
         * Instance of theaterDetailsController
         * @type {theaterDetailsController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.theaterDetailsController
         */
        var self = this,

            /**
             * theater data copy
             * @memberOf angular_module.WB.cinehub.controllers.theaterDetailsController
             * @private
             * @type {object}
             */
            theaterDataCopy = null;

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.theaterDetailsController
         */
        self.pageHeaderItem = {
            pageTitle: "",
            breadcrumbItems: [{
                linkTitle: "",
                link: "/search/exhibitros-theaters"
            }, {
                linkTitle: "",
                link: ""
            }]
        };

        /**
         * The translations
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.theaterDetailsController
         */
        self.translations = localStorageService.get("translations");

        /**
         * impersonate user flag
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.theaterDetailsController
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");

        /**
         * The user info
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.theaterDetailsController
         */
        self.user = localStorageService.get("userInfo");

        /**
         * Sort string
         * @type {string}
         * @memberOf angular_module.WB.cinehub.controllers.theaterDetailsController
         * @public
         */
        self.sort = "name,asc";

        /**
         * theaters data
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.theaterDetailsController
         */
        self.theaterData = {};

        /**
         * Theaters data
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.theaterDetailsController
         */
        self.theaterScreenData = {
            page: {
                size: DEFAULT_PAGE_SIZE,
                number: 1
            }
        };

        /**
         * Get theater by id
         * @function getTheaterById
         * @memberOf angular_module.WB.cinehub.controllers.theaterDetailsController
         * @public
         * @returns {promise}
         */
        self.getTheaterById = function() {
            return theatersService.findById(window.WB.cinehub.preload.theaterId).success(function(result) {
                self.theaterData = result._embedded.theaters[0];
                if (self.pageHeaderItem.breadcrumbItems.length > 1) {
                    self.pageHeaderItem.breadcrumbItems[1].linkTitle = self.theaterData.localName;
                } else {
                    self.pageHeaderItem.breadcrumbItems[0].linkTitle = self.theaterData.localName;
                }
                theaterDataCopy = _.clone(self.theaterData);
            });
        };

        /**
         * Get theater screens
         * @function getTheaterScreens
         * @memberOf angular_module.WB.cinehub.controllers.theaterDetailsController
         * @public
         * @returns {promise}
         */
        self.getTheaterScreens = function(filterQuery, page, size, sortOrder) {
            var sort = sortOrder || self.sort;
            return theaterScreensService.findByTheaterId(window.WB.cinehub.preload.theaterId, self.theaterScreenData.page.number, self.theaterScreenData.page.size, sort).success(function(result) {
                self.theaterScreenData = result;
            });
        };

        /**
         * Save theater details info
         * @function savetheaterDetailsInfo
         * @memberOf angular_module.WB.cinehub.controllers.theaterDetailsController
         * @public
         */
        self.saveTheaterInfo = function() {
            self.theaterData.localNameManuallyUpdated = 1;
            theatersService.updateTheaters(self.theaterData).success(function() {
                self.isEditMode = false;
                theaterDataCopy = _.clone(self.theaterData);
                userService.updateUserAffiliationByTheater([self.theaterData]);
                cacheService.deleteExhibitorsTheatersRedisCache();
            });
        };

        /**
         * Cancel
         * @function cancel
         * @memberOf angular_module.WB.cinehub.controllers.theaterDetailsController
         * @public
         */
        self.cancel = function() {
            if (self.theaterForm.$dirty) {
                $uibModal.open({
                    templateUrl: "confirmationTemplate.html",
                    controller: "modalConfirmationController",
                    controllerAs: "modalConfirmationCtrl",
                    keyboard: false,
                    backdrop: "static",
                    size: "md",
                    resolve: {
                        message: function() {
                            return self.translations.global.messages.confirmationMessage;
                        },
                        headerTitle: function() {
                            return self.translations.global.messages.warning;
                        },
                        showAffirmativeBtn: function() {
                            return true;
                        },
                        affirmativeBtnText: function() {
                            return self.translations.global.labels.yesButton;
                        },
                        cancelBtnText: function() {
                            return self.translations.global.labels.noButton;
                        }
                    }
                }).result.then(function() {
                    self.theaterData = theaterDataCopy;
                    self.isEditMode = false;
                });
            } else {
                self.isEditMode = false;
            }
        };

        /**
         * Init
         * @function init
         * @memberOf angular_module.WB.cinehub.controllers.theaterDetailsController
         * @public
         */
        self.init = function() {
            if (deviceDetectorService.isMobileOrTablet()) {
                $window.location.href = "/";
                return false;
            }
            self.pageHeaderItem.pageTitle = self.translations.theaterDetails.labels.pageTitle;
            self.pageHeaderItem.breadcrumbItems[0].linkTitle = self.translations.theaterDetails.labels.breadcrumbLinkTitle;
            if (restrictService.getAccessGrant("standard")) {
                self.pageHeaderItem.breadcrumbItems.splice(0, 1);
            }
            self.getTheaterById();
            self.getTheaterScreens();
        };

        //INIT
        self.init();
    }

    controllers.controller("theaterDetailsController", ["localStorageService", "theatersService", "theaterScreensService", "userService", "$uibModal", "$window", "restrictService", "deviceDetectorService", "DEFAULT_PAGE_SIZE", "cacheService", theaterDetailsController]);
}());
