(function () {
    "use strict";
    var directives = angular.module("WB.cinehub.directives");
    directives.directive("ngEnter", function () {
        return function(scope, element, attrs) {
            element.bind("keydown keypress", function(event) {
                var isEnterkey = event.which === 13 || event.code === 13;
                if(isEnterkey) {
                    scope.$apply(function(){
                        scope.$eval(attrs.ngEnter);
                    });
                    
                    event.preventDefault();
                }
            });
        };
    });
} ());