(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Reset password controller
     * @class resetPasswordController
     * @memberOf angular_module.WB.cinehub.controllers
     * @desc This is reset password controller
     * @param {!angular.$rootScope} $rootScope angular root scope
     * @param {!angular.$scope} $scope angular scope
     * @param {service} passwordService reset password service
     * @param {service} localStorageService local storage service
     * @param {service} helpContactService help contact service
     * @param {service} userService user service
     */
    function resetPasswordController($scope, $rootScope, $q, passwordService, localStorageService, helpContactService, userService) {
        /**
         * Instance to resetPasswordController
         * @private
         * @type {resetPasswordController}
         * @memberOf angular_module.WB.cinehub.controllers.resetPasswordController
         */
        var self = this;

        /**
         * translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.resetPasswordController
         */
        self.translations = localStorageService.get("translations");

        /**
         * Submitted flag
         * @memberOf angular_module.WB.cinehub.controllers.resetPasswordController
         * @public
         * @type {bool}
         */
        self.submitted = false;

        /**
         * Reset succeeded flag
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.resetPasswordController
         * @type {bool}
         */
        self.resetSucceeded = false;

         /**
         * errMsg
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.resetPasswordController
         * @type {string}
         */
        self.errMsg = null;

        /**
         * Verification error flag
         * @type {boolean}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.resetPasswordController
         */
        self.verificationComplete = false;

        /**
         * Verification error flag
         * @type {boolean}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.resetPasswordController
         */
        self.verificationError = false;

        /**
         * Verification error message 
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.resetPasswordController
         */
        self.verificationErrorMessage = "";

        /**
         * Verification error message 
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.resetPasswordController
         */
        //commented Okta Migration
        //self.migrationMessage = "";
        
        /**
         * Validate password
         * @function validatePassword
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.resetPasswordController
         * @returns {promise}
         */
        var validatePassword = function () {
            var errorMessage = "";
            var promise = $q.defer();
            if (self.user.password !== self.user.password2) {
                promise.resolve(self.translations.resetPassword.messages.passwordsDoNotMatchError);
            } else {
                self.user.userId = self.user.userId ? self.user.userId : "";
                userService.getUserByUserIdAndLoginName(self.user.userId, self.user.loginName).success(function (result) {
                    self.userObj = result[0];
                    self.user.loginName = self.userObj.loginName;
                    var invalidPass = passwordService.invalidPassword(self.user.password);
                    var passwordUsername = passwordService.passwordUsernameError(self.user.password, self.user.loginName, self.userObj.firstName, self.userObj.lastName);
                    if (invalidPass) {
                        promise.resolve(self.translations.resetPassword.messages.invalidPasswordError);
                    } else if (passwordUsername) {
                        promise.resolve(self.translations.global.messages.username);
                    }
                    promise.resolve(errorMessage);
                }).error(function () {
                    promise.resolve(self.translations.resetPassword.messages.resetPasswordError);
                });
            }
            return promise.promise;
        };

        /**
         * Get parameter by name
         * @function getParameterByName
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.resetPasswordController
         * @returns {string}
         */
        var getParameterByName = function (name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return "";
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        };

        /**
         * Reset password
         * @memberOf angular_module.WB.cinehub.controllers.resetPasswordController
         * @public
         * @function resetPassword
         */
        self.resetPassword = function () {
            self.submitted = true;
            self.errMsg = null;
            if ($scope.formResetPassword.$valid) {
                validatePassword().then(function (errMsg) {
                    if (errMsg !== "") {
                        $scope.$emit("showAlert", "danger", errMsg);
                    } else {
                        $scope.$emit("hideAlert");
                        passwordService.resetPassword(self.user).success(function () {
                            self.resetSucceeded = true;
                        }).error(function (error) {
                            var errorMessage = "";
                            $rootScope.$broadcast("hideAlert");
                            errorMessage = getErrorMessage(error.code);
                            if (errorMessage === self.translations.registerProfile.messages.callFail) {
                                errorMessage = self.translations.resetPassword.messages.resetPasswordError;
                            }
                            if (errorMessage) {
                                $rootScope.$broadcast("showAlert", "danger", errorMessage, "anonymousUserAlert");
                            }
                        });
                    }
                });
            }
        };

        /**
         * Reset password
         * @memberOf angular_module.WB.cinehub.controllers.resetPasswordController
         * @public
         * @function resetPassword
         */
        self.verifyLoginNameAndToken = function () {
            self.verificationErrorMessage = getErrorMessage("CH_208");
            return userService.verifyResetPasswordToken(self.user).success(function (response) {
                if (response.status && response.status.toLowerCase() === "success") {
                    self.userObj = response.data.user;
                    self.verificationComplete = true;
                }
            }).error(function (response) {
                if (response.status && response.status.toLowerCase() === "fail") {
                    self.verificationComplete = true;
                    self.verificationError = true;
                    self.verificationErrorMessage = getErrorMessage(response.code);
                }
            });
        };

        /**
         * Get error message 
         * @function getErrorMessage
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.adminRegisteredProfileController
         * @returns {string}
         * @param {string} errorCode error code
         */
        var getErrorMessage = function (errorCode) {
            var errorMessage = "";
            switch (errorCode) {
                case "CH_100":
                    errorMessage = self.translations.registerProfile.messages.userNotFound;
                    break;
                case "CH_109":
                    errorMessage = self.translations.forgotPassword.messages.cantChangePasswordForDisabled;
                    break;
                case "CH_207":
                    errorMessage = self.translations.registerProfile.messages.invalidToken;
                    break;
                case "CH_217":
                    errorMessage = self.translations.registerProfile.messages.invalidToken;
                    break;
                case "CH_218":
                    errorMessage = self.translations.resetPassword.messages.resetLinkExpiredMsg;
                    break;
                case "CH_210":
                    errorMessage = self.translations.registerProfile.messages.invalidEmail;
                    break;
                default:
                    errorMessage = self.translations.registerProfile.messages.callFail;
            }
            return errorMessage;
        };


        /**
         * Init
         * @function init
         * @memberOf angular_module.WB.cinehub.controllers.resetPasswordController
         * @public
         */
        self.init = function () {
            var status =  getParameterByName("status");
            if (status === "expired") {
                self.errMsg = self.translations.forgotPassword.messages.passwordExpiryError;
            }

            self.user = {
                loginName: getParameterByName("loginName"),
                userId: getParameterByName("userId"),
                resetPasswordToken: getParameterByName("resetPasswordToken")
            };
            helpContactService.getHelpContactEmail().then(function (contactEmail) {
                self.contactEmail = contactEmail;
            });
            self.verifyLoginNameAndToken();
        };

        //INIT
        self.init();
    }

    controllers.controller("resetPasswordController", ["$scope","$rootScope", "$q", "passwordService", "localStorageService", "helpContactService", "userService", resetPasswordController]);
} ());
