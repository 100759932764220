(function ($) {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class oauthService
     * @memberOf angular_module.WB.cinehub.services
     * @desc authentication service
     * @param {!angular.$http} $http angular $http service
     * @param {constant} API_ROOT API_ROOT constant  
     */
    function oauthService($http, API_ROOT) {

        /**
         * get assets
         * @memberOf angular_module.WB.cinehub.services.oauthService
         * @public
         * @function getAppOauthToken
         * @returns {promise}
         * @todo Switch this to a correct endpoint.
         */
        this.getAppOauthToken = function () {
            return $http.post(API_ROOT + "oauth/token?grant_type=client_credentials&scope=write", {
                errorMessage: "Cannot retrieve the authentication token.",
                alertKey: "anonymousUserAlert",
                showSpinner: false
            });
        };

        /**
         * Get oauth token 
         * @memberOf angular_module.WB.cinehub.services.oauthService
         * @public
         * @function getUserOauthToken
         * @param {string} grantType grant type 
         * @param {string} username user name
         * @param {string} alertKey alert key
         * @returns {promise}
         */
        this.getUserOauthToken = function (grantType, username, password) {
            return $http.post(API_ROOT + "oauth/token?grant_type=" + grantType + "&scope=write", $.param({
                "username": username,
                "password": password
            }), {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                errorMessage: "Cannot retrieve the authentication token.",
                alertKey: "anonymousUserAlert",
                showSpinner: false
            });
        };

    }

    services.service("oauthService", ["$http", "API_ROOT", oauthService]);
}(window.jQuery));
