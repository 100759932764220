(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class filmService
     * @memberOf angular_module.WB.cinehub.services
     * @desc film service
     * @param {!angular.$http} $http angular $http service
     * @paarm {strict} API_ROOT API_ROOT constant
     */
    function filmService($http, API_ROOT, localStorageService) {

        /**
         * translations
         * @memberOf angular_module.WB.cinehub.services.filmService
         * @private
         * @type {object}
         */
        var translations = localStorageService.get("translations"),

            /**
             * Location
             * @memberOf angular_module.WB.cinehub.services.filmService
             * @private
             * @type {object}
             */
            location = localStorageService.get("location"),

            /**
             * user info
             * @memberOf angular_module.WB.cinehub.services.filmService
             * @private
             * @type {object}
             */
            user = localStorageService.get("userInfo"),

            /**
             * exhibitor id
             * @memberOf angular_module.WB.cinehub.services.filmService
             * @private
             * @type {int}
             */
            exhibitorId = localStorageService.get("currentExhibitorAffiliation") || -1,

            /**
             * theater id
             * @memberOf angular_module.WB.cinehub.services.filmService
             * @private
             * @type {int}
             */
            theaterId = localStorageService.get("currentTheaterAffiliation") || -1,

            /**
             * is admin flag
             * @memberOf angular_module.WB.cinehub.services.filmService
             * @private
             * @type {boolean}
             */
            isAdmin = user.role === "homeOfficeAdmin" || user.role === "territoryAdmin";

        /**
         * Get film source and cinehub data - This endpoint at most returns 0-0-ORIG (EIS), 0-0-PUB (HomeOffice Version), and localized film data.
         * @memberOf angular_module.WB.cinehub.services.filmService
         * @public
         * @param {int} territoryId territory id
         * @param {int} localeId locale id
         * @param {int} eisId eis id
         * @param {int} locLocaleId loc locale id for territory specific record
         * @param {int} locTerritoryId loc territory id for territory specific record
         * @returns {promise}
         * @function getFilmSourceAndCinehubData
         */
        this.getFilmSourceAndCinehubData = function (eisId, territoryId, localeId, locTerritoryId, locLocaleId) {
            var territoryIdLoc = locTerritoryId || "",
                localeIdLoc = locLocaleId || "";
            return $http.get(API_ROOT + "films/findByEisIdAndTerritoryIdAndLocaleId?eisId=" + eisId + "&territoryId=" + territoryId + "&localeId=" + localeId + "&exhibitorId=" + exhibitorId + "&theaterId=" + theaterId + "&locTerritoryId=" + territoryIdLoc + "&locLocaleId=" + localeIdLoc + "&ignoreLocalizationString", {
                errorMessage: translations.filmDetails.messages.getFilmDetailsError
            });
        };

        /**
         * Get hoa film assets
         * @memberOf angular_module.WB.cinehub.services.filmService
         * @public
         * @param {int} eisId film eisId
         * @param {string} category asset category
         * @param {str} wbAssetType asset type
         * @param {str} wbpiAssetType asset sub type
         * @param {str} fileType asset file type
         * @param {str} size page size
         * @param {str} page page index
         * @param {str} sort sorting
         * @returns {promise}
         * @function getHoaFilmAssets
         */
        this.getHoaFilmAssets = function (eisId, assetCategory, wbAssetType, wbpiAssetType, fileType, size, page, sort) {
            var error = "";
            if (assetCategory === "artwork") {
                error = translations.filmDetails.messages.getArtworkMaterialsError;
            } else if (assetCategory === "audioVideo") {
                error = translations.filmDetails.messages.getAudioVideoMaterialsError;
            } else if (assetCategory === "written") {
                error = translations.filmDetails.messages.getWrittenMaterialsError;
            } else {
                error = translations.filmDetails.messages.getOtherMaterialsError;
            }
            return $http.post(API_ROOT + "filmAssets/search/findHOAFilmAssetsByCategory?size=" + size + "&page=" + page + "&sort=" + sort + "&sort=wbAssetType,asc&sort=wbpiAssetType,asc&sort=fileName,asc&ignoreLocalizationString", {
                "wbAssetType": wbAssetType,
                "wbpiAssetType": wbpiAssetType,
                "fileType": fileType,
                "assetCategory": assetCategory,
                "eisId": eisId
            }, {
                errorMessage: error
            });
        };
        
        /**
         * Get film assets
         * @memberOf angular_module.WB.cinehub.services.filmService
         * @public
         * @param {int} filmId film id
         * @param {string} category asset category
         * @param {str} wbAssetType asset type
         * @param {str} wbpiAssetType asset sub type
         * @param {str} fileType asset file type
         * @param {str} size page size
         * @param {str} page page index
         * @param {str} sort sorting
         * @param {str} recType rec type
         * @returns {promise}
         * @function getFilmAssets
         */
        this.getFilmAssets = function (filmId, assetCategory, wbAssetType, wbpiAssetType, fileType, size, page, sort, recType, showAll, userRole) {
            var error = "";
            if (assetCategory === "artwork") {
                error = translations.filmDetails.messages.getArtworkMaterialsError;
            } else if (assetCategory === "audioVideo") {
                error = translations.filmDetails.messages.getAudioVideoMaterialsError;
            } else if (assetCategory === "written") {
                error = translations.filmDetails.messages.getWrittenMaterialsError;
            } else {
                error = translations.filmDetails.messages.getOtherMaterialsError;
            }
            if (userRole === "homeOfficeAdmin") {
                return $http.post(API_ROOT + "filmAssets/search/getFilmAssetsForHOA?size=" + size + "&page=" + page + "&sort=" + sort + "&sort=wbAssetType,asc&sort=wbpiAssetType,asc&sort=fileName,asc", {
                    "wbAssetType": wbAssetType,
                    "wbpiAssetType": wbpiAssetType,
                    "fileType": fileType,
                    "assetCategory": assetCategory,
                    "territoryId": location.territoryId,
                    "localeId": location.localeId,
                    "recType": recType,
                    "exhibitorId": exhibitorId,
                    "theaterId": theaterId,
                    "showKilled": isAdmin,
                    "film": filmId,
                    "showAll": showAll,
                    "userRole": userRole
                }, {
                    errorMessage: error
                });
            } else {
                return $http.post(API_ROOT + "filmAssets/search/getFilmAssetsByCategory?size=" + size + "&page=" + page + "&sort=" + sort + "&sort=wbAssetType,asc&sort=wbpiAssetType,asc&sort=fileName,asc", {
                    "wbAssetType": wbAssetType,
                    "wbpiAssetType": wbpiAssetType,
                    "fileType": fileType,
                    "assetCategory": assetCategory,
                    "territoryId": location.territoryId,
                    "localeId": location.localeId,
                    "recType": recType,
                    "exhibitorId": exhibitorId,
                    "theaterId": theaterId,
                    "showKilled": isAdmin,
                    "film": filmId,
                    "showAll": showAll,
                    "userRole": userRole
                }, {
                    errorMessage: error
                });
            }
        };

        /**
         * get film trailers
         * @memberOf angular_module.WB.cinehub.services.filmService
         * @public
         * @param {int} filmId film id
         * @param {int} size page size
         * @param {str} alertKey alert key
         * @returns {promise}
         * @function getFilmTrailers
         */
        this.getFilmTrailers = function (filmId, size, alertKey) {
            return $http.get(API_ROOT + "filmAssets/search/findLocByFilmIdAndWbAssetType?filmId=" + filmId + "&wbAssetType=Trailer", {
                errorMessage: translations.filmDetails.messages.getTrailers,
                alertKey: alertKey
            });
        };

        /**
         * get censor cards
         * @memberOf angular_module.WB.cinehub.services.filmService
         * @public
         * @param {int} filmId film id
         * @param {str} alertKey alert key
         * @returns {promise}
         * @function getCensorCards
         */
        this.getFilmRelatedAssets = function (filmId, alertKey) {
            return $http.get(API_ROOT + "filmAssets/findLocByFilmAssetTypes?filmId=" + filmId, {
                errorMessage: translations.editFilmModal.messages.getCensorCardAssetsError,
                alertKey: alertKey
            });
        };

        /**
         * create film details
         * @memberOf angular_module.WB.cinehub.services.filmService
         * @public
         * @param {obj} film film
         * @param {str} alertKey alert key
         * @returns {promise}
         * @function createFilmDetails
         */
        this.createFilmDetails = function (film, alertKey) {
            return $http.post(API_ROOT + "films/", film, {
                errorMessage: translations.editFilmModal.messages.publishError,
                alertKey: alertKey
            });
        };

        /**
         * update film details
         * @memberOf angular_module.WB.cinehub.services.filmService
         * @public
         * @param {obj} film film
         * @param {str} alertKey alert key
         * @returns {promise}
         * @function updateFilmDetails
         */
        this.updateFilmDetails = function (film, alertKey) {
            return $http.put(API_ROOT + "films/" + film.filmId, film, {
                errorMessage: translations.editFilmModal.messages.publishError,
                alertKey: alertKey
            });
        };
        
        /**
         * upload onesheet
         * @memberOf angular_module.WB.cinehub.services.filmService
         * @public
         * @param {obj} film film
         * @param {str} alertKey alert key
         * @returns {promise}
         * @function uploadOnesheet
         */
        this.uploadOnesheet = function (film, alertKey) {
            return $http.post(API_ROOT + "films/uploadOnesheet/", film, {
                errorMessage: translations.editFilmModal.messages.publishError,
                alertKey: alertKey
            });
        };

        /**
         * get asset types and sub types
         * @memberOf angular_module.WB.cinehub.services.filmService
         * @public
         * @param {int} filmId film id
         * @param {str} assetCategory asset category
         * @returns {promise}
         * @function getAssetTypesAndSubTypes
         */
        this.getAssetTypesAndSubTypes = function (filmId, assetCategory) {
            return $http.get(API_ROOT + "filmAssets/findLocAssetTypeAndSubtype?filmId=" + filmId + "&assetCategory=" + assetCategory, {
                errorMessage: translations.filmDetails.messages.getAssetTypesAndSubTypesError,
                showSpinner: false
            });
        };

        /**
         * Get all films excluding highlighted
         * @memberOf angular_module.WB.cinehub.services.filmService
         * @public
         * @param {int} page page number
         * @param {int} size page size
         * @param {string} sort sort
         * @returns {promise}
         * @function getAllFilmsExcludingHighlighted
         */
        this.getAllFilmsExcludingHighlighted = function (page, size, sort) {
            return $http.get(API_ROOT + "films/search/findLocAllForHighlightedFilms?page=" + page + "&size=" + size + "&sort=" + sort, {
                errorMessage: translations.editMainFilmCarousel.messages.getAllFilmsExcludingHighlightedError
            });
        };

        /**
         * Get asset type and subtype by type and subtype
         * @memberOf angular_module.WB.cinehub.services.filmService
         * @public
         * @param {int} filmId film id
         * @param {string} assetCategory asset category
         * @param {string} type asset type
         * @param {string} subType asset subtype
         * @param {string} fileType asset file type
         * @param (string) userRole user role
         * @returns {promise}
         * @function getAssetTypeAndSubtypeByTypeAndBySubtype
         */
        this.getAssetTypeAndSubtypeByTypeAndBySubtype = function (filmId, assetCategory, type, subType, fileType, recType, showAll) {
            var findLocAssetTypeAndSubtypeByTypeAndBySubtype = user.role === "homeOfficeAdmin" ? "filmAssets/findLocAssetTypeAndSubtypeForHOA" : "filmAssets/findLocAssetTypeAndSubtypeByTypeAndBySubtype";
            return $http.post(API_ROOT + findLocAssetTypeAndSubtypeByTypeAndBySubtype, {
                "wbAssetType": type,
                "wbpiAssetType": subType,
                "fileType": fileType,
                "assetCategory": assetCategory,
                "territoryId": location.territoryId,
                "localeId": location.localeId,
                "recType": recType,
                "exhibitorId": exhibitorId,
                "theaterId": theaterId,
                "showKilled": isAdmin,
                "showAll": showAll,
                "film": filmId
            }, {
                errorMessage: translations.filmDetails.messages.getAssetTypesAndSubTypesError,
                showSpinner: false
            });
        };

        /**
         * Get asset type and subtype by type and subtype for hoa
         * @memberOf angular_module.WB.cinehub.services.filmService
         * @public
         * @param {int} eisId film eisId
         * @param {string} assetCategory asset category
         * @param {string} type asset type
         * @param {string} subType asset subtype
         * @param {string} fileType asset file type
         * @returns {promise}
         * @function findLocAssetTypeAndSubtypeByTypeAndBySubtypeForHoa
         */
        this.findLocAssetTypeAndSubtypeByTypeAndBySubtypeForHoa = function (eisId, assetCategory, type, subType, fileType, showAll) {
            return $http.post(API_ROOT + "filmAssets/findLocAssetTypeAndSubtypeByTypeAndBySubtypeForHoa", {
                "wbAssetType": type,
                "wbpiAssetType": subType,
                "fileType": fileType,
                "eisId": eisId,
                "assetCategory": assetCategory,
                "showAll": showAll
            }, {
                errorMessage: translations.filmDetails.messages.getAssetTypesAndSubTypesError,
                showSpinner: false
            });
        };
                
        /**
         * get Material Tab Count
         * @memberOf angular_module.WB.cinehub.services.filmService
         * @public
         * @param {int} filmId film id
         * @param {string} category asset category
         * @param {str} wbAssetType asset type
         * @param {str} wbpiAssetType asset sub type
         * @param {str} fileType asset file type
         * @param {str} sort sorting
         * @param {str} recType rec type
         * @param {str} size page show all
         * @param {str} page page user Role 
         * @returns {promise}
         * @function getMaterialTabCount
         */
        this.getMaterialTabCount = function (filmId, assetCategory, wbAssetType, wbpiAssetType, fileType, sort, recType, showAll, userRole) {
            var error = "";
            if (assetCategory === "artwork") {
                error = translations.filmDetails.messages.getArtworkMaterialsError;
            } else if (assetCategory === "audioVideo") {
                error = translations.filmDetails.messages.getAudioVideoMaterialsError;
            } else if (assetCategory === "written") {
                error = translations.filmDetails.messages.getWrittenMaterialsError;
            } else {
                error = translations.filmDetails.messages.getOtherMaterialsError;
            }
            if (userRole === "homeOfficeAdmin") {
                return $http.post(API_ROOT + "filmAssets/search/getFilmAssetsCountForHOA?sort=" + sort + "&sort=wbAssetType,asc&sort=wbpiAssetType,asc&sort=fileName,asc", {
                    "wbAssetType": wbAssetType,
                    "wbpiAssetType": wbpiAssetType,
                    "fileType": fileType,
                    "assetCategory": assetCategory,
                    "territoryId": location.territoryId,
                    "localeId": location.localeId,
                    "recType": recType,
                    "exhibitorId": exhibitorId,
                    "theaterId": theaterId,
                    "showKilled": isAdmin,
                    "film": filmId,
                    "showAll": showAll,
                    "userRole": userRole
                }, {
                    errorMessage: error
                });
            } else {
                return $http.post(API_ROOT + "filmAssets/search/getFilmAssetsCountByCategory?sort=" + sort + "&sort=wbAssetType,asc&sort=wbpiAssetType,asc&sort=fileName,asc", {
                    "wbAssetType": wbAssetType,
                    "wbpiAssetType": wbpiAssetType,
                    "fileType": fileType,
                    "assetCategory": assetCategory,
                    "territoryId": location.territoryId,
                    "localeId": location.localeId,
                    "recType": recType,
                    "exhibitorId": exhibitorId,
                    "theaterId": theaterId,
                    "showKilled": isAdmin,
                    "film": filmId,
                    "showAll": showAll,
                    "userRole": userRole
                }, {
                    errorMessage: error
                });
            }
        };

        /**
         * Split multi value Field by filtering empty values
         * @function splitMultiValFields
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.filmController
         */
        this.splitMultiValFields = function(str) {
            return str.split(",").filter(function(e) {
                return e !== "";
            });
        };
        /**
         * get Material Tab Count Test
         * @memberOf angular_module.WB.cinehub.services.filmService
         * @public
         * @param {int} filmId film id
         * @param {string} category asset category
         * @param {str} wbAssetType asset type
         * @param {str} wbpiAssetType asset sub type
         * @param {str} fileType asset file type
         * @param {str} sort sorting
         * @param {str} recType rec type
         * @param {str} size page show all
         * @param {str} page page user Role 
         * @returns {promise}
         * @function getMaterialTabCount
         */
        this.getFilmTabCount = function (filmId, sort, recType, userRole) {
            return $http.post(API_ROOT + "filmAssets/search/getFilmTabCounts", {
                "territoryId": location.territoryId,
                "localeId": location.localeId,
                "recType": recType,
                "exhibitorId": exhibitorId,
                "theaterId": theaterId,
                "film": filmId,
                "pastDays": 1000,
                "userRole": userRole,
                "sort": "lastModified,desc"
            }, {
                errorMessage: "error while retrieving data"
            });
        };

    /**
    * get film rating
    * @memberOf angular_module.WB.cinehub.services.filmService
    * @public
    * @returns {promise}
    * @function getFilmRating
    */
        this.getFilmRating = function (territoryId) {
            return $http.post(API_ROOT + "filmRatings/findByTerritoryId", {
                "territoryId" : territoryId
            }, {
                errorMessage: "error while retrieving data"
            });
        };

    }

    services.service("filmService", ["$http", "API_ROOT", "localStorageService", filmService]);
}());
