(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Modal add edit news controller
     * @dec The modal add edit news controller 
     * @class modalDeleteNewsController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.Scope} $scope angular scope
     * @param {$uibModalInstance} $uibModalInstance modal instance 
     * @param {$window} $window window instance 
     * @param {service} deleteNewsService delete news service 
     * @param {service} localStorageService local storage service 
     * @param {object} newsItem news item
     */
    function modalDeleteNewsController($scope, $uibModalInstance, $window, deleteNewsService, newsItem, localStorageService) {
        
        /**
         * Instance of modalDeleteNewsController
         * @type {modalDeleteNewsController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalDeleteNewsController
         */
        var self = this;

        /**
         * news title
         * @type {modalDeleteNewsController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalDeleteNewsController
         */
        self.newsTitle = newsItem.title;

        /**
         * translations
         * @memberOf angular_module.WB.cinehub.controllers.modalDeleteNewsController
         * @public
         * @type {object}
         */
        self.translations = localStorageService.get("translations");

        /**
         * Cancel 
         * @function cancel 
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalDeleteNewsController\
         */
        self.cancel = function() {
            $uibModalInstance.dismiss();
        };

        /**
         * delete the news item
         * @function publish 
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalDeleteNewsController
         */
        self.delete = function() {
            var errorMessage = self.translations.deleteNewsModal.messages.deleteNewsError,
                alertKey = "modalAlert";

            deleteNewsService.delete(newsItem, errorMessage, alertKey).success(function () {
                $uibModalInstance.close();
                $window.location.href = "/news-list";
            });
        };
    }
    
    controllers.controller("modalDeleteNewsController", ["$scope", "$uibModalInstance", "$window", "deleteNewsService", "newsItem", "localStorageService", modalDeleteNewsController]);
}());
