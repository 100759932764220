(function () {
    "use strict";
    var services = angular.module("WB.cinehub.services");

    /**
     * Location service
     * @class locationService
     * @memberOf angular_module.WB.cinehub.services
     * @desc This is device detector service
     * @param {$window} $window window
     * @param {service} localStorageService local storage service
     * @param {service} translateService translate service
     */
    function locationService($window, localStorageService, translateService) {

        /**
         * instance to locationService
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.locationService
         * @type {locationService}
         */
        var self = this,

            /**
             * location
             * @memberOf angular_module.WB.cinehub.controllers.locationService
             * @private
             * @type {object}
             */
            location = localStorageService.get("location") || {},

            /**
             * User info
             * @memberOf angular_module.WB.cinehub.controllers.locationService
             * @private
             * @type {object}
             */
            user = localStorageService.get("userInfo");

        /**
         * Find items by ids
         * @private
         * @function findItemsByIds
         * @memberOf angular_module.WB.cinehub.controllers.locationService
         * @param {object[]} collection collection
         * @param {string} property property
         * @param {object} values values
         */
        var findItemsByIds = function (collection, property, values) {
            return _.filter(collection, function (item) {
                return _.includes(values, item[property]);
            });
        };

        /**
         * sort affiliation list
         * @private
         * @function sortAffiliationList
         * @memberOf angular_module.WB.cinehub.controllers.locationService
         * @param {object[]} list territoryLanguages
         * @param {int} defaultIndex default index
         */
        var sortAffiliationList = function (list, defaultIndex) {
            var defaultElement = list[defaultIndex];
            list.splice(defaultIndex, 1);

            list.unshift(defaultElement);
        };

        /**
         * Load translations
         * @function loadTranslations
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.locationService
         */
        var loadTranslations = function (locale) {
            return translateService.loadTranslations(locale).success(function (result) {
                localStorageService.set("translations", result);
            });
        };

        /**
         * save location in local storage
         * @private
         * @function saveLocationInLocalStorage
         * @memberOf angular_module.WB.cinehub.controllers.locationService
         * @param {string} locale locale
         * @param {object} territory territory
         */
        var saveLocationInLocalStorage = function (locale, territory) {
            var translations = localStorageService.get("translations"),
                locationInfoFromStorage = _.find(translations.values.locales, {
                    code: locale
                });

            location.territoryId = territory.id.toString();
            location.locale = locale;
            location.localeId = locationInfoFromStorage.id.toString();
            location.value = locationInfoFromStorage.value;
            localStorageService.set("location", location);
        };

        /**
         * Get territory languages
         * @function getTerritoryLanguages
         * @public
         * @param {Object} user userObj
         * @memberOf angular_module.WB.cinehub.controllers.locationService
         * @returns {object[]}
         */
        self.getTerritoryLanguages = function (userObj) {
            var translations = localStorageService.get("translations");
            if (!user || !user.territoryId) {
                if (userObj && userObj.territoryId) {
                    user = userObj;
                } else {
                    return false;
                }
            }
            var territoryIds = [user.territoryId],
                defaultIndex = 0,
                territoryLanguages = [];

            if (user.additionalCountries) {
                territoryIds = territoryIds.concat(JSON.parse(user.additionalCountries));
            }

            territoryLanguages = findItemsByIds(translations.values.territories, "code", territoryIds);
            defaultIndex = _.findIndex(territoryLanguages, {
                id: user.territoryId
            });
            for (var i = 0; i < territoryLanguages.length; i++) {
                territoryLanguages[i].languages = _.filter(translations.values.locales, {
                    extraCode: territoryLanguages[i].code
                });
            }
            sortAffiliationList(territoryLanguages, defaultIndex);

            return territoryLanguages;
        };

        /**
         * Get territory
         * @function getTerritory
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.locationService
         * @returns {object}
         */
        self.getTerritory = function () {
            var territoryLanguages = self.getTerritoryLanguages();
            return location.locale ? _.find(territoryLanguages, {
                id: location.territoryId
            }) : territoryLanguages[0];
        };

        /**
         * Get default locale
         * @function getDefaultLocale
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.locationService
         * @returns {string}
         */
        self.getDefaultLocale = function () {
            var translations = localStorageService.get("translations");
            if (!user || !user.localeId || !translations) {
                return false;
            }
            return _.find(translations.values.locales, {
                id: user.localeId
            }).code;
        };

        /**
         * Get locale text
         * @function getLocaleText
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.locationService
         * @returns {string}
         */
        self.getLocaleText = function () {
            var territory = self.getTerritory(),
                translations = localStorageService.get("translations");
            if ((location && location.locale) || (territory && territory.languages)) {
                return location.locale ? _.find(translations.values.locales, {
                    id: location.localeId
                }).value : territory.languages[0].value;
            }
        };

        /**
         * Change locale
         * @memberOf angular_module.WB.cinehub.controllers.locationService
         * @function changeLocale
         * @public
         * @param {string} locale new locale
         * @param {object} territory new territory
         * @param {boolean} reload reload flag
         * @param {function} callback callback function
         * @param {boolean} isCallback flag
         */
        self.changeLocale = function (locale, territory, reload, callback, isCallback) {
            var languageSwitched = location.locale !== locale;
            if (languageSwitched || isCallback) {
                loadTranslations(locale).then(function () {
                    saveLocationInLocalStorage(locale, territory);
                    //$window.location.reload(true);
                    if (reload) {
                        $window.location.href = "/";
                    }
                    if (typeof callback === "function") {
                        callback();
                    }
                });
            }
        };

        /**
         * Set locale
         * @memberOf angular_module.WB.cinehub.controllers.locationService
         * @function setDefaultLocale
         * @public
         */
        self.setDefaultLocale = function () {
            var locale = self.getDefaultLocale(),
                territory = self.getTerritory();

            if (location.locale) {
                self.changeLocale(locale, territory);
            } else {
                saveLocationInLocalStorage(locale, territory);
            }
        };

        /**
         * Identify Non English Lang Territories
         * @memberOf angular_module.WB.cinehub.controllers.locationService
         * @function isNonEnglishTerritory
         * @public
         */
        self.isNonEnglishTerritory = function (user) {
            var liveLocales = localStorageService.get("liveLocales");
            var userLocale = _.filter(liveLocales, function(loc) {
                return loc.territoryId === user.territoryId;
            });
            var nonEngTerr = _.some(userLocale, function(loc) {
                return loc.isNonEngTerr === "Y";
            });
            return nonEngTerr;
        };

        /**
         * filter locale base on region
         * @memberOf angular_module.WB.cinehub.controllers.locationService
         * @function filterLocaleByRegion
         * @param {string} region region
         * @public
         */
        self.filterLocaleByRegion = function (region) {
            var liveLocales = localStorageService.get("liveLocales");
            var matchingLocale =_.findLast(liveLocales, {
                regionId: region.id
            });
            var filterPredicate = { regionId: region.id };
            if (matchingLocale && matchingLocale.regionGroupId) {
                filterPredicate = {
                    regionGroupId: matchingLocale.regionGroupId
                };
            }
            var localeData =_.filter(liveLocales, filterPredicate);
            return localeData;
        };


        /**
         * get Regions with any active locales
         * @memberOf angular_module.WB.cinehub.controllers.locationService
         * @function filterLocaleByRegion
         * @param {string} region region
         * @public
         */
        self.getActiveRegions = function (regions) {
            var liveLocales = localStorageService.get("liveLocales");
            var activeRegions = localStorageService.get("activeRegions");
            if(activeRegions && !_.isEmpty(activeRegions)) {
                return activeRegions;
            } else {
                activeRegions = [];
                if (liveLocales && !_.isEmpty(liveLocales)) {
                    regions.forEach(function (region) {
                        if (_.findIndex(liveLocales, {
                            regionId: region.id }) >= 0) {
                            activeRegions.push(region);
                        }
                    });
                    localStorageService.set("activeRegions", activeRegions);
                    return activeRegions;
                }
            }
            return regions;
        };
    }

    services.service("locationService", ["$window", "localStorageService", "translateService", locationService]);
}());
