(function () {
    "use strict";

    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Registration controller
     * @class registrationController
     * @memberOf angular_module.WB.cinehub.controllers.registrationController
     * @desc This is registration controller
     * @param {!angular.$scope} $scope angular root scope
     * @param {!angular.$rootScope} $rootScope angular root scope
     * @param {$window} $window window instance
     * @param {$uibModal} $uibModal uib modal
     * @param {service} localStorageService local storage service
     * @param {service} registrationService registration service
     * @param {service} additionalAffiliationService additionalAffiliation service
     * @param {service} userService user service
     * @param {array} STATE_LIST state list constant for us and canada
     * @param {service} convertLocalizationCodeToValueService convert localization code to value
     * @param {service} emailService email service
     * @param {service} locationService location service
     * @param {service} helpContactService help contact service
     * @param {service} territoriesService territories service
     * @param {service} privacyPolicyService privacy policy service
     */
    function registrationController($scope, $rootScope, $window, $uibModal, localStorageService, registrationService, additionalAffiliationService, userService, STATE_LIST, convertLocalizationCodeToValueService, emailService, locationService, helpContactService, territoriesService, privacyPolicyService, DEFAULT_CONTACT_US_EMAIL) {

        /**
         * Instance to registrationController
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @private
         * @type {registrationController}
         */
        var self = this,

            /**
             * registration info to be cloned before submit in case of error
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.registrationController
             */
            registrationInfo = {},

            /**
             * initial user state object
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.registrationController
             */
            initialUserState = {
                receiveNewsletter: false,
                receiveNewMaterialUpdates: false,
                receiveNewsUpdates: false,
                receiveReleaseDateUpdates: false,
                creationDate: moment().utc().format()
            };

        /**
         * USA country code constant
         * @type {constant}
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @public
         */
        self.USA = "176";


        /**
         * CANADA country code constant
         * @type {constant}
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @public
         */
        self.CANADA = "177";
               
        /**
         * Registration form
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         */
        self.registrationForm = null;
        
        /**
         * translated labels/messages/values stored in local storage
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @public
         * @type {object}
         */
        self.translations = localStorageService.get("translations");

        /**
         * Email service
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @type {service}
         * @public
         */
        self.emailService = emailService;

        /**
         * Registration steps 
         * @constant
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.registrationController 
         */
        self.REGISTRATION_STEPS = {
            LANGUAGE_USER_TYPE: 1,
            ACCOUNT_INFO: 2,
            PASSWORD: 3,
            THEATER_AFFILIATION: 21,
            EXHIBITOR_AFFILIATION: 22,
            ADDITIONAL_EXHIBITOR_AFFILIATION: 23,
            PREFERENCES: 4,
            REVIEW: 5,
            COMPLETE: 6
        };

        /**
         * sub step in step 1
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @type {int}
         * @public
         */
        self.subStep = 1;

        /**
         * current step
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @type {int}
         * @public
         */
        self.currentStep = 1;
        
        /**
         * state list
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @type {array}
         * @public
         */
        self.stateList = STATE_LIST;

        /**
         * email format regex
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @type {regex}
         */
        self.emailFormat = emailService.getEmailFormatRegex();

        /**
         * user object to store all the registration data
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @type {obj}
         * @public
         */
        self.user = _.clone(initialUserState);

        /**
         * territories within the region excluding the selected territory
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @type {array}
         * @public
         */
        self.otherRegionTerritories = [];

        /**
         * addtional affiliations list to store additional affiliation object 
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @type {array}
         * @public
         */
        self.additionalAffiliations = [];

        /**
         * user affiliations list to store affiliation object 
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @type {array}
         * @public
         */
        self.user.userAffiliations = [];

        /**
         * To check is existing or newly created user
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @type {boolean}
         * @public
         */
        self.isExistingUser = false;

        /**
         * additional affiliation object to store affiliation details
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @type {array}
         * @public
         */
        self.additionalAffiliation = {};

        self.exhibitorRelationshipExist = true;
        /**
         * get country code
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function getCountryCode
         * @private
         */
       /* var getCountryCode = function () {
            registrationService.getTerritoryInfo(self.user.territoryId).success(function (result) {
                self.countryCode = result._embedded.territories.length > 0 ? result._embedded.territories[0].countryCode : "";
            });
        };*/

        /**
         * check if its isNonEnglishTerritory
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function isNonEnglishTerritory
         * @private
         */
        self.isNonEnglishTerritory = function () {
            return locationService.isNonEnglishTerritory(self.user);
        };
        
        /**
         * get contact info
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function getContactInfo
         * @private
         */
        var getContactInfo = function () {
            territoriesService.getTerritoryById(self.user.territoryId).success(function (result) {
                self.contactEmail = result._embedded.territories.length > 0 ? result._embedded.territories[0].contactInfo : "";
                self.countryCode = result._embedded.territories.length > 0 ? result._embedded.territories[0].countryCode : "";

                self.contactEmail = emailService.isValidEmail(self.contactEmail) ? self.contactEmail : DEFAULT_CONTACT_US_EMAIL;
            });
        };

        /**
         * store user territory info in local storage. I need this for viewing anti-piracy, privacy policy, or terms of use pages in the appropriate language.
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function storeUserTerritory
         * @private
         */
        var storeTerritoryInfo = function () {
            var territoryInfo = {
                territoryId: self.user.territoryId,
                localeId: self.user.localeId
            };
            localStorageService.set("territoryInfo", territoryInfo);
        };

        /**
         * finalize registration data by modifying it to store proper values
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function finalizeRegistrationInfo
         * @private
         */
        var finalizeRegistrationInfo = function () {
            switch (self.user.userType) {
                case "ex-theater":
                    self.user.theaterAffiliations = self.user.selectedTheaters && self.user.selectedTheaters.length > 0 ? JSON.stringify(self.user.selectedTheaters) : "";
                    break;
                case "ex-other":
                    self.user.exhibitorRelationship = self.user.exhibitorRelationship && self.user.exhibitorRelationship.length > 0 ? JSON.stringify(self.user.exhibitorRelationship) : "";
                    break;  
                default:
                    break;
            }

            if (self.user.userType !== "ex-other") {
                self.user.exhibitorRelationship = null;
            }

            self.user.primaryPhone = "+" + self.countryCode + "-" + self.user.phone;

        };

        /**
         * set other region territories
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function setOtherRegionTerritories
         * @private
         */
        var setOtherRegionTerritories = function () {
            self.otherRegionTerritories = _.filter(self.translations.values.territories, function (item) {
                return item.id !== self.user.territoryId && item.extraCode === self.user.regionId;
            });
        };

        /**
         * show confirmation dialog before closing the tab/window during the registration
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function confirmBeforeExit
         * @todo http://stackoverflow.com/questions/37921349/chrome-onbeforeunload-method-does-not-use-given-text
         * Chrome does not allow us to set the custom message.
         * @private
         */
        var confirmBeforeExit = function () {
            var confirmationMessage = self.translations ? self.translations.global.messages.cancelRegistration : "";
            $window.onbeforeunload = function () {
                return confirmationMessage;
            };
        };

        /**
         * set user data to reload the page after selecting the language
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function setUserData
         * @private
         */
        var setUserData = function () {
            var user = localStorageService.get("registrationData");
            var localeData = localStorageService.get("localeData");

            if (user) {
                self.user.regionId = user.regionId;
                self.user.territory = user.territory;
                self.user.territoryId = user.territoryId;
                self.user.localeId = user.localeId;
                self.user.locale = user.locale;
                //self.user.exhibitorRelationship = null;
                self.currentStep = self.REGISTRATION_STEPS.LANGUAGE_USER_TYPE;
                self.subStep = 3;
            }
            if(localeData && self.user.regionId) {
                self.locales = localeData;
            }
        };

        /**
         * Validate account info
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function validateAccountInfo
         */
        var validateAccountInfo = function () {
            if (self.user.email) {
                self.isWBEmail = self.isInternalAccount;
                self.user.loginName = self.user.email;
            }
        };

        /**
         * Reset registration form
         * @function resetRegistrationForm
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         */
        var resetRegistrationForm = function () {
            self.registrationForm.$setPristine();
            self.registrationForm.$submitted = false;
        };

        /**
         * Conver localization code to value
         * @type {service}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         */
        self.convertLocalizationCodeToValueService = convertLocalizationCodeToValueService;

        /**
         * getLocaleByRegion
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function getLocaleByRegion
         * @param {obj} region region object
         * @public
         */
        self.getLocaleByRegion = function (region) {
            if (self.user.regionId && self.user.regionId === region.id) {
                return;
            }
            var usrLocale = localStorageService.get("browserLanguage"),
                liveLocales = localStorageService.get("liveLocales");
            usrLocale = usrLocale ? usrLocale : "en-US";
            var localeObj = _.find(liveLocales, function(loc) {
                return loc.abbreviation === usrLocale;
            });
            if (!localeObj) {
                localeObj = _.find(liveLocales, function(loc) {
                    return loc.abbreviation === "en-GB";
                });
            }
            if ((self.user && !self.user.regionId)
                || (localStorageService.get("location")
                    && localStorageService.get("location").locale === localeObj.abbreviation)) {
                self.setRegionData(region);
            } else {
                self.setTerritoryData(localeObj, region, true);
            }
        };
    

        /**
         * set territory data
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function setTerritoryData
         * @param {obj} liveLocale locale object
         * @param {string} region region
         * @param {boolean} isRegionChanged isRegionChanged
         * @public
         */
        self.setTerritoryData = function (liveLocale, region, isRegionChanged) {
            if (liveLocale && localStorageService.get("location")
                && localStorageService.get("location").locale === liveLocale.abbreviation) {
                self.setLocaleLanguage(liveLocale, region, isRegionChanged);
            } else {
                locationService.changeLocale(liveLocale.abbreviation, {
                    id: liveLocale.territoryId
                }, false, function () {
                    self.setLocaleLanguage(liveLocale, region, isRegionChanged);
                });
            }
        };

        /**
         * set Region Data
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function setRegionData
         * @param {string} region region
         * @public
         */
        self.setRegionData = function (region) {
            self.user = {};
            self.user.regionId = region.id;
            var localeData = locationService.filterLocaleByRegion(region);
            self.locales = localeData;
            localStorageService.set("localeData", localeData);
        };

        /**
         * getAdditionalLocaleByRegion
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function getAdditionalLocaleByRegion
         * @public
         */
        self.getAdditionalLocaleByRegion = function () {
            var result =  additionalAffiliationService.getAdditionalLocaleByRegion(self.additionalAffiliation, self.user, self.additionalAffiliations, self.isExistingUser);
            setResultValues(result);
            localStorageService.set("additionalLocales", self.additionalAffiliation.additionalLocales);
        };

        /**
         * getAdditionalLocaleByRegionExOther
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function getAdditionalLocaleByRegionExOther
         * @public
         */
        self.getAdditionalLocaleByRegionExOther = function (selectedItem) {
            self.additionalAffiliation = null;
            self.additionalAffiliation = {
                additionalRegion: selectedItem
            };
            var result =  additionalAffiliationService.getAdditionalLocaleByRegion(self.additionalAffiliation, self.user, self.additionalAffiliations, self.isExistingUser, "registration");
            setResultValues(result);
            localStorageService.set("additionalLocales", self.additionalAffiliation.additionalLocales);
        };
        /**
         * Set Additional Territory Data
         * @function setAdditionalTerritoryData
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @return {(boolean|object[])}
         */
        self.setAdditionalTerritoryData = function () {
            self.additionalAffiliation =  additionalAffiliationService.setAdditionalTerritoryData(self.additionalAffiliation);
        };

        /**
         * set Locale Language
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function setLocaleLanguage
         * @param {obj} liveLocale locale object
         * @param {string} region region
         * @param {boolean} isRegionChanged isRegionChanged
         * @public
         */
        self.setLocaleLanguage = function (liveLocale, region, isRegionChanged) {
            self.translations = localStorageService.get("translations");
            var selectedTerritory = _.filter(self.translations.values.territories, {
                    id: liveLocale.territoryId
                }),
                location = {
                    locale: liveLocale.abbreviation
                };
            self.user = {};
            self.user.territory = selectedTerritory[0].value;
            self.user.territoryId = liveLocale.territoryId;
            if (!isRegionChanged) {
                self.user.regionId = liveLocale.regionId;
                self.user.localeId = liveLocale.id;
                self.user.locale = liveLocale.name;
                self.contactEmail = getContactInfo();
                localStorageService.set("registrationData", self.user);
                localStorageService.set("location", location);
            } else {
                self.setRegionData(region);
            }
        };


        /**
         * set user type
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function setUserType
         * @param {obj} userType user type
         * @public
         */
        self.setUserType = function (userType) {
            self.user = _.clone(initialUserState);
            setUserData();
            getContactInfo();
            self.user.userType = userType.code;
            self.user.userTypeValue = userType.value;
            self.disableNextButton = false;
            self.userExists = true;
            self.userAlreadyRegistered = false;
            if (self.user.userType === "wb" || self.user.userType === "wb-other") {
                self.user.receiveNewMaterialUpdates = true;
            }
        };

        /**
         * cancel registration and redirect to login page
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function cancel
         * @public
         */
        self.cancel = function () {
            var modalInstance = $uibModal.open({
                templateUrl: "confirmationTemplate.html",
                controller: "modalConfirmationController",
                controllerAs: "modalConfirmationCtrl",
                keyboard: false,
                backdrop: "static",
                size: "md",
                resolve: {
                    message: function () {
                        return self.translations.global.messages.cancelRegistration;
                    },
                    headerTitle: function () {
                        return self.translations.userInfo.messages.warning;
                    },
                    showAffirmativeBtn: function () {
                        return true;
                    },
                    affirmativeBtnText: function () {
                        return self.translations.userInfo.labels.yesBtn;
                    },
                    cancelBtnText: function () {
                        return self.translations.userInfo.labels.noBtn;
                    }
                }
            });

            modalInstance.result.then(function () {
                $window.onbeforeunload = undefined;
                $window.location.href = "/login";
            });
        };

        /**
         * back to the previous step
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function back
         * @public
         */
        self.back = function () {
            self.additionalExhibitor = false;
            if (self.user.userType === "ex-other" && self.user.isAdditionalAffiliation && self.currentStep === self.REGISTRATION_STEPS.ADDITIONAL_EXHIBITOR_AFFILIATION) {
                self.additionalExhibitor = false;
                self.currentStep = self.REGISTRATION_STEPS.ACCOUNT_INFO;
            } else if (self.user.userType === "ex-other" && self.user.isAdditionalAffiliation === undefined && self.currentStep === self.REGISTRATION_STEPS.PASSWORD) {
                self.currentStep = self.REGISTRATION_STEPS.ACCOUNT_INFO;
            } else if (self.user.userType === "ex-corporate" && !self.user.isAdditionalAffiliation && self.currentStep === self.REGISTRATION_STEPS.PASSWORD) {
                self.currentStep = self.REGISTRATION_STEPS.EXHIBITOR_AFFILIATION;
            } else if ((self.user.userType === "ex-corporate" || self.user.userType === "ex-other") && self.currentStep === self.REGISTRATION_STEPS.PASSWORD && self.user.isAdditionalAffiliation) {
                self.additionalExhibitor = true;
                self.currentStep = self.REGISTRATION_STEPS.ADDITIONAL_EXHIBITOR_AFFILIATION;
            } else if (self.user.userType === "ex-corporate" && self.currentStep === self.REGISTRATION_STEPS.EXHIBITOR_AFFILIATION) {
                self.additionalExhibitor = false;
                self.currentStep = self.REGISTRATION_STEPS.ACCOUNT_INFO;
            } else if (self.user.userType === "ex-theater" && self.currentStep === self.REGISTRATION_STEPS.PASSWORD) {
                self.currentStep = self.REGISTRATION_STEPS.THEATER_AFFILIATION;
            } else if (self.user.userType === "ex-theater" && self.currentStep === self.REGISTRATION_STEPS.THEATER_AFFILIATION) {
                self.currentStep = self.REGISTRATION_STEPS.ACCOUNT_INFO;
            } else if (self.currentStep === self.REGISTRATION_STEPS.ACCOUNT_INFO) { 
                self.subStep = 3;
                self.currentStep--;
            } else if (self.currentStep === self.REGISTRATION_STEPS.LANGUAGE_USER_TYPE) {
                self.subStep--;
            } else {
                self.currentStep--;
            }
        };

        /**
         * proceed to the next step
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function next
         * @public
         */
        self.next = function () {
            var incrementStep = function (isFormValid) {
                self.additionalExhibitor = false;
                if (isFormValid) {
                    if (self.currentStep === self.REGISTRATION_STEPS.LANGUAGE_USER_TYPE && self.user.regionId 
                        && self.user.localeId && self.user.userType) {
                        self.currentStep++;
                    } else if (self.user.userType === "ex-other" && self.user.isAdditionalAffiliation && self.currentStep === self.REGISTRATION_STEPS.ACCOUNT_INFO) {
                        self.additionalExhibitor = true;
                        self.currentStep = self.REGISTRATION_STEPS.ADDITIONAL_EXHIBITOR_AFFILIATION;
                    } else if (self.user.userType != "ex-corporate" && self.user.userType != "ex-theater"
                        && self.currentStep === self.REGISTRATION_STEPS.ACCOUNT_INFO) {
                        self.currentStep = self.REGISTRATION_STEPS.PASSWORD;
                    } else if (self.user.userType === "ex-corporate" && self.currentStep === self.REGISTRATION_STEPS.ACCOUNT_INFO) {
                        self.currentStep = self.REGISTRATION_STEPS.EXHIBITOR_AFFILIATION;
                    } else if (self.user.userType === "ex-corporate" && self.user.isAdditionalAffiliation && self.currentStep === self.REGISTRATION_STEPS.EXHIBITOR_AFFILIATION) {
                        self.additionalExhibitor = true;
                        self.currentStep = self.REGISTRATION_STEPS.ADDITIONAL_EXHIBITOR_AFFILIATION;
                    } else if ((self.user.userType === "ex-corporate" || self.user.userType === "ex-other") && self.user.isAdditionalAffiliation && self.additionalAffiliations && self.currentStep === self.REGISTRATION_STEPS.ADDITIONAL_EXHIBITOR_AFFILIATION) {
                        self.additionalExhibitor = false;
                        self.currentStep = self.REGISTRATION_STEPS.PASSWORD;
                    } else if (self.user.userType === "ex-corporate" && self.currentStep === self.REGISTRATION_STEPS.EXHIBITOR_AFFILIATION && !self.user.isAdditionalAffiliation) {
                        self.currentStep = self.REGISTRATION_STEPS.PASSWORD;
                    } else if (self.user.userType === "ex-theater" && self.currentStep === self.REGISTRATION_STEPS.ACCOUNT_INFO) {
                        self.currentStep = self.REGISTRATION_STEPS.THEATER_AFFILIATION;
                    } else if (self.user.userType === "ex-theater" && self.currentStep === self.REGISTRATION_STEPS.THEATER_AFFILIATION) {
                        self.currentStep = self.REGISTRATION_STEPS.PASSWORD;
                    } else {
                        if (self.currentStep >= 3) {
                            self.currentStep++;
                        } else {
                            self.subStep++;
                        }
                    }
                    resetRegistrationForm();
                }
            };

            confirmBeforeExit();
            $rootScope.$emit("hideAlert");

            switch (self.currentStep) {
                case self.REGISTRATION_STEPS.ACCOUNT_INFO:
                    validateAccountInfo();
                    incrementStep(self.registrationForm.$valid);
                    break;
                case self.REGISTRATION_STEPS.PASSWORD:
                    if (self.registrationForm.$valid) {
                        setOtherRegionTerritories();
                        storeTerritoryInfo();
                    }
                    incrementStep(self.registrationForm.$valid);
                    break;
                case self.REGISTRATION_STEPS.EXHIBITOR_AFFILIATION:
                    incrementStep(self.registrationForm.$valid);
                    break;
                case self.REGISTRATION_STEPS.THEATER_AFFILIATION:
                    incrementStep(self.registrationForm.$valid);
                    break;
                case self.REGISTRATION_STEPS.REVIEW:
                    if (self.registrationForm.$valid) {
                        createUser(function() {
                            incrementStep(self.registrationForm.$valid);
                        });
                    }
                    break;
                default:
                    incrementStep(self.registrationForm.$valid);
                    break;
            }
        };

        /**
         * handle
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function handleNext
         * @public
         */
        self.handleNext = function () {
            if (!self.user.regionId && self.currentStep === self.REGISTRATION_STEPS.LANGUAGE_USER_TYPE) {
                self.subStep = 1;
            } else if (self.user.regionId && !self.user.localeId && self.currentStep === self.REGISTRATION_STEPS.LANGUAGE_USER_TYPE) {
                self.subStep = 2;
            } if (self.user.regionId && self.user.localeId && self.currentStep !== self.REGISTRATION_STEPS.REVIEW) {
                self.subStep = 3;
            }
        };

        /**
         * remove theater
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function removeTheater
         * @param {int} theaterId theater id
         * @public
         */
        self.removeTheater = function (theaterId) {
            var selectedIndex = _.findIndex(self.user.selectedTheaters, {
                id: theaterId
            });
            self.user.selectedTheaters.splice(selectedIndex, 1);
            if(!self.user.selectedTheaters.length) {
                self.user.selectedTheaters = "";
            }
        };

        /**
         * removeAdditionalAffiliation
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function removeAdditionalAffiliation
         * @param {int} theaterId theater id
         * @public
         */
        self.removeAdditionalAffiliation = function (theaterId) {
            var res =  additionalAffiliationService.removeAdditionalAffiliation(
                theaterId, self.additionalAffiliations, self.user);
            if (res && res.user) {
                self.additionalAffiliations = res.addlAfflns;
                self.user = res.user;
            }
        };

        /**
         * removeAdditionalAffiliationExOther
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function removeAdditionalAffiliationExOther
         * @param {int} theaterId theater id
         * @public
         */
        self.removeAdditionalAffiliationExOther = function (theaterId) {
            var res =  additionalAffiliationService.removeAdditionalAffiliationExOther(
                theaterId, self.additionalAffiliations, self.user, self.additionalAffiliation);
            if (res && res.user) {
                self.additionalAffiliation = res.additionalAffiliation;
                self.additionalAffiliations = res.addlAfflns;
                self.user = res.user;
            }
        };
        /**
         * getSelectedAffiliationId
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function getSelectedAffiliationId
         * @param {obj} exhibitor exhibitor
         * @public
         */
        self.getSelectedAffiliationId = function (exhibitor) {
            return additionalAffiliationService.getSelectedAffiliationId(exhibitor);
        };
        
        /**
         * Remove theater when the exhibitor changes
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function removeBulkTheater
         * @public
         */
        self.removeBulkTheater = function(){
            if(self.user.selectedTheaters.length) {
                self.user.selectedTheaters = "";
            }
        };

        /**
         * set additional exhibitors
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function setAdditionalExhibitors
         * @public
         */
        self.setAdditionalExhibitors = function(selectedItem){
            var result =  additionalAffiliationService.setAdditionalExhibitors(selectedItem,self.additionalAffiliation, self.user, self.additionalAffiliations, self.isExistingUser);
            setResultValues(result);
        };

        /**
         * set additional exhibitors for Ex-Other
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function setAdditionalExhibitorsExOther
         * @public
         */
        self.setAdditionalExhibitorsExOther = function(selectedItem, isAddedOrRemoved){
            var origAddlAffln= _.cloneDeep(self.additionalAffiliation);
            var result =  additionalAffiliationService.setAdditionalExhibitors(selectedItem,self.additionalAffiliation, self.user, self.additionalAffiliations, self.isExistingUser, isAddedOrRemoved);
            setResultValuesExOther(result, origAddlAffln);            
        };

        /**
         * set result values for Ex-Other
         * @function setResultValuesExOther
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @public
         */
        var setResultValuesExOther = function (result, origAddlAffln) {
            self.additionalAffiliation = !result.additionalAffiliation ? origAddlAffln : result.additionalAffiliation;
            self.user = result.user;
            self.additionalAffiliations = result.additionalAffiliations;
        };
        /**
         * get exhibitors
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function getExhibitors
         * @param {string} keyword search term
         * @public
         */
        self.getExhibitors = function (keyword) {
            return registrationService.getExhibitors(keyword, self.user.territoryId);
        };

        /**
         * get getAdditional Exhibitors
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function getExhibitors
         * @param {string} keyword search term
         * @public
         */
        self.getAdditionalExhibitors = function (keyword) {
            return registrationService.getExhibitors(keyword, self.additionalAffiliation.territoryId);
        };

        /**
         * submit the form and create the user
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function createUser
         * @private
         * @param {function} successCallback success callback function
         */
        var createUser = function (successCallback) {
            registrationInfo = _.cloneDeep(self.user);
            finalizeRegistrationInfo();

            self.user.loginName = self.user.loginName.toLowerCase();
            self.user.email = self.user.email.toLowerCase();
            delete self.user.selectedTheaters;
            delete self.user.exhibitorObj;
            delete self.user.confirmPassword;
            delete self.user.departmentObj;
            delete self.user.divisionObj;
            delete self.user.phone;
            delete self.user.titleObj;
            delete self.user.userTypeValue;
            if (self.agreed) {
                self.user.termsAccepted = true;
                self.user.privPolicyAccepted = true;
            }
            if (!_.isEmpty(self.additionalAffiliations)) {
                delete self.additionalAffiliation;
                delete self.additionalAffiliations;
            }
            registrationService.createUser(self.user).success(function () {
                //Need to unregister the onbeforeunload event after creating a user successfully.
                $window.onbeforeunload = undefined;
                successCallback();
            }).error(function () {
                self.user = _.cloneDeep(registrationInfo);
            });
        };

        /**
         * Open lookup theater modal
         * @function openLookupTheaterModal
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         */
        self.openLookupTheaterModal = function () {
            var modalInstance = $uibModal.open({
                templateUrl: "/modules/lookupTheaterModal/modalLookupTheater.html",
                controller: "modalLookupTheaterController",
                controllerAs: "lookupTheaterCtrl",
                keyboard: false,
                backdrop: "static",
                size: "lg",
                resolve: {
                    exhibitor: function () {
                        return self.user.isAdditionalAffiliation ? self.additionalAffiliation.exhibitorObj : self.user.exhibitorObj;
                    },
                    territoryId: function () {
                        return self.user.isAdditionalAffiliation ? self.additionalAffiliation.territoryId : self.user.territoryId;
                    },
                    headerText: function () {
                        return self.user.userType === "ex-corporate" ? self.translations.registration.labels.exhibitorLookup : self.translations.registration.labels.theaterLookup;
                    },
                    isExCorporate: function () {
                        return self.user.userType === "ex-corporate";
                    },
                    isExOther: function () {
                        return self.user.userType === "ex-other";
                    },
                    selectedTheaters: function () {
                        return self.user.isAdditionalAffiliation ? self.additionalAffiliation.selectedTheaters : self.user.selectedTheaters;
                    }
                }
            });

            modalInstance.result.then(function (selectedTheaters) {
                if (self.user.isAdditionalAffiliation) {
                    if (self.user.userType === "ex-theater") {
                        self.additionalAffiliation.selectedTheaters = selectedTheaters;
                        self.disableNextButton = false;
                    } else if (self.user.userType === "ex-corporate") {
                        self.setAdditionalExhibitors(selectedTheaters[0]);
                    }
                    else if(self.user.userType === "ex-other") {
                        self.setAdditionalExhibitorsExOther(selectedTheaters[0], "added");
                    }
                } else {
                    if (self.user.userType === "ex-theater") {
                        self.user.selectedTheaters = selectedTheaters;
                        self.disableNextButton = false;
                    } else if (self.user.userType === "ex-corporate") {
                        self.user.theater = selectedTheaters[0];
                        self.user.exhibitorId = selectedTheaters[0].exhibitorId;
                    }
                }
            });
        };

        /**
         * set result value
         * @function setResultValues
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @public
         */
        var setResultValues = function (result) {
            self.additionalAffiliation = result.additionalAffiliation;
            self.user = result.user;
            self.additionalAffiliations = result.additionalAffiliations;
        };

        /**
         * Init
         * @function init
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @public
         */
        self.init = function () {
            setUserData();
            self.activeRegions =  locationService.getActiveRegions(self.translations.values.regions);
            helpContactService.getHelpContactEmail().then(function (contactEmail) {
                self.contactEmail = contactEmail;
            });
        };

         /**
         * Exhibitor/Company Not Found Selected.
         * @function companyNotSelected
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @public
         */
        self.companyNotSelected = function () {
            self.user.exhibitorObj = null;
            self.user.theater = null;
            self.user.selectedTheaters = null ;
            self.user.exhibitorId = null;
        };

        /**
         * Additional Exhibitor/Company Not Found Selected.
         * @function additionalCompanyNotSelected
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @public
         */
        self.additionalCompanyNotSelected = function () {
            self.additionalAffiliation =  additionalAffiliationService.additionalCompanyNotSelected(self.additionalAffiliation, self.user.userType);
        };
        
        /**
         * Additional Affiliations Not Selected.
         * @function additionalAffiliationNotSelected
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @public
         */
        self.additionalAffiliationNotSelected = function () {
            self.additionalAffiliations = null;
            self.additionalAffiliation = null;
        };

        $rootScope.$on("localizationIsUpdated", function () {
            self.translations = localStorageService.get("translations");
        });
        /**
         * isInternalAccount
         * @function isInternalAccount
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @public
         */
        self.isInternalAccount = function () {
            if (self.user.accountType === "INTERNAL") {
                return true;
            }
            return false;
        };

        $rootScope.$on("localizationIsUpdated", function () {
            self.translations = localStorageService.get("translations");
        });

         /**
         * User Account Type eventListener
         * public
         * @param {object} event javascript event
         * @param {bool} isBulkAction
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         */
        $scope.$on("accountTypeUpdate", function (event, data) {
            self.user.accountType = data.accountType;
            self.user.existsInOkta = data.existsInOkta;
        });

        /**
         * privacy policy
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @private
         * @function privacyPolicy
         */
        self.privacyPolicy = function () {
            privacyPolicyService.getWMPrivacyPolicyUrl(localStorageService.get("location"));
        };

        //INIT
        self.init();
    }

    controllers.controller("registrationController", ["$scope", "$rootScope", "$window", "$uibModal", "localStorageService", "registrationService", "additionalAffiliationService", "userService", "STATE_LIST",
        "convertLocalizationCodeToValueService", "emailService", "locationService", "helpContactService", "territoriesService","privacyPolicyService", "DEFAULT_CONTACT_US_EMAIL", registrationController
    ]);
}());
