(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * User manage controller
     * @dec The user management controller
     * @class manageUserController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.$rootScope} $rootScope angular $rootScope
     * @param {service} localStorageService local storage service
     * @param {$uibModal} $uibModal uib modal
     * @param {service} userService user service
     * @param {service} passwordService user service
     * @param {service} exhibitorsService exhibitor service
     * @param {service} additionalAffiliationService additionalAffiliationservice
     * @param {service} theatersService theater service
     * @param {service} convertLocalizationCodeToValueService convert localization code to value
     * @param {service} emailService email service
     * @param {constant} STATE_LIST STATE_LIST
     * @param {service} $cookies $cookies service
     * @param {constant} SECURE_COOKIES SECURE_COOKIES
     * @param {constant} OAUTH_SESSION_LIFETIME oauth session lifetime
     * @param {constant} DOMAIN DOMAIN
     * @param {$window} $window $window instance
     * 
     */
    function manageUserController($rootScope, localStorageService, $uibModal, userService, passwordService, exhibitorsService, theatersService, convertLocalizationCodeToValueService, additionalAffiliationService, emailService, locationService, translateService, STATE_LIST, $cookies, SECURE_COOKIES, OAUTH_SESSION_LIFETIME, DOMAIN, $window) {

        /**
         * Instance of manageUserController
         * @type {manageUserController}
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        var self = this;

        /**
         * Copy of user object
         * Current variable is public because of unit test 
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        self.originalUserInfo = null;

        /**
         * Locales object
         * Current variable made public for unit tests
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        self.locales = localStorageService.get("liveLocales");

        /**
         * Current user object
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        self.currentUser = localStorageService.get("userInfo");

        /**
         * impersonate user flag
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");

        /**
         * User profile format
         * @type {angular.Form}
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         */
        self.userProfileForm = null;

        /**
         * List of the states for Canada and US 
         * @type {constant}
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         */
        self.stateList = STATE_LIST;

        /**
         * USA country code constant
         * @type {constant}
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         */
        self.USA = "176";

        /**
         * CANADA country code constant
         * @type {constant}
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         */
        self.CANADA = "177";

        /**
         * HONG_KONG country code constant
         * @type {constant}
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         */
        self.HONG_KONG = "131";

        /**
         * JAPAN country code constant
         * @type {constant}
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         */
        self.JAPAN = "105";

        /**
         * KOREA country code constant
         * @type {constant}
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         */
        self.KOREA = "130";

        /**
         * TAIWAN country code constant
         * @type {constant}
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         */
        self.TAIWAN = "134";

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        self.pageHeaderItem = {
            pageTitle: "",
            breadcrumbItems: [{
                linkTitle: "",
                link: "/manage-users"
            }, {
                linkTitle: "",
                link: ""
            }]
        };

        /**
         * The user
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @type {object[]}
         */
        self.user = [];

        /**
         * The translations
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        self.translations = localStorageService.get("translations");

        /**
         * Is edit mode flag
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @type {boolean}
         */
        self.isEditMode = window.WB.cinehub.preload.isEditMode === "1" ? true : false;
        
        /**
         * Exhibitor service
         * @type {service}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        self.exhibitorsService = exhibitorsService;

        /**
         * Theater service
         * @type {service}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        self.theatersService = theatersService;

        /**
         * Conver localization code to value
         * @type {service}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        self.convertLocalizationCodeToValueService = convertLocalizationCodeToValueService;

        /**
         * Exhibitor Relationship
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        self.exhibitorRelationship = [];
        self.additionalExhibitorsCurrentUser = [];
        self.additionalAffiliation = [];
        self.userAffiliations = [];
        /**
         * Theater Affiliations
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        self.theaterAffiliations = [];

        /**
         * Additional countries
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        self.additionalCountries = [];

        /**
         * email format regex
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @type {regex}
         */
        self.emailFormat = emailService.getEmailFormatRegex();

        /**
         * table header for additional affiliations
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @type {regex}
         */
        self.headers= additionalAffiliationService.headers();

        /**
         * territory Dropdown list for Admins
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @type {regex}
         */
        self.territories = self.translations && self.translations.values && !_.isEmpty(self.translations.values.territories)
            ? self.translations.values.territories : [];

        /**
         * roles Dropdown list for Admins
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @type {regex}
         */
        self.roles = self.translations && self.translations.values && !_.isEmpty(self.translations.values.roles)
        ? self.translations.values.roles : [];    

        /**
         * Convert additional country code tags input object
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @function convertAdditionalCountryCodeIntoTagsInputObj
         */
        var convertAdditionalCountryCodeIntoTagsInputObj = function () {
            var additionalCountries = JSON.parse(self.user.additionalCountries),
                index = 0,
                additionalCountriesLength = 0;
            if (additionalCountries) {
                additionalCountriesLength = additionalCountries.length;
                self.additionalCountries = [];
                for (index; index < additionalCountriesLength; index++) {
                    self.additionalCountries.push(_.find(self.translations.values.allTerritories, {
                        code: additionalCountries[index].toString()
                    }));
                }
            }
        };

        /**
         * Conver additional country tags input obj into array codes
         * @function convertAdditionalCountryTagsInputObjIntoArrayCodes
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @private
         */
        var convertAdditionalCountryTagsInputObjIntoArrayCodes = function () {
            var additionalCountriesLength = 0,
                index = 0,
                additionalCountries = [];
            if (self.additionalCountries) {
                additionalCountriesLength = self.additionalCountries.length;
                if (additionalCountriesLength > 0) {
                    for (index; index < additionalCountriesLength; index++) {
                        additionalCountries.push(self.additionalCountries[index].code);
                    }
                    self.user.additionalCountries = JSON.stringify(additionalCountries);
                } else {
                    self.user.additionalCountries = "[]";
                }
            }
        };

        /**
         * check if its isNonEnglishTerritory
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function isNonEnglishTerritory
         * @private
         */
        self.isNonEnglishTerritory = function () {
            return locationService.isNonEnglishTerritory(self.user);
        };
        /**
         * Get Filtered Exhibitors
         * @function getFilteredExhibitors
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        self.getFilteredExhibitors = function(query) {
            return self.exhibitorsService
                .findByTerritoryIdAndByNameLike(self.currentUser.territoryId, query)
                .then(function(exhibitors) {
                    // Filter out already selected exhibitors
                    return exhibitors.filter(function(exhibitor) {
                        return !self.additionalExhibitorsCurrentUser.some(function(selected) {
                            return selected.exhibitorId === exhibitor.id.toString();  // Ensure ID is unique
                        });
                    });
                });
        };

        /**
         * set additional exhibitors for Ex-Other
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function setAdditionalExhibitorsExOther
         * @public
         */
        self.setAdditionalExhibitors = function(selectedItem, isAddedOrRemoved){
            var addExbObj = {};
            addExbObj.exhibitorId = selectedItem && selectedItem.exhibitorId ? selectedItem.exhibitorId : selectedItem.id;
            selectedItem.localName = selectedItem && selectedItem.exhibitorId ? selectedItem.exhibitorName : selectedItem.localName;
            addExbObj.regionId = self.currentUser.regionId;
            addExbObj.territoryId = selectedItem.territoryId;
            addExbObj.territory = selectedItem.territoryName ? selectedItem.territoryName : self.convertLocalizationCodeToValueService.convert("allTerritories", selectedItem.territoryId);
            addExbObj.localName = selectedItem.localName + " (" + addExbObj.territory + ")";
            addExbObj.localeId = self.currentUser.localeId;
            addExbObj.isAddedOrRemoved = isAddedOrRemoved;
            self.additionalAffiliation.push(addExbObj);
        };
        /**
         * Get user by email
         * @function getUserByUserId
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        var getUserByUserId = function () {
            userService.getUserByUserId(window.WB.cinehub.preload.userId).success(function (response) {
                self.user = response[0];
                self.exhibitorRelationship = JSON.parse(self.user.exhibitorRelationship);
                self.theaterAffiliations = JSON.parse(self.user.theaterAffiliations);
                self.isAdditionalAffiliation =  additionalAffiliationService.isAdditionalAffiliation(self.user);
                self.isPrimaryAdmin = (self.currentUser.role === "homeOfficeAdmin") || (self.user.territoryId === self.currentUser.territoryId) ? true : false;
                self.user.userMigrationLink = window.location.origin + "/register-profile?userId=" + self.user.userId + "&emailVerificationToken=" + self.user.emailVerificationToken;
                
                //commented Okta Migration
                /*if (!self.user.oktaMigrationFlag && self.user.resetPasswordFlag) {
                    self.user.resetPasswordLink = window.location.origin + "/reset-password?userId=" + self.user.userId +"&resetPasswordToken=" + self.user.resetPasswordToken;
                }*/
                self.user.addUserLink = window.location.origin + "/admin-registered-profile?userId=" + self.user.userId + "&emailVerificationToken=" + self.user.emailVerificationToken;
                self.originalUserInfo = _.cloneDeep(self.user);
                self.pageHeaderItem.breadcrumbItems[1].linkTitle = self.user.firstName + " " + self.user.lastName;
                self.filterDepartmentsByUserType();
                processRoles();
                if (self.user.oktaStatus && self.user.oktaStatus.toLowerCase() === "locked_out") {
                    self.user.oktaStatus = "locked";
                }
                convertAdditionalCountryCodeIntoTagsInputObj();
                filterRegisteredTerritory();
                if ((self.user.userType === "ex-corporate" || self.user.userType === "ex-other") && self.isAdditionalAffiliation) {
                    var processedData =  additionalAffiliationService.processAdditionalExhibitors(self.user, self.currentUser, self.isPrimaryAdmin);
                    self.additionalExhibitors = processedData.additionalExhibitors;
                    self.primaryExhibitor = processedData.primaryExhibitor;
                    self.currentExhibitor = processedData.currentExhibitor;  
                    self.additionalExhibitorsCurrentUser = processedData.additionalExhibitorsCurrentUser;
                }            
            });
        };

        /**
         * enable/disable roles based on user type
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @function filterRegisteredTerritory
         * @public
         */
        var processRoles = function () {
            var roles = self.roles;
            if (self.user.userType !== self.originalUserInfo.userType) {
                self.user.role = "";
            } else {
                self.user.role = self.originalUserInfo.role;
            }
            roles.forEach(function (role) {
                if ( self.user.userType && self.user.userType === "wb" || self.user.userType === "wb-other") {
                    role["show"] = true;
                } else {
                    role.code && role.code.includes("standard")
                    ? role["show"] = true : role["show"] = false;
                }
            });
            self.roles = roles;
        };
        /**
         * remove selected registered territory from lang drop-down.
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @function filterRegisteredTerritory
         * @public
         */
        var filterRegisteredTerritory = function () {
            var additionalCountries = JSON.parse(self.user.additionalCountries);
            var userAffiliations = self.user.userAffiliations;
            if (!_.isEmpty(userAffiliations)) {
                _.remove(self.territories , function (territory) {
                    return _.findIndex(userAffiliations, function (userAffiliation) {
                        return territory.code === userAffiliation.territoryId
                            && self.user.territoryId !== userAffiliation.territoryId;
                    }) >= 0;
                });
            }
            if (!_.isEmpty(additionalCountries)) {
                _.remove(self.territories , function (territory) {
                    return _.findIndex(self.additionalCountries, function (country) {
                        return territory.code === country.code;
                    }) >= 0;
                });
            }
        };
        /**
         * Update user info
         * @function updateUser
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @private
         */
        var updateUser = function () {
            convertAdditionalCountryTagsInputObjIntoArrayCodes();
            self.user.exhibitorRelationship = self.exhibitorRelationship ? JSON.stringify(self.exhibitorRelationship) : "";
            self.user.theaterAffiliations = self.theaterAffiliations ? JSON.stringify(self.theaterAffiliations) : "";
            if(self.user.userType === "ex-other" && self.additionalAffiliation && self.additionalAffiliation.length > 0) {
                self.userAffiliations = !_.isEmpty(self.user.userAffiliations) ?  _.cloneDeep(self.user.userAffiliations) : [];
                self.additionalAffiliation.forEach(function(additionalAff) {
                    if(additionalAff.isAddedOrRemoved === "added") {
                        delete additionalAff.isAddedOrRemoved;
                        self.userAffiliations.push(additionalAff);
                    } else if (self.userAffiliations) {                            
                        _.remove(self.userAffiliations, function (affil) {
                            return affil.exhibitorId == additionalAff.exhibitorId;
                        });            
                    }                                      
                });
                self.user.userAffiliations = self.userAffiliations;
            }
            
            if (self.user.userType !== self.originalUserInfo.userType) {
                self.user.addlTerrStatusList = [];
            }
            self.user.lastModified = moment().utc().format();
            self.user.loginName = self.user.email;
            if (self.originalUserInfo.territoryId !== self.user.territoryId) {
                self.user.localeId = _.find(self.locales, {
                    territoryId: self.user.territoryId
                }).id;
                self.user.regionId = _.find(self.locales, {
                    territoryId: self.user.territoryId
                }).regionId;
            } else  {
                self.user.localeId = self.originalUserInfo.localeId;
                self.user.regionId = self.originalUserInfo.regionId;
            }
            return userService.updateUser(self.user, window.WB.cinehub.preload.userId).success(function () {
                convertAdditionalCountryCodeIntoTagsInputObj();

                if (self.currentUser.userId === window.WB.cinehub.preload.userId) {
                    localStorageService.set("userInfo", self.user);
                }

                //refresh the page if additional country got updated
                if (self.originalUserInfo.additionalCountries !== self.user.additionalCountries) {
                    window.location.reload();
                }
                self.originalUserInfo = _.cloneDeep(self.user);
            }).error(function () {
                self.user = _.cloneDeep(self.originalUserInfo);
            });
        };
        
        /**
         * Cancel
         * @function cancel
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @private
         */
        var cancel = function () {
            self.isEditMode = false;
            self.user = _.cloneDeep(self.originalUserInfo);
            self.exhibitorRelationship = JSON.parse(self.user.exhibitorRelationship);
            self.theaterAffiliations = JSON.parse(self.user.theaterAffiliations);
            self.userProfileForm.$setPristine();
            convertAdditionalCountryCodeIntoTagsInputObj();
        };

        /**
         * Set exhibitor
         * @function setExhibitor
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         * @param {object} exhibitor exhibitor
         */
        self.setExhibitor = function (exhibitor) {
            self.user.exhibitorId = exhibitor.id;
            self.user.exhibitorName = exhibitor.name;
        };

        /**
         * Set additional exhibitor
         * @function setAdditionalExhibitor
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         * @param {object} exhibitor exhibitor
         */
        self.setAdditionalExhibitor = function (exhibitor) {
            self.currentExhibitor.exhibitorId = exhibitor.id;
            self.currentExhibitor.exhibitorName = exhibitor.name;
            self.user.userAffiliations.forEach (function (affiliation) {                   
                if (affiliation.territoryId === self.currentUser.territoryId) {
                    affiliation.exhibitorId = self.currentExhibitor.exhibitorId;
                    affiliation.exhibitorName = self.currentExhibitor.exhibitorName;
                }
            });
        };

        /**
         * Update user
         * @function updateUser
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        self.updateUser = function () {
            if (self.userProfileForm.$valid) {
                if ((self.user.userType !== self.originalUserInfo.userType) || (self.user.role !== self.originalUserInfo.role) || (self.user.territoryId !== self.originalUserInfo.territoryId)) {
                    updateConfirmationModel();
                } else {
                    updateUser().then(function () {
                        self.isEditMode = false;
                    });
                }

            }
        };

        /**
         * Primary territory changed
         * @function primaryTerritoryChanged
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        self.primaryTerritoryChanged = function () {
            var index = _.findIndex(self.additionalCountries, {
                code: self.user.territoryId
            });
            if (index !== -1) {
                self.additionalCountries.splice(index, 1);
            }
            if (self.user.exhibitorName) {
                self.user.exhibitorName = "";
                angular.element("#exhibitor").focus();
            }
            if (self.exhibitorRelationship) {
                self.exhibitorRelationship = "";
            }
            if (self.theaterAffiliations) {
                self.theaterAffiliations = self.user.exhibitorName ? self.theaterAffiliations : "";
            }
        };

        /**
         * save confirmation  model
         * @function cancel
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */  
        var updateConfirmationModel = function () {
            var modalInstance = $uibModal.open({
                templateUrl: "confirmationTemplate.html",
                controller: "modalConfirmationController",
                controllerAs: "modalConfirmationCtrl",
                keyboard: false,
                backdrop: "static",
                size: "md",
                resolve: {
                    message: function () {
                        return self.translations.userInfo.messages.updateUserWarning;
                    },
                    headerTitle: function () {
                        return self.translations.userInfo.messages.warning;
                    },
                    showAffirmativeBtn: function () {
                        return true;
                    },
                    affirmativeBtnText: function () {
                        return self.translations.userInfo.labels.yesBtn;
                    },
                    cancelBtnText: function () {
                        return self.translations.userInfo.labels.noBtn;
                    }
                }
            });

            modalInstance.result.then(function () {
                updateUser().then(function () {
                    self.isEditMode = false;
                });
            });
        };
        

        /**
         * Cnacel edit user info
         * @function cancel
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         */
        self.cancel = function () {
            if (self.userProfileForm.$dirty) {
                var modalInstance = $uibModal.open({
                    templateUrl: "confirmationTemplate.html",
                    controller: "modalConfirmationController",
                    controllerAs: "modalConfirmationCtrl",
                    keyboard: false,
                    backdrop: "static",
                    size: "md",
                    resolve: {
                        message: function () {
                            return self.translations.userInfo.messages.cancelationWarning;
                        },
                        headerTitle: function () {
                            return self.translations.userInfo.messages.warning;
                        },
                        showAffirmativeBtn: function () {
                            return true;
                        },
                        affirmativeBtnText: function () {
                            return self.translations.userInfo.labels.yesBtn;
                        },
                        cancelBtnText: function () {
                            return self.translations.userInfo.labels.noBtn;
                        }
                    }
                });

                modalInstance.result.then(function () {
                    cancel();
                });
            } else {
                cancel();
            }
        };

        /**
         * Activate user
         * @function activateUser
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         */
        self.updateUserOktaStatus = function () {
            userService.updateUserOktaStatus(self.user).success(function (result) {
                if (result.status === "success") {
                    $rootScope.$broadcast("showAlert", "success", result.message, "alert");
                    self.user.oktaStatus = result.data.oktaStatus;
                    self.originalUserInfo.oktaStatus = result.data.oktaStatus;
                }
                if (result.status === "failure") {
                    $rootScope.$broadcast("showAlert", "danger", result.message, "anonymousUserAlert");
                }
                //window.location.reload();
            }).error(function () {
                self.user = _.cloneDeep(self.originalUserInfo);
            });
        };

        
        /**
         * Impersonate Token
         * @function impersonateToken
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         */
        self.getImpersonateToken = function () {
            userService.impersonateToken(self.user).success(function (result) {
                if (result.status === "success") {
                    localStorageService.set("originalUserInfo", localStorageService.get("userInfo"));
                    localStorageService.set("userInfo", result.data.userInfo);
                    if (result.data.userInfo.localeId !== localStorageService.get("location").localeId) {
                        var switchedLocale = _.find(localStorageService.get("liveLocales"), {id : result.data.userInfo.localeId});
                        var locale = switchedLocale && switchedLocale.abbreviation ? switchedLocale.abbreviation : "en-US";
                        localStorageService.set("origTranslations", localStorageService.get("translations"));
                        translateService.loadTranslations(locale).success(function (translations) {
                            localStorageService.set("translations", translations);
                            localStorageService.set("origLocation", localStorageService.get("location"));
                            localStorageService.set("location", null);
                            locationService.setDefaultLocale();
                            self.setImpersonateFields(result);
                        });
                    } else {
                        self.setImpersonateFields(result);
                    }

                }
            }).error(function () {
                self.user = _.cloneDeep(self.originalUserInfo);
            });
        };

        /**
         * set Impersonate Fields
         * @function setImpersonateFields
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         */
        self.setImpersonateFields = function (result) {
            switch (result.data.userInfo.userType) {
                case "ex-corporate":
                    localStorageService.set("currentExhibitorAffiliation", result.data.userInfo.exhibitorId);
                    break;
                case "ex-other":
                    var exhibitorRelationship = JSON.parse(result.data.userInfo.exhibitorRelationship);
                    localStorageService.set("currentExhibitorAffiliation", exhibitorRelationship[0].id);
                    break;
                case "ex-theater":
                    var theaterAffiliations = JSON.parse(result.data.userInfo.theaterAffiliations);
                    localStorageService.set("currentExhibitorAffiliation", theaterAffiliations[0].exhibitorId);
                    localStorageService.set("currentTheaterAffiliation", theaterAffiliations[0].id);
                    break;
            }
            $cookies.put("accessToken", result.data.auth, {
                sameSite: "strict",
                secure: SECURE_COOKIES,
                expires: OAUTH_SESSION_LIFETIME,
                domain: DOMAIN,
                path: "/"
            });
            localStorageService.set("impersonateUserFlag", true);
            $window.location.href = "/home";

        };


        /**
         * Deactivate user
         * @function deactivateUser
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         */
        self.deactivateUser = function () {
            var modalInstance = $uibModal.open({
                templateUrl: "confirmationTemplate.html",
                controller: "modalConfirmationController",
                controllerAs: "modalConfirmationCtrl",
                keyboard: false,
                backdrop: "static",
                size: "md",
                resolve: {
                    message: function () {
                        var deactivateMsg = self.translations.userInfo.messages.deactivateUserConfirmationQuestion
                            ? self.translations.userInfo.messages.deactivateUserConfirmationQuestion.replace("?", "") : "";
                        return deactivateMsg + " " + self.user.firstName + " " + self.user.lastName + " ?";
                    },
                    headerTitle: function () {
                        return self.translations.userInfo.labels.deactivateUserConfirmationModalTitle;
                    },
                    showAffirmativeBtn: function () {
                        return true;
                    },
                    affirmativeBtnText: function () {
                        return self.translations.userInfo.labels.yesDeactivateBtn;
                    },
                    cancelBtnText: function () {
                        return self.translations.userInfo.labels.noCancelBtn;
                    }
                }
            });

            modalInstance.result.then(function () {
                self.user.status = "inactive";
                self.user.adminDeactivated = moment().utc().format();
                updateUser();
            });
        };

        /**
         * Reactivate user
         * @function reactivateUser
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         */
        self.reactivateUser = function () {
            self.user.status = "active";
            updateUser();
        };

        /**
         * Reject user
         * @function rejectUser
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         */
        self.rejectUser = function () {
            if(self.isPrimaryAdmin) {
                self.user.status = "rejected";
                self.user.adminRejected = moment().utc().format();
                self.user.rejectedBy = self.currentUser.firstName + " " + self.currentUser.lastName;
            } else if (self.isAdditionalAffiliation && !self.isPrimaryAdmin) {
                self.user.addlTerrStatusList.forEach (function (addlTerrStatus) {                   
                    if (addlTerrStatus.territoryId === self.currentUser.territoryId) {
                        addlTerrStatus.status = "rejected";
                        addlTerrStatus.comments =  self.currentExhibitor.comments;
                        addlTerrStatus.actionDate = moment().utc().format();
                        addlTerrStatus.actionBy = self.currentUser.userId;
                    }
                });
            }
            updateUser();
        };

        /**
         * Approve user
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         * @function approveUser
         */
        self.approveUser = function () {
            if (self.isPrimaryAdmin) {
                self.user.status = "active";
                self.user.adminApproved = moment().utc().format();
                self.user.approverId = self.currentUser.id;
            } else if (self.isAdditionalAffiliation && !self.isPrimaryAdmin) {
                self.user.addlTerrStatusList.forEach (function (addlTerrStatus) {                   
                    if (addlTerrStatus.territoryId === self.currentUser.territoryId) {
                        addlTerrStatus.status = "active";
                        addlTerrStatus.actionDate = moment().utc().format();
                        addlTerrStatus.actionBy = self.currentUser.userId;
                        addlTerrStatus.comments = "";
                    }
                });
            }

            updateUser();
        };

        /**
         * isPrimaryCheck function
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         * @function isPrimaryCheck
         */
        self.isPrimaryCheck = function () {
            return self.isPrimaryAdmin && self.user && self.user.status;
        };

        /**
         * isSecondaryCheck function
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         * @function isSecondaryCheck
         */
        self.isSecondaryCheck = function () {
            return self.isAdditionalAffiliation && !self.isPrimaryAdmin
                && self.user && self.user.status.toLowerCase() === "active"
                && self.currentExhibitor && self.currentExhibitor.status;
        };

        /**
         * Resend email verification
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         * @function resendEmailVerification
         */
        self.resendEnableLink = function () {
            passwordService.forgotPassword(self.user.loginName).success(function (result) {
                if (result.status === "success") {
                    $rootScope.$broadcast("showAlert", "success", self.translations.userInfo.messages.resendResetPwdSuccess, "alert");
                }
            });
        };
        /**
         * Resend email verification
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         * @function resendEmailVerification
         */
        self.resendEmailVerification = function () {
            userService.resendEmailVerification(self.user.loginName).success(function (result) {
                if (result.status === "success") {
                    $rootScope.$broadcast("showAlert", "success", self.translations.userInfo.messages.resendEmailVerificationSuccess, "alert");
                }
            });
        };

        /**
         * Resend account verification email
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         * @function resendUserMigrationEmail
         */
        self.resendUserMigrationEmail = function () {
            userService.resendUserMigrationEmail(self.user.loginName, self.user.emailVerificationToken, self.user.status).success(function (result) {
                if (result.status === "success") {
                    $rootScope.$broadcast("showAlert", "success", self.translations.userInfo.messages.resendUserMigrationEmailSuccess, "alert");
                }
            });
        };

        /**
         * Resend account verification email
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         * @function resendUserMigrationEmail
         */
        self.resendAdminRegisteredUserEmail = function () {
            userService.resendAdminRegisteredUserEmail(self.user.loginName, self.user.emailVerificationToken, self.user.status).success(function (result) {
                if (result.status === "success") {
                    $rootScope.$broadcast("showAlert", "success", self.translations.adminRegisterProfile.messages.addUserEmailMsg, "alert");
                }
            });
        };

        /**
         * Filter departments by user type
         * @function filterDepartmentsByUserType
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @return {(boolean|object[])}
         */
        self.filterDepartmentsByUserType = function () {
            if (self.user.userType) {
                self.departments = _.filter(self.translations.values.departments, {
                    extraCode: self.user.userType
                });
                self.departments = _.sortBy(self.departments, ["value"]);
                self.departments.unshift({
                    id: -1,
                    code: "",
                    value: self.translations.userInfo.labels.selectDepartment,
                    extraCode: self.user.userType
                });
                if (self.user.userType !== self.originalUserInfo.userType && self.user.department) {
                    self.user.department = "";
                } else {
                    self.user.department = self.originalUserInfo.department;
                }
                processRoles();
            } else {
                self.departments = false;
            }
        };

        /**
         * Get additional countries for additional countries tags input element 
         * @function getAdditionalCountries
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         * @param {string} $query the search term 
         * @returns {object[]}
         */
        self.getAdditionalCountries = function ($query) {
            if (/\w/.test($query)) {
                return self.translations.values.territories.filter(function (territory) {
                    var regex = new RegExp("^" + $query, "ig");
                    return territory.code !== self.user.territoryId && regex.test(territory.value);
                });
            } else {
                return [];
            }
        };
        
        /**
         * Reset Theater Affiliations on Exhibitor name change
         * @function resetTheaterAffiliations
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         */
        self.resetTheaterAffiliations = function () {
            if (self.theaterAffiliations) {
                self.theaterAffiliations = self.user.exhibitorName ? self.theaterAffiliations : "";
            }
        };

        /**
         * Init
         * @function init
         * @memberOf angular_module.WB.cinehub.controllers.manageUserController
         * @public
         */
        self.init = function () {
            getUserByUserId();

            self.pageHeaderItem.pageTitle = self.translations.manageUsers.labels.pageTitle;
            self.pageHeaderItem.breadcrumbItems[0].linkTitle = self.translations.manageUsers.labels.breadcrumbLinkTitle;
        };

        //INIT
        self.init();
    }

    controllers.controller("manageUserController", ["$rootScope", "localStorageService", "$uibModal", "userService", "passwordService", "exhibitorsService", "theatersService", "convertLocalizationCodeToValueService", "additionalAffiliationService", "emailService", "locationService", "translateService", "STATE_LIST", "$cookies", "SECURE_COOKIES", "OAUTH_SESSION_LIFETIME", "DOMAIN", "$window", manageUserController]);
}());
