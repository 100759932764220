(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Restrict fields to allow numbers only.
     * @class numbersOnly
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     */
    function numbersOnly() {
        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @param {jqueryElement} element jquery element
         * @param {object} attr attributes
         * @private
         * @memberOf angular_module.WB.cinehub.directives.numbersOnly 
         */
        var link = function (scope, element, attr, ngModelCtrl) {
            function fromUser(text) {
                if (text) {
                    var transformedInput = text.replace(/[^0-9]/g, "");

                    if (transformedInput !== text) {
                        ngModelCtrl.$setViewValue(transformedInput);
                        ngModelCtrl.$render();
                    }
                    return transformedInput;
                }
                return undefined;
            }            
            ngModelCtrl.$parsers.push(fromUser);
        };

        return {
            require: "ngModel",
            link: link
        };
    }

    directives.directive("numbersOnly", [numbersOnly]);
} ());
