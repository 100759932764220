(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");
    
    /**
     * @class antiPiracyService
     * @memberOf angular_module.WB.cinehub.services
     * @desc anti piracy service
     * @param {!angular.$http} $http angular $http service
     * @paarm {strict} API_ROOT API_ROOT constant  
     */
    function antiPiracyService($http, API_ROOT, localStorageService) {
        
        /**
         * translations
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.antiPiracyService
         * @public
         */
        var translations = localStorageService.get("translations");
        
        /**
         * Get anti piracy of use
         * @memberOf angular_module.WB.cinehub.services.antiPiracyService
         * @public
         * @returns {promise}
         */
        this.getAntiPiracy = function (territoryId, localeId) {
            return $http.get(API_ROOT + "antiPiracyInfo/search/findByTerritoryIdAndLocaleId?territoryId="+territoryId+"&localeId="+localeId, { errorMessage: translations.antiPiracy.messages.getAntiPiracyError});
        };

    }

    services.service("antiPiracyService", ["$http", "API_ROOT", "localStorageService", antiPiracyService]);
}());
