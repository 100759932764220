(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Modal edit film controller
     * @dec The modal edit film controller
     * @class modalEditFilmController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {$uibModalInstance} $uibModalInstance modal instance
     * @param {service} filmService film service
     * @param {int} filmId film id
     * @param {int} eisId eis id
     * @param {string} initialTabSelected tab to be selected on init
     * @param {string} initialSubTabSelected sub tab to select
     * @param {service} localStorageService local storage service
     * @param {service} translateService translate service
     * @param {service} activityHistoryService activity history service
     * @param {service} filmRatingService filmRating service
     * @param {constant} API_EFD API_EFD
     * @param {$uibModal} $uibModal $uibModal
     * @param {service} assetCategoryConvertor asset category convertor
     */
    function modalEditFilmController($uibModalInstance, filmService, filmId, eisId, initialTabSelected, initialSubTabSelected, localStorageService, translateService, activityHistoryService, filmRatingService, API_EFD, $uibModal, assetCategoryConvertor) {

        /**
         * Instance of modalEditFilmController
         * @type {modalEditFilmController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        var self = this,

            /**
             * location
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
             */
            location = localStorageService.get("location"),

            /**
             * Alert key
             * @type {string}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
             */
            alertKey = "modalAlert";

        /**
         * translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        self.translations = localStorageService.get("translations");

        /**
         * impersonate user flag
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");

        /**
         * API_EFD
         * @constant
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @private
         * @type {string}
         */
        self.API_EFD = API_EFD;

        /**
         * Submitted flag
         * @type {boolean}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @public
         */
        self.submitted = false;

        /**
         * film details
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @public
         */
        self.filmDetails = {};

        /**
         * original film details
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @public
         */
        self.originalFilmDetails = {};

        /**
         * film source details
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @public
         */
        self.filmSourceDetails = {};

        /**
         * asset category convertor
         * @type {service}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @public
         */
        self.assetCategoryConvertor = assetCategoryConvertor;

        /**
         * index of active tab
         * @type {int}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @public
         */
        self.activeTab = 0;

        /**
         * options for start date
         * @type {array}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @public
         */
        self.releaseDateOptions = {
            showWeeks: false,
            minMode: "day",
            maxDate: null
        };

        /**
         * onesheetUpdated Flag
         * @type {boolean}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @public
         */
        self.onesheetUpdated = false;

        /**
         * tinymce text editor option
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @public
         */
        self.tinymceOptions = {
            inline: false,
            plugins: "advlist autolink code fullscreen image link lists preview table textcolor",
            skin: "lightgray",
            menu: [],
            toolbar1: "undo redo | styleselect | bold italic forecolor | alignleft aligncenter alignright alignjustify",
            toolbar2: "table bullist numlist outdent indent | preview link image fullscreen code",
            theme: "modern",
            min_height: 250,
            relative_urls : false,
            remove_script_host : false,
            trusted: true,
            default_link_target: "_blank",
            target_list: [
                {title: "New window", value: "_blank"}
            ],
            content_css: "/static/css/site.min.css",
            formats: {
                hilitecolor: {
                    inline: "span",
                    classes: "hilitecolor",
                    styles: {
                        backgroundColor: "#ffffff"
                    }
                },
                customformat: {
                    inline: "span",
                    styles: {
                        backgroundColor: "#ffffff"
                    }
                }
            }
        };

        /**
         * tab menus
         * @type {array}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @public
         */
        self.tabs = [{
            heading: self.translations.editFilmModal.labels.oneSheetTab,
            templateUrl: "/modules/editFilmModal/visualAssetTab/visualAssetTab.html",
            propertyName: "oneSheets",
            active: false,
            key: "editFilmModal.oneSheet",
            hasUpdates: false
        }, {
            heading: self.translations.editFilmModal.labels.trailerTab,
            templateUrl: "/modules/editFilmModal/visualAssetTab/visualAssetTab.html",
            propertyName: "trailers",
            active: false,
            key: "editFilmModal.trailer",
            hasUpdates: false
        }, {
            heading: self.translations.editFilmModal.labels.titleInfoTab,
            templateUrl: "/modules/editFilmModal/titleInfoTab/titleInfoTab.html",
            active: false,
            key: "editFilmModal.titleInfo",
            hasUpdates: false
        }, {
            heading: self.translations.filmDetails.labels.detailsTab,
            templateUrl: "/modules/editFilmModal/detailsTab/detailsTab.html",
            active: false,
            key: "editFilmModal.details",
            hasUpdates: false
        }, {
            heading: self.translations.editFilmModal.labels.censorCardTab,
            templateUrl: "/modules/editFilmModal/visualAssetTab/visualAssetTab.html",
            propertyName: "censorCards",
            active: false,
            key: "editFilmModal.censorCards",
            hasUpdates: false
        }, {
            heading: self.translations.filmDetails.labels.strategyTab,
            templateUrl: "/modules/editFilmModal/strategyTab/strategyTab.html",
            propertyName: "strategy",
            active: false,
            key: "editFilmModal.strategy",
            hasUpdates: false
        }, {
            heading: self.translations.filmDetails.labels.antiPiracyTab,
            templateUrl: "/modules/editFilmModal/textEditorTab/textEditorTab.html",
            propertyName: "antiPiracyInfo",
            active: false,
            key: "editFilmModal.antiPiracyInfo",
            hasUpdates: false
        }];

        /**
         * Strategy tabs
         * @type {array}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @public
         */
        self.strategyTabs = [{
            heading: self.translations.filmDetails.labels.trailerPlacementStrategyTab,
            templateUrl: "/modules/editFilmModal/textEditorTab/textEditorTab.html",
            propertyName: "trailerPlcmntRecommendations",
            active: false,
            key: "editFilmModal.strategy.trailerPlacement",
            hasUpdates: false
        }, {
            heading: self.translations.filmDetails.labels.generalStrategyTab,
            templateUrl: "/modules/editFilmModal/textEditorTab/textEditorTab.html",
            propertyName: "generalStrategy",
            active: false,
            key: "editFilmModal.strategy.general",
            hasUpdates: false
        }, {
            heading: self.translations.filmDetails.labels.inTheaterStrategyTab,
            templateUrl: "/modules/editFilmModal/textEditorTab/textEditorTab.html",
            propertyName: "intheaterStrategy",
            active: false,
            key: "editFilmModal.strategy.intheaterStrategy",
            hasUpdates: false
        }, {
            heading: self.translations.filmDetails.labels.onlinePromotionStrategyTab,
            templateUrl: "/modules/editFilmModal/textEditorTab/textEditorTab.html",
            propertyName: "onlinepromotionsStrategy",
            active: false,
            key: "editFilmModal.strategy.onlinepromotionsStrategy",
            hasUpdates: false
        }];

        /**
         * Build data collection
         * @function buildDataCollection
         * @private
         * @param {object} data data
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        var buildDataCollection = function (data) {
            data.genres = window.helpers.checkNull(data.genres);
            data.format = window.helpers.checkNull(data.format);
            data.releasePattern = window.helpers.checkNull(data.releasePattern);
            data.aspectRatio = window.helpers.checkNull(data.aspectRatio);
            data.ratingId = window.helpers.checkNull(data.ratingId);

            data.genreData = data.genres ? translateService.findItemsByProperty(self.translations.values.genres, "code", window.helpers.isJson(data.genres) ? JSON.parse(data.genres) : filmService.splitMultiValFields(data.genres)) : [];
            data.formatData = data.format ? translateService.findItemsByProperty(self.translations.values.formats, "code", window.helpers.isJson(data.format) ? JSON.parse(data.format) : filmService.splitMultiValFields(data.format)) : [];
            data.releasePatternData = data.releasePattern ? translateService.findItemsByProperty(self.translations.values.releasePatterns, "code", window.helpers.isJson(data.releasePattern) ? JSON.parse(data.releasePattern) : filmService.splitMultiValFields(data.releasePattern)) : [];
            data.aspectRatioData = data.aspectRatio ? translateService.findItemsByProperty(self.translations.values.aspectRatios, "code", window.helpers.isJson(data.aspectRatio) ? JSON.parse(data.aspectRatio) : filmService.splitMultiValFields(data.aspectRatio)) : [];
        };

        /**
         * Check new EIS ingest
         * @function checkNewEISIngest
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        var checkNewEisIngest = function () {
            var publishDate = "";

            self.newIngestAfterLastPublish = moment(self.filmSourceDetails.lastModified).isAfter(moment(self.filmDetails.lastModified));
            self.newTitle = moment(self.filmSourceDetails.lastModified).isSame(moment(self.filmDetails.lastModified));

            //publishDate = "2016-11-10%2010:15:25";
            publishDate = moment(self.filmDetails.lastModified).utc().format("YYYY-MM-DD HH:mm:ss");
            activityHistoryService.getEisUpdatedFields("film", filmId, publishDate).success(function (result) {
                //result is an array of column names
                self.eisNewUpdatedFields = result;
                if (self.eisNewUpdatedFields.indexOf("talent_director") > -1 || self.eisNewUpdatedFields.indexOf("talent_writer") > -1 || self.eisNewUpdatedFields.indexOf("talent_producer") > -1 || self.eisNewUpdatedFields.indexOf("talent_cast") > -1 || self.eisNewUpdatedFields.indexOf("talent_execproducer") > -1 || self.eisNewUpdatedFields.indexOf("runtime") > -1 || self.eisNewUpdatedFields.indexOf("genres") > -1 || self.eisNewUpdatedFields.indexOf("release_patterns") > -1 || self.eisNewUpdatedFields.indexOf("aspect_ratios") > -1 || self.eisNewUpdatedFields.indexOf("formats") > -1 || self.eisNewUpdatedFields.indexOf("production_company") > -1 || self.eisNewUpdatedFields.indexOf("other") > -1 || self.eisNewUpdatedFields.indexOf("other_marketing_material") > -1) {
                    self.tabs[3].hasUpdates = true;
                }
                if (self.eisNewUpdatedFields.indexOf("release_date_comment") > -1 || self.eisNewUpdatedFields.indexOf("title") > -1 || self.eisNewUpdatedFields.indexOf("rating") > -1 || self.eisNewUpdatedFields.indexOf("rating_description") > -1 || self.eisNewUpdatedFields.indexOf("synopsis") > -1 || self.eisNewUpdatedFields.indexOf("website") > -1 || self.eisNewUpdatedFields.indexOf("facebook_url") > -1 || self.eisNewUpdatedFields.indexOf("twitter_url") > -1 || self.eisNewUpdatedFields.indexOf("instagram_url") > -1 || self.eisNewUpdatedFields.indexOf("youtube_url") > -1 || self.eisNewUpdatedFields.indexOf("tumblr_url") > -1) {
                    self.tabs[2].hasUpdates = true;
                }

                if (self.eisNewUpdatedFields.indexOf("trailer_plcmnt_recommendations") > -1) {
                    self.tabs[5].hasUpdates = true;
                    self.strategyTabs[0].hasUpdates = true;
                }
            });
        };

        /**
         * Set modal title
         * @function setModalTitle
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        var setModalTitle = function () {
            if (self.newTitle) {
                self.modalTitle = self.translations.editFilmModal.labels.editNewTitle;
            } else if (self.newIngestAfterLastPublish) {
                self.modalTitle = self.translations.editFilmModal.labels.editUpdatedTitle;
            } else {
                self.modalTitle = self.translations.editFilmModal.labels.editTitle;
            }
        };

        /**
         * Get published data
         * @function getPublishedData
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        var getPublishedData = function () {
            filmService.getFilmSourceAndCinehubData(eisId, location.territoryId, location.localeId).success(function (film) {
                var filmObjs = {};
                filmObjs.film = film; 
                return filmRatingService.setFilmRatings(filmObjs).then(function (res) {
                    self.filmDetails = res.filmObjs["film"];
                    if (_.isEmpty(film)) {
                        self.filmDetails = _.cloneDeep(self.filmSourceDetails);
                        delete self.filmDetails.filmId;
                    } else {
                        processRatingData(self.filmDetails);
                    }
                    buildDataCollection(self.filmDetails);
                    self.originalFilmDetails = _.cloneDeep(self.filmDetails);
                    checkNewEisIngest();
                    setModalTitle();
                });
            });
        };

        /**
         * Get source data
         * @function getSourceData
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        var getSourceData = function () {
            self.ratingModel = [];
            var filmObjs = {};
            var ratings = [];
            filmService.getFilmSourceAndCinehubData(eisId, -1, -1, location.territoryId, location.localeId).success(function (film) {
                filmRatingService.setFilmRatings(filmObjs).then(function (res) {
                    ratings = res.ratings;
                    ratings.forEach(function (rating) {
                        rating.groupPrefix && !rating.groupPrefix.includes("1_")
                            ? rating["disabled"] = true : rating["disabled"] = false;
                    });
                    self.ratingOption = ratings;
                });
                self.filmSourceDetails = film;
                buildDataCollection(self.filmSourceDetails);
                getPublishedData();
            });
        };

        /**
         * Process rating data
         * @function processRatingData
         * @privateself.ratingOption
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        var processRatingData = function (data) {
            if (data.ratingId) {
                data.filmRating.forEach(function (fr) {
                    self.ratingModel.push({id : fr.id, label: fr.rating});
                });
                if(isMultiTerritory()) {
                    var primaryRtg = _.find(self.ratingOption, function (opt) {
                        return self.ratingModel.some(function (e) {
                            return e.id === opt.id
                                && opt.groupPrefix
                                && opt.groupPrefix.includes("1_");
                        });
                    });
                    var subTerrRtgs = primaryRtg.filmRatingTerrMapping;
                    if (self.ratingOption) {
                        self.ratingOption.forEach(function (opt) {
                            var validSubRatg = _.find(subTerrRtgs, {subTerrRatingId : opt.id});
                            if (!validSubRatg && !opt.groupPrefix.includes("1_")) {
                                opt.disabled = true;
                            } else {
                                opt.disabled = false;
                            }
                        });
                    }
                }
            }
        };

        /**
         * process rating data before publish
         * @function processRatingDataForPublish
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        var processRatingDataForPublish = function (modelData) {
            if (modelData && !_.isEmpty(modelData) ) {
                if (modelData.length > 1) {
                    var primaryRtg = _.find(self.ratingOption, function (opt) {
                        return modelData.some(function (e) {
                            return e.id === opt.id
                                && opt.groupPrefix
                                && opt.groupPrefix.includes("1_");
                        });
                    });
                    self.filmDetails.rating = primaryRtg.rating;
                    self.filmDetails.ratingId = primaryRtg.id;
                    //determine sub-rating for multi valued territories
                    var secRating = _.find(modelData, function (opt) {
                        return opt.id !== primaryRtg.id;
                    });
                    if (secRating) {
                        var secRatingOpt = _.filter(primaryRtg.filmRatingTerrMapping,
                            {subTerrRatingId: secRating.id});
                        if (secRatingOpt)
                         {
                            self.filmDetails.subRatingId = _.map(secRatingOpt, "subTerrRatingId").join();
                        } else {
                            self.filmDetails.subRatingId = null;
                        }
                    }
                } else {
                    // Single Valued Territories
                    self.filmDetails.rating = modelData[0].label;
                    self.filmDetails.ratingId = modelData[0].id;
                    self.filmDetails.subRatingId = null;
                }
            } else {
                self.filmDetails.rating = null;
                self.filmDetails.ratingId = null;
                self.filmDetails.subRatingId = null;
            }
        };

        /**
         * ratingModelSettings.
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @type {Object}
         */
        self.ratingModelSettings = {
            idProp: "id",
            displayProp: "rating",
            smartButtonMaxItems: 3,
            smartButtonTextConverter: function (itemText, originalItem) {
                return setRatingSmartText(itemText, originalItem);
            },
            showUncheckAll: false,
            showCheckAll: false,
            keyboardControls: true,
            groupByTextProvider: function (groupValue) {
                if (groupValue) {
                    return groupValue.substring(groupValue.indexOf("_") + 1);
                }
            }
        };

        /**
         * setRatingSmartText.
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @param itemText
         * @param originalItem
         */
        var setRatingSmartText = function (itemText, originalItem) {
            return isMultiTerritory() ? itemText + " (" + originalItem.territoryName + ")" : itemText;
        };

        /**
         * setRatingSelLimit.
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         *
         */
        var isMultiTerritory = function () {
            var isMultiTerr = !_.isEmpty(self.ratingOption)
                && _.findLast(self.ratingOption, function(r) {
                    return r && r.groupPrefix;
                });
            return isMultiTerr ? true : false;
        };

        /**
         * ratingModelEvents.
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @type {Object}
         */
        self.ratingModelEvents = {
            onItemSelect: function(item) {
                var selectedRtg = _.find(self.ratingOption, {id : item.id});
                if (selectedRtg && selectedRtg.groupPrefix
                    && selectedRtg.groupPrefix.includes("1_")) {
                    self.ratingModel.splice(0, self.ratingModel.length);
                    self.ratingModel.push({id : selectedRtg.id, label: selectedRtg.rating});
                    if(isMultiTerritory()){
                        var subTerrRtgs = selectedRtg.filmRatingTerrMapping;
                        self.ratingOption.forEach(function (opt) {
                            var validSubRatg = _.find(subTerrRtgs, {subTerrRatingId : opt.id});
                            if (!validSubRatg && !opt.groupPrefix.includes("1_")) {
                                opt.disabled = true;
                            } else {
                                opt.disabled = false;
                            }
                        });
                        var subTerrOpt = _.find(subTerrRtgs, {isDefault : "Y"});
                        if (subTerrOpt) {
                            var ratingLabel = _.find(self.ratingOption, {id : subTerrOpt.subTerrRatingId});
                            if (ratingLabel && ratingLabel.id) {
                                self.ratingModel.push({id : ratingLabel.id, label: ratingLabel.rating});
                            }
                        }
                    }
                }
                else if (isMultiTerritory() && selectedRtg && selectedRtg.groupPrefix
                    && !selectedRtg.groupPrefix.includes("1_")) {
                    _.remove(self.ratingModel, function(r) {
                        var rtg = _.find(self.ratingOption, {id : r.id});
                        if(!rtg.groupPrefix.includes("1_") && rtg.territoryId === selectedRtg.territoryId){
                            return  r.id ;
                        }
                    });
                    self.ratingModel.push({id : selectedRtg.id, label: selectedRtg.rating});
                } else if (!isMultiTerritory()) {
                    //single valued Territory
                    if (selectedRtg && selectedRtg.id) {
                        self.ratingModel.splice(0, self.ratingModel.length);
                        self.ratingModel.push({id : selectedRtg.id, label: selectedRtg.rating});
                    }
                }
            },
            onItemDeselect: function(item) {
                var deSelectedRtg = _.find(self.ratingOption, {id : item.id});
                if (deSelectedRtg && deSelectedRtg.groupPrefix
                    && deSelectedRtg.groupPrefix.includes("1_")) {
                    self.ratingOption.forEach(function (opt) {
                        if (!opt.groupPrefix.includes("1_")) {
                            opt.disabled = true;
                            _.remove(self.ratingModel, function(r) {
                                return r.id === opt.id;
                            });
                        }
                    });
                }
            }
        };

        /**
         * Extract data Code
         * @function extractDataCode
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        var extractDataCode = function () {
            self.filmDetails.genres = _.map(self.filmDetails.genreData, "code").join();
            self.filmDetails.format = _.map(self.filmDetails.formatData, "code").join();
            self.filmDetails.releasePattern = _.map(self.filmDetails.releasePatternData, "code").join();
            self.filmDetails.aspectRatio = _.map(self.filmDetails.aspectRatioData, "code").join();
        };

        /**
         * Get film related assets
         * @function getFilmRelatedAssets
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        var getFilmRelatedAssets = function () {
            filmService.getFilmRelatedAssets(filmId, alertKey).success(function (result) {
                self.oneSheets = result.data.onesheets;
                self.censorCards = result.data.censorCards;
                self.trailers = result.data.trailers;
            });
        };

        /**
         * set initial tab
         * @function setInitialTabSelected
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        var setInitialTabSelected = function () {
            self.activeTab = initialTabSelected ? _.findIndex(self.tabs, {
                key: initialTabSelected
            }) : 0;
            self.strateryActiveTab = initialSubTabSelected ? _.findIndex(self.strategyTabs, {
                key: initialSubTabSelected
            }) : 0;
        };

        /**
         * create film details data
         * @function createFilmDetails
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        var createFilmDetails = function () {
            filmService.createFilmDetails(self.filmDetails, alertKey).success(function () {
                $uibModalInstance.close();
            });
        };

        /**
         * update film details data
         * @function updateFilmDetails
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        var updateFilmDetails = function () {
            if (self.filmDetails.onesheet !== self.originalFilmDetails.onesheet) {
                filmService.uploadOnesheet(self.filmDetails, alertKey).success(function () {
                    self.onesheetUpdated = true;
                });
            }
            filmService.updateFilmDetails(self.filmDetails, alertKey).success(function () {
                $uibModalInstance.close();
            });
        };

        /**
         * starts with filter
         * @function startsWith
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @param {string} actual actual text
         * @param {string} expected expected text
         */
        self.startsWith = function (actual, expected) {
            var lowerStr = (actual + "").toLowerCase();
            return lowerStr.indexOf(expected.toLowerCase()) === 0;
        };

        /**
         * Cancel
         * @function cancel
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        self.cancel = function () {
            var oneSheetChanged = false,
                trailerChanged = false;

            if (self.filmDetails) {
                oneSheetChanged = self.filmDetails.onesheet !== self.originalFilmDetails.onesheet,
                    trailerChanged = self.filmDetails.trailerId !== self.originalFilmDetails.trailerId;
            }

            if (self.$parent.editFilmForm.$dirty || oneSheetChanged || trailerChanged) {
                $uibModal.open({
                    templateUrl: "confirmationTemplate.html",
                    controller: "modalConfirmationController",
                    controllerAs: "modalConfirmationCtrl",
                    keyboard: false,
                    backdrop: "static",
                    size: "md",
                    resolve: {
                        message: function () {
                            return self.translations.global.messages.confirmationMessage;
                        },
                        headerTitle: function () {
                            return self.translations.global.messages.warning;
                        },
                        showAffirmativeBtn: function () {
                            return true;
                        },
                        affirmativeBtnText: function () {
                            return self.translations.global.labels.yesButton;
                        },
                        cancelBtnText: function () {
                            return self.translations.global.labels.noButton;
                        }
                    }
                }).result.then(function () {
                    $uibModalInstance.dismiss();
                });
            } else {
                $uibModalInstance.dismiss();
            }
        };

        /**
         * Select asset to set a default onesheet and trailer
         * @function setActiveTab
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @param {string} propertyName onesheet or trailerId
         * @param {integer} assetId id of the asset
         */
        self.selectAsset = function (propertyName, assetId) {
            self.filmDetails[propertyName] = assetId;
        };

        /**
         * Save
         * @function save
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        self.publish = function () {
            var location = localStorageService.get("location") || {},
                territoryId = location.territoryId || 0,
                localeId = location.localeId || 0;

            self.submitted = true;
            self.releaseDateChanged = false;

            if (self.filmDetails) {
                self.releaseDateChanged = self.filmDetails.releaseDateComment !== self.originalFilmDetails.releaseDateComment;
            }
            if (self.$parent.editFilmForm.$valid) {
                self.filmDetails.territoryId = territoryId;
                self.filmDetails.localeId = localeId;
                self.filmDetails.recType = "PUB";
                if (self.filmDetails.locReleaseDate) {
                    self.filmDetails.releaseDate=self.filmDetails.locReleaseDate;
                }
                self.filmDetails.lastModified = moment().utc().format();
                self.filmDetails.distributionRights = "Y";
                extractDataCode();
                processRatingDataForPublish(self.ratingModel);
                if(!self.releaseDateChanged && self.eisNewUpdatedFields.indexOf("release_date_comment") > -1 ){

                    $uibModal.open({
                        templateUrl: "releaseDateCompareTemplate.html",
                        controller: "modalConfirmationController",
                        controllerAs: "modalConfirmationCtrl",
                        keyboard: false,
                        backdrop: "static",
                        size: "md",
                        resolve: {
                            message: function () {
                                return self.translations.global.labels.publishTitleMessage;
                            },
                            headerTitle: function () {
                                return self.translations.global.labels.publishTitleHeader;
                            },
                            showAffirmativeBtn: function () {
                                return true;
                            },
                            affirmativeBtnText: function () {
                                return self.translations.global.labels.yesButton;
                            },
                            cancelBtnText: function () {
                                return self.translations.global.labels.noButton;
                            }
                        }
                    }).result.then(function () {

                        if (self.filmDetails.filmId) {
                            updateFilmDetails();
                        } else {
                            self.filmDetails.mockedMpm = "F";
                            createFilmDetails();
                        }
                    });
                }else{

                    if (self.filmDetails.filmId) {
                        updateFilmDetails();
                    } else {
                        self.filmDetails.mockedMpm = "F";
                        createFilmDetails();
                    }

                }
            }

        };

        /**
         * Reset one sheet selection
         * @function resetOneSheet
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @public
         */
        self.resetOneSheet = function () {
            self.selectAsset("onesheet", "");
        };

        /**
         * scroll top on tab change
         * @function scrollPosition
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         * @public
         */
        self.scrollPosition = function() {
            var tabContent = angular.element(document.querySelector( ".modal-edit-film .tab-content" ));
            tabContent.scrollTop(0);
        };
        /**
         * Init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditFilmController
         */
        self.init = function () {
            getSourceData();
            getFilmRelatedAssets();
            setInitialTabSelected();
        };

        //INIT
        self.init();
    }

    controllers.controller("modalEditFilmController", ["$uibModalInstance", "filmService", "filmId", "eisId", "initialTabSelected", "initialSubTabSelected", "localStorageService", "translateService", "activityHistoryService", "filmRatingService", "API_EFD", "$uibModal", "assetCategoryConvertor", modalEditFilmController]);
}());
