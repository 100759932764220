(function () {
    "use strict";
    var services = angular.module("WB.cinehub.services");

    /**
     * tinymceService Text Editor service
     * @class tinymceService
     * @memberOf angular_module.WB.cinehub.services
     * @desc This is video source generator service
     */
    function tinymceService() {

       /**
         * tinymce text editor option
         * @type {object}
         * @memberOf angular_module.WB.cinehub.services.tinymceService
         * @public
         */
        this.getTinymceOptions = function () {
            return {
                inline: false,
                plugins: "advlist autolink code fullscreen image link lists preview table textcolor",
                skin: "lightgray",
                menu: [],
                toolbar1: "undo redo | styleselect | bold italic sizeselect fontselect fontsizeselect forecolor | alignleft aligncenter alignright alignjustify",
                toolbar2: "table bullist numlist outdent indent | preview link image fullscreen code",
                fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
                theme: "modern",
                min_height: 250,
                relative_urls : false,
                remove_script_host : false,
                trusted: true,
                default_link_target: "_blank",
                target_list: [
                    {title: "New window", value: "_blank"}
                ],
                content_css: "/static/css/site.min.css",
                formats: {
                    hilitecolor: {
                        inline: "span",
                        classes: "hilitecolor",
                        styles: {
                            backgroundColor: "#ffffff"
                        }
                    },
                    customformat: {
                        inline: "span",
                        styles: {
                            backgroundColor: "#ffffff"
                        }
                    }
                },
                setup: function(editor){
                      // Listen for paste event, add "Paste as plain text" callback
                    editor.on("paste", function (e) {
                        e.preventDefault();
                        var content = ((e.originalEvent || e).clipboardData || window.clipboardData).getData("Text");
                        editor.execCommand("mceInsertContent", false, content);
                    });
                    editor.on("cut", function (e) {
                        e.preventDefault();
                        var content = ((e.originalEvent || e).clipboardData || window.clipboardData).getData("Text");
                        editor.execCommand("mceInsertContent", false, content);
                    });
                }
            };
        };

    }

    services.service("tinymceService", [
        tinymceService
    ]);
} ());
