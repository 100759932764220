(function() {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * User search users controller
     * @dec The search user controller
     * @class searchUsersController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {service} localStorageService localstorage service
     * @param {service} deviceDetectorService device detector service
     * @param {service} userService user service
     * @param {!angular.$window} $window window
     */
    function searchUsersController(localStorageService, deviceDetectorService, userService, $window, DEFAULT_PAGE_SIZE) {

        /**
         * Instance of searchUsersController
         * @type {searchUsersController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchUsersController
         */
        var self = this;

        /**
         * Current user object
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchUsersController
         */
        self.user = localStorageService.get("userInfo");

         /**
         * List of users
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchUsersController
         */
        self.usersList = {
            page: {
                size: DEFAULT_PAGE_SIZE,
                number: 1,
                totalElements: 0
            }
        };

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.searchUsersController
         */
        self.pageHeaderItem = {
            pageTitle: "",
            breadcrumbItems: [{
                linkTitle: "",
                link: ""
            }]
        };

        /**
         * The list of the users
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchUsersController
         * @type {object}
         */
        //self.usersList = null;

        /**
         * Filter query
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchUsersController
         * @type {string}
         */
        self.filterQuery = "";

        /**
         * The translations
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.searchUsersController
         */
        self.translations = localStorageService.get("translations");

        /**
         * Device detector service
         * @memberOf angular_module.WB.cinehub.controllers.searchUsersController
         * @private
         * @type {service}
         */
        self.deviceDetectorService = deviceDetectorService;

        /**
         * User service
         * @type {service}
         * @memberOf angular_module.WB.cinehub.controllers.searchUsersController
         * @public
         */
        self.userService = userService;

        /**
         * Sort string
         * @type {string}
         * @memberOf angular_module.WB.cinehub.controllers.searchUsersController
         * @public
         */
        self.sort = "userId,desc";

        /**
         * Open user profile
         * @function openUserProfile
         * @memberOf angular_module.WB.cinehub.controllers.searchUsersController
         * @public
         * @param {int} userId user userId
         */
        self.openUserProfile = function(userId) {
            $window.open("/manage-users/user/" + userId + "/0/", "_blank");
        };

        /**
         * Init
         * @function init
         * @memberOf angular_module.WB.cinehub.controllers.searchUsersController
         * @public
         */
        self.init = function() {
            if(!self.deviceDetectorService.isMobileOrTablet()) {
                self.pageHeaderItem.pageTitle = self.translations.manageUsers.labels.pageTitle;
                self.pageHeaderItem.breadcrumbItems[0].linkTitle = self.translations.manageUsers.labels.breadcrumbLinkTitle;
            } else {
                $window.location.href = "/error/404";
            }
        };

        //INIT
        self.init();
    }

    controllers.controller("searchUsersController", ["localStorageService", "deviceDetectorService", "userService", "$window","DEFAULT_PAGE_SIZE", searchUsersController]);
}());
