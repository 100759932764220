(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * Date parser factory
     * @class dateParserFactory
     * @memberOf angular_module.WB.cinehub.services
     * @returns {parsedDate}
     */
    function dateParserFactory() {
        return function (releaseDate) {
            // original releaseDate format = dd-MMM-yy or "22-AUG-14"
            return releaseDate ? moment(releaseDate).format("MMMM DD, YYYY") : "";
        };
    }

    services.factory("dateParserFactory", [dateParserFactory]);
} ());