(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Modal lookup add user controller
     * @dec The add user controller
     * @class addUserController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {$uibModal} $uibModal modal window
     * @param {service} localStorageService local storage service
     * @param {service} registrationService registration service
     * @param {service} emailService email service
     * @param {service} locationService location service
     */
    function addUserController($rootScope, $uibModal, localStorageService, registrationService, emailService, locationService) {

        /**
         * Instance of addUserController
         * @type {addUserController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         */
        var self = this;

        /**
         * Admin user
         * @type {object}
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         */
        self.adminUser = localStorageService.get("userInfo");
        /**
         * translated labels/messages/values stored in local storage
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         * @public
         * @type {object}
         */
        self.translations = localStorageService.get("translations");

        /**
         * impersonate user flag
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");

        /**
         * add user flag
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         * @type {boolean}
         */
        self.addUserFlag = true;

        /**
         * add user success flag
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         * @type {boolean}
         */
        self.addUserSuccessFlag = false;
        /**
         * Add User form
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         */
        self.addUserForm = null;

        /**
         * user object to store all the registration data
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         * @type {obj}
         * @public
         */
        self.user = {};

        /**
         * email format regex
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         * @type {regex}
         */
        self.emailFormat = emailService.getEmailFormatRegex();

        /**
         * get exhibitors
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         * @function getExhibitors
         * @param {string} keyword search term
         * @public
         */
        self.getExhibitors = function (keyword) {
            return registrationService.getExhibitors(keyword, self.user.territoryId);
        };

        /**
         * Exhibitor/Company Not Found Selected.
         * @function companyNotSelected
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         * @public
         */
        self.companyNotSelected = function () {
            self.user.exhibitorObj = null;
            self.user.theater = null;
            self.user.selectedTheaters=null ;
            self.user.exhibitorId = null;
        };

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.reportsController
         */
        self.pageHeaderItem = {
            pageTitle: self.translations.adminRegisterProfile.labels.addUsers,
            breadcrumbItems: [{
                linkTitle: self.translations.adminRegisterProfile.labels.addUsers,
                link: "/add-user"
            }]
        };

        /**
         * Filter departments by user type
         * @function filterAffiliationsByUserType
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         * @return {(boolean|object[])}
         */
        self.filterAffiliationsByUserType = function () {
            if (!self.user.territoryId) {
                self.territoryFlag = true;
            } else {
                self.territoryFlag = false;
                delete self.user.theaterAffiliations;
                delete self.user.exhibitorRelationship;
                delete self.user.exhibitorId;
                delete self.user.exhibitorObj;
                delete self.user.companyNotListed;
            }
        };

        /**
         * open download information modal
         * @function openDownloadInformaitonModal
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         */
        var openDownloadInformaitonModal = function () {
            $uibModal.open({
                templateUrl: "confirmationTemplate.html",
                controller: "modalConfirmationController",
                controllerAs: "modalConfirmationCtrl",
                keyboard: false,
                backdrop: "static",
                size: "md",
                resolve: {
                    message: function () {
                        return self.translations.adminRegisterProfile.messages.addUserConfirmationMsg;
                    },
                    headerTitle: function () {
                        return self.translations.adminRegisterProfile.labels.adduserConfirmation;
                    },
                    showAffirmativeBtn: function () {
                        return false;
                    },
                    affirmativeBtnText: function () {
                        return "";
                    },
                    cancelBtnText: function () {
                        return self.translations.cart.labels.closeButton;
                    }
                }
            });
        };
        self.reset = function () {
            self.user = {};
            self.init();
        };
        /**
         * Filter departments by user type
         * @function filterAffiliationsByUserType
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         * @return {(boolean|object[])}
         */
        self.filterAffiliationsByCountry = function () {
            self.territoryFlag = false;
            delete self.user.theaterAffiliations;
            delete self.user.exhibitorRelationship;
            delete self.user.exhibitorId;
            delete self.user.exhibitorObj;
            delete self.user.userType;
            delete self.user.companyNotListed;
            if (self.adminUser.role === "homeOfficeAdmin") {
                var selectedTerritory = _.filter(self.translations.values.territories, {
                    id: self.user.territoryId
                });
                self.user.regionId = selectedTerritory[0].extraCode;
            }
        };

        /**
         * getLocaleByRegion
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         * @function getLocaleByRegion
         * @param {obj} region region object
         * @public
         */
        self.getLocaleByRegion = function () {
            var region = JSON.parse(self.user.region);
            self.user.regionId = region.id;
            var localeData = locationService.filterLocaleByRegion(region);
            self.locales = localeData;
            localStorageService.set("localeData", localeData);
        };

        /**
         * Set Territory Data
         * @function setTerritoryData
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         * @return {(boolean|object[])}
         */
        self.setTerritoryData = function () {
            var selectedLocale = _.filter(self.translations.values.locales, {
                id: self.user.localeId
            });
            var selectedTerritory = _.filter(self.translations.values.territories, {
                id: selectedLocale[0].extraCode
            });
            self.user.regionId = selectedTerritory[0].extraCode;
            self.user.territory = selectedTerritory[0].value;
            self.user.territoryId = selectedLocale[0].extraCode;
            self.user.locale = selectedLocale[0].value;
            self.territoryFlag = false;
            delete self.user.theaterAffiliations;
            delete self.user.exhibitorRelationship;
            delete self.user.exhibitorId;
            delete self.user.exhibitorObj;
            delete self.user.userType;
            delete self.user.companyNotListed;
        };

        /**
         * Open lookup theater modal
         * @function openLookupTheaterModal
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         */
        self.openLookupTheaterModal = function () {
            var modalInstance = $uibModal.open({
                templateUrl: "/modules/lookupTheaterModal/modalLookupTheater.html",
                controller: "modalLookupTheaterController",
                controllerAs: "lookupTheaterCtrl",
                keyboard: false,
                backdrop: "static",
                size: "lg",
                resolve: {
                    exhibitor: function () {
                        return self.user.exhibitorObj;
                    },
                    territoryId: function () {
                        return self.user.territoryId;
                    },
                    headerText: function () {
                        return self.user.userType === "ex-corporate" ? self.translations.registration.labels.exhibitorLookup : self.translations.registration.labels.theaterLookup;
                    },
                    isExCorporate: function () {
                        return self.user.userType === "ex-corporate";
                    },

                    selectedTheaters: function () {
                        return self.user.selectedTheaters;
                    }
                }
            });

            modalInstance.result.then(function (selectedTheaters) {
                if (self.user.userType === "ex-theater") {
                    self.user.selectedTheaters = selectedTheaters;
                    self.disableNextButton = false;
                } else if (self.user.userType === "ex-corporate") {
                    self.user.theater = selectedTheaters[0];
                    self.user.exhibitorId = selectedTheaters[0].exhibitorId;
                }
            });
        };

        /**
         * remove theater
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         * @function removeTheater
         * @param {int} theaterId theater id
         * @public
         */
        self.removeTheater = function (theaterId) {
            var selectedIndex = _.findIndex(self.user.selectedTheaters, {
                id: theaterId
            });
            self.user.selectedTheaters.splice(selectedIndex, 1);
            if(!self.user.selectedTheaters.length) {
                self.user.selectedTheaters = "";
            }
        };

        /**
         * finalize registration data by modifying it to store proper values
         * @memberOf angular_module.WB.cinehub.controllers.registrationController
         * @function finalizeRegistrationInfo
         * @private
         */
        var finalizeRegistrationInfo = function () {
            switch (self.user.userType) {
                case "ex-theater":
                    self.user.theaterAffiliations = self.user.selectedTheaters && self.user.selectedTheaters.length > 0 ? JSON.stringify(self.user.selectedTheaters) : "";
                    break;
                case "ex-other":
                    self.user.exhibitorRelationship = self.user.exhibitorRelationship && self.user.exhibitorRelationship.length > 0 ? JSON.stringify(self.user.exhibitorRelationship) : "";
                    break;
                default:
                    break;
            }

            if (self.user.userType !== "ex-other") {
                self.user.exhibitorRelationship = null;
            }

        };

        /**
         * Submit profile
         * @function submit 
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         */
        self.submit = function () {
            if (self.addUserForm.$valid) {
                self.user.email = self.user.email.toLowerCase();
                self.user.loginName = self.user.email;
                self.user.termsAccepted = false;
                self.user.privPolicyAccepted = false;
                finalizeRegistrationInfo();
                registrationService.validateUserEmail(self.user.loginName).success(function (response) {
                    self.user.accountType = response.data.accountType;
                    self.isWBUser = self.user.accountType === "INTERNAL" ? true : false;
                    if (self.isWBUser && self.user.userType !== "wb" && self.user.userType !== "wb-other") {
                        self.isInternalUserFlag = true;
                    } else if (!self.isWBUser && self.user.userType === "wb") {
                        self.isExternalUserFlag = true;
                    } else {
                        registrationService.addUser(self.user).success(function () {
                            self.addUserFlag = true;
                            self.isInternalUserFlag = false;
                            self.isExternalUserFlag = false;
                            self.user = {};
                            openDownloadInformaitonModal();
                            self.addUserSuccessFlag = true;
                            self.init();
                            self.addUserForm.$setPristine();
                        }).error(function (error) {
                            self.addUserFlag = false;
                            $rootScope.$broadcast("hideAlert");
                            if (error) {
                                var errorMessage = self.translations.registration.messages.createUserError;
                                $rootScope.$broadcast("showAlert", "danger", errorMessage, "anonymousUserAlert");
                            }
                        });
                    }
                });
            }
        };

        /**
         * Remove theater when the exhibitor changes
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         * @function removeBulkTheater
         * @public
         */
        self.removeBulkTheater = function(){
            if(self.user.selectedTheaters.length) {
                self.user.selectedTheaters = "";
            }
        };

        /**
         * Set territory and Region
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         * @function setTerritoryAndRegion
         * @public
         */
        self.setTerritoryAndRegion = function(){
            if (self.adminUser.role !== "homeOfficeAdmin") {
                self.user.territoryId = self.adminUser.territoryId;
                self.user.regionId = self.adminUser.regionId;
                self.user.localeId = self.adminUser.localeId;
            }
        };
        /**
         * Init
         * @function init
         * @memberOf angular_module.WB.cinehub.controllers.addUserController
         * @public
         */
        self.init = function () {
            self.setTerritoryAndRegion();
            self.activeRegions =  locationService.getActiveRegions(self.translations.values.regions);
            self.addUserSuccessFlag = false;
        };
        self.init();
    }

    controllers.controller("addUserController", ["$rootScope", "$uibModal", "localStorageService", "registrationService", "emailService", "locationService", addUserController]);
}());
