(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");
    
    /**
     * @class spotlightDetailsService
     * @memberOf angular_module.WB.cinehub.services
     * @desc spotlight details service
     * @param {!angular.$http} $http angular $http service
     * @param {strict} API_ROOT API_ROOT constant
     * @param {service} localStorageService localStorageService
     */
    function spotlightDetailsService($http, API_ROOT, localStorageService) {

        /**
         * Instance of spotlightDetailsService
         * @type {spotlightDetailsService}
         * @public
         * @memberOf angular_module.WB.cinehub.services.spotlightDetailsService
         */
        var self = this,

            /**
             * Location
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.services.spotlightDetailsService
             */
            location = localStorageService.get("location");
        
        /**
         * translated labels/messages/values stored in local storage
         * @memberOf angular_module.WB.cinehub.services.spotlightDetailsService
         * @public
         * @type {object}
         */
        self.translations = localStorageService.get("translations");

        /**
         * get new spotlight count for the logged in user.
         * @memberOf angular_module.WB.cinehub.services.spotlightDetailsService
         * @public
         * @param {string} userId user id
         * @returns {promise}
         */
        this.getNewSpotlightCount = function (userId) {
            return $http.get(API_ROOT + "spotlightItems/search/findSpotlightNotifications?userId=" + userId + "&modifier=" + userId, {
                errorMessage : self.translations.spotlight.messages.fetchingSpotlightNotificationErrorMessage
                //errorMessage : "Error while fetching spotlight notifications"
            });
        };

        /**
         * update one sheet for spotlight.
         * @memberOf angular_module.WB.cinehub.services.spotlightDetailsService
         * @public
         * @param {string} asset asset
         * @returns {promise}
         */
        this.updateOnesheet = function (asset) {
            return $http.post(API_ROOT + "spotlightItems/updateOrRemovePosterImage?spotlightId=" + asset.spotlightParentId + "&assetId=" + asset.id, {
                errorMessage : self.translations.spotlight.messages.updatingOnesheetErrorMessage
            });
        };
        /**
         * remove default image.
         * @memberOf angular_module.WB.cinehub.services.spotlightDetailsService
         * @public
         * @param {string} id id
         * @returns {promise}
         */
        this.removeDefaultImage = function (id) {
            var assetId = "";
            return $http.post(API_ROOT + "spotlightItems/updateOrRemovePosterImage?spotlightId=" + id + "&assetId=" + assetId , {
                errorMessage : self.translations.spotlight.messages.updatingOnesheetErrorMessage
            });
        };
        /**
         * get spotlight details along with assets
         * @memberOf angular_module.WB.cinehub.services.spotlightDetailsService
         * @public
         * @param {int} spotlightId spotlight id
         * @param {int} filmId film id
         * @param {string} wbAssetType wb asset type
         * @param {string} wbpiAssetType wbpi asset type
         * @param {string} fileType file type
         * @param {str} size page size
         * @param {str} page page index
         * @param {str} sort sorting
         * @param {str} errorMessage error message
         * @returns {promise}
         */
        this.getSpotlightDetails = function (spotlightId, filmId, wbAssetType, wbpiAssetType, fileType, showAll, size, page, sort, errorMessage) {
            return $http.post(API_ROOT + "spotlightItems/search/findLocById?size=" + size + "&page=" + page + "&sort=" + sort , {
                "recordId" : spotlightId,
                "filmId": filmId,
                "wbAssetType": wbAssetType,
                "wbpiAssetType": wbpiAssetType,
                "fileType": fileType,
                "showAll": showAll,
                "territoryId": location.territoryId,
                "localeId": location.localeId,
                "exhibitorId": localStorageService.get("currentExhibitorAffiliation") || -1,
                "theaterId": localStorageService.get("currentTheaterAffiliation") || -1
            }, {
                errorMessage : errorMessage
            });
        };

        /**
         * get spotlight asset Filters
         * @memberOf angular_module.WB.cinehub.services.spotlightDetailsService
         * @public
         * @param {int} spotlightId spotlight id
         * @param {int} filmId film id
         * @param {string} wbAssetType wb asset type
         * @param {string} wbpiAssetType wbpi asset type
         * @param {string} fileType file type
         * @returns {promise}
         */
        this.getSpotlightAssetFilters = function (spotlightId, filmId, wbAssetType, wbpiAssetType, fileType, showAll) {
            return $http.post(API_ROOT + "spotlightItems/search/findLocByIdFilters" , {
                "recordId" : spotlightId,
                "filmId": filmId,
                "wbAssetType": wbAssetType,
                "wbpiAssetType": wbpiAssetType,
                "fileType": fileType,
                "showAll": showAll,
                "territoryId": location.territoryId,
                "localeId": location.localeId,
                "exhibitorId": localStorageService.get("currentExhibitorAffiliation") || -1,
                "theaterId": localStorageService.get("currentTheaterAffiliation") || -1
            }, {
                errorMessage : self.translations.toDo.messages.cannotGetFilterValues,
                showSpinner: true
            });
        };

        /**
         * delete the spotlight item
         * @memberOf angular_module.WB.cinehub.services.spotlightDetailsService
         * @public
         * @param {spotlightId} spotlightId
         * @param {array} spotlightItem assets
         * @param {str} errorMessage error message
         * @returns {promise}
         */
        this.deleteSpotlightAssets = function (spotlightId, deletedAssets, errorMessage) {
            return $http.post(API_ROOT + "spotlightItems/addOrUpdateSpotlightAssets", {
                "spotlightId" : spotlightId,
                "spotlightAssets" : deletedAssets,
                action: "DELETE"
            }, {
                errorMessage : errorMessage
            });
        };

        /**
         * delete the spotlight item
         * @memberOf angular_module.WB.cinehub.services.spotlightDetailsService
         * @public
         * @param {spotlightItem} spotlightItem
         * @param {isNew} isNew
         * @param {spotlightId} spotlightId
         * @param {array} spotlightItem assets
         * @param {str} errorMessage error message
         * @returns {promise}
         */
        this.addSpotlightAssets = function (spotlightItem, isNew, spotlightId, addedAssets, errorMessage) {
            self.status = isNew ? "ADD" : "UPDATE";
            spotlightItem.territoryId = location.territoryId;
            if (isNew) {
                return $http.post(API_ROOT + "spotlightItems/addOrUpdateSpotlightAssets", {
                    "spotlightItem" : spotlightItem,
                    "spotlightId" : spotlightId,
                    "spotlightAssets" : addedAssets,
                    action: self.status
                }, {
                    errorMessage : errorMessage
                });
            } else {
                return $http.post(API_ROOT + "spotlightItems/addOrUpdateSpotlightAssets", {
                    "spotlightId" : spotlightId,
                    "spotlightAssets" : addedAssets,
                    action: self.status
                }, {
                    errorMessage : errorMessage
                });
            }
        };

        /**
         * create spotlight details changes
         * @memberOf angular_module.WB.cinehub.services.spotlightDetailsService
         * @public
         * @param {obj} spotlightItem spotlight item
         * @returns {promise}
         */
        this.create = function(spotlightItem) {
            spotlightItem.territoryId = location.territoryId;
            spotlightItem.localeId = location.localeId;
            return $http.post(API_ROOT + "spotlightItems/saveSpotlight", spotlightItem, {
                errorMessage: self.translations.addEditNewsModal.labels.publishNewsError,
                alertKey: "modalAlert"
            });
        };

        /**
         * update spotlight details changes
         * @memberOf angular_module.WB.cinehub.services.spotlightDetailsService
         * @public
         * @param {obj} spotlightItem spotlight item
         * @returns {promise}
         */
        this.update = function(spotlightItem) {
            spotlightItem.territoryId = location.territoryId;
            spotlightItem.localeId = location.localeId;
            delete spotlightItem.spotlightAssetPage;
            return $http.put(API_ROOT + "spotlightItems/updateSpotlight", spotlightItem, {
                errorMessage: self.translations.addEditNewsModal.labels.publishNewsError,
                alertKey: "modalAlert"
            });
        };
        /**
         * Get film titles
         * @memberOf angular_module.WB.cinehub.services.spotlightDetailsService
         * @public
         * @function getFilmTitles
         * @returns {promise}
         */
        this.getFilmTitles = function() {
            return $http.get(API_ROOT + "films/search/findLocAll?page=0&size=100&projection=forNewsItem", {
                errorMessage: self.translations.addEditNewsModal.labels.getFilmTitlesError
            });
        };
    }

    services.service("spotlightDetailsService", ["$http", "API_ROOT", "localStorageService", spotlightDetailsService]);
}());
