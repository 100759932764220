(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");
    
    /**
     * @class healthCheckService
     * @memberOf angular_module.WB.cinehub.services
     * @desc Health check service
     * @param {!angular.$http} $http angular $http service
     * @param {strict} API_ROOT API_ROOT constant  
     */
    function healthCheckService($http, API_ROOT) {
        
        /**
         * Get health info 
         * @memberOf angular_module.WB.cinehub.services.healthCheckService
         * @public
         * @returns {promise}
         */
        this.getHealthInfo = function () {
            return $http.get(API_ROOT + "health&ignoreLocalizationString");
        };

    }

    services.service("healthCheckService", ["$http", "API_ROOT", healthCheckService]);
}());
