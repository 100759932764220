(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class userService
     * @memberOf angular_module.WB.cinehub.services
     * @desc user service
     * @param {!angular.$rootScope} $rootScope angular $rootScope
     * @param {!angular.$http} $http angular $http service
     * @param {strict} API_ROOT API_ROOT constant
     * @param {service} localStorageService local storage service
     * @param {service} loginService login service
     * @param {factory} promiseFactory promiseFactory
     */
    function userService($rootScope, $http, $window, $cookies, API_ROOT, localStorageService, promiseFactory, loginService, OAUTH_SESSION_LIFETIME, SECURE_COOKIES, DOMAIN) {

        /**
         * Reference to this object
         * @type {object}
         * @private
         * @memberOf angular_module.WB.cinehub.services.userService
         */
        var self = this,

            /**
             * Translations
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.services.userService
             */
            translations = localStorageService.get("translations"),

            /**
             * User info
             * @type {object}
             * @memberOf angular_module.WB.cinehub.controllers.userService
             * @private
             */
            userInfo = localStorageService.get("userInfo");

        /**
         * Update user profile exhibitor relationship
         * @memberOf angular_module.WB.cinehub.services.userService
         * @param {object} exhibitor exhibitor info
         * @private
         * @function updateUserProfileExhibitorRelationship
         * @returns {promise}
         */
        var updateUserProfileExhibitorRelationship = function (exhibitor) {
            if (userInfo.exhibitorRelationship && userInfo.exhibitorRelationship.length > 0) {
                var exhibitorRelationship = JSON.parse(userInfo.exhibitorRelationship),
                    isAffiliationLocalNameUpdated = false,
                    index = 0,
                    exhibitorRelationshipLength = exhibitorRelationship.length;
                for (index; index < exhibitorRelationshipLength; index++) {
                    if (exhibitorRelationship[index].id === exhibitor.id) {
                        exhibitorRelationship[index] = exhibitor;
                        isAffiliationLocalNameUpdated = true;
                    }
                }
                userInfo.exhibitorRelationship = JSON.stringify(exhibitorRelationship);
                localStorageService.set("userInfo", userInfo);
                if (isAffiliationLocalNameUpdated) {
                    $rootScope.$emit("exhibitorLocalNameUpdated");
                }
            }
        };

        /**
         * Update user profile theater affiliation
         * @memberOf angular_module.WB.cinehub.services.userService
         * @param {object} theater theater info
         * @private
         * @function updateUserProfileTheaterAffiliation
         * @returns {promise}
         */
        var updateUserProfileTheaterAffiliation = function (theater) {
            if (userInfo.theaterAffiliations && userInfo.theaterAffiliations.length > 0) {
                var theaterAffiliations = JSON.parse(userInfo.theaterAffiliations),
                    isAffiliationLocalNameUpdated = false,
                    index = 0,
                    theaterAffiliationsLength = theaterAffiliations.length;
                for (index; index < theaterAffiliationsLength; index++) {
                    if (theaterAffiliations[index].id === theater.id) {
                        theaterAffiliations[index] = theater;
                        isAffiliationLocalNameUpdated = true;
                    }
                }
                userInfo.theaterAffiliations = JSON.stringify(theaterAffiliations);
                localStorageService.set("userInfo", userInfo);
                if (isAffiliationLocalNameUpdated) {
                    $rootScope.$emit("theaterLocalNameUpdated");
                }
            }
        };

        /**
         * Search users
         * @memberOf angular_module.WB.cinehub.services.userService
         * @param {string} filterQuery filter query
         * @public
         * @function searchUsers
         * @returns {promise}
         */
        this.searchUsers = function (filterQuery, page, size, sort) {
            return $http.get(API_ROOT + "users/search/findByFilters?" + filterQuery + "&page=" + page + "&size=" + size + "&sort=" + sort + "&projection=default&ignoreLocalizationString", {
                errorMessage: translations.userInfo.messages.searchUsersError
            });
        };

        /**
         * The search users function with some json parsing code. Apparently not all of the searchUsers consuming code wants this data parsed.
         * @type {function}
         * @memberOf angular_module.WB.cinehub.controllers.searchUsersController
         * @public
         */
        this.searchUsersAndParse = function (filterQuery, pageNumber, pageSize, gridSort) {
            var deferred = promiseFactory.defer();

            self.searchUsers(filterQuery, pageNumber, pageSize, gridSort)
                .then(function (result) {
                    result.data.content
                        .forEach(function (user) {
                            user.exhibitorRelationship = user.exhibitorRelationship ? JSON.parse(user.exhibitorRelationship) : [];
                            user.theaterAffiliations = user.theaterAffiliations ? JSON.parse(user.theaterAffiliations) : [];
                            user.additionalCountries = user.additionalCountries ? JSON.parse(user.additionalCountries) : [];
                        });

                    deferred.resolve(result.data);
                })
                .catch(function () {
                    deferred.reject();
                });

            return deferred.promise;
        };

        /**
         * Get the list of all users in the system
         * @memberOf angular_module.WB.cinehub.services.userService
         * @public
         * @returns {promise}
         * @function getAllUsers
         * @param {int} page page number
         * @param {int} size page size number
         * @param {string} sort sort get query
         */
        this.getUsersByTerritoryId = function (page, size, sort) {
            return $http.get(API_ROOT + "users/search/findByTerritoryId?page=" + page + "&size=" + size + "&sort=" + sort + "&projection=default&ignoreLocalizationString", {
                errorMessage: translations.userInfo.messages.getUsersError
            });
        };

        /**
         * Get user profile by user id
         * @memberOf angular_module.WB.cinehub.services.userService
         * @public
         * @param {int} userId user user id
         * @returns {promise}
         * @function getUserByUserId
         */
        this.getUserByUserId = function (userId) {
            return $http.get(API_ROOT + "users/search/findByUserId?userId=" + userId, {
                errorMessage: translations.userInfo.messages.getUserError
            });
        };

        /**
         * Get user profile by user id
         * @memberOf angular_module.WB.cinehub.services.userService
         * @public
         * @param {int} userId user user id
         * @returns {promise}
         * @function getUserByUserId
         */
        this.getUserByUserIdAndLoginName = function (userId, loginName) {
            return $http.get(API_ROOT + "users/findByUserIdAndLoginname?userId=" + userId +"&loginname=" + loginName, {
                errorMessage: translations.userInfo.messages.getUserError
            });
        };
        /**
         * Verifies a newly registered user has possesion of the email address they registered with
         * @memberOf angular_module.WB.cinehub.services.userService
         * @public
         * @param {Object} user user
         * @returns {promise}
         * @function verifyEmail
         */
        this.verifyEmail = function (user) {
            return $http.post(API_ROOT + "users/verifyEmail", user, {
                errorMessage: translations.userInfo.messages.getUserError
            });
        };

        /**
         * Resend email verification 
         * @memberOf angular_module.WB.cinehub.services.userService
         * @public
         * @param {string} loginName login name = email user register with
         * @returns {promise}
         * @function resendEmailVerification
         */
        this.resendEmailVerification = function (loginName) {
            return $http.get(API_ROOT + "users/resendVerificationEmail?loginName=" + loginName, {
                errorMessage: translations.userInfo.messages.resendEmailVerificationError
            });
        };

        /**
         * Resend user migration email 
         * @memberOf angular_module.WB.cinehub.services.userService
         * @public
         * @param {string} loginName login name = email user register with
         * @param {string} emailVerificationToken email verification token
         * @param {string} status status
         * @returns {promise}
         * @function resendUserMigrationEmail
         */
        this.resendUserMigrationEmail = function (loginName, emailVerificationToken, status) {
            return $http.get(API_ROOT + "users/sendRegisterProfileEmail?loginName=" + loginName + "&verificationToken=" + emailVerificationToken + "&status=" + status + "&ignoreLocalizationString", {
                errorMessage: translations.userInfo.messages.resendUserMigrationEmailError
            });
        };

        /**
         * Resend user migration email 
         * @memberOf angular_module.WB.cinehub.services.userService
         * @public
         * @param {string} loginName login name = email user register with
         * @param {string} emailVerificationToken email verification token
         * @param {string} status status
         * @returns {promise}
         * @function resendUserMigrationEmail
         */
        this.resendAdminRegisteredUserEmail = function (loginName, emailVerificationToken, status) {
            return $http.get(API_ROOT + "users/sendRegisterProfileEmail?loginName=" + loginName + "&verificationToken=" + emailVerificationToken + "&status=" + status + "&ignoreLocalizationString", {
                errorMessage: translations.adminRegisterProfile.messages.addUserEmailFailureMsg
            });
        };

        /**
         * Update user information
         * @memberOf angular_module.WB.cinehub.services.userService
         * @public
         * @param {object} user user object
         * @returns {promise}
         * @function updateUser
         */
        this.updateUser = function (user) {
            return this.bulkUpdateUsers([user]);
        };
        
        /**
         * Create impersonate user token
         * @memberOf angular_module.WB.cinehub.services.userService
         * @public
         * @param {object} user user object
         * @returns {promise}
         * @function impersonateToken
         */
        this.impersonateToken = function (user) {
            return $http.post(API_ROOT + "users/impersonateToken?grant_type=password&scope=write", user, {
                errorMessage: translations.viewAs.messages.createImpersonateTokenErrorMessage
            });
        };

        /**
         * Update user Okta Status
         * @memberOf angular_module.WB.cinehub.services.userService
         * @public
         * @param {object} user user object
         * @returns {promise}
         * @function updateUserOktaStatus
         */
        this.updateUserOktaStatus = function (user) {
            return $http.post(API_ROOT + "users/updateUserOktaStatus", {
                loginName: user.loginName
            }, {
                errorMessage: translations.registration.messages.createUserError
            });
        };

        /**
         * Update my profile information
         * @memberOf angular_module.WB.cinehub.services.userService
         * @public
         * @param {object} user user object
         * @returns {promise}
         * @function updateMyProfile
         */
        this.updateMyProfile = function (user) {
            return $http.post(API_ROOT + "users/updateMyProfile", user, {
                errorMessage: translations.userInfo.messages.updateUserError
            });
        };

        /**
         * Get my profile
         * @function getMyProfile
         * @public
         * @returns {promise}
         * @memberOf angular_module.WB.cinehub.services.userService
         */
        this.getMyProfile = function () {
            return $http.get(API_ROOT + "users/getMyProfile?&ignoreLocalizationString", {
                errorMessage: translations.userInfo.messages.getUserError
            });
        };

        /**
         * Save users in bulk
         * @memberOf angular_module.WB.cinehub.services.userService
         * @public
         * @function bulkUpdateUsers
         * @param {object[]} users array of users objects
         * @returns {promise}
         */
        this.bulkUpdateUsers = function (users) {
            users.forEach(function (user) {
                delete user.authorities;
            });
            return $http.post(API_ROOT + "users/saveAll", users, {
                errorMessage: translations.userInfo.messages.updateUserError
            });
        };

        /**
         * Save users in bulk
         * @memberOf angular_module.WB.cinehub.services.userService
         * @public
         * @function bulkUpdateUsers
         * @param {object[]} theaters theaters info
         * @returns {promise}
         */
        this.updateUserAffiliationByTheater = function (theaters) {
            return $http.post(API_ROOT + "users/updateUserAffiliationByTheater", theaters, {
                errorMessage: translations.userInfo.messages.updateUserError
            }).success(function () {
                updateUserProfileTheaterAffiliation(theaters[0]);
            });
        };

        /**
         * Update user exhibitor affiliation
         * @memberOf angular_module.WB.cinehub.services.userService
         * @public
         * @function updateUserAffiliationByExhibitor
         * @param {object[]} exhibitors exhibitors info
         * @returns {promise}
         */
        this.updateUserAffiliationByExhibitor = function (exhibitors) {
            return $http.post(API_ROOT + "users/updateUserAffiliationByExhibitor", exhibitors, {
                errorMessage: translations.userInfo.messages.updateUserError
            }).success(function () {
                updateUserProfileExhibitorRelationship(exhibitors[0]);
            });
        };

        /**
         * Verify profile for user migration
         * @function verifyRegisteredProfile
         * @public
         * @returns {promise}
         * @memberOf angular_module.WB.cinehub.services.userService
         * @param {object} payload payload consist of loginName and emailVerificationToken
         */
        this.verifyRegisteredProfile = function (payload) {
            return $http.post(API_ROOT + "users/verifyRegisteredProfile", payload, {
                errorMessage: translations.registerProfile.messages.callFail
            });
        };

        /**
         * Verify profile for user migration
         * @function verifyRegisteredProfile
         * @public
         * @returns {promise}
         * @memberOf angular_module.WB.cinehub.services.userService
         * @param {object} payload payload consist of loginName and emailVerificationToken
         */
        this.verifyStandardProfile = function (payload) {
            return $http.post(API_ROOT + "users/verifyStandardProfile", payload, {
                errorMessage: translations.registerProfile.messages.callFail
            });
        };

        /**
         * Register migrated profile for user migration
         * @function registerProfile
         * @public
         * @returns {promise}
         * @memberOf angular_module.WB.cinehub.services.userService
         * @param {object} payload payload consist of user profile information
         */
        this.registerProfile = function (payload) {
            return $http.post(API_ROOT + "users/registerProfile", payload, {
                errorMessage: translations.registerProfile.messages.callFail
            });
        };

        /**
         * Admin Registered Profile
         * @function registerProfile
         * @public
         * @returns {promise}
         * @memberOf angular_module.WB.cinehub.services.userService
         * @param {object} payload payload consist of user profile information
         */
        this.adminRegisteredProfile = function (payload) {
            return $http.post(API_ROOT + "users/adminRegisteredProfile", payload, {
                errorMessage: translations.registerProfile.messages.callFail
            });
        };

        /**
         * Verify profile for user migration
         * @function verifyRegisteredProfile
         * @public
         * @returns {promise}
         * @memberOf angular_module.WB.cinehub.services.userService
         * @param {object} payload payload consist of loginName and emailVerificationToken
         */
        this.verifyResetPasswordToken = function (payload) {
            return $http.post(API_ROOT + "users/verifyResetPasswordToken", payload, {
                errorMessage: translations.registerProfile.messages.callFail
            });
        };

        /**
         * Get user info
         * @function getUserInfo
         * @memberOf angular_module.WB.cinehub.services.userService
         * @param {string} username username
         */
        this.getUserInfo = function (username) {
            loginService.getUserInfo(username).success(function (result) {
                var userInfo = result[0];
                if (userInfo) {
                    localStorageService.set("userInfo", userInfo);
                    if (userInfo.expiryAlert === "YES") {
                        localStorageService.set("expiryPageAlert", userInfo.loginName);
                        if (userInfo.expiryDays <= 0) {
                            $cookies.remove("accessToken", {domain: DOMAIN, path: "/" });
                            $window.location.href = "/reset-password?userId=" + userInfo.userId + "&resetPasswordToken=" + userInfo.resetPasswordToken + "&status=expired";
                        } else {
                            $window.location.href = "/password-expired";
                        }
                    } else {
                        switch (userInfo.userType) {
                            case "ex-corporate":
                                localStorageService.set("currentExhibitorAffiliation", userInfo.exhibitorId);
                                break;
                            case "ex-other":
                                var exhibitorRelationship = JSON.parse(userInfo.exhibitorRelationship);
                                localStorageService.set("currentExhibitorAffiliation", exhibitorRelationship[0].id);
                                break;
                            case "ex-theater":
                                var theaterAffiliations = JSON.parse(userInfo.theaterAffiliations);
                                localStorageService.set("currentExhibitorAffiliation", theaterAffiliations[0].exhibitorId);
                                localStorageService.set("currentTheaterAffiliation", theaterAffiliations[0].id);
                                break;
                        }
                        if ($window.location.hash) {
                            $window.location.href = $window.location.hash.slice(1, $window.location.hash.length);
                        } else {
                            $window.location.href = "/home";
                        }
                    }
                }
            }).error(function () {
                self.disableLoginBtn = false;
            });
        };  

    }

    services.service("userService", ["$rootScope", "$http", "$window", "$cookies", "API_ROOT", "localStorageService", "promiseFactory", "loginService", userService]);
}());
