(function () {
    "use strict";
    var services = angular.module("WB.cinehub.services");

    /**
     * Text service
     * @class textService
     * @memberOf angular_module.WB.cinehub.services
     * @desc This is text service
     */
    function textService() {

        /**
         * Remove html tags
         * @function removeHtmlTags
         * @param {string} str
         * @public
         * @memberOf angular_module.WB.cinehub.services.textService
         */
        this.removeHtmlTags = function (str) {
            if (str) {
                return str.replace(/(<([^>]+)>)/ig, "");
            } else {
                return "";
            }
        };
    }

    services.service("textService", [
        textService
    ]);
}());
