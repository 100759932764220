(function() {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Spotlight details controller
     * @dec The Spotlight details controller
     * @class spotlightDetailsController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.Scope} $scope angular scope
     * @param {!angular.$rootScope} $rootScope angular root scope
     * @param {$uibModal} $uibModal modal instance
     * @param {$window} $window $window instance
     * @param {$cookies} $window $cookies instance
     * @param {$location} $window $location instance
     * @param {$anchorScroll} $window $$anchorScroll instance
     * @param {$q} $window $q instance
     * @param {service} spotlightDetailsService Spotlight details service
     * @param {service} assetPickerService asset picker service
     * @param {service} localStorageService local storage service
     * @param {constant} API_EFD API_EFD
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     * @param {service} tinymceService tinymce service
     */
    function spotlightDetailsController($scope, $rootScope, $uibModal, $window, $cookies, $location, $anchorScroll, $q, spotlightDetailsService, assetPickerService, localStorageService, shoppingCartService, API_EFD, DEFAULT_PAGE_SIZE, tinymceService) {

        /**
         * Instance of spotlightDetailsController
         * @type {spotlightDetailsController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        var self = this,

            /**
             * Spotlight id
             * @type {int}
             * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
             * @private
             */
            spotlightId = window.WB.cinehub.preload.spotlightId,

            /**
             * User info
             * @type {object}
             * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
             * @private
             */
            user = localStorageService.get("userInfo");

        /**
         * impersonate user flag
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");

        /**
         * Is edit mode flag
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @type {boolean}
         */
        self.isEditMode = window.WB.cinehub.preload.isEditMode === "1" ? true : false;
        
        /**
         * new item flag
         * @public
         * @type {boolean}
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.isNew = !spotlightId ? true : false;

        /**
         * Is visible flag
         * @public
         * @type {boolean}
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.IsVisible = false;

        /**
         * Sort by options model
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @private
         * @type [Array]
         */
        self.spotlightAssetsSortModel = {};

        /**
         * Multi Select Filter Options & Models.
         * Holds all he filter options
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @private
         * @type [Array]
         */
        self.filterOptions = [];
        /**
         * translations
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.translations = localStorageService.get("translations");

        /**
         * impersonate user flag
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.viewAs = localStorageService.get("impersonateUserFlag");

        /**
         * API_EFD
         * @type {constant}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.API_EFD = API_EFD;
        

        /**
         * is SpotlightItem Loaded Flag
         * @type {constant}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.isSpotlightItemLoaded = false;

        /**
         * Spotlight asset filter
         * @type {Array}
         * private
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.spotlightAssetsFilterSortOptions = [];

        /**
         * All filter
         * @type {Array}
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.allFilterValues = [];

        /**
         * Button default text for films
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @private
         * @type [Array]
         */
        self.filmTranslations = {buttonDefaultText: self.translations.filter.labels.films};

        /**
         * Button default text for Asset types
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @private
         * @type [Array]
         */
        self.assetTypeTranslations = {buttonDefaultText: self.translations.filter.labels.assetTypes};

        /**
         * Button default text for sub types
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @private
         * @type [Array]
         */
        self.subTypeTranslations = {buttonDefaultText: self.translations.filter.labels.assetSubTypes};

        /**
         * Button default text for file types
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @private
         * @type [Array]
         */
        self.fileTypeTranslations = {buttonDefaultText: self.translations.filter.labels.fileTypes};

         /**
         * Multi Select Filter Options & Models.
         * Default button text for Sort
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @private
         * @type [Array]
         */
        self.sortTypeTranslations = {buttonDefaultText: "Sort By"};

       /**
         * Shopping cart service
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @public
         * @type {service}
         */
        self.shoppingCartService = shoppingCartService;

        /**
         * Do not show
         * @public
         * @type {boolean}
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.doNotShow = false;

        /**
         * do not checked
         * @public
         * @type {boolean}
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.donotShowChecked = $cookies.get("donotShowChecked") || "";

        /**
         * tinymce text editor option
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @public
         */
        self.tinymceOptions = tinymceService.getTinymceOptions();

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.pageHeaderItem = {
            pageTitle: self.translations.spotlight.labels.Pagetitle,
            breadcrumbItems: [{
                linkTitle: self.translations.spotlight.labels.spotlight,
                link: "/spotlight-list"
            }]
        };

        /**
         * Open lightbox
         * @function openLightBox
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @param {object} $event browser event
         * @param {object} asset asset
         * @param {string} action action
         */
        self.openLightBox = function ($event, asset, action) {
            self.openLightbox = true;
            self.lightboxAsset = asset;
            self.lightboxAction = action;
        };


        /**
         * Get spotlight details
         * @function getSpotlightDetails
         * @private
         * @param {boolean}SpotlightUpdated spotlight updated flag
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.getSpotlightDetails = function(spotlightUpdated) {
            var errorMessage = self.translations.newsDetails.messages.getNewsDetailsError;

            spotlightDetailsService.getSpotlightDetails(spotlightId, self.spotlightAssetsFilterSort.filmId, self.spotlightAssetsFilterSort.wbAssetType, self.spotlightAssetsFilterSort.wbpiAssetType,
            self.spotlightAssetsFilterSortspotlighteType, self.spotlightAssetsFilterSort.showAll, self.spotlightAssets.data.size, self.spotlightAssets.data.number, self.spotlightAssetsFilterSort.sort, errorMessage).success(function(result) {
                self.isSpotlightItemLoaded = true;
                self.origSpotLightItem = _.cloneDeep(result[0]);
                self.spotlightItem = result[0];
                self.filmId = self.spotlightItem && self.spotlightItem.filmId ? self.spotlightItem.filmId : null;
                self.filmSpotlight = self.filmId ? true : false;
                self.spotlightAssets.data = self.spotlightItem && self.spotlightItem.spotlightAssetPage ? self.spotlightItem.spotlightAssetPage : self.spotlightAssets.data;
                if (self.spotlightAssets.data.content.length > 0) {
                    self.spotlightAssets.data.number++;
                }
                if (spotlightUpdated) {
                    self.pageHeaderItem.breadcrumbItems.splice(-1, 1);
                }
                if (self.spotlightAssets.data.content.length > 0) {
                    self.filmAssetExist = _.find(self.spotlightAssets.data.content , 
                        function(a) { return a.mpmNumber && !_.includes(a.mpmNumber.toUpperCase(),"WBP360"); });
                }
            });
            self.getSpotlightAssetFilters();
            getSpotlightAssetsValuesForAssetPicker();
        };


         /**
         * Get spotlight asset filters
         * @function getSpotlightAssetFilters
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.getSpotlightAssetFilters = function () {
            spotlightDetailsService.getSpotlightAssetFilters(spotlightId, self.spotlightAssetsFilterSort.filmId, self.spotlightAssetsFilterSort.wbAssetType,
            self.spotlightAssetsFilterSort.wbpiAssetType, self.spotlightAssetsFilterSort.fileType, self.spotlightAssetsFilterSort.showAll).success(function (result) {
                self.spotlightAssetsFilterSortOptions = [{
                    type: "select",
                    options: result.data.films,
                    propertyName: "filmId"
                }, {
                    type: "select",
                    options: result.data.assetTypes,
                    propertyName: "wbAssetType"
                }, {
                    type: "select",
                    options: result.data.assetSubtypes,
                    propertyName: "wbpiAssetType"
                }, {
                    type: "select",
                    options: result.data.fileTypes,
                    propertyName: "fileType"
                }, {
                    type: "select",
                    options: self.sortOptions,
                    propertyName: "sort"
                }];

                if (user.role === "territoryAdmin" || user.role === "homeOfficeAdmin") {
                    self.spotlightAssetsFilterSortOptions.push({
                        type: "checkbox",
                        options: [{
                            value: undefined,
                            text: "Show All"
                        }],
                        propertyName: "showAll"
                    });
                }
                //Add default option
                self.spotlightAssetsFilterSortOptions[0].options.unshift({
                    text: self.translations.filter.labels.allFilms,
                    value: "ALL",
                    disabled: true
                });
                self.spotlightAssetsFilterSortOptions[1].options.unshift({
                    text: self.translations.filter.labels.allAssetTypes,
                    value: "ALL",
                    disabled: true
                });
                self.spotlightAssetsFilterSortOptions[2].options.unshift({
                    text: self.translations.filter.labels.allAssetSubTypes,
                    value: "ALL",
                    disabled: true
                });
                self.spotlightAssetsFilterSortOptions[3].options.unshift({
                    text: self.translations.filter.labels.allFileTypes,
                    value: "ALL",
                    disabled: true
                });
                self.allFilterValues = self.spotlightAssetsFilterSortOptions[4].options;
                self.filterOptions = self.getFilterOptions();
            });

        };

         /**
         * spotlight asset sorting options
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.spotlightAsssetsSortOptions = [{
            value: "spotlightId,desc",
            text: self.translations.sort.labels.newestToOldest
        }, {
            value: "spotlightId,asc",
            text: self.translations.sort.labels.oldestToNewest
        }];

         /**
         * Reset All Static Filter Models
         * @function initStaticFilters
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.initStaticFilters = function () {
            self.spotlightAssetsSortModel = {id : self.spotlightAsssetsSortOptions[0].value, label: self.spotlightAsssetsSortOptions[0].text};
        };

         /**
         * Bulk add to cart
         * @function bulkAddToCart
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @public
         * @param {object[]} downloadItems download items
         */
        self.bulkAddToCart = function (downloadItems) {
            shoppingCartService.bulkAddToCart(downloadItems).success(function () {
                $rootScope.$emit("updateShoppingCartCount", true);
                shoppingCartService.clearSelectedAssets();
                clearSelectedState();
            });
        };

        /**
         * Clear selected state
         * @function clearSelectedState
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        var clearSelectedState = function () {
            self.spotlightAssets.data.content.forEach(function (asset) {
                asset.selected = false;
            });
        };

        /**
         * Get spotlight asset filter options
         * @function getFilterOptions
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.getFilterOptions = function () {
            var multiSelectOptions = [
                {
                    type : "films",
                    id:"filmId",
                    value:"title",
                    translations:self.filmTranslations
                },
                {
                    type : "assetTypes",
                    id:"wbAssetType",
                    translations:self.assetTypeTranslations
                },
                {
                    type : "subTypes",
                    id:"wbpiAssetType",
                    translations:self.subTypeTranslations
                },
                {
                    type : "fileTypes",
                    id:"fileType",
                    translations:self.fileTypeTranslations
                },
                {
                    type : "sort",
                    options: self.spotlightAsssetsSortOptions,
                    model:self.spotlightAssetsSortModel,
                    translations:self.sortTypeTranslations
                }];

            for (var filterIndex = 0; filterIndex < multiSelectOptions.length; filterIndex++) {
                if (multiSelectOptions[filterIndex].type !== "sort" && multiSelectOptions[filterIndex].type !== "modifiedInterval") {
                    multiSelectOptions[filterIndex].data = self.spotlightAssetsFilterSortOptions[filterIndex];
                    //multiSelectOptions[filterIndex].activeTabIndex = self.activeTabIndex;
                }
            }
            if (user.role === "territoryAdmin" || user.role === "homeOfficeAdmin") {
                multiSelectOptions.push(self.spotlightAssetsFilterSortOptions[5]);
            }

            return multiSelectOptions;
        };

       /**
         * Disabled bulk add to queue btn
         * @function disableBulkAddToQueueBtn
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @public
         * @returns {boolean}
         */
        self.disableBulkAddToQueueBtn = function () {
            var isSelected = false;
            if (self.spotlightAssets.data && self.spotlightAssets.data.content) {
                isSelected = _.findIndex(self.spotlightAssets.data.content, {
                    selected: true
                }) >= 0;
            }
            return !isSelected;
        };

        /**
         * Spotlight assets
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.spotlightAssets = {
            data: {
                size: DEFAULT_PAGE_SIZE,
                number: 1,
                content : []
            }
        };

        /**
         * takes the user to other page
         * @function go
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @param {string} linkTo link to the other page
         */
        self.go = function(linkTo) {
            $window.location.href = linkTo;
        };

        /**
         * Open delete Spotlight modal
         * @function openAddEditSpotlightModal
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @param {object} SpotlightItem Spotlight item
         */
        self.openDeleteSpotlightModal = function(spotlightItem) {
            $uibModal.open({
                templateUrl: "/modules/deleteSpotlightModal/modalDeleteSpotlight.html",
                controller: "modalDeleteSpotlightController",
                controllerAs: "deleteSpotlightModalCtrl",
                keyboard: false,
                backdrop: "static",
                resolve: {
                    spotlightItem: function() {
                        return spotlightItem;
                    }
                }
            });
        };

        /**
         * Update default Image
         * @function updateDefaultImage
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @param {object} SpotlightItem Spotlight item
         */
        self.updateDefaultImage = function(spotlightItem) {
            spotlightDetailsService.removeDefaultImage(spotlightItem.id).success(function () {
                self.init();
            });
        };

        /**
         * Remove Asset
         * @function removeAsset
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @public
         */
        self.removeAsset = function() {
            self.deletedAssets = [];
            self.spotlightAssets.data.content.forEach(function(asset) {
                if (asset.selected) {
                    self.deletedAssets.push(asset);
                }
            });
            if (self.deletedAssets.length > 0) {
                var errorMessage = "Error while removing the assets from spotlights";
                spotlightDetailsService.deleteSpotlightAssets(spotlightId, self.deletedAssets, errorMessage).success(function () {
                    self.updateFlag = true;
                    // if everything is removed from the last page then reset to the prev page
                    if (self.spotlightAssets.data.number === self.spotlightAssets.data.totalPages
                        && self.spotlightAssets.data.size === self.deletedAssets.length) {
                        self.spotlightAssets.data.number = self.spotlightAssets.data.number === 1 ? self.spotlightAssets.data.number : self.spotlightAssets.data.number--;
                    }
                    self.getSpotlightDetails();
                });
            }
        };

        /**
         * Disabled remove btn
         * @function disableRemoveBtn
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @public
         * @returns {boolean}
         */
        self.disableRemoveBtn = function () {
            var isSelected = false;
            if (self.spotlightAssets.data && self.spotlightAssets.data.content) {
                isSelected = _.findIndex(self.spotlightAssets.data.content, {
                    selected: true
                }) >= 0;
            }
            return !isSelected;
        };
        /**
         * scroll top on tab change
         * @function scrollPosition
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @public
         */
        self.scrollPosition = function() {
            var tabContent = angular.element(document.querySelector( ".modal-edit-film .tab-content" ));
            tabContent.scrollTop(0);
        };


       /**
         * Get Spotlight Assets values for asset picker
         * @function getSpotlightAssetsValuesForAssetPicker
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        var getSpotlightAssetsValuesForAssetPicker = function () {
            assetPickerService.getFiltersValuesForAssetPicker(spotlightId, self.spotlightAssetsFilterSort.filmId, self.spotlightAssetsFilterSort.wbAssetType, self.spotlightAssetsFilterSort.wbpiAssetType, self.spotlightAssetsFilterSort.fileType).success(function (result) {
                self.spotlightAssetInfo  = _.find(result.data.films, {
                    text: "SPOTLIGHT"
                });
            });

        };

        /**
         * Open asset picker modal
         * @function openAssetPickerModal
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.openAssetPickerModal = function() {
            var modelAssets = self.spotlightItem && self.spotlightItem.selectedAssets ? self.spotlightItem.selectedAssets : [];
            var filmId = [];
            if(self.spotlightItem && self.spotlightItem.filmId){
                filmId =[self.spotlightItem.filmId];
            }
            if(self.spotlightAssetInfo && self.spotlightAssetInfo.value) {
                filmId.push(self.spotlightAssetInfo.value);
            }
            var modalInstance = $uibModal.open({
                templateUrl: "/modules/assetPickerModal/modalAssetPicker.html",
                controller: "modalAssetPickerController",
                controllerAs: "assetPickerModalCtrl",
                keyboard: false,
                backdrop: "static",
                size: "xlg",
                resolve: {
                    spotlightId: function() {
                        return spotlightId;
                    },
                    selectedAssets: function () {
                        return modelAssets;
                    },
                    filmId: function () {
                        return filmId;
                    }
                }
            });

            modalInstance.result.then(function(selectedAssets) {
                self.spotlightItem.selectedAssets = selectedAssets;
            });
        };

        /**
         * Open delete Spotlight modal
         * @function openAddEditSpotlightModal
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @param {object} SpotlightItem Spotlight item
         */
        self.openDeleteSpotlightModal = function(spotlightItem) {
            $uibModal.open({
                templateUrl: "/modules/deleteSpotlightModal/modalDeleteSpotlight.html",
                controller: "modalDeleteSpotlightController",
                controllerAs: "deleteSpotlightModalCtrl",
                keyboard: false,
                backdrop: "static",
                resolve: {
                    spotlightItem: function() {
                        return spotlightItem;
                    }
                }
            });
        };


        /** setEditScroll
         * @function setPageScroll
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.setEditScroll = function () {
            $location.hash("spotlightTitle");
            var titleElement = document.getElementById("spotlightTitle");
            var angularTitleElement = angular.element(titleElement);
            angularTitleElement.focus();
            $anchorScroll();
        };

       /**
          * tab change event
          * @function tabDeSelected
          * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
          * @public
          */
        self.tabDeSelected = function(tab, $event) {
            if (tab && tab.heading === self.tabs[0].heading && self.addEditSpotlightForm.$dirty
                && $event.currentTarget && $event.currentTarget.innerText === self.tabs[1].heading) {
                /*return openSpotlightEditConfirmationModal($event).then(function (res) {
                    $q.resolve(res);
                });*/
                var answer = confirm(self.translations.spotlight.messages.editConfirmationModalErrorMessage);
                if (!answer) {
                    $event.preventDefault();
                    $event.currentTarget.blur();
                } else {
                    self.spotlightItem = _.cloneDeep(self.origSpotLightItem);
                    self.addEditSpotlightForm.$setPristine();
                }
            }
        };

        /**
         * open openSpotlight Edit confirmation modal
         * @function openSpotlightEditConfirmationModal
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        /*var openSpotlightEditConfirmationModal = function ($event) {
            var confModalInstance = $uibModal.open({
                templateUrl: "confirmationTemplate.html",
                controller: "modalConfirmationController",
                controllerAs: "modalConfirmationCtrl",
                keyboard: false,
                backdrop: "static",
                size: "md",
                resolve: {
                    message: function () {
                        return "You have pending changes! Are you sure to discard and continue to the Materials Tab? ";
                    },
                    headerTitle: function () {
                        return self.translations.adminRegisterProfile.labels.adduserConfirmation;
                    },
                    showAffirmativeBtn: function () {
                        return true;
                    },
                    affirmativeBtnText: function () {
                        return self.translations.global.labels.yesButton;
                    },
                    cancelBtnText: function () {
                        return self.translations.global.labels.noButton;
                    }
                }
            });
            return confModalInstance.result.then(function () {
                self.spotlightItem = _.cloneDeep(self.origSpotLightItem);
                self.addEditSpotlightForm.$setPristine();
            }, function () {
                $event.preventDefault();
                $event.currentTarget.blur();
            });
        };*/

        /**
         * tab menus
         * @type {array}
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @public
         */
        self.tabs = [{
            heading: self.translations.spotlight.labels.spotlightDetails,
            templateUrl: "/modules/spotlight/detailsTab/spotlightDetailsTab.html",
            active: false,
            key: "spotlight.details",
            disabled: false
        }, {
            heading: self.translations.spotlight.labels.materials,
            templateUrl: "/modules/spotlight/materialsTab/spotlightMaterials.html",
            propertyName: "assets",
            active: false,
            key: "spotlight.assets",
            disabled: true
        }];

        /**
         * Select asset to set a default onesheet and trailer
         * @function selectAsset
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @param {string} propertyName onesheet or trailerId
         * @param {integer} assetId id of the asset
         */
        self.selectAsset = function (propertyName, assetId) {
            self.filmDetails[propertyName] = assetId;
        };
        
        /**
         * scroll top on tab change
         * @function scrollPosition
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @public
         */
        self.scrollPosition = function() {
            var tabContent = angular.element(document.querySelector( ".modal-edit-film .tab-content" ));
            tabContent.scrollTop(0);
        };

        self.setSpotlightAssets = function (selectedAssets) {
            var addedAssets = [];
            selectedAssets.forEach(function(asset) {
                var existing = _.find(self.spotlightAssets.data.content, { id : asset.id});
                if (!existing) {
                    //self.spotlightAssets.data.content.push(asset);
                    addedAssets.push(asset);
                }
            });
            if (addedAssets.length > 0) {
                var errorMessage = "Error while updating spotlights";
                if (self.isNew) {
                    var now = moment().utc().format();
                    self.spotlightItem.creationDate = now;
                    self.spotlightItem.lastModified = now;
                    self.spotlightItem.modifier = user.userId;
                    self.spotlightItem.spotlightAssets = addedAssets;
                }
                spotlightDetailsService.addSpotlightAssets(self.spotlightItem, self.isNew, spotlightId, addedAssets, errorMessage).success(function (result) {
                    spotlightId = self.isNew ? result.data.spotlightItem.id : spotlightId;
                    self.isNew = false;
                    addedAssets = [];
                    self.updateFlag = true;
                    self.init(true);
                });
            }
        };

        /**
         * Cancel
         * @function cancel
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         * @private
         */
        var cancel = function () {
            self.isEditMode = false;
        };

        /**
         * Cnacel edit user info
         * @function cancel
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.cancel = function () {
            var modalInstance = $uibModal.open({
                templateUrl: "confirmationTemplate.html",
                controller: "modalConfirmationController",
                controllerAs: "modalConfirmationCtrl",
                keyboard: false,
                backdrop: "static",
                size: "md",
                resolve: {
                    message: function () {
                        return self.translations.userInfo.messages.cancelationWarning;
                    },
                    headerTitle: function () {
                        return self.translations.userInfo.messages.warning;
                    },
                    showAffirmativeBtn: function () {
                        return true;
                    },
                    affirmativeBtnText: function () {
                        return self.translations.userInfo.labels.yesBtn;
                    },
                    cancelBtnText: function () {
                        return self.translations.userInfo.labels.noBtn;
                    }
                }
            });
            modalInstance.result.then(function () {
                cancel();
                self.spotlightItem = _.cloneDeep(self.origSpotLightItem);
            });
        };

        /**
         * Select All Filters
         * @function filterSelectAll
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.filterSelectAll = function () {
            self.spotlightAssetsFilterSort.filmId = [];
            self.spotlightAssetsFilterSort.wbAssetType = [];
            self.spotlightAssetsFilterSort.wbpiAssetType = [];
            self.spotlightAssetsFilterSort.fileType = [];
            self.spotlightAssetsFilterSort.showAll = false;
            self.getSpotlightDetails();
        };

        $rootScope.$on("UpdateParentSpotlight", function () {
            self.init();
            $window.scrollTo(0, 0);
        });

        var pushAssetsListener = $rootScope.$on("updateSpotlightAssets", function ($event, assets) {
            self.setSpotlightAssets(assets);
        });

        $scope.$on("$destroy", function() {
            pushAssetsListener();
        });

        /**
         * clear film dropdown when checkbox not selected 
         * @function clearSelectedFilm
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.clearSelectedFilm = function () {
            if(!self.filmSpotlight){
                self.spotlightItem.filmId = null;
                self.spotlightItem.filmTitle = null;
            }
        };
        /**
         * Save
         * @function publish
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.publish = function () {
            var saveEndpoint = null;
            var spotlightItemSaveCopy = _.cloneDeep(self.spotlightItem);
            if(!self.isNew && spotlightItemSaveCopy.filmId
                && (self.filmId === null || self.filmId !== spotlightItemSaveCopy.filmId)
                && (self.spotlightAssets.data.content.length > 0 && self.filmAssetExist)) {
                var modalInstance = $uibModal.open({
                    templateUrl: "confirmationTemplate.html",
                    controller: "modalConfirmationController",
                    controllerAs: "modalConfirmationCtrl",
                    keyboard: false,
                    backdrop: "static",
                    size: "md",
                    resolve: {
                        message: function () {
                            return self.translations.spotlight.messages.spotlightEditConfirmationMessage;
                        },
                        headerTitle: function () {
                            return self.translations.userInfo.messages.warning;
                        },
                        showAffirmativeBtn: function () {
                            return true;
                        },
                        affirmativeBtnText: function () {
                            return self.translations.userInfo.labels.yesBtn;
                        },
                        cancelBtnText: function () {
                            return self.translations.userInfo.labels.noBtn;
                        }
                    }
                });
                modalInstance.result.then(function () {
                    saveEndpoint = spotlightDetailsService.update(spotlightItemSaveCopy);
                    postPublish(saveEndpoint, spotlightItemSaveCopy);
                });
            }
            else if ((self.addEditSpotlightForm.$dirty ||  self.updateFlag) && self.addEditSpotlightForm.$valid) {
                saveEndpoint = self.isNew ? spotlightDetailsService.create(spotlightItemSaveCopy) : spotlightDetailsService.update(spotlightItemSaveCopy);
                postPublish(saveEndpoint, spotlightItemSaveCopy);
            }
        };

        $rootScope.$on("assetTypeUpdated", function () {
            self.init();
        });

        /*
         * common function for save and update
         * @function postPublish
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        var postPublish = function (saveEndpoint, spotlightItemSaveCopy) {
            var now = moment().utc().format();
            spotlightItemSaveCopy.creationDate = self.isNew ? now : spotlightItemSaveCopy.creationDate;
            spotlightItemSaveCopy.lastModified = now;
            delete spotlightItemSaveCopy.film;
            spotlightItemSaveCopy.modifier = user.userId;
            spotlightItemSaveCopy.spotlightAssets = self.spotlightAssets.data && self.spotlightAssets.data.content ? self.spotlightAssets.data.content : [];
            saveEndpoint.success(function (res) {
            //$rootScope.$emit("UpdateParentSpotlight");
                self.IsVisible = true;
                if (self.isNew) {
                    self.isNew = false;
                    if (res && res.data && res.data.spotlightItems && res.data.spotlightItems.id) {
                        $window.location.href = "/spotlight/" + res.data.spotlightItems.id;
                    }
                }
                self.isNew = false;
                self.origSpotLightItem = _.cloneDeep(res.data.spotlightItems);
                self.getSpotlightDetails();
                self.addEditSpotlightForm.$setPristine();
            });
        };

        /*
         * Get film titles
         * @function getFilmTitles
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        var getFilmTitles = function () {
            spotlightDetailsService.getFilmTitles().success(function (result) {
                self.filmTitles = result._embedded.films;
            
            });
        };
        /**
         * Init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.spotlightDetailsController
         */
        self.init = function() {
            getFilmTitles();
            self.spotlightAssetsFilterSort = {
                filmId: [],
                sort: "spotlightId,desc",
                wbAssetType: [],
                wbpiAssetType: [],
                fileType: [],
                showAll: "false"
            };
            self.initStaticFilters();
            if (spotlightId !== 0)  {
                self.getSpotlightDetails();
            }
        };

        //INIT
        self.init();
    }

    controllers.controller("spotlightDetailsController", ["$scope", "$rootScope", "$uibModal", "$window", "$cookies", "$location", "$anchorScroll", "$q", "spotlightDetailsService", "assetPickerService", "localStorageService", "shoppingCartService", "API_EFD", "DEFAULT_PAGE_SIZE", "tinymceService", spotlightDetailsController]);
}());
