(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Password rules validator form directive
     * @class passwordRulesValidator
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     * @param {service} passwordService password service
     */
    function passwordRulesValidator(passwordService) {

        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @param {jqueryElement} element jquery element
         * @param {object} attrs attributes
         * @param {object} ngModel ng model
         * @private
         * @memberOf angular_module.WB.cinehub.directives.passwordRulesValidator
         */
        var link = function (scope, element, attrs, ngModel) {
            ngModel.$validators.integer = function(modelValue, viewValue) {
                var INTEGER_REGEXP = /[\d]/;
                if (ngModel.$isEmpty(modelValue)) {
                    return true;
                }
                if (INTEGER_REGEXP.test(viewValue)) {
                    return true;
                }
                return false;
            };
            ngModel.$validators.spl = function(modelValue, viewValue) {
                var SPL_REGEXP = /[\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:]/;
                if (ngModel.$isEmpty(modelValue)) {
                    return true;
                }
                if (SPL_REGEXP.test(viewValue)) {
                    return true;
                }
                return false;
            };
            ngModel.$validators.upperCase = function(modelValue, viewValue) {
                var UPPER_CASE_REGEXP = /[A-Z]/;
                if (ngModel.$isEmpty(modelValue)) {
                    return true;
                }
                if (UPPER_CASE_REGEXP.test(viewValue)) {
                    return true;
                }
                return false;
            };
            ngModel.$validators.lowerCase = function(modelValue, viewValue) {
                var LOWER_CASE_REGEXP = /[a-z]/;
                if (ngModel.$isEmpty(modelValue)) {
                    return true;
                }
                if (LOWER_CASE_REGEXP.test(viewValue)) {
                    return true;
                }
                return false;
            };
            ngModel.$validators.minChars = function(modelValue, viewValue) {
                var minimumPasswordLength = 12;
                if (ngModel.$isEmpty(modelValue)) {
                    return true;
                }
                if (viewValue.length < minimumPasswordLength ) {
                    return false;
                } else {
                    return true;
                }
            };
            ngModel.$validators.passwordContainsUsername = function (modelValue, viewValue) {
                if (ngModel.$isEmpty(modelValue)) {
                    // consider empty models to be valid
                    return true;
                }
                var firstName = attrs.firstName ? attrs.firstName : attrs.firstname;
                var lastName = attrs.lastName ? attrs.lastName : attrs.lastname;
                return !passwordService.passwordUsernameError(viewValue, attrs.email, firstName, lastName);
            };
        };
        
        return {
            require: "ngModel",
            link: link
        };
    }

    directives.directive("passwordRulesValidator", ["passwordService", passwordRulesValidator]);
}());
