(function ($) {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Material details lightbox directive
     * @class materialDetailsLightboxDirective
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     */
    function materialDetailsLightboxDirective() {

        /**
         * Material details controller
         * @dec The material details controller
         * @class materialDetailsController
         * @memberOf angular_module.WB.cinehub.controllers
         * @param {!angular.rootScope} $rootScope angular root scope
         * @param {!angular.scope} $scope angular scope
         * @param {!angular.$q} $q $q
         * @param {$window} $window window instance
         * @param {$location} $location window location
         * @param {$uibModal} $uibModal uibModal instance
         * @param {materialDetailsService} materialDetailsService material details instance
         * @param {factory} filmUrlGeneratorFactory fulm url generator factory
         * @param {service} shoppingCartService add remove asset cart service
         * @param {constant} API_EFD API_EFD
         * @param {service} restrictService restrict service
         * @param {service} permissionService permission service
         * @param {service} convertLocalizationCodeToValueService conver localization code to value service
         * @param {service} permissionQueueService permission queue service
         * @param {service} exhibitorsService exhibitors service
         * @param {constant} AUDIO_VIDEO_ASSET_CATEGORY
         * @param {service} videoSourceGeneratorService video source helpers
         * @param {service} assetCategoryConvertor asset category convertor
         * @param {service} activityHistoryService activity history service
         */
        var materialDetailsController = ["$rootScope",
            "$scope",
            "$q",
            "$window",
            "$location",
            "$uibModal",
            "materialDetailsService",
            "localStorageService",
            "filmUrlGeneratorFactory",
            "shoppingCartService",
            "API_EFD",
            "restrictService",
            "permissionService",
            "convertLocalizationCodeToValueService",
            "permissionQueueService",
            "exhibitorsService",
            "deviceDetectorService",
            "AUDIO_VIDEO_ASSET_CATEGORY",
            "videoSourceGeneratorService",
            "assetCategoryConvertor",
            "activityHistoryService",
            "getRenditionsService",
            "PERMISSIONS",
            function ($rootScope,
                $scope,
                $q,
                $window,
                $location,
                $uibModal,
                materialDetailsService,
                localStorageService,
                filmUrlGeneratorFactory,
                shoppingCartService,
                API_EFD,
                restrictService,
                permissionService,
                convertLocalizationCodeToValueService,
                permissionQueueService,
                exhibitorsService,
                deviceDetectorService,
                AUDIO_VIDEO_ASSET_CATEGORY,
                videoSourceGeneratorService,
                assetCategoryConvertor,
                activityHistoryService,
                getRenditionsService,
                PERMISSIONS) {

                /**
                 * Instance of materialDetailsController
                 * @type {materialDetailsController}
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                var self = this,

                    /**
                     * exhibitors
                     * @type {object[]}
                     * @private
                     * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                     */
                    exhibitors = [],

                    /**
                     * Permissions
                     * @type {object[]}
                     * @private
                     * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                     */
                    permissions = [],

                    /**
                     * admin user permission data created flag
                     * @type {boolean}
                     * @private
                     * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                     */
                    adminUserPermissionDataCreated = false,



                    /**
                     * Location
                     * @type {object}
                     * @public
                     * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                     */
                    location = localStorageService.get("location");

                /**
                 * User info
                 * @type {object}
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.user = localStorageService.get("userInfo");
                /**
                 * exhibitor id
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @private
                 * @type {int}
                 */
                self.exhibitorId = localStorageService.get("currentExhibitorAffiliation") || -1;

                /**
                 * theater id
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @private
                 * @type {int}
                 */
                self.theaterId = localStorageService.get("currentTheaterAffiliation") || -1;
                /**
                 * view As
                 * @type {object[]}
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @public
                 */
                self.viewAs = localStorageService.get("impersonateUserFlag");
                /**
                 * API_EFD
                 * @constant
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @private
                 * @type {string}
                 */
                self.API_EFD = API_EFD;

                /**
                 * AUDIO_VIDEO_ASSET_CATEGORY
                 * @constant
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @public
                 * @type {string}
                 */
                self.AUDIO_VIDEO_ASSET_CATEGORY = AUDIO_VIDEO_ASSET_CATEGORY;

                /**
                 * PERMISSIONS
                 * @constant
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @public
                 * @type {object}
                 */
                self.PERMISSIONS = PERMISSIONS;

                /**
                 * Action appears to happen on the asset edit or publish
                 * @type {string}
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.action = "view";

                /**
                 * isAssetTypeUpdated is subtype changes
                 * @type {string}
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.isAssetTypeUpdated = false;
                /**
                 * subTypes
                 * @type {object[]}
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.subTypes = [];
                /**
                 * mmx id
                 * @type {int}
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @public
                 */
                self.mmxId = 0;

                /**
                 * Film url generator
                 * @public
                 * @memberOf angular_module.WB.cinehub.directives.materialDetailsController
                 * @type {factory}
                 */
                self.filmUrlGenerator = filmUrlGeneratorFactory;

                /**
                 * add shopping cart service
                 * @public
                 * @memberOf angular_module.WB.cinehub.directives.materialDetailsController
                 * @type {service}
                 */
                self.shoppingCartService = shoppingCartService;

                /**
                 * asset category convertor
                 * @public
                 * @memberOf angular_module.WB.cinehub.directives.materialDetailsController
                 * @type {service}
                 */
                self.assetCategoryConvertor = assetCategoryConvertor;

                /**
                 * Convert localization code to value service
                 * @public
                 * @memberOf angular_module.WB.cinehub.directives.materialDetailsController
                 * @type {service}
                 */
                self.convertLocalizationCodeToValueService = convertLocalizationCodeToValueService;

                /**
                 * Edit mode flag for details tab
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @type {bool}
                 */
                self.isEditMode = false;

                /**
                 * is asset added to the cart
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @type {bool}
                 */
                self.isAssetAddedToShoppingCart = false;

                /**
                 * Translations
                 * @type {object}
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.translations = localStorageService.get("translations");

                /**
                 * tab menus
                 * @type {array}
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @public
                 */
                self.tabs = [{
                    heading: self.translations.materialDetails.labels.detailsTab,
                    accessGranted: true,
                    name: "details",
                    route: "materialDetails",
                    templateUrl: "/shared/materialDetailsLightbox/detailsTab/detailsTab.html"
                }, {
                    heading: self.translations.materialDetails.labels.permissionsTab,
                    accessGranted: restrictService.getAccessGrant(["standard"]) && !deviceDetectorService.isMobileOrTablet(),
                    name: "permissions",
                    route: "materialPermissions",
                    templateUrl: "/shared/materialDetailsLightbox/permissionTab/permissionTab.html"
                }, {
                    heading: self.translations.materialDetails.labels.activityHistoryTab,
                    accessGranted: true,
                    name: "activityHistory",
                    route: "materialActivityHistory",
                    templateUrl: "/shared/materialDetailsLightbox/activityHistoryTab/activityHistoryTab.html"
                }];

                /**
                 * Active tab index
                 * @type {int}
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.activeTabIndex = 0;

                /**
                 * calendar options
                 * @type {array}
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @public
                 */
                self.calendarOpenState = {
                    startDate: false,
                    endDate: false
                };

                /**
                 * Video Player auto play
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.videoAutoPlay = true;

                /**
                 * open share asset information modal
                 * @function openShareAssetInformaitonModal
                 * @private
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                var openShareAssetInformaitonModal = function () {
                    var WEB_ROOT_URL = $location.protocol() + "://" + $location.host() + ($location.port() == 80 || $location.port() == 443 ? "" : ":" + $location.port());
                    var assetUrl = WEB_ROOT_URL + "/materials/" + self.asset.id + "/" + self.asset.mmxId + "/view/";
                    $uibModal.open({
                        templateUrl: "assetShareTemplate.html",
                        controller: "modalConfirmationController",
                        controllerAs: "modalConfirmationCtrl",
                        keyboard: false,
                        backdrop: "static",
                        size: "md",
                        resolve: {
                            message: function () {
                                return assetUrl;
                            },
                            headerTitle: function () {
                                return self.translations.assetShare.popupHeader;
                            },
                            showAffirmativeBtn: function () {
                                return false;
                            },
                            affirmativeBtnText: function () {
                                return "";
                            },
                            cancelBtnText: function () {
                                return self.translations.cart.labels.closeButton;
                            }
                        }
                    });
                };


                /**
                 * options for start date
                 * @type {array}
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @public
                 */
                self.startDateOptions = {
                    showWeeks: false,
                    minMode: "day",
                    maxDate: self.asset ? self.asset.availabilityEndDate : null
                };

                /**
                 * options for end date
                 * @type {array}
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @public
                 */
                self.endDateOptions = {
                    showWeeks: false,
                    minMode: "day",
                    minDate: self.asset ? self.asset.availabilityStartDate : null
                };

                /**
                 * Asset
                 * @type {object}
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.asset = {};

                /**
                 * Open lightbox flag
                 * @type {boolean}
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                $scope.openLightbox = false;

                /**
                 * Is card reveled flag
                 * @type {boolean}
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.isCardRevealed = true;
                
                
                /**
                 * Is Asset Details Loaded flag
                 * @type {boolean}
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.isAssetDetailsLoaded = false;

                /**
                 * permissions
                 * @type {object[]}
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.permissions = [];

                /**
                 * No saved permissions flag
                 * @type {boolean}
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.noSavedPermissions = false;

                /**
                 * Videogular player config
                 * @public
                 * @type {object}
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.videogularPlayerConfig = {
                    nativeFullscreen: true,
                    autoPlay: true,
                    preload: "none",
                    controls: false,
                    sources: null,
                    plugins: {
                        poster: ""
                    }
                };

                /**
                 * Eis new updated fields
                 * @public
                 * @type {array}
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.eisNewUpdatedFields = [];

                /**
                 * Videogular  player api
                 * @type {object}
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.videogularPlayerAPI = null;

                /**
                 * Determines whether the user's device is a mobile or a tablet
                 * @public
                 * @function isMobileOrTablet
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.isMobileOrTablet = deviceDetectorService.isMobileOrTablet;

                 /**
                 * Show flash player
                 * @public
                 * @type {boolean}
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.showFlashPlayer = false;
                $scope.displayPopover =false;
                
                /**
                 * Show File Name
                 * @public
                 * @type {Object}
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.pubFileName = "";

                /**
                 * Show Asset Type
                 * @public
                 * @type {Object}
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.pubAssetType = "";
                /**
                 * Orig File name
                 * @public
                 * @type {Object}
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.origAssetFileName = "";
                /**
                 * Orig Asset Type
                 * @public
                 * @type {Object}
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.origAssetType = "";
                /**
                 * OrigSub  Asset Type
                 * @public
                 * @type {Object}
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.origSubAssetType = "";
                /**
                 * Show Asset Type
                 * @public
                 * @type {Object}
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.pubSubAssetType = "";
                /**
                 * File name extension
                 * @public
                 * @type {Object}
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.fileExtension = "";



                /**
                 * Remove tab based on tab name
                 * @function removeTab
                 * @private
                 * @param {string} tabName tab name
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                var removeTab = function (tabName) {
                    var permissionTabIndex = _.findIndex(self.tabs, {
                        name: tabName
                    });
                    if (permissionTabIndex !== -1) {
                        self.tabs.splice(permissionTabIndex, 1);
                    }
                };

                /**
                 * get asset types
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @function getAssetTypes
                 * @param {string} keyword search term
                 * @public
                 */
                self.getAssetTypes = function (keyword) {
                    return materialDetailsService.getAssetTypes(keyword);
                };
                /**
                 * Set asset properties
                 * @function setAssetProps
                 * @private
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                var setAssetProps = function () {
                    if (self.asset) {
                        self.asset.available = self.asset.available === "false" ? false : self.asset.available === "true" ? true : false;
                        self.asset.availabilityStartDate = self.asset.availabilityStartDate ? new Date(moment(self.asset.availabilityStartDate).format()) : "";
                        self.asset.availabilityEndDate = self.asset.availabilityEndDate ? new Date(moment(self.asset.availabilityEndDate).format()) : "";
                        self.pubAssetType = self.asset.wbAssetType;
                        self.pubSubAssetType = self.asset.wbpiAssetType;
                        self.setAssetFileName(self.asset);
                        self.origAssetFileName = self.pubFileName;
                        self.origAssetType = self.pubAssetType;
                        self.origSubAssetType = self.pubSubAssetType;
                        self.originalFilmAsset = _.cloneDeep(self.asset);

                        if (self.asset.status && self.asset.status.toLowerCase() === "killed") {
                            removeTab("permissions");
                        }
                    }
                };

                  /**
                  * Set asset file name for edit mode
                  * @function setassetFileName
                  * @private
                  * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                  */
                self.setAssetFileName = function (asset) {
                    var filenameArray = asset.fileName.split(".");
                    self.fileExtension = filenameArray[filenameArray.length - 1];
                    filenameArray.pop();
                    self.pubFileName = "";
                    filenameArray.forEach(function (index) {
                        if (self.pubFileName === "") {
                            self.pubFileName = index;
                        } else {
                            self.pubFileName = self.pubFileName + "." + index;
                        }
                    });
                };

                /**
                 * Get asset MARS orig data
                 * @function getAssetMarsOrig
                 * @private
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @returns {promise}
                 */
                var getAssetMarsOrig = function () {
                    return materialDetailsService.getAssetOrig(self.mmxId).success(function (result) {
                        if (result._embedded.filmAssets && result._embedded.filmAssets.length) {
                            self.sourceAsset = result._embedded.filmAssets[0];
                        }
                    });
                };

                /**
                 * Get asset territory orig data
                 * @function getAssetTerritoryOrig
                 * @private
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @returns {promise}
                 */
                var getAssetTerritoryOrig = function () {
                    if (_.isEmpty(self.asset) ) {
                        return materialDetailsService.getAssetOrig(self.mmxId, location.territoryId, location.localeId).success(function (result) {
                            if (result._embedded.filmAssets && result._embedded.filmAssets.length) {
                                self.asset = result._embedded.filmAssets[0];
                            } else {
                                self.asset = _.cloneDeep(self.sourceAsset);
                            }

                        });
                    } else {
                        return $q.defer().resolve();
                    }
                };

                /**
                 * Get activity history
                 * @function getActivityHistory
                 * @private
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @returns {promise}
                 */
                var getActivityHistory = function () {
                    var lastModified = moment(self.asset.lastModified).utc().format("YYYY-MM-DD HH:mm:ss");
                    activityHistoryService.getEisUpdatedFields("film_asset", self.asset.id, lastModified).success(function (eisNewUpdatedFields) {
                        self.eisNewUpdatedFields = eisNewUpdatedFields;
                    });
                };

                /**
                 * Get asset pub data
                 * @function getAssetPub
                 * @private
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                var getAssetPub = function () {
                    return materialDetailsService.getAssetPub(self.mmxId).success(function (result) {
                        if (self.user.role.indexOf("Admin") !== 0) {
                            self.isAssetDetailsLoaded = true;
                        }
                        if (result._embedded.filmAssetDownloadViews && result._embedded.filmAssetDownloadViews.length) {
                            self.asset = result._embedded.filmAssetDownloadViews[0];
                            self.isAssetDetailsLoaded = true;
                            if (self.asset.assetCategory && self.asset.fileTypeCategory && self.asset.fileTypeCategory.toUpperCase() === "VIDEO"
                                    && (!self.videogularPlayerConfig.sources || _.isEmpty(self.videogularPlayerConfig.sources[0]))) {
                                self.videogularPlayerConfig.sources = videoSourceGeneratorService.getSource(self.asset.id, self.asset.fileName);
                            }
                        }
                        if($(".angular-lightbox section > content img").height() > 450){
                            $(".imageSection").addClass("imageHeight"); 
                        }   
                    }, {
                        errorMessage: "error",
                        showSpinner: true
                    });
                    
                };

                /**
                 * Reset form
                 * @function resetForm
                 * @private
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                var resetForm = function () {
                    self.editAssetForm.$setPristine();
                    self.isEditMode = !self.isEditMode;
                    self.asset = _.cloneDeep(self.originalFilmAsset);
                    self.pubFileName = "";
                    self.pubAssetType = "";
                    self.pubSubAssetType = "";
                    self.subTypes = [];
                    self.isAssetTypeUpdated = false;
                };

                /**
                 * check unsaved changes
                 * @function checkUnsavedChanges
                 * @private
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @param {string} action action
                 */
                var checkUnsavedChanges = function (action) {
                    if (self.editAssetForm && self.editAssetForm.$dirty) {
                        var modalInstance = $uibModal.open({
                            templateUrl: "confirmationTemplate.html",
                            controller: "modalConfirmationController",
                            controllerAs: "modalConfirmationCtrl",
                            keyboard: false,
                            backdrop: "static",
                            size: "md",
                            resolve: {
                                message: function () {
                                    return self.translations.global.messages.confirmationMessage;
                                },
                                headerTitle: function () {
                                    return self.translations.userInfo.messages.warning;
                                },
                                showAffirmativeBtn: function () {
                                    return true;
                                },
                                affirmativeBtnText: function () {
                                    return self.translations.userInfo.labels.yesBtn;
                                },
                                cancelBtnText: function () {
                                    return self.translations.userInfo.labels.noBtn;
                                }
                            }
                        });

                        modalInstance.result.then(function () {
                            if (action === "cancel") {
                                resetForm();
                            } else {
                                self.closeLightbox();
                            }
                        });
                    } else {
                        if (action === "cancel") {
                            resetForm();
                        } else {
                            self.closeLightbox();
                        }
                    }

                };

                /**
                 * Create home office user permission data
                 * @function createHomeOfficeUserPermissionData
                 * @private
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @param {object[]} permissions asset permissions
                 * @returns {object[]}
                 */
                var createHomeOfficeUserPermissionData = function (permissions) {
                    var regions = permissionService.buildRegionPermissionsJson(),
                        noSavedPermissions = true;

                    permissions.forEach(function (permission) {
                        permission.value = convertLocalizationCodeToValueService.convert("territories", permission.territoryId);
                    });

                    regions.forEach(function (region) {
                        region.permissions.forEach(function (permission) {
                            var permissionObj = _.find(permissions, {
                                territoryId: permission.code
                            });
                            if (permissionObj) {
                                noSavedPermissions = false;
                                permission.permission = permissionObj.permission;
                            } else {
                                permission.permission = "NOT_DEFINED";
                            }
                        });
                        region.permissions = _.groupBy(region.permissions, "permission");
                        region.open = true;
                    });

                    self.noSavedPermissions = noSavedPermissions;

                    return regions;
                };

                /**
                 * Create territory admin user permission data
                 * @function createTerritoryAdminUserPermissionData
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @private
                 */
                var createTerritoryAdminUserPermissionData = function () {
                    window.console.time("createTerritoryAdminUserPermissionData");
                    var isPermissioned = permissions.length > 1,
                        exhibitorsIndex = 0,
                        noSavedPermissions = true,
                        exhibitorsLength = exhibitors.length;
                    for (exhibitorsIndex; exhibitorsIndex < exhibitorsLength; exhibitorsIndex++) {
                        var exhibitor = exhibitors[exhibitorsIndex];
                        exhibitor.childCount = exhibitor.theaters.length;
                        exhibitor.permissions = [];
                        if (isPermissioned) {
                            var permissionsIndex = 0,
                                permissionsLength = permissions.length;
                            for (permissionsIndex; permissionsIndex < permissionsLength; permissionsIndex++) {
                                var permission = permissions[permissionsIndex];
                                if ((permission.exhibitorId !== "-1" && permission.theaterId !== "-1") && parseInt(exhibitor.id, 10) === parseInt(permission.exhibitorId, 10)) {
                                    exhibitor.permissions.push(_.extend({}, permission, _.find(exhibitor.theaters, {
                                        id: parseInt(permission.theaterId, 10)
                                    })));
                                    noSavedPermissions = false;
                                }
                            }
                        }
                        if (exhibitor.permissions && exhibitor.permissions.length > 0) {
                            exhibitor.permissions = _.groupBy(exhibitor.permissions, "permission");
                        } else {
                            delete exhibitor.permissions;
                        }
                    }

                    self.noSavedPermissions = noSavedPermissions;

                    self.permissions = exhibitors.filter(function (exhibitor) {
                        return exhibitor.hasOwnProperty("permissions");
                    });
                    window.console.timeEnd("createTerritoryAdminUserPermissionData");
                };

                /**
                 * Get Exhibitors and theaters under exhibitor by territory id
                 * @function getExhibitorsAndTheatersUnderExhibitorByTerritoryId
                 * @memberOf  angular_module.WB.cinehub.controllers.materialDetailsController
                 * @private
                 * @returns {promise}
                 */
                var getExhibitorsAndTheatersByTerritoryId = function () {
                    return exhibitorsService.findByTerritoryId(location.territoryId).success(function (result) {
                        exhibitors = result.data.exhibitors;
                    });
                };

                /**
                 * Get permissions by asset id
                 * @function getPermissionsByAssetId
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @private
                 */
                var getPermissionsByAssetId = function () {
                    permissionService.getPermissionItemsByAssetIds(self.asset.id).success(function (result) {
                        self.permissions = createHomeOfficeUserPermissionData(result._embedded.filmAssetPermissions);
                    });
                };

                /**
                 * Get permissions by asset id and territory id
                 * @function getPermissionsByAssetIdAndTerritoryId
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @private
                 * @returns {promise}
                 */
                var getPermissionsByAssetIdAndByTerritoryId = function () {
                    return permissionService.getAllPermissionsByAssetAndTerritory(self.asset.id, location.territoryId).success(function (result) {
                        permissions = result;
                    });
                };

                /**
                 * Remove item from permission queue
                 * @function removeItemFromPermissionQueue
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @private
                 * @param {object} result
                 */
                var removeItemFromPermissionQueue = function (permissionItems) {
                    var queueItem = _.find(permissionItems._embedded.permissionItems, {
                        assetId: self.asset.id.toString()
                    });
                    if (queueItem) {
                        permissionQueueService.removeFromQueue(queueItem);
                    }
                };

                /**
                 * Get permission queue assets
                 * @function getPermissionQueueAssets
                 * @private
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                var getPermissionQueueAssets = function () {
                    if (self.user.role === "homeOfficeAdmin") {
                        return permissionQueueService.getPermissionQueueAssetsForHOA(self.user.userId).success(function (result) {
                            removeItemFromPermissionQueue(result);
                        });
                    } else if (self.user.role === "territoryAdmin") {
                        return permissionQueueService.getPermissionQueueAssets(self.user.userId).success(function (result) {
                            removeItemFromPermissionQueue(result);
                        });
                    }
                };

                /**
                 * Edit permission
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @function editPermissions
                 */
                self.editPermissions = function () {
                    getPermissionQueueAssets().then(function () {
                        permissionQueueService.addToQueue(self.asset).success(function () {
                            self.displaySelectBtn = true;
                            $window.location.href = "/permission/cart/" + self.asset.id + "/";
                        });
                    });
                };

                /**
                 * On  player ready
                 * @public
                 * @function onPlayerReady
                 * @param {object} API videogular api
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.onPlayerReady = function onPlayerReady(API) {
                    self.videogularPlayerAPI = API;
                };

                /**
                 * On player error
                 * @public
                 * @function onPlayerError
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.onPlayerError = function onPlayerError() {
                    window.console.error("Videogular error! Fallback to flash.");
                    self.showFlashPlayer = true;
                    setTimeout(function () {
                        $scope.$apply();
                    }, 0);
                };

                /**
                 * cancel editing the asset details
                 * @function cancel
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.cancel = function () {
                    checkUnsavedChanges("cancel");
                };

                /**
                 * edit button
                 * @function editButon
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.editButton = function () {
                    self.isEditMode = !self.isEditMode;
                    var asset = self.originalFilmAsset ? self.originalFilmAsset : self.asset;
                    self.setAssetFileName(asset);
                    self.pubAssetType = self.originalFilmAsset.wbAssetType;
                    self.pubSubAssetType = self.originalFilmAsset.wbpiAssetType;
                };

                /**
                 * close window
                 * @function close
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.close = function () {
                    checkUnsavedChanges("close");
                };

                /**
                 * show the property
                 * @function showProperty
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @param {string} propertyName property name
                 */
                self.showProperty = function (propertyName) {
                    var show = false;
                    if (self.asset) {
                        show = self.isEditMode ? true : self.asset[propertyName] !== null;
                    }
                    return show;
                };

                /**
                 * open calendar
                 * @function showProperty
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @param {object} $event event
                 * @param {string} key key
                 */
                self.openCalendar = function ($event, key) {
                    $event.preventDefault();
                    $event.stopPropagation();

                    if (key === "startDate") {
                        self.calendarOpenState.startDate = true;
                        self.calendarOpenState.endDate = false;
                    } else if (key === "endDate") {
                        self.calendarOpenState.startDate = false;
                        self.calendarOpenState.endDate = true;
                    }
                };

                /**
                 * get asset subtypes
                 * @function getAssetSubTypes
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.getAssetSubTypes = function () {
                    var assetType = _.find(self.translations.values.assetType, {
                        code: self.asset.wbAssetType
                    });

                    self.asset.assetCategory = _.find(self.translations.values.category, {
                        id: assetType.extraCode
                    }).code;

                    return self.translations.values.assetSubType.filter(function (subTypeFilter) {
                        return subTypeFilter.extraCode === assetType.id;
                    });
                };

                /**
                 * save
                 * @function save
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.save = function () {
                    var errorMessage = self.translations.materialDetails.messages.saveError;

                    if (self.editAssetForm.$valid) {
                        self.asset.lastModified = moment().utc().format();
                        self.asset.recType = "PUB";
                        var origFileName = self.asset.fileName;
                        if (self.fileExtension === "") {
                            self.asset.fileName = self.pubFileName;
                        } else {
                            self.asset.fileName = self.pubFileName + "." + self.fileExtension;
                        }
                        self.asset.wbAssetType = self.pubAssetType.name ? self.pubAssetType.name : self.asset.wbAssetType;
                        self.asset.wbpiAssetType = self.pubSubAssetType.name ? self.pubSubAssetType.name :self.asset.wbpiAssetType;
                        materialDetailsService.save(self.asset, errorMessage).success(function () {
                            self.isEditMode = false;
                            self.isEdited = self.isAssetTypeUpdated; 
                            self.isAssetTypeUpdated = false;
                            self.originalFilmAsset = _.cloneDeep(self.asset);
                            self.editAssetForm.$setPristine();
                            $scope.$broadcast("materialUpdated");
                            $scope.$emit("updateTrailerFilename");
                            //$rootScope.$emit("materialUpdatePublished");
                            if(origFileName !== self.asset.fileName) {
                                $rootScope.$emit("materialNameUpdated", {oldFileName : origFileName, newFileName: self.asset.fileName});
                            }
                            if(self.isEdited) {
                                $rootScope.$emit("assetTypeUpdated");
                            }
                        });
                        //self.pubFileName = "";
                    }
                };

                /**
                 * update sub types when the asste type changes
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 * @function updateSubType
                 * @public
                 */
                self.updateSubType = function(event){
                    self.isAssetTypeUpdated = true;
                    materialDetailsService.getSubTypes(event.id).success(function (result) {
                        self.subTypes = result;
                        self.subTypes.forEach(function (subTypes) {
                            if (subTypes.name.toLowerCase() === "other") {
                                self.pubSubAssetType = subTypes;
                            }
                        });
                    });
                    
                };

                /**
                 * Add to cart
                 * @function addToCart
                 * @memberOf angular_module.WB.cinehub.directives.selectableMediaAsset
                 * @public
                 */
                self.addToCart = function () {
                    if (self.isAssetAddedToShoppingCart) {
                        return;
                    }
                    shoppingCartService.addToCart(self.asset).success(function () {
                        $rootScope.$emit("updateShoppingCartCount", true);
                        self.isAssetAddedToShoppingCart = true;
                    });
                };

                /**
                 * On tab change
                 * @function onTabChange
                 * @public
                 * @param {string} tabName tab name
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.onTabChange = function (tabName) {
                    var hasPermissions = self.permissions && self.permissions.length;
                    switch (tabName) {
                        case "details":
                            self.activeTabIndex = 0;
                            break;
                        case "permissions":
                            self.activeTabIndex = 1;
                            if (!hasPermissions && !adminUserPermissionDataCreated) {
                                if (self.user.role === "homeOfficeAdmin") {
                                    getPermissionsByAssetId();
                                } else if (self.user.role === "territoryAdmin") {
                                    $q.all([
                                        getPermissionsByAssetIdAndByTerritoryId(),
                                        getExhibitorsAndTheatersByTerritoryId()
                                    ]).then(createTerritoryAdminUserPermissionData);
                                }
                                adminUserPermissionDataCreated = true;
                            }
                            break;
                        case "activityHistory":
                            self.activeTabIndex = 2;
                            break;
                    }
                };

                /**
                 * Close lightbox
                 * @function closeLightbox
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.closeLightbox = function () {
                    $scope.openLightbox = false;
                    self.isCardRevealed = true;
                    self.isEditMode = false;
                    self.pubFileName = "";
                    self.pubAssetType = "";
                    self.pubSubAssetType = "";
                    self.subTypes = [];
                    self.isAssetTypeUpdated = false;
                    $("body").css({
                        "overflowY": "auto"
                    });
                };

                /**
                 * share Asset
                 * @function shareAsset
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.shareAsset = function () {
                    openShareAssetInformaitonModal();
                };

                /**
                 * share Asset
                 * @function shareAsset
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.mailAsset = function () {
                    var assetUrl = "Testing";
                    return materialDetailsService["mailAssetUrl"](assetUrl).success(function (result) {
                        assetUrl = result;
                    });
                };

                /**
                 * getEFD URL
                 * @function getEfdUrl
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                // self.getEfdUrl = function () {
                //     if (self.asset.id) {
                //         return self.API_EFD + "efd/sourceFiles/" + self.asset.id + "/" + self.user.userId + "?territoryId="+self.user.territoryId+
                //     "&localeId="+self.user.localeId+"&exhibitorId="+self.exhibitorId+"&theaterId="+self.theaterId;
                //     } else {
                //         return "";
                //     }

                // };

                /**
                 * Flip
                 * @function flip
                 * @public
                 * @param {boolean} isCardRevealed is card releaved flag
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.flip = function (isCardRevealed) {
                    if (isCardRevealed) {
                        self.isCardRevealed = false;
                        $(".angular-lightbox section > content.flipToBack").css({
                            top: "13%"
                        });
                        $(".material-details").animate({
                            width: "900px",
                            height: $(window).height() > 800 ? "717px" : "520px",
                            opacity: 1
                        }, {
                            duration: 600,
                            queue: false
                        });
                        if (self.asset.fileTypeCategory && self.asset.fileTypeCategory.toUpperCase() === "VIDEO") {
                            self.videogularPlayerAPI.pause();
                        }
                    } else {
                        $(".material-details").animate({
                            width: "512px",
                            height: "342px",
                            opacity: 0
                        }, {
                            duration: 200,
                            queue: false
                        });
                        
                        self.isCardRevealed = true;
                    }

                };
                self.getEncodes = function(){
                    $scope.selectedEncodes = [];
                    $scope.displayPopover =false;
                    var filmAsset = $scope.asset && $scope.asset.fileName ? $scope.asset : self.asset;
                    if($scope.displayCartEncodes){
                        shoppingCartService.getCartEncodes(filmAsset).success(function(result){
                            $scope.encodeOptions = result.data.AssetData;
                            $scope.displayPopover = true;
                        });
                    }
                    else {
                        getRenditionsService.getEncodes(filmAsset).success(function(result){
                            $scope.encodeOptions = result.data.AssetData;
                            $scope.displayPopover = true;
                        });
                    }
                   
                };
                $scope.getCartEncodes = function(){
                    var downloadItem = $scope.asset;
                    shoppingCartService.getCartEncodes(downloadItem).success(function(result){
                        $scope.cartEncodes = result.data.AssetData;
                    });
                };
                /**
                 * Init
                 * @function init
                 * @public
                 * @memberOf angular_module.WB.cinehub.controllers.materialDetailsController
                 */
                self.init = function () {
                    self.asset = $scope.asset;
                    self.action = $scope.action;
                    self.mmxId = self.asset.mmxId;
                    self.videoAutoPlay = true;
                    if (self.action === "view") {
                        self.videoAutoPlay = false;
                    }
                    var assets = [self.asset];
                    shoppingCartService.checkAssetsInDownloadCart(assets);
                    self.isAssetAddedToShoppingCart = assets[0].isInDownloadCart;

                    window.WB.cinehub.preload.assetId = self.asset.id;
                    window.WB.cinehub.preload.mmxId = self.mmxId;
                    window.WB.cinehub.preload.action = self.action;
                    // if (self.asset._links){
                    //     self.asset.id = self.asset.assetId;
                    // }
                    exhibitors = [];
                    permissions = [];
                    adminUserPermissionDataCreated = false;
                    location = localStorageService.get("location");

                    self.isEditMode = false;
                    self.translations = localStorageService.get("translations");
                    self.activeTabIndex = 0;
                    self.calendarOpenState = {
                        startDate: false,
                        endDate: false
                    };
                    self.tabs = [{
                        heading: self.translations.materialDetails.labels.detailsTab,
                        accessGranted: true,
                        name: "details",
                        route: "materialDetails",
                        templateUrl: "/shared/materialDetailsLightbox/detailsTab/detailsTab.html"
                    }, {
                        heading: self.translations.materialDetails.labels.permissionsTab,
                        accessGranted: restrictService.getAccessGrant(["standard"]) && !deviceDetectorService.isMobileOrTablet(),
                        name: "permissions",
                        route: "materialPermissions",
                        templateUrl: "/shared/materialDetailsLightbox/permissionTab/permissionTab.html"
                    }, {
                        heading: self.translations.materialDetails.labels.activityHistoryTab,
                        accessGranted: true,
                        name: "activityHistory",
                        route: "materialActivityHistory",
                        templateUrl: "/shared/materialDetailsLightbox/activityHistoryTab/activityHistoryTab.html"
                    }];
                    self.startDateOptions = {
                        showWeeks: false,
                        minMode: "day",
                        maxDate: self.asset ? self.asset.availabilityEndDate : null
                    };
                    self.endDateOptions = {
                        showWeeks: false,
                        minMode: "day",
                        minDate: self.asset ? self.asset.availabilityStartDate : null
                    };
                    self.permissions = [];
                    self.noSavedPermissions = false;
                    self.videogularPlayerConfig = {
                        nativeFullscreen: true,
                        autoPlay: true,
                        preload: "none",
                        controls: false,
                        sources: [{}],
                        plugins: {
                            poster: ""
                        }
                    };
                    self.eisNewUpdatedFields = [];
                    self.videogularPlayerAPI = null;
                    self.showFlashPlayer = false;

                    if (self.action === "sourceView") {
                        getAssetMarsOrig().then(function () {
                            self.asset = self.sourceAsset;
                        });
                        removeTab("activityHistory");
                    } else if (self.action !== "playTrailerOnly" && self.action !== "permissionReviewOnly") {
                        if (self.user.role === "homeOfficeAdmin" || self.user.role === "territoryAdmin") {
                            $q.all([
                                getAssetMarsOrig(),
                                getAssetPub()
                            ]).then(function () {
                                $q.all([
                                    getAssetTerritoryOrig(),
                                    getActivityHistory()
                                ]).then(function () {
                                    if (self.user.role === "homeOfficeAdmin" && self.asset && (!self.asset.assetId || !self.asset.fileName)) {
                                        self.asset = self.sourceAsset;
                                    }
                                    self.isAssetDetailsLoaded = true;
                                    setAssetProps();
                                });
                            });
                        } else {
                            getAssetPub();
                            getPermissionQueueAssets();

                        }
                    }
                    if (self.asset.assetCategory && self.asset.fileTypeCategory && self.asset.fileTypeCategory.toUpperCase() === "VIDEO") {
                        if (self.asset.assetFilename) {
                            self.videogularPlayerConfig.sources = videoSourceGeneratorService.getSource(self.asset.assetId, self.asset.assetFilename);
                        } else {
                            self.videogularPlayerConfig.sources = videoSourceGeneratorService.getSource(self.asset.id, self.asset.fileName);
                        }
                    }

                    if(self.asset.status && self.asset.status.toLowerCase() === "killed") {
                        removeTab("permissions");
                    }
                    if (self.asset.assetFilename) {
                        self.isAssetAddedToShoppingCart = true;
                    }
                    //leave only permission tab
                    if (self.action === "permissionReviewOnly") {
                        removeTab("details");
                        removeTab("activityHistory");
                        self.onTabChange("permissions");
                    }
                };

                /**
                 * @public
                 * @listens assetSuccessfullyAddedToCart
                 */
                $rootScope.$on("assetSuccessfullyAddedToCart", function () {
                    self.isAssetAddedToShoppingCart = true;
                });

                $scope.$watch("materialDetailsCtrl.asset.availabilityStartDate", function (newVal) {
                    self.endDateOptions.minDate = newVal;
                });

                $scope.$watch("materialDetailsCtrl.asset.availabilityEndDate", function (newVal) {
                    self.startDateOptions.maxDate = newVal;
                });

                $scope.$watch("openLightbox", function (newVal) {
                    if (newVal) {
                        self.init();

                        switch ($scope.activeTab) {
                            case "activityHistory":
                                self.onTabChange("activityHistory");
                                break;
                            default:
                                if ($scope.action !== "playTrailerOnly") {
                                    self.onTabChange("details");
                                }
                        }

                        $("body").css({
                            "overflowY": "hidden"
                        });
                    }
                });
            }
        ];

        return {
            restrict: "E",
            templateUrl: "materialDetailsLightboxTemplate.html",
            transclude: true,
            scope: {
                openLightbox: "=",
                asset: "=",
                action: "@",
                activeTab: "@",
                displayCartEncodes:"="
            },
            controller: materialDetailsController,
            controllerAs: "materialDetailsCtrl"
        };
    }

    directives.directive("materialDetailsLightbox", [materialDetailsLightboxDirective]);
}(window.jQuery));
