(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");
    
    /**
     * @class deleteNewsService
     * @memberOf angular_module.WB.cinehub.services
     * @desc Add edit news service
     * @param {!angular.$http} $http angular $http service
     * @paarm {strict} API_ROOT API_ROOT constant  
     */
    function deleteNewsService($http, API_ROOT) {

        /**
         * delete the news item
         * @memberOf angular_module.WB.cinehub.services.deleteNewsService
         * @public
         * @param {obj} newsItem news item
         * @param {str} errorMessage error message
         * @param {str} alertKey alert key
         * @returns {promise}
         */
        this.delete = function (newsItem, errorMessage, alertKey) {
            return $http.delete(API_ROOT + "newsItems/" + newsItem.id, newsItem, { errorMessage: errorMessage, alertKey: alertKey });
        };
    }

    services.service("deleteNewsService", ["$http", "API_ROOT", deleteNewsService]);
}());