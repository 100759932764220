(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Cart controller
     * @dec cart controller
     * @class cartController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.$rootScope} $rootScope angular rootScope
     * @param {service} shoppingCartService cart service
     * @param {service} localStorageService local storage service
     * @param {constant} API_EFD API_EFD
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     * @param {service} deviceDetectorService device detector service
     * @param {$uibModal} $uibModal uibModal instance
     * @param {service} assetCategoryConvertor asset category convertor
     * @param {constant} AUDIO_VIDEO_ASSET_CATEGORY AUDIO_VIDEO_ASSET_CATEGORY
     */
    function cartEncodesController($rootScope, $scope, shoppingCartService, localStorageService, API_EFD, DEFAULT_PAGE_SIZE, deviceDetectorService, $uibModal, assetCategoryConvertor, AUDIO_VIDEO_ASSET_CATEGORY) {

        /**
         * Instance of cartController
         * @type {cartController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        var self = this,

            /**
             * user id
             * @type {object}
             * @public
             * @memberOf angular_module.WB.cinehub.controllers.cartController
             */
            user = localStorageService.get("userInfo");

        /**
         * API_EFD
         * @constant
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         * @private
         * @type {string}
         */
        self.API_EFD = API_EFD;

        /**
         * AUDIO_VIDEO_ASSET_CATEGORY
         * @constant
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         * @private
         * @type {string}
         */
        self.AUDIO_VIDEO_ASSET_CATEGORY = AUDIO_VIDEO_ASSET_CATEGORY;

        /**
         * default current page
         * @type {int}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        self.currentPage = 1;

        /**
         * page size
         * @type {int}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        self.pageSize = DEFAULT_PAGE_SIZE;

        /**
         * translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        self.translations = localStorageService.get("translations");

        /**
         * get cart
         * @function getCart
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.cartController
         */
        $scope.getCart = function () {
            var errorMessage = "cart.messages.getCartError";

            shoppingCartService.getShoppingCartAssets(user.userId, self.pageSize, self.currentPage, errorMessage).success(function (result) {
                $scope.downloadItems = result;
            });
        };

        $scope.removeCartPopover = function ($index) {
            var removeEncode = $scope.cartEncodes[$index];
            shoppingCartService.removeCartPopover(removeEncode).success(function () {
                $scope.getCart();
                $rootScope.$emit("updateShoppingCartCount", true);
                $rootScope.$emit("updateDownloadcart", true);
            });
        };
    }

    controllers.controller("cartEncodesController", ["$rootScope", "$scope", "shoppingCartService", "localStorageService", "API_EFD", "DEFAULT_PAGE_SIZE", "deviceDetectorService", "$uibModal", "assetCategoryConvertor", "AUDIO_VIDEO_ASSET_CATEGORY", cartEncodesController]);
}());
