(function() {
    "use strict";
    var filters = angular.module("WB.cinehub.filters");

    /**
     * Page size filter for pagination directive 
     * @class sizeFormat
     * @memberOf angular_module.WB.cinehub.filters
     * @desc This size Format filter to define size
     * @returns {string} 
     */
    function sizeFormat() {

        return function (size) {
            if (isNaN(size))
                size = 0;

            if (size < 1024)
                return size + " Bytes";

            size /= 1024;

            if (size < 1024)
                return size.toFixed(2) + " KB";

            size /= 1024;

            if (size < 1024)
                return size.toFixed(2) + " MB";

            size /= 1024;

            if (size < 1024)
                return size.toFixed(2) + " GB";

            size /= 1024;

            return size.toFixed(2) + " Tb";
        };
    }

    filters.filter("sizeFormat", [sizeFormat]);

}());