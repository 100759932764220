(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Modal delete spotlight controller
     * @dec The modal delete spotlight controller 
     * @class modalDeleteSpotlightController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.Scope} $scope angular scope
     * @param {$uibModalInstance} $uibModalInstance modal instance 
     * @param {$window} $window window instance 
     * @param {service} modalDeleteSpotlightService delete spotlight service 
     * @param {object} spotlighItem spotlight item
     * @param {service} localStorageService localStorageService
     */
    function modalDeleteSpotlightController($scope, $uibModalInstance, $window, modalDeleteSpotlightService, spotlightItem, localStorageService) {
        
        /**
         * Instance of modalDeleteSpotlightController
         * @type {modalDeleteSpotlightController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalDeleteSpotlightController
         */
        var self = this;

        /**
         * news title
         * @type {modalDeleteSpotlightController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalDeleteSpotlightController
         */
        self.newsTitle = spotlightItem.title;

        /**
         * translations
         * @memberOf angular_module.WB.cinehub.controllers.modalDeleteSpotlightController
         * @public
         * @type {object}
         */
        self.translations = localStorageService.get("translations");

        /**
         * Cancel 
         * @function cancel 
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalDeleteSpotlightController\
         */
        self.cancel = function() {
            $uibModalInstance.dismiss();
        };

        /**
         * delete the news item
         * @function publish 
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalDeleteSpotlightController
         */
        self.delete = function() {
            var errorMessage = self.translations.deleteNewsModal.messages.deleteNewsError,
                alertKey = "modalAlert";
            modalDeleteSpotlightService.removeSpotlight(spotlightItem, errorMessage, alertKey).success(function () {
                $uibModalInstance.close();
                $window.location.href = "/spotlight-list";
            });
        };
    }
    
    controllers.controller("modalDeleteSpotlightController", ["$scope", "$uibModalInstance", "$window", "modalDeleteSpotlightService", "spotlightItem", "localStorageService", modalDeleteSpotlightController]);
}());
