(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class faqService
     * @memberOf angular_module.WB.cinehub.services
     * @desc faq service
     * @param {!angular.$http} $http angular $http service
     * @param {strict} API_ROOT API_ROOT constant
     * @param {service} localStorageService local storage service  
     */
    function faqService($http, API_ROOT, localStorageService) {

        /**
         * translations
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.faqService
         * @public
         */
        var translations = localStorageService.get("translations");

        /**
         * get faqs
         * @memberOf angular_module.WB.cinehub.services.faqService
         * @public
         * @function getFAQs
         * @returns {promise}
         */
        this.getFAQs = function () {
            return $http.get(API_ROOT + "faqs/?", {
                errorMessage: translations.faq.messages.getFAQError
            });
        };
    }

    services.service("faqService", ["$http", "API_ROOT", "localStorageService", faqService]);
}());
