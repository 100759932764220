(function () {
    "use strict";
    var services = angular.module("WB.cinehub.services");

    /**
     * Download service 
     * @class downloadService
     * @memberOf angular_module.WB.cinehub.services
     * @desc This is download service 
     * @param {!angular.$http} $http angular $http service
     * @param {string} API_ROOT API_ROOT constant 
     * @param {service} localStorageService local storage service
     * @param {$window} $window $window
     */
    function downloadService($http, API_ROOT, localStorageService, $window) {

        /**
         * Location information
         * @private
         * @memberOf angular_module.WB.cinehub.services.downloadService
         * @type {object}
         */
        var location = localStorageService.get("location"),

            /**
             * exhibitor id
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.services.downloadService
             */
            exhibitorId = localStorageService.get("currentExhibitorAffiliation") || -1,

            /**
             * User info
             * @type {int}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.shoppingCartService
             */
            user = localStorageService.get("userInfo"),

            /**
             * locale id
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.services.downloadService
             */
            localeId = user && user.role === "homeOfficeAdmin" ? -1 : location && location.localeId ? location.localeId : -1,

            /**
             * territory id
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.services.downloadService
             */
            territoryId = user && user.role === "homeOfficeAdmin" ? -1 : location && location.territoryId ? location.territoryId : -1,

            /**
             * theater id
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.services.downloadService
            */
            theaterId = localStorageService.get("currentTheaterAffiliation") || -1,

            /**
             * translations
             * @private
             * @memberOf angular_module.WB.cinehub.services.downloadService
             * @type {object}
            */
            translations = localStorageService.get("translations");

        /**
         * Trigger download 
         * @memberOf angular_module.WB.cinehub.services.downloadService
         * @public
         * @function download
         * @param {int} downloadId download it
         * @param {int} userId user id
         * @returns {promise}
         */
        this.download = function (userId, downloadId) {
            $window.location.assign(API_ROOT + "filmAssetsDownload/downloadAssetById?downloadId=" + downloadId + "&userId=" + userId +
                    "&territoryId=" + territoryId + "&localeId=" + localeId + "&exhibitorId=" + exhibitorId + "&theaterId=" + theaterId);
            //return $http.get(API_ROOT + "filmAssetsDownload/downloadAssetById?downloadId=" + downloadId + "&userId=" + userId + "&ignoreLocalizationString", { errorMessage: translations.downloadPage.messages.downloadError });
        };

        /**
         * Verify download link
         * @memberOf angular_module.WB.cinehub.services.downloadService
         * @public
         * @function verifyDownloadLink
         * @param {int} downloadId download it
         * @param {int} userId user id
         * @returns {promise}
         */
        this.verifyDownloadLink = function (userId, downloadId) {
            return $http.get(API_ROOT + "filmAssetsDownload/verifyDownloadLink?downloadId=" + downloadId + "&userId=" + userId, { errorMessage: translations.downloadPage.messages.downloadError });
        };
    }

    services.service("downloadService", ["$http", "API_ROOT", "localStorageService", "$window", downloadService]);
}());
