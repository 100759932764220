(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * FilmRating service
     * @class filmRatingService
     * @param {!angular.$http} $http angular $http service
     * @param {strict} API_ROOT API_ROOT constant
     * @param {service} filmService film service
     * @memberOf angular_module.WB.cinehub.services
     * @desc The film rating service
     */
    function filmRatingService($http, API_ROOT, $q, localStorageService, filmService) {

        /**
         * location
         * @memberOf angular_module.WB.cinehub.services.filmRatingService
         * @public
         * @type {object}
         */
        var location = localStorageService.get("location");

        /**
         * Fetches Ratings and sets to Film objects.
         * @function setRatingDataForFilm
         * @private
         * @memberOf angular_module.WB.cinehub.services.filmRatingService
         */
        this.setFilmRatings = function(filmObjs) {
            var ratingData = localStorageService.get("ratingData_" + location.territoryId);
            if(ratingData && _.isArray(ratingData)) {
                var q = $q.defer();
                q.resolve(processRatings(ratingData, filmObjs, location.territoryId, false));
                //return promisified result
                return q.promise;
            } else {
                return filmService.getFilmRating(location.territoryId).then(function (ratings) {
                    return processRatings(ratings.data, filmObjs, location.territoryId, true);
                });
            }
        };

        /**
         * set Rating data to Films
         * @param film
         * @param ratings
         * @memberOf angular_module.WB.cinehub.services.filmRatingService
         */
        var mapFilmRatings = function (film, ratings) {
            film.filmRating = [];
            var primaryRtg = _.find(ratings, {id : film.ratingId});
            var secRtgId = film.subRatingId ? filmService.splitMultiValFields(film.subRatingId) : [];
            if (primaryRtg) {
                film.filmRating.push(primaryRtg);
            }
            secRtgId.forEach(function (id) {
                var secRtg = _.find(ratings, function (opt) {
                    return opt.id === parseInt(id);
                });
                film.filmRating.push(secRtg);
            });
        };

        /**
         * Process Rating data.
         * @param ratings
         * @param filmObjs
         * @param setCache
         * @returns {{}}
         * @memberOf angular_module.WB.cinehub.services.filmRatingService
         */
        var processRatings = function (ratings, filmObjs, territoryId, setCache) {
            var res = {};
            res.ratings = ratings;
            if (setCache) {
                localStorageService.set("ratingData_" + territoryId,  res.ratings);
            }
            if(filmObjs && _.isObject(filmObjs)) {
                for (var key in filmObjs) {
                    var filmObj = filmObjs[key];
                    if (_.isArray(filmObj)) {
                        filmObj.forEach(function (film) {
                            mapFilmRatings(film,  res.ratings);
                        });
                    } else if (_.isObject(filmObj)) {
                        mapFilmRatings(filmObj,  res.ratings);
                    }
                }
            }
            res.filmObjs = filmObjs;
            return res;
        };
    }   
    services.service("filmRatingService", ["$http", "API_ROOT", "$q", "localStorageService", "filmService", filmRatingService]);
}());
