(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Footer controller 
     * @class footerController
     * @memberOf angular_module.WB.cinehub.controllers
     * @desc This is footer controller 
     * @param {service} privacyPolicyService privacy policy service
     * @param {object} $window window object
     */
    function footerController($window, localStorageService, footerService, privacyPolicyService, DEFAULT_CONTACT_US_EMAIL) {

        /**
         * Instance to footerController
         * @memberOf angular_module.WB.cinehub.controllers.footerController
         * @private
         * @type {footerController}
         */
        var self = this;

        /**
         * translations
         * @memberOf angular_module.WB.cinehub.controllers.footerController
         * @public
         * @type {object}
         */
        self.translations = localStorageService.get("translations");

        /**
         * Current year 
         * @memberOf angular_module.WB.cinehub.controllers.footerController
         * @public
         * @type {string}
         */
        self.currentYear = moment().year();

         /**
         * contactInfo
         * @memberOf angular_module.WB.cinehub.controllers.footerController
         * @public
         * @type {string}
         */
        self.contactEmail = DEFAULT_CONTACT_US_EMAIL;

        /**
        * User info object
        * @type {object}
        * @memberOf angular_module.WB.cinehub.controllers.footerController
        * @private
        */
        self.user = localStorageService.get("userInfo");
        
        /**
         * location
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.footerController
         * @public 
         */
        self.location = localStorageService.get("location");

         /**
         * browserLanguage
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.footerController
         * @public 
         */
        self.browserLanguage = localStorageService.get("browserLanguage");

         /**
         * Init
         * @memberOf angular_module.WB.cinehub.controllers.footerController
         * @function init
         * @public
         */
        self.init = function () {
            footerService.getHelpContactEmail().then(function (contactEmail) {
                self.contactEmail = contactEmail;
            });
        };

        /**
         * Privacy Policy
         * @memberOf angular_module.WB.cinehub.controllers.footerController
         * @function privacyPolicy
         * @public
         */
        self.privacyPolicy = function () {
            privacyPolicyService.getWMPrivacyPolicyUrl(localStorageService.get("location"));
        };

        //INIT
        self.init();
    }

    controllers.controller("footerController", ["$window","localStorageService", "footerService", "privacyPolicyService", "DEFAULT_CONTACT_US_EMAIL", footerController]);
} ());
