
(function() {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * preferences directive
     * @class preferences
     * @memberOf angular_module.WB.cinehub.directives
     * @returns {directiveDefinitionObj}
     * @param {service} convertLocalizationCodeToValueService convert localization code to value service
     * @param {service} localStorageService local storage service
     */
    function preferences(convertLocalizationCodeToValueService, localStorageService) {

        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @private
         * @memberOf angular_module.WB.cinehub.directives.preferences
         */
        var link = function(scope) {

            /**
             * Translations
             * @type {object}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.preferences
             */
            scope.translations = localStorageService.get("translations");

            /**
             * Convert localization code to value service
             * @type {service}
             * @public
             * @memberOf angular_module.WB.cinehub.directives.preferences
             */
            scope.convertLocalizationCodeToValueService = convertLocalizationCodeToValueService;

        };

        return {
            link: link,
            scope: {
                data: "="
            },
            templateUrl: "preferencesTemplate.html"
        };
    }

    directives.directive("preferences", ["convertLocalizationCodeToValueService", "localStorageService", preferences]);
}());
