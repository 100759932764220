(function () {
    "use strict";

    var services = angular.module("WB.cinehub.services");
    
    /**
     * @class filmNewsService
     * @memberOf angular_module.WB.cinehub.services
     * @desc film service
     * @param {!angular.$http} $http angular $http service
     * @paarm {strict} API_ROOT API_ROOT constant  
     */
    function filmNewsService($http, API_ROOT) {

        /**
         * get news items 
         * @memberOf angular_module.WB.cinehub.services.filmNewsService
         * @public
         * @param {int} filmId film id
         * @param {str} size page size
         * @param {str} page page index
         * @param {str} sort sorting
         * @param {str} errorMessage error message
         * @returns {promise}
         * @todo Revisit the issue with pastDays
         */
        this.getNewsItems = function (filmId, size, page, sort, errorMessage) {
            return $http.get(API_ROOT + "newsItems/search/findLocByFilmIdAndByPastDays?filmId=" + filmId + "&pastDays=1000&page=" + page + "&size=" + size + "&sort=" + sort, { errorMessage: errorMessage });
        };
    }

    services.service("filmNewsService", ["$http", "API_ROOT", filmNewsService]);
}());