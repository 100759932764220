(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Catalog controller
     * @dec catalog controller
     * @class catalogController
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {$window} $window window instance
     * @param {service} catalogService catalog service
     * @param {factory} filmUrlGeneratorFactory filim url generator factory
     * @param {service} localStorageService local storage service
     * @param {constant} API_EFD API_EFD,
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     * @param {service} convertLocalizationCodeToValueService convert localization code to value service
     */
    function catalogController($window, catalogService, filmUrlGeneratorFactory, localStorageService, filmRatingService, API_EFD, DEFAULT_PAGE_SIZE, convertLocalizationCodeToValueService) {

        /**
         * Instance of catalogController
         * @type {catalogController}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         */
        var self = this;

        /**
         * translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         */
        self.translations = localStorageService.get("translations");

        /**
         * browse all sort options - text to be replaced with translated text
         * @type {object[]}
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         */
        var browseAllSortOptions = [{
            value: "releaseDate,asc",
            text: self.translations.sort.labels.releaseDateOldestToNewest
        }, {
            value: "releaseDate,desc",
            text: self.translations.sort.labels.releaseDateNewestToOldest
        }, {
            value: "title,asc",
            text: self.translations.sort.labels.titleNameAToZ
        }, {
            value: "title,desc",
            text: self.translations.sort.labels.titleNameZToA
        }, {
            value: "lastModified,desc",
            text: self.translations.sort.labels.updatedDateNewestToOldest
        }, {
            value: "lastModified,asc",
            text: self.translations.sort.labels.updatedDateOldestToNewest
        }];

        /**
         * browse all sort options during hoa view 
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         * @private
         */
        var browseAllSortOptionsDuringHOAView = [{
            value: "title,asc",
            text: self.translations.sort.labels.titleNameAToZ
        }, {
            value: "title,desc",
            text: self.translations.sort.labels.titleNameZToA
        }];

        /**
         * valendar view sorting options for mobile/tablet - text to be replaced with translated text
         * @type {object[]}
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         */
        var calendarViewSortOptions = [{
            value: "releaseDate,asc",
            text: self.translations.sort.labels.releaseDateOldestToNewest
        }, {
            value: "releaseDate,desc",
            text: self.translations.sort.labels.releaseDateNewestToOldest
        }, {
            value: "title,asc",
            text: self.translations.sort.labels.titleNameAToZ
        }, {
            value: "title,desc",
            text: self.translations.sort.labels.titleNameZToA
        }, {
            value: "rating,desc",
            text: self.translations.sort.labels.ratingAToZ
        }, {
            value: "rating,asc",
            text: self.translations.sort.labels.ratingZToA
        }];

        /**
         * film types - text to be replaced with translated text
         * @type {object[]}
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         */
        var filmTypes = [{
            value: "allFilms",
            text: self.translations.filter.labels.allFilms
        }, {
            value: "comingSoon",
            text: self.translations.filter.labels.comingSoon
        }, {
            value: "inTheaters",
            text: self.translations.filter.labels.inTheaters
        }];

        /**
         * modified interval - text to be replaced with translated text
         * @type {object[]}
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         */
        var modifiedIntervals = [{
            value: 0,
            text: self.translations.filter.labels.allDays
        }, {
            value: 3,
            text: self.translations.filter.labels.modifiedWithin3Days
        }, {
            value: 7,
            text: self.translations.filter.labels.modifiedWithin7Days
        }, {
            value: 14,
            text: self.translations.filter.labels.modifiedWithin2Weeks
        }];

        /**
         * Hoa filter 
         * @type {object}
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         */
        var hoaFilterOption = {
            type: "checkbox",
            options: [{
                value: false,
                text: self.translations.global.labels.hoaView
            }],
            propertyName: "isHOAView"
        };

        /**
         * User profile information
         * @type {object}
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         */
        var user = localStorageService.get("userInfo");

        /**
         * Browse all tab filter sort options
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         * @public
         */
        self.browseAllTabFilterSortOptions = [{
            type: "select",
            options: filmTypes,
            propertyName: "filmType",
            disabled: false
        }, {
            type: "select",
            options: modifiedIntervals,
            propertyName: "modifiedInterval",
            disabled: false
        }, {
            type: "select",
            options: browseAllSortOptions,
            propertyName: "sort",
            disabled: false
        }];

        /**
         * Release calendar tab filter sort options
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         * @public
         */
        self.releaseCalendarTabFilterSortOptions = [{
            type: "select",
            options: filmTypes,
            propertyName: "filmType",
            disabled: false
        }, {
            type: "select",
            options: modifiedIntervals,
            propertyName: "modifiedInterval",
            disabled: false
        }, {
            type: "select",
            options: calendarViewSortOptions,
            propertyName: "sort",
            hideOnLargeScreen: true,
            disabled: false
        }];

        /**
         * API_EFD
         * @constant
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         * @private
         * @type {string}
         */
        self.API_EFD = API_EFD;

        /**
         * page header item for the page
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         */
        self.pageHeaderItem = {
            pageTitle: self.translations.catalog.labels.pageTitle,
            breadcrumbItems: [{
                linkTitle: self.translations.catalog.labels.breadcrumbLinkTitle,
                link: "/catalog"
            }]
        };

        /**
         * film url generator
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         * @public
         * @type {filmUrlGeneratorFactory}
         */
        self.filmUrlGenerator = filmUrlGeneratorFactory;

        /**
         * Convert location code to value service
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         * @public
         * @type {service}
         */
        self.convertLocalizationCodeToValueService = convertLocalizationCodeToValueService;

        /**
         * tab menus
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         * @public
         */
        self.tabs = [{
            heading: self.translations.catalog.labels.browseAllTab,
            templateUrl: "/modules/catalog/browseAll/browseAll.html",
            active: false
        }, {
            heading: self.translations.catalog.labels.releaseCalendarTab,
            templateUrl: "/modules/catalog/releaseCalendar/releaseCalendar.html",
            active: false
        }];

        /**
         * films object
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         */
        self.films = {
            page: {
                number: 1,
                size: DEFAULT_PAGE_SIZE
            }
        };

        /**
         * filter sort 
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         */
        self.filterSort = {
            sort: "releaseDate,asc",
            filmType: "allFilms",
            modifiedInterval: 0,
            isHOAView: undefined
        };

        /**
         * set film url
         * @function setFilmUrl
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         * @private
         */
        var setFilmUrl = function () {
            var title = "",
                index = 0,
                filmLength = self.films._embedded.films.length,
                isSourceView = !!(self.filterSort.isHOAView && user.role === "homeOfficeAdmin");
            for (index; index < filmLength; index++) {
                title = self.filmUrlGenerator(self.films._embedded.films[index].title);
                self.films._embedded.films[index].filmUrl = "/films/" + self.films._embedded.films[index].id + "/" + self.films._embedded.films[index].eisId + "/" + isSourceView + "/" + title + "#/film/details";
                self.films._embedded.films[index].format = window.helpers.isJson(self.films._embedded.films[index].format) ? JSON.parse(self.films._embedded.films[index].format) : self.films._embedded.films[index].format.split(",");

            }
        };

        /**
         * get all films
         * @function getAllFilms
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         */
        var getAllFilms = function () {
            catalogService.getAllFilms(self.filterSort.modifiedInterval, self.films.page.number, self.films.page.size, self.filterSort.sort).success(function (result) {
                self.films = result;
                setFilmUrl();
                processRatingData(result);
            });
        };

        /**
         * get all films for hoa
         * @function getAllFilmsHoa
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         */
        var getAllFilmsHoa = function () {
            catalogService.getAllFilmsHoa(self.films.page.number, self.films.page.size, self.filterSort.sort).success(function (result) {
                self.films = result;
                setFilmUrl();
                processRatingData(result);
            });
        };

        /**
         * get upcoming films
         * @function getUpcomingFilms
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         * @param {str} secondarySort secondary sort
         */
        var getUpcomingFilms = function (secondarySort) {
            catalogService.getUpcomingFilms(self.filterSort.modifiedInterval, self.films.page.number, self.films.page.size, self.filterSort.sort, secondarySort).success(function (result) {
                self.films = result;
                setFilmUrl();
                processRatingData(result);
            });
        };

        /**
         * get in theater films
         * @function getInTheaterFilms
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         * @param {str} secondarySort secondary sort
         */
        var getInTheaterFilms = function (secondarySort) {
            catalogService.getInTheaterFilms(self.filterSort.modifiedInterval, self.films.page.number, self.films.page.size, self.filterSort.sort, secondarySort).success(function (result) {
                self.films = result;
                setFilmUrl();
                processRatingData(result);
            });
        };

        
        /**
         * Process rating data
         * @function processRatingData
         * @privateself.ratingOption
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         */
        var processRatingData = function (result) {
            var filmObjs = {};
            filmObjs.films = result && result._embedded && result._embedded.films ? result._embedded.films : [];
            return filmRatingService.setFilmRatings(filmObjs).then(function (res) {
                self.films._embedded.films = res.filmObjs["films"];
            });
        };

        var isHoaState = false;

        /**
         * Change filters 
         * @function changeFilters
         * @param {boolean} isHOAView is hoa view
         * @private
         */
        var changeFilters = function (isHOAView) {
            if (isHOAView !== undefined) {
                self.browseAllTabFilterSortOptions[0].disabled = isHOAView;
                self.browseAllTabFilterSortOptions[1].disabled = isHOAView;
                if (isHOAView && !isHoaState) {
                    self.browseAllTabFilterSortOptions[2].options = browseAllSortOptionsDuringHOAView;
                    self.filterSort.sort = browseAllSortOptionsDuringHOAView[0].value;
                    self.filterSort.filmType = "allFilms";
                    self.filterSort.modifiedInterval = 0;
                    isHoaState = true;
                } else if (isHOAView === false) {
                    self.browseAllTabFilterSortOptions[2].options = browseAllSortOptions;
                    self.filterSort.isHOAView = undefined;
                    isHoaState = false;
                }
            }
        };

        /**
         * get catalog
         * @function getCatalog
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         */
        self.getCatalog = function () {

            changeFilters(self.filterSort.isHOAView);

            if (self.filterSort.filmType === "comingSoon") {
                getUpcomingFilms();
            } else if (self.filterSort.filmType === "inTheaters") {
                getInTheaterFilms();
            } else if (self.filterSort.isHOAView) {
                getAllFilmsHoa();
            } else {
                getAllFilms();
            }
        };

        /**
         * redirect to film page
         * @function redirectToFilmPage
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         */
        self.redirectToFilmPage = function (filmUrl) {
            $window.location.href = filmUrl;
        };

        /**
         * Open lightbox
         * @function openLightBox
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         * @param {object} $event browser event
         * @param {object} asset asset
         * @param {string} action action
         */
        self.openLightBox = function ($event, asset, action) {
            self.openLightbox = true;
            self.lightboxAsset = asset;
            self.lightboxAction = action;
        };

        /**
         * init
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.catalogController
         */
        self.init = function () {
            self.filterSort = {
                sort: "releaseDate,asc",
                modifiedInterval: 0
            };
            switch (window.WB.cinehub.preload.catalogFilter) {
                case "comingSoon":
                    self.filterSort.filmType = "comingSoon";
                    break;
                case "inTheaters":
                    self.filterSort.filmType = "inTheaters";
                    self.filterSort.sort = "releaseDate,desc";
                    break;
                default:
                    self.filterSort.filmType = "allFilms";
                    self.filterSort.isHOAView = false;
            }

            if (user.role === "homeOfficeAdmin") {
                self.browseAllTabFilterSortOptions.push(hoaFilterOption);
            }

            self.getCatalog(self.filterSort);
        };
    }

    controllers.controller("catalogController", ["$window", "catalogService", "filmUrlGeneratorFactory", "localStorageService", "filmRatingService", "API_EFD", "DEFAULT_PAGE_SIZE", "convertLocalizationCodeToValueService", catalogController]);
}());
