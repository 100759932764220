(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Forgot password controller
     * @class forgotPasswordController
     * @memberOf angular_module.WB.cinehub.controllers
     * @desc This is forgot password controller
     * @param {!angular.$rootScope} $rootScope angular root scope
     * @param {service} passwordService password service
     * @param {service} localStorageService local storage service
     * @param {service} emailService email service
     * @param {service} helpContactService help contact service
     */
    function forgotPasswordController($rootScope, passwordService, localStorageService, emailService, helpContactService) {

        /**
         * Incstance to forgotPasswordController
         * @private
         * @type {forgotPasswordController}
         * @memberOf angular_module.WB.cinehub.controllers.forgotPasswordController
         */
        var self = this;

        /**
         * translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.forgotPasswordController
         */
        self.translations = localStorageService.get("translations");

        /**
         * Show confirmation flag
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.forgotPasswordController
         * @type {bool}
         */
        self.showConfirmation = false;

        /**
         * Show confirmation flag
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.forgotPasswordController
         * @type {bool}
         */
        self.isInternalAccount = false;


        /**
         * email format regex
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.forgotPasswordController
         * @type {regex}
         */
        self.emailFormat = emailService.getEmailFormatRegex();

        /**
         * forgot password
         * @function forgotPassword
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.forgotPasswordController
         */
        self.forgotPassword = function () {
            if (self.formForgotPassword.$valid) {
                passwordService.forgotPassword(self.email).success(function (result) {
                    self.showConfirmation = true;
                    self.validEmail = result.status === "success";
                }).error(function (error) {
                    var errorMessage = "";
                    $rootScope.$broadcast("hideAlert");
                    if(error.message === "pendingAccountVerification") {
                        errorMessage = self.translations.forgotPassword.messages.pendingAccountVerificationError;
                    } else if(error.message === "inactive") {
                        errorMessage = self.translations.login.messages.inactiveError;
                    } else if(error.message === "pendingEmailVerification") {
                        errorMessage = self.translations.login.messages.pendingEmailVerificationError;
                    } else if(error.message === "pendingApproval") {
                        errorMessage = self.translations.login.messages.pendingApprovalError;
                    } else if(error.message === "rejected") {
                        errorMessage = self.translations.login.messages.rejectedError;
                    } else if(error.message === "cantChangePasswordForWbUser") {
                        self.isInternalAccount = true;
                        angular.element("#email").focus();
                    } else {
                        errorMessage = self.translations.forgotPassword.messages.resetPasswordError;
                    }

                    if (errorMessage) {
                        $rootScope.$broadcast("showAlert", "danger", errorMessage, "anonymousUserAlert");
                    }
                });
            }
        };

        /**
         * Init controller
         * @function init
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.forgotPasswordController
         */
        self.init = function () {
            helpContactService.getHelpContactEmail().then(function (contactEmail) {
                self.contactEmail = contactEmail;
            });
        };

        self.init();
    }

    controllers.controller("forgotPasswordController", ["$rootScope", "passwordService", "localStorageService", "emailService", "helpContactService", forgotPasswordController]);
}());
