(function () {
    "use strict";
    var controllers = angular.module("WB.cinehub.controllers");

    /**
     * Modal lookup theater controller
     * @dec The modal lookup theater controller
     * @class modalEditMainFilmCarousel
     * @memberOf angular_module.WB.cinehub.controllers
     * @param {!angular.$scope} $scope angular scope
     * @param {$uibModal} $uibModal uib modal
     * @param {$uibModalInstance} $uibModalInstance modal instance
     * @param {service} localStorageService local storage service
     * @param {constant} DEFAULT_PAGE_SIZE DEFAULT_PAGE_SIZE
     * @param {object} highlightedFilms highlighted films  
     * @param {service} filmService film service
     * @param {constant} API_EFD API_EFD
     * @param {service} highlightedFilmsService highlighted films service
     * @param {constant} API_ROOT API_ROOT
     * @param {service} $cookies $cookies angular service
     * @param {service} $q angular $q service
     */
    function modalEditMainFilmCarousel($scope, $uibModal, $uibModalInstance, localStorageService, DEFAULT_PAGE_SIZE, highlightedFilms, filmService, API_EFD, highlightedFilmsService, API_ROOT, $cookies, $q) {

        /**
         * Instance of modalEditMainFilmCarousel
         * @type {modalEditMainFilmCarousel}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        var self = this,

            /**
             * User profile information 
             * @private 
             * @type {object}
             * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
             */
            user = localStorageService.get("userInfo"),

            /**
             * Location information
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
             */
            location = localStorageService.get("location"),

            /**
             * Selected film
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
             */
            selectedFilm = null,
            
            /**
             * Selected film
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
             */
            filmsCopy = null,

            /**
             * Is edit highlighted film mode
             * @type {boolean}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
             */
            isEditHighlightedFilmMode = false,

            /**
             * The count of CinehubHero jpg in the images/heroDefault folder
             * @constant
             * @type {int}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
             */
            MAX_INDEX_COUNT_HERO_DEFAULT_IMG = 11,

            /**
             * Difered save object
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
             */
            deferedDelete = $q.defer(),

            /**
             * Difered save object
             * @type {object}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
             */
            deferedSave = $q.defer(),

            /**
             * highlighted film copy 
             * @type {object[]}
             * @private
             * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
             */
            highlightedFilmsCopy = _.cloneDeep(highlightedFilms);

        /**
         * Is next disabled flag
         * @public
         * @type {boolean}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        self.isNextDisabled = true;
        
        /**
         * Is Visible flag
         * @public
         * @type {boolean}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        self.IsVisible = false; 

        /**
         * Access token
         * @public
         * @type {string}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        self.accessToken = $cookies.get("accessToken");

        /**
         * EFD server url 
         * @public
         * @constant
         * @type {string}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        self.API_EFD = API_EFD;

        /**
         * Highlighted film index 
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @public
         * @type {int}
         */
        self.highlightedFilmIndex = 0;

        /**
         * translations
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        self.translations = localStorageService.get("translations");

        /**
         * Highlighted films 
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        self.highlightedFilms = _.cloneDeep(highlightedFilms);

        /**
         * Film titles 
         * @public
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        self.films = {
            page: {
                size: DEFAULT_PAGE_SIZE,
                number: 0
            }
        };

        /**
         * tab menus
         * @type {object[]}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @public
         */
        self.tabs = [{
            heading: self.translations.editMainFilmCarousel.labels.titleRelated,
            templateUrl: "/modules/editMainFilmCarouselModal/titleRelatedTab.html",
            active: true
        },
        {
            heading: self.translations.editMainFilmCarousel.labels.genericBackground,
            templateUrl: "/modules/editMainFilmCarouselModal/genericBackgroundTab.html",
            active: false
        }];

        /**
         * Film sort query 
         * @public
         * @type {string}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        self.filmSortQuery = "releaseDate,asc";

        /**
         * Show selected title flag 
         * @type {boolean}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @public
         */
        self.showSelectTitle = false;

        /**
         * Show select feature image 
         * @type {boolean}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @public
         */
        self.showSelectFeatureImage = false;

        /**
         * Is croppie ready flag 
         * @type {boolean}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @public
         */
        self.isCroppieReady = false;

        /**
         * Generic backgrounds 
         * @type {array}
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @public
         */
        self.genericBackgrounds = [];

        /**
         * Show preview flag
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @type {boolean}
         */
        self.showPreview = false;

        /**
         * Film assets 
         * @type {object[]}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        self.filmAssets = [];

        /**
         * Crop tool settings 
         * @type {object}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        self.cropToolSettings = {
            viewport: {
                width: 400,
                height: 230
            },
            boundary: {
                width: 415,
                height: 450
            }
        };

        /**
         * Crop result image
         * @type {string}
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        self.cropResultImage = "";

        /**
         * Generate generic backgrounds 
         * @function generateGenericBackgrounds
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @private
         */
        var generateGenericBackgrounds = function () {
            var index = 0;
            self.genericBackgrounds = [];
            for (index; index < MAX_INDEX_COUNT_HERO_DEFAULT_IMG; index++) {
                self.genericBackgrounds.push({
                    imageSrc: "/static/images/heroDefault/CinehubHero" + (index + 1) + ".jpg",
                    selected: false
                });
            }
        };

        /**
         * Get film assets 
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @private
         * @function getFilmAssets
         * @param {int} filmId film id
         * @returns {promise}
         */
        var getFilmAssets = function (filmId) {

            return highlightedFilmsService.findLocByFilmIdAndArtworksForHighlightedFilms(filmId).success(function (result) {
                self.filmAssets = result._embedded.filmAssets;
            });
        };

        /**
         * Check already added highlighted film 
         * @function checkAlreadyAddedHighlightedFilm
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        var checkAlreadyAddedHighlightedFilm = function () {
            self.films = _.cloneDeep(filmsCopy);
            self.highlightedFilms._embedded.highlightedFilms.filter(function (highlightedFilm) {
                return !highlightedFilm.isDeleted;
            }).forEach(function (highlightedFilm) {
                var filmIndex = _.findIndex(self.films._embedded.films, {
                    filmId: highlightedFilm.filmId
                });
                if (filmIndex >= 0) {
                    self.films._embedded.films.splice(filmIndex, 1);
                    self.films.page.totalElements--;
                    self.films.page.totalPages = Math.floor(self.films.page.totalElements/self.films.page.size) + 1;
                }
            });
        };

        /**
         * Reset selected state 
         * @function resetSelectedState
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        var resetSelectedState = function () {
            self.genericBackgrounds.forEach(function (heroImg) {
                heroImg.selected = false;
            });

            self.filmAssets.forEach(function (heroImg) {
                heroImg.selected = false;
            });
        };

        /**
         * Reorder highlighted film order
         * @function reorderHighlightedFilmOrder
         * @private
         * @param {object} highlightedFilms highlighted films
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        var reorderHighlightedFilmOrder = function (highlightedFilms) {
            highlightedFilms.filter(function (highlightedFilm) {
                return !highlightedFilm.isDeleted;
            }).forEach(function (highlightedFilm, index) {
                highlightedFilm.order = index + 1;
            });
        };

        /**
         * Delete highlighted films
         * @function deleteHighlightedFilms
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        var deleteHighlightedFilms = function () {
            var deletedHighlightedFilms = self.highlightedFilms._embedded.highlightedFilms.filter(function (highlightedFilm) {
                if (highlightedFilm.isDeleted && highlightedFilm.id) {
                    delete highlightedFilm.film;
                    return true;
                }
            });
            if (deletedHighlightedFilms.length > 0) {
                return highlightedFilmsService.deleteHighlightedFilms(deletedHighlightedFilms);
            } else {
                deferedDelete.resolve();
                return deferedDelete.promise;
            }
        };

        /**
         * Save highlighted films
         * @function saveHighlightedFilms
         * @private
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        var saveHighlightedFilms = function () {
            var highlightedFilms = self.highlightedFilms._embedded.highlightedFilms.filter(function (highlightedFilm) {
                    return !highlightedFilm.isDeleted;
                }),
                highlightedFilmsFormatedForSave = null;

            if (_.isEmpty(highlightedFilms)) { //all items got deleted 
                self.highlightedFilms._embedded.highlightedFilms = [];
                deferedSave.resolve();
                return deferedSave.promise;
            }

            reorderHighlightedFilmOrder(highlightedFilms);

            highlightedFilmsFormatedForSave = _.cloneDeep(highlightedFilms);
            highlightedFilmsFormatedForSave.forEach(function (highlightedFilm) {
                highlightedFilm.heroImage = highlightedFilm.heroImage.replace(new RegExp("^" + API_ROOT), "");
                delete highlightedFilm.film;
            });
            return highlightedFilmsService.bulkSaveHighlightedFilms(highlightedFilmsFormatedForSave).success(function (result) {
                if (result.status.toLowerCase() === "success") {
                    self.highlightedFilms._embedded.highlightedFilms = highlightedFilms;
                    result.data.highlightedFilms.forEach(function(highlightedFilm){
                        delete highlightedFilm.film;
                    });
                    _.merge(self.highlightedFilms._embedded.highlightedFilms, result.data.highlightedFilms);

                    /*self.highlightedFilms._embedded.highlightedFilms.forEach(function (highlightedFilm) {
                        if (/\?timestamp=\d+/ig.test(highlightedFilm.heroImage)) {
                            highlightedFilm.heroImage.replace(/\?timestamp=\d+/ig, "?timestamp=" + moment().unix());
                        } else {
                            highlightedFilm.heroImage += "?timestamp=" + moment().unix();
                        }
                    });*/
                }
            });
        };

        /**
         * Get all films excluding highlighted 
         * @function getAllFilmsExcludingHighlighted
         * @param {string} filterQuery filter query
         * @param {int} number number 
         * @param {size} size page size 
         * @param {string} sort sort param 
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @returns {promise}
         */
        self.getAllFilmsExcludingHighlighted = function (filterQuery, number, size, sort) {
            var sortQuery = sort || self.filmSortQuery;
            return filmService.getAllFilmsExcludingHighlighted(self.films.page.number, self.films.page.size, sortQuery).success(function (result) {
                self.films = result;
                filmsCopy = _.cloneDeep(self.films);
            });
        };

        /**
         * Cancel
         * @function cancel
         * @public
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        self.cancel = function () {
            if (self.showSelectTitle) {
                self.showSelectTitle = false;
            } else {
                $uibModal.open({
                    templateUrl: "confirmationTemplate.html",
                    controller: "modalConfirmationController",
                    controllerAs: "modalConfirmationCtrl",
                    keyboard: false,
                    backdrop: "static",
                    size: "md",
                    resolve: {
                        message: function () {
                            return self.translations.global.messages.confirmationMessage;
                        },
                        headerTitle: function () {
                            return self.translations.global.messages.warning;
                        },
                        showAffirmativeBtn: function () {
                            return true;
                        },
                        affirmativeBtnText: function () {
                            return self.translations.global.labels.yesButton;
                        },
                        cancelBtnText: function () {
                            return self.translations.global.labels.noButton;
                        }
                    }
                }).result.then(function () {
                    $uibModalInstance.dismiss();
                });
            }
        };

        /**
         * On film select 
         * @function onFilmSelect
         * @param {object} film film title object
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @public
         */
        self.onFilmSelect = function (film) {
            self.isNextDisabled = !(self.showSelectTitle && film);
            selectedFilm = film;
        };

        /**
         * Add title 
         * @function addTitle
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @public
         */
        self.addTitle = function () {
            isEditHighlightedFilmMode = false;
            self.showSelectTitle = true;
            if (self.films._embedded && self.films._embedded.films && self.films._embedded.films.length > 0) {
                checkAlreadyAddedHighlightedFilm();
            } else {
                self.getAllFilmsExcludingHighlighted().then(function () {
                    checkAlreadyAddedHighlightedFilm();
                });
            }
        };

        /**
         * Go next
         * @function next 
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @public
         */
        self.next = function () {
            if (self.showSelectTitle) {
                self.showSelectTitle = false;
                self.showSelectFeatureImage = true;

                self.highlightedFilms._embedded.highlightedFilms.unshift({
                    filmId: selectedFilm.filmId,
                    heroImage: "",
                    recType: "PUB",
                    territoryId: location.territoryId,
                    showOnesheet: 0,
                    localeId: location.localeId,
                    order: 1,
                    film: selectedFilm
                });

                getFilmAssets(selectedFilm.id);
                generateGenericBackgrounds();

            } else if (self.showSelectFeatureImage) {

                self.showSelectTitle = false;
                self.showSelectFeatureImage = false;
                self.showEditFeatureImage = true;
            }

            self.isNextDisabled = true;
        };

        /**
         * Go back
         * @function back
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @public
         */
        self.back = function () {
            if (self.showSelectFeatureImage) {
                if (isEditHighlightedFilmMode) {
                    self.showSelectTitle = false;
                    self.showSelectFeatureImage = false;
                    self.highlightedFilmIndex = 0;
                } else {
                    self.showSelectTitle = true;
                    self.showSelectFeatureImage = false;
                }
                self.highlightedFilms = _.cloneDeep(highlightedFilmsCopy);
            } else if (self.showEditFeatureImage) {
                self.isNextDisabled = false;
                self.showSelectFeatureImage = true;
                self.showEditFeatureImage = false;
                self.cropResultImage = "";
                self.isCroppieReady = false;
            }
            self.IsVisible = false;
        };

        /**
         * On hero generic background select 
         * @function onHeroGenericBackgroundSelect
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @public
         * @param {object} heroImage hero image object
         */
        self.onHeroGenericBackgroundSelect = function (heroImage) {
            resetSelectedState();

            heroImage.selected = true;
            self.isNextDisabled = false;

            self.highlightedFilms._embedded.highlightedFilms[self.highlightedFilmIndex].heroImage = heroImage.imageSrc;
            self.highlightedFilms._embedded.highlightedFilms[self.highlightedFilmIndex].heroImageType = "generic";
        };

        /**
         * On hero image select
         * @function onHeroImageSelect
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @public
         * @param {object} filmAsset selected film asset
         */
        self.onHeroImageSelect = function (filmAsset) {
            resetSelectedState();

            filmAsset.selected = true;
            self.isNextDisabled = false;

            self.highlightedFilms._embedded.highlightedFilms[self.highlightedFilmIndex].heroImage = API_EFD + "efd/heroImages/data/" + filmAsset.id;
            self.highlightedFilms._embedded.highlightedFilms[self.highlightedFilmIndex].heroImageType = "titleRelated";
            self.highlightedFilms._embedded.highlightedFilms[self.highlightedFilmIndex].assetId = filmAsset.id;
        };

        /**
         * Preview image results 
         * @function preview 
         * @public 
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        self.preview = function () {
            self.showPreview = true;
        };

        /**
         * On croppie ready
         * @function onCroppieReady 
         * @public 
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         */
        self.onCroppieReady = function () {
            self.isCroppieReady = true;
            setTimeout(function () {
                $scope.$apply();
            }, 0);
        };

        /**
         * Finish adding/editing highlighted fiml 
         * @function finishAddEditHighlightedFilm
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @public
         */
        self.finishAddEditHighlightedFilm = function () {
            self.highlightedFilms._embedded.highlightedFilms[self.highlightedFilmIndex].onesheet = self.highlightedFilms._embedded.highlightedFilms[self.highlightedFilmIndex].film.onesheet;
            self.highlightedFilms._embedded.highlightedFilms[self.highlightedFilmIndex].lastModified = moment().utc().format();
            self.highlightedFilms._embedded.highlightedFilms[self.highlightedFilmIndex].modifier = user.userId;
            self.highlightedFilms._embedded.highlightedFilms.sort(function (current, next) {
                return current.order - next.order;
            }).forEach(function (highlightedFilm, index) {
                highlightedFilm.order = index + 1;
            });
            if (self.highlightedFilms._embedded.highlightedFilms[self.highlightedFilmIndex].heroImageType === "titleRelated") {
                setTimeout(function () { // croppie plugin has promise to return base64 so this runs firs we want to run this after croppie returns base64
                    self.highlightedFilms._embedded.highlightedFilms[self.highlightedFilmIndex].heroImage = self.cropResultImage;
                    self.showEditFeatureImage = false;
                    self.highlightedFilmIndex = 0;
                    self.cropResultImage = "";
                    highlightedFilmsCopy = _.cloneDeep(self.highlightedFilms);
                    $scope.$apply();
                }, 1);
            } else {
                self.showEditFeatureImage = false;
                setTimeout(function () {
                    self.highlightedFilmIndex = 0;
                    highlightedFilmsCopy = _.cloneDeep(self.highlightedFilms);
                    $scope.$apply();
                }, 0);
            }
            self.showPreview = false;
            self.isCroppieReady = false;
            self.IsVisible = false;
            resetSelectedState();

        };

        /**
         * Save highlighted film 
         * @function saveHighlightedFilm
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @public
         */
        self.saveHighlightedFilm = function () {
            $q.all([
                deleteHighlightedFilms(),
                saveHighlightedFilms()
            ]).then(function () {
                $uibModalInstance.close(self.highlightedFilms);
            });
        };

        /**
         * Delete highlighted film 
         * @function deleteHighlightedFilm
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @public
         * @param {object} highlightedFilm highlighted film
         */
        self.deleteHighlightedFilm = function (highlightedFilm) {
            highlightedFilm.isDeleted = true;
            reorderHighlightedFilmOrder(self.highlightedFilms._embedded.highlightedFilms);
        };

        /**
         * Edit highlighted film 
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @function editHighlightedFilm
         * @public
         * @param {object} highlighted film 
         */
        self.editHighlightedFilm = function (highlightedFilm) {
            self.highlightedFilmIndex = _.findIndex(self.highlightedFilms._embedded.highlightedFilms, {
                id: highlightedFilm.id
            });
            if (self.highlightedFilmIndex === -1) {
                self.highlightedFilmIndex = 0;
            }
            getFilmAssets(highlightedFilm.film.id).then(function () {
                var filmAsset = _.find(self.filmAssets, {
                    id: self.highlightedFilms._embedded.highlightedFilms[self.highlightedFilmIndex].assetId
                });
                if (filmAsset) {
                    self.onHeroImageSelect(filmAsset);
                }
            });
            generateGenericBackgrounds();
            self.showSelectFeatureImage = true;
            isEditHighlightedFilmMode = true;
        };

        /**
         * Change order up 
         * @function changeOrderUp
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @public
         * @param {object} highlightedFilm highlighted film
         */
        self.changeOrderUp = function (highlightedFilm) {
            var filteredHighlightedFilms = self.highlightedFilms._embedded.highlightedFilms.filter(function (highlightedFilm) {
                    return !highlightedFilm.isDeleted;
                }),
                deletedHighlightedFilms = self.highlightedFilms._embedded.highlightedFilms.filter(function (highlightedFilm) {
                    return highlightedFilm.isDeleted;
                }),
                index = _.findIndex(filteredHighlightedFilms, {
                    filmId: highlightedFilm.filmId
                }),
                tempHighlightedFilm = filteredHighlightedFilms[index - 1];

            highlightedFilm.order -= 1;
            tempHighlightedFilm.order += 1;

            filteredHighlightedFilms[index - 1] = highlightedFilm;
            filteredHighlightedFilms[index] = tempHighlightedFilm;
            
            filteredHighlightedFilms.concat(deletedHighlightedFilms);
            self.highlightedFilms._embedded.highlightedFilms = filteredHighlightedFilms;
        };
        
        /**
         * show or hide onesheet position div 
         * @function showPosition
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @public
         * @param {object} display display the div
         */
        self.showPosition = function (display) {
            if (display === "Y") {
                self.IsVisible = true;
            } else {
                self.IsVisible = false;
            }
        };

        /**
         * Change order down 
         * @function changeOrderDown
         * @memberOf angular_module.WB.cinehub.controllers.modalEditMainFilmCarousel
         * @public
         * @param {object} highlightedFilm highlighted film
         */
        self.changeOrderDown = function (highlightedFilm) {
            var filteredHighlightedFilms = self.highlightedFilms._embedded.highlightedFilms.filter(function (highlightedFilm) {
                    return !highlightedFilm.isDeleted;
                }),
                deletedHighlightedFilms = self.highlightedFilms._embedded.highlightedFilms.filter(function (highlightedFilm) {
                    return highlightedFilm.isDeleted;
                }),
                index = _.findIndex(filteredHighlightedFilms, {
                    filmId: highlightedFilm.filmId
                }),
                tempHighlightedFilm = filteredHighlightedFilms[index + 1];

            highlightedFilm.order += 1;
            tempHighlightedFilm.order -= 1;

            filteredHighlightedFilms[index + 1] = highlightedFilm;
            filteredHighlightedFilms[index] = tempHighlightedFilm;
            
            filteredHighlightedFilms.concat(deletedHighlightedFilms);
            self.highlightedFilms._embedded.highlightedFilms = filteredHighlightedFilms;
        };
    }

    controllers.controller("modalEditMainFilmCarousel", ["$scope", "$uibModal", "$uibModalInstance", "localStorageService", "DEFAULT_PAGE_SIZE", "highlightedFilms", "filmService", "API_EFD", "highlightedFilmsService", "API_ROOT", "$cookies", "$q", modalEditMainFilmCarousel]);
}());
