(function () {
    "use strict";
    var services = angular.module("WB.cinehub.services");

    /**
     * Video source generator service
     * @class videoSourceGeneratorService
     * @memberOf angular_module.WB.cinehub.services
     * @desc This is video source generator service
     * @param {constant} API_EFD API_EFD
     * @param {service} $sce $sce
     * @param {service} mediaTypeService mediaTypeService
     */
    function videoSourceGeneratorService(API_EFD, $sce, mediaTypeService) {

        this.getSource = function (id, fileName) {
            if (id) {
                return [{
                    src: $sce.trustAsResourceUrl(API_EFD + "efd/sourceFiles/" + id),
                    /* this could be more robust, but we don't know what valid types apply to the various file extensions */
                    type: mediaTypeService.getMediaTypeFromFileName(fileName) === mediaTypeService.AUDIO ? "audio/mpeg" : "video/mp4"
                }];
            } else {
                // throw new Error("There must be both an id and filename. Received id: '" + id + "' fileName: '" + fileName + "'");
                return [{}];
            }
        };
    }

    services.service("videoSourceGeneratorService", [
        "API_EFD",
        "$sce",
        "mediaTypeService",
        videoSourceGeneratorService
    ]);
} ());
