(function () {
    "use strict";

    var directives = angular.module("WB.cinehub.directives");

    /**
     * Resize directive
     * @class resizeDirective
     * @memberOf angular_module.WB.cinehub.directives
     * @param {window} $window window
     * @returns {directiveDefinitionObj}
     */
    function resizeDirective($window) {

        /**
         * Directive link
         * @function link
         * @param {!angular.Scope} scope angular scope
         * @private
         * @memberOf angular_module.WB.cinehub.directives.resizeDirective
         */
        var link = function (scope, element) {
            var window = angular.element($window);
            
            element.height((window.height() - scope.resizeWithOffset) + "px");

            window.bind("resize", function () {
                element.height((window.height() - scope.resizeWithOffset) + "px");
            });
        };

        return {
            link: link,
            scope: {
                resizeWithOffset: "="
            }
        };
    }

    directives.directive("resize", ["$window", resizeDirective]);
}());
