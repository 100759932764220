(function() {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class territoriesService
     * @memberOf angular_module.WB.cinehub.services
     * @desc exhibitors service
     * @param {!angular.$http} $http angular $http service
     * @param {strict} API_ROOT API_ROOT constant
     * @param {service} translateService translate service
     */
    function territoriesService($http, API_ROOT, localStorageService) {

        /**
         * translations
         * @private
         * @type {object}
         * @memberOf angular_module.WB.cinehub.services.territoriesService
         */
        var translations = localStorageService.get("translations");

        /**
         * Search territories by search criteria
         * @memberOf angular_module.WB.cinehub.services.territoriesService
         * @public
         * @function searchTerritoriesBySearchTerm
         * @returns {promise}
         * @param {string} term search term
         */
        this.searchTerritoriesBySearchTerm = function(term) {
            return $http.get(API_ROOT + "territories/search/findByNameLike?name=" + term, {
                errorMessage: translations.global.messages.cannotRetrieveListOfTerritories,
                showSpinner: false
            }).then(function(result) {
                return result.data._embedded.territories;
            });
        };

        /**
         * Search territories by id
         * @memberOf angular_module.WB.cinehub.services.territoriesService
         * @public
         * @function getTerritoryById
         * @returns {promise}
         * @param {int} it territory id
         */
        this.getTerritoryById = function(id) {
            return $http.get(API_ROOT + "territories/search/findById?id=" + id + "&ignoreLocalizationString", {
                errorMessage: translations ? translations.global.messages.cannotRetrieveListOfTerritories : "Cannot retrieve the list of territories. Please try again later.",
                showSpinner: false
            });
        };

        /**
         * get live territories and locales
         * @private
         * @function
         * @memberOf angular_module.WB.cinehub.services.territoriesService
         */
        this.getLiveTerritoriesAndLocales = function () {
            return $http.get(API_ROOT + "locales/search/findAllActive?projection=embedLocales&ignoreLocalizationString", {
                errorMessage: translations ? translations.global.messages.cannotRetrieveListOfTerritories : "Cannot retrieve the list of territories. Please try again later.",
                showSpinner: false
            });
        };
    }

    services.service("territoriesService", ["$http", "API_ROOT", "localStorageService", territoriesService]);
}());
