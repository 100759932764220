(function() {
    "use strict";

    var services = angular.module("WB.cinehub.services");

    /**
     * @class convertLocalizationCodeToValueService
     * @memberOf angular_module.WB.cinehub.services
     * @param {!angular.$http} $http angular $http service
     * @param {strict} API_ROOT API_ROOT constant  
     * @param {service} translateService translateService
     */
    function convertLocalizationCodeToValueService(localStorageService) {

        /**
         * Translations
         * @private
         * @type {object}
         * @memberOf angular_module.WB.cinehub.controllers.convertLocalizationCodeToValueService
         */
        var translations = null;

        /**
         * get value by code
         * @memberOf angular_module.WB.cinehub.controllers.convertLocalizationCodeToValueService
         * @function etValueByCode
         * @paran {string} name prop name
         * @paran {string} code localization code
         * @public
         */
        this.convert = function(name, code) {
            translations = localStorageService.get("translations");
            if (code && translations) {
                var translation = _.find(translations.values[name], {
                    "code": code
                });
                if (translation && translation.value) {
                    return translation.value;
                } else {
                    return code;
                }
            } else {
                return "";
            }
        };
    }

    services.service("convertLocalizationCodeToValueService", ["localStorageService", convertLocalizationCodeToValueService]);
}());
